

const HeaderComponent = () => {
    return (
       <>
       <header className="img-fluid mb-5" style={{ backgroundImage: 'url(../assets/img/geofencing2.webp)', backgroundSize: 'cover', backgroundPosition: 'center', padding: '60px 0' }}>
    <div className="container position-relative">
        <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10 col-md-12">
                <div className="text-center text-white" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', padding: '20px', borderRadius: '8px' }}>
                    <h1 className="mb-4" style={{ fontSize: '2.5rem', fontWeight: 'bold', lineHeight: '1.2' }}>
                        Rastreie Cervejas e Vinhos. Descubra promoções <span style={{ color: '#ffcc00' }}>PERTO</span> de você! Grátis.
                    </h1>
                </div>
            </div>
        </div>
    </div>
</header>

{
    /*
<header className="masthead img-fluid mb-5">
            <div className="container position-relative">
                <div className="row justify-content-center">
                    <div className="col-xl-6">
                        <div className="text-center text-white">
                            
                            <h1 className="mb-5">Rastreie Cervejas e Vinhos. Descubra promoções PERTO de você! Grátis</h1>
                           
                          
                        </div>
                    </div>
                </div>
            </div>
        </header>
    */
}
        </>
    );
}

export default HeaderComponent;


