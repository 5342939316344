import React , {useEffect} from 'react';
import { useForm } from 'react-hook-form';
import apiAxios from '../../services/api';
import MyVerticallyCenteredModal from '../modals/verticallyCenteredModal';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';

const SectionRedefinirSenhaParceiro = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [modalShow, setModalShow] = React.useState(false);
  const [textBodyModal, setTextBodyModal] = React.useState('');
  const [titleTextBodyModal, setTitleTextBodyModal] = React.useState('');
  const [titleModal, setTitleModal] = React.useState('');
  const [token, setToken] = React.useState(null);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    setToken(token);
  }, [location.search]);

  const onSubmit = async (data) => {

        const { novaSenha, confirmeNovaSenha } = data;

        if(novaSenha !== confirmeNovaSenha){
          setTextBodyModal('Senha é diferente da confirmação');
          setTitleTextBodyModal('Senha deve ser igual a confirmação');
          setTitleModal('Atenção:');
          setModalShow(true);
          return;
        }

        if(novaSenha.length > 6 || novaSenha.length < 6){
          setTextBodyModal('Senha deve conter 6 caracteres');
          setTitleTextBodyModal('Senha deve conter 6 caracteres');
          setTitleModal('Atenção:');
          setModalShow(true);
          return;
        }

        if(!token || token === null || token === ''){
          setTextBodyModal('Problemas com o token, faça o processo novamente');
          setTitleTextBodyModal('Deve conter token');
          setTitleModal('Atenção:');
          setModalShow(true);
          return;
        }

        const urlRedefinirSenha = '/parceiro/redefinirSenha';
        
        apiAxios.post(urlRedefinirSenha, {
            'novaSenha' : novaSenha,
            'confirmeNovaSenha' : confirmeNovaSenha ,
            'token' : token
           })
           .then((response) => {
             
            setTextBodyModal(response.data.msg);
            setTitleTextBodyModal('Sobre sua senha...');
            setTitleModal('Atenção:');
            setModalShow(true);

           })
           .catch((erro) => {
             console.log(JSON.stringify(erro));
             alert('Ocorreu um erro ao solicitar a redefinição de senha. Por favor, tente novamente.');
            
           });

   
  };

  return ( 
    <div>
    <MyVerticallyCenteredModal
    show={modalShow}
    onHide={() => setModalShow(false)}
    textBodyModal={textBodyModal}
    titleTextBodyModal={titleTextBodyModal}
    titleModal={titleModal}
  />   

<Container>
  <section className="w-100 px-4 py-5 text-center bg-light rounded shadow-sm">
    
    <h2 className="mb-4">Redefinição de Senha</h2>
    
    <Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column align-items-center">
      
      <Row className="mb-4 w-100 justify-content-center">
        <Col md={4}>
          <Form.Group controlId="novaSenha">
            <Form.Label>Nova Senha:</Form.Label>
            <Form.Control
              type="password"
              {...register('novaSenha', { required: 'Senha é obrigatória' })}
              isInvalid={!!errors.novaSenha}
            />
            <Form.Control.Feedback type="invalid">
              {errors.novaSenha?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      
      <Row className="mb-4 w-100 justify-content-center">
        <Col md={4}>
          <Form.Group controlId="confirmeNovaSenha">
            <Form.Label>Confirme Nova Senha:</Form.Label>
            <Form.Control
              type="password"
              {...register("confirmeNovaSenha", { required: 'Confirmação é obrigatória' })}
              isInvalid={!!errors.confirmeNovaSenha}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmeNovaSenha?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="w-100 justify-content-center">
        <Col md={4}>
          <Button variant="primary" type="submit" className="w-100">Solicitar Redefinição de Senha</Button>
        </Col>
      </Row>

    </Form>
    
  </section>
</Container>

    </div>
  );
};

export default SectionRedefinirSenhaParceiro;