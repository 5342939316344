import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import ImagemVantagem from '../assets/images/pessoaencontrandopromocoes.webp';

// Lista de frases para serem exibidas.
const phrases = [
    'A Geoget transforma a gestão de bares e restaurantes, proporcionando uma experiência aprimorada tanto para os proprietários quanto para os clientes, através do uso inteligente de dados e tecnologia de geolocalização.',
    'Atraia clientes locais com nossas ferramentas de geopromoção personalizadas.',
    'Aqui geolocalização é usada de maneira eficaz por restaurantes para atrair clientes, destacando o poder das promoções direcionadas para impulsionar o tráfego e aumentar as vendas.'
    
  ];

const SectionVantagemBaresRestaurantes = (props) => {

    const [phrase, setPhrase] = useState('');

    useEffect(() => {
        // Seleciona uma frase aleatória da lista a cada renderização.
        const randomIndex = Math.floor(Math.random() * phrases.length);
        setPhrase(phrases[randomIndex]);
      }, []); // O array vazio garante que a frase seja definida apenas uma vez por renderização.

    return (
        <Container className="my-4">
          <Row className="align-items-center">
            <Col md={6}>
              <Image src={ImagemVantagem} alt="Geoget Geopromocoes de cervejas e vinhos" fluid />
            </Col>
            <Col md={6}>
              <h3>
              {phrase}
              </h3>
            </Col>
          </Row>
        </Container>
      );

}

export default SectionVantagemBaresRestaurantes;