import { useEffect, useRef } from "react";
import { Navbar, Nav, NavDropdown, Button, Container, Form } from "react-bootstrap";
import LogoGeoGet from '../assets/images/geogetHoriz.png'; 
import { controleAbasAbertasLocalStorage } from "../util/util";


const NavBarComponent = () => {
    
  
  return (
    <Navbar expand="lg" className="bg-body-tertiary" style={{ backgroundColor: '#E9ECEF' }}>
    <Container>
      <Navbar.Brand href="/">
        <img src={LogoGeoGet} alt="Logotipo GeoGet - GeoPromoções" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav className="me-auto my-2 my-lg-0" navbarScroll>
          <Nav.Link href="/cadastroEstabelecimentos" className="nav-item">
            <i className="fas fa-beer"></i> Vender Cervejas ou Vinhos
          </Nav.Link>
          <Nav.Link href="/cadastroUsuarios" className="nav-item">
            <i className="fas fa-search"></i> Localizar Cervejas ou Vinhos
          </Nav.Link>
          <Nav.Link href="/cadastroParceiros" className="nav-item">
            <i className="fas fa-plug"></i> Parceria Distribuidoras/Sistemas
          </Nav.Link>
        </Nav>
        <div className="font-weight-bold">
          GeoPromoções
        </div>
        
      </Navbar.Collapse>
    </Container>
  </Navbar>
  
    );
}

export default NavBarComponent;