import {
    Container,
    Row,
    Col,
    Figure
} from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import {Link} from 'react-router-dom';
import ImagemRotaCerveja from '../assets/images/rotaCervejaMapaMarker.webp';
import ImagemRotaVinho from '../assets/images/rotaVinhoEscura.webp';
import { useState,useEffect } from "react";






const SectionRotas = (props) => { 



    const [estabelecimentos,setEstabelecimentos] = useState([]);
    const [urlRotaCerveja, setUrlRotaCerveja] = useState(null);
    const [urlRotaVinho, setUrlRotaVinho] = useState(null);
    
    const [estabelecimentosCerveja, setEstabelecimentosCerveja] = useState([{}]);
    const [estabelecimentosVinho, setEstabelecimentosVinho] = useState([{}]);
    const [origin, setOrigin] = useState({ lat: null, lng: null });
    

    useEffect(() => {

      const arrayEstabelecimentosCerveja = [];
      const arrayEstabelecimentosVinho = [];
      
      estabelecimentos.forEach(estabelecimento => {
        // Verifica se o estabelecimento tem produtos da categoria Cervejas
        const hasCervejas = estabelecimento.produtos_venda.some(produto => produto.categoria === 'Cervejas');
        if (hasCervejas) {
          arrayEstabelecimentosCerveja.push(estabelecimento);
        }
      
        // Verifica se o estabelecimento tem produtos da categoria Vinhos
        const hasVinhos = estabelecimento.produtos_venda.some(produto => produto.categoria === 'Vinhos');
        if (hasVinhos) {
          arrayEstabelecimentosVinho.push(estabelecimento);
        }
      });

      if(arrayEstabelecimentosCerveja.length > 0){
        setEstabelecimentosCerveja(arrayEstabelecimentosCerveja);
      }

      if(arrayEstabelecimentosVinho.length > 0){
        setEstabelecimentosVinho(arrayEstabelecimentosVinho);
      }

      

      if(estabelecimentosCerveja.length > 0){
        //Criar rotas das Cervejas
        const ultimoEstabelecimentoCerveja = estabelecimentosCerveja[estabelecimentosCerveja.length - 1];
        const destinationCerveja = { lat: ultimoEstabelecimentoCerveja.latitude, lng: ultimoEstabelecimentoCerveja.longitude }; 
        const waypointsCerveja = [];
        estabelecimentosCerveja.forEach(estabelecimentoCerveja => {
  
          if(estabelecimentoCerveja._id !== ultimoEstabelecimentoCerveja._id ){
  
            const waypointCerva = {lat: estabelecimentoCerveja.latitude, lng: estabelecimentoCerveja.longitude};
          
            waypointsCerveja.push(waypointCerva);
          }
          
  
        });
        
        const rotaCriadaCerveja = criarRotas(origin, waypointsCerveja, destinationCerveja);
        setUrlRotaCerveja(rotaCriadaCerveja);
  
      }

      if(estabelecimentosVinho.length > 0){
        //Criar rotas dos Vinhos
  
  
  
  
        const ultimoEstabelecimentoVinho = estabelecimentosVinho[estabelecimentosVinho.length - 1];
        const destinationVinho = { lat: ultimoEstabelecimentoVinho.latitude, lng: ultimoEstabelecimentoVinho.longitude }; 
        const waypointsVinho = [];
        estabelecimentosVinho.forEach(estabelecimentoVinho => {
  
          if(estabelecimentoVinho._id !== ultimoEstabelecimentoVinho._id ){
  
            const waypointVinho = {lat: estabelecimentoVinho.latitude, lng: estabelecimentoVinho.longitude};
          
            waypointsVinho.push(waypointVinho);
          }
          
  
        });
        
        const rotaCriadaVinho = criarRotas(origin, waypointsVinho, destinationVinho);
        setUrlRotaVinho(rotaCriadaVinho);
  
      }
  
    },[estabelecimentos]);

    useEffect(() => {
      
      
      setEstabelecimentos(props.estabelecimentos);
      setOrigin(props.origin);

    },[props.estabelecimentos,props.origin]);

    

    //https://developers.google.com/maps/documentation/urls/get-started?hl=pt-br
    const criarRotas = (origin, waypoints, destination) =>{
        const baseUrl = 'https://www.google.com/maps/dir/?api=1';
        const originParam = `&origin=${origin.lat},${origin.lng}`;
        const destinationParam = `&destination=${destination.lat},${destination.lng}`;
        const waypointsParam = waypoints.length 
          ? `&waypoints=${waypoints.map(wp => `${wp.lat},${wp.lng}`).join('|')}`
          : '';
        const travelModeParam = '&travelmode=driving';
    
        return `${baseUrl}${originParam}${destinationParam}${waypointsParam}${travelModeParam}`;
      }

    

      

      // Estilos inline para otimizar a responsividade e adicionar sombra
  const styles = {
    section: {
      backgroundColor: '#f5f5f5', // Cor de fundo suave para contraste
      padding: '4rem 0', // Padding vertical generoso
    },
    heading: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
      color: '#007bff', // Azul GeoGet
      textAlign: 'center',
      marginBottom: '2.5rem', // Margem inferior para espaçamento
    },
    figureImage: {
      width: '100%',
      height: 'auto',
      borderRadius: '0.5rem', // Bordas arredondadas para suavidade
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Sombra suave para destaque
    },
    figureCaption: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      color: '#007bff',
      marginTop: '1rem',
    },
    column: {
      marginBottom: '1.5rem', // Margem inferior para espaçamento entre colunas
      textAlign: 'center',
    },
  };

      return (
        <div>
          {
            estabelecimentos !== null && 
            estabelecimentos.length > 0 && 
            (urlRotaCerveja !== null || urlRotaVinho !== null) ? 
            
            (
              <section key={uuidv4()} style={styles.section}>
              <Container>
                <h2 style={styles.heading}>
                Não sabe onde ir com os amigos? Deixe a GeoGet te guiar pelas melhores paradas de cervejas e vinhos em promoção.
                </h2>
        
                <Row className="justify-content-center">
                  {
                    (estabelecimentosCerveja.length > 1 || (estabelecimentosCerveja.length === 1 && Object.keys(estabelecimentosCerveja[0]).length > 0)) && urlRotaCerveja !== null ? (
                      <Col md={urlRotaVinho === null ? 12 : 6} style={styles.column}>
                        <Link to={urlRotaCerveja} target='_blank'>
                          <Figure>
                            <Figure.Image
                              alt="Rota da Cerveja"
                              src={ImagemRotaCerveja}
                              style={styles.figureImage}
                            />
                            <Figure.Caption style={styles.figureCaption}>
                              Rota da Cerveja
                            </Figure.Caption>
                          </Figure>
                        </Link>
                      </Col>
                    ) : null
                  }
        
                  {
                    (estabelecimentosVinho.length > 1 || (estabelecimentosVinho.length === 1 && Object.keys(estabelecimentosVinho[0]).length > 0)) && urlRotaVinho !== null ? (
                      <Col md={urlRotaCerveja === null ? 12 : 6} style={styles.column}>
                        <Link to={urlRotaVinho} target='_blank'>
                          <Figure>
                            <Figure.Image
                              alt="Rota do Vinho"
                              src={ImagemRotaVinho}
                              style={styles.figureImage}
                            />
                            <Figure.Caption style={styles.figureCaption}>
                              Rota do Vinho
                            </Figure.Caption>
                          </Figure>
                        </Link>
                      </Col>
                    ) : null
                  }
                </Row>
              </Container>
            </section>

            )
            : null
          }
        </div>
      );
      

}

export default SectionRotas;