import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SectionPoliticaPrivacidade = () => {
    return (
        <Container>
     <Row>
      <Col>
      <div style={{ margin: '20px', padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: 1.6 }}>
      <h1>Política de Privacidade</h1>

      <p>Esta Política de Privacidade descreve como a GeoGet coleta, usa e protege as informações pessoais dos usuários do nosso site, em conformidade com a Lei Geral de Proteção de Dados (LGPD) do Brasil.</p>

      <h2>1. Coleta de Informações</h2>
      <p>Coletamos as seguintes informações pessoais dos usuários:</p>
      <ul>
        <li>Informações de contato, como nome, endereço de e-mail e número de telefone;</li>
        <li>Informações de localização, quando permitido pelo usuário;</li>
        <li>Dados de navegação, como endereço IP, tipo de navegador e páginas acessadas;</li>
        <li>Informações fornecidas pelo usuário ao preencher formulários no site.</li>
      </ul>

      <h2>2. Uso das Informações</h2>
      <p>As informações pessoais coletadas são utilizadas para:</p>
      <ul>
        <li>Fornecer e melhorar nossos serviços;</li>
        <li>Personalizar a experiência do usuário no site;</li>
        <li>Enviar comunicações promocionais e informativas, com o consentimento do usuário;</li>
        <li>Responder a solicitações e perguntas dos usuários;</li>
        <li>Cumprir obrigações legais e regulatórias.</li>
      </ul>

      <h2>3. Compartilhamento de Informações</h2>
      <p><b>Não compartilhamos informações pessoais dos usuários com terceiros</b>, exceto nas seguintes situações:</p>
      <ul>
        <li>Quando necessário para fornecer nossos serviços, como com fornecedores e parceiros de confiança;</li>
        <li>Para cumprir obrigações legais e regulatórias;</li>
        <li>Com o consentimento explícito do usuário.</li>
      </ul>

      <h2>4. Segurança das Informações</h2>
      <p>Implementamos medidas de segurança adequadas para proteger as informações pessoais dos usuários contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhum método de transmissão pela internet ou armazenamento eletrônico é completamente seguro.</p>

      <h2>5. Direitos dos Usuários</h2>
      <p>De acordo com a LGPD, os usuários têm os seguintes direitos em relação às suas informações pessoais:</p>
      <ul>
        <li>Acessar suas informações pessoais;</li>
        <li>Solicitar a correção de informações pessoais incompletas, inexatas ou desatualizadas;</li>
        <li>Solicitar a anonimização, bloqueio ou eliminação de informações pessoais desnecessárias, excessivas ou tratadas em desconformidade com a LGPD;</li>
        <li>Solicitar a portabilidade de suas informações pessoais a outro fornecedor de serviço ou produto;</li>
        <li>Solicitar a eliminação das informações pessoais coletadas e tratadas com base no seu consentimento;</li>
        <li>Obter informações sobre as entidades públicas e privadas com as quais compartilhamos suas informações pessoais;</li>
        <li>Revogar o consentimento, a qualquer momento, para o tratamento de suas informações pessoais.</li>
      </ul>

      <h2>6. Cookies e Tecnologias Semelhantes</h2>
      <p>Nosso site utiliza cookies para melhorar a experiência do usuário, analisar o tráfego do site e personalizar o conteúdo oferecido. Além disso, utilizamos cookies do Google para veicular anúncios aos usuários. Esses cookies permitem que o Google e seus parceiros exibam anúncios com base nas visitas anteriores dos usuários ao nosso site ou a outros sites na Internet. Os usuários podem desativar a publicidade personalizada acessando as Configurações de anúncios do Google.</p>

      <h2>7. Exercício de Direitos</h2>
      <p>Para exercer seus direitos, os usuários podem entrar em contato conosco através do e-mail geoget.ofertas@gmail.com. Responderemos às solicitações no menor tempo possível, em conformidade com a LGPD.</p>

      <h2>8. Alterações nesta Política de Privacidade</h2>
      <p>Podemos atualizar esta Política de Privacidade periodicamente. Recomendamos que os usuários revisem esta página regularmente para se manterem informados sobre quaisquer mudanças. O uso contínuo do site após a publicação das alterações constitui aceitação das mesmas.</p>

      <h2>9. Contato</h2>
      <p>Se você tiver qualquer dúvida ou preocupação sobre esta Política de Privacidade ou sobre o tratamento de suas informações pessoais, entre em contato conosco através do e-mail geoget.ofertas@gmail.com.</p>
    </div>
      </Col>
      </Row>
      </Container>
    );
}

export default SectionPoliticaPrivacidade;