export const TOKEN_ESTABELECIMENTO_LOGADO = "geoget-estabelecimento-Token"; //TOKEN VERIFICACAO ESTABELECIMENTO LOGADO
export const TOKEN_CLIENT_LOGADO = "geoget-usuario-Token"; //TOKEN VERIFICACAO USUARIO LOGADO
export const DATA_CLIENT = "dataUser"; //DADOS DO USUARIO - NOME
export const DATA_ESTABELECIMENTO = "dataEstabelecimento"; //DADOS DO ESTABELECIMENDO - NOME
export const TOKEN_JWT_FORM = "tokenJwtForm"; //TOKEN PARA ENVIO JUNTO COM FORMS
export const TOKEN_SOCKETIO = "tokenSocketIo"; // TOKEN PARA ENVIO JUNTO COM TODA COMUNICAÇÃO DE SOCKET

export const PLANO_ATIVO_PARCEIRO = 'planoAtivoParceiro';
export const DATA_USER_PARCEIRO = 'dataUserParceiro';
export const TOKEN_PARCEIRO_LOGADO = 'tokenParceiroLogado';
export const TOKEN_ADMIN_LOGADO = 'tokenAdminLogado';


export const isAuthenticated = () => {
  return localStorage.getItem(TOKEN_ESTABELECIMENTO_LOGADO) !== null;
};

export const getTokenIsAuthenticatedEstabelecimento = () => JSON.parse(localStorage.getItem(TOKEN_ESTABELECIMENTO_LOGADO));

export const login = (token,dataUser) => {
  localStorage.setItem(TOKEN_ESTABELECIMENTO_LOGADO, JSON.stringify(token.toString()));
  
  localStorage.setItem(DATA_ESTABELECIMENTO,JSON.stringify(dataUser));
  
};

export const atualizaDataUserEstabelecimentoLocalStorage = (dataUser)=>{
  localStorage.setItem(DATA_ESTABELECIMENTO,JSON.stringify(dataUser));
}

export const loginParceiro = (planoAtivo,dataUser,tokenLogado)=>{

  localStorage.setItem(PLANO_ATIVO_PARCEIRO, planoAtivo);
  localStorage.setItem(DATA_USER_PARCEIRO,JSON.stringify(dataUser));
  localStorage.setItem(TOKEN_PARCEIRO_LOGADO,tokenLogado);
 
}

export const getTokenParceiroLogado = () =>{
  return localStorage.getItem(TOKEN_PARCEIRO_LOGADO);
}

export const logoffParceiro = () => {
  localStorage.removeItem(TOKEN_PARCEIRO_LOGADO);
  localStorage.removeItem(DATA_USER_PARCEIRO);
  localStorage.removeItem(PLANO_ATIVO_PARCEIRO);
  
}

export const loginAdmin = (tokenAdminLogado)=>{
  localStorage.setItem(TOKEN_ADMIN_LOGADO, tokenAdminLogado);
}

export const getTokenAdminLogado = () => {
  return localStorage.getItem(TOKEN_ADMIN_LOGADO);
}

export const logoffAdmin = () => {
  localStorage.removeItem(TOKEN_ADMIN_LOGADO);
}



export const logout = () => {
  localStorage.removeItem(TOKEN_ESTABELECIMENTO_LOGADO);
  localStorage.removeItem(DATA_ESTABELECIMENTO);
  localStorage.removeItem(TOKEN_JWT_FORM);
 
   
};

////////////////////////////////////////////////////////////
/////////////

//Verifica se Parceiro esta Logado
export const isAuthenticatedParceiro = ()=>{
  return (localStorage.getItem(DATA_USER_PARCEIRO) !== null && 
  localStorage.getItem(TOKEN_PARCEIRO_LOGADO) !== null)
  ;
}

//Verifica se Usuario esta logado
export const isAuthenticatedClient = () => {
  return localStorage.getItem(TOKEN_CLIENT_LOGADO) !== null;
};

//Obtem o token do usuario logado
export const getTokenIsAuthenticatedClient = () => JSON.parse(localStorage.getItem(TOKEN_CLIENT_LOGADO));

//Adiciona token do Usuario no Local Storage, ao se logar
export const loginClient = (token,dataUser,tokenJwtForm) => { 
  localStorage.setItem(TOKEN_CLIENT_LOGADO, JSON.stringify(token));
  localStorage.setItem(DATA_CLIENT,JSON.stringify(dataUser));
  localStorage.setItem(TOKEN_JWT_FORM,JSON.stringify(tokenJwtForm));
};

//Realiza o Logoff do Usuario
export const logoutClient = () => {
  localStorage.removeItem(TOKEN_CLIENT_LOGADO);
  localStorage.removeItem(DATA_CLIENT);
};

/////////////////
//////////////////////////////////////////////////////////

export const getDataClientLocalStorage = () => JSON.parse(localStorage.getItem(DATA_CLIENT));

///



export const getDataEstabelecimentoLocalStorage = () => JSON.parse(localStorage.getItem(DATA_ESTABELECIMENTO));

///

export const addTokenSocketLocalStorage = dataTokenSocket => {
  localStorage.setItem(TOKEN_SOCKETIO,JSON.stringify(dataTokenSocket));
};

export const getTokenSocketLocalStorage = () => JSON.parse(localStorage.getItem(TOKEN_SOCKETIO));


export const getTokenJwtFormLocalStorage = () => JSON.parse(localStorage.getItem(TOKEN_JWT_FORM));
