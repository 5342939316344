
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';


export function isLatitude(maybeLat) {
    var latF = parseFloat(maybeLat)
    if (isNaN(latF)) return false
    return (latF >= -90 && latF <= 90)
  }
  
  export function isLongitude(maybeLon) {
    var lonF = parseFloat(maybeLon)
    if (isNaN(lonF)) return false
    return lonF >= -180 && lonF <= 180
  } 


export function converterDecimal128ParaPrecoBrasil(decimal128Obj) {
  if (!decimal128Obj) return ''; // Verifica se o objeto Decimal128 está definido
  const precoString = decimal128Obj.$numberDecimal;
  const [inteira, decimal] = precoString.split('.');

  const inteiraComPontos = inteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  const decimalCorrigido = decimal ? decimal.padEnd(2, '0') : '00';

  return `${inteiraComPontos},${decimalCorrigido}`;
}

export function formatarPreco(preco) {
  return preco.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export async function controleAbasAbertasLocalStorage(abrir = false,fechar = false){

    if(abrir){

      if(localStorage.getItem('countAbasAbertas') !== null){
        //ja tem aba aberta, entao adicionar
        const qtdeAbasAbertas = parseInt(localStorage.getItem('countAbasAbertas')) + 1;
        localStorage.setItem('countAbasAbertas',qtdeAbasAbertas.toString());
      }
      else{
        //nao tem aba abarta entao iniciar
        localStorage.setItem('countAbasAbertas','1');
      }

    }

    if(fechar){

      const qtdeAbas = parseInt(localStorage.getItem('countAbasAbertas'));

      if(qtdeAbas === 1){
        localStorage.removeItem('countAbasAbertas');
      }
      else{
        //ja tem mais de uma aba aberta, entao retirar
        const qtdeAbasAbertas = parseInt(localStorage.getItem('countAbasAbertas')) - 1;
        localStorage.setItem('countAbasAbertas',qtdeAbasAbertas.toString());
      }

    }
}

export function getAbasAbertas(){
  //verifica se ainda tem abas abertas
  
  if(localStorage.getItem('countAbasAbertas') && parseInt(localStorage.getItem('countAbasAbertas')) >= 1){
    return true;
  }
  else{
    return false;
  }
  
}

export async function regraIdSocketsLocalSotrage(){

  if(
    localStorage.getItem('socketIdPing') === null || 
    localStorage.getItem('socketIdPing') === undefined 
  ){
    localStorage.setItem('socketIdPing',uuidv4());
  }

  if(
     localStorage.getItem('socketIdPingUsuarioLogado') === null ||
     localStorage.getItem('socketIdPingUsuarioLogado') === undefined
    ){
    localStorage.setItem('socketIdPingUsuarioLogado',uuidv4());
  }

  if(
    localStorage.getItem('socketIdPingEstabelecimentoLogado') === null ||
    localStorage.getItem('socketIdPingEstabelecimentoLogado') === undefined
  ){
    localStorage.setItem('socketIdPingEstabelecimentoLogado',uuidv4());
  }

}

  
// Função para formatar a data
export function formatDateDDMMAAAA (date)  {
  // Certifique-se de que a entrada é um objeto Date
 
  if(!date || date == undefined || date == null){
    return '';
  }

  // Use moment-timezone para definir o timezone de São Paulo e formatar a data
  const formattedDate = moment(date).tz('America/Sao_Paulo').format('DD/MM/YYYY');
  
  return formattedDate;
};
//https://developers.google.com/maps/documentation/urls/get-started?hl=pt-br
export async function criarUrlGoogleMapsDirect(rua,numero,bairro,cidade,estado,cep){

  //let urlGooGleMaps = new URL('https://www.google.com/maps/dir/');
  //urlGooGleMaps.searchParams.append('q', rua+','+numero+' - '+bairro+','+' '+cidade+' - '+estado+', '+cep);
  
  //let urlGooGleMaps = new URL('https://www.google.com/maps/search/?api=1');
  let urlGooGleMaps = new URL('https://www.google.com/maps/dir/?api=1');
  
  urlGooGleMaps.searchParams.append('query', rua+','+numero+' - '+bairro+','+' '+cidade+' - '+estado+', '+cep);
  

  return urlGooGleMaps;

}


