



const HeaderPageCadastroLoginParceiros = () => {
    return (
        <header className="mastheadPageCadastroLoginEstabelecimentos img-fluid mb-5">
            <div className="container position-relative">
                <div className="row justify-content-center">
                    <div className="col-xl-6">
                        <div className="text-center text-white">
                            
                            <h1 className="mb-5">Utilize a API GeoGet e acelere a venda de Cervejas e Vinhos dos seus clientes!</h1>
                           
                          
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderPageCadastroLoginParceiros;