export function getTiposEstabelecimentosBebidas(){


const arrayEstabelecimentosBebidas = 
['Tipo de Comércio',
    'Adega', 
    'Mercado', 
    'Restaurante',
    'Sacolão', 
    'Vinícola', 
    'Bar ou Boteco', 
    'Distr. Bebidas',
    'Cervejaria Artesanal'];

    return arrayEstabelecimentosBebidas;

}


export function getCategoriasBebidas(){

    const arrayCategoriasBebidas = ["Informe a Categoria", "Vinhos", "Cervejas"];

    return arrayCategoriasBebidas;
}