import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputMask from 'react-input-mask';
import apiAxios from '../../services/api';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import MyVerticallyCenteredModal from '../modals/verticallyCenteredModal';
import { loginAdmin, loginParceiro } from '../../services/auth';

const loginSchema = yup.object().shape({
    emailLogin: yup.string().email('Email inválido').required('Email é obrigatório'),
    passwordLogin: yup.string().required('Senha é obrigatória'),
  });

const SectionLoginAdmin = (props) => {

    const [modalShow, setModalShow] = React.useState(false);
 const [textBodyModal, setTextBodyModal] = React.useState('');
 const [titleTextBodyModal, setTitleTextBodyModal] = React.useState('');
 const [titleModal, setTitleModal] = React.useState('');

    const {
        handleSubmit: handleLoginSubmit,
        control: controlLogin,
        formState: { errors: errorsLogin },
      } = useForm({
        resolver: yupResolver(loginSchema),
      });

      const onLoginSubmit = (data:any) => {
        
    
        const urlLoginAdmin = "/admin/login";
    
        apiAxios.post(urlLoginAdmin, data)
          .then((response) => {
          
            if(response.data.auth){
            
                
                loginAdmin(response.data.tokenAdminLogado);
                props.callbackAdminIsLoged(true);
    
    
            }
            else{
              //Nao sucesso
              props.callbackAdminIsLoged(false);
              setTextBodyModal(response.data.message);
              setTitleTextBodyModal('Ops, Atenção !');
              setTitleModal('Verifique :');
              setModalShow(true);
    
            }
            
          })
          .catch((erro) => {
            //alert('ERRO: '+erro);
            setTitleModal('Seu Cadastro :');
            setTitleTextBodyModal('Oooops...');
            setTextBodyModal('Algo ocorreu, contate o Administrador.');
            setModalShow(true);
          });
    
       
      };

      return (
        <section className="my-4">
             <MyVerticallyCenteredModal
     show={modalShow}
     onHide={() => setModalShow(false)}
     textBodyModal={textBodyModal}
     titleTextBodyModal={titleTextBodyModal}
     titleModal={titleModal}
   /> 
        <Container>
        <h1 className="mb-5">Potencialize o Negócio dos seus clientes com a GeoGet: Marketing de Geolocalização e <b>Inteligência Artificial</b> para Estabelecimentos que vendem Cervejas e Vinhos</h1>
        <Row>
          <Col md={6}>
            <h2>Login</h2>
            <Form onSubmit={handleLoginSubmit(onLoginSubmit)}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Controller
                  name="emailLogin"
                  control={controlLogin}
                  render={({ field }) => (
                    <Form.Control
                      type="email"
                      placeholder="Digite seu email"
                      isInvalid={!!errorsLogin.emailLogin}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsLogin.emailLogin?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Senha</Form.Label>
                <Controller
                  name="passwordLogin"
                  control={controlLogin}
                  render={({ field }) => (
                    <Form.Control
                      type="password"
                      placeholder="Digite sua senha"
                      isInvalid={!!errorsLogin.passwordLogin}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsLogin.passwordLogin?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit">Entrar</Button>
            </Form>
          </Col>
          
        </Row>
      </Container>
      </section>
    );

}

export default SectionLoginAdmin;