const HeaderPageCadastroLoginEstabelecimentos = () => {
    return (
        <header className="mastheadPageCadastroLoginEstabelecimentos img-fluid mb-5">
            <div className="container position-relative">
                <div className="row justify-content-center">
                    <div className="col-xl-6">
                        <div className="text-center text-white">
                            
                            <h1 className="mb-5">Cadastre seu Estabelecimento e atraia clientes sedentos ao seu redor! É Grátis</h1>
                           
                          
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default HeaderPageCadastroLoginEstabelecimentos;