export const modelosCarros = 
[
    {
        "codigo":"6",
        "nome":"Audi",
        "modelos":[
         {
            "codigo":'',
            "nome":"Selecione o Modelo"
         },  
         {
               "codigo":43,
               "nome":"100 2.8 V6"
            },
            {
               "codigo":44,
               "nome":"100 2.8 V6 Avant"
            },
            {
               "codigo":45,
               "nome":"100 S-4 2.2 Avant Turbo"
            },
            {
               "codigo":46,
               "nome":"80 2.0"
            },
            {
               "codigo":47,
               "nome":"80 2.0 Avant"
            },
            {
               "codigo":48,
               "nome":"80 2.6/ 2.8"
            },
            {
               "codigo":49,
               "nome":"80 2.6/2.8 Avant"
            },
            {
               "codigo":50,
               "nome":"80 2.8 Cabriolet"
            },
            {
               "codigo":51,
               "nome":"80 S2 Avant"
            },
            {
               "codigo":5496,
               "nome":"A1 1.4 TFSI 122cv S-tronic 3p"
            },
            {
               "codigo":6293,
               "nome":"A1 2.0 TFSI Quattro 256cv 3p"
            },
            {
               "codigo":6143,
               "nome":"A1 Sport 1.4 TFSI 185cv 3p S-tronic"
            },
            {
               "codigo":7727,
               "nome":"A1 Sport. S Edition 1.4 TFSI 5p S-tronic"
            },
            {
               "codigo":6201,
               "nome":"A1 Sportback 1.4 TFSI 185cv 5p S-tronic"
            },
            {
               "codigo":6200,
               "nome":"A1 Sportback 1.4 TFSI 5p S-tronic"
            },
            {
               "codigo":7418,
               "nome":"A1 Sportback 1.8 TFSI 192cv 5p S-tronic"
            },
            {
               "codigo":52,
               "nome":"A3 1.6 3p"
            },
            {
               "codigo":53,
               "nome":"A3 1.6 3p Aut."
            },
            {
               "codigo":54,
               "nome":"A3 1.6 5p"
            },
            {
               "codigo":55,
               "nome":"A3 1.6 5p Aut."
            },
            {
               "codigo":4154,
               "nome":"A3 1.6 8V 102cv 3p"
            },
            {
               "codigo":56,
               "nome":"A3 1.8 3p"
            },
            {
               "codigo":57,
               "nome":"A3 1.8 3p Aut."
            },
            {
               "codigo":58,
               "nome":"A3 1.8 5p Aut."
            },
            {
               "codigo":59,
               "nome":"A3 1.8 5p Mec."
            },
            {
               "codigo":60,
               "nome":"A3 1.8 Turbo 180cv 3p Aut./ Tip."
            },
            {
               "codigo":61,
               "nome":"A3 1.8 Turbo 180cv 3p Mec."
            },
            {
               "codigo":62,
               "nome":"A3 1.8 Turbo 180cv 5p Aut./ Tip."
            },
            {
               "codigo":63,
               "nome":"A3 1.8 Turbo 180cv 5p Mec."
            },
            {
               "codigo":64,
               "nome":"A3 1.8 Turbo 3p Aut."
            },
            {
               "codigo":65,
               "nome":"A3 1.8 Turbo 3p Mec."
            },
            {
               "codigo":66,
               "nome":"A3 1.8 Turbo 5p Aut."
            },
            {
               "codigo":67,
               "nome":"A3 1.8 Turbo 5p Mec."
            },
            {
               "codigo":6950,
               "nome":"A3 Cabriolet 1.8 16V TFSI 180cv S-tronic"
            },
            {
               "codigo":8227,
               "nome":"A3 Cabriolet Ambition 2.0 TFSI S-tronic"
            },
            {
               "codigo":8559,
               "nome":"A3 Cabriolet Perfor. 2.0 TFSI S-tronic"
            },
            {
               "codigo":7419,
               "nome":"A3 Sed. Ambition 2.0 TSFI 220cv S-tronic"
            },
            {
               "codigo":8945,
               "nome":"A3 Sed. P. Plus Tech 1.4 Flex TFSI Tip."
            },
            {
               "codigo":8768,
               "nome":"A3 Sed. P.Plus 1.4 25Anos Flex TFSI Tip."
            },
            {
               "codigo":6689,
               "nome":"A3 Sed.1.8/1.8 Ambit.16V TB FSI S-tronic"
            },
            {
               "codigo":10195,
               "nome":"A3 Sed.Perf. Black 2.0 TFSI S-tr. (Hib.)"
            },
            {
               "codigo":7391,
               "nome":"A3 Sedan 1.4 TFSI Flex Tiptronic 4p"
            },
            {
               "codigo":6768,
               "nome":"A3 Sedan 1.4/ Attra. 16V TB FSI S-tronic"
            },
            {
               "codigo":8560,
               "nome":"A3 Sedan Performance 2.0 TFSI S-tronic"
            },
            {
               "codigo":8561,
               "nome":"A3 Sedan Prestige 1.4 TFSI Flex Tip."
            },
            {
               "codigo":8562,
               "nome":"A3 Sedan Prestige Plus 1.4 TFSI Flex Tip"
            },
            {
               "codigo":9963,
               "nome":"A3 Sedan S-Line 2.0 TFSI  S-Tron.(Híb.)"
            },
            {
               "codigo":9655,
               "nome":"A3 Sedan S-Line Limited 1.4 TFSI Tiptro."
            },
            {
               "codigo":6461,
               "nome":"A3 Sport 1.8 16V TFSI S-tronic 3p"
            },
            {
               "codigo":5125,
               "nome":"A3 Sport 2.0 16V TFSI S- tronic"
            },
            {
               "codigo":9964,
               "nome":"A3 Sportb. Perf. Black 2.0 S-tron.(Híb.)"
            },
            {
               "codigo":8563,
               "nome":"A3 Sportb. Prestige Plus 1.4 TFSI S-tron"
            },
            {
               "codigo":9965,
               "nome":"A3 Sportb. S-Line 2.0 TFSI S-Tron.(Híb.)"
            },
            {
               "codigo":9657,
               "nome":"A3 Sportb. S-Line Limited 1.4 TFSI Tip."
            },
            {
               "codigo":6510,
               "nome":"A3 Sportback 1.4 TFSI S-tronic"
            },
            {
               "codigo":4155,
               "nome":"A3 Sportback 1.6 8V 102cv 5p"
            },
            {
               "codigo":6511,
               "nome":"A3 Sportback 1.8 16V TFSI S-tronic 5p"
            },
            {
               "codigo":4156,
               "nome":"A3 Sportback 2.0 16V TFSI Mec."
            },
            {
               "codigo":4157,
               "nome":"A3 Sportback 2.0 16V TFSI S-tronic"
            },
            {
               "codigo":68,
               "nome":"A4 1.8  Aut."
            },
            {
               "codigo":69,
               "nome":"A4 1.8  Mec."
            },
            {
               "codigo":70,
               "nome":"A4 1.8 Avant Aut."
            },
            {
               "codigo":71,
               "nome":"A4 1.8 Avant Mec."
            },
            {
               "codigo":72,
               "nome":"A4 1.8 Avant Turbo Mec."
            },
            {
               "codigo":73,
               "nome":"A4 1.8 Avant Turbo Tip./ Multitronic"
            },
            {
               "codigo":74,
               "nome":"A4 1.8 Tip./ Multitronic Turbo"
            },
            {
               "codigo":75,
               "nome":"A4 1.8 Turbo"
            },
            {
               "codigo":4843,
               "nome":"A4 2.0 16V TFSI 183/180cv Multitronic"
            },
            {
               "codigo":4182,
               "nome":"A4 2.0 16V TFSI 200/ 214cv Multitronic"
            },
            {
               "codigo":6144,
               "nome":"A4 2.0 16V TFSI Quattro Stronic"
            },
            {
               "codigo":76,
               "nome":"A4 2.0 20V 130cv Multitronic 4p"
            },
            {
               "codigo":6145,
               "nome":"A4 2.0 Avant 16V TFSI Quattro Stronic"
            },
            {
               "codigo":7750,
               "nome":"A4 2.0 Avant Ambie. 2.0 16V TFSi S tron."
            },
            {
               "codigo":4844,
               "nome":"A4 2.0 Avant TFSI 183/180cv Multitroni"
            },
            {
               "codigo":4183,
               "nome":"A4 2.0 Avant TFSI 200/214cv Multitron."
            },
            {
               "codigo":77,
               "nome":"A4 2.4 30V Avant Mec."
            },
            {
               "codigo":78,
               "nome":"A4 2.4 30V Avant Tip./Multitronic"
            },
            {
               "codigo":79,
               "nome":"A4 2.4 30V Mec."
            },
            {
               "codigo":80,
               "nome":"A4 2.4 30V Tip./Multitronic"
            },
            {
               "codigo":81,
               "nome":"A4 2.4 Avant V6 30V Quattro Mec."
            },
            {
               "codigo":82,
               "nome":"A4 2.4 Avant V6 30V Quattro Tiptronic"
            },
            {
               "codigo":83,
               "nome":"A4 2.4 V6 30V Quattro Mec."
            },
            {
               "codigo":84,
               "nome":"A4 2.4 V6 30V Quattro Tiptronic"
            },
            {
               "codigo":85,
               "nome":"A4 2.8"
            },
            {
               "codigo":86,
               "nome":"A4 2.8 30V  Tiptronic/ Aut."
            },
            {
               "codigo":87,
               "nome":"A4 2.8 30V Mec."
            },
            {
               "codigo":88,
               "nome":"A4 2.8 Avant V6 12V"
            },
            {
               "codigo":89,
               "nome":"A4 2.8 Avant V6 30V Mec."
            },
            {
               "codigo":90,
               "nome":"A4 2.8 Avant V6 30V Quattro Mec."
            },
            {
               "codigo":91,
               "nome":"A4 2.8 Avant V6 30V Quattro Tiptronic"
            },
            {
               "codigo":92,
               "nome":"A4 2.8 Avant V6 30V Tiptronic"
            },
            {
               "codigo":93,
               "nome":"A4 2.8 V6 30V  Quattro Tiptronic"
            },
            {
               "codigo":94,
               "nome":"A4 2.8 V6 30V Quattro Mec."
            },
            {
               "codigo":95,
               "nome":"A4 3.0 30V 218cv Multitronic 4p"
            },
            {
               "codigo":96,
               "nome":"A4 3.0 30V 218cv Quattro Tiptron./Aut 4p"
            },
            {
               "codigo":97,
               "nome":"A4 3.0 Avant 30V 218cv Multitronic 5p"
            },
            {
               "codigo":98,
               "nome":"A4 3.0 Avant 30V 218cv Quattro Tiptronic"
            },
            {
               "codigo":99,
               "nome":"A4 3.0 Cabriolet 30V 218cv Multitronic"
            },
            {
               "codigo":4286,
               "nome":"A4 3.2 FSI 24V 255cv Multitronic"
            },
            {
               "codigo":4731,
               "nome":"A4 3.2 FSI 24V 269cv Quattro Tiptronic"
            },
            {
               "codigo":4287,
               "nome":"A4 3.2 FSI Avant 24V 255cv Multitronic"
            },
            {
               "codigo":4935,
               "nome":"A4 3.2 FSI Avant 24V Quattro S Tronic"
            },
            {
               "codigo":4288,
               "nome":"A4 3.2 FSI Cabriolet 24V 255cv Multitron"
            },
            {
               "codigo":7717,
               "nome":"A4 Ambiente 2.0 TFSI 190cv S tronic"
            },
            {
               "codigo":7894,
               "nome":"A4 Ambition 2.0 TFSI Quattro S tronic"
            },
            {
               "codigo":7656,
               "nome":"A4 Attraction 2.0 TFSI 190cv S tronic"
            },
            {
               "codigo":8228,
               "nome":"A4 Avant Lim. Ed. 2.0 TFSI S tronic"
            },
            {
               "codigo":8769,
               "nome":"A4 Avant Prest. Plus 2.0 TFSI S-tronic"
            },
            {
               "codigo":7537,
               "nome":"A4 Launch Edition 2.0 TFSI 190cv S troni"
            },
            {
               "codigo":8229,
               "nome":"A4 Limited Edition 2.0 TFSI S tronic"
            },
            {
               "codigo":9223,
               "nome":"A4 Perfor. Black 2.0 TFSI Quat. S tronic"
            },
            {
               "codigo":9894,
               "nome":"A4 Prestige 2.0 TFSI S-tronic"
            },
            {
               "codigo":8771,
               "nome":"A4 Prestige Plus 2.0 TFSI 190cv S tronic"
            },
            {
               "codigo":9824,
               "nome":"A4 S Line 2.0  TFSI 204cv S Tonic"
            },
            {
               "codigo":4798,
               "nome":"A5 3.2 FSI 24V 269cv Multitronic"
            },
            {
               "codigo":5126,
               "nome":"A5 3.2 FSI V6 24V 269cv Quattro Tiptroni"
            },
            {
               "codigo":7999,
               "nome":"A5 Ambiente Sportb. 2.0 TFSI S tonic"
            },
            {
               "codigo":8000,
               "nome":"A5 Ambit. Plus Sport. 2.0 TFSI S tronic"
            },
            {
               "codigo":8001,
               "nome":"A5 Ambit. Sport. 2.0 TFSI S tronic"
            },
            {
               "codigo":8002,
               "nome":"A5 Attraction Sportb. 2.0 TFSI S tronic"
            },
            {
               "codigo":6082,
               "nome":"A5 Cabriolet 2.0 TFSI Quattro Stronic"
            },
            {
               "codigo":6083,
               "nome":"A5 Coupê 2.0 TFSI Quattro Stronic"
            },
            {
               "codigo":6084,
               "nome":"A5 Sportb. 2.0 16V TFSI Quat. S-tronic"
            },
            {
               "codigo":10652,
               "nome":"A5 Sportb. Carbon Ed. 2.0 TFSI S-tronic"
            },
            {
               "codigo":8564,
               "nome":"A5 Sportb. Perf. 2.0 TFSI Quat. S-tronic"
            },
            {
               "codigo":8565,
               "nome":"A5 Sportb. Prestige 2.0 16V TFSI S-troni"
            },
            {
               "codigo":8566,
               "nome":"A5 Sportb.Perf.B. 2.0 TFSI Quat. S-troni"
            },
            {
               "codigo":8567,
               "nome":"A5 Sportb.Prestige Plus 2.0 TFSI S-tronc"
            },
            {
               "codigo":7022,
               "nome":"A5 Sportback 1.8 TFSI 170cv Multi."
            },
            {
               "codigo":6043,
               "nome":"A5 Sportback 2.0 16V TFSI 180cv Multi."
            },
            {
               "codigo":5234,
               "nome":"A5 Sportback 2.0 16V TFSI 211cv Multi."
            },
            {
               "codigo":8772,
               "nome":"A5 Sportback S-Line 2.0 TFSI S-tronic"
            },
            {
               "codigo":7254,
               "nome":"A6 2.0 TFSI 252cv S tronic 4p"
            },
            {
               "codigo":100,
               "nome":"A6 2.4 30V Mec"
            },
            {
               "codigo":101,
               "nome":"A6 2.4 30V Tip./Multitronic"
            },
            {
               "codigo":102,
               "nome":"A6 2.4 Avant 30V Mec."
            },
            {
               "codigo":103,
               "nome":"A6 2.4 Avant 30V Tip./ Multitronic"
            },
            {
               "codigo":104,
               "nome":"A6 2.4 Avant V6 30V Quattro Mec."
            },
            {
               "codigo":105,
               "nome":"A6 2.4 Avant V6 30V Quattro Tiptronic"
            },
            {
               "codigo":106,
               "nome":"A6 2.4 V6 30V Quattro Mec"
            },
            {
               "codigo":107,
               "nome":"A6 2.4 V6 30V Quattro Tiptronic"
            },
            {
               "codigo":108,
               "nome":"A6 2.7 30V Avant Tiptronic"
            },
            {
               "codigo":109,
               "nome":"A6 2.7 30V Mec."
            },
            {
               "codigo":110,
               "nome":"A6 2.7 Avant Quattro Tiptronic"
            },
            {
               "codigo":111,
               "nome":"A6 2.7 Quattro Tiptronic"
            },
            {
               "codigo":112,
               "nome":"A6 2.8"
            },
            {
               "codigo":113,
               "nome":"A6 2.8 30V Mec."
            },
            {
               "codigo":114,
               "nome":"A6 2.8 30V Tiptronic"
            },
            {
               "codigo":115,
               "nome":"A6 2.8 Avant"
            },
            {
               "codigo":116,
               "nome":"A6 2.8 Avant 30V Mec."
            },
            {
               "codigo":117,
               "nome":"A6 2.8 Avant 30V Tiptronic"
            },
            {
               "codigo":118,
               "nome":"A6 2.8 Avant V6 30V Quattro Mec."
            },
            {
               "codigo":119,
               "nome":"A6 2.8 Avant V6 30V Quattro Tiptronic"
            },
            {
               "codigo":120,
               "nome":"A6 2.8 V6 30V Quattro Mec."
            },
            {
               "codigo":121,
               "nome":"A6 2.8 V6 30V Quattro Tiptronic"
            },
            {
               "codigo":122,
               "nome":"A6 3.0 Avant V6 30V 218cv Multitronic 5p"
            },
            {
               "codigo":5374,
               "nome":"A6 3.0 Avant. TB FSI V6 Quattro Tip. 5p"
            },
            {
               "codigo":6413,
               "nome":"A6 3.0 Avant.TFSI V6 Quattro S tronic 5p"
            },
            {
               "codigo":4958,
               "nome":"A6 3.0 TB FSI V6 Quattro Tiptronic"
            },
            {
               "codigo":6414,
               "nome":"A6 3.0 TFSI V6 Quattro S tronic 4p"
            },
            {
               "codigo":123,
               "nome":"A6 3.0 V6 30V 218cv Multitronic 4p"
            },
            {
               "codigo":4526,
               "nome":"A6 3.2 FSI 24V 255cv Multitronic"
            },
            {
               "codigo":124,
               "nome":"A6 4.2 Avant Quattro Tiptronic"
            },
            {
               "codigo":125,
               "nome":"A6 4.2 Quattro Tiptronic"
            },
            {
               "codigo":6462,
               "nome":"A6 Allroad 3.0 TFSI V6 Quattro S tronic"
            },
            {
               "codigo":9298,
               "nome":"A6 Perf. Black 3.0 TFSI Quattro S-tronic"
            },
            {
               "codigo":8814,
               "nome":"A6 Performance 3.0 TFSI Quattro S.tronic"
            },
            {
               "codigo":9451,
               "nome":"A6 Prestige Plus 2.0 TFSI Quattro S-tro."
            },
            {
               "codigo":9319,
               "nome":"A7 SP.b. Perf. Bla. 3.0 TFSI Quat. S-tro"
            },
            {
               "codigo":7447,
               "nome":"A7 Sportback 2.0 TFSI S Tronic"
            },
            {
               "codigo":5549,
               "nome":"A7 Sportback 3.0 TFSI Quattro S Tronic"
            },
            {
               "codigo":8815,
               "nome":"A7 Sportback Perf. 3.0 TFSI S-tron Quat."
            },
            {
               "codigo":7127,
               "nome":"A8 3.0 TFSI 310cv Quattro Tiptronic 4p"
            },
            {
               "codigo":6631,
               "nome":"A8 4.0 TFSI Quattro Tiptronic"
            },
            {
               "codigo":126,
               "nome":"A8 4.2 Quattro Tiptronic"
            },
            {
               "codigo":127,
               "nome":"A8 4.2 V8 32V Tiptronic"
            },
            {
               "codigo":3928,
               "nome":"A8 6.0 W12 48V 450cv Quattro Tiptronic"
            },
            {
               "codigo":5717,
               "nome":"A8 6.3 W12 48V Quattro Tiptronic"
            },
            {
               "codigo":128,
               "nome":"Allroad 2.7 30V Quattro Turbo Tiptronic"
            },
            {
               "codigo":129,
               "nome":"Avant RS2"
            },
            {
               "codigo":9077,
               "nome":"E-TRON Performa. Black Aut. (Elétrico)"
            },
            {
               "codigo":9078,
               "nome":"E-TRON Performace Aut. (Elétrico)"
            },
            {
               "codigo":9605,
               "nome":"E-TRON S Sportback Quat. Aut. (Elétrico)"
            },
            {
               "codigo":9186,
               "nome":"E-TRON Sportb. Perf.Black Aut.(Elétrico)"
            },
            {
               "codigo":9187,
               "nome":"E-TRON Sportback Perf. Aut. (Elétrico)"
            },
            {
               "codigo":7255,
               "nome":"Q3 1.4 TFSI/TFSI Flex S-tronic 5p"
            },
            {
               "codigo":6044,
               "nome":"Q3 2.0 TFSI Quat. 170/180cv S-tronic 5p"
            },
            {
               "codigo":6045,
               "nome":"Q3 2.0 TFSI Quat. 211/220cv S-tronic 5p"
            },
            {
               "codigo":8230,
               "nome":"Q3 Black Ed. 1.4 TFSI Flex/Black S-tron."
            },
            {
               "codigo":9304,
               "nome":"Q3 Black S Line 1.4 TFSI S-tronic"
            },
            {
               "codigo":8569,
               "nome":"Q3 P. Plus 1.4 TFSI Flex/P.Plus S-tronic"
            },
            {
               "codigo":9796,
               "nome":"Q3 Perf. Black 2.0 TFSI Tiptr. Quattro"
            },
            {
               "codigo":9871,
               "nome":"Q3 Performance 2.0 TFSI Tiptr. Quattro"
            },
            {
               "codigo":8568,
               "nome":"Q3 Prest. 1.4 TFSI Flex/Prest. S-tronic"
            },
            {
               "codigo":10111,
               "nome":"Q3 Prestige 2.0 TFSI Tiptr.Quatro "
            },
            {
               "codigo":9835,
               "nome":"Q3 SPB Perf. Black 2.0 TFSI Tipt. Quat."
            },
            {
               "codigo":9836,
               "nome":"Q3 SPB Performance 2.0 TFSI Tipt. Quatt."
            },
            {
               "codigo":6573,
               "nome":"Q5 2.0 16V TFSI 225cv Quattro Tiptronic"
            },
            {
               "codigo":4936,
               "nome":"Q5 2.0 16V TFSI Quattro S Tronic"
            },
            {
               "codigo":8231,
               "nome":"Q5 2.0 TFSI Quattro S tronic (Blindado)"
            },
            {
               "codigo":6486,
               "nome":"Q5 3.0 V6 TFSI 272cv Quattro Tiptronic"
            },
            {
               "codigo":4959,
               "nome":"Q5 3.2 FSI V6 Quattro S Tronic"
            },
            {
               "codigo":8036,
               "nome":"Q5 Ambiente 2.0 TFSI Quattro S tronic"
            },
            {
               "codigo":8037,
               "nome":"Q5 Ambition 2.0 TFSI Quattro S tronic"
            },
            {
               "codigo":8038,
               "nome":"Q5 Attraction 2.0 TFSI Quattro S tronic"
            },
            {
               "codigo":8583,
               "nome":"Q5 Black 2.0 TFSI Quattro S tronic"
            },
            {
               "codigo":10045,
               "nome":"Q5 Perf. Black 2.0 TFSIe S.Tr. Qt (Hib.)"
            },
            {
               "codigo":10046,
               "nome":"Q5 Performance 2.0 TFSIe S.Tr. Qt (Hib.)"
            },
            {
               "codigo":8584,
               "nome":"Q5 Prestige 2.0 TFSI Quattro S tronic"
            },
            {
               "codigo":8585,
               "nome":"Q5 Prestige Plus 2.0 TFSI Quat. S tronic"
            },
            {
               "codigo":10047,
               "nome":"Q5 SB P Black. 2.0 TFSIe S.Tr. Qt (Hib.)"
            },
            {
               "codigo":10048,
               "nome":"Q5 SB Perf. 2.0 TFSIe S.Tr. Qt (Hib.)"
            },
            {
               "codigo":8773,
               "nome":"Q5 S-Line 2.0 TFSI Quattro S-tronic"
            },
            {
               "codigo":9486,
               "nome":"Q5 S-Line Black 2.0 TFSI Quat. S-Tronic"
            },
            {
               "codigo":9526,
               "nome":"Q5 SPB. S-Line Black 2.0 TFSI Qua.S-Tron"
            },
            {
               "codigo":9527,
               "nome":"Q5 Sportback S-Line 2.0 TFSI Quat.S-Tron"
            },
            {
               "codigo":7917,
               "nome":"Q7 3.0 V6 TDI Quat.Tip. 5p Die./Perfor."
            },
            {
               "codigo":10049,
               "nome":"Q7 3.0 V6 TFSI  Quat.Tip.5p/ Perf.(Híb.)"
            },
            {
               "codigo":4694,
               "nome":"Q7 3.6 V6 284cv Quattro Tiptronic"
            },
            {
               "codigo":4184,
               "nome":"Q7 4.2 V8 40V 350cv Quattro Tiptronic"
            },
            {
               "codigo":10050,
               "nome":"Q7 Perf. Black 3.0 TFSI Quat.Tip.(Híb.)"
            },
            {
               "codigo":8658,
               "nome":"Q7 Perf.Black 3.0 TDI Quat.Tip.5p Dies."
            },
            {
               "codigo":9205,
               "nome":"Q7 S-Line 3.0 V6 TFSI Quattro Tiptronic"
            },
            {
               "codigo":10051,
               "nome":"Q8 Perf.Black 3.0 TFSI Quat. S-tr.(Híb.)"
            },
            {
               "codigo":10052,
               "nome":"Q8 Perfo. 3.0 TFSI Quattro S-tron.(Híb.)"
            },
            {
               "codigo":5235,
               "nome":"R8 4.2 V8 426cv Quattro R-tronic"
            },
            {
               "codigo":5236,
               "nome":"R8 5.2 V10 Quattro R-tronic/S-tronic"
            },
            {
               "codigo":5974,
               "nome":"R8 GT 5.2 V10 560cv Quattro R-tronic"
            },
            {
               "codigo":5550,
               "nome":"R8 Spyder 5.2 Quattro R-tronic/S-tronic"
            },
            {
               "codigo":9606,
               "nome":"RS E-TRON GT Quattro Aut. (Elétrico)"
            },
            {
               "codigo":6865,
               "nome":"RS Q3 2.5 TFSI Quattro S-tronic 5p"
            },
            {
               "codigo":9292,
               "nome":"RS Q3 Sportback 2.5 TFSI Quattro S-tron."
            },
            {
               "codigo":9224,
               "nome":"RS Q8 4.0 Bi-TB V8 FSI Quattro Tiptronic"
            },
            {
               "codigo":8175,
               "nome":"RS3 Sedan 2.5 TFSI Quattro S-tronic"
            },
            {
               "codigo":5922,
               "nome":"RS3 Sportback 2.5 TFSI Quattro S-tronic"
            },
            {
               "codigo":130,
               "nome":"RS4 2.7 Avant V6 30V BI-TB Quattro 380cv"
            },
            {
               "codigo":8628,
               "nome":"RS4 2.9 Avant V6 TFSI Quattro Tiptronic"
            },
            {
               "codigo":6487,
               "nome":"RS4 4.2 Avant 32V FSI Quattro S-tronic"
            },
            {
               "codigo":5410,
               "nome":"RS5 4.2 FSI V8 450cv Quattro S-Tron. 2p"
            },
            {
               "codigo":8629,
               "nome":"RS5 Coupê 2.9 V6 TFSI Quattro Tiptronic"
            },
            {
               "codigo":10681,
               "nome":"RS5 SPB. C.Plus Track 2.9 Quat. S-tronic"
            },
            {
               "codigo":10682,
               "nome":"RS5 SPB. Compet. Plus 2.9 Quat. S-tronic"
            },
            {
               "codigo":9247,
               "nome":"RS5 Sportback 2.9 V6 TFSI Quattro Tip."
            },
            {
               "codigo":10227,
               "nome":"RS6 4.0 Avant TFSI BI-TB Quat.Tip.(Hib.)"
            },
            {
               "codigo":131,
               "nome":"RS6 4.2 450cv BI-TB Quattro Tiptronic 4p"
            },
            {
               "codigo":132,
               "nome":"RS6 4.2 Avant  BI-TB Quattro Tiptron. 5p"
            },
            {
               "codigo":5335,
               "nome":"RS6 5.0 Avant.FSI V10 Bi-TB Quattro Tip."
            },
            {
               "codigo":5336,
               "nome":"RS6 5.0 FSI V10 Bi-TB Quattro Tip."
            },
            {
               "codigo":6883,
               "nome":"RS7 Sportback 4.0 TFSI Quattro Tiptronic"
            },
            {
               "codigo":6769,
               "nome":"S Q5 3.0 V6 TFSI 354cv Quattro Tiptronic"
            },
            {
               "codigo":133,
               "nome":"S3 1.8 225cv Turbo Quattro"
            },
            {
               "codigo":6951,
               "nome":"S3 Sedan 2.0 TFSI Quattro 286cv S-tronic"
            },
            {
               "codigo":5127,
               "nome":"S3 Sportback 2.0 TFSI Quattro S Tronic"
            },
            {
               "codigo":134,
               "nome":"S4 2.7 Avant Turbo Quattro"
            },
            {
               "codigo":135,
               "nome":"S4 2.7 Turbo Quattro Mec."
            },
            {
               "codigo":6085,
               "nome":"S4 3.0 Avant TFSI V6 24V Quattro Stronic"
            },
            {
               "codigo":6086,
               "nome":"S4 3.0 TFSI V6 24V Quattro Stronic"
            },
            {
               "codigo":136,
               "nome":"S4 4.2 Avant V8 40V 344cv Quattro Tipt."
            },
            {
               "codigo":137,
               "nome":"S4 4.2 V8 40V 344cv Quattro Tiptronic"
            },
            {
               "codigo":6087,
               "nome":"S5 Cabriolet 3.0 TFSI Quattro Stronic"
            },
            {
               "codigo":6088,
               "nome":"S5 Coupê 3.0 TFSI Quattro Stronic"
            },
            {
               "codigo":6089,
               "nome":"S5 Sportback 3.0 TFSI Quattro Stronic"
            },
            {
               "codigo":138,
               "nome":"S6 2.2 20V TB"
            },
            {
               "codigo":139,
               "nome":"S6 2.2 Avant 20V TB"
            },
            {
               "codigo":7302,
               "nome":"S6 4.0 TFSI Quattro S-Tronic 4p"
            },
            {
               "codigo":140,
               "nome":"S6 4.2 Avant V8 40V Quattro Tiptronic"
            },
            {
               "codigo":141,
               "nome":"S6 4.2 V8 40V Quattro Mec"
            },
            {
               "codigo":142,
               "nome":"S6 4.2 V8 40V Quattro Tiptronic"
            },
            {
               "codigo":5186,
               "nome":"S6 5.2 Avant FSI V10 435cv Quattro Tiptr"
            },
            {
               "codigo":5187,
               "nome":"S6 5.2 FSI V10 435cv Quattro Tiptronic"
            },
            {
               "codigo":143,
               "nome":"S6 Plus 4.2 Avant V8 32V"
            },
            {
               "codigo":144,
               "nome":"S6 Plus 4.2 V8 32V"
            },
            {
               "codigo":6632,
               "nome":"S7 4.0 TFSI 420/450cv Quattro S-tronic"
            },
            {
               "codigo":7128,
               "nome":"S8 4.0 TFSI Quattro Tiptronic 4p"
            },
            {
               "codigo":145,
               "nome":"S8 4.2 Mec"
            },
            {
               "codigo":146,
               "nome":"S8 4.2 Quattro Mec."
            },
            {
               "codigo":147,
               "nome":"S8 4.2 Quattro Tiptronic"
            },
            {
               "codigo":148,
               "nome":"S8 4.2 Tiptronic"
            },
            {
               "codigo":149,
               "nome":"TT 1.8 TB 180cv"
            },
            {
               "codigo":150,
               "nome":"TT 1.8 TB Quattro 225cv"
            },
            {
               "codigo":4330,
               "nome":"TT 2.0 16v TFSI S-Tronic"
            },
            {
               "codigo":151,
               "nome":"TT Roadster 1.8 TB Quattro 225cv (Conv.)"
            },
            {
               "codigo":4431,
               "nome":"TT Roadster 2.0 16v TFSI S-Tronic"
            },
            {
               "codigo":6258,
               "nome":"TTRS 2.5 TFSI QUATTRO S Tronic"
            },
            {
               "codigo":6259,
               "nome":"TTRS Roadster 2.5 TB FSI Quat. S Tronic"
            },
            {
               "codigo":5128,
               "nome":"TTS 2.0 TFSI QUATTRO S Tronic"
            },
            {
               "codigo":5129,
               "nome":"TTS Roadster 2.0 TB FSI Quattro S Tronic"
            }
         ],
         "anos":[
            {
               "codigo":"32000-1",
               "nome":"32000 Gasolina"
            },
            {
               "codigo":"2023-1",
               "nome":"2023 Gasolina"
            },
            {
               "codigo":"2022-1",
               "nome":"2022 Gasolina"
            },
            {
               "codigo":"2021-1",
               "nome":"2021 Gasolina"
            },
            {
               "codigo":"2020-1",
               "nome":"2020 Gasolina"
            },
            {
               "codigo":"2019-1",
               "nome":"2019 Gasolina"
            },
            {
               "codigo":"2019-3",
               "nome":"2019 Diesel"
            },
            {
               "codigo":"2018-1",
               "nome":"2018 Gasolina"
            },
            {
               "codigo":"2018-3",
               "nome":"2018 Diesel"
            },
            {
               "codigo":"2017-1",
               "nome":"2017 Gasolina"
            },
            {
               "codigo":"2017-3",
               "nome":"2017 Diesel"
            },
            {
               "codigo":"2016-1",
               "nome":"2016 Gasolina"
            },
            {
               "codigo":"2015-1",
               "nome":"2015 Gasolina"
            },
            {
               "codigo":"2014-1",
               "nome":"2014 Gasolina"
            },
            {
               "codigo":"2013-1",
               "nome":"2013 Gasolina"
            },
            {
               "codigo":"2012-1",
               "nome":"2012 Gasolina"
            },
            {
               "codigo":"2011-1",
               "nome":"2011 Gasolina"
            },
            {
               "codigo":"2010-1",
               "nome":"2010 Gasolina"
            },
            {
               "codigo":"2009-1",
               "nome":"2009 Gasolina"
            },
            {
               "codigo":"2008-1",
               "nome":"2008 Gasolina"
            },
            {
               "codigo":"2007-1",
               "nome":"2007 Gasolina"
            },
            {
               "codigo":"2006-1",
               "nome":"2006 Gasolina"
            },
            {
               "codigo":"2005-1",
               "nome":"2005 Gasolina"
            },
            {
               "codigo":"2004-1",
               "nome":"2004 Gasolina"
            },
            {
               "codigo":"2003-1",
               "nome":"2003 Gasolina"
            },
            {
               "codigo":"2002-1",
               "nome":"2002 Gasolina"
            },
            {
               "codigo":"2001-1",
               "nome":"2001 Gasolina"
            },
            {
               "codigo":"2000-1",
               "nome":"2000 Gasolina"
            },
            {
               "codigo":"1999-1",
               "nome":"1999 Gasolina"
            },
            {
               "codigo":"1998-1",
               "nome":"1998 Gasolina"
            },
            {
               "codigo":"1997-1",
               "nome":"1997 Gasolina"
            },
            {
               "codigo":"1996-1",
               "nome":"1996 Gasolina"
            },
            {
               "codigo":"1995-1",
               "nome":"1995 Gasolina"
            },
            {
               "codigo":"1994-1",
               "nome":"1994 Gasolina"
            },
            {
               "codigo":"1993-1",
               "nome":"1993 Gasolina"
            }
         ]
    },
    {
        "codigo":"7",
        "nome":"BMW",
        "modelos":[
            {
               "codigo":6146,
               "nome":"116iA 1.6 TB 16V 136cv 5p"
            },
            {
               "codigo":9955,
               "nome":"118i M Sport 1.5 TB 12V Aut. 5p"
            },
            {
               "codigo":8946,
               "nome":"118i Sport 1.5 TB 12V Aut. 5p"
            },
            {
               "codigo":5576,
               "nome":"118iA 2.0 16V 136cv 3p"
            },
            {
               "codigo":4960,
               "nome":"118iA 2.0 16V 136cv 5p"
            },
            {
               "codigo":6147,
               "nome":"118iA Full 1.6 TB 16V 170cv 5p"
            },
            {
               "codigo":5923,
               "nome":"118iA/ Urban/Sport 1.6 TB 16V 170cv 5p"
            },
            {
               "codigo":152,
               "nome":"120i 2.0 16V 150cv/ 156cv 5p"
            },
            {
               "codigo":153,
               "nome":"120iA 2.0 16V 150cv/ 156cv 5p"
            },
            {
               "codigo":4700,
               "nome":"120iA 2.0 16V 156cv 3p"
            },
            {
               "codigo":4683,
               "nome":"120iA Cabrio 2.0 16V 156cv 2p"
            },
            {
               "codigo":7178,
               "nome":"120iA Sport 2.0/ActiveFlex 16V Aut."
            },
            {
               "codigo":6377,
               "nome":"125i M Sport/Active Flex 2.0 TB Aut. 5p"
            },
            {
               "codigo":3879,
               "nome":"130i 3.0 24V 265cv 5p"
            },
            {
               "codigo":4473,
               "nome":"130iA 3.0 24V 265cv 3p"
            },
            {
               "codigo":4199,
               "nome":"130iA 3.0 24V 265cv 5p"
            },
            {
               "codigo":4961,
               "nome":"135iA Coupé 3.0 24V 306cv"
            },
            {
               "codigo":9036,
               "nome":"218i Gran Coupe Sport GP 1.5 Bi-Turbo"
            },
            {
               "codigo":7448,
               "nome":"220i Tourer Active Flex 2.0 TB Aut."
            },
            {
               "codigo":7129,
               "nome":"225i Active Tourer Sport 2.0 TB Aut."
            },
            {
               "codigo":154,
               "nome":"316 (todas)"
            },
            {
               "codigo":6660,
               "nome":"316i 1.6 TB 16V 136cv 4p"
            },
            {
               "codigo":155,
               "nome":"318i Cabrio 1.8 16V"
            },
            {
               "codigo":156,
               "nome":"318i/iA 1.8 16V"
            },
            {
               "codigo":157,
               "nome":"318i/iA Compact 1.8 16V"
            },
            {
               "codigo":5607,
               "nome":"318iA 2.0 16V 136cv 5p"
            },
            {
               "codigo":158,
               "nome":"318iS/ISA 1.9 16V"
            },
            {
               "codigo":159,
               "nome":"318Ti Compact Mec"
            },
            {
               "codigo":160,
               "nome":"318Ti/TiA Compact 1.9 16V"
            },
            {
               "codigo":161,
               "nome":"320i"
            },
            {
               "codigo":162,
               "nome":"320iA"
            },
            {
               "codigo":7256,
               "nome":"320iA 2.0 TB M Sport A.Flex/M.Sport 4p"
            },
            {
               "codigo":6090,
               "nome":"320iA 2.0 Turbo/ActiveFlex 16V/GP  4p"
            },
            {
               "codigo":6609,
               "nome":"320iA GT Sport 2.0 Turbo 16V 184cv 5p"
            },
            {
               "codigo":6091,
               "nome":"320iA Modern/Sport TB 2.0/A.Flex/GP 4p"
            },
            {
               "codigo":163,
               "nome":"323Ci Coupê"
            },
            {
               "codigo":164,
               "nome":"323CiA Coupê"
            },
            {
               "codigo":165,
               "nome":"323i 2.5 24V"
            },
            {
               "codigo":166,
               "nome":"323i Confort"
            },
            {
               "codigo":167,
               "nome":"323i Sport"
            },
            {
               "codigo":168,
               "nome":"323i Touring"
            },
            {
               "codigo":172,
               "nome":"323i/iA Exclusive"
            },
            {
               "codigo":169,
               "nome":"323iA 2.5 24V"
            },
            {
               "codigo":170,
               "nome":"323iA Confort"
            },
            {
               "codigo":171,
               "nome":"323iA Entry Sedan"
            },
            {
               "codigo":173,
               "nome":"323iA Sport"
            },
            {
               "codigo":174,
               "nome":"323iA Top Sedan"
            },
            {
               "codigo":175,
               "nome":"323iA Touring"
            },
            {
               "codigo":176,
               "nome":"323Ti Compact"
            },
            {
               "codigo":177,
               "nome":"323Ti Compact Sport"
            },
            {
               "codigo":178,
               "nome":"323TiA Compact Top"
            },
            {
               "codigo":179,
               "nome":"325i"
            },
            {
               "codigo":180,
               "nome":"325i/iA Cabrio"
            },
            {
               "codigo":181,
               "nome":"325iA"
            },
            {
               "codigo":182,
               "nome":"325iA 2.5 24V Protection"
            },
            {
               "codigo":183,
               "nome":"325iA Coupé 2.5 24V 2p"
            },
            {
               "codigo":3955,
               "nome":"325iA Touring"
            },
            {
               "codigo":184,
               "nome":"328i Exclusive 2.8 24V"
            },
            {
               "codigo":185,
               "nome":"328i Touring/Sport"
            },
            {
               "codigo":186,
               "nome":"328i/iA (modelo antigo)"
            },
            {
               "codigo":187,
               "nome":"328i/iA (novo modelo)"
            },
            {
               "codigo":188,
               "nome":"328i/iA Cabrio"
            },
            {
               "codigo":6046,
               "nome":"328iA 2.0 TB/2.0 TB Flex 16V 4p"
            },
            {
               "codigo":189,
               "nome":"328iA Exclusive 2.8 24V"
            },
            {
               "codigo":6633,
               "nome":"328iA GT M Sport 2.0 Turbo 16V 245cv 5p"
            },
            {
               "codigo":6047,
               "nome":"328iA Luxury/Modern 2.0 TB 16V 4p"
            },
            {
               "codigo":7303,
               "nome":"328iA M Sport 2.0 16V Flex 4p"
            },
            {
               "codigo":6048,
               "nome":"328iA Plus 2.0 TB 16V 245cv 4p"
            },
            {
               "codigo":6049,
               "nome":"328iA Sport 2.0 16V/2.0 16V Flex 4p"
            },
            {
               "codigo":190,
               "nome":"328iA Touring/Sport"
            },
            {
               "codigo":191,
               "nome":"330Ci Cabriolet"
            },
            {
               "codigo":192,
               "nome":"330CiA Cabriolet"
            },
            {
               "codigo":9956,
               "nome":"330e M Sport 2.0 Turbo Aut. (Híbrido)"
            },
            {
               "codigo":8630,
               "nome":"330i M Sport 2.0 TB 16V 4p"
            },
            {
               "codigo":193,
               "nome":"330i MotorSport 3.0 24V 4p"
            },
            {
               "codigo":8688,
               "nome":"330i Sport 2.0 TB 16V 4p"
            },
            {
               "codigo":3956,
               "nome":"330iA Exclusive 3.0 24V 4p"
            },
            {
               "codigo":194,
               "nome":"330iA MotorSport 4p"
            },
            {
               "codigo":195,
               "nome":"330iA Top 4p"
            },
            {
               "codigo":9957,
               "nome":"335i 3.0 Active 3 (Híbrido)"
            },
            {
               "codigo":4272,
               "nome":"335iA 3.0 24V 306cv"
            },
            {
               "codigo":4416,
               "nome":"335iA Cabriolet 3.0 24V 306cv"
            },
            {
               "codigo":6513,
               "nome":"335iA M Sport 3.0 24V 306cv 4p"
            },
            {
               "codigo":6148,
               "nome":"335iA Sport 3.0 24V 306cv 4p"
            },
            {
               "codigo":9785,
               "nome":"420i Cabriolet M Sport 2.0 TB 16V 2p."
            },
            {
               "codigo":7083,
               "nome":"420i Cabriolet Sport 2.0 TB 184cv 2p"
            },
            {
               "codigo":6952,
               "nome":"428i Cabriolet Sport 2.0 TB 245cv 2p"
            },
            {
               "codigo":7084,
               "nome":"428i Gran Coupe M Sport 2.0 TB 245cv"
            },
            {
               "codigo":7085,
               "nome":"428i Gran Coupe Sport 2.0 TB 245cv"
            },
            {
               "codigo":7728,
               "nome":"430i Cab. Sport Limited Ed.2.0 TB 2p"
            },
            {
               "codigo":9442,
               "nome":"430i Cabriolet M Sport 2.0 TB 258cv 2p"
            },
            {
               "codigo":7875,
               "nome":"430i Cabriolet Sport 2.0 TB 252cv 2p"
            },
            {
               "codigo":7729,
               "nome":"430i Gran Coupé M Sport TB 5p"
            },
            {
               "codigo":6691,
               "nome":"435iA M Sport Coupe 3.0 24V 306cv 2p"
            },
            {
               "codigo":196,
               "nome":"520i 2.0 16V"
            },
            {
               "codigo":197,
               "nome":"525i/iA"
            },
            {
               "codigo":198,
               "nome":"525i/iA Touring"
            },
            {
               "codigo":199,
               "nome":"528i/iA"
            },
            {
               "codigo":6149,
               "nome":"528iA 2.0 Turbo 16V 245cv 4p"
            },
            {
               "codigo":200,
               "nome":"528iA High"
            },
            {
               "codigo":6514,
               "nome":"528iA M Sport 2.0 Turbo 16V 245cv 4p"
            },
            {
               "codigo":201,
               "nome":"528iA Touring"
            },
            {
               "codigo":9959,
               "nome":"530e Luxury 2.0 Turbo (Híbrido)"
            },
            {
               "codigo":9960,
               "nome":"530e M Sport 2.0 Turbo Aut. (Híbrido)"
            },
            {
               "codigo":7918,
               "nome":"530i M Sport 2.0 Turbo 252cv Aut."
            },
            {
               "codigo":8816,
               "nome":"530i Security"
            },
            {
               "codigo":202,
               "nome":"530i/iA"
            },
            {
               "codigo":203,
               "nome":"530i/iA Touring"
            },
            {
               "codigo":204,
               "nome":"535i/iA 3.5 24V"
            },
            {
               "codigo":5267,
               "nome":"535iA 3.0 24V 306cv Bi-Turbo"
            },
            {
               "codigo":5292,
               "nome":"535iA GT 3.0 306cv Bi-Turbo"
            },
            {
               "codigo":6415,
               "nome":"535iA M Sport 3.0 24V 306cv Bi-Turbo"
            },
            {
               "codigo":205,
               "nome":"540i"
            },
            {
               "codigo":7919,
               "nome":"540i M Sport 3.0 Turbo 340cv Aut."
            },
            {
               "codigo":206,
               "nome":"540i/iA Touring"
            },
            {
               "codigo":207,
               "nome":"540iA"
            },
            {
               "codigo":208,
               "nome":"540iA MotorSport"
            },
            {
               "codigo":209,
               "nome":"540iA Protection"
            },
            {
               "codigo":210,
               "nome":"540iTA"
            },
            {
               "codigo":211,
               "nome":"545iA 4.4 32V V8 333cv"
            },
            {
               "codigo":5268,
               "nome":"550iA 4.4 32V 407cv Bi-Turbo"
            },
            {
               "codigo":3880,
               "nome":"550iA 4.8 32V"
            },
            {
               "codigo":4995,
               "nome":"550iA Limited Sport Edition 4.8 32V"
            },
            {
               "codigo":4432,
               "nome":"550iA Security 4.8 32V"
            },
            {
               "codigo":6378,
               "nome":"640i Gran Coupe 3.0 320cv 4p"
            },
            {
               "codigo":3929,
               "nome":"645Ci Cabriolet 4.4 V8 32V 333cv"
            },
            {
               "codigo":212,
               "nome":"645iA 4.4 V8 32V 333cv"
            },
            {
               "codigo":5718,
               "nome":"650Ci Cabriolet 4.4 407cv Bi-Turbo"
            },
            {
               "codigo":4126,
               "nome":"650Ci Cabriolet 4.8 V8 32V 360cv"
            },
            {
               "codigo":6634,
               "nome":"650i Gran Coupe 4.4 V8 450cv 4p"
            },
            {
               "codigo":6150,
               "nome":"650iA 4.4 407cv Bi-Turbo"
            },
            {
               "codigo":4125,
               "nome":"650iA 4.8 V8 32V 360cv"
            },
            {
               "codigo":5057,
               "nome":"650iA Limited Sport  Edition 4.8 32V"
            },
            {
               "codigo":213,
               "nome":"730i 3.0 32V"
            },
            {
               "codigo":214,
               "nome":"730iA 3.0 32V"
            },
            {
               "codigo":215,
               "nome":"735i/iA 3.5 32V"
            },
            {
               "codigo":216,
               "nome":"740i"
            },
            {
               "codigo":217,
               "nome":"740iA"
            },
            {
               "codigo":218,
               "nome":"740iL/iLA Highline 4.4 32V"
            },
            {
               "codigo":219,
               "nome":"740iLA Protection"
            },
            {
               "codigo":220,
               "nome":"745iA 4.4 V8 32V 333cv"
            },
            {
               "codigo":9961,
               "nome":"745Le M Sport Sedan 3.0 V6 Aut. (Híb.)"
            },
            {
               "codigo":221,
               "nome":"750i"
            },
            {
               "codigo":7147,
               "nome":"750i M Sport Sedan 4.4 V8 450cv Aut."
            },
            {
               "codigo":222,
               "nome":"750iA"
            },
            {
               "codigo":223,
               "nome":"750iL Highline 5.4 24V"
            },
            {
               "codigo":7657,
               "nome":"750iL M Sport Sedan 4.4 V8 450cv Aut."
            },
            {
               "codigo":8660,
               "nome":"750iL Pure Excellence Sedan 4.4 V8 Aut."
            },
            {
               "codigo":9962,
               "nome":"750iLA 4.4 Active 7 (Híbrido)"
            },
            {
               "codigo":224,
               "nome":"760iL 6.0 V12 445cv/544cv"
            },
            {
               "codigo":225,
               "nome":"840Ci"
            },
            {
               "codigo":226,
               "nome":"840CiA"
            },
            {
               "codigo":227,
               "nome":"850Ci/CiA 5.0 24V"
            },
            {
               "codigo":228,
               "nome":"850Ci/CiA 5.4 24V"
            },
            {
               "codigo":229,
               "nome":"850CSi 5.6 24V"
            },
            {
               "codigo":230,
               "nome":"850i 5.0 24V"
            },
            {
               "codigo":8817,
               "nome":"i3 Bev 170cv Aut. (Elétrico)"
            },
            {
               "codigo":8818,
               "nome":"i3 Bev Connected 170cv Aut. (Elétrico)"
            },
            {
               "codigo":8819,
               "nome":"i3 Bev Full 170cv Aut. (Elétrico)"
            },
            {
               "codigo":9861,
               "nome":"i3 Bev Un. Forever 170cv Aut. (Életrico)"
            },
            {
               "codigo":7023,
               "nome":"i3 Rex E Drive 170cv Aut.(Elétrico)"
            },
            {
               "codigo":8734,
               "nome":"i3 Rex E Drive Connected Aut. (Elétrico)"
            },
            {
               "codigo":7024,
               "nome":"i3 Rex E Drive Full 170cv Aut.(Elétrico)"
            },
            {
               "codigo":10668,
               "nome":"i4 e-Drive 35 M Sport (Elétrico)"
            },
            {
               "codigo":10669,
               "nome":"i4 e-Drive 40 M Sport (Elétrico)"
            },
            {
               "codigo":10371,
               "nome":"i4 M50 (Elétrico)"
            },
            {
               "codigo":10019,
               "nome":"i8 e-Drive 1.5 TB 12V  Aut. (Híbrido)"
            },
            {
               "codigo":10020,
               "nome":"i8 Road. e-Drive 1.5 TB 12V Aut. (Híb.)"
            },
            {
               "codigo":9910,
               "nome":"iX 3 M Sport Aut.(Elétrico)"
            },
            {
               "codigo":9863,
               "nome":"iX XDrive 40 Aut. (Elétrico)"
            },
            {
               "codigo":9864,
               "nome":"iX XDrive 50 Sport Aut. (Elétrico)"
            },
            {
               "codigo":6416,
               "nome":"M 135i 3.0 24V 320cv"
            },
            {
               "codigo":9006,
               "nome":"M 135i XDRIVE 2.0 306cv Aut."
            },
            {
               "codigo":6910,
               "nome":"M 235i Coupe 3.0 24V 326cv"
            },
            {
               "codigo":231,
               "nome":"M Roadster"
            },
            {
               "codigo":5719,
               "nome":"M1 Coupe 3.0 24V 340cv"
            },
            {
               "codigo":7823,
               "nome":"M140i 3.0  24V 340cv 5p"
            },
            {
               "codigo":8439,
               "nome":"M2 Competition 3.0 Bi-Turbo 410cv Aut."
            },
            {
               "codigo":9103,
               "nome":"M2 Competition Ed. Espe. 3.0 Bi-TB Mec."
            },
            {
               "codigo":7611,
               "nome":"M2 Coupe 3.0 Bi-Turbo 24V 370cv"
            },
            {
               "codigo":9339,
               "nome":"M2 CS Coupe 3.0 Bi-Turbo 450cv"
            },
            {
               "codigo":9104,
               "nome":"M235i XDRIVE Gran Coupé 2.0 Aut."
            },
            {
               "codigo":7857,
               "nome":"M240i Coupe 3.0 24V 340cv 2p"
            },
            {
               "codigo":232,
               "nome":"M3 3.2 24V"
            },
            {
               "codigo":233,
               "nome":"M3 Cabrio 3.0 24V"
            },
            {
               "codigo":4919,
               "nome":"M3 Cabrio 4.0 32V"
            },
            {
               "codigo":9482,
               "nome":"M3 Competition M 3.0 Bi-TB 510cv"
            },
            {
               "codigo":9483,
               "nome":"M3 Competition M Track 3.0 Bi-TB"
            },
            {
               "codigo":234,
               "nome":"M3 Coupê 3.0 24V 255cv"
            },
            {
               "codigo":235,
               "nome":"M3 Coupê 3.0 V6 24V 282cv"
            },
            {
               "codigo":4527,
               "nome":"M3 Coupê 4.0 32V"
            },
            {
               "codigo":8661,
               "nome":"M3 CS Sedan 3.0 Bi-Turbo 460cv 4p"
            },
            {
               "codigo":7130,
               "nome":"M3 Sedan 3.0 Bi-Turbo 24V 4p"
            },
            {
               "codigo":4823,
               "nome":"M3 Sedan 4.0 32V"
            },
            {
               "codigo":9105,
               "nome":"M340i XDRIVE 3.0 Turbo Aut."
            },
            {
               "codigo":9094,
               "nome":"M340i XDRIVE First Ed. 3.0 Turbo Aut."
            },
            {
               "codigo":7148,
               "nome":"M4 Cabriolet 3.0 Bi-Turbo 24V 431cv 2p"
            },
            {
               "codigo":7116,
               "nome":"M4 Coupe 3.0 Bi-Turbo 24V 431cv 2p"
            },
            {
               "codigo":8314,
               "nome":"M4 CS Coupe 3.0 Bi-Turbo 460cv 2p"
            },
            {
               "codigo":9524,
               "nome":"M440i Coupe 3.0 TB Aut."
            },
            {
               "codigo":236,
               "nome":"M5 3.8/Touring 3.8 24V"
            },
            {
               "codigo":6260,
               "nome":"M5 4.4 560cv Bi-Turbo Aut."
            },
            {
               "codigo":237,
               "nome":"M5 5.0 32V/ 40V"
            },
            {
               "codigo":9484,
               "nome":"M5 Competition M XDRIVE 4.4 Bi-TB V8"
            },
            {
               "codigo":8388,
               "nome":"M5 M XDRIVE 4.4 Bi-TB V8"
            },
            {
               "codigo":8389,
               "nome":"M5 Perform. Pack M XDRIVE 4.4 Bi-TB V8"
            },
            {
               "codigo":238,
               "nome":"M6 5.0 V10 40V 507cv"
            },
            {
               "codigo":6460,
               "nome":"M6 Coupe 4.4 Bi-Turbo V8 32V"
            },
            {
               "codigo":6635,
               "nome":"M6 Gran Coupe 4.4 Bi-Turbo V8 32V 560cv"
            },
            {
               "codigo":8735,
               "nome":"M760Li xDrive 6.6 V12 585cv Aut."
            },
            {
               "codigo":9188,
               "nome":"M8 Gran Coupé Comp.First ED. 4.4 V8 32V"
            },
            {
               "codigo":9525,
               "nome":"M8 Gran Coupé Competition 4.4 V8 32V"
            },
            {
               "codigo":8736,
               "nome":"M850i XDrive Coupé 4.4 V8 530cv Aut."
            },
            {
               "codigo":5269,
               "nome":"X1 SDRIVE 18i 2.0 16V 4x2 Aut."
            },
            {
               "codigo":10414,
               "nome":"X1 SDRIVE 18i GP 1.5 TB Aut."
            },
            {
               "codigo":6230,
               "nome":"X1 SDRIVE 20i 2.0/2.0 TB Acti.Flex Aut."
            },
            {
               "codigo":9226,
               "nome":"X1 SDRIVE 20i M S. 2.0 25 Anos TB Flex"
            },
            {
               "codigo":10415,
               "nome":"X1 SDRIVE 20i M Sport 2.0 TB Aut."
            },
            {
               "codigo":9682,
               "nome":"X1 SDRIVE 20i M Sport 2.0 TB Flex Aut."
            },
            {
               "codigo":10115,
               "nome":"X1 SDRIVE 20i Outdoor 2.0.Flex Aut."
            },
            {
               "codigo":8570,
               "nome":"X1 SDRIVE 20i X-Line 2.0 TB Active Flex"
            },
            {
               "codigo":10416,
               "nome":"X1 SDRIVE 20i X-Line 2.0 TB Aut."
            },
            {
               "codigo":7449,
               "nome":"X1 XDRIVE 25i Sport 2.0/2.0 Flex Aut."
            },
            {
               "codigo":6151,
               "nome":"X1 XDRIVE 28i 2.0 Turbo 16V 4x4 Aut."
            },
            {
               "codigo":5158,
               "nome":"X1 XDRIVE 28i 3.0 24V 4x4 Aut."
            },
            {
               "codigo":7086,
               "nome":"X1 XDRIVE 28i Sport 2.0 ActiveFlex Aut."
            },
            {
               "codigo":8820,
               "nome":"X2 M35i 2.0 Turbo 306cv Aut."
            },
            {
               "codigo":8277,
               "nome":"X2 SDRIVE  20i M Sp/M Sp 2.0 TB Flex Aut"
            },
            {
               "codigo":8510,
               "nome":"X2 SDRIVE 18i 1.5 ActiveFlex 136cv Aut."
            },
            {
               "codigo":8338,
               "nome":"X2 SDRIVE 20i 2.0/2.0 TB A. Flex 16V Aut"
            },
            {
               "codigo":239,
               "nome":"X3 Family 2.5 24V 192cv/ 218cv"
            },
            {
               "codigo":240,
               "nome":"X3 Family 3.0 24V 231cv"
            },
            {
               "codigo":8842,
               "nome":"X3 M Competition 3.0 Bi-TB 510cv Aut."
            },
            {
               "codigo":9881,
               "nome":"X3 M40i 3.0 M Sport Edit. V6 Turbo Aut."
            },
            {
               "codigo":9797,
               "nome":"X3 M40i 3.0 Turbo V6  Aut."
            },
            {
               "codigo":4241,
               "nome":"X3 Sport 2.5 24V 192cv/ 218cv"
            },
            {
               "codigo":241,
               "nome":"X3 Sport 3.0 24V 231cv"
            },
            {
               "codigo":6417,
               "nome":"X3 XDRIVE 20i 2.0/X-Line Bi-TB Flex Aut."
            },
            {
               "codigo":6294,
               "nome":"X3 XDRIVE 28i 2.0 Turbo 245cv Aut."
            },
            {
               "codigo":5608,
               "nome":"X3 XDRIVE 28i 3.0 258cv"
            },
            {
               "codigo":10075,
               "nome":"X3 XDRIVE 30e M SP Launch Ed. TB (Híb.)"
            },
            {
               "codigo":10076,
               "nome":"X3 XDRIVE 30e M Sport Turbo Aut. (Híb.)"
            },
            {
               "codigo":10077,
               "nome":"X3 XDRIVE 30e Turbo Aut. (Híbrido)"
            },
            {
               "codigo":10078,
               "nome":"X3 XDRIVE 30e X-Line Turbo Aut. (Híb.)"
            },
            {
               "codigo":8233,
               "nome":"X3 XDRIVE 30i X-Line 2.0 Turbo 252cv Aut"
            },
            {
               "codigo":5497,
               "nome":"X3 XDRIVE 35i/M-Sport 3.0 306cv Bi-Turbo"
            },
            {
               "codigo":8985,
               "nome":"X4 M Competition 3.0 Bi-TB 510cv Aut."
            },
            {
               "codigo":9882,
               "nome":"X4 M40i 3.0 M Sport Edit V6 Turbo Aut."
            },
            {
               "codigo":9927,
               "nome":"X4 M40i 3.0 Turbo 388cv Aut."
            },
            {
               "codigo":7065,
               "nome":"X4 XDRIVE 28i X-Line 2.0 Turbo 245cv Aut"
            },
            {
               "codigo":8512,
               "nome":"X4 XDRIVE 30i M-Sport 2.0 Tb. 252cv Aut"
            },
            {
               "codigo":7066,
               "nome":"X4 XDRIVE 35i M-Sport 3.0 TB 306cv Aut."
            },
            {
               "codigo":242,
               "nome":"X5 3.0 4x4"
            },
            {
               "codigo":243,
               "nome":"X5 4.4 4x4"
            },
            {
               "codigo":244,
               "nome":"X5 4.8 4x4 V8 32V 360cv"
            },
            {
               "codigo":4585,
               "nome":"X5 Endurance 4.8is 4x4 V8 32V 355cv Aut."
            },
            {
               "codigo":5188,
               "nome":"X5 M 4.4 4x4 V8 32V Bi-Turbo Aut."
            },
            {
               "codigo":9535,
               "nome":"X5 M Competition 4.4 V8 Bi-TB Aut."
            },
            {
               "codigo":4242,
               "nome":"X5 Security 4.4 4x4 V8 32V"
            },
            {
               "codigo":5020,
               "nome":"X5 Security 4.8is 4x4 V8 32V 355cv Aut."
            },
            {
               "codigo":245,
               "nome":"X5 Sport 4.4 4x4 V8 32V"
            },
            {
               "codigo":4331,
               "nome":"X5 Sport 4.8 4x4 V8 32V 355cv"
            },
            {
               "codigo":7283,
               "nome":"X5 XDRIVE 30d 3.0 Diesel"
            },
            {
               "codigo":7087,
               "nome":"X5 XDRIVE 30d Full 3.0 258cv Diesel"
            },
            {
               "codigo":8662,
               "nome":"X5 XDRIVE 30d M Sport 3.0 265cv Dies Aut"
            },
            {
               "codigo":5356,
               "nome":"X5 XDRIVE 35i 3.0 306cv Bi-Turbo"
            },
            {
               "codigo":6911,
               "nome":"X5 XDRIVE 35i Full 3.0 306cv Bi-Turbo"
            },
            {
               "codigo":10079,
               "nome":"X5 XDRIVE 45e 3.0 M.Sport  Aut. (Híb.)"
            },
            {
               "codigo":5357,
               "nome":"X5 XDRIVE 50i 4.4  Bi-Turbo"
            },
            {
               "codigo":6710,
               "nome":"X5 XDRIVE 50i M Sport 4.4 Bi-Turbo"
            },
            {
               "codigo":5590,
               "nome":"X5 XDRIVE 50i Security 4.4 Bi-Turbo"
            },
            {
               "codigo":7117,
               "nome":"X5 XDRIVE M50d 3.0 Diesel"
            },
            {
               "codigo":9206,
               "nome":"X5 XDRIVE M50i 4.4 Bi-Turbo Aut."
            },
            {
               "codigo":5189,
               "nome":"X6 M 4.4 4x4 V8 32V Bi-Turbo Aut."
            },
            {
               "codigo":9565,
               "nome":"X6 M Competition 4.4 V8 BI-TB 625CV Aut."
            },
            {
               "codigo":4745,
               "nome":"X6 XDRIVE 35i 3.0 306cv Bi-Turbo"
            },
            {
               "codigo":9883,
               "nome":"X6 XDRIVE 40i Bl.Vermillion 3.0 Bi-Turbo"
            },
            {
               "codigo":9037,
               "nome":"X6 XDRIVE 40i M Sport 3.0 Bi-Turbo"
            },
            {
               "codigo":4746,
               "nome":"X6 XDRIVE 50i 4.4 407cv Bi-Turbo"
            },
            {
               "codigo":7088,
               "nome":"X6 XDRIVE 50i M Sport 4.4 Bi-Turbo"
            },
            {
               "codigo":9038,
               "nome":"X7 M50i 4.4 V8 Bi-TB Aut."
            },
            {
               "codigo":9362,
               "nome":"X7 M50i Dark Shadow 4.4 V8 Bi-TB Aut."
            },
            {
               "codigo":8776,
               "nome":"X7 XDRIVE 50i M Sport 4.4 V8 Bi-TB Aut."
            },
            {
               "codigo":246,
               "nome":"Z3 2.8 Aut."
            },
            {
               "codigo":247,
               "nome":"Z3 2.8 Mec."
            },
            {
               "codigo":248,
               "nome":"Z3 3.0 24V Roadster 2p"
            },
            {
               "codigo":249,
               "nome":"Z3 Roadster 1.9 Mec."
            },
            {
               "codigo":4887,
               "nome":"Z3 Roadster 2.8"
            },
            {
               "codigo":250,
               "nome":"Z3 Roadster M 3.2"
            },
            {
               "codigo":4243,
               "nome":"Z4 Coupé M 3.2 V6 24V 343cv"
            },
            {
               "codigo":8777,
               "nome":"Z4 M40i 3.0 340cv Aut."
            },
            {
               "codigo":4185,
               "nome":"Z4 Roadster 2.0 150cv 2p"
            },
            {
               "codigo":251,
               "nome":"Z4 Roadster 3.0 V6 24V Aut."
            },
            {
               "codigo":4244,
               "nome":"Z4 Roadster M 3.2 V6 24V 343cv"
            },
            {
               "codigo":6261,
               "nome":"Z4 Roadster sDRIVE 20i 2.0 16V 2p Aut."
            },
            {
               "codigo":4996,
               "nome":"Z4 Roadster sDRIVE 23i 2.5 24V 204cv 2p"
            },
            {
               "codigo":8689,
               "nome":"Z4 Roadster sDRIVE 30i MSport 2.0 TB Aut"
            },
            {
               "codigo":4997,
               "nome":"Z4 Roadster sDRIVE 35i 3.0 24V 306cv 2p"
            },
            {
               "codigo":252,
               "nome":"Z4 Roadster Sport 3.0 24V 231cv Aut."
            },
            {
               "codigo":253,
               "nome":"Z8 5.0 V8"
            }
         ],
         "anos":[
            {
               "codigo":"32000-1",
               "nome":"32000 Gasolina"
            },
            {
               "codigo":"2023-1",
               "nome":"2023 Gasolina"
            },
            {
               "codigo":"2022-1",
               "nome":"2022 Gasolina"
            },
            {
               "codigo":"2021-1",
               "nome":"2021 Gasolina"
            },
            {
               "codigo":"2020-1",
               "nome":"2020 Gasolina"
            },
            {
               "codigo":"2020-3",
               "nome":"2020 Diesel"
            },
            {
               "codigo":"2019-1",
               "nome":"2019 Gasolina"
            },
            {
               "codigo":"2019-3",
               "nome":"2019 Diesel"
            },
            {
               "codigo":"2018-1",
               "nome":"2018 Gasolina"
            },
            {
               "codigo":"2018-3",
               "nome":"2018 Diesel"
            },
            {
               "codigo":"2017-1",
               "nome":"2017 Gasolina"
            },
            {
               "codigo":"2017-3",
               "nome":"2017 Diesel"
            },
            {
               "codigo":"2016-1",
               "nome":"2016 Gasolina"
            },
            {
               "codigo":"2016-3",
               "nome":"2016 Diesel"
            },
            {
               "codigo":"2015-1",
               "nome":"2015 Gasolina"
            },
            {
               "codigo":"2015-3",
               "nome":"2015 Diesel"
            },
            {
               "codigo":"2014-1",
               "nome":"2014 Gasolina"
            },
            {
               "codigo":"2013-1",
               "nome":"2013 Gasolina"
            },
            {
               "codigo":"2012-1",
               "nome":"2012 Gasolina"
            },
            {
               "codigo":"2011-1",
               "nome":"2011 Gasolina"
            },
            {
               "codigo":"2010-1",
               "nome":"2010 Gasolina"
            },
            {
               "codigo":"2009-1",
               "nome":"2009 Gasolina"
            },
            {
               "codigo":"2008-1",
               "nome":"2008 Gasolina"
            },
            {
               "codigo":"2007-1",
               "nome":"2007 Gasolina"
            },
            {
               "codigo":"2006-1",
               "nome":"2006 Gasolina"
            },
            {
               "codigo":"2005-1",
               "nome":"2005 Gasolina"
            },
            {
               "codigo":"2004-1",
               "nome":"2004 Gasolina"
            },
            {
               "codigo":"2003-1",
               "nome":"2003 Gasolina"
            },
            {
               "codigo":"2002-1",
               "nome":"2002 Gasolina"
            },
            {
               "codigo":"2001-1",
               "nome":"2001 Gasolina"
            },
            {
               "codigo":"2000-1",
               "nome":"2000 Gasolina"
            },
            {
               "codigo":"1999-1",
               "nome":"1999 Gasolina"
            },
            {
               "codigo":"1998-1",
               "nome":"1998 Gasolina"
            },
            {
               "codigo":"1997-1",
               "nome":"1997 Gasolina"
            },
            {
               "codigo":"1996-1",
               "nome":"1996 Gasolina"
            },
            {
               "codigo":"1995-1",
               "nome":"1995 Gasolina"
            },
            {
               "codigo":"1994-1",
               "nome":"1994 Gasolina"
            },
            {
               "codigo":"1993-1",
               "nome":"1993 Gasolina"
            },
            {
               "codigo":"1992-1",
               "nome":"1992 Gasolina"
            },
            {
               "codigo":"1991-1",
               "nome":"1991 Gasolina"
            },
            {
               "codigo":"1990-1",
               "nome":"1990 Gasolina"
            },
            {
               "codigo":"1989-1",
               "nome":"1989 Gasolina"
            },
            {
               "codigo":"1988-1",
               "nome":"1988 Gasolina"
            },
            {
               "codigo":"1987-1",
               "nome":"1987 Gasolina"
            },
            {
               "codigo":"1986-1",
               "nome":"1986 Gasolina"
            }
         ]
    },
    {
        "codigo":"161",
        "nome":"Caoa Chery",
        "modelos":[
            {
               "codigo":9039,
               "nome":"ARRIZO 5 RT 1.5 Turbo Flex Aut."
            },
            {
               "codigo":9040,
               "nome":"ARRIZO 5 RTS 1.5 Turbo Flex Aut."
            },
            {
               "codigo":8513,
               "nome":"ARRIZO 5 RX 1.5 16V Turbo Flex Aut."
            },
            {
               "codigo":9262,
               "nome":"ARRIZO 5 RXS 1.5 Turbo Flex Aut."
            },
            {
               "codigo":8514,
               "nome":"ARRIZO 5 RXT 1.5 16V Turbo Flex Aut."
            },
            {
               "codigo":9299,
               "nome":"ARRIZO 5e (Elétrico)"
            },
            {
               "codigo":9628,
               "nome":"ARRIZO 6  PRO 1.5 Turbo Flex Aut."
            },
            {
               "codigo":9106,
               "nome":"ARRIZO 6 GSX 1.5 Turbo Flex Aut."
            },
            {
               "codigo":10133,
               "nome":"ARRIZO 6 PRO 1.5 Turbo (Híbrido)"
            },
            {
               "codigo":6379,
               "nome":"Celer Hatch 1.5 16V Flex 5p"
            },
            {
               "codigo":7222,
               "nome":"Celer Hatch ACT 1.5 16V Flex 5p"
            },
            {
               "codigo":6380,
               "nome":"Celer Sedan 1.5 16V Flex 5p"
            },
            {
               "codigo":7223,
               "nome":"Celer Sedan ACT 1.5 16V Flex 5p"
            },
            {
               "codigo":5208,
               "nome":"CIELO 1.6 16V 119cv  5p"
            },
            {
               "codigo":5209,
               "nome":"CIELO Sedan 1.6 16V 119cv  4p"
            },
            {
               "codigo":5210,
               "nome":"Face 1.3 16V/1.3 16V Flex.Mec."
            },
            {
               "codigo":9862,
               "nome":"iCar EQ1 TECH (Elétrico)"
            },
            {
               "codigo":7224,
               "nome":"QQ 1.0  ACT FL 12V/1.0 12V Flex  5p"
            },
            {
               "codigo":7089,
               "nome":"QQ 1.0 ACT 12V 69cv 5p"
            },
            {
               "codigo":7225,
               "nome":"QQ 1.0 Look FL 12V/1.0 12V Flex 5p"
            },
            {
               "codigo":7902,
               "nome":"QQ 1.0 SMILE 12V Flex 5p"
            },
            {
               "codigo":5534,
               "nome":"QQ 1.1/1.0 12V 69cv 5p"
            },
            {
               "codigo":5740,
               "nome":"S-18 1.3 16V Flex Mec. 5p"
            },
            {
               "codigo":8339,
               "nome":"Tiggo 2 ACT 1.5 16V Flex Aut.5p"
            },
            {
               "codigo":8278,
               "nome":"Tiggo 2 ACT 1.5 16V Flex Mec. 5p"
            },
            {
               "codigo":9227,
               "nome":"Tiggo 2 EX 1.5 16V Flex Aut. 5p"
            },
            {
               "codigo":8340,
               "nome":"Tiggo 2 Look 1.5 16V Flex Aut.5p"
            },
            {
               "codigo":8279,
               "nome":"Tiggo 2 Look 1.5 16V Flex Mec. 5p"
            },
            {
               "codigo":9492,
               "nome":"Tiggo 2 SMILE 1.5 16V Flex Aut."
            },
            {
               "codigo":6661,
               "nome":"Tiggo 2.0 16V Aut. 5p"
            },
            {
               "codigo":5021,
               "nome":"Tiggo 2.0 16V Mec. 5p"
            },
            {
               "codigo":9532,
               "nome":"Tiggo 3X Plus 1.0 Turbo Flex Aut."
            },
            {
               "codigo":9533,
               "nome":"Tiggo 3X Pro 1.0 Turbo Flex Aut."
            },
            {
               "codigo":10067,
               "nome":"Tiggo 5X PRO 1.5 Turbo (Híbrido)"
            },
            {
               "codigo":9749,
               "nome":"Tiggo 5X PRO 1.5 Turbo Flex Aut."
            },
            {
               "codigo":8571,
               "nome":"Tiggo 5X T 1.5 16V Turbo Flex Aut."
            },
            {
               "codigo":8572,
               "nome":"Tiggo 5X TXS 1.5 16V Turbo Flex Aut."
            },
            {
               "codigo":10068,
               "nome":"Tiggo 7 PRO 1.5 Turbo (Híbrido)  "
            },
            {
               "codigo":9681,
               "nome":"Tiggo 7 Pro 1.6 Turbo 16V Aut.  "
            },
            {
               "codigo":10114,
               "nome":"Tiggo 7 Pro Max Drive 1.6 Turbo 16V Aut."
            },
            {
               "codigo":8586,
               "nome":"Tiggo 7 T 1.5 16V Turbo Flex Aut."
            },
            {
               "codigo":8587,
               "nome":"Tiggo 7 TXS 1.5 16V Turbo Flex Aut."
            },
            {
               "codigo":9924,
               "nome":"Tiggo 8 FOUNDER S EDITION 1.6 TGDi Aut."
            },
            {
               "codigo":10182,
               "nome":"Tiggo 8 Max Drive 1.6 Turbo Aut."
            },
            {
               "codigo":9925,
               "nome":"Tiggo 8 PRO 1.5 Turbo (Híbrido) "
            },
            {
               "codigo":9162,
               "nome":"Tiggo 8 TXS 1.6 16V TGDi Aut."
            }
         ],
         "anos":[
            {
               "codigo":"32000-1",
               "nome":"32000 Gasolina"
            },
            {
               "codigo":"2024-1",
               "nome":"2024 Gasolina"
            },
            {
               "codigo":"2023-1",
               "nome":"2023 Gasolina"
            },
            {
               "codigo":"2022-1",
               "nome":"2022 Gasolina"
            },
            {
               "codigo":"2021-1",
               "nome":"2021 Gasolina"
            },
            {
               "codigo":"2020-1",
               "nome":"2020 Gasolina"
            },
            {
               "codigo":"2019-1",
               "nome":"2019 Gasolina"
            },
            {
               "codigo":"2018-1",
               "nome":"2018 Gasolina"
            },
            {
               "codigo":"2017-1",
               "nome":"2017 Gasolina"
            },
            {
               "codigo":"2016-1",
               "nome":"2016 Gasolina"
            },
            {
               "codigo":"2015-1",
               "nome":"2015 Gasolina"
            },
            {
               "codigo":"2014-1",
               "nome":"2014 Gasolina"
            },
            {
               "codigo":"2013-1",
               "nome":"2013 Gasolina"
            },
            {
               "codigo":"2012-1",
               "nome":"2012 Gasolina"
            },
            {
               "codigo":"2011-1",
               "nome":"2011 Gasolina"
            },
            {
               "codigo":"2010-1",
               "nome":"2010 Gasolina"
            },
            {
               "codigo":"2009-1",
               "nome":"2009 Gasolina"
            }
         ]
    },
    {
        "codigo":"13",
        "nome":"Citroen",
        "modelos":[
            {
               "codigo":8737,
               "nome":"AIRCROSS 100 Anos 1.6 Flex 16V Aut."
            },
            {
               "codigo":8045,
               "nome":"AIRCROSS BUSINESS 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":6418,
               "nome":"AIRCROSS Exc. ATACA. 1.6 Flex 16V 5p Aut"
            },
            {
               "codigo":6419,
               "nome":"AIRCROSS Exc. ATACA. 1.6 Flex 16V 5p Mec"
            },
            {
               "codigo":5609,
               "nome":"AIRCROSS Exclusive 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":5337,
               "nome":"AIRCROSS Exclusive 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":7420,
               "nome":"AIRCROSS Feel 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":7421,
               "nome":"AIRCROSS Feel 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":5338,
               "nome":"AIRCROSS GL 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":5610,
               "nome":"AIRCROSS GLX 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":5339,
               "nome":"AIRCROSS GLX 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":6420,
               "nome":"AIRCROSS GLX ATACAMA 1.6 Flex 16V 5p Aut"
            },
            {
               "codigo":6421,
               "nome":"AIRCROSS GLX ATACAMA 1.6 Flex 16V 5p Mec"
            },
            {
               "codigo":7422,
               "nome":"AIRCROSS Live 1.5 Flex 8V 5p Mec."
            },
            {
               "codigo":7423,
               "nome":"AIRCROSS Live 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":7962,
               "nome":"AIRCROSS Live 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":9585,
               "nome":"AIRCROSS Live Bus. 1.6 Flex 5p Aut."
            },
            {
               "codigo":7903,
               "nome":"AIRCROSS SALOMON 1.5 Flex 8V 5p Mec."
            },
            {
               "codigo":7897,
               "nome":"AIRCROSS SALOMON 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":7090,
               "nome":"AIRCROSS SALOMON EXCLUSIVE 1.6 Flex Aut."
            },
            {
               "codigo":7091,
               "nome":"AIRCROSS SALOMON EXCLUSIVE 1.6 Flex Mec."
            },
            {
               "codigo":7092,
               "nome":"AIRCROSS SALOMON TENDANCE 1.6 Flex Aut."
            },
            {
               "codigo":7093,
               "nome":"AIRCROSS SALOMON TENDANCE 1.6 Flex Mec."
            },
            {
               "codigo":7424,
               "nome":"AIRCROSS Shine 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":7425,
               "nome":"AIRCROSS Start 1.5 Flex 8V 5p Mec."
            },
            {
               "codigo":7963,
               "nome":"AIRCROSS Start 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":6770,
               "nome":"AIRCROSS TENDANCE 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":6771,
               "nome":"AIRCROSS TENDANCE 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":283,
               "nome":"AX GTi"
            },
            {
               "codigo":8391,
               "nome":"Berlingo Furgão 1.6 16V Flex 5P"
            },
            {
               "codigo":285,
               "nome":"Berlingo MultSpace GLX 1.6 16V 110cv 4p"
            },
            {
               "codigo":284,
               "nome":"Berlingo MultSpace GLX 1.8i 3p"
            },
            {
               "codigo":286,
               "nome":"Berlingo MultSpace GLX 1.8i 4p"
            },
            {
               "codigo":287,
               "nome":"BX 1.6S 16V"
            },
            {
               "codigo":288,
               "nome":"BX GTi 1.9"
            },
            {
               "codigo":8738,
               "nome":"C3 100 Anos 1.6 16V Flex Aut."
            },
            {
               "codigo":6835,
               "nome":"C3 Attra/Origine Pack 1.5 Flex 8V 5p Mec"
            },
            {
               "codigo":7964,
               "nome":"C3 Attraction 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":7585,
               "nome":"C3 Attraction Pure Tech 1.2 Flex 12V Mec"
            },
            {
               "codigo":6152,
               "nome":"C3 Excl. 1.6 VTi Flex Start 16V 5p Aut."
            },
            {
               "codigo":6153,
               "nome":"C3 Excl. 1.6 VTi Flex Start 16V 5p Mec."
            },
            {
               "codigo":4761,
               "nome":"C3 Excl./Excl.Solar./Sonora 1.6 Flex Aut"
            },
            {
               "codigo":289,
               "nome":"C3 Exclus./Excl.Solaris 1.6 Flex 16V Mec"
            },
            {
               "codigo":4315,
               "nome":"C3 Exclusive 1.4 Flex 8V 5p"
            },
            {
               "codigo":6772,
               "nome":"C3 Exclusive 1.5 Flex 8V 5p Mec."
            },
            {
               "codigo":9346,
               "nome":"C3 Exclusive Bus. 1.6 Flex 5p Aut."
            },
            {
               "codigo":10091,
               "nome":"C3 Feel 1.0 Flex 6V 5p Mec."
            },
            {
               "codigo":10092,
               "nome":"C3 Feel 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":10093,
               "nome":"C3 Feel Pack 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":10094,
               "nome":"C3 First Edition 1.0 Flex 6V 5p Mec."
            },
            {
               "codigo":10095,
               "nome":"C3 First Edition 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":290,
               "nome":"C3 GLX 1.4/ GLX Sonora 1.4 Flex 8V 5p"
            },
            {
               "codigo":4762,
               "nome":"C3 GLX 1.6 Flex 16V 5p  Aut."
            },
            {
               "codigo":291,
               "nome":"C3 GLX 1.6/ 1.6 Flex 16V 5p"
            },
            {
               "codigo":10096,
               "nome":"C3 Live 1.0 Flex 6V 5p Mec."
            },
            {
               "codigo":10097,
               "nome":"C3 Live Pack 1.0 Flex 6V 5p Mec."
            },
            {
               "codigo":292,
               "nome":"C3 Ocimar Versolato 1.6 16V 110cv 5p"
            },
            {
               "codigo":6154,
               "nome":"C3 Origine 1.5 Flex 8V 5p Mec."
            },
            {
               "codigo":7586,
               "nome":"C3 Origine Pure Tech 1.2 Flex 12V Mec"
            },
            {
               "codigo":5611,
               "nome":"C3 Picasso Excl. 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":5551,
               "nome":"C3 Picasso Exclusive 1.6 Flex 16V 5p Mec"
            },
            {
               "codigo":6092,
               "nome":"C3 Picasso GL 1.5 Flex 8V Mec."
            },
            {
               "codigo":5552,
               "nome":"C3 Picasso GL 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":6093,
               "nome":"C3 Picasso GLX 1.5 Flex 8V 5p Mec."
            },
            {
               "codigo":5612,
               "nome":"C3 Picasso GLX 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":5553,
               "nome":"C3 Picasso GLX 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":6773,
               "nome":"C3 Picasso Origine 1.5 Flex 8V Mec."
            },
            {
               "codigo":6774,
               "nome":"C3 Picasso Tendance 1.5 Flex 8V 5p Mec."
            },
            {
               "codigo":6775,
               "nome":"C3 Picasso Tendance 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":8003,
               "nome":"C3 Start 1.2 Flex 12V 5p"
            },
            {
               "codigo":7730,
               "nome":"C3 Style Ed. Pure Tech 1.2 Flex 12V Mec."
            },
            {
               "codigo":7731,
               "nome":"C3 Style Edition 1.6 Flex 16V Aut."
            },
            {
               "codigo":6155,
               "nome":"C3 Tendance 1.5 Flex 8V 5p Mec."
            },
            {
               "codigo":6776,
               "nome":"C3 Tendance 1.6 VTi Flex Start 16V Aut."
            },
            {
               "codigo":7587,
               "nome":"C3 Tendance Pure Tech 1.2 Flex 12V Mec."
            },
            {
               "codigo":8341,
               "nome":"C3 Urban Trail 1.6 Flex 16V 5p Aut."
            },
            {
               "codigo":8882,
               "nome":"C3 Urban Trail P.Tech 1.2 Flex 12V Mec."
            },
            {
               "codigo":6777,
               "nome":"C3 X-BOX ONE 1.6 VTi Flex 16V 5p Mec."
            },
            {
               "codigo":4302,
               "nome":"C3 XTR 1.4 Flex 8V 5p"
            },
            {
               "codigo":4054,
               "nome":"C3 XTR 1.6 Flex 16V 5p"
            },
            {
               "codigo":8739,
               "nome":"C4 CACTUS 100 Anos 1.6 TB 16V Flex Aut."
            },
            {
               "codigo":9207,
               "nome":"C4 CACTUS C-SERIES 1.6 16V Flex Aut."
            },
            {
               "codigo":8466,
               "nome":"C4 CACTUS FEEL 1.6 16V Flex Aut."
            },
            {
               "codigo":8467,
               "nome":"C4 CACTUS FEEL 1.6 16V Flex Mec."
            },
            {
               "codigo":9586,
               "nome":"C4 CACTUS FEEL Bus. 1.6 Flex Aut."
            },
            {
               "codigo":8469,
               "nome":"C4 CACTUS FEEL Pack 1.6 16V Flex Aut."
            },
            {
               "codigo":9191,
               "nome":"C4 CACTUS LIVE 1.6 16V Flex Aut."
            },
            {
               "codigo":8470,
               "nome":"C4 CACTUS LIVE 1.6 16V Flex Mec."
            },
            {
               "codigo":9453,
               "nome":"C4 CACTUS Rip Curl 1.6 16V Flex Aut."
            },
            {
               "codigo":9757,
               "nome":"C4 CACTUS SHINE 1.6 16V Flex Aut."
            },
            {
               "codigo":8471,
               "nome":"C4 CACTUS SHINE 1.6 Turbo Flex Aut."
            },
            {
               "codigo":8472,
               "nome":"C4 CACTUS SHINE Pack 1.6 Turbo Flex Aut."
            },
            {
               "codigo":9609,
               "nome":"C4 CACTUS X-Series 1.6 16V Flex Aut."
            },
            {
               "codigo":6574,
               "nome":"C4 Competition 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":6559,
               "nome":"C4 Competition 2.0 Flex 16V 5p Aut."
            },
            {
               "codigo":4888,
               "nome":"C4 Excl./Excl. Solar. 2.0 Flex 16V Mec."
            },
            {
               "codigo":4845,
               "nome":"C4 Excl.2.0/2.0 Solaris Flex 16V 5p Aut."
            },
            {
               "codigo":4846,
               "nome":"C4 GLX 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":4847,
               "nome":"C4 GLX 2.0 Flex 16V 5p Aut."
            },
            {
               "codigo":4889,
               "nome":"C4 GLX 2.0 Flex 16V 5p Mec."
            },
            {
               "codigo":8740,
               "nome":"C4 LOUNGE 100 Anos 1.6 16V TB Flex Aut."
            },
            {
               "codigo":6575,
               "nome":"C4 LOUNGE Exclusive 1.6 Turbo 4p Aut."
            },
            {
               "codigo":7094,
               "nome":"C4 LOUNGE Exclusive 1.6 Turbo Flex Aut."
            },
            {
               "codigo":6576,
               "nome":"C4 LOUNGE Exclusive 2.0 Flex 4p Aut."
            },
            {
               "codigo":8234,
               "nome":"C4 LOUNGE Feel 1.6 Turbo Flex Aut."
            },
            {
               "codigo":8515,
               "nome":"C4 LOUNGE Live 1.6 Turbo Flex Aut."
            },
            {
               "codigo":9347,
               "nome":"C4 LOUNGE Live Bus. 1.6 Flex Aut."
            },
            {
               "codigo":7658,
               "nome":"C4 LOUNGE Orig.Business 1.6 TB Flex Aut."
            },
            {
               "codigo":7684,
               "nome":"C4 LOUNGE Origine 1.6 Turbo Flex  Aut."
            },
            {
               "codigo":7685,
               "nome":"C4 LOUNGE Origine 1.6 Turbo Flex Mec."
            },
            {
               "codigo":7370,
               "nome":"C4 LOUNGE Origine 2.0 Flex 4p Aut."
            },
            {
               "codigo":6577,
               "nome":"C4 LOUNGE Origine 2.0 Flex 4p Mec."
            },
            {
               "codigo":7787,
               "nome":"C4 LOUNGE S 1.6 Turbo Flex Aut."
            },
            {
               "codigo":8235,
               "nome":"C4 LOUNGE Shine 1.6 Turbo Flex Aut."
            },
            {
               "codigo":6884,
               "nome":"C4 LOUNGE Tendance 1.6 Turbo 4p Aut"
            },
            {
               "codigo":7118,
               "nome":"C4 LOUNGE Tendance 1.6 Turbo Flex Aut."
            },
            {
               "codigo":6578,
               "nome":"C4 LOUNGE Tendance 2.0 Flex 4p Aut."
            },
            {
               "codigo":6579,
               "nome":"C4 LOUNGE Tendance 2.0 Flex 4p Mec."
            },
            {
               "codigo":4397,
               "nome":"C4 PAL.Excl/Excl(Tech.) 2.0/2.0 Flex Aut"
            },
            {
               "codigo":4398,
               "nome":"C4 PALLAS Exclusive 2.0/2.0 Flex 16V Mec"
            },
            {
               "codigo":4399,
               "nome":"C4 PALLAS GLX 2.0/ 2.0 Flex Aut."
            },
            {
               "codigo":4400,
               "nome":"C4 PALLAS GLX 2.0/2.0 Flex 16V Mec."
            },
            {
               "codigo":4667,
               "nome":"C4 Picasso Grand 2.0 16V 143cv Aut"
            },
            {
               "codigo":7426,
               "nome":"C4 Picasso Intensive 1.6 Turbo 16V Aut."
            },
            {
               "codigo":7427,
               "nome":"C4 Picasso Seduction 1.6 Turbo 16V Aut."
            },
            {
               "codigo":4824,
               "nome":"C4 Picasso/Pic. La Luna 2.0 16V  Aut."
            },
            {
               "codigo":6580,
               "nome":"C4 Rock You 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":6737,
               "nome":"C4 Tendance 1.6 Flex 16V 5p Mec."
            },
            {
               "codigo":6738,
               "nome":"C4 Tendance 2.0 Flex 16V 5p Aut."
            },
            {
               "codigo":4159,
               "nome":"C4 VTR 2.0 16V 143cv"
            },
            {
               "codigo":293,
               "nome":"C5 3.0 24V 210cv 4p Aut."
            },
            {
               "codigo":294,
               "nome":"C5 Exclusive 2.0 16V 138cv 4p Mec."
            },
            {
               "codigo":295,
               "nome":"C5 Exclusive 2.0 16V 4p Aut."
            },
            {
               "codigo":296,
               "nome":"C5 Exclusive Break 2.0 16V 138cv 5p Mec."
            },
            {
               "codigo":297,
               "nome":"C5 Exclusive Break 2.0 16V Aut."
            },
            {
               "codigo":4937,
               "nome":"C5 Tourer Exclusive 2.0 16V 5p Aut."
            },
            {
               "codigo":4328,
               "nome":"C6 Exclusive 3.0 V6 24V 215cv Aut."
            },
            {
               "codigo":298,
               "nome":"C8 Exclusive 2.0 16V 138cv 5p Aut."
            },
            {
               "codigo":6050,
               "nome":"DS3 1.6 Turbo 16V 3p Mec."
            },
            {
               "codigo":7067,
               "nome":"DS3 Sport Chic 1.6 TB 16V 3p Mec."
            },
            {
               "codigo":7068,
               "nome":"DS4 1.6 Chic Turbo 16V 5p Aut."
            },
            {
               "codigo":7069,
               "nome":"DS4 1.6 So Chic Turbo 16V 5p Aut."
            },
            {
               "codigo":6347,
               "nome":"DS4 1.6 Turbo 16V 5p Aut."
            },
            {
               "codigo":7095,
               "nome":"DS5 1.6 Be Chic Turbo 16V 5p Aut."
            },
            {
               "codigo":7096,
               "nome":"DS5 1.6 So Chic Turbo 16V 5p Aut."
            },
            {
               "codigo":6295,
               "nome":"DS5 1.6 Turbo 16V 5p Aut."
            },
            {
               "codigo":9759,
               "nome":"Ë-Jumpy Cargo 136cv (Elétrico)"
            },
            {
               "codigo":299,
               "nome":"Evasion  GLX 2.0 16V"
            },
            {
               "codigo":300,
               "nome":"Evasion VSX Turbo"
            },
            {
               "codigo":7588,
               "nome":"Grand C4 Picasso Intensive 1.6 TB Aut."
            },
            {
               "codigo":7589,
               "nome":"Grand C4 Picasso Seduction 1.6 TB Aut."
            },
            {
               "codigo":9556,
               "nome":"Jumper 2.0 Cargo Furgão 16V TB Diesel"
            },
            {
               "codigo":8516,
               "nome":"Jumper 2.0 FurgãoTurbo Diesel"
            },
            {
               "codigo":8883,
               "nome":"Jumper 2.0 Minibus 16L Turbo Diesel"
            },
            {
               "codigo":5103,
               "nome":"Jumper 2.3 15/16Lug. TB Diesel"
            },
            {
               "codigo":5140,
               "nome":"Jumper 2.3 Furgão TB Diesel"
            },
            {
               "codigo":5659,
               "nome":"Jumper 2.3 Vetrato Exec. 16Lug. TB Dies."
            },
            {
               "codigo":5411,
               "nome":"Jumper 2.3 Vetrato TB Diesel"
            },
            {
               "codigo":301,
               "nome":"Jumper 2.5 Diesel"
            },
            {
               "codigo":302,
               "nome":"Jumper 2.8 16Lug.  Diesel"
            },
            {
               "codigo":303,
               "nome":"Jumper 2.8 Furgão 35C Diesel"
            },
            {
               "codigo":304,
               "nome":"Jumper 2.8 Furgão 35MH Diesel"
            },
            {
               "codigo":9763,
               "nome":"Jumper Cargo 2.2 Turbo Diesel"
            },
            {
               "codigo":9764,
               "nome":"Jumper Furgão 2.2 Turbo Diesel"
            },
            {
               "codigo":8078,
               "nome":"Jumpy 1.6  Furgão Pack Turbo Diesel"
            },
            {
               "codigo":8077,
               "nome":"Jumpy 1.6  Furgão Turbo Diesel"
            },
            {
               "codigo":8342,
               "nome":"Jumpy 1.6 Minibus Turbo Diesel"
            },
            {
               "codigo":9741,
               "nome":"Jumpy Cargo 1.5 Turbo Diesel"
            },
            {
               "codigo":9742,
               "nome":"Jumpy Vitré 1.5 Turbo Diesel"
            },
            {
               "codigo":9192,
               "nome":"Jumpy VITRÉ 1.6 Turbo Diesel"
            },
            {
               "codigo":305,
               "nome":"Xantia 2.0  16V"
            },
            {
               "codigo":306,
               "nome":"Xantia Activa 2.0"
            },
            {
               "codigo":307,
               "nome":"Xantia Activa 2.0 TB"
            },
            {
               "codigo":308,
               "nome":"Xantia Activa 3.0 V6 Mec"
            },
            {
               "codigo":309,
               "nome":"Xantia Break 2.0 8V/GLX 2.0 16V Aut"
            },
            {
               "codigo":310,
               "nome":"Xantia Break GLX 2.0 16V Mec."
            },
            {
               "codigo":311,
               "nome":"Xantia Exclusive 2.0 16V"
            },
            {
               "codigo":312,
               "nome":"Xantia Exclusive 3.0 V6"
            },
            {
               "codigo":313,
               "nome":"Xantia GLX 2.0 16V Aut."
            },
            {
               "codigo":314,
               "nome":"Xantia GLX 2.0 16V Mec."
            },
            {
               "codigo":315,
               "nome":"Xantia SX 1.8"
            },
            {
               "codigo":316,
               "nome":"Xantia SX 2.0 8V/16V Mec./Aut."
            },
            {
               "codigo":317,
               "nome":"Xantia VSX 2.0"
            },
            {
               "codigo":318,
               "nome":"Xantia VSX 2.0 16V"
            },
            {
               "codigo":319,
               "nome":"XM Exclusive 12v"
            },
            {
               "codigo":320,
               "nome":"XM Exclusive 24V"
            },
            {
               "codigo":321,
               "nome":"XM Exclusive Break"
            },
            {
               "codigo":322,
               "nome":"XM Sensation 2.0 TB"
            },
            {
               "codigo":323,
               "nome":"Xsara Break Exclusive 1.6 16V 5p Aut."
            },
            {
               "codigo":324,
               "nome":"Xsara Break Exclusive 1.6 16V 5p Mec."
            },
            {
               "codigo":325,
               "nome":"Xsara Break Exclusive 1.8 16V"
            },
            {
               "codigo":326,
               "nome":"Xsara Break Exclusive 2.0 16V"
            },
            {
               "codigo":327,
               "nome":"Xsara Break GLX 1.6 16V 5p Aut."
            },
            {
               "codigo":328,
               "nome":"Xsara Break GLX 1.6 16V 5p Mec."
            },
            {
               "codigo":329,
               "nome":"Xsara Break GLX 1.8 16V"
            },
            {
               "codigo":330,
               "nome":"Xsara Break GLX/ Paris 2.0 16V"
            },
            {
               "codigo":331,
               "nome":"Xsara Exclusive 1.6 16V 5p Aut."
            },
            {
               "codigo":332,
               "nome":"Xsara Exclusive 1.6 16V 5p Mec."
            },
            {
               "codigo":333,
               "nome":"Xsara Exclusive 1.8 8V/16V 5p Aut."
            },
            {
               "codigo":334,
               "nome":"Xsara Exclusive 1.8 8V/16V 5p Mec."
            },
            {
               "codigo":335,
               "nome":"Xsara Exclusive 2.0 16V"
            },
            {
               "codigo":336,
               "nome":"Xsara GLX 1.6 16V 3p"
            },
            {
               "codigo":337,
               "nome":"Xsara GLX 1.6 16V 5p Aut."
            },
            {
               "codigo":338,
               "nome":"Xsara GLX 1.6 16V 5p Mec."
            },
            {
               "codigo":339,
               "nome":"Xsara GLX 1.8 16V 5p Mec."
            },
            {
               "codigo":340,
               "nome":"Xsara GLX 1.8 16V Cupê Mec."
            },
            {
               "codigo":341,
               "nome":"Xsara GLX 1.8 8V 5p Aut."
            },
            {
               "codigo":342,
               "nome":"Xsara GLX 1.8 8V Cupê Aut."
            },
            {
               "codigo":343,
               "nome":"Xsara GLX/ Paris 2.0 16V"
            },
            {
               "codigo":344,
               "nome":"Xsara Picasso Exc./Etoile 2.0 16V Mec."
            },
            {
               "codigo":3930,
               "nome":"Xsara Picasso Exclus. 1.6/ 1.6 Flex 16V"
            },
            {
               "codigo":345,
               "nome":"Xsara Picasso Exclusive 2.0 16V Aut."
            },
            {
               "codigo":347,
               "nome":"Xsara Picasso GLX 1.6/ 1.6 Flex 16V"
            },
            {
               "codigo":348,
               "nome":"Xsara Picasso GLX 2.0 16V  Aut."
            },
            {
               "codigo":346,
               "nome":"Xsara Picasso GLX/Brasil/Etoile 2.0 Mec."
            },
            {
               "codigo":349,
               "nome":"Xsara VTS 1.6 16V 3p"
            },
            {
               "codigo":350,
               "nome":"Xsara VTS 1.8 16V"
            },
            {
               "codigo":351,
               "nome":"Xsara VTS 2.0  16V Cupê Mec."
            },
            {
               "codigo":352,
               "nome":"ZX Cupê 16V"
            },
            {
               "codigo":353,
               "nome":"ZX Dakar 2.0 16V"
            },
            {
               "codigo":354,
               "nome":"ZX Furio"
            },
            {
               "codigo":355,
               "nome":"ZX Paris 1.8"
            },
            {
               "codigo":356,
               "nome":"ZX Volcane 3p e 5p"
            }
         ],
         "anos":[
            {
               "codigo":"32000-1",
               "nome":"32000 Gasolina"
            },
            {
               "codigo":"32000-3",
               "nome":"32000 Diesel"
            },
            {
               "codigo":"2023-1",
               "nome":"2023 Gasolina"
            },
            {
               "codigo":"2023-3",
               "nome":"2023 Diesel"
            },
            {
               "codigo":"2022-1",
               "nome":"2022 Gasolina"
            },
            {
               "codigo":"2022-3",
               "nome":"2022 Diesel"
            },
            {
               "codigo":"2021-1",
               "nome":"2021 Gasolina"
            },
            {
               "codigo":"2021-3",
               "nome":"2021 Diesel"
            },
            {
               "codigo":"2020-1",
               "nome":"2020 Gasolina"
            },
            {
               "codigo":"2020-3",
               "nome":"2020 Diesel"
            },
            {
               "codigo":"2019-1",
               "nome":"2019 Gasolina"
            },
            {
               "codigo":"2019-3",
               "nome":"2019 Diesel"
            },
            {
               "codigo":"2018-1",
               "nome":"2018 Gasolina"
            },
            {
               "codigo":"2018-3",
               "nome":"2018 Diesel"
            },
            {
               "codigo":"2017-1",
               "nome":"2017 Gasolina"
            },
            {
               "codigo":"2017-3",
               "nome":"2017 Diesel"
            },
            {
               "codigo":"2016-1",
               "nome":"2016 Gasolina"
            },
            {
               "codigo":"2016-3",
               "nome":"2016 Diesel"
            },
            {
               "codigo":"2015-1",
               "nome":"2015 Gasolina"
            },
            {
               "codigo":"2015-3",
               "nome":"2015 Diesel"
            },
            {
               "codigo":"2014-1",
               "nome":"2014 Gasolina"
            },
            {
               "codigo":"2014-3",
               "nome":"2014 Diesel"
            },
            {
               "codigo":"2013-1",
               "nome":"2013 Gasolina"
            },
            {
               "codigo":"2013-3",
               "nome":"2013 Diesel"
            },
            {
               "codigo":"2012-1",
               "nome":"2012 Gasolina"
            },
            {
               "codigo":"2012-3",
               "nome":"2012 Diesel"
            },
            {
               "codigo":"2011-1",
               "nome":"2011 Gasolina"
            },
            {
               "codigo":"2011-3",
               "nome":"2011 Diesel"
            },
            {
               "codigo":"2010-1",
               "nome":"2010 Gasolina"
            },
            {
               "codigo":"2010-3",
               "nome":"2010 Diesel"
            },
            {
               "codigo":"2009-1",
               "nome":"2009 Gasolina"
            },
            {
               "codigo":"2009-3",
               "nome":"2009 Diesel"
            },
            {
               "codigo":"2008-1",
               "nome":"2008 Gasolina"
            },
            {
               "codigo":"2008-3",
               "nome":"2008 Diesel"
            },
            {
               "codigo":"2007-1",
               "nome":"2007 Gasolina"
            },
            {
               "codigo":"2007-3",
               "nome":"2007 Diesel"
            },
            {
               "codigo":"2006-1",
               "nome":"2006 Gasolina"
            },
            {
               "codigo":"2006-3",
               "nome":"2006 Diesel"
            },
            {
               "codigo":"2005-1",
               "nome":"2005 Gasolina"
            },
            {
               "codigo":"2005-3",
               "nome":"2005 Diesel"
            },
            {
               "codigo":"2004-1",
               "nome":"2004 Gasolina"
            },
            {
               "codigo":"2004-3",
               "nome":"2004 Diesel"
            },
            {
               "codigo":"2003-1",
               "nome":"2003 Gasolina"
            },
            {
               "codigo":"2003-3",
               "nome":"2003 Diesel"
            },
            {
               "codigo":"2002-1",
               "nome":"2002 Gasolina"
            },
            {
               "codigo":"2002-3",
               "nome":"2002 Diesel"
            },
            {
               "codigo":"2001-1",
               "nome":"2001 Gasolina"
            },
            {
               "codigo":"2001-3",
               "nome":"2001 Diesel"
            },
            {
               "codigo":"2000-1",
               "nome":"2000 Gasolina"
            },
            {
               "codigo":"2000-3",
               "nome":"2000 Diesel"
            },
            {
               "codigo":"1999-1",
               "nome":"1999 Gasolina"
            },
            {
               "codigo":"1998-1",
               "nome":"1998 Gasolina"
            },
            {
               "codigo":"1998-3",
               "nome":"1998 Diesel"
            },
            {
               "codigo":"1997-1",
               "nome":"1997 Gasolina"
            },
            {
               "codigo":"1996-1",
               "nome":"1996 Gasolina"
            },
            {
               "codigo":"1995-1",
               "nome":"1995 Gasolina"
            },
            {
               "codigo":"1995-3",
               "nome":"1995 Diesel"
            },
            {
               "codigo":"1994-1",
               "nome":"1994 Gasolina"
            },
            {
               "codigo":"1993-1",
               "nome":"1993 Gasolina"
            },
            {
               "codigo":"1992-1",
               "nome":"1992 Gasolina"
            }
         ]

    },
    {
        "codigo":"17",
        "nome":"Dodge",
        "modelos":[
            {
               "codigo":388,
               "nome":"Avenger ES 2.0/2.5 16V"
            },
            {
               "codigo":389,
               "nome":"Dakota 2.5"
            },
            {
               "codigo":390,
               "nome":"Dakota Club 2.5"
            },
            {
               "codigo":391,
               "nome":"Dakota Durango 5.9 4x4 V8"
            },
            {
               "codigo":392,
               "nome":"Dakota Durango SLT 5.2 4x4 V8"
            },
            {
               "codigo":394,
               "nome":"Dakota RT 5.2 Aut."
            },
            {
               "codigo":395,
               "nome":"Dakota RT 5.2 CE Aut."
            },
            {
               "codigo":396,
               "nome":"Dakota Sport 2.5 4x4"
            },
            {
               "codigo":397,
               "nome":"Dakota Sport 2.5 CD Diesel"
            },
            {
               "codigo":398,
               "nome":"Dakota Sport 2.5 Diesel"
            },
            {
               "codigo":399,
               "nome":"Dakota Sport 3.9 V6"
            },
            {
               "codigo":400,
               "nome":"Dakota Sport 3.9 V6 CD Aut."
            },
            {
               "codigo":401,
               "nome":"Dakota Sport 3.9 V6 CD Mec."
            },
            {
               "codigo":402,
               "nome":"Dakota Sport 5.2 V8"
            },
            {
               "codigo":403,
               "nome":"Dakota Sport 5.2 V8 CD Aut."
            },
            {
               "codigo":404,
               "nome":"Dakota Sport CE 2.5 Diesel"
            },
            {
               "codigo":405,
               "nome":"Dakota Sport CE 3.9 V6"
            },
            {
               "codigo":6316,
               "nome":"Durango Citadel 3.6 24V 4x4 Aut."
            },
            {
               "codigo":6317,
               "nome":"Durango Crew 3.6 24V 4x4 Aut."
            },
            {
               "codigo":7131,
               "nome":"Durango Limited 3.6 24V 4x4 Aut."
            },
            {
               "codigo":393,
               "nome":"Intrepid 3.3 V6"
            },
            {
               "codigo":6953,
               "nome":"JOURNEY Crossroad 3.6 V6 Aut."
            },
            {
               "codigo":5695,
               "nome":"JOURNEY RT  3.6 V6 Aut."
            },
            {
               "codigo":5238,
               "nome":"JOURNEY RT 2.7 V6 185cv Aut."
            },
            {
               "codigo":6954,
               "nome":"JOURNEY RT 3.6 AWD V6 Aut."
            },
            {
               "codigo":5270,
               "nome":"JOURNEY SE 2.7 V6 185cv Aut."
            },
            {
               "codigo":4732,
               "nome":"JOURNEY SXT 2.7 V6 185cv Aut."
            },
            {
               "codigo":5694,
               "nome":"JOURNEY SXT 3.6 V6 Aut."
            },
            {
               "codigo":406,
               "nome":"Ram 2500 H.DUTY 5.9 SLT 24V CD 4x4 Dies."
            },
            {
               "codigo":3931,
               "nome":"Ram 2500 H.DUTY 5.9 SLT TDI CS 4x4 Dies."
            },
            {
               "codigo":5924,
               "nome":"Ram 2500 LARAMIE 6.7 TDI CD 4x4 Dies"
            },
            {
               "codigo":5150,
               "nome":"Ram 2500 TROPIVAN 5.9 SLT Exec.TDI Dies."
            },
            {
               "codigo":4575,
               "nome":"Ram 2500 TROPIVAN 5.9 SLT TDI 4x4 Dies."
            },
            {
               "codigo":407,
               "nome":"Ram Laramie 5.9 V8"
            },
            {
               "codigo":408,
               "nome":"Ram Laramie/SLT 5.2 V8"
            },
            {
               "codigo":409,
               "nome":"Ram Sport 5.9 V8"
            },
            {
               "codigo":410,
               "nome":"Stealth 3.0 MPI"
            }
         ],
         "anos":[
            {
               "codigo":"2018-1",
               "nome":"2018 Gasolina"
            },
            {
               "codigo":"2017-1",
               "nome":"2017 Gasolina"
            },
            {
               "codigo":"2016-1",
               "nome":"2016 Gasolina"
            },
            {
               "codigo":"2015-1",
               "nome":"2015 Gasolina"
            },
            {
               "codigo":"2014-1",
               "nome":"2014 Gasolina"
            },
            {
               "codigo":"2013-1",
               "nome":"2013 Gasolina"
            },
            {
               "codigo":"2012-1",
               "nome":"2012 Gasolina"
            },
            {
               "codigo":"2012-3",
               "nome":"2012 Diesel"
            },
            {
               "codigo":"2011-1",
               "nome":"2011 Gasolina"
            },
            {
               "codigo":"2010-1",
               "nome":"2010 Gasolina"
            },
            {
               "codigo":"2010-3",
               "nome":"2010 Diesel"
            },
            {
               "codigo":"2009-1",
               "nome":"2009 Gasolina"
            },
            {
               "codigo":"2009-3",
               "nome":"2009 Diesel"
            },
            {
               "codigo":"2008-3",
               "nome":"2008 Diesel"
            },
            {
               "codigo":"2007-3",
               "nome":"2007 Diesel"
            },
            {
               "codigo":"2006-3",
               "nome":"2006 Diesel"
            },
            {
               "codigo":"2005-3",
               "nome":"2005 Diesel"
            },
            {
               "codigo":"2004-3",
               "nome":"2004 Diesel"
            },
            {
               "codigo":"2001-1",
               "nome":"2001 Gasolina"
            },
            {
               "codigo":"2001-3",
               "nome":"2001 Diesel"
            },
            {
               "codigo":"2000-1",
               "nome":"2000 Gasolina"
            },
            {
               "codigo":"2000-3",
               "nome":"2000 Diesel"
            },
            {
               "codigo":"1999-1",
               "nome":"1999 Gasolina"
            },
            {
               "codigo":"1999-3",
               "nome":"1999 Diesel"
            },
            {
               "codigo":"1998-1",
               "nome":"1998 Gasolina"
            },
            {
               "codigo":"1997-1",
               "nome":"1997 Gasolina"
            },
            {
               "codigo":"1996-1",
               "nome":"1996 Gasolina"
            },
            {
               "codigo":"1995-1",
               "nome":"1995 Gasolina"
            },
            {
               "codigo":"1994-1",
               "nome":"1994 Gasolina"
            },
            {
               "codigo":"1993-1",
               "nome":"1993 Gasolina"
            },
            {
               "codigo":"1992-1",
               "nome":"1992 Gasolina"
            }
         ]
    },
    {
        "codigo":"21",
        "nome":"Fiat",
        "modelos":[
            {
               "codigo":437,
               "nome":"147 C/ CL"
            },
            {
               "codigo":438,
               "nome":"147 Furgão (todos)"
            },
            {
               "codigo":439,
               "nome":"147 Pick-Up (todas)"
            },
            {
               "codigo":7097,
               "nome":"500 ABARTH MULTIAIR 1.4 TB 16V 3p"
            },
            {
               "codigo":6885,
               "nome":"500 Cabrio Dualogic Flex 1.4 8V"
            },
            {
               "codigo":6886,
               "nome":"500 Cabrio Flex 1.4 8V Mec."
            },
            {
               "codigo":6262,
               "nome":"500 Cabrio/500 Coupe Gucci/Flex 1.4 Aut."
            },
            {
               "codigo":5613,
               "nome":"500 Cult 1.4 Flex 8V EVO Dualogic"
            },
            {
               "codigo":5614,
               "nome":"500 Cult 1.4 Flex 8V EVO Mec."
            },
            {
               "codigo":5059,
               "nome":"500 LOUNGE 1.4 16V 100cv  Mec."
            },
            {
               "codigo":5060,
               "nome":"500 LOUNGE 1.4 16V 100cv Dualogic"
            },
            {
               "codigo":5615,
               "nome":"500 Lounge Air 1.4/ 1.4 Flex 16V Aut."
            },
            {
               "codigo":5061,
               "nome":"500 SPORT 1.4 16V 100cv  Dualogic"
            },
            {
               "codigo":5062,
               "nome":"500 SPORT 1.4 16V 100cv Mec."
            },
            {
               "codigo":5616,
               "nome":"500 Sport Air 1.4 16V/1.4 Flex 16V Aut."
            },
            {
               "codigo":5617,
               "nome":"500 Sport Air 1.4 16V/1.4 Flex Mec."
            },
            {
               "codigo":9584,
               "nome":"500e ICON (Elétrico)"
            },
            {
               "codigo":8315,
               "nome":"ARGO 1.0 6V Flex."
            },
            {
               "codigo":7965,
               "nome":"ARGO DRIVE 1.0 6V Flex"
            },
            {
               "codigo":7966,
               "nome":"ARGO DRIVE 1.3 8V Flex"
            },
            {
               "codigo":10392,
               "nome":"ARGO DRIVE 1.3 8V Flex Aut."
            },
            {
               "codigo":7967,
               "nome":"ARGO DRIVE GSR 1.3 8V Flex"
            },
            {
               "codigo":9543,
               "nome":"ARGO DRIVE S-DESIGN 1.3 8V Flex"
            },
            {
               "codigo":7968,
               "nome":"ARGO HGT 1.8 16V Flex Aut."
            },
            {
               "codigo":7969,
               "nome":"ARGO HGT 1.8 16V Flex Mec."
            },
            {
               "codigo":7971,
               "nome":"ARGO PRECISION 1.8 16V Flex Aut."
            },
            {
               "codigo":7970,
               "nome":"ARGO PRECISION 1.8 16V Flex Mec."
            },
            {
               "codigo":8690,
               "nome":"ARGO TREKKING 1.3 8V Flex"
            },
            {
               "codigo":10393,
               "nome":"ARGO TREKKING 1.3 8V Flex Aut."
            },
            {
               "codigo":8947,
               "nome":"ARGO TREKKING 1.8 16V Flex Aut."
            },
            {
               "codigo":440,
               "nome":"Brava ELX  1.6 16V 4p"
            },
            {
               "codigo":441,
               "nome":"Brava HGT 1.8 mpi 16V  4p"
            },
            {
               "codigo":442,
               "nome":"Brava SX 1.6 16V 4p"
            },
            {
               "codigo":5446,
               "nome":"Bravo ABSOLUTE 1.8 Flex 16V 5p"
            },
            {
               "codigo":5447,
               "nome":"Bravo ABSOLUTE Dualogic 1.8 Flex 16V 5p"
            },
            {
               "codigo":7132,
               "nome":"Bravo BlackMotion 1.8 Dualogic Flex 5p"
            },
            {
               "codigo":7133,
               "nome":"Bravo BlackMotion 1.8 Flex 16v 5p"
            },
            {
               "codigo":5448,
               "nome":"Bravo ESSENCE 1.8 Flex 16V 5p"
            },
            {
               "codigo":5449,
               "nome":"Bravo ESSENCE Dualogic 1.8 Flex 16V 5p"
            },
            {
               "codigo":6051,
               "nome":"Bravo SPORTING 1.8 Dualogic Flex 16V 5p"
            },
            {
               "codigo":6052,
               "nome":"Bravo SPORTING 1.8 Flex 16V 5p"
            },
            {
               "codigo":443,
               "nome":"Bravo SX 1.6"
            },
            {
               "codigo":5591,
               "nome":"Bravo T-JET 1.4 16V Turbo 5p"
            },
            {
               "codigo":6515,
               "nome":"Bravo WOLVERINE 1.8 Dualogic Flex 16V 5p"
            },
            {
               "codigo":6516,
               "nome":"Bravo WOLVERINE 1.8 Flex 16V 5p"
            },
            {
               "codigo":444,
               "nome":"Cinquecento 0.7"
            },
            {
               "codigo":445,
               "nome":"Coupe 16V"
            },
            {
               "codigo":9988,
               "nome":"CRONOS 1.0 6V Flex"
            },
            {
               "codigo":8199,
               "nome":"CRONOS 1.3 8V Flex"
            },
            {
               "codigo":9590,
               "nome":"CRONOS 1.8 16V Flex Aut. "
            },
            {
               "codigo":9989,
               "nome":"CRONOS DRIVE 1.0 6V Flex"
            },
            {
               "codigo":8200,
               "nome":"CRONOS DRIVE 1.3 8V Flex"
            },
            {
               "codigo":9990,
               "nome":"CRONOS DRIVE 1.3 8V Flex Aut."
            },
            {
               "codigo":8518,
               "nome":"CRONOS DRIVE 1.8 16V Flex Aut."
            },
            {
               "codigo":8201,
               "nome":"CRONOS DRIVE GSR 1.3 8V Flex"
            },
            {
               "codigo":8778,
               "nome":"CRONOS HGT 1.8 16V Flex Aut."
            },
            {
               "codigo":10394,
               "nome":"CRONOS PRECISION 1.3 8V Flex Aut."
            },
            {
               "codigo":8202,
               "nome":"CRONOS PRECISION 1.8 16V Flex Aut."
            },
            {
               "codigo":8203,
               "nome":"CRONOS PRECISION 1.8 16V Flex Mec."
            },
            {
               "codigo":5104,
               "nome":"Doblo  1.4 mpi Fire Flex  8V 4p"
            },
            {
               "codigo":5105,
               "nome":"Doblo  Cargo 1.4 mpi Fire Flex 8V 3p"
            },
            {
               "codigo":6053,
               "nome":"Doblo Adv. XINGU 1.8 Flex 16V 5p"
            },
            {
               "codigo":6054,
               "nome":"Doblo Adv. XINGU LOCKER 1.8 Flex 16V 5p"
            },
            {
               "codigo":7503,
               "nome":"Doblo Adv.Ext./Adv.Ext.Loc. 1.8 16V Flex"
            },
            {
               "codigo":3933,
               "nome":"Doblo Adv/Adv TRYON/LOCKER 1.8 Flex"
            },
            {
               "codigo":446,
               "nome":"Doblo Adventure/ Adv.ER 1.8 mpi 8V 103cv"
            },
            {
               "codigo":5535,
               "nome":"Doblo ATTRACTIVE 1.4 Fire Flex 8V 5p"
            },
            {
               "codigo":447,
               "nome":"Doblo Cargo 1.3 Fire 16V 4/5p"
            },
            {
               "codigo":448,
               "nome":"Doblo Cargo 1.6 16V 4/5p"
            },
            {
               "codigo":449,
               "nome":"Doblo Cargo 1.8 mpi 8V 103cv"
            },
            {
               "codigo":3934,
               "nome":"Doblo Cargo 1.8 mpi Fire Flex 8V/16V 4p"
            },
            {
               "codigo":5106,
               "nome":"Doblo ELX 1.4 mpi Fire Flex 8V 4p"
            },
            {
               "codigo":450,
               "nome":"Doblo ELX 1.6 16V 4/5p"
            },
            {
               "codigo":451,
               "nome":"Doblo ELX 1.8 mpi 8V 103cv"
            },
            {
               "codigo":3935,
               "nome":"Doblo ELX 1.8 mpi 8V Flex"
            },
            {
               "codigo":5536,
               "nome":"Doblo ESSENCE 1.8 Flex 16V 5p"
            },
            {
               "codigo":452,
               "nome":"Doblo EX 1.3 Fire 16V 80cv 4/5p"
            },
            {
               "codigo":4097,
               "nome":"Doblo HLX 1.8 mpi Flex 5p"
            },
            {
               "codigo":10659,
               "nome":"Ducato Cargo 2.2 Diesel (E6)"
            },
            {
               "codigo":453,
               "nome":"Ducato Cargo 2.8 Curto/Longo TB Diesel"
            },
            {
               "codigo":8204,
               "nome":"Ducato Cargo Curto 2.3 16V Diesel"
            },
            {
               "codigo":5022,
               "nome":"Ducato Cargo Curto 2.3 ME Diesel"
            },
            {
               "codigo":5023,
               "nome":"Ducato Cargo Longo 2.3 ME Diesel"
            },
            {
               "codigo":8205,
               "nome":"Ducato Cargo Médio 2.3 16V Diesel"
            },
            {
               "codigo":8206,
               "nome":"Ducato Chassi 2.3 16V Diesel"
            },
            {
               "codigo":5024,
               "nome":"Ducato Combinato 2.3 ME Diesel"
            },
            {
               "codigo":454,
               "nome":"Ducato Combinato 2.8 Diesel"
            },
            {
               "codigo":455,
               "nome":"Ducato Combinato 2.8 Turbo Diesel"
            },
            {
               "codigo":8395,
               "nome":"Ducato Executivo 2.3 16V Diesel"
            },
            {
               "codigo":456,
               "nome":"Ducato Furgão Maxi 2.8 Diesel"
            },
            {
               "codigo":5025,
               "nome":"Ducato Maxi. Curta 2.3 T.Alto ME Diesel"
            },
            {
               "codigo":5026,
               "nome":"Ducato Maxi. Long. 2.3 T.Alto ME Diesel"
            },
            {
               "codigo":10660,
               "nome":"Ducato MaxiCargo 2.2 Diesel (E6)"
            },
            {
               "codigo":8207,
               "nome":"Ducato MaxiCargo 2.3 16V Diesel"
            },
            {
               "codigo":457,
               "nome":"Ducato MaxiCargo/Furgão Maxi 2.8 TB Dies"
            },
            {
               "codigo":8208,
               "nome":"Ducato MaxiMulti 2.3 16V Diesel"
            },
            {
               "codigo":10661,
               "nome":"Ducato Minibus 2.2 Comf.19L Diesel (E6)"
            },
            {
               "codigo":10662,
               "nome":"Ducato Minibus 2.2 Exec. 17L Diesel (E6)"
            },
            {
               "codigo":8396,
               "nome":"Ducato Minibus 2.3 16V Diesel"
            },
            {
               "codigo":5027,
               "nome":"Ducato Minibus 2.3 ME Diesel"
            },
            {
               "codigo":5028,
               "nome":"Ducato Minibus 2.3 T.Alto ME Diesel"
            },
            {
               "codigo":458,
               "nome":"Ducato Minibus 2.8 Diesel"
            },
            {
               "codigo":459,
               "nome":"Ducato Minibus 2.8 Turbo Diesel"
            },
            {
               "codigo":8397,
               "nome":"Ducato Minibus Comfort 2.3 16v Diesel"
            },
            {
               "codigo":460,
               "nome":"Ducato Mult/ Vetrato 2.8 T.Alto TB Dies."
            },
            {
               "codigo":461,
               "nome":"Ducato Mult/ Vetrato 2.8 T.Baixo TB Dies"
            },
            {
               "codigo":10663,
               "nome":"Ducato Multi 2.2 Diesel (E6)"
            },
            {
               "codigo":8209,
               "nome":"Ducato Multi 2.3 16V Diesel"
            },
            {
               "codigo":5029,
               "nome":"Ducato Multi Long. 2.3 T.Alto ME Diesel"
            },
            {
               "codigo":462,
               "nome":"Ducato Van 2.5  Diesel"
            },
            {
               "codigo":8398,
               "nome":"Ducato Vip Bus 2.3 16V Diesel"
            },
            {
               "codigo":463,
               "nome":"Ducato-10 Furgão 2.5 Diesel"
            },
            {
               "codigo":464,
               "nome":"Ducato-15 2.8 Furgão TB Diesel"
            },
            {
               "codigo":465,
               "nome":"Ducato-15 Furgão 2.8 Diesel"
            },
            {
               "codigo":466,
               "nome":"Ducato-8 Furgão 2.5 Diesel"
            },
            {
               "codigo":467,
               "nome":"Duna 1.6ie"
            },
            {
               "codigo":468,
               "nome":"Elba 1.6i.e/Top/CSL/ 1.6i.e/1.5 2p e 4p"
            },
            {
               "codigo":469,
               "nome":"Elba CS 1.6 / 1.5 /1.3"
            },
            {
               "codigo":470,
               "nome":"Elba S 1.6/ 1.5ie / 1.5 / 1.3"
            },
            {
               "codigo":471,
               "nome":"Elba Weekend 1.5 i.e. 2p e 4p"
            },
            {
               "codigo":10369,
               "nome":"E-Scudo (Elétrico)"
            },
            {
               "codigo":10101,
               "nome":"Fastback Audace 1.0 200 T. Flex Aut"
            },
            {
               "codigo":10102,
               "nome":"Fastback Impetus 1.0 200 T. Flex Aut"
            },
            {
               "codigo":10103,
               "nome":"Fastback Limited Ed.1.3 270 T.Flex Aut."
            },
            {
               "codigo":9194,
               "nome":"Fiorino Endurance EVO 1.4 Flex 8V 2p"
            },
            {
               "codigo":473,
               "nome":"Fiorino Furg.1.5/1.3/1.3 Fire/1.3 F.Flex"
            },
            {
               "codigo":472,
               "nome":"Fiorino Furgão 1.0"
            },
            {
               "codigo":474,
               "nome":"Fiorino Furgão 1.5 mpi / i.e."
            },
            {
               "codigo":6662,
               "nome":"Fiorino Furgão Celeb. EVO 1.4 Flex 8V 2p"
            },
            {
               "codigo":6663,
               "nome":"Fiorino Furgão EVO 1.4 Flex 8V 2p"
            },
            {
               "codigo":7751,
               "nome":"Fiorino Furgão Work. HARD 1.4 Flex 8V 2p"
            },
            {
               "codigo":475,
               "nome":"Fiorino Pick-Up 1.0"
            },
            {
               "codigo":476,
               "nome":"Fiorino Pick-Up 1.5 i.e./1.3/1.5/HD"
            },
            {
               "codigo":477,
               "nome":"Fiorino Pick-Up LX (todas)"
            },
            {
               "codigo":478,
               "nome":"Fiorino Pick-Up Trekking 1.5 mpi / i.e."
            },
            {
               "codigo":479,
               "nome":"Fiorino Pick-Up Working 1.5 mpi / i.e."
            },
            {
               "codigo":9208,
               "nome":"Fiorino Working 1.4 Flex 8V 2p"
            },
            {
               "codigo":5618,
               "nome":"FREEMONT EMOT./PRECISION 2.4 16V 5p Aut."
            },
            {
               "codigo":9209,
               "nome":"Grand Siena 1.0 EVO Flex 8V 4p"
            },
            {
               "codigo":9210,
               "nome":"Grand Siena 1.4 EVO Flex 8V 4p"
            },
            {
               "codigo":5925,
               "nome":"Grand Siena ATTRAC. 1.4 EVO F.Flex 8V"
            },
            {
               "codigo":7824,
               "nome":"Grand Siena ATTRACTIVE 1.0 Flex 8V 4p"
            },
            {
               "codigo":6955,
               "nome":"Grand Siena ESSEN. ITALIA Dual. 1.6 Flex"
            },
            {
               "codigo":6488,
               "nome":"Grand Siena ESSEN.SUBLIME 1.6 Flex"
            },
            {
               "codigo":6489,
               "nome":"Grand Siena ESSEN.SUBLIME Dual. 1.6 Flex"
            },
            {
               "codigo":5926,
               "nome":"Grand Siena ESSENCE 1.6 Flex 16V"
            },
            {
               "codigo":5927,
               "nome":"Grand Siena ESSENCE Dual. 1.6 Flex 16V"
            },
            {
               "codigo":6956,
               "nome":"Grand Siena ESSENCE ITALIA 1.6 Flex 16V"
            },
            {
               "codigo":5928,
               "nome":"Grand Siena TETRAFUEL 1.4 Evo F. Flex 8V"
            },
            {
               "codigo":7504,
               "nome":"Idea A.Ext./A..Ext.Loc.Dual. 1.8 Flex 5p"
            },
            {
               "codigo":4966,
               "nome":"Idea Adv./ Adv.LOCK.Dualogic 1.8 Flex 5p"
            },
            {
               "codigo":7505,
               "nome":"Idea Adv.Ext./Adv.Ext. Loc. 1.8 Flex 5p"
            },
            {
               "codigo":4160,
               "nome":"Idea Advent./ Adv.LOCKER 1.8 mpi Flex 5p"
            },
            {
               "codigo":5340,
               "nome":"Idea ATTRACTIVE 1.4 Fire Flex 8V 5p"
            },
            {
               "codigo":480,
               "nome":"Idea ELX 1.4 mpi Fire Flex 8V 5p"
            },
            {
               "codigo":4962,
               "nome":"Idea ELX 1.8 mpi Flex 8V 5p"
            },
            {
               "codigo":5341,
               "nome":"Idea ESSENCE 1.6 Flex 16V 5p"
            },
            {
               "codigo":5342,
               "nome":"Idea ESSENCE Dualogic 1.6 Flex 16V 5p"
            },
            {
               "codigo":6711,
               "nome":"Idea ESSENCE SUBLIME 1.6 Flex 16V 5p"
            },
            {
               "codigo":6712,
               "nome":"Idea ESSENCE SUBLIME Dual.1.6 Flex16V 5p"
            },
            {
               "codigo":481,
               "nome":"Idea HLX 1.8 mpi Flex 8V 5p"
            },
            {
               "codigo":5343,
               "nome":"Idea SPORTING 1.8 Flex 16V 5p"
            },
            {
               "codigo":5344,
               "nome":"Idea SPORTING Dualogic 1.8 Flex 16V 5p"
            },
            {
               "codigo":4748,
               "nome":"LINEA 1.9/ HLX 1.9/ 1.8 Flex 16V 4p"
            },
            {
               "codigo":4750,
               "nome":"LINEA 1.9/ HLX 1.9/1.8 Flex  Dualogic 4p"
            },
            {
               "codigo":4749,
               "nome":"LINEA ABSOLUTE 1.9/1.8 Flex Dualogic 4p"
            },
            {
               "codigo":6581,
               "nome":"LINEA ESSEN.SUBLIME 1.8 Flex 16V 4p"
            },
            {
               "codigo":6582,
               "nome":"LINEA ESSEN.SUBLIME Dual.1.8 Flex 16V 4p"
            },
            {
               "codigo":5577,
               "nome":"LINEA ESSENCE 1.8 Flex 16V 4p"
            },
            {
               "codigo":5578,
               "nome":"LINEA ESSENCE Dualogic 1.8 Flex 16V 4p"
            },
            {
               "codigo":4921,
               "nome":"LINEA LX 1.9/ 1.8 Flex 16V 4p"
            },
            {
               "codigo":4920,
               "nome":"LINEA LX 1.9/ 1.8 Flex 16V Dualogic 4p"
            },
            {
               "codigo":4751,
               "nome":"LINEA T-JET 1.4 16V Turbo 4p"
            },
            {
               "codigo":482,
               "nome":"Marea City"
            },
            {
               "codigo":483,
               "nome":"Marea ELX 1.8 mpi 16V 132cv 4p"
            },
            {
               "codigo":484,
               "nome":"Marea ELX 2.0 20V 4p"
            },
            {
               "codigo":485,
               "nome":"Marea ELX 2.4 mpi 20V 4p"
            },
            {
               "codigo":486,
               "nome":"Marea HLX 2.0 20V 4p"
            },
            {
               "codigo":487,
               "nome":"Marea HLX 2.4 mpi 20V 4p Aut."
            },
            {
               "codigo":488,
               "nome":"Marea HLX 2.4 mpi 20V 4p Mec."
            },
            {
               "codigo":489,
               "nome":"Marea SX 1.6 mpi 16V 106cv 4p"
            },
            {
               "codigo":490,
               "nome":"Marea SX 1.8 16V 4p"
            },
            {
               "codigo":491,
               "nome":"Marea SX 2.0 20V 4p"
            },
            {
               "codigo":492,
               "nome":"Marea Turbo 2.0 20V 4p"
            },
            {
               "codigo":493,
               "nome":"Marea Weekend City 4p"
            },
            {
               "codigo":494,
               "nome":"Marea Weekend ELX 1.8 mpi 16V 132cv 4p"
            },
            {
               "codigo":495,
               "nome":"Marea Weekend ELX 2.0 20V 4p"
            },
            {
               "codigo":496,
               "nome":"Marea Weekend ELX 2.4 mpi 20V 4p"
            },
            {
               "codigo":497,
               "nome":"Marea Weekend HLX 2.0 20V 4p"
            },
            {
               "codigo":498,
               "nome":"Marea Weekend HLX 2.4 mpi 20V 4p Aut."
            },
            {
               "codigo":499,
               "nome":"Marea Weekend HLX 2.4 mpi 20V 4p Mec."
            },
            {
               "codigo":500,
               "nome":"Marea Weekend SX 1.6 mpi 16V 106cv 4p"
            },
            {
               "codigo":501,
               "nome":"Marea Weekend SX 1.8 16V 4p"
            },
            {
               "codigo":502,
               "nome":"Marea Weekend SX 2.0 20V 4p"
            },
            {
               "codigo":503,
               "nome":"Marea Weekend Turbo 2.0 20V 4p"
            },
            {
               "codigo":7825,
               "nome":"MOBI DRIVE 1.0 Flex 6V 5p"
            },
            {
               "codigo":7920,
               "nome":"MOBI DRIVE GSR 1.0 Flex 6V 5p"
            },
            {
               "codigo":7538,
               "nome":"MOBI EASY 1.0 Fire Flex 5p."
            },
            {
               "codigo":8344,
               "nome":"MOBI EASY COMFORT 1.0 Flex 5p."
            },
            {
               "codigo":7539,
               "nome":"MOBI EASY on 1.0 Fire Flex 5p."
            },
            {
               "codigo":7540,
               "nome":"MOBI LIKE 1.0 Fire Flex 5p."
            },
            {
               "codigo":7541,
               "nome":"MOBI LIKE ON 1.0 Fire Flex 5p."
            },
            {
               "codigo":9228,
               "nome":"MOBI TREKKING 1.0 Flex 5p."
            },
            {
               "codigo":7612,
               "nome":"MOBI WAY 1.0 Fire Flex 5p."
            },
            {
               "codigo":7613,
               "nome":"MOBI WAY on 1.0 Fire Flex 5p."
            },
            {
               "codigo":504,
               "nome":"Oggi"
            },
            {
               "codigo":4826,
               "nome":"Palio 1.0 Cel. ECON./ITALIA F.Flex 8V 4p"
            },
            {
               "codigo":4825,
               "nome":"Palio 1.0 Celebr. ECONOMY F.Flex 8V 2p"
            },
            {
               "codigo":4827,
               "nome":"Palio 1.0 ECONOMY Fire Flex 8V 2p"
            },
            {
               "codigo":4828,
               "nome":"Palio 1.0 ECONOMY Fire Flex 8V 4p"
            },
            {
               "codigo":505,
               "nome":"Palio 1.0/ Trofeo 1.0 Fire/ Fire Flex 2p"
            },
            {
               "codigo":506,
               "nome":"Palio 1.0/ Trofeo 1.0 Fire/ Fire Flex 4p"
            },
            {
               "codigo":507,
               "nome":"Palio 1.5 mpi 8V 2p"
            },
            {
               "codigo":508,
               "nome":"Palio 1.5 mpi 8V 4p"
            },
            {
               "codigo":509,
               "nome":"Palio 1.6 mpi 16V 2p"
            },
            {
               "codigo":510,
               "nome":"Palio 1.6 mpi 16V 4p"
            },
            {
               "codigo":4290,
               "nome":"Palio 1.8R mpi Flex 8V 2p"
            },
            {
               "codigo":3883,
               "nome":"Palio 1.8R mpi Flex 8V 4p"
            },
            {
               "codigo":7180,
               "nome":"Palio ATTRA. Best Seller 1.0 EVO Flex 5p"
            },
            {
               "codigo":7181,
               "nome":"Palio ATTRA. Best Seller 1.4 EVO Flex 5p"
            },
            {
               "codigo":5271,
               "nome":"Palio ATTRA./ITÁLIA 1.4 EVO F.Flex 8V 5p"
            },
            {
               "codigo":5721,
               "nome":"Palio ATTRACTIVE 1.0 EVO Fire Flex 8v 5p"
            },
            {
               "codigo":3965,
               "nome":"Palio Celebration 1.0 Fire Flex 8V 2p"
            },
            {
               "codigo":3966,
               "nome":"Palio Celebration 1.0 Fire Flex 8V 4p"
            },
            {
               "codigo":511,
               "nome":"Palio City 1.0 4p"
            },
            {
               "codigo":512,
               "nome":"Palio City 1.5/1.6 4p"
            },
            {
               "codigo":513,
               "nome":"Palio CityMatic 1.0 mpi"
            },
            {
               "codigo":514,
               "nome":"Palio ED 1.0 mpi 2p e 4p"
            },
            {
               "codigo":515,
               "nome":"Palio EDX 1.0 mpi 2p"
            },
            {
               "codigo":516,
               "nome":"Palio EDX 1.0 mpi 4p"
            },
            {
               "codigo":517,
               "nome":"Palio EL 1.5 mpi 2p e 4p"
            },
            {
               "codigo":518,
               "nome":"Palio EL 1.6 spi 2p e 4p"
            },
            {
               "codigo":521,
               "nome":"Palio ELX 1.0 Fire/30 Anos F. Flex 8V 4p"
            },
            {
               "codigo":520,
               "nome":"Palio ELX 1.0 mpi Fire 16v 4p (25 anos)"
            },
            {
               "codigo":519,
               "nome":"Palio ELX 1.0/ 1.0 Fire Flex 8V 2p"
            },
            {
               "codigo":522,
               "nome":"Palio ELX 1.3 mpi  Fire 16V 4p"
            },
            {
               "codigo":523,
               "nome":"Palio ELX 1.3 mpi Flex 8V 4p"
            },
            {
               "codigo":524,
               "nome":"Palio ELX 1.4 Fire/30 Anos F. Flex 8V 4p"
            },
            {
               "codigo":525,
               "nome":"Palio ELX 1.5 4p"
            },
            {
               "codigo":526,
               "nome":"Palio ELX 1.6 4p"
            },
            {
               "codigo":527,
               "nome":"Palio ELX 1.6 mpi 16v 4p"
            },
            {
               "codigo":528,
               "nome":"Palio ELX 1.8/ 1.8 mpi Flex 8V 4p"
            },
            {
               "codigo":4967,
               "nome":"Palio ELX Dualogic 1.8 mpi Flex 8V 4p"
            },
            {
               "codigo":529,
               "nome":"Palio ELX/ 500 1.0 4p"
            },
            {
               "codigo":5293,
               "nome":"Palio ESSENCE 1.6 Flex 16V 5p"
            },
            {
               "codigo":5294,
               "nome":"Palio ESSENCE Dualogic 1.6 Flex 16V 5p"
            },
            {
               "codigo":530,
               "nome":"Palio EX 1.0 mpi 2p"
            },
            {
               "codigo":531,
               "nome":"Palio EX 1.0 mpi 4p"
            },
            {
               "codigo":533,
               "nome":"Palio EX 1.0 mpi Fire 8v 4p"
            },
            {
               "codigo":532,
               "nome":"Palio EX 1.0 mpi Fire/ Fire Flex 8v 2p"
            },
            {
               "codigo":534,
               "nome":"Palio EX 1.3 mpi Fire 8V 67cv 2p"
            },
            {
               "codigo":535,
               "nome":"Palio EX 1.3 mpi Fire 8V 67cv 4p"
            },
            {
               "codigo":536,
               "nome":"Palio EX 1.8 mpi 8V 103cv 4p"
            },
            {
               "codigo":537,
               "nome":"Palio EX Century 1.0 mpi Fire 16v 2p"
            },
            {
               "codigo":538,
               "nome":"Palio EX Century 1.0 mpi Fire 16v 4p"
            },
            {
               "codigo":539,
               "nome":"Palio HLX 1.8 mpi 8V 103cv 4p"
            },
            {
               "codigo":540,
               "nome":"Palio HLX 1.8 mpi Flex 8V 4p"
            },
            {
               "codigo":7026,
               "nome":"Palio Rua 1.0 Fire Flex 8V 5p"
            },
            {
               "codigo":6318,
               "nome":"Palio SPORT.INTERLAGOS 1.6 Flex 16V"
            },
            {
               "codigo":6319,
               "nome":"Palio SPORT.INTERLAGOS Dual.1.6 Flex 16V"
            },
            {
               "codigo":5696,
               "nome":"Palio SPORTING 1.6 Flex 16V 5p"
            },
            {
               "codigo":7614,
               "nome":"Palio SPORTING B.Edit. 1.6 Flex 16V 5p"
            },
            {
               "codigo":7615,
               "nome":"Palio SPORTING Dual. B.Edit. 1.6 Flex 5p"
            },
            {
               "codigo":5697,
               "nome":"Palio SPORTING Dualogic 1.6 Flex 16V 5p"
            },
            {
               "codigo":541,
               "nome":"Palio Stile 1.6 mpi 16v 4p"
            },
            {
               "codigo":5879,
               "nome":"Palio W.Adv. LOC. ITAL.Dual.1.8 Flex 16V"
            },
            {
               "codigo":5881,
               "nome":"Palio W.ADV. LOCK. ITALIA 1.8 Flex 16V"
            },
            {
               "codigo":6836,
               "nome":"Palio Way 1.0 Fire Flex 8V 5p"
            },
            {
               "codigo":6837,
               "nome":"Palio Way Celebration 1.0 F. Flex 8V 5p"
            },
            {
               "codigo":5619,
               "nome":"Palio Week. Adv. Dualogic 1.8 Flex"
            },
            {
               "codigo":5880,
               "nome":"Palio Week. Adv. ITALIA 1.8 Flex 16V"
            },
            {
               "codigo":547,
               "nome":"Palio Week. Adv/Adv TRYON 1.8 mpi Flex"
            },
            {
               "codigo":5272,
               "nome":"Palio Week. ATTRACTIVE 1.4 Fire Flex 8V"
            },
            {
               "codigo":5882,
               "nome":"Palio Week.Adv. ITAL. Dual. 1.8 Flex 16V"
            },
            {
               "codigo":4968,
               "nome":"Palio Week.Adv.LOCK.Dualogic 1.8 Flex"
            },
            {
               "codigo":542,
               "nome":"Palio Weekend 1.0 6-marchas"
            },
            {
               "codigo":543,
               "nome":"Palio Weekend 1.5 mpi 4p"
            },
            {
               "codigo":544,
               "nome":"Palio Weekend 1.6 mpi 16V 4p"
            },
            {
               "codigo":7542,
               "nome":"Palio Weekend Adv. Ext. 1.8 Dual. Flex"
            },
            {
               "codigo":7543,
               "nome":"Palio Weekend Adv. Ext. 1.8 Flex"
            },
            {
               "codigo":7544,
               "nome":"Palio Weekend Adv. Loc.Ext.1.8 Dual.Flex"
            },
            {
               "codigo":7545,
               "nome":"Palio Weekend Adv. LOCKER Ext. 1.8 Flex"
            },
            {
               "codigo":545,
               "nome":"Palio Weekend Adventure 1.6 8V/16V"
            },
            {
               "codigo":546,
               "nome":"Palio Weekend Adventure 1.8 8V 103cv 4p"
            },
            {
               "codigo":4673,
               "nome":"Palio Weekend Adventure LOCKER 1.8 Flex"
            },
            {
               "codigo":548,
               "nome":"Palio Weekend City 1.5/ 1.6 4p"
            },
            {
               "codigo":549,
               "nome":"Palio Weekend ELX 1.0 mpi Fire 16V"
            },
            {
               "codigo":550,
               "nome":"Palio Weekend ELX 1.3 mpi  Fire 16V"
            },
            {
               "codigo":551,
               "nome":"Palio Weekend ELX 1.3 mpi Flex 8V 4p"
            },
            {
               "codigo":552,
               "nome":"Palio Weekend ELX 1.4 mpi Fire Flex 8V"
            },
            {
               "codigo":553,
               "nome":"Palio Weekend ELX 1.5 mpi 4p"
            },
            {
               "codigo":554,
               "nome":"Palio Weekend ELX 1.6 mpi"
            },
            {
               "codigo":555,
               "nome":"Palio Weekend EX 1.3 mpi Fire 8V 67cv 4p"
            },
            {
               "codigo":556,
               "nome":"Palio Weekend EX 1.5 mpi"
            },
            {
               "codigo":557,
               "nome":"Palio Weekend EX 1.8 mpi 8V 103cv 4p"
            },
            {
               "codigo":558,
               "nome":"Palio Weekend HLX 1.8 mpi Flex 4p"
            },
            {
               "codigo":559,
               "nome":"Palio Weekend Sport 1.6 mpi 16V 4p"
            },
            {
               "codigo":560,
               "nome":"Palio Weekend Stile 1.6 mpi 16V 4p"
            },
            {
               "codigo":561,
               "nome":"Palio Weekend Stile 1.8 mpi 8V 103cv 4p"
            },
            {
               "codigo":4674,
               "nome":"Palio Weekend Trekking 1.4 Fire Flex 8V"
            },
            {
               "codigo":5412,
               "nome":"Palio Weekend Trekking 1.6 Flex 16V 5p"
            },
            {
               "codigo":5063,
               "nome":"Palio Weekend Trekking 1.8 mpi Flex 8V"
            },
            {
               "codigo":562,
               "nome":"Palio Young 1.0 mpi 8v 2p"
            },
            {
               "codigo":563,
               "nome":"Palio Young 1.0 mpi 8v 4p"
            },
            {
               "codigo":564,
               "nome":"Palio Young 1.0 mpi Fire 8V 2p"
            },
            {
               "codigo":565,
               "nome":"Palio Young 1.0 mpi Fire 8V 4p"
            },
            {
               "codigo":566,
               "nome":"Panorama C/CL"
            },
            {
               "codigo":567,
               "nome":"Premio CS 1.5 i.e. 2p/ SL 1.6/1.5/1.3 4p"
            },
            {
               "codigo":568,
               "nome":"Premio CS 1.6/ 1.5/ 1.3 2p"
            },
            {
               "codigo":569,
               "nome":"Premio CSL 1.6 i.e./ 1.5 4p"
            },
            {
               "codigo":570,
               "nome":"Premio CSL 1.6/ 1.5"
            },
            {
               "codigo":571,
               "nome":"Premio S 1.5 i.e./ 1.5 / 1.3"
            },
            {
               "codigo":10219,
               "nome":"PULSE ABARTH 1.3 Turbo 16V Flex Aut."
            },
            {
               "codigo":9645,
               "nome":"PULSE AUDACE 1.0 Turbo 200 Flex Aut."
            },
            {
               "codigo":9646,
               "nome":"PULSE DRIVE 1.0 Turbo 200 Flex Aut."
            },
            {
               "codigo":9647,
               "nome":"PULSE DRIVE 1.3 8V Flex Aut."
            },
            {
               "codigo":9648,
               "nome":"PULSE DRIVE 1.3 8V Flex Mec. "
            },
            {
               "codigo":9649,
               "nome":"PULSE IMPETUS 1.0 TURBO 200 Flex Aut."
            },
            {
               "codigo":4421,
               "nome":"Punto  ELX 1.4 Fire Flex 8V 5p"
            },
            {
               "codigo":4422,
               "nome":"Punto 1.4 Fire Flex 8V 5p"
            },
            {
               "codigo":5273,
               "nome":"Punto ATTRACTIVE 1.4 Fire Flex 8V 5p"
            },
            {
               "codigo":5883,
               "nome":"Punto ATTRACTIVE ITALIA 1.4 F.Flex 8V 5p"
            },
            {
               "codigo":6560,
               "nome":"Punto BLACKMOTION 1.8 Flex 16V 5p."
            },
            {
               "codigo":6561,
               "nome":"Punto BLACKMOTION Dual. 1.8 Flex 16v 5p"
            },
            {
               "codigo":572,
               "nome":"Punto Cabrio"
            },
            {
               "codigo":573,
               "nome":"Punto EL/ELX"
            },
            {
               "codigo":5295,
               "nome":"Punto ESSENCE 1.6 Flex 16V 5p"
            },
            {
               "codigo":5296,
               "nome":"Punto ESSENCE 1.8 Flex 16V 5p"
            },
            {
               "codigo":5463,
               "nome":"Punto ESSENCE Dualogic 1.6 Flex 16V 5p"
            },
            {
               "codigo":5297,
               "nome":"Punto ESSENCE Dualogic 1.8 Flex 16V 5p"
            },
            {
               "codigo":7182,
               "nome":"Punto ESSENCE SP 1.6 Flex 16V 5p"
            },
            {
               "codigo":7226,
               "nome":"Punto ESSENCE SP Dualogic 1.6 Flex 16V"
            },
            {
               "codigo":4423,
               "nome":"Punto HLX 1.8 Flex 8V 5p"
            },
            {
               "codigo":574,
               "nome":"Punto S"
            },
            {
               "codigo":4424,
               "nome":"Punto Sporting 1.8 Flex 8V/16V 5p"
            },
            {
               "codigo":5298,
               "nome":"Punto Sporting Dualogic 1.8 Flex 16V 5p"
            },
            {
               "codigo":575,
               "nome":"Punto SX"
            },
            {
               "codigo":4859,
               "nome":"Punto T-JET 1.4 16V Turbo 5p"
            },
            {
               "codigo":9919,
               "nome":"Scudo Cargo 1.5 16V Turbo Diesel"
            },
            {
               "codigo":9920,
               "nome":"Scudo Multi 1.5 16 V Turbo Diesel"
            },
            {
               "codigo":576,
               "nome":"Siena 1.0 mpi/ 500 1.0 mpi"
            },
            {
               "codigo":577,
               "nome":"Siena 1.0/ EX 1.0 mpi Fire/ Fire Flex 8v"
            },
            {
               "codigo":578,
               "nome":"Siena 1.5 mpi 8V 4p"
            },
            {
               "codigo":5299,
               "nome":"Siena ATTRACTIVE 1.0 Fire Flex 8V 4p"
            },
            {
               "codigo":5300,
               "nome":"Siena ATTRACTIVE 1.4 Fire Flex 8V 4p"
            },
            {
               "codigo":3967,
               "nome":"Siena Celebration 1.0 Fire Flex 8V 4p"
            },
            {
               "codigo":579,
               "nome":"Siena City 4p"
            },
            {
               "codigo":4860,
               "nome":"Siena EL 1.0 mpi Fire Flex 8V 4p"
            },
            {
               "codigo":5361,
               "nome":"Siena EL 1.4 mpi Fire Flex 8V 4p"
            },
            {
               "codigo":580,
               "nome":"Siena EL 1.6 mpi 16V"
            },
            {
               "codigo":581,
               "nome":"Siena EL 1.6 spi"
            },
            {
               "codigo":4861,
               "nome":"Siena EL Celeb. 1.0 mpi Fire Flex 8V 4p"
            },
            {
               "codigo":5362,
               "nome":"Siena EL Celeb. 1.4 mpi Fire Flex 8V 4p"
            },
            {
               "codigo":582,
               "nome":"Siena ELX 1.0 mpi Fire 16v 4p (25 anos)"
            },
            {
               "codigo":583,
               "nome":"Siena ELX 1.0 mpi Fire/Fire Flex 8V 4p"
            },
            {
               "codigo":584,
               "nome":"Siena ELX 1.3 mpi Fire 16V 4p"
            },
            {
               "codigo":585,
               "nome":"Siena ELX 1.3 mpi Flex 8V 4p"
            },
            {
               "codigo":586,
               "nome":"Siena ELX 1.4 mpi Fire Flex 8V 4p"
            },
            {
               "codigo":587,
               "nome":"Siena ELX 1.5 mpi 4p"
            },
            {
               "codigo":588,
               "nome":"Siena ELX 1.6 mpi 8V/16V 4p"
            },
            {
               "codigo":589,
               "nome":"Siena ELX 1.8 mpi 8V 103cv 4p"
            },
            {
               "codigo":5301,
               "nome":"Siena ESSENCE 1.6 Flex 16V 4p"
            },
            {
               "codigo":5302,
               "nome":"Siena ESSENCE Dualogic 1.6 Flex 16V 4p"
            },
            {
               "codigo":590,
               "nome":"Siena EX 1.0 mpi Fire 16v 4p"
            },
            {
               "codigo":591,
               "nome":"Siena EX 1.3 mpi Fire 8V 67cv 4p"
            },
            {
               "codigo":592,
               "nome":"Siena EX 1.8 mpi 8V 103cv 4p"
            },
            {
               "codigo":593,
               "nome":"Siena HL 1.6 mpi 16V"
            },
            {
               "codigo":594,
               "nome":"Siena HLX 1.8 mpi Flex 8V 4p"
            },
            {
               "codigo":4969,
               "nome":"Siena HLX Dualogic 1.8 mpi Flex 8V 4p"
            },
            {
               "codigo":5363,
               "nome":"Siena Sporting 1.6 Flex 16V 4p"
            },
            {
               "codigo":5364,
               "nome":"Siena Sporting Dualogic 1.6 Flex 16V 4p"
            },
            {
               "codigo":595,
               "nome":"Siena Stile/Sport MTV 1.6 mpi 16V"
            },
            {
               "codigo":4133,
               "nome":"Siena TETRAFUEL 1.4 mpi Fire Flex 8v 4p"
            },
            {
               "codigo":5064,
               "nome":"Stilo 1.8 ATTRACTIVE Flex 8V 5p"
            },
            {
               "codigo":597,
               "nome":"Stilo 1.8 MS Lim.Edit./ MS Season 16V"
            },
            {
               "codigo":4187,
               "nome":"Stilo 1.8 SP Flex 8V 5p"
            },
            {
               "codigo":4329,
               "nome":"Stilo 1.8 Sporting Flex 8V 5P"
            },
            {
               "codigo":596,
               "nome":"Stilo 1.8/ 1.8 Connect 8V 103cv 5p"
            },
            {
               "codigo":3813,
               "nome":"Stilo 1.8/ 1.8 Connect Flex 8V 5p"
            },
            {
               "codigo":598,
               "nome":"Stilo 1.8/ 1.8 SP/ Connect 16V 122cv 5p"
            },
            {
               "codigo":599,
               "nome":"Stilo 2.4 Abarth 20V 167cv 5p"
            },
            {
               "codigo":10065,
               "nome":"Stilo Dualogic 1.8 ATTRACTIVE Flex 8V 5p"
            },
            {
               "codigo":4862,
               "nome":"Stilo Dualogic 1.8 BlackMotion Flex 8V"
            },
            {
               "codigo":4529,
               "nome":"Stilo Dualogic 1.8 Flex 8V 5p"
            },
            {
               "codigo":4530,
               "nome":"Stilo Dualogic 1.8 SP Flex 8V 5p"
            },
            {
               "codigo":4531,
               "nome":"Stilo Dualogic 1.8 Sporting Flex 8V 5p"
            },
            {
               "codigo":600,
               "nome":"Strada 1.3 mpi Fire 8V 67cv CE"
            },
            {
               "codigo":601,
               "nome":"Strada 1.3 mpi Fire 8V 67cv CS"
            },
            {
               "codigo":602,
               "nome":"Strada 1.4 mpi Fire Flex 8V CE"
            },
            {
               "codigo":603,
               "nome":"Strada 1.4 mpi Fire Flex 8V CS"
            },
            {
               "codigo":7450,
               "nome":"Strada Adv. Ext. 1.8 LOCKER Dual.Flex CE"
            },
            {
               "codigo":7451,
               "nome":"Strada Adv. Ext./ Ext.1.8 LOCKER Flex CE"
            },
            {
               "codigo":7452,
               "nome":"Strada Adv. Extreme 1.8 Dual. Flex CE"
            },
            {
               "codigo":6517,
               "nome":"Strada Adv. M. March. 1.8 Flex 16V CD"
            },
            {
               "codigo":6518,
               "nome":"Strada Adv. M. March.1.8 Dual. Flex CD"
            },
            {
               "codigo":6636,
               "nome":"Strada Adv.1.8 16V  LOCKER Dual. Flex CE"
            },
            {
               "codigo":5620,
               "nome":"Strada Adv.1.8 16V Dualogic Flex CD"
            },
            {
               "codigo":6637,
               "nome":"Strada Adv.1.8 16V Dualogic Flex CE"
            },
            {
               "codigo":5621,
               "nome":"Strada Adv.1.8 16V LOCKER Dualo. Flex CD"
            },
            {
               "codigo":7453,
               "nome":"Strada Adv.Ext. 1.8 LOCKER Dual. Flex CD"
            },
            {
               "codigo":7454,
               "nome":"Strada Adv.Ext./ Ext. 1.8 LOCKER Flex CD"
            },
            {
               "codigo":606,
               "nome":"Strada Adv/Adv TRYON 1.8 mpi Flex 8V CE"
            },
            {
               "codigo":604,
               "nome":"Strada Adventure 1.6 mpi 16V CE"
            },
            {
               "codigo":605,
               "nome":"Strada Adventure 1.8 mpi 8V 103cv CE"
            },
            {
               "codigo":4733,
               "nome":"Strada Adventure 1.8/ 1.8 LOCKER Flex CE"
            },
            {
               "codigo":7455,
               "nome":"Strada Adventure Ext. 1.8  Dual. Flex CD"
            },
            {
               "codigo":4970,
               "nome":"Strada Adventure1.8/ 1.8 LOCKER Flex CD"
            },
            {
               "codigo":5274,
               "nome":"Strada Celeb. 1.4 mpi Fire Flex 8V CE"
            },
            {
               "codigo":5275,
               "nome":"Strada Celeb. 1.4 mpi Fire Flex 8V CS"
            },
            {
               "codigo":9108,
               "nome":"Strada Endurance 1.4 Flex 8V CD"
            },
            {
               "codigo":9109,
               "nome":"Strada Endurance 1.4 Flex 8V CS Plus"
            },
            {
               "codigo":9110,
               "nome":"Strada Freedom 1.3 Flex 8V  CS Plus"
            },
            {
               "codigo":9111,
               "nome":"Strada Freedom 1.3 Flex 8V CD"
            },
            {
               "codigo":8399,
               "nome":"Strada Freedom 1.4 Flex 8V CD"
            },
            {
               "codigo":8544,
               "nome":"Strada Freedom 1.4 Flex 8V CS"
            },
            {
               "codigo":607,
               "nome":"Strada LX 1.6 16V CE"
            },
            {
               "codigo":608,
               "nome":"Strada LX 1.6 mpi 16V"
            },
            {
               "codigo":9229,
               "nome":"Strada Opening Edition 1.3 Flex 8V CD"
            },
            {
               "codigo":9723,
               "nome":"Strada Ranch 1.3 Flex 8V CD Aut."
            },
            {
               "codigo":5464,
               "nome":"Strada Sporting 1.8 Flex 16V CE"
            },
            {
               "codigo":6519,
               "nome":"Strada Trek. M. March. 1.6 Flex 16V CE"
            },
            {
               "codigo":3884,
               "nome":"Strada Trekking 1.4 mpi Fire Flex 8V CE"
            },
            {
               "codigo":3885,
               "nome":"Strada Trekking 1.4 mpi Fire Flex 8V CS"
            },
            {
               "codigo":6055,
               "nome":"Strada Trekking 1.6 16V Flex CD"
            },
            {
               "codigo":6056,
               "nome":"Strada Trekking 1.6 16V Flex CE"
            },
            {
               "codigo":6057,
               "nome":"Strada Trekking 1.6 16V Flex CS"
            },
            {
               "codigo":7227,
               "nome":"Strada Trekking 1.6 16V LOCKER Flex  CD"
            },
            {
               "codigo":609,
               "nome":"Strada Trekking 1.6 mpi"
            },
            {
               "codigo":610,
               "nome":"Strada Trekking 1.8 mpi Flex 8V CE"
            },
            {
               "codigo":611,
               "nome":"Strada Trekking 1.8 mpi Flex 8V CS"
            },
            {
               "codigo":9112,
               "nome":"Strada Volcano 1.3 Flex 8V CD"
            },
            {
               "codigo":9724,
               "nome":"Strada Volcano 1.3 Flex 8V CD Aut."
            },
            {
               "codigo":5066,
               "nome":"Strada Working 1.4 mpi Fire Flex 8V CD"
            },
            {
               "codigo":5067,
               "nome":"Strada Working 1.4 mpi Fire Flex 8V CE"
            },
            {
               "codigo":5068,
               "nome":"Strada Working 1.4 mpi Fire Flex 8V CS"
            },
            {
               "codigo":612,
               "nome":"Strada Working 1.6 mpi 16V CE"
            },
            {
               "codigo":613,
               "nome":"Strada Working 1.6 mpi 16V CS"
            },
            {
               "codigo":614,
               "nome":"Strada Working 1.8 mpi 8v 103cv CE"
            },
            {
               "codigo":615,
               "nome":"Strada Working 1.8 mpi 8V 103cv CS"
            },
            {
               "codigo":6096,
               "nome":"Strada Working Celeb.1.4 Fire Flex 8V CD"
            },
            {
               "codigo":6097,
               "nome":"Strada Working Celeb.1.4 Fire Flex 8V CE"
            },
            {
               "codigo":6098,
               "nome":"Strada Working Celeb.1.4 Fire Flex 8V CS"
            },
            {
               "codigo":7134,
               "nome":"Strada Working HARD 1.4 Fire Flex 8V CD"
            },
            {
               "codigo":7135,
               "nome":"Strada Working HARD 1.4 Fire Flex 8V CE"
            },
            {
               "codigo":7136,
               "nome":"Strada Working HARD 1.4 Fire Flex 8V CS"
            },
            {
               "codigo":7752,
               "nome":"Strada Working Plus 1.4 8V Flex CS"
            },
            {
               "codigo":616,
               "nome":"Strada/ Strada Working 1.5 mpi 8V CE"
            },
            {
               "codigo":617,
               "nome":"Strada/ Strada Working 1.5 mpi 8V CS"
            },
            {
               "codigo":618,
               "nome":"Tempra 2.0 i.e 16V 2p e 4p"
            },
            {
               "codigo":619,
               "nome":"Tempra 2.0 i.e. 8V 2p e 4p"
            },
            {
               "codigo":620,
               "nome":"Tempra 2.0 mpi 16V"
            },
            {
               "codigo":621,
               "nome":"Tempra 8V/ City 8V"
            },
            {
               "codigo":622,
               "nome":"Tempra HLX 2.0 16V 4p"
            },
            {
               "codigo":623,
               "nome":"Tempra Ouro 16V 2p e 4p"
            },
            {
               "codigo":624,
               "nome":"Tempra Ouro/Prata 2.0 2p e 4p"
            },
            {
               "codigo":625,
               "nome":"Tempra Stile 2.0 i.e. Turbo 4p"
            },
            {
               "codigo":626,
               "nome":"Tempra SW SLX 2.0 i.e."
            },
            {
               "codigo":627,
               "nome":"Tempra SX 2.0 16V 4p"
            },
            {
               "codigo":628,
               "nome":"Tempra SX 2.0 i.e. 8V 4p"
            },
            {
               "codigo":629,
               "nome":"Tempra Turbo 2.0 i.e. 2p"
            },
            {
               "codigo":630,
               "nome":"Tipo 1.6 i.e. 2p e 4p"
            },
            {
               "codigo":631,
               "nome":"Tipo 1.6 mpi 4p"
            },
            {
               "codigo":632,
               "nome":"Tipo 2.0 16V 2p/4p"
            },
            {
               "codigo":633,
               "nome":"Tipo 2.0 SLX 4p"
            },
            {
               "codigo":8046,
               "nome":"Toro Blackjack 2.4 16V flex Aut"
            },
            {
               "codigo":9493,
               "nome":"Toro Endurance 1.3 T270 4x2 Flex Aut."
            },
            {
               "codigo":8236,
               "nome":"Toro Endurance 1.8 16V Flex Aut."
            },
            {
               "codigo":8741,
               "nome":"Toro Endurance 1.8 16V Flex Mec."
            },
            {
               "codigo":8742,
               "nome":"Toro Endurance 2.0 16V 4x4 Diesel Aut."
            },
            {
               "codigo":9494,
               "nome":"Toro Freedom 1.3 T270 4x2 Flex Aut."
            },
            {
               "codigo":7475,
               "nome":"Toro Freedom 1.8 16V Flex Aut."
            },
            {
               "codigo":7476,
               "nome":"Toro Freedom 2.0 16V 4x2 TB Diesel Mec."
            },
            {
               "codigo":10069,
               "nome":"Toro Freedom 2.0 16V 4x4 TB Diesel Aut."
            },
            {
               "codigo":7477,
               "nome":"Toro Freedom 2.0 16V 4x4 TB Diesel Mec."
            },
            {
               "codigo":7789,
               "nome":"Toro Freedom 2.4 16V Flex Aut."
            },
            {
               "codigo":7950,
               "nome":"Toro Freedom Road 1.8 16V Flex Aut."
            },
            {
               "codigo":7951,
               "nome":"Toro Freedom Road 2.4 16V Flex Aut."
            },
            {
               "codigo":7921,
               "nome":"Toro Opening Ed. Plus 1.8 16V Flex Aut."
            },
            {
               "codigo":7478,
               "nome":"Toro Opening Edition 1.8 16V Flex Aut."
            },
            {
               "codigo":10070,
               "nome":"Toro Ranch 2.0 16V 4x4 TB Diesel Aut."
            },
            {
               "codigo":10071,
               "nome":"Toro Ultra 2.0 16V 4x4 TB Diesel Aut."
            },
            {
               "codigo":9495,
               "nome":"Toro Volcano 1.3 T270 4x2 Flex Aut."
            },
            {
               "codigo":7479,
               "nome":"Toro Volcano 2.0 16V 4x4 TB Diesel Aut."
            },
            {
               "codigo":8237,
               "nome":"Toro Volcano 2.4 16V Flex Aut."
            },
            {
               "codigo":634,
               "nome":"Uno 1.6 mpi 2p e 4p"
            },
            {
               "codigo":635,
               "nome":"Uno 1.6R mpi / 1.6R / 1.5R"
            },
            {
               "codigo":5480,
               "nome":"UNO ATTRACTI. Celeb.1.4 EVO F.Flex 8V 2p"
            },
            {
               "codigo":5240,
               "nome":"UNO ATTRACTI. Celeb.1.4 EVO F.Flex 8V 4p"
            },
            {
               "codigo":6957,
               "nome":"UNO ATTRACTIVE 1.0 Fire Flex 8V 5p"
            },
            {
               "codigo":7732,
               "nome":"UNO ATTRACTIVE 1.0 Flex 6V 5p"
            },
            {
               "codigo":5481,
               "nome":"UNO ATTRACTIVE 1.4 EVO Fire Flex 8V 2p"
            },
            {
               "codigo":5239,
               "nome":"UNO ATTRACTIVE 1.4 EVO Fire Flex 8V 4p"
            },
            {
               "codigo":9725,
               "nome":"UNO CIAO 1.0 Fire Flex 8V 5p"
            },
            {
               "codigo":636,
               "nome":"Uno City / Smart City 1.0 4p"
            },
            {
               "codigo":637,
               "nome":"Uno CS/Top/Sport 1.5 i.e. / 1.5 /1.3"
            },
            {
               "codigo":638,
               "nome":"Uno CS/Top/Sport 1.5 i.e. / 1.5 4p"
            },
            {
               "codigo":639,
               "nome":"Uno CSL 1.6 4p (Argentino)"
            },
            {
               "codigo":7952,
               "nome":"UNO DRIVE 1.0 Flex 6V 5p"
            },
            {
               "codigo":5664,
               "nome":"UNO ECONOMY 1.4 EVO Fire Flex 8V 2p"
            },
            {
               "codigo":5665,
               "nome":"UNO ECONOMY 1.4 EVO Fire Flex 8V 4p"
            },
            {
               "codigo":5666,
               "nome":"UNO ECONOMY Celeb. 1.4 EVO F. Flex 8V 2p"
            },
            {
               "codigo":5667,
               "nome":"UNO ECONOMY Celeb. 1.4 EVO F. Flex 8V 4p"
            },
            {
               "codigo":6958,
               "nome":"UNO EVOLUTION 1.4 Fire Flex 8V 5p"
            },
            {
               "codigo":7119,
               "nome":"UNO Furgão 1.0 Fire Flex 8V 3p"
            },
            {
               "codigo":640,
               "nome":"Uno Furgão 1.3 mpi Fire/ Fire Flex 8V"
            },
            {
               "codigo":641,
               "nome":"Uno Furgão 1.5 mpi/i.e."
            },
            {
               "codigo":642,
               "nome":"Uno Furgão 1.5/ 1.3"
            },
            {
               "codigo":643,
               "nome":"Uno Mille  ELX  2p e 4p"
            },
            {
               "codigo":646,
               "nome":"Uno Mille 1.0 Electronic 4p"
            },
            {
               "codigo":644,
               "nome":"Uno Mille 1.0 Fire/ F.Flex/ ECONOMY 2p"
            },
            {
               "codigo":645,
               "nome":"Uno Mille 1.0 Fire/ F.Flex/ ECONOMY 4p"
            },
            {
               "codigo":647,
               "nome":"Uno Mille 1.0/ i.e./Electronic/Brio 2p"
            },
            {
               "codigo":4763,
               "nome":"Uno Mille Celeb. WAY ECON. 1.0 F.Flex 2p"
            },
            {
               "codigo":4764,
               "nome":"Uno Mille Celeb. WAY ECON. 1.0 F.Flex 4p"
            },
            {
               "codigo":4316,
               "nome":"Uno Mille Celeb/Celeb.ECON 1.0 F.Flex 2p"
            },
            {
               "codigo":4317,
               "nome":"Uno Mille Celeb/Celeb.ECON 1.0 F.Flex 4p"
            },
            {
               "codigo":648,
               "nome":"Uno Mille EP 2p e 4p"
            },
            {
               "codigo":6692,
               "nome":"Uno Mille Grazie 1.0 Fire Flex 8v 4p"
            },
            {
               "codigo":650,
               "nome":"Uno Mille SX 2p e 4p"
            },
            {
               "codigo":651,
               "nome":"Uno Mille SX Young 1.0 IE"
            },
            {
               "codigo":6320,
               "nome":"UNO MILLE WAY ECO.XINGU 1.0 F.Flex 8v 5p"
            },
            {
               "codigo":4765,
               "nome":"Uno Mille WAY ECONOMY 1.0 F.Flex 2p"
            },
            {
               "codigo":4766,
               "nome":"Uno Mille WAY ECONOMY 1.0 F.Flex 4p"
            },
            {
               "codigo":649,
               "nome":"Uno Mille/ Mille EX/ Smart 2p"
            },
            {
               "codigo":652,
               "nome":"Uno Mille/ Mille EX/ Smart 4p"
            },
            {
               "codigo":653,
               "nome":"Uno S 1.5 i.e. / 1.5 / 1.3/ SX 1.3"
            },
            {
               "codigo":7616,
               "nome":"UNO SPORT. Dual. 1.4 B.Edit. Flex 8V 5p"
            },
            {
               "codigo":6321,
               "nome":"UNO SPORT.INTERLAGOS 1.4 EVO F.Flex 8v"
            },
            {
               "codigo":7733,
               "nome":"UNO SPORTING 1.3 Flex 8V 5p"
            },
            {
               "codigo":7617,
               "nome":"UNO SPORTING 1.4 B.Edit. Flex 8V 5p"
            },
            {
               "codigo":5482,
               "nome":"UNO SPORTING 1.4 EVO Fire Flex 8V 2p"
            },
            {
               "codigo":5465,
               "nome":"UNO SPORTING 1.4 EVO Fire Flex 8V 4p"
            },
            {
               "codigo":7734,
               "nome":"UNO SPORTING Dual./GSR 1.3 Flex 8V 5p"
            },
            {
               "codigo":7070,
               "nome":"UNO SPORTING Dualogic 1.4 EVO Flex 8V 4p"
            },
            {
               "codigo":654,
               "nome":"Uno Turbo 1.4 i.e. 2p"
            },
            {
               "codigo":5483,
               "nome":"UNO VIVACE 1.0 EVO Fire Flex 8V 3p"
            },
            {
               "codigo":5484,
               "nome":"UNO VIVACE Celeb. 1.0 EVO F. Flex 8V 3p"
            },
            {
               "codigo":5242,
               "nome":"UNO VIVACE Celeb. 1.0 EVO F.Flex 8V 5p"
            },
            {
               "codigo":6520,
               "nome":"UNO VIVACE College 1.0 EVO FireFlex 5p"
            },
            {
               "codigo":7149,
               "nome":"UNO VIVACE ITALIA 1.0 EVO F. Flex 8V 5p"
            },
            {
               "codigo":5241,
               "nome":"UNO VIVACE/RUA 1.0 EVO Fire Flex 8V 5p"
            },
            {
               "codigo":5485,
               "nome":"UNO WAY 1.0 EVO Fire Flex 8V 2p"
            },
            {
               "codigo":5243,
               "nome":"UNO WAY 1.0 EVO Fire Flex 8V 5p"
            },
            {
               "codigo":7735,
               "nome":"UNO WAY 1.0 Flex 6V 5p"
            },
            {
               "codigo":7736,
               "nome":"UNO WAY 1.3 Flex 8V 5p"
            },
            {
               "codigo":7027,
               "nome":"UNO WAY 1.4 EVO Dualogic Flex 8V 5p"
            },
            {
               "codigo":5486,
               "nome":"UNO WAY 1.4 EVO Fire Flex 8V 2p"
            },
            {
               "codigo":5244,
               "nome":"UNO WAY 1.4 EVO Fire Flex 8V 5p"
            },
            {
               "codigo":5487,
               "nome":"UNO WAY Celeb. 1.0 EVO Fire Flex 8V 2p"
            },
            {
               "codigo":5245,
               "nome":"UNO WAY Celeb. 1.0 EVO Fire Flex 8V 5p"
            },
            {
               "codigo":5488,
               "nome":"UNO WAY Celeb. 1.4 EVO Fire Flex 8V 2p"
            },
            {
               "codigo":5246,
               "nome":"UNO WAY Celeb. 1.4 EVO Fire Flex 8V 5p"
            },
            {
               "codigo":7737,
               "nome":"UNO WAY Dual./GSR 1.3 Flex 8V 5p"
            },
            {
               "codigo":7183,
               "nome":"UNO WAY RIO 450 1.0 EVO Flex 8V 5p"
            },
            {
               "codigo":6322,
               "nome":"UNO WAY XINGU 1.0 EVO F.Flex 8V 5P"
            },
            {
               "codigo":6323,
               "nome":"UNO WAY XINGU 1.4 EVO F.Flex 8V 5p"
            }
         ],
         "anos":[
            {
               "codigo":"32000-1",
               "nome":"32000 Gasolina"
            },
            {
               "codigo":"32000-3",
               "nome":"32000 Diesel"
            },
            {
               "codigo":"2024-1",
               "nome":"2024 Gasolina"
            },
            {
               "codigo":"2024-3",
               "nome":"2024 Diesel"
            },
            {
               "codigo":"2023-1",
               "nome":"2023 Gasolina"
            },
            {
               "codigo":"2023-3",
               "nome":"2023 Diesel"
            },
            {
               "codigo":"2022-1",
               "nome":"2022 Gasolina"
            },
            {
               "codigo":"2022-3",
               "nome":"2022 Diesel"
            },
            {
               "codigo":"2021-1",
               "nome":"2021 Gasolina"
            },
            {
               "codigo":"2021-3",
               "nome":"2021 Diesel"
            },
            {
               "codigo":"2020-1",
               "nome":"2020 Gasolina"
            },
            {
               "codigo":"2020-3",
               "nome":"2020 Diesel"
            },
            {
               "codigo":"2019-1",
               "nome":"2019 Gasolina"
            },
            {
               "codigo":"2019-3",
               "nome":"2019 Diesel"
            },
            {
               "codigo":"2018-1",
               "nome":"2018 Gasolina"
            },
            {
               "codigo":"2018-3",
               "nome":"2018 Diesel"
            },
            {
               "codigo":"2017-1",
               "nome":"2017 Gasolina"
            },
            {
               "codigo":"2017-3",
               "nome":"2017 Diesel"
            },
            {
               "codigo":"2016-1",
               "nome":"2016 Gasolina"
            },
            {
               "codigo":"2016-3",
               "nome":"2016 Diesel"
            },
            {
               "codigo":"2015-1",
               "nome":"2015 Gasolina"
            },
            {
               "codigo":"2015-3",
               "nome":"2015 Diesel"
            },
            {
               "codigo":"2014-1",
               "nome":"2014 Gasolina"
            },
            {
               "codigo":"2014-3",
               "nome":"2014 Diesel"
            },
            {
               "codigo":"2013-1",
               "nome":"2013 Gasolina"
            },
            {
               "codigo":"2013-3",
               "nome":"2013 Diesel"
            },
            {
               "codigo":"2012-1",
               "nome":"2012 Gasolina"
            },
            {
               "codigo":"2012-3",
               "nome":"2012 Diesel"
            },
            {
               "codigo":"2011-1",
               "nome":"2011 Gasolina"
            },
            {
               "codigo":"2011-3",
               "nome":"2011 Diesel"
            },
            {
               "codigo":"2010-1",
               "nome":"2010 Gasolina"
            },
            {
               "codigo":"2010-3",
               "nome":"2010 Diesel"
            },
            {
               "codigo":"2009-1",
               "nome":"2009 Gasolina"
            },
            {
               "codigo":"2009-3",
               "nome":"2009 Diesel"
            },
            {
               "codigo":"2008-1",
               "nome":"2008 Gasolina"
            },
            {
               "codigo":"2008-3",
               "nome":"2008 Diesel"
            },
            {
               "codigo":"2007-1",
               "nome":"2007 Gasolina"
            },
            {
               "codigo":"2007-3",
               "nome":"2007 Diesel"
            },
            {
               "codigo":"2006-1",
               "nome":"2006 Gasolina"
            },
            {
               "codigo":"2006-3",
               "nome":"2006 Diesel"
            },
            {
               "codigo":"2005-1",
               "nome":"2005 Gasolina"
            },
            {
               "codigo":"2005-3",
               "nome":"2005 Diesel"
            },
            {
               "codigo":"2005-2",
               "nome":"2005 Álcool"
            },
            {
               "codigo":"2004-1",
               "nome":"2004 Gasolina"
            },
            {
               "codigo":"2004-3",
               "nome":"2004 Diesel"
            },
            {
               "codigo":"2004-2",
               "nome":"2004 Álcool"
            },
            {
               "codigo":"2003-1",
               "nome":"2003 Gasolina"
            },
            {
               "codigo":"2003-3",
               "nome":"2003 Diesel"
            },
            {
               "codigo":"2003-2",
               "nome":"2003 Álcool"
            },
            {
               "codigo":"2002-1",
               "nome":"2002 Gasolina"
            },
            {
               "codigo":"2002-3",
               "nome":"2002 Diesel"
            },
            {
               "codigo":"2002-2",
               "nome":"2002 Álcool"
            },
            {
               "codigo":"2001-1",
               "nome":"2001 Gasolina"
            },
            {
               "codigo":"2001-3",
               "nome":"2001 Diesel"
            },
            {
               "codigo":"2001-2",
               "nome":"2001 Álcool"
            },
            {
               "codigo":"2000-1",
               "nome":"2000 Gasolina"
            },
            {
               "codigo":"2000-3",
               "nome":"2000 Diesel"
            },
            {
               "codigo":"2000-2",
               "nome":"2000 Álcool"
            },
            {
               "codigo":"1999-1",
               "nome":"1999 Gasolina"
            },
            {
               "codigo":"1999-3",
               "nome":"1999 Diesel"
            },
            {
               "codigo":"1999-2",
               "nome":"1999 Álcool"
            },
            {
               "codigo":"1998-1",
               "nome":"1998 Gasolina"
            },
            {
               "codigo":"1998-3",
               "nome":"1998 Diesel"
            },
            {
               "codigo":"1998-2",
               "nome":"1998 Álcool"
            },
            {
               "codigo":"1997-1",
               "nome":"1997 Gasolina"
            },
            {
               "codigo":"1997-3",
               "nome":"1997 Diesel"
            },
            {
               "codigo":"1997-2",
               "nome":"1997 Álcool"
            },
            {
               "codigo":"1996-1",
               "nome":"1996 Gasolina"
            },
            {
               "codigo":"1996-2",
               "nome":"1996 Álcool"
            },
            {
               "codigo":"1995-1",
               "nome":"1995 Gasolina"
            },
            {
               "codigo":"1995-2",
               "nome":"1995 Álcool"
            },
            {
               "codigo":"1994-1",
               "nome":"1994 Gasolina"
            },
            {
               "codigo":"1993-1",
               "nome":"1993 Gasolina"
            },
            {
               "codigo":"1992-1",
               "nome":"1992 Gasolina"
            },
            {
               "codigo":"1991-1",
               "nome":"1991 Gasolina"
            },
            {
               "codigo":"1990-1",
               "nome":"1990 Gasolina"
            },
            {
               "codigo":"1989-1",
               "nome":"1989 Gasolina"
            },
            {
               "codigo":"1988-1",
               "nome":"1988 Gasolina"
            },
            {
               "codigo":"1987-1",
               "nome":"1987 Gasolina"
            },
            {
               "codigo":"1986-1",
               "nome":"1986 Gasolina"
            },
            {
               "codigo":"1985-1",
               "nome":"1985 Gasolina"
            }
         ]
    }
]