import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputMask from 'react-input-mask';
import apiAxios from '../../services/api';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import MyVerticallyCenteredModal from '../modals/verticallyCenteredModal';
import { loginParceiro } from '../../services/auth';
import { cidades } from '../../hooks/cidades';

const loginSchema = yup.object().shape({
    emailLogin: yup.string().email('Email inválido').required('Email é obrigatório'),
    passwordLogin: yup.string().required('Senha é obrigatória'),
  });
  
  const signupSchema = yup.object().shape({
    nomeSistema: yup.string().required('Nome do Sistema é obrigatório'),
    cnpj: yup.string().required('CNPJ é obrigatório'),
    telefone: yup.string().required('Telefone é obrigatório'),
    email: yup.string().email('Email inválido').required('Email é obrigatório'),
    senha: yup.string().required('Senha é obrigatória'),
    nome_responsavel: yup.string().required('Nome do Responsável é obrigatório'),
    rua: yup.string().required('Rua é obrigatória'),
    numero: yup.string().required('Número é obrigatório'),
    cep: yup.string().required('CEP é obrigatório'),
    bairro: yup.string().required('Bairro é obrigatório'),
    estado: yup.string().required('Estado é obrigatório'),
    cidade: yup.string().required('Cidade é obrigatória'),
  });

const SectionFormCadastroLoginParceiro = (props) => {


 const { executeRecaptcha } = useGoogleReCaptcha();
 const [modalShow, setModalShow] = React.useState(false);
 const [textBodyModal, setTextBodyModal] = React.useState('');
 const [titleTextBodyModal, setTitleTextBodyModal] = React.useState('');
 const [titleModal, setTitleModal] = React.useState('');
  const [estadoSelecionado, setEstadoSelecionado] = useState('');
  

  const {
    handleSubmit: handleLoginSubmit,
    control: controlLogin,
    formState: { errors: errorsLogin },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const {
    handleSubmit: handleSignupSubmit,
    reset: resetSignup,
    control: controlSignup,
    setValue,
    formState: { errors: errorsSignup },
  } = useForm({
    resolver: yupResolver(signupSchema),
  });

  const estados = [
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA',
    'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN',
    'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'
  ];

  const handleEstadoChange = (e) => {
    const selectedEstado = e.target.value;
    setEstadoSelecionado(selectedEstado);
  };
  const cidaesDoEstadoEscolhido = cidades.filter(x => x.estado === estadoSelecionado);

  const onLoginSubmit = (data:any) => {
    console.log('Login Data:', data);

    const urlLoginParceiro = "/parceiro/login";

    apiAxios.post(urlLoginParceiro, {
   
        'email':data.emailLogin,
        'senha':data.passwordLogin
       
      })
      .then((response) => {
      
        if(response.data.auth){
        
            
            loginParceiro(response.data.planoAtivo,response.data.dataUser,response.data.tokenParceiroLogado);
            props.callbackParceiroIsLoged(true);


        }
        else{
          //Nao sucesso
          props.callbackParceiroIsLoged(false);
          setTextBodyModal(response.data.message);
          setTitleTextBodyModal('Ops, Atenção !');
          setTitleModal('Verifique :');
          setModalShow(true);

        }
        
      })
      .catch((erro) => {
        //alert('ERRO: '+erro);
        setTitleModal('Seu Cadastro :');
        setTitleTextBodyModal('Oooops...');
        setTextBodyModal('Algo ocorreu, contate o Administrador.');
        setModalShow(true);
      });

   
  };

  const onSignupSubmit = async(data:any) => {
    

    if (!executeRecaptcha) {
        console.log('Execute recaptcha não está disponível ainda');
        return;
      }

      const token = await executeRecaptcha('signup');

    const urlNovoParceiro = "/parceiro/novo";
   
    data.tokenRecaptcha = token;
    // Remover máscara dos campos CNPJ, CEP e Telefone
    data.cnpj = data.cnpj.replace(/\D/g, '');
   // data.cep = data.cep.replace(/\D/g, '');
    data.telefone = data.telefone.replace(/\D/g, '');
    
     console.log('DATA NOVO PARCEIRO: '+data);//Aqui fazer o submit para o backend


          apiAxios.post(urlNovoParceiro, data)
         .then((response) => {
          if(response.data.success){resetSignup();}
            setTextBodyModal(response.data.message);
            setTitleTextBodyModal(response.data.success ? 'Cadastrado' : 'Ops...');
            setTitleModal(response.data.success ? 'Sucesso' : 'Atenção !');
            setModalShow(true);   
           
         })
         .catch((erro) => {
           
       console.log(erro);
          
         });
  };
  
    return (
        <section className="my-4">
             <MyVerticallyCenteredModal
     show={modalShow}
     onHide={() => setModalShow(false)}
     textBodyModal={textBodyModal}
     titleTextBodyModal={titleTextBodyModal}
     titleModal={titleModal}
   /> 
        <Container>
        <h1 className="mb-5">Potencialize o Negócio dos seus clientes com a GeoGet: Marketing de Geolocalização e <b>Inteligência Artificial</b> para Estabelecimentos que vendem Cervejas e Vinhos</h1>
        <Row>
          <Col md={6}>
            <h2>Login</h2>
            <Form onSubmit={handleLoginSubmit(onLoginSubmit)}>
              <Form.Group as={Row} className="justify-content-md-center mb-3">
               
                <Col sm="10" md="6">
                <Controller
                  name="emailLogin"
                  control={controlLogin}
                  render={({ field }) => (
                    <Form.Control
                      type="email"
                      placeholder="Digite seu email"
                      isInvalid={!!errorsLogin.emailLogin}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsLogin.emailLogin?.message}
                </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="justify-content-md-center mb-3">
                
                <Col sm="10" md="6">
                <Controller
                  name="passwordLogin"
                  control={controlLogin}
                  render={({ field }) => (
                    <Form.Control
                      type="password"
                      placeholder="Digite sua senha"
                      isInvalid={!!errorsLogin.passwordLogin}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsLogin.passwordLogin?.message}
                </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Row className="justify-content-md-center">
              <Col xs="auto">
              <Button type="submit">Entrar</Button>
              </Col>
              </Row>
              <Row className="justify-content-md-center mt-2">
              <Col xs="auto">
          <label style={{ fontSize: '12px' }}><a href="/esqueciSenha" target='_blank'>Esqueci a senha</a></label>
          </Col>
          </Row>
            </Form>
          </Col>
          <Col md={6}>
            <h2>Cadastro</h2>
            <Form onSubmit={handleSignupSubmit(onSignupSubmit)}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Nome do Sistema</Form.Label>
                    <Controller
                      name="nomeSistema"
                      control={controlSignup}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          placeholder="Digite o nome do sistema"
                          isInvalid={!!errorsSignup.nomeSistema}
                          {...field}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorsSignup.nomeSistema?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>CNPJ</Form.Label>
                    <Controller
                      name="cnpj"
                      control={controlSignup}
                      render={({ field }) => (
                        <InputMask
                          mask="99.999.999/9999-99"
                          value={field.value}
                          onChange={field.onChange}
                        >
                          {(inputProps) => (
                            <Form.Control
                              {...inputProps}
                              placeholder="Digite o CNPJ"
                              isInvalid={!!errorsSignup.cnpj}
                            />
                          )}
                        </InputMask>
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorsSignup.cnpj?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Telefone</Form.Label>
                    <Controller
                      name="telefone"
                      control={controlSignup}
                      render={({ field }) => (
                        <InputMask
                          mask="(99) 99999-9999"
                          value={field.value}
                          onChange={field.onChange}
                        >
                          {(inputProps) => (
                            <Form.Control
                              {...inputProps}
                              placeholder="Digite o telefone"
                              isInvalid={!!errorsSignup.telefone}
                            />
                          )}
                        </InputMask>
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorsSignup.telefone?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Controller
                      name="email"
                      control={controlSignup}
                      render={({ field }) => (
                        <Form.Control
                          type="email"
                          placeholder="Digite seu email"
                          isInvalid={!!errorsSignup.email}
                          {...field}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorsSignup.email?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Rua</Form.Label>
                    <Controller
                      name="rua"
                      control={controlSignup}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          placeholder="Digite a rua"
                          isInvalid={!!errorsSignup.rua}
                          {...field}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorsSignup.rua?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Número</Form.Label>
                    <Controller
                      name="numero"
                      control={controlSignup}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          placeholder="Digite o número"
                          isInvalid={!!errorsSignup.numero}
                          {...field}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorsSignup.numero?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>CEP</Form.Label>
                    <Controller
                      name="cep"
                      control={controlSignup}
                      render={({ field }) => (
                        <InputMask
                          mask="99999-999"
                          value={field.value}
                          onChange={field.onChange}
                        >
                          {(inputProps) => (
                            <Form.Control
                              {...inputProps}
                              placeholder="Digite o CEP"
                              isInvalid={!!errorsSignup.cep}
                            />
                          )}
                        </InputMask>
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorsSignup.cep?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Bairro</Form.Label>
                    <Controller
                      name="bairro"
                      control={controlSignup}
                      render={({ field }) => (
                        <Form.Control
                          type="text"
                          placeholder="Digite o bairro"
                          isInvalid={!!errorsSignup.bairro}
                          {...field}
                        />
                      )}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errorsSignup.bairro?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>Estado</Form.Label>
                <Controller
                  name="estado"
                  control={controlSignup}
                  render={({ field }) => (
                    <Form.Control
                      as="select"
                      value={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        handleEstadoChange(e);
                      }}
                      isInvalid={!!errorsSignup.estado}
                    >
                      <option value="">Selecione o estado</option>
                      {estados.map((estado) => (
                        <option key={estado} value={estado}>
                          {estado}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsSignup.estado?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Cidade</Form.Label>
                <Controller
                  name="cidade"
                  control={controlSignup}
                  render={({ field }) => (
                    <Form.Control
                      as="select"
                      value={field.value}
                      onChange={field.onChange}
                      isInvalid={!!errorsSignup.cidade}
                      disabled={!estadoSelecionado}
                    >
                      {cidaesDoEstadoEscolhido[0].cidades.map((cidade) => (
                   <option key={cidade.codigo_ibge} value={  cidade.codigo_ibge === 'Selecione_a_Cidade' ? "" : cidade.nome}>{cidade.nome}</option>
                 ))}
                    </Form.Control>
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsSignup.cidade?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Nome do Responsável</Form.Label>
                <Controller
                  name="nome_responsavel"
                  control={controlSignup}
                  render={({ field }) => (
                    <Form.Control
                      type="text"
                      placeholder="Digite o nome do responsável"
                      isInvalid={!!errorsSignup.nome_responsavel}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsSignup.nome_responsavel?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Senha</Form.Label>
                <Controller
                  name="senha"
                  control={controlSignup}
                  render={({ field }) => (
                    <Form.Control
                      type="password"
                      placeholder="Digite sua senha"
                      isInvalid={!!errorsSignup.senha}
                      {...field}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsSignup.senha?.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Button type="submit">Cadastrar</Button>
            </Form>
          </Col>
        </Row>
      </Container>
      </section>
    );



}

export default SectionFormCadastroLoginParceiro;