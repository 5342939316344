import { Modal } from 'react-bootstrap';
import { Button } from "react-bootstrap";

const MyVerticallyCenteredModal = (props) =>{

    return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {props.titleModal}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{props.titleTextBodyModal}</h4>
            <p>
            {props.textBodyModal}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
          </Modal.Footer>
        </Modal>
      );
  
  
  }

  export default MyVerticallyCenteredModal;