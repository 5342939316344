import { Col,Row } from "react-bootstrap";


const CardsClientesProximos = (props) => {


    return (
        <Row className="g-3">
            <Col xs={12} md={6}>
                <div className="card border-left-primary shadow h-100 py-3">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col-8">
                                <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                    Consumidores Próximos
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                    {props.numeroClientesPorPerto}
                                </div>
                            </div>
                            <div className="col-4 text-center">
                                <i className="fa fa-users fa-3x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={12} md={6}>
                <div className="card border-left-success shadow h-100 py-3">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col-8">
                                <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                    Consumidores Alvo Próximos
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                    {props.numeroClientesLogados}
                                </div>
                            </div>
                            <div className="col-4 text-center">
                                <i className="fa fa-users fa-3x text-gray-300"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col xs={12} md={6}>
                {/* Você pode adicionar mais conteúdo aqui */}
            </Col>
        </Row>
  
    )

}

export default CardsClientesProximos;