

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';





const SectionVantagens = () => {
   return (
    <section className="features-icons bg-white text-center py-5">
    <Container>
        <Row className="gy-4">
            <Col lg="4" md="6">
                <div className="features-icons-item mx-auto mb-4">
                    <div className="features-icons-icon d-flex align-items-center justify-content-center mb-3">
                        <i className="bi bi-pin-map-fill text-primary fs-1"></i>
                    </div>
                    <h3 className="fw-bold">Para você</h3>
                    <p className="lead">Quer saber quais promoções tem por perto?</p>
                </div>
            </Col>
            <Col lg="4" md="6">
                <div className="features-icons-item mx-auto mb-4">
                    <div className="features-icons-icon d-flex align-items-center justify-content-center mb-3">
                        <i className="bi bi-shop text-primary fs-1"></i>
                    </div>
                    <h3 className="fw-bold">Para seu comércio</h3>
                    <p className="lead">Ofereça seus produtos para clientes que estão por perto.</p>
                </div>
            </Col>
            <Col lg="4" md="6">
                <div className="features-icons-item mx-auto">
                    <div className="features-icons-icon d-flex align-items-center justify-content-center mb-3">
                        <i className="bi bi-signpost-2 text-primary fs-1"></i>
                    </div>
                    <h3 className="fw-bold">Onde você for</h3>
                    <p className="lead">Descontos e promoções onde você estiver, basta liberar sua localização.</p>
                </div>
            </Col>
        </Row>
    </Container>
</section>

   );
}

export default SectionVantagens;









