



import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';





const SectionDepoimentos = () => {
   return (
    <section className="testimonials text-center bg-white py-5">
    <Container>
        <h2 className="mb-5 fw-bold text-primary">Depoimentos dos nossos usuários</h2>
        <Row className="gy-4">
            <Col lg="4" md="6">
                <div className="testimonial-item mx-auto">
                    <img className="img-fluid rounded-circle mb-4 shadow-sm" src="assets/img/testimonials-1.jpg" alt="Depoimento de Margaret E." />
                    <h5 className="fw-bold">Margaret E.</h5>
                    <p className="text-muted">"Esse site é uma maravilha para quem gosta de cervejas e vinhos! A função de geolocalização me mostra todas as promoções próximas, e já economizei bastante com as ofertas que encontrei."</p>
                </div>
            </Col>
            <Col lg="4" md="6">
                <div className="testimonial-item mx-auto">
                    <img className="img-fluid rounded-circle mb-4 shadow-sm" src="assets/img/testimonials-2.jpg" alt="Depoimento de Fred S." />
                    <h5 className="fw-bold">Fred S.</h5>
                    <p className="text-muted">"Adorei usar o site para encontrar promoções de cervejas e vinhos perto de mim! Achei bares e lojas de cervejas que nem sabia que existiam."</p>
                </div>
            </Col>
            <Col lg="4" md="6">
                <div className="testimonial-item mx-auto">
                    <img className="img-fluid rounded-circle mb-4 shadow-sm" src="assets/img/testimonials-3.jpg" alt="Depoimento de Sarah W." />
                    <h5 className="fw-bold">Sarah W.</h5>
                    <p className="text-muted">"Descobri meu novo site favorito para promoções de cervejas e vinhos! A geolocalização me mostra as melhores ofertas ao meu redor."</p>
                </div>
            </Col>
        </Row>
    </Container>
</section>

   );
}

export default SectionDepoimentos;














