//import logo from './logo.svg';
//import 'bootstrap/dist/css/bootstrap.min.css';
import NavBarComponent from './components/NavBarComponent';
import HeaderComponent from './components/HeaderComponent';
import SectionCardsCervejaVinho from './components/SectionCardsCervejaVinho';
import SectionTimeLine from './components/SectionTimeLine';
import SectionVantagens from './components/SectionVantagens';
import SectionDepoimentos from './components/SectionDepoimentos';
import SectionFormsCadastroLoginEstabelecimento from './components/SectionFormsCadastroLoginEstabelecimento';
import SectionEdicaoProdutos from './components/SectionEdicaoProdutos';
import FooterComponent from './components/FooterComponent';
import SectionFormsCadastroLoginCliente from './components/SectionFormsCadastroLoginCliente';
import SectionFormCadastroLoginParceiro from './components/parceiro/SectionFormCadastroLoginParceiro';

import '../src/assets/css/App.css';
import '../src/assets/css/leaflet.css';
import { useEffect } from 'react';
import { useState } from 'react';
import { addTokenSocketLocalStorage,getTokenSocketLocalStorage, getDataEstabelecimentoLocalStorage, isAuthenticated, isAuthenticatedClient, isAuthenticatedParceiro, getTokenAdminLogado } from './services/auth';

import apiAxios from './services/api';
import SectionCardsClienteLogado from './components/SectionCardsClienteLogado';
import SectionEdicaoProdutosCarros from './components/SectionEdicaoProdutosCarros';
import SectionPageCadastroLoginEstabelecimentos from './components/SectionPageCadastroLoginEstabelecimentos';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import SectionPageCadastroLoginUsuarios from './components/SectionPageCadastroLoginUsuarios';
import SectionAppsGeoGet from './components/SectionAppsGeoGet';
import {Notifications} from 'react-push-notification';
import HeaderPageCadastroLoginUsuarios from './components/HeaderPageCadastroLoginUsuarios';
import HeaderPageCadastroLoginEstabelecimentos from './components/HeaderPageCadastroLoginEstabelecimentos';
import SectionPoliticaPrivacidade from './components/SectionPoliticaPrivacidade';
import SectionSobreGeoGet from './components/SectionSobreGeoGet';
import SectionTermosUso from './components/SectionTermosUso';
import SectionEsqueciSenha from './components/SectionEsqueciSenha';
import SectionRedefinirSenhaEstabelecimento from './components/SectionRedefinirSenhaEstabelecimento';
import SectionRedefinirSenhaUsuario from './components/SectionRedefinirSenhaUsuario';
import { v4 as uuidv4 } from 'uuid';
import { regraIdSocketsLocalSotrage } from './util/util';
import SectionVantagensParceiroGeoGet from './components/parceiro/SectionVantagensParceiroGeoGet';
import SectionDashParceiro from './components/parceiro/SectionDashParceiro';
import HeaderPageCadastroLoginParceiros from './components/parceiro/HeaderPageCadastroLoginParceiros';
import SectionAdmin from './components/admin/SectionAdmin';
import SectionLoginAdmin from './components/admin/SectionLoginAdmin';
import SectionVantagemBaresRestaurantes from './components/SectionVantagemBaresRestaurantes';
import SectionVantagemUsuarios from './components/SectionVantagemUsuarios';
import SectionVantagemParceiros from './components/SectionVantagemParceiros';
import SectionRedefinirSenhaParceiro from './components/parceiro/SectionRedefinirSenhaParceiro';
import SectionIaGeoGet from './components/SectionIaGeoGet';

 /*
navigator.serviceWorker.register('service-worker.js')
.then( async serviceWorker => {
  let subscription =   await serviceWorker.pushManager.getSubscription(); // assinatura do usuario com o serviço de notificações

  if(!subscription){

    const publicKeyResponse = await apiAxios.get('/serviceworker/public_key'); 

    subscription = await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: publicKeyResponse.data.publicKey
    });
  }

  console.log(subscription);

});*/

function App(props) { 

  const [isLogged, setIsLogged] = useState(false);
  const [clientIsLogged, setClientIsLogged] = useState(false);
  const [parceiroIsLogged, setParceiroIsLogged] = useState(false);
  const [adminIsLogged, setAdminIsLogged] = useState(false);

  

  var docWidth = document.documentElement.offsetWidth;

  [].forEach.call(
    document.querySelectorAll('*'),
    function(el) {
      if (el.offsetWidth > docWidth) {
        console.log('O elemento que esta estourando a largura da pagina => '+el);
      }
    }
  );

  useEffect(() => {

    if(getTokenAdminLogado()){
      setAdminIsLogged(true);
    }
    else{
      setAdminIsLogged(false);
    }

  },[adminIsLogged]);

  
  useEffect(() => {

    if(isAuthenticatedParceiro()){
      setParceiroIsLogged(true);
    }
    else{
      setParceiroIsLogged(false);
    }

  },[parceiroIsLogged]);


  useEffect(() => {

    if(isAuthenticatedClient()){
      setClientIsLogged(true);
    }
    else{
      setClientIsLogged(false);
    }

  },[clientIsLogged]);

  useEffect(() => {
    console.log('USEEFFECT APP ESTABELECIMENTO LOGADO : '+isAuthenticated());
    if(isAuthenticated()){
      setIsLogged(true);
    }
    else{
      setIsLogged(false);
    }
 
    const tokenSocketLocalStorage = getTokenSocketLocalStorage();

    if(tokenSocketLocalStorage === null || tokenSocketLocalStorage === 'undefined'){
  
    var urlApis = "/index/apis";

     apiAxios.post(urlApis, {
      'apis' : true
     })
     .then((response) => {
      
      if(response.data.valid){
        
          addTokenSocketLocalStorage(response.data.jwtSocketIo);
      }

      console.log('RESPOSTA CONSULTA URL PARA GERAR TOKEN SOCKET IO => '+response.data.valido);
      console.log('RESPOSTA CONSULTA URL PARA GERAR TOKEN SOCKET IO : TOKEN : => '+response.data.jwtSocketIo);

     })
     .catch((erro) => {
       
     
      console.log('ERRO AO TENTAR GERAR TOKEN => '+ JSON.stringify(erro));

     });
    
    }

  }, [isLogged]); //todas vez que isAuthenticated mudar, vai executar useEffect


  
  useEffect(() => { 
    
    regraIdSocketsLocalSotrage();
    

  },[]);
    //const {location, erro} = usePosition();
  
  /*
esse aqui envolve todo o APP: <SocketContext.Provider value={socket}> todas tags do app </SocketContext.Provider>
  */

function Home(){

  return (
    
<div>   
<Notifications/>
<NavBarComponent estabelecimentoLogado={isLogged} clienteLogado={clientIsLogged} />
<HeaderComponent />
<SectionCardsCervejaVinho />




     {clientIsLogged ? <SectionCardsClienteLogado /> : (
      <>
      { (!isLogged ? <SectionTimeLine/> : '')}
       
      <SectionFormsCadastroLoginCliente
      callbackParentClientIsLoged={(clientIsLoggedLogin) => setClientIsLogged(clientIsLoggedLogin)}     
     />
     </>
     ) 
     }

<SectionVantagens />
{/*<SectionAppsGeoGet/>*/}
<SectionDepoimentos />
<SectionIaGeoGet />
{isLogged ? 
(
getDataEstabelecimentoLocalStorage().tipo_estabelecimento === 'Concessionaria de Carros' ||  
getDataEstabelecimentoLocalStorage().tipo_estabelecimento === 'Multimarcas de Carros' 
?
<SectionEdicaoProdutosCarros />
:
 <SectionEdicaoProdutos /> 

 ) 
: 
( <SectionFormsCadastroLoginEstabelecimento 
                                             callbackParentIsLoged={(isLoggedLogin) => setIsLogged(isLoggedLogin)}     
                                              />
                                              )
                                              }


<FooterComponent />


</div>

  )

}

function CadastroEstabelecimento(){
  return(
    <div>
    <NavBarComponent estabelecimentoLogado={isLogged} clienteLogado={clientIsLogged}/>
    {
    isLogged ? 
    <SectionEdicaoProdutos /> 
    :
    <SectionPageCadastroLoginEstabelecimentos callbackParentIsLoged={(isLoggedLogin) => setIsLogged(isLoggedLogin)} />
    }
    <SectionIaGeoGet />
   <SectionVantagemBaresRestaurantes/>
   {/*<SectionAppsGeoGet/>*/}
   <HeaderPageCadastroLoginEstabelecimentos /> 
   <FooterComponent />
   </div>
  )
}

function CadastroUsuarios(){
  return(
    <div>
    <NavBarComponent estabelecimentoLogado={isLogged} clienteLogado={clientIsLogged}/>
   
    {
    (
    clientIsLogged ? 
    <SectionCardsClienteLogado /> : 
    <SectionPageCadastroLoginUsuarios callbackParentClientIsLoged={(clientIsLoggedLogin) => setClientIsLogged(clientIsLoggedLogin)}  />
     )
     }
   <SectionVantagemUsuarios/>
   {/*<SectionAppsGeoGet/>*/}
   <HeaderPageCadastroLoginUsuarios />
   <FooterComponent />
   </div>
  )
}

function PoliticaPrivacidade(){
  return(
    <div>
       <NavBarComponent estabelecimentoLogado={isLogged} clienteLogado={clientIsLogged}/>
      <SectionPoliticaPrivacidade />
      {/*<SectionAppsGeoGet/>*/}
   <HeaderPageCadastroLoginUsuarios />
   <FooterComponent />
    </div>
  )
}

function Sobre(){
  return(
    <div>
    <NavBarComponent estabelecimentoLogado={isLogged} clienteLogado={clientIsLogged}/>
    <HeaderPageCadastroLoginEstabelecimentos /> 
   <SectionSobreGeoGet />
   {/*<SectionAppsGeoGet/>*/}
<HeaderPageCadastroLoginUsuarios />
<FooterComponent />
 </div>
  )
}

function TermosUso(){
  return(
    <div>
      <NavBarComponent estabelecimentoLogado={isLogged} clienteLogado={clientIsLogged}/>
   <SectionTermosUso />
   {/*<SectionAppsGeoGet/>*/}
<HeaderPageCadastroLoginUsuarios />
<FooterComponent />
      </div>
  )
}

function EsqueciSenha(){
  return(
    <div>
      <NavBarComponent estabelecimentoLogado={isLogged} clienteLogado={clientIsLogged}/>
   <SectionEsqueciSenha  />
   {/*<SectionAppsGeoGet/>*/}
<HeaderPageCadastroLoginUsuarios />
<FooterComponent />
      </div>
  )
}

function RedefinirSenhaEstabelecimento(){

  return(
    <div>
      <NavBarComponent estabelecimentoLogado={isLogged} clienteLogado={clientIsLogged}/>
   <SectionRedefinirSenhaEstabelecimento  />
   {/*<SectionAppsGeoGet/>*/}
<HeaderPageCadastroLoginUsuarios />
<FooterComponent />
      </div>
  )

}

function RedefinirSenhaParceiro(){

  
  return(
    <div>
      <NavBarComponent estabelecimentoLogado={isLogged} clienteLogado={clientIsLogged}/>
      <SectionRedefinirSenhaParceiro />
   {/*<SectionAppsGeoGet/>*/}
<HeaderPageCadastroLoginUsuarios />
<FooterComponent />
      </div>
  )

}

function RedefinirSenhaUsuario (){

  return(
    <div>
      <NavBarComponent estabelecimentoLogado={isLogged} clienteLogado={clientIsLogged}/>
   <SectionRedefinirSenhaUsuario  />
   {/*<SectionAppsGeoGet/>*/}
<HeaderPageCadastroLoginUsuarios />
<FooterComponent />
      </div>
  )

}


function PageCadastroLoginParceiro(){
  return(
    <div>
     <NavBarComponent estabelecimentoLogado={isLogged} clienteLogado={clientIsLogged}/>
     <HeaderPageCadastroLoginParceiros />
    
    {
      parceiroIsLogged ?
      <SectionDashParceiro 
      callbackParceiroIsLoged={(parceiroIsLogado) => setParceiroIsLogged(parceiroIsLogado)}/>
      :
      <SectionFormCadastroLoginParceiro 
    callbackParceiroIsLoged={(parceiroIsLogado) => setParceiroIsLogged(parceiroIsLogado)} />
    }
    
    <SectionVantagensParceiroGeoGet /> 
    <SectionVantagemParceiros />
    {/*<SectionAppsGeoGet/>*/}
    <FooterComponent />
  </div>
  )
}

function Admin(){
  return(
    <div>
     <NavBarComponent />
    
    {
      adminIsLogged ?
      
      <SectionAdmin callbackAdminIsLoged={(adminIsLogado) => setAdminIsLogged(adminIsLogado)}/>
      :
      <SectionLoginAdmin 
      callbackAdminIsLoged={(adminIsLogado) => setAdminIsLogged(adminIsLogado)}/>
   
    }
   
    
   
    <FooterComponent />
  </div>
  )
}


return (

  <BrowserRouter>
  <Routes>
    <Route exact path='/' Component={Home} />
    <Route path='/cadastroEstabelecimentos' Component={CadastroEstabelecimento} />
    <Route path='/cadastroUsuarios' Component={CadastroUsuarios} />
    <Route path='/politicaPrivacidade' Component={PoliticaPrivacidade} />
    <Route path='/sobre' Component={Sobre} />
    <Route path='/termosUso' Component={TermosUso} />
    <Route path='/esqueciSenha' Component={EsqueciSenha} />
    <Route path='/redefinirSenhaEstabelecimento' Component={RedefinirSenhaEstabelecimento} />
    <Route path='/redefinirSenhaParceiro' Component={RedefinirSenhaParceiro} />
    <Route path='/redefinirSenhaUsuario' Component={RedefinirSenhaUsuario} />
    <Route path='/cadastroParceiros' Component={PageCadastroLoginParceiro} />
    <Route path='/admin' Component={Admin} />
    
    
  </Routes>
  </BrowserRouter>

);
}

export default App;
