import React, {useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';  
import Col from 'react-bootstrap/Col';
import { Table } from 'react-bootstrap';
import moment from 'moment-timezone';
import { formatarPreco } from "../util/util";
import { getDataEstabelecimentoLocalStorage } from "../services/auth";
import SectionPrecoPlanoEstabelecimentos from "./SectionPrecoPlanoEstabelecimentos";




const SectionEstatisticasEstabelecimento = (props) => { 


  const [tituloAbaEstatisticas,setTituloAbaEstatisticas] = useState('Insights de Mercado: Publique Seus Produtos com Precisão e Estratégia!');

  useEffect(() => { // esse é responsável em pegar as alterações
    
    setTituloAbaEstatisticas(props.tituloAbaEstatisticas);
   
 }, [props.tituloAbaEstatisticas]);

    // Função para converter dia da semana de número para nome
function getDayName(dayNumber) {
    const days = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    return days[dayNumber - 1];
  }

  const formatHour = (hour) => {
    return moment.tz({ hour }, 'America/Sao_Paulo').format('HH:00');
  };

  function aggregateByDayOfWeek(results) {
    // Objeto para armazenar o total de contagens por dia da semana
    const aggregated = {};
  
    // Percorre cada objeto no array de resultados
    results.forEach((result) => {
      const { diaDaSemana } = result._id;
      const { count } = result;
  
      // Se o diaDaSemana ainda não está no objeto aggregated, inicializa com 0
      if (!aggregated[diaDaSemana]) {
        aggregated[diaDaSemana] = 0;
      }
  
      // Adiciona o count ao total acumulado para o dia da semana
      aggregated[diaDaSemana] += count;
    });
  
    // Converte o objeto aggregated em um array de objetos
    const aggregatedArray = Object.keys(aggregated).map((dia) => ({
      diaDaSemana: parseInt(dia),
      totalCount: aggregated[dia],
    }));
  
    return aggregatedArray;
  }

    
  const tableStyle = {
    marginBottom: '20px', // Espaço entre as tabelas
  };
  
return(

    <>
    <section >
      
     

      <Container>
      <h4 className="mb-5">{tituloAbaEstatisticas}</h4>
      
      <Row>
     
     { 
      (props.qtdeLeadsPeriodoRaio  ?  
        <>
      <Col xs={12} md={6} >
      
         <h2>Dia e Hora de maiores concentrações e clientes ao seu redor: Aproveite o Potencial de Venda!</h2>
             
         <Table responsive striped bordered hover style={tableStyle}>
  <thead>
    <tr>
      <th>Dia da Semana</th>
      <th>Data</th>
      <th>Hora</th>
      <th>Leads ao seu Redor</th>
    </tr>
  </thead>
  <tbody>
      

    
{
       props.qtdeLeadsPeriodoRaio.slice(0, 10).map((entry, index) => (
            <tr key={index}>
              <td>{getDayName(entry._id.diaDaSemana)}</td>
              <td>{entry._id.data}</td>
              <td>{formatHour(entry._id.hora)}</td>
              <td>{entry.count}</td>
            </tr>
          ))
          }

          </tbody>
        </Table>
      
        </Col>
        <Col xs={12} md={6} >
        
          <h2>Melhores Dias e Horários para Publicar Suas Promoções de Bebidas!</h2>
          
          <Table responsive striped bordered hover style={tableStyle}>
  <thead>
    <tr>
      <th>Dia da Semana</th>
      <th>Qtde Leads</th>
    </tr>
  </thead>
  <tbody>
          
  {
    
    aggregateByDayOfWeek(props.qtdeLeadsPeriodoRaio.slice(0, 10)).map((entry, index) => (
            <tr key={index}>
              <td>{getDayName(entry.diaDaSemana)}</td>
              <td>{entry.totalCount}</td>
            </tr>
          ))
          }

          </tbody>
        </Table>
          <ul>
            {props.qtdeLeadsPeriodoRaio.slice(0, 5).map((period, index) => (
              <li key={index}>
                {getDayName(period._id.diaDaSemana)} às {formatHour(period._id.hora)} - {period.count} Leads ao seu redor
              </li>
            ))}
          </ul>
        
        </Col>
        </>
        :
        ''
        )}
        
       
        </Row>
        <Row>
        { (props.estatisticaValorMedioProdutosRegiao ?  
        <Col xs={12} md={6} >
        
        <h2>Referências para te ajudar a definir o preço ideal! </h2>
        <Table responsive striped bordered hover style={tableStyle}>
  <thead>
    <tr>
      <th>Categoria</th>
      <th>Produto</th>
      <th>ml</th>
      <th>Preço Medio</th>
      <th>Min e Max</th>
    </tr>
  </thead>
  <tbody>
      

    
{
       props.estatisticaValorMedioProdutosRegiao.slice(0, 15).map((entry, index) => (
            <tr key={index}>
              <td>{entry.categoria}</td>
              <td>{entry.nome}</td>
              <td>{entry.ml}</td>
              <td>{formatarPreco(entry.precoMedio)}</td>
              <td>
                {(entry.maiorPreco &&  entry.menorPreco ? 
              formatarPreco(entry.menorPreco) +' - '+formatarPreco(entry.maiorPreco) :
              'Somente 1 encontrado')}
              </td>
            </tr>
          ))
          }

          </tbody>
        </Table>
      
      </Col>
    :
    ''  
    )
     }

 
   <Col xs={12} md={6} >
        
   <h2>Veja quais Produtos mais atrairam pessoas para seu Estabelecimento </h2>
    
      {
      props.estatisticaCliquesToMapPordutos && getDataEstabelecimentoLocalStorage().isPlanoAtivo ?
    
      <Table responsive striped bordered hover style={tableStyle}>
  <thead>
    <tr>
      <th>Categoria</th>
      <th>Produto</th>
      <th>Preço</th>
      <th>Pessoas Direcionadas</th>
      
    </tr>
  </thead>
  <tbody>
  {
       props.estatisticaCliquesToMapPordutos.map((entry, index) => (
            <tr key={index}>
              <td>{entry.categoria}</td>
              <td>{entry.nome_produto}</td>
              <td>{formatarPreco(entry.preco)}</td>
              <td>{(entry.qtdeClicksToMap === null ||  entry.qtdeClicksToMap === undefined ? '0' : entry.qtdeClicksToMap)}</td>
             
            </tr>
          ))
          }

          </tbody>
        </Table>
        :
        <SectionPrecoPlanoEstabelecimentos/>
        
    }

        
   </Col>

        </Row>

        </Container>   
	 
	   
    </section>
    </>



);

}

export default SectionEstatisticasEstabelecimento;












