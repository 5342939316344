import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form,Container , Row, Col} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import apiAxios from '../../services/api'
import MyVerticallyCenteredModal from '../modals/verticallyCenteredModal';
import moment from 'moment-timezone';
import { converterDecimal128ParaPrecoBrasil, formatarPreco, formatDateDDMMAAAA } from '../../util/util';
import { logoffAdmin } from '../../services/auth';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { v4 as uuidv4 } from 'uuid';



const SectionAdmin = (props) => { 

  const [modalShow, setModalShow] = React.useState(false);
  const [textBodyModal, setTextBodyModal] = React.useState('');
  const [titleTextBodyModal, setTitleTextBodyModal] = React.useState('');
  const [titleModal, setTitleModal] = React.useState('');

  const [partners, setPartners] = useState([]);
  const [establishments, setEstablishments] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [products, setProducts] = useState([]);
  const [showPartnerModal, setShowPartnerModal] = useState(false);
  const [showDesvicEstabModal, setShowDesvicEstabModal] = useState(false);
  const [showAtualizaCoordEstabelecimento, setShowAtualizaCoordEstabelecimento] = useState(false);
  const [showAtualizaCoordUsuario, setShowAtualizaCoordUsuario] = useState(false);
  
  const [showEstablishmentModal, setShowEstablishmentModal] = useState(false);
  
  const [showEditUsuario, setShowEditUsuario] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);
  const [showPlanPartnerModal, setShowPlanPartnerModal] = useState(false);
  const [showPlanEstabelecimentoModal, setShowPlanEstabelecimentoModal] = useState(false);
  
  const [currentPartner, setCurrentPartner] = useState(null);
  const [currentEstablishment, setCurrentEstablishment] = useState(null);
  const [currentUsuario, setCurrentUsuario] = useState(null);
  const [motoristas,setMotoristas]=useState(null);
  const [currentMotorista,setCurrentMotorista] = useState(null);
  const [showModalEditMotorista,setShowModalEditMotorista] = useState(false);

  const [showModalComissoesMoto,setShowModalComissoesMoto] = useState(false);
  const [modalDadosComissoes, setModalDadosComissoes] = useState({
    estabelecimentos: [],
    gastosComissoes: []
  });
  
  const [isPartnerPlan, setIsPartnerPlan] = useState(false);
  const [qtdeDiasInicioGratisEstabelecimento, setQtdeDiasInicioGratisEstabelecimento] = useState(0);
  const [qtdeDiasInicioGratisParceiro, setQtdeDiasInicioGratisParceiro] = useState(0);
  const [maxTokensMesChatEstabelecimentosOpenAi, setMaxTokensMesChatEstabelecimentosOpenAi] = useState(0);
  const [porcentagemComissaoMotoristas,setPorcentagemComissaoMotoristas] = useState('0');
  const [qtdePublicacoesGratisEstabelecimentos,setQtdePublicacoesGratisEstabelecimentos] = useState('0');
  const [showModalConfirmDeletePartner , setShowModalConfirmDeletePartner]= useState(false);
  const [showModalConfirmDeleteEstablishment,setShowModalConfirmDeleteEstablishment] = useState(false);
    
   const [showModalProdutosEstabelecimento,setShowModalProdutosEstabelecimento] = useState(false);
  const [produtosEstabelecimento,setProdutosEstabelecimento] = useState([]);

  const onDeleteEstabelecimento = async () =>{

    try {
        
      //alert(parseInt(qtdeDiasInicioGratisEstabelecimento, 10));
      const url= '/admin/deletar';


      apiAxios.post(url, {
        'idEstabelecimento' : currentEstablishment._id
      })
            .then( async (response) => {
            
              if(response.data.success){

                
              setEstablishments(response.data.estabelecimentos);
                setShowModalConfirmDeleteEstablishment(false);
               setTextBodyModal('Atualização realizada com sucesso');
               setTitleTextBodyModal('Tudo OK !');
               setTitleModal('Seguir... :');
               setModalShow(true);
               
              }
              else{
                //Nao sucesso
               
                setTextBodyModal('Ocorreu algum erro ');
                setTitleTextBodyModal('Ops, Atenção !');
                setTitleModal('Verifique :');
                setModalShow(true);
      
              }
              
            })
            .catch((erro) => {
              //alert('ERRO: '+erro);
              setTitleModal('Algum erro :');
              setTitleTextBodyModal('Oooops...');
              setTextBodyModal('Algo ocorreu, contate o Administrador.');
              setModalShow(true);
            });


    } catch (error) {
      console.error('Erro ao tentar deletar estabelecimento:', error);
      alert('Erro ao tentar deletar estabelecimento.');
    }
   
  }
  

  const onDeleteParceiro = async () =>{
    
    try {
        
      //alert(parseInt(qtdeDiasInicioGratisEstabelecimento, 10));
      const url= '/admin/deletar';


      apiAxios.post(url, {
        'idParceiro' : currentPartner._id
      })
            .then( async (response) => {
            
              if(response.data.success){

                
              setPartners(response.data.parceiros);
                setShowModalConfirmDeletePartner(false);
               setTextBodyModal('Atualização realizada com sucesso');
               setTitleTextBodyModal('Tudo OK !');
               setTitleModal('Seguir... :');
               setModalShow(true);
               
              }
              else{
                //Nao sucesso
               
                setTextBodyModal('Ocorreu algum erro ');
                setTitleTextBodyModal('Ops, Atenção !');
                setTitleModal('Verifique :');
                setModalShow(true);
      
              }
              
            })
            .catch((erro) => {
              //alert('ERRO: '+erro);
              setTitleModal('Algum erro :');
              setTitleTextBodyModal('Oooops...');
              setTextBodyModal('Algo ocorreu, contate o Administrador.');
              setModalShow(true);
            });


    } catch (error) {
      console.error('Erro ao tentar deletar parceiro:', error);
      alert('Erro ao tentar deletar parceiro.');
    }
   
  }

const atualizaCamposAdminTela = async (adminRecebido) => {

  setQtdeDiasInicioGratisEstabelecimento(parseInt(adminRecebido.qtdeDiasInicioGratisEstabelecimento));
  setQtdeDiasInicioGratisParceiro(parseInt(adminRecebido.qtdeDiasInicioGratisParceiro));
  setMaxTokensMesChatEstabelecimentosOpenAi(parseInt(adminRecebido.maxTokensMesChatEstabelecimentosOpenAi));
  setPorcentagemComissaoMotoristas(adminRecebido.porcentagemComissaoMotoristas);
  setQtdePublicacoesGratisEstabelecimentos(adminRecebido.qtdePublicacoesGratisEstabelecimentos);
}

  const handleUpdateDiasGratisEstabelecimento = async () => {
    if (!isNaN(qtdeDiasInicioGratisEstabelecimento)) {
      try {
        
        //alert(parseInt(qtdeDiasInicioGratisEstabelecimento, 10));
        const url= '/admin/changeQtdeDiasInicioGratisEstabelecimento';


        apiAxios.post(url, {
          'qtdeDiasInicioGratisEstabelecimento' : qtdeDiasInicioGratisEstabelecimento
        })
              .then( async (response) => {
              
                if(response.data.success){

                  
                 setAdmin(response.data.admin);
                 await atualizaCamposAdminTela(response.data.admin);

                 setTextBodyModal('Atualização realizada com sucesso');
                 setTitleTextBodyModal('Tudo OK !');
                 setTitleModal('Seguir... :');
                 setModalShow(true);
                 
                }
                else{
                  //Nao sucesso
                 
                  setTextBodyModal('Ocorreu algum erro ');
                  setTitleTextBodyModal('Ops, Atenção !');
                  setTitleModal('Verifique :');
                  setModalShow(true);
        
                }
                
              })
              .catch((erro) => {
                //alert('ERRO: '+erro);
                setTitleModal('Algum erro :');
                setTitleTextBodyModal('Oooops...');
                setTextBodyModal('Algo ocorreu, contate o Administrador.');
                setModalShow(true);
              });


      } catch (error) {
        console.error('Erro ao atualizar qtdeDiasInicioGratisEstabelecimento:', error);
        alert('Erro ao atualizar qtdeDiasInicioGratisEstabelecimento.');
      }
    } else {
      alert('Por favor, insira um número válido para Qtde Dias Inicio Gratis Estabelecimento.');
    }
  };

  const handleUpdateQtdePublicacoesGratisEstabelecimentos = async ()=>{

        
    const url= '/admin/changeQtdePublicacoesGratisEstabelecimentos';


    apiAxios.post(url, {
      'qtdePublicacoesGratisEstabelecimentos' : qtdePublicacoesGratisEstabelecimentos
    })
          .then( async (response) => {
          
            if(response.data.success){

               
             setAdmin(response.data.admin);
             await atualizaCamposAdminTela(response.data.admin);

             setTextBodyModal('Atualização realizada com sucesso');
             setTitleTextBodyModal('Tudo OK !');
             setTitleModal('Seguir... :');
             setModalShow(true);
             
            }
            else{
              //Nao sucesso
             
              setTextBodyModal('Ocorreu algum erro ');
              setTitleTextBodyModal('Ops, Atenção !');
              setTitleModal('Verifique :');
              setModalShow(true);
    
            }
            
          })
          .catch((erro) => {
            //alert('ERRO: '+erro);
            setTitleModal('Algum erro :');
            setTitleTextBodyModal('Oooops...');
            setTextBodyModal('Algo ocorreu, contate o Administrador.');
            setModalShow(true);
          });



  }

  const handleUpdatePorcentagemComissaoMotoristas = async ()=>{


    const url= '/admin/changePorcentagemComissaoMotoristas';


    apiAxios.post(url, {
      'porcentagemComissaoMotoristas' : porcentagemComissaoMotoristas
    })
          .then( async (response) => {
          
            if(response.data.success){

               
             setAdmin(response.data.admin);
             await atualizaCamposAdminTela(response.data.admin);

             setTextBodyModal('Atualização realizada com sucesso');
             setTitleTextBodyModal('Tudo OK !');
             setTitleModal('Seguir... :');
             setModalShow(true);
             
            }
            else{
              //Nao sucesso
             
              setTextBodyModal('Ocorreu algum erro ');
              setTitleTextBodyModal('Ops, Atenção !');
              setTitleModal('Verifique :');
              setModalShow(true);
    
            }
            
          })
          .catch((erro) => {
            //alert('ERRO: '+erro);
            setTitleModal('Algum erro :');
            setTitleTextBodyModal('Oooops...');
            setTextBodyModal('Algo ocorreu, contate o Administrador.');
            setModalShow(true);
          });


  }

  const handleUpdateMaxTokensMesChatEstabelecimentosOpenAi = async ()=>{

    const url= '/admin/changeQtdeMaxTokensChatEstabOpenAi';


    apiAxios.post(url, {
      'maxTokensMesChatEstabelecimentosOpenAi' : maxTokensMesChatEstabelecimentosOpenAi
    })
          .then( async (response) => {
          
            if(response.data.success){

               
             setAdmin(response.data.admin);
             await atualizaCamposAdminTela(response.data.admin);

             setTextBodyModal('Atualização realizada com sucesso');
             setTitleTextBodyModal('Tudo OK !');
             setTitleModal('Seguir... :');
             setModalShow(true);
             
            }
            else{
              //Nao sucesso
             
              setTextBodyModal('Ocorreu algum erro ');
              setTitleTextBodyModal('Ops, Atenção !');
              setTitleModal('Verifique :');
              setModalShow(true);
    
            }
            
          })
          .catch((erro) => {
            //alert('ERRO: '+erro);
            setTitleModal('Algum erro :');
            setTitleTextBodyModal('Oooops...');
            setTextBodyModal('Algo ocorreu, contate o Administrador.');
            setModalShow(true);
          });


  } 

  const handleUpdateDiasGratisParceiro = async () => {
    if (!isNaN(qtdeDiasInicioGratisParceiro)) {
      
      const url= '/admin/changeQtdeDiasInicioGratisParceiro';


      apiAxios.post(url, {
        'qtdeDiasInicioGratisParceiro' : qtdeDiasInicioGratisParceiro
      })
            .then( async (response) => {
            
              if(response.data.success){

                 
               setAdmin(response.data.admin);
               await atualizaCamposAdminTela(response.data.admin);

               setTextBodyModal('Atualização realizada com sucesso');
               setTitleTextBodyModal('Tudo OK !');
               setTitleModal('Seguir... :');
               setModalShow(true);
               
              }
              else{
                //Nao sucesso
               
                setTextBodyModal('Ocorreu algum erro ');
                setTitleTextBodyModal('Ops, Atenção !');
                setTitleModal('Verifique :');
                setModalShow(true);
      
              }
              
            })
            .catch((erro) => {
              //alert('ERRO: '+erro);
              setTitleModal('Algum erro :');
              setTitleTextBodyModal('Oooops...');
              setTextBodyModal('Algo ocorreu, contate o Administrador.');
              setModalShow(true);
            });


    } else {
      alert('Por favor, insira um número válido para Qtde Dias Inicio Gratis Parceiro.');
    }
  };

  const isPlanActive =  (startDate:any, endDate:any) => {
    
    if(!startDate || !endDate){
      return false;
    }
    else{

      const now = moment().tz('America/Sao_Paulo').startOf('day');
    const start = moment(startDate).tz('America/Sao_Paulo').startOf('day');
    const end = moment(endDate).tz('America/Sao_Paulo').startOf('day');
    return now.isSameOrAfter(start) && now.isSameOrBefore(end);

    }
    
    
  };

  const isFreePeriod = (endDate) => {
    // Obter a data atual no fuso horário especificado
    const today = moment.tz('America/Sao_Paulo').startOf('day');
    
    // Converter endDate para um objeto moment se ainda não for
    const end = moment.tz(endDate, 'America/Sao_Paulo').startOf('day');

    // Comparar se hoje é menor ou igual a endDate
    return today.isSameOrBefore(end);
}

  const fetchPartners = async () => {
    //const response = await axios.get('/api/partners');
    //setPartners(response.data);
  };

  const fetchEstablishments = async () => {
   // const response = await axios.get('/api/establishments');
    //setEstablishments(response.data);
  };

  const consultaInicial = async ()=>{
    const urlConsultaInicial = '/admin/consultaInicial';

    apiAxios.post(urlConsultaInicial, {})
          .then((response) => {
          
            if(response.data.success){
              setPartners(response.data.parceiros);
              setEstablishments(response.data.estabelecimentos);
              setUsuarios(response.data.usuarios);
              setAdmin(response.data.admin);
              atualizaCamposAdminTela(response.data.admin);
              setMotoristas(response.data.motoristas);
              
            }
            else{
              //Nao sucesso
             
              setTextBodyModal('Ocorreu algum erro na consulta Inicial');
              setTitleTextBodyModal('Ops, Atenção !');
              setTitleModal('Verifique :');
              setModalShow(true);
    
            }
            
          })
          .catch((erro) => {
            //alert('ERRO: '+erro);
            setTitleModal('Seu Cadastro :');
            setTitleTextBodyModal('Oooops...');
            setTextBodyModal('Algo ocorreu, contate o Administrador.');
            setModalShow(true);
          });
  }

  useEffect(() => {
    consultaInicial();
    fetchPartners();
    fetchEstablishments();
  }, []);

  const usuarioSchema = yup.object().shape({
    nome: yup.string().required(),
    email: yup.string().email().required(),
    celular: yup.string().required(),
    rua: yup.string().required(),
    numero: yup.string().required(),
    cep: yup.string().required(),
    bairro: yup.string().required(),
    cidade: yup.string().required(),
    estado: yup.string().required()
  });

  const partnerSchema = yup.object().shape({
    nomeSistema: yup.string().required(),
    email: yup.string().email().required(),
    cnpj: yup.string().required(),
    rua: yup.string().required(),
    numero: yup.string().required(),
    cep: yup.string().required(),
    bairro: yup.string().required(),
    cidade: yup.string().required(),
    estado: yup.string().required(),
    telefone: yup.string().required()
    
  });

  const establishmentSchema = yup.object().shape({
    nome_estabelecimento: yup.string().required(),
    email: yup.string().email().required(),
    cnpj: yup.string().required(),
    tipo_estabelecimento: yup.string().required(),
    rua: yup.string().required(),
    numero: yup.string().required(),
    cep: yup.string().required(),
    bairro: yup.string().required(),
    cidade: yup.string().required(),
    estado: yup.string().required(),
    telefone: yup.string().required(),
    instagramUsername: yup.string().required()
  });
  

  /*
  const productSchema = yup.object().shape({
    name: yup.string().required(),
    value: yup.number().required(),
    category: yup.string().required(),
    ml: yup.number().required(),
  });*/

 

  const planEstabelecimentoSchema = yup.object().shape({
   
    qtdeDiasPlano: yup.number().required(),
    valorPlano: yup.number().required()
  });

  const planParceiroSchema = yup.object().shape({
   
    qtdeDiasPlano: yup.number().required(),
    valorPlano: yup.number().required(),
    qtdeEstabelecimentosPlano:yup.number().required()
  });



  const { register: registerPartner, handleSubmit: handleSubmitPartner, reset: resetPartner } = useForm({
    resolver: yupResolver(partnerSchema),
  });

  const { register: registerEstablishment, handleSubmit: handleSubmitEstablishment, reset: resetEstablishment } = useForm({
    resolver: yupResolver(establishmentSchema),
  });

  /*const { register: registerProduct, handleSubmit: handleSubmitProduct, reset: resetProduct } = useForm({
    resolver: yupResolver(productSchema),
  });*/

  const { register: registerUsuario, handleSubmit: handleSubmitUsuario, reset: resetUsuario } = useForm({
    resolver: yupResolver(usuarioSchema),
  });

  const { register: registerPlanEstabelecimento, handleSubmit: handleSubmitPlanEstabelecimento, reset: resetPlanEstabelecimento } = useForm({
    resolver: yupResolver(planEstabelecimentoSchema),
  });

  const { register: registerPlanParceiro, handleSubmit: handleSubmitPlanParceiro, reset: resetPlanParceiro } = useForm({
    resolver: yupResolver(planParceiroSchema),
  });

  const handleSubmitAlterarMotorista = async (e) => {
    e.preventDefault();
    const app = e.target.elements.app.value;
    const codigoMotorista = e.target.elements.codigoMotorista.value;
    const idMotorista = currentMotorista.idMotorista;

    const url = '/admin/alterarDadosMotorista';

    const response = await apiAxios.post(url, {
      'app':app,
      'codigoMotorista':codigoMotorista,
      'idMotorista':idMotorista
    });
       
    setShowModalEditMotorista(false);     
    if(response.data.success){

      setMotoristas(response.data.motoristas);
      setTextBodyModal('Atualização realizada');
      setTitleTextBodyModal('Tudo OK !');
      setTitleModal('Sucesso :');
      setModalShow(true);

    }
    else{
      setTextBodyModal(response.data.message);
      setTitleTextBodyModal('Alteração Nao realizada !');
      setTitleModal('Verifique :');
      setModalShow(true);
    }
  };

  const onSubmitAtualizaCoordenadasUsuario = async (event) => { 

    if(
      !event.target.elements.latitude.value || !event.target.elements.longitude.value
    ){

      alert('Latitude ou Longitude nao informados');
      return;
    }

    // Obtendo os valores dos campos através do evento de submissão
    const data = {
      latitude: event.target.elements.latitude.value,
      longitude: event.target.elements.longitude.value,
      usuarioId: currentUsuario._id
    };


    const urlAtualizaCoordenadasUsuario = '/admin/atualizaLatitudeLongitudeUsuario';

    try {
     
     
      const response = await apiAxios.post(urlAtualizaCoordenadasUsuario, data);
     
      setShowAtualizaCoordUsuario(false);
      if(response.data.success){

        setUsuarios(response.data.usuarios);
        setTextBodyModal('Atualização realizada');
        setTitleTextBodyModal('Tudo OK !');
        setTitleModal('Sucesso :');
        setModalShow(true);

      }
      else{
        setTextBodyModal(response.data.message);
        setTitleTextBodyModal('Alteração Nao realizada !');
        setTitleModal('Verifique :');
        setModalShow(true);
      }


      
    } catch (error) {
      console.error('Erro ao atualiza coord estabelecimento:', error.response ? error.response.data : error.message);
    }


  }

  const onSubmitAtualizaCoordenadasEstabelecimento = async (event) => { 

    if(
      !event.target.elements.latitude.value || !event.target.elements.longitude.value
    ){

      alert('Latitude ou Longitude nao informados');
      return;
    }

    // Obtendo os valores dos campos através do evento de submissão
    const data = {
      latitude: event.target.elements.latitude.value,
      longitude: event.target.elements.longitude.value,
      estabelecimentoId: currentEstablishment._id
    };

    const urlAtualizaCoordenadasEstabelecimento = '/admin/atualizaLatitudeLongitudeEstabelecimento';

    try {
     
     
      const response = await apiAxios.post(urlAtualizaCoordenadasEstabelecimento, data);
     
      setShowAtualizaCoordEstabelecimento(false);
      if(response.data.success){

        setEstablishments(response.data.estabelecimentos);
        setTextBodyModal('Atualização realizada');
        setTitleTextBodyModal('Tudo OK !');
        setTitleModal('Sucesso :');
        setModalShow(true);

      }
      else{
        setTextBodyModal(response.data.message);
        setTitleTextBodyModal('Alteração Nao realizada !');
        setTitleModal('Verifique :');
        setModalShow(true);
      }


      
    } catch (error) {
      console.error('Erro ao atualiza coord estabelecimento:', error.response ? error.response.data : error.message);
    }

  }

  const onSubmitDesvicnEstabParceiro = async (data:any) => {

    const idEstabelecimento = currentEstablishment._id;
    const idParceiro = currentEstablishment.sistemaParceiro;

    const urlDesvicnEstabelecimento = '/admin/desvincularPlanoEstabelecimento';

    try {
     
     
      const response = await apiAxios.post(urlDesvicnEstabelecimento, {
        'idEstabelecimento':idEstabelecimento,
        'idParceiro':idParceiro
      });
     
      setShowDesvicEstabModal(false);
      if(response.data.success){

        setEstablishments(response.data.estabelecimentos);
        setTextBodyModal('Atualização realizada');
        setTitleTextBodyModal('Tudo OK !');
        setTitleModal('Sucesso :');
        setModalShow(true);

      }
      else{
        setTextBodyModal(response.data.message);
        setTitleTextBodyModal('Alteração Nao realizada !');
        setTitleModal('Verifique :');
        setModalShow(true);
      }


      
    } catch (error) {
      console.error('Erro ao desvincular estab de parceiro:', error.response ? error.response.data : error.message);
    }

  }

  const onSubmitPartner = async (data:any) => {
   

      const urlAtualizaDadosParceiro = '/admin/atualizaDadosParceiro';
   
      try {
        data.idParceiro = currentPartner._id
       
        const response = await apiAxios.post(urlAtualizaDadosParceiro, data);
       
        setShowPartnerModal(false);
        if(response.data.success){

          setPartners(response.data.parceiros);
          setTextBodyModal('Atualização realizada');
          setTitleTextBodyModal('Tudo OK !');
          setTitleModal('Sucesso :');
          setModalShow(true);

        }
        else{
          setTextBodyModal(response.data.message);
          setTitleTextBodyModal('Alteração Nao realizada !');
          setTitleModal('Verifique :');
          setModalShow(true);
        }


        
      } catch (error) {
        console.error('Erro ao atualizar parceiro:', error.response ? error.response.data : error.message);
      }

      //await axios.put(`/api/partners/${currentPartner.id}`, data);
    
    
    setShowPartnerModal(false);
    resetPartner();
  };

  const onSubmitEstablishment = async (data:any) => {
    
    
    const urlAtualizaDadosEstabelecimento = '/admin/atualizaDadosEstabelecimento';
   
      try {
        data.idEstabelecimento = currentEstablishment._id
       
        const response = await apiAxios.post(urlAtualizaDadosEstabelecimento, data);
       
        setShowEstablishmentModal(false);
        if(response.data.success){

          setEstablishments(response.data.estabelecimentos);
          resetEstablishment();
          setTextBodyModal('Atualização realizada');
          setTitleTextBodyModal('Tudo OK !');
          setTitleModal('Sucesso :');
          setModalShow(true);

        }
        else{
          setShowEstablishmentModal(false);
          setTextBodyModal(response.data.message);
          setTitleTextBodyModal('Alteração Nao realizada !');
          setTitleModal('Verifique :');
          setModalShow(true);
        }


        
      } catch (error) {
        console.error('Erro ao atualizar estabelecimentos:', error.response ? error.response.data : error.message);
      }

   
   
    
  };

  const onSubmitEditUsuario = async (data:any) => {
    
    const urlEdicaoUsuario = '/admin/atualizarDadosUsuario';

      try {
        data.idUsuario = currentUsuario._id
       console.log(JSON.stringify(data));
        const response = await apiAxios.post(urlEdicaoUsuario, data);
        setShowEditUsuario(false);
        if(response.data.success){

          setUsuarios(response.data.usuarios);
          setTextBodyModal('Atualização realizada');
          setTitleTextBodyModal('Tudo OK !');
          setTitleModal('Sucesso :');
          setModalShow(true);
          resetUsuario();
        }
        else{
          setTextBodyModal(response.data.message);
          setTitleTextBodyModal('Alteração Nao realizada !');
          setTitleModal('Verifique :');
          setModalShow(true);
        }


        
      } catch (error) {
        console.error('Erro ao atualizar usuario:', error.response ? error.response.data : error.message);
      }

  };

  const onSubmitPlanParceiro = async (data) => {

    const urlAtualizaPlanoParceiro = '/admin/addPlanoParceiro';
      try {
        data.idParceiro = currentPartner._id
        data.valorPlano = data.valorPlano.toString().replace(/\./g, '').replace(',', '.');
        const response = await apiAxios.post(urlAtualizaPlanoParceiro, data);
        setShowPlanPartnerModal(false);
        if(response.data.success){

          setPartners(response.data.parceiros);
          setTextBodyModal('Atualização realizada');
          setTitleTextBodyModal('Tudo OK !');
          setTitleModal('Sucesso :');
          setModalShow(true);
          resetPlanParceiro();
        }
        else{
          setTextBodyModal(response.data.message);
          setTitleTextBodyModal('Alteração Nao realizada !');
          setTitleModal('Verifique :');
          setModalShow(true);
        }


        
      } catch (error) {
        console.error('Erro ao atualizar parceiro:', error.response ? error.response.data : error.message);
      }
      

  }

  const onSubmitPlanEstabelecimento = async (data) => {

       //Add Plano Estabelecimento
     const urlAtualizaPlanoEstabelecimento = '/admin/addPlanoEstabelecimento';
     try {
       data.idEstabelecimento = currentEstablishment._id
       data.valorPlano = data.valorPlano.toString().replace(/\./g, '').replace(',', '.');
       const response = await apiAxios.post(urlAtualizaPlanoEstabelecimento, data);
       setShowPlanEstabelecimentoModal(false);
       if(response.data.success){

         setEstablishments(response.data.estabelecimentos);
         setTextBodyModal('Atualização realizada');
         setTitleTextBodyModal('Tudo OK !');
         setTitleModal('Sucesso :');
         setModalShow(true);
         resetPlanEstabelecimento();
       }
       else{
         setTextBodyModal(response.data.message);
         setTitleTextBodyModal('Alteração Nao realizada !');
         setTitleModal('Verifique :');
         setModalShow(true);
       }


       
     } catch (error) {
       console.error('Erro ao atualizar parceiro:', error.response ? error.response.data : error.message);
     }


  }

  const atualizarGeminiEstabelecimento = async (idEstabelecimento:any) => {
   
     
    const url = '/admin/atualizaContextGeminiEstabelecimento';
   
    
      const response = await apiAxios.post(url, {'idEstabelecimento':idEstabelecimento});
     
      if(response.data.success){

       
        setTextBodyModal('Atualização realizada');
        setTitleTextBodyModal('Tudo OK !');
        setTitleModal('Sucesso :');
        setModalShow(true);
       
      }
      else{
        setTextBodyModal(response.data.message);
        setTitleTextBodyModal('Alteração Nao realizada !');
        setTitleModal('Verifique :');
        setModalShow(true);
      }


      
    
  };

  const handleEditPartner = (partner) => {
    setCurrentPartner(partner);
    resetPartner(partner);
    setShowPartnerModal(true);
  };

  const handleDeleteParceiro = (partner)=> {
    setCurrentPartner(partner);
    setShowModalConfirmDeletePartner(true);
  }

  const handleDeleteEstabelecimento = (establishment) =>{
    setCurrentEstablishment(establishment);
    setShowModalConfirmDeleteEstablishment(true);
  }

  const handleEditEstablishment = (establishment) => {
    setCurrentEstablishment(establishment);
    resetEstablishment(establishment);
    setShowEstablishmentModal(true);
  };

  const handleEditUsuario = (usuario:any) => {
    setCurrentUsuario(usuario);
    resetUsuario(usuario);
    setShowEditUsuario(true);
   
  };

  const handleAtualCoordUsuario = (usuario:any) => {
    setCurrentUsuario(usuario);
   
  setShowAtualizaCoordUsuario(true);
   
  };
  

  const handleAddPlan = (isPartner, entity) => {
    setIsPartnerPlan(isPartner);
    if (isPartner) {
      setCurrentPartner(entity);
      setShowPlanPartnerModal(true);
    } else {
      setCurrentEstablishment(entity);
      setShowPlanEstabelecimentoModal(true);
    }
   
  };

  const consultarProdutosEstabelecimento = async (idEstabelecimento:any) => {
    try {

      const url = '/admin/consultarProdutosEstabelecimento';

      const response = await apiAxios.post(url, {'idEstabelecimento':idEstabelecimento});
      
       if(response.data.success){

         
        setProdutosEstabelecimento(response.data.produtos || []);
        // Chama a função para abrir a modal e exibir os produtos
        abrirModalProdutosEstabelecimento();
       }
       else{
         setTextBodyModal(response.data.message);
         setTitleTextBodyModal('Consulta Nao realizada !');
         setTitleModal('Verifique :');
         setModalShow(true);
       }


     
    } catch (error) {
      console.error("Erro ao consultar produtos do estabelecimento:", error);
    }
  };

  const abrirModalProdutosEstabelecimento = () => {
    
    setShowModalProdutosEstabelecimento(true);
  };
  

const handleAtualizaCoordenadasEstabelecimento = (entity:any) => {
  setCurrentEstablishment(entity);
  setShowAtualizaCoordEstabelecimento(true);
}

  const handleDesatrelarPlanoParceiro = (isPartner, entity:any) => {
    setIsPartnerPlan(isPartner);
    if (isPartner) {
      setCurrentPartner(entity);
    } else {
      setCurrentEstablishment(entity);
      //chamar a modal para verificar se realmente que desvicular do parceiro
      setShowDesvicEstabModal(true);
    }
    //setShowPlanModal(true);
  };


const handleBlockEstabelecimento = async (isBlock:any, entity:any) => {

  const urlBloquearDesbloquearEstabelecimento = '/admin/bloquearDesbloquearEstabelecimento';
  try {
    
   
    const response = await apiAxios.post(urlBloquearDesbloquearEstabelecimento, {
      'idEstabelecimento' :entity._id,
      'isBloquear':isBlock
    });
   
    if(response.data.success){

      setEstablishments(response.data.estabelecimentos);
      setTextBodyModal('Atualização realizada');
      setTitleTextBodyModal('Tudo OK !');
      setTitleModal('Sucesso :');
      setModalShow(true);

    }
    else{
      setTextBodyModal(response.data.message);
      setTitleTextBodyModal('Alteração Nao realizada !');
      setTitleModal('Verifique :');
      setModalShow(true);
    }


    
  } catch (error) {
    console.error('Erro ao atualizar parceiro:', error.response ? error.response.data : error.message);
  }

}

  const handleBlock = async (isPartner, entity:any) => {
    setIsPartnerPlan(isPartner);
    if (isPartner) {
      const urlBloquearParceiro = '/admin/bloquearParceiro';
      try {
        
       
        const response = await apiAxios.post(urlBloquearParceiro, {
          'idParceiro' :entity._id
        });
       
        if(response.data.success){

          setPartners(response.data.parceiros);
          setTextBodyModal('Atualização realizada');
          setTitleTextBodyModal('Tudo OK !');
          setTitleModal('Sucesso :');
          setModalShow(true);

        }
        else{
          setTextBodyModal(response.data.message);
          setTitleTextBodyModal('Alteração Nao realizada !');
          setTitleModal('Verifique :');
          setModalShow(true);
        }


        
      } catch (error) {
        console.error('Erro ao atualizar parceiro:', error.response ? error.response.data : error.message);
      }
    } else {
      setCurrentEstablishment(entity);
    }
    
  };

  // Funções de exemplo para bloqueio e edição
  async function handleAlterarStatusMotorista(id, statusAtual) {
    const novoStatus = statusAtual === 'A' ? 'B' : 'A';
   
    const url = '/admin/alterarStatusMotorista';

    const response = await apiAxios.post(url, {
      'status':novoStatus,
      'idMotorista':id
    });
       
     
    if(response.data.success){

      setMotoristas(response.data.motoristas);
      setTextBodyModal('Atualização realizada');
      setTitleTextBodyModal('Tudo OK !');
      setTitleModal('Sucesso :');
      setModalShow(true);

    }
    else{
      setTextBodyModal(response.data.message);
      setTitleTextBodyModal('Alteração Nao realizada !');
      setTitleModal('Verifique :');
      setModalShow(true);
    }
  }

  async function consultarComissoesMotorista(idMotorista:any) {
    try {
       

      const url = '/admin/consultarComissoesMotorista';
      const response = await apiAxios.post(url, {
        'idMotorista' :idMotorista
      });
  
       
  
      if (response.data.success) {
        // Supondo que você tenha uma função para exibir o modal e preencher os dados
        abrirModalComissoes(response.data.comissoes,response.data.gastosComissoes);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Erro ao consultar comissões do motorista:', error);
    }
  }

  function abrirModalComissoes(comissoes:any,gastosComissoes:any) {
    
    setModalDadosComissoes({ 
      estabelecimentos: comissoes, 
      gastosComissoes: gastosComissoes 
    });
    setShowModalComissoesMoto(true); // Abre o modal
  }

  function handleEditarMotorista(motorista:any) {
    setCurrentMotorista(motorista);
    setShowModalEditMotorista(true);

    // Lógica para editar motorista
  }

  
  const handleLiberar = async (isPartner, entity:any) => {
    setIsPartnerPlan(isPartner);
    if (isPartner) {
      const urlDesBloquearParceiro = '/admin/desbloquearParceiro';
      try {
        
       
        const response = await apiAxios.post(urlDesBloquearParceiro, {
          'idParceiro' :entity._id
        });
       
        if(response.data.success){

          setPartners(response.data.parceiros);
          setTextBodyModal('Atualização realizada');
          setTitleTextBodyModal('Tudo OK !');
          setTitleModal('Sucesso :');
          setModalShow(true);

        }
        else{
          setTextBodyModal(response.data.message);
          setTitleTextBodyModal('Alteração Nao realizada !');
          setTitleModal('Verifique :');
          setModalShow(true);
        }


        
      } catch (error) {
        console.error('Erro ao atualizar parceiro:', error.response ? error.response.data : error.message);
      }
    } else {
      setCurrentEstablishment(entity);
    }
    
  };

  
  const sair = () => {
    logoffAdmin();
    props.callbackAdminIsLoged(false);
  };

  return (
    <div>
       <section className="my-4">
     <MyVerticallyCenteredModal
     show={modalShow}
     onHide={() => setModalShow(false)}
     textBodyModal={textBodyModal}
     titleTextBodyModal={titleTextBodyModal}
     titleModal={titleModal}
   /> 
   <Container>
    <Row className="mb-3">
    <Col>
    <Button variant="danger" onClick={sair}>Sair</Button>
    </Col>
    </Row>
     

     
    <Tabs
      defaultActiveKey="parceiros"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="parceiros" title="Sist Parceiros">
    
 
 <Container>
     <h2>Partners</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Tel</th>
            <th>Pl Ativo ?</th>
            <th>VLr Plano</th>
            <th>Dt Inicio / Fim Plano</th>
            <th>Qtde Estab Plano</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {partners.map( (partner) => (
            <tr key={partner._id}>
              <td>{partner.nomeSistema}</td>
              <td>{partner.email}</td>
              <td>{partner.telefone}</td>
              <td>{ 
              (
                partner.dataInicioPlano && partner.dataFimPlano ?
                (isPlanActive(partner.dataInicioPlano, partner.dataFimPlano) ? 'Sim' : 'Não')
                :
                'Não'
              )
               }</td>
              <td>{converterDecimal128ParaPrecoBrasil(partner.valorPlano)}</td>
              <td>{formatDateDDMMAAAA(partner.dataInicioPlano)} a {formatDateDDMMAAAA(partner.dataFimPlano)}</td>
              <td>{partner.qtdeEstabelecimentosPlano ? partner.qtdeEstabelecimentosPlano : '-'}</td>
              <td>{partner.status}</td>
              <td>
                <Button onClick={() => handleEditPartner(partner)}>Edit</Button>
                <Button onClick={() => handleAddPlan(true, partner)}>Add Plan</Button>
                {
                partner.status == 'B' ? 
                <Button onClick={() => handleLiberar(true, partner)}>Liberar</Button>
                  :
                <Button onClick={() => handleBlock(true, partner)}>Block</Button>
              }
              <Button variant="danger" onClick={() => handleDeleteParceiro(partner)} className="me-2 mb-2">Deletar</Button>
                
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
  </Container>

      </Tab>
      <Tab eventKey="estabelecimentos" title="Estabelecimentos">
      <Container>
     <h2>Establishments</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Parc Moto ?</th>
            <th>Tel</th>
            <th>Sist Parceiro</th>
            <th>Qtde Produtos</th>
            <th>Status</th>
            <th>Pl Ativo</th>
            <th>Vr Plano</th>
            <th>Dt Inicio/Fim Pl</th>
            <th>Coord Atualiza</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {establishments.map((establishment) => (
            <tr key={establishment.id}>
              <td>{establishment.nome_estabelecimento}</td>
              <td>{establishment.email}</td>
              <td>{establishment.isParticipaRedeMotoristasAppTaxista ? 'Sim':'Não'}</td>
              <td>{establishment.telefone}</td>
              <td>{establishment.nomeSistemaParceiro ? establishment.nomeSistemaParceiro : '-' }</td>
              <td>{establishment.produtos_venda.length}</td>
              <td>{establishment.status}</td>
              <td>
                {  
              
              (
              establishment.dtFimPeriodoGratis && isFreePeriod(establishment.dtFimPeriodoGratis) ? 
              'Sim-Per Gratis'
              :
              (
                establishment.dataInicioPlano && establishment.dataFimPlano ?
                isPlanActive(establishment.dataInicioPlano, establishment.dataFimPlano) ? 'Sim' : 'Não'
                :
                'Não'
              )
               
              )
              
              
              
              }
              </td>
              <td>{establishment.valorPlano ? converterDecimal128ParaPrecoBrasil(establishment.valorPlano) : '-'}</td>
              <td>
              {(establishment.dataInicioPlano ? formatDateDDMMAAAA(establishment.dataInicioPlano) : '-')}
               a 
              {(establishment.dataFimPlano ? formatDateDDMMAAAA(establishment.dataFimPlano) : '-')}
              </td>
              <td>{establishment.coordenadasAtualizadas   ?   'Sim' : 'Nao'}</td>
              <td>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '5px', // Espaçamento entre botões
                }}
              >
                <Button variant="primary" onClick={() => handleEditEstablishment(establishment)} style={{flex: '1 1 30%', marginBottom: '0' }}>Edit</Button>
                <Button variant="secondary" onClick={() => handleAddPlan(false, establishment)} style={{flex: '1 1 30%', marginBottom: '0' }}>Add Plan</Button>
                <Button variant="success" onClick={() => handleAtualizaCoordenadasEstabelecimento(establishment)} style={{flex: '1 1 30%', marginBottom: '0' }}>Atual. Coord</Button>
                <Button variant="info" onClick={() => consultarProdutosEstabelecimento(establishment._id)}>Produtos</Button>
               {
                establishment.status == 'B' ?
                <Button variant="success" onClick={() => handleBlockEstabelecimento(false,establishment)} style={{flex: '1 1 30%', marginBottom: '0' }}>Desbloquear</Button>
              :
              <Button variant="danger" onClick={() => handleBlockEstabelecimento(true,establishment)} style={{flex: '1 1 30%', marginBottom: '0' }}>Bloquear</Button>
              }

                {
                  establishment.nomeSistemaParceiro ?
                  <Button variant="warning" onClick={() => handleDesatrelarPlanoParceiro(false, establishment)} style={{flex: '1 1 30%', marginBottom: '0' }}>Remove Parceiro</Button>
                  :
                  ''
                }
                <Button variant="danger" onClick={() => handleDeleteEstabelecimento(establishment)} style={{flex: '1 1 30%', marginBottom: '0' }}>Deletar</Button>
                
                <Button variant="info" onClick={() => atualizarGeminiEstabelecimento(establishment._id)} style={{ flex: '1 1 30%' }}>
                  Gemini
                </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
  </Container>
      </Tab>
      <Tab eventKey="usuarios" title="Usuarios">
      <Container>
    <h2>Usuarios</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Coord Atuliz</th>
            <th>Is Motorista</th>
            <th>Acoes</th>
            
          </tr>
        </thead>
        <tbody>
          {usuarios.map((usuario) => (
            <tr key={usuario.id}>
              <td>{usuario.nome}</td>
              <td>{usuario.email}</td>
              <td>{usuario.coordenadasAtualizadas ? 'Sim':'Nao'}</td>
              <td>{usuario.isMotoristaAppTaxista ? 'Sim':'Nao'}</td>
              <td>
                <Button onClick={() => handleEditUsuario(usuario)}>Edit</Button>
                <Button onClick={() => handleAtualCoordUsuario(usuario)}>Atual Coord</Button>
               
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
  </Container>
      </Tab>
      <Tab eventKey="motoristas" title="Motoristas">
      <Container>
        <h1>Motoristas</h1>
        {motoristas && motoristas.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nome</th>
                <th>App</th>
                <th>Código do Motorista</th>
                <th>Comissão Total</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {motoristas.map((motorista) => (
                <tr key={motorista._id}>
                  <td>{motorista.nomeMotorista}</td>
                  <td>{motorista.app}</td>
                  <td>{motorista.codigoMotorista}</td>
                  <td>  {motorista.valorTotalComissaoMotorista && !isNaN(parseFloat(motorista.valorTotalComissaoMotorista.$numberDecimal))
    ? parseFloat(motorista.valorTotalComissaoMotorista.$numberDecimal).toFixed(2)
    : 'Sem comissão'}</td>
                  <td>{motorista.status === 'A' ? 'Ativo' : 'Bloqueado'}</td>
                  <td>
                  <Button
                      variant={motorista.status === 'A' ? 'warning' : 'success'}
                      onClick={() => handleAlterarStatusMotorista(motorista.idMotorista, motorista.status)}
                    >
                      {motorista.status === 'A' ? 'Bloquear' : 'Desbloquear'}
                    </Button>{' '}
                    <Button variant="primary" onClick={() => handleEditarMotorista(motorista)}>
                      Editar
                    </Button>{' '}
                <Button variant="info" onClick={() => consultarComissoesMotorista(motorista.idMotorista)}>
                  Consultar
                </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>Carregando motoristas...</p>
        )}
      </Container>
    </Tab>
      <Tab eventKey="controles" title="Controles">
     <Container>
      <h1>Atualizar Campos</h1>
      <Form>
        <Form.Group as={Row} controlId="formQtdeDiasInicioGratisEstabelecimento">
          <Form.Label column sm={4}>Qtde Dias Inicio Gratis Estabelecimento</Form.Label>
          <Col sm={2}>
          <Form.Control
            type="text"
            value={qtdeDiasInicioGratisEstabelecimento}
            onChange={(e) => setQtdeDiasInicioGratisEstabelecimento(e.target.value)}
            pattern="\d*"
          />
           </Col>
           <Col sm={2}> 
        <Button variant="primary" onClick={handleUpdateDiasGratisEstabelecimento}>
          Atualizar
        </Button>
        </Col>
        </Form.Group>
      </Form>
      <Form>
        <Form.Group as={Row} controlId="formQtdeDiasInicioGratisParceiro">
          <Form.Label column sm={4}>Qtde Dias Inicio Gratis Parceiro</Form.Label>
          <Col sm={2}>
          <Form.Control
            type="text"
            value={qtdeDiasInicioGratisParceiro}
            onChange={(e) => setQtdeDiasInicioGratisParceiro(e.target.value)}
            pattern="\d*"
          />
          </Col>
          <Col sm={2}> 
        <Button variant="primary" onClick={handleUpdateDiasGratisParceiro}>
          Atualizar
        </Button>
        </Col>
        </Form.Group>
      </Form>
      
      <Form>
        <Form.Group as={Row} controlId="formMaxTokensMesChatEstabelecimentosOpenAi">
          <Form.Label column sm={4}>Max Tokens Mes Chat Estabelecimentos</Form.Label>
          <Col sm={2}>
          <Form.Control
            type="text"
            value={maxTokensMesChatEstabelecimentosOpenAi}
            onChange={(e) => setMaxTokensMesChatEstabelecimentosOpenAi(e.target.value)}
            pattern="\d*"
          />
          </Col>
          <Col sm={2}> 
        <Button variant="primary" onClick={handleUpdateMaxTokensMesChatEstabelecimentosOpenAi}>
          Atualizar
        </Button>
        </Col>
        </Form.Group>
      </Form>

      
      <Form>
        <Form.Group as={Row} controlId="formPorcentagemComissaoMotoristas">
          <Form.Label column sm={4}>Porcentagem Comissao Motoristas</Form.Label>
          <Col sm={2}>
          <Form.Control
            type="text"
            value={porcentagemComissaoMotoristas}
            onChange={(e) => setPorcentagemComissaoMotoristas(e.target.value)}
            pattern="\d*"
          />
          </Col>
          <Col sm={2}> 
        <Button variant="primary" onClick={handleUpdatePorcentagemComissaoMotoristas}>
          Atualizar
        </Button>
        </Col>
        </Form.Group>
      </Form>

     
      <Form>
        <Form.Group as={Row} controlId="formQtdePublicacoesGratisEstabelecimentos">
          <Form.Label column sm={4}>Qtde Publicações Gratis Estabelecimentos</Form.Label>
          <Col sm={2}>
          <Form.Control
            type="text"
            value={qtdePublicacoesGratisEstabelecimentos}
            onChange={(e) => setQtdePublicacoesGratisEstabelecimentos(e.target.value)}
            pattern="\d*"
          />
          </Col>
          <Col sm={2}> 
        <Button variant="primary" onClick={handleUpdateQtdePublicacoesGratisEstabelecimentos}>
          Atualizar
        </Button>
        </Col>
        </Form.Group>
      </Form>

      

      
      
    </Container>
      </Tab>

     
    </Tabs>
      
    <Modal show={showModalComissoesMoto} onHide={() => setShowModalComissoesMoto(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Comissões do Motorista</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {/* Tabela de Comissões */}
    <h5>Comissões Recebidas</h5>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Estabelecimento</th>
          <th>Valor Gasto</th>
          <th>Valor Comissão</th>
          <th>Data</th>
        </tr>
      </thead>
      <tbody>
        {modalDadosComissoes.estabelecimentos.map((estabelecimento) => (
          estabelecimento.indicacoesMotorista.map((indicacao) => (
            <tr key={indicacao._id}>
              <td>{estabelecimento.nomeEstabelecimento}</td>
              <td>{indicacao.valorGastoEstabelecimento.$numberDecimal}</td>
              <td>{indicacao.valorComissaoMotorista.$numberDecimal}</td>
              <td>{new Date(indicacao.data).toLocaleDateString()}</td>
            </tr>
          ))
        ))}
      </tbody>
    </Table>

    {/* Tabela de Gastos com Comissões */}
    <h5>Gastos com Comissões</h5>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Estabelecimento</th>
          <th>Total Conta</th>
          <th>Gasto Comissão</th>
        </tr>
      </thead>
      <tbody>
        {modalDadosComissoes.gastosComissoes.map((gasto) => (
          <tr key={gasto._id}>
            <td>{gasto.nomeEstabelecimento}</td>
            <td>{gasto.totalConta.$numberDecimal}</td>
            <td>{gasto.gastoComissao.$numberDecimal}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModalComissoesMoto(false)}>
      Fechar
    </Button>
  </Modal.Footer>
</Modal>

           {/* Modal para editar motorista */}
        <Modal show={showModalEditMotorista} onHide={() => setShowModalEditMotorista(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Motorista</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmitAlterarMotorista}>
              <Form.Group controlId="formApp">
                <Form.Label>App: {(currentMotorista ? currentMotorista.app : '')}</Form.Label>
                <Form.Control
                  type="text"
                  
                  name='app'
                  required
                />
              </Form.Group>
              <Form.Group controlId="formCodigoMotorista">
                <Form.Label>Código do Motorista: {(currentMotorista ? currentMotorista.codigoMotorista : '')}</Form.Label>
                <Form.Control
                  type="text"
                  
                  name='codigoMotorista'
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Salvar Alterações
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      

      {/* Atualizar coordenadas usuario */}
      <Modal show={showAtualizaCoordUsuario} onHide={() => setShowAtualizaCoordUsuario(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Atualiza Coordenadas {currentUsuario ? currentUsuario.nome : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmitAtualizaCoordenadasUsuario}>
           
          <Form.Group controlId="formLatitude">
            <Form.Label>Latitude atual: {currentUsuario ? currentUsuario.latitude : ''}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Insira a latitude"
              name="latitude"
              required
            />
          </Form.Group>
          <Form.Group controlId="formLongitude" className="mt-3">
            <Form.Label>Longitude atual: {currentUsuario ? currentUsuario.longitude : ''}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Insira a longitude"
              name="longitude"
              required
            />
          </Form.Group>
            
          <Button variant="secondary" onClick={() => setShowAtualizaCoordUsuario(false)} className="me-2">Cancelar</Button>
            <Button type="submit">Atualizar</Button>
          </Form>
        </Modal.Body>
      </Modal>
      
         
{/* Atualizar coordenadas estabelecimento */}
      <Modal show={showAtualizaCoordEstabelecimento} onHide={() => setShowAtualizaCoordEstabelecimento(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Atualiza Coordenadas {currentEstablishment ? currentEstablishment.nome_estabelecimento : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmitAtualizaCoordenadasEstabelecimento}>
           
          <Form.Group controlId="formLatitude">
            <Form.Label>Latitude atual: {currentEstablishment ? currentEstablishment.latitude : ''}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Insira a latitude"
              name="latitude"
              required
            />
          </Form.Group>
          <Form.Group controlId="formLongitude" className="mt-3">
            <Form.Label>Longitude atual: {currentEstablishment ? currentEstablishment.longitude : ''}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Insira a longitude"
              name="longitude"
              required
            />
          </Form.Group>
            
          <Button variant="secondary" onClick={() => setShowAtualizaCoordEstabelecimento(false)} className="me-2">Cancelar</Button>
            <Button type="submit">Atualizar</Button>
          </Form>
        </Modal.Body>
      </Modal>

     

      {/* Confirmacao desvincular estabelecimento Modal */}
      <Modal show={showDesvicEstabModal} onHide={() => setShowDesvicEstabModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Desvincular{currentEstablishment ? currentEstablishment.nome_estabelecimento : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmitDesvicnEstabParceiro}>
           
          <Form.Label>Deseja realmente desvincular o: {currentEstablishment ? currentEstablishment.nome_estabelecimento : ''} do Parceiro: {currentEstablishment ? currentEstablishment.nomeSistemaParceiro : ''} ?</Form.Label>
            
          <Button variant="secondary" onClick={() => setShowDesvicEstabModal(false)} className="me-2">Cancelar</Button>
            <Button type="submit">Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>

      

      {/* Partner Modal */}
      <Modal show={showPartnerModal} onHide={() => setShowPartnerModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{currentPartner ? 'Edit Partner' : 'Add Partner'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitPartner(onSubmitPartner)}>
            <Form.Group controlId="partnerName">
              <Form.Label>Name</Form.Label>
              <Form.Control {...registerPartner('nomeSistema')} />
            </Form.Group>
            <Form.Group controlId="partnerEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" {...registerPartner('email')} />
            </Form.Group>
            <Form.Group controlId="partnerTelefone">
              <Form.Label>Telefone</Form.Label>
              <Form.Control {...registerPartner('telefone')} />
            </Form.Group>
            <Form.Group controlId="partnerCnpj">
              <Form.Label>CNPJ</Form.Label>
              <Form.Control {...registerPartner('cnpj')} />
            </Form.Group>
            <Form.Group controlId="partnerRua">
              <Form.Label>Rua</Form.Label>
              <Form.Control {...registerPartner('rua')} />
            </Form.Group>
            <Form.Group controlId="partnerNumero">
              <Form.Label>Numero</Form.Label>
              <Form.Control {...registerPartner('numero')} />
            </Form.Group>
            <Form.Group controlId="partnerCep">
              <Form.Label>Cep</Form.Label>
              <Form.Control {...registerPartner('cep')} />
            </Form.Group>
            <Form.Group controlId="partnerBairro">
              <Form.Label>Bairro</Form.Label>
              <Form.Control {...registerPartner('bairro')} />
            </Form.Group>
            <Form.Group controlId="partnerCidade">
              <Form.Label>Cidade</Form.Label>
              <Form.Control {...registerPartner('cidade')} />
            </Form.Group>
            <Form.Group controlId="partnerEstado">
              <Form.Label>Estado</Form.Label>
              <Form.Control {...registerPartner('estado')} />
            </Form.Group>
            <Button type="submit">Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Establishment Modal */}
      <Modal show={showEstablishmentModal} onHide={() => setShowEstablishmentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{currentEstablishment ? 'Edit Establishment' : 'Add Establishment'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitEstablishment(onSubmitEstablishment)}>
          
          <Form.Group controlId="establishmentDate">
              <Form.Label>Data Cadastro: { (currentEstablishment ? formatDateDDMMAAAA(currentEstablishment.createdAt) : '' )} </Form.Label>
            </Form.Group>

            <Form.Group controlId="total_tokens">
              <Form.Label>Total Tokens Chat GPT: { currentEstablishment ? currentEstablishment.total_tokens : '--'} </Form.Label>
            </Form.Group>
           
            <Form.Group controlId="establishmentName">
              <Form.Label>Name</Form.Label>
              <Form.Control {...registerEstablishment('nome_estabelecimento')} />
            </Form.Group>
            <Form.Group controlId="establishmentEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" {...registerEstablishment('email')} />
            </Form.Group>
            <Form.Group controlId="establishmentCnpj">
              <Form.Label>CNPJ</Form.Label>
              <Form.Control {...registerEstablishment('cnpj')} />
            </Form.Group>
            <Form.Group controlId="establishmentType">
              <Form.Label>Tipo</Form.Label>
              <Form.Control type="text" {...registerEstablishment('tipo_estabelecimento')} />
            </Form.Group>
            <Form.Group controlId="establishmentRua">
              <Form.Label>Rua</Form.Label>
              <Form.Control type="text" {...registerEstablishment('rua')} />
            </Form.Group>
            <Form.Group controlId="establishmentNumero">
              <Form.Label>Numero</Form.Label>
              <Form.Control type="text" {...registerEstablishment('numero')} />
            </Form.Group>
            <Form.Group controlId="establishmentCep">
              <Form.Label>Cep</Form.Label>
              <Form.Control type="text" {...registerEstablishment('cep')} />
            </Form.Group>
            <Form.Group controlId="establishmentBairro">
              <Form.Label>Bairro</Form.Label>
              <Form.Control type="text" {...registerEstablishment('bairro')} />
            </Form.Group>
            <Form.Group controlId="establishmentEstado">
              <Form.Label>Estado</Form.Label>
              <Form.Control type="text" {...registerEstablishment('estado')} />
            </Form.Group>
            <Form.Group controlId="establishmentCidade">
              <Form.Label>Cidade</Form.Label>
              <Form.Control type="text" {...registerEstablishment('cidade')} />
            </Form.Group>
            <Form.Group controlId="establishmentTelefone">
              <Form.Label>Telefone</Form.Label>
              <Form.Control type="text" {...registerEstablishment('telefone')} />
            </Form.Group>
            <Form.Group controlId="establishmentUsuarioInstagram">
              <Form.Label>Instagram</Form.Label>
              <Form.Control type="text" {...registerEstablishment('instagramUsername')} />
            </Form.Group>
            <Button type="submit">Submit</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
  <Form.Group controlId="fixedText">
    <Form.Label>Infos Contexto Gemini</Form.Label>
    <Form.Control 
      as="textarea" 
      rows={5}  // Define o número de linhas visíveis no textarea
      readOnly 
      style={{ width: '100%' }}
      value={(currentEstablishment ? 
            currentEstablishment.geminiContextEstabelecimento : 'Ainda não cadastrada infos')} 
    />
  </Form.Group>
</Modal.Footer>
      </Modal>
      

  

      {/* Edit Usuario Modal */}
      <Modal show={showEditUsuario} onHide={() => setShowEditUsuario(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{currentUsuario ? 'Edit Usuario' : ''}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitUsuario(onSubmitEditUsuario)}>
            <Form.Group controlId="usuarioNome">
              <Form.Label>Name</Form.Label>
              <Form.Control {...registerUsuario('nome')} />
            </Form.Group>
            <Form.Group controlId="usuarioEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" {...registerUsuario('email')} />
            </Form.Group>
            <Form.Group controlId="usuarioCelular">
              <Form.Label>Celular</Form.Label>
              <Form.Control {...registerUsuario('celular')} />
            </Form.Group>
            <Form.Group controlId="usuarioRua">
              <Form.Label>Rua</Form.Label>
              <Form.Control type="text" {...registerUsuario('rua')} />
            </Form.Group>
            <Form.Group controlId="usuarioNumero">
              <Form.Label>Numero</Form.Label>
              <Form.Control type="text" {...registerUsuario('numero')} />
            </Form.Group>
            <Form.Group controlId="usuarioCep">
              <Form.Label>Cep</Form.Label>
              <Form.Control type="text" {...registerUsuario('cep')} />
            </Form.Group>
            <Form.Group controlId="usuarioBairro">
              <Form.Label>Bairro</Form.Label>
              <Form.Control type="text" {...registerUsuario('bairro')} />
            </Form.Group>
            <Form.Group controlId="usuarioCidade">
              <Form.Label>Cidade</Form.Label>
              <Form.Control type="text" {...registerUsuario('cidade')} />
            </Form.Group>
            <Form.Group controlId="usuariEstado">
              <Form.Label>Estado</Form.Label>
              <Form.Control type="text" {...registerUsuario('estado')} />
            </Form.Group>
            <Button type="submit">Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Plan Modal */}
      <Modal show={showPlanEstabelecimentoModal} onHide={() => setShowPlanEstabelecimentoModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Establishment Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitPlanEstabelecimento(onSubmitPlanEstabelecimento)}>
            {/*<Form.Group controlId="planStartDate">
              <Form.Label>Start Date</Form.Label>
              <Form.Control type="date" {...registerPlan('startDate')} />
            </Form.Group>*/}
             <Form.Group controlId="planValue">
              <Form.Label>Valor Plano</Form.Label>
              <Form.Control type="number" step="0.01" {...registerPlanEstabelecimento('valorPlano')} />
            </Form.Group>
            <Form.Group controlId="planDays">
              <Form.Label>Days</Form.Label>
              <Form.Control type="number" {...registerPlanEstabelecimento('qtdeDiasPlano')} />
            </Form.Group>
            <Button type="submit">Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Partner Plan Modal */}
      <Modal show={showPlanPartnerModal} onHide={() => setShowPlanPartnerModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Partner Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitPlanParceiro(onSubmitPlanParceiro)}>
             <Form.Group controlId="planValue">
              <Form.Label>Valor Plano</Form.Label>
              <Form.Control type="number" step="0.01" {...registerPlanParceiro('valorPlano')} />
            </Form.Group>
            <Form.Group controlId="planDays">
              <Form.Label>Dias</Form.Label>
              <Form.Control type="number" {...registerPlanParceiro('qtdeDiasPlano')} />
            </Form.Group>
            <Form.Group controlId="planQtdeEstabelecimentos">
              <Form.Label>Qtde Estabelecimentos Plano</Form.Label>
              <Form.Control type="number" {...registerPlanParceiro('qtdeEstabelecimentosPlano')} />
            </Form.Group>
            <Button type="submit">Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showModalConfirmDeletePartner} onHide={() => setShowModalConfirmDeletePartner(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Deleção</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Você realmente quer deletar o parceiro <strong>{(currentPartner && currentPartner.nomeSistema ? currentPartner.nomeSistema : '')}</strong>?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModalConfirmDeletePartner(false)}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={onDeleteParceiro}>
          Deletar
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={showModalConfirmDeleteEstablishment} onHide={() => setShowModalConfirmDeleteEstablishment(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Deleção</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Você realmente quer deletar o estabelecimento <strong>{(currentEstablishment && currentEstablishment.nome_estabelecimento ? currentEstablishment.nome_estabelecimento : '')}</strong>?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModalConfirmDeleteEstablishment(false)}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={onDeleteEstabelecimento}>
          Deletar
        </Button>
      </Modal.Footer>
    </Modal>
    
    <Modal show={showModalProdutosEstabelecimento} onHide={() => setShowModalProdutosEstabelecimento(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Produtos do Estabelecimento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nome do Produto</th>
              <th>Descrição</th>
              <th>Marca</th>
              <th>Preço</th>
              <th>Status</th>
              <th>Categoria</th>
              <th>Dt Vcto</th>
              <th>Qtde Prom</th>
            </tr>
          </thead>
          <tbody>
            {produtosEstabelecimento.map((produto) => { 

const formatarData = (dataIso) => {
  if (!dataIso) return '-'; // Se não tiver data
  const data = new Date(dataIso);
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0');
  const ano = data.getFullYear();
  return `${dia}/${mes}/${ano}`;
};

return (
              <tr key={produto._id }>
                <td>{produto.nome_produto}</td>
                <td>{produto.descricao || '-'}</td>
                <td>{produto.marca || '-'}</td>
                <td> {produto.preco && !isNaN(parseFloat(produto.preco.$numberDecimal))
    ? parseFloat(produto.preco.$numberDecimal).toFixed(2)
    : '0'}</td>
                <td>{produto.status}</td>
                <td>{produto.categoria}</td>
                <td>{formatarData(produto.dataVencimentoPromocao)}</td>
                <td>{produto.qtdeParaPromocao || '-'}</td>
              </tr>
               );
            })}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModalProdutosEstabelecimento(false)}>Fechar</Button>
      </Modal.Footer>
    </Modal>
      </Container>

      </section>
    </div>
  );
};

export default SectionAdmin;
