const getCoordinates = async () => {
  
  // Função para detectar o navegador baseado no userAgent
  const detectBrowser = () => {
    const userAgent = navigator.userAgent;
    if (userAgent.includes("Chrome") && !userAgent.includes("Edge")) {
      return "Chrome";
    } else if (userAgent.includes("Firefox")) {
      return "Firefox";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      return "Safari";
    } else if (userAgent.includes("Edge")) {
      return "Edge";
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
      return "Opera";
    } else {
      return "Unknown Browser";
    }
  };
  
  try {
    // Verifica o status da permissão de geolocalização
    const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

    // Define o status inicial como 'unknown'
    let permissionState = 'unknown';

    if (permissionStatus.state === 'granted') {
      permissionState = 'granted';
    } else if (permissionStatus.state === 'denied') {
      permissionState = 'denied';
    } else if (permissionStatus.state === 'prompt') {
      permissionState = 'prompt';
    }

    

    const browser = detectBrowser();

    return new Promise((resolve) => {
      if (!navigator.geolocation) {
        resolve({
          status: 'unsupported',
          permissionState,
          latitude: null,
          longitude: null,
          message: 'Geolocation is not supported by this browser.',
          browser
        });
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({
            status: 'success',
            permissionState,
            latitude,
            longitude,
            message: 'Geolocation successful.',
            browser,
          });
        },
        (error) => {
          let status;
          let message;

          switch (error.code) {
            case error.PERMISSION_DENIED:
              status = 'denied';
              message = 'User denied the request for Geolocation.';
              break;
            case error.POSITION_UNAVAILABLE:
              status = 'unavailable';
              message = 'Location information is unavailable.';
              break;
            case error.TIMEOUT:
              status = 'timeout';
              message = 'The request to get user location timed out.';
              break;
            default:
              status = 'error';
              message = 'An unknown error occurred.';
              break;
          }

          resolve({
            status,
            permissionState,
            latitude: null,
            longitude: null,
            message,
            browser,
          });
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    });
  } catch (error) {
    // Caso o browser não suporte a API Permissions ou ocorra outro erro
    const browser = detectBrowser();
    return {
      status: 'error',
      permissionState: 'unknown',
      latitude: null,
      longitude: null,
      message: error.message || 'An error occurred while checking permissions.',
      browser,
    };
  }
};

export default getCoordinates;
