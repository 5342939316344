import socketio from 'socket.io-client';
import {io} from 'socket.io-client';
import { getTokenIsAuthenticatedClient, getTokenIsAuthenticatedEstabelecimento, getTokenSocketLocalStorage } from '../services/auth';
import env from 'react-dotenv';
import { v4 as uuidv4 } from 'uuid';


const urlSocketPingUserLogado = 'wss://api.geoget.com.br/pingUsuarioLogado';

/*
export const socketPingUsuarioLogado = socketio.connect(urlSocketPingUserLogado, {
  transports : ['websocket'],
  reconnectionDelayMax: 10000,
    auth: {
        token: getTokenSocketLocalStorage()
      }
});*/


export const socketPingUsuarioLogado = io(urlSocketPingUserLogado,{

  transports : ['websocket'],
  reconnectionDelayMax: 10000,
  auth:(cb) =>{

    let idPing = '';
    if(
      localStorage.getItem('socketIdPingUsuarioLogado') === null || 
      localStorage.getItem('socketIdPingUsuarioLogado') === undefined
    ){

      idPing = uuidv4();
      
      localStorage.setItem('socketIdPingUsuarioLogado',idPing);
    }
    else{
      idPing = localStorage.getItem('socketIdPingUsuarioLogado');
    }

    cb ({
      token: getTokenSocketLocalStorage(),
      socketIdPing:idPing,
      tokenUsuarioLogado: getTokenIsAuthenticatedClient()
    });
  },
  autoConnect:false

});


socketPingUsuarioLogado.on("connect_error", (err) => {
  // the reason of the error, for example "xhr poll error"
  console.log('MENSAGEM ERRO SOCKET PING => '+ err.message);

  // some additional description, for example the status code of the initial HTTP response
  console.log('DESCRICAO ERRO SOCKET PING => '+ err.description);

  // some additional context, for example the XMLHttpRequest object
  console.log('CONTEXT ERRO SOCKET PING => '+err.context);
});

socketPingUsuarioLogado.on("connect", (err) => {
  // the reason of the error, for example "xhr poll error"
  console.log('ID SOCKET PING CONECTADO => '+ socketPing.id);


});


//https://socket.io/docs/v3/client-initialization/
const urlSocketPing = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 
//Dev
env.ADDRESS_API_DESENV
//'http://localhost:3333/ping'
:
//Prd
'wss://api.geoget.com.br/ping'
) ;

export const socketPing = io(urlSocketPing,{

  transports : ['websocket'],
  reconnectionDelayMax: 10000,
  auth: (cb) =>{

    let idPing = '';
    if(
      localStorage.getItem('socketIdPing') === null || 
      localStorage.getItem('socketIdPing') === undefined
    ){

      idPing = uuidv4();
      
      localStorage.setItem('socketIdPing',idPing);
    }
    else{
      idPing = localStorage.getItem('socketIdPing');
    }

    cb ({
      token: getTokenSocketLocalStorage(),
      socketIdPing:idPing
    });
  }
  
  ,
  autoConnect:false

});

/*
export const socketPing = socketio.connect(urlSocketPing, { 
    transports : ['websocket'],
    reconnectionDelayMax: 10000,
    auth: {
        token: getTokenSocketLocalStorage()
      },
    autoConnect:false
    });*/

    socketPing.on("connect", (err) => {
      // the reason of the error, for example "xhr poll error"
      console.log('ID SOCKET PING CONECTADO => '+ socketPing.id);


    });


    socketPing.on("connect_error", (err) => {
      // the reason of the error, for example "xhr poll error"
      console.log('MENSAGEM ERRO SOCKET PING => '+ err.message);
    
      // some additional description, for example the status code of the initial HTTP response
      console.log('DESCRICAO ERRO SOCKET PING => '+ err.description);
    
      // some additional context, for example the XMLHttpRequest object
      console.log('CONTEXT ERRO SOCKET PING => '+err.context);
    });

  
    const urlSocketEstabelecimento = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 
//Dev
env.ADDRESS_API_DESENV_ESTABELECIMENTOS_GET_LEADS_PROXIMOS
//'http://localhost:3333/estabelecimentoGetLeadsProximos'
:
//Prd
'wss://api.geoget.com.br/estabelecimentoGetLeadsProximos'
) ;

/*
    export const socketEstabelecimento = socketio.connect(urlSocketEstabelecimento, { 
      
    transports : ['websocket'],
    reconnectionDelayMax: 10000,
    auth: {
        token: getTokenSocketLocalStorage()
      }
    });*/

    export const socketEstabelecimento = io(urlSocketEstabelecimento,{

      transports : ['websocket'],
      reconnectionDelayMax: 10000,
      auth:(cb) =>{

        let idPing = '';
        if(
          localStorage.getItem('socketIdPingEstabelecimentoLogado') === null || 
          localStorage.getItem('socketIdPingEstabelecimentoLogado') === undefined
        ){
    
          idPing = uuidv4();
          
          localStorage.setItem('socketIdPingEstabelecimentoLogado',idPing);
        }
        else{
          idPing = localStorage.getItem('socketIdPingEstabelecimentoLogado');
        }
    
        cb ({
          token: getTokenSocketLocalStorage(),
          socketIdPing:idPing,
          jwtSocketIoEstabelecimentoLogado:getTokenIsAuthenticatedEstabelecimento()
        });
      },
      autoConnect:false
    
    });

    socketEstabelecimento.on("connect", (err) => {
      // the reason of the error, for example "xhr poll error"
      console.log('ID SOCKET ESTABELECIMENTO LOGADO CONECTADO => '+ socketEstabelecimento.id);


    });


    socketEstabelecimento.on("connect_error", (err) => {
      // the reason of the error, for example "xhr poll error"
      console.log('MENSAGEM ERRO SOCKET ESTABELECIMENTO LOGADO => '+ err.message);
    
      // some additional description, for example the status code of the initial HTTP response
      console.log('DESCRICAO ERRO SOCKET ESTABELECIMENTO LOGADO => '+ err.description);
    
      // some additional context, for example the XMLHttpRequest object
      console.log('CONTEXT ERRO SOCKET ESTABELECIMENTO LOGADO => '+err.context);
    });

    /*
    const urlSocketProdutosPublicados = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 
    //Dev
   env.ADDRESS_API_DESENV_ESTABELECIMENTOS_GET_PRODUTOS_PUBLICADOS
    // 'http://localhost:3333/produtosPublicados'
    :
    //Prd
    'wss://api.geoget.com.br/produtosPublicados'
    ) ;

    export const produtosPublicados = socketio.connect(urlSocketProdutosPublicados, { 
    transports : ['websocket'],
    reconnectionDelayMax: 10000,
    auth: {
        token: getTokenSocketLocalStorage()
      }
    });*/
