import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import ImagemVantagem1 from '../assets/images/grupoachoucerveja.webp';
import ImagemVantagem2 from '../assets/images/gruposelecionandoamelhoroferta.webp';
import ImagemVantagem3 from '../assets/images/recebendomensagens.webp';
import ImagemVantagem4 from '../assets/images/cervejaefelizes.webp';
import ImagemVantagem5 from '../assets/images/clientescomplaca.webp';
import ImagemVantagem6 from '../assets/images/clientesblindando.webp';
import ImagemVantagem7 from '../assets/images/chegandonorestaurante.webp';


// Lista de imagens importadas.
const imageImports = [ImagemVantagem1, ImagemVantagem2, ImagemVantagem3, ImagemVantagem4, ImagemVantagem5, ImagemVantagem6, ImagemVantagem7];
// Lista de frases para serem exibidas.
const phrases = [
    'a integração da API da Geoget pode transformar a forma como restaurantes e bares gerenciam suas promoções e operações diárias.',
    'Descubra o poder da geolocalização: revolucione as promoções e estratégias de seus clientes, através da API da Geoget!',
    'Transforme dados em ação: aumente a eficiência e o lucro dos bares ou restaurantes com as vantagens exclusivas da Geoget!',
    'Desvende o fluxo de clientes dos Bares e Restaurantes: potencialize suas promoções com análises precisas de concentração de clientes!'
  ];

const SectionVantagemParceiros = (props) => {

    const [phrase, setPhrase] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        // Seleciona uma frase aleatória da lista a cada renderização.
        const randomIndex = Math.floor(Math.random() * phrases.length);
        setPhrase(phrases[randomIndex]);

         // Seleciona uma imagem aleatória da lista a cada renderização.
    const randomImageIndex = Math.floor(Math.random() * imageImports.length);
    setImageUrl(imageImports[randomImageIndex]);
      }, []); // O array vazio garante que a frase seja definida apenas uma vez por renderização.

    return (
        <Container className="my-4">
          <Row className="align-items-center">
            <Col md={6}>
              <Image src={imageUrl} alt="Geoget Geopromocoes de cervejas e vinhos" fluid />
            </Col>
            <Col md={6}>
              <h3>
              {phrase}
              </h3>
            </Col>
          </Row>
        </Container>
      );

}

export default SectionVantagemParceiros;