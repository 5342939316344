import Container  from "react-bootstrap/Container";
import Row  from "react-bootstrap/Row";
import Col  from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { getDataEstabelecimentoLocalStorage, getTokenIsAuthenticatedEstabelecimento, getTokenSocketLocalStorage, isAuthenticated, logout } from "../services/auth";
import apiAxios from "../services/api";
import FileDownload from "js-file-download";
import { socketEstabelecimento } from "../context/socket";
import MapLeaflet from "./mapLeaflet";
import SectionFormCarrosEdicaoCarros from "./sectionFormCarrosEdicaoCarros";

//import axios from "axios";


const SectionEdicaoProdutosCarros = () => {
 

const [numeroClientesPorPerto , setNumeroClientesPorPerto] = useState(2);
const [leads , setLeads] = useState([]);
const [dadosLocalizacao , setDadosLocalizacao] = useState({});


  useEffect(() => { 
    


     const onErrorLocation = (error) => {
      console.error(error);
      setDadosLocalizacao({
        
        loaded: true,
        coords: '',
        isGeolocationAvailable: false,
        isGeolocationEnabled : false,
        error: true
      
    });
    };

     const handleCurrentLocation = async (location) => { 
      
      
      setDadosLocalizacao({
        
          loaded: true,
          coords: location.coords,
          isGeolocationAvailable: true,
          isGeolocationEnabled : true,
          error: false
        
      });

      const posicaoAtual = {latitude:location.coords.latitude, longitude:location.coords.longitude};
        
        
        //Executar aqui a verificação com o socket

        var tipoEstabelecimento = getDataEstabelecimentoLocalStorage().tipo_estabelecimento;
       
        var produtosPreferencia = [];
        var categoriasPreferencia = [];


        if(tipoEstabelecimento == 'Concessionaria de Carros' || tipoEstabelecimento  == 'Multimarcas de Carros'){
          produtosPreferencia.push('Carros');
          categoriasPreferencia.push('Carros');
        }
        else{
          produtosPreferencia.push('Vinhos');
          produtosPreferencia.push('Cervejas');
          categoriasPreferencia.push('Vinhos');
          categoriasPreferencia.push('Cervejas');
        }

          
  
          const data = {
  
              //'latitude' : position.latitude, 
              //'longitude' : position.longitude,
              
              'latitude' : getDataEstabelecimentoLocalStorage().latitude, 
              'longitude' : getDataEstabelecimentoLocalStorage().longitude,
              'distanciaMetros' : '75000',
              'produtos' : produtosPreferencia,
              'categorias' : categoriasPreferencia,
              'jwtSocketIoEstabelecimentoLogado' : getTokenSocketLocalStorage()
  
          };
  
            
            //AQUI QUE A MAGICA ACONTECE
            console.log('ENVIANDO EMIT: estabelecimentoGetLeadsProximos ');
            socketEstabelecimento.emit("/estabelecimentoGetLeadsProximos", data);
        

      
    };

     const executeSetIntervalGeolocation = async () => {


      if (!navigator?.geolocation) {
        return;
      }

      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
       
      navigator.geolocation.getCurrentPosition(handleCurrentLocation, onErrorLocation,options);

    }
    if(isAuthenticated()){
      const interval = setInterval(executeSetIntervalGeolocation, 5000);
    
    return () => clearInterval(interval);
    }

    
     

  }, []);

  socketEstabelecimento.on('estabelecimentoGetLeadsProximos', data =>{ 

   // console.log(JSON.stringify(data));
    const leads = data.resultadoConsulta.pingsProximos;
    const usuariosLousuariosLogadosProximosgados = data.resultadoConsulta.usuariosLogadosProximos;
   
   
    const validationTokenSocket = data.validationTokenSocket;

    if(!validationTokenSocket){

      logoff();


    }

    var arrayLeads = [];

    if(leads.length > 0 ){
      leads.forEach(async (lead) => {
        arrayLeads.push(lead);
      });
    }

    if(usuariosLousuariosLogadosProximosgados.length > 0 ){
      usuariosLousuariosLogadosProximosgados.forEach(async (lead) => {
        arrayLeads.push(lead);
      });
    }
    console.log('LEANDS AND USUARIOS LOGADOS => '+JSON.stringify(arrayLeads));
    setNumeroClientesPorPerto(arrayLeads.length);
    setLeads(arrayLeads);
            });
  
     

const imgQrcode = useRef();




const sair = event => {

  logout();
  window.location.reload(false);


}

const logoff = () => {

  logout();
  window.location.reload(false);


}



    return (

        <section className="w-100 px-4 py-5 text-center bg-light">
      
  

      <Container>
     
      <Row>
      <Col className="md-2">
        </Col>
        <Col className="md-2">
        </Col>
      <Col className="md-2 mb-3">
      <Button variant="danger" onClick={sair} size="sm" type="submit" className="px-4">Sair</Button>
      </Col>
        </Row>
        <Row>
      
        <Col className="md-2"> 
        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                Carros a Venda por perto</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{numeroClientesPorPerto}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="fa fa-users fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        </Col>
       
        </Row>
       
       {

getDataEstabelecimentoLocalStorage().latitude != 'Atualizar' && 
getDataEstabelecimentoLocalStorage().longitude != 'Atualizar'

?


(<Row>
  <section key='1298' className="text-center" style={{"padding-top": "3rem", "padding-bottom": "3rem", "height": "100vh"}}>   
      <Container>             
      

      <MapLeaflet     
      arrayMarkers={leads}
      centroMapaPosicaoUser={[getDataEstabelecimentoLocalStorage().latitude,getDataEstabelecimentoLocalStorage().longitude]}
    // posicaoUser={[getDataEstabelecimentoLocalStorage().latitude,getDataEstabelecimentoLocalStorage().longitude]}
      dataUserCenter={getDataEstabelecimentoLocalStorage()}
      mapParaEstabelecimento={true}
      
      />

</Container>

</section>
  </Row>)

  :
  ''

        
        }
	  <Row>
	 
	  <SectionFormCarrosEdicaoCarros 
    tipoUsuario = 'estabelecimento'
    />

	  </Row>
    <hr/>
      
      

  adicionar aqui QRCode
</Container>   
	 
	   
    </section>



    );
}

export default SectionEdicaoProdutosCarros;





