export const cidades = [
   {
      "estado" : "",
      "cidades" : [
         {
            "nome":"Selecione o Estado",
            "codigo_ibge":"Selecione_o_Estado"
         }
      ]
   },
   {
      "estado" : "TO",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ABREULANDIA",
            "codigo_ibge":"1700251"
         },
         {
            "nome":"AGUIARNOPOLIS",
            "codigo_ibge":"1700301"
         },
         {
            "nome":"ALIANCA DO TOCANTINS",
            "codigo_ibge":"1700350"
         },
         {
            "nome":"ALMAS",
            "codigo_ibge":"1700400"
         },
         {
            "nome":"ALVORADA",
            "codigo_ibge":"1700707"
         },
         {
            "nome":"ANANAS",
            "codigo_ibge":"1701002"
         },
         {
            "nome":"ANGICO",
            "codigo_ibge":"1701051"
         },
         {
            "nome":"APARECIDA DO RIO NEGRO",
            "codigo_ibge":"1701101"
         },
         {
            "nome":"ARAGOMINAS",
            "codigo_ibge":"1701309"
         },
         {
            "nome":"ARAGUACEMA",
            "codigo_ibge":"1701903"
         },
         {
            "nome":"ARAGUACU",
            "codigo_ibge":"1702000"
         },
         {
            "nome":"ARAGUAINA",
            "codigo_ibge":"1702109"
         },
         {
            "nome":"ARAGUANA",
            "codigo_ibge":"1702158"
         },
         {
            "nome":"ARAGUATINS",
            "codigo_ibge":"1702208"
         },
         {
            "nome":"ARAPOEMA",
            "codigo_ibge":"1702307"
         },
         {
            "nome":"ARRAIAS",
            "codigo_ibge":"1702406"
         },
         {
            "nome":"AUGUSTINOPOLIS",
            "codigo_ibge":"1702554"
         },
         {
            "nome":"AURORA DO TOCANTINS",
            "codigo_ibge":"1702703"
         },
         {
            "nome":"AXIXA DO TOCANTINS",
            "codigo_ibge":"1702901"
         },
         {
            "nome":"BABACULANDIA",
            "codigo_ibge":"1703008"
         },
         {
            "nome":"BANDEIRANTES DO TOCANTINS",
            "codigo_ibge":"1703057"
         },
         {
            "nome":"BARRA DO OURO",
            "codigo_ibge":"1703073"
         },
         {
            "nome":"BARROLANDIA",
            "codigo_ibge":"1703107"
         },
         {
            "nome":"BERNARDO SAYAO",
            "codigo_ibge":"1703206"
         },
         {
            "nome":"BOM JESUS DO TOCANTINS",
            "codigo_ibge":"1703305"
         },
         {
            "nome":"BRASILANDIA DO TOCANTINS",
            "codigo_ibge":"1703602"
         },
         {
            "nome":"BREJINHO DE NAZARE",
            "codigo_ibge":"1703701"
         },
         {
            "nome":"BURITI DO TOCANTINS",
            "codigo_ibge":"1703800"
         },
         {
            "nome":"CACHOEIRINHA",
            "codigo_ibge":"1703826"
         },
         {
            "nome":"CAMPOS LINDOS",
            "codigo_ibge":"1703842"
         },
         {
            "nome":"CARIRI DO TOCANTINS",
            "codigo_ibge":"1703867"
         },
         {
            "nome":"CARMOLANDIA",
            "codigo_ibge":"1703883"
         },
         {
            "nome":"CARRASCO BONITO",
            "codigo_ibge":"1703891"
         },
         {
            "nome":"CASEARA",
            "codigo_ibge":"1703909"
         },
         {
            "nome":"CENTENARIO",
            "codigo_ibge":"1704105"
         },
         {
            "nome":"CHAPADA DE AREIA",
            "codigo_ibge":"1704600"
         },
         {
            "nome":"CHAPADA DA NATIVIDADE",
            "codigo_ibge":"1705102"
         },
         {
            "nome":"COLINAS DO TOCANTINS",
            "codigo_ibge":"1705508"
         },
         {
            "nome":"COMBINADO",
            "codigo_ibge":"1705557"
         },
         {
            "nome":"CONCEICAO DO TOCANTINS",
            "codigo_ibge":"1705607"
         },
         {
            "nome":"COUTO MAGALHAES",
            "codigo_ibge":"1706001"
         },
         {
            "nome":"CRISTALANDIA",
            "codigo_ibge":"1706100"
         },
         {
            "nome":"CRIXAS DO TOCANTINS",
            "codigo_ibge":"1706258"
         },
         {
            "nome":"DARCINOPOLIS",
            "codigo_ibge":"1706506"
         },
         {
            "nome":"DIANOPOLIS",
            "codigo_ibge":"1707009"
         },
         {
            "nome":"DIVINOPOLIS DO TOCANTINS",
            "codigo_ibge":"1707108"
         },
         {
            "nome":"DOIS IRMAOS DO TOCANTINS",
            "codigo_ibge":"1707207"
         },
         {
            "nome":"DUERE",
            "codigo_ibge":"1707306"
         },
         {
            "nome":"ESPERANTINA",
            "codigo_ibge":"1707405"
         },
         {
            "nome":"FATIMA",
            "codigo_ibge":"1707553"
         },
         {
            "nome":"FIGUEIROPOLIS",
            "codigo_ibge":"1707652"
         },
         {
            "nome":"FILADELFIA",
            "codigo_ibge":"1707702"
         },
         {
            "nome":"FORMOSO DO ARAGUAIA",
            "codigo_ibge":"1708205"
         },
         {
            "nome":"FORTALEZA DO TABOCAO",
            "codigo_ibge":"1708254"
         },
         {
            "nome":"GOIANORTE",
            "codigo_ibge":"1708304"
         },
         {
            "nome":"GOIATINS",
            "codigo_ibge":"1709005"
         },
         {
            "nome":"GUARAI",
            "codigo_ibge":"1709302"
         },
         {
            "nome":"GURUPI",
            "codigo_ibge":"1709500"
         },
         {
            "nome":"IPUEIRAS",
            "codigo_ibge":"1709807"
         },
         {
            "nome":"ITACAJA",
            "codigo_ibge":"1710508"
         },
         {
            "nome":"ITAGUATINS",
            "codigo_ibge":"1710706"
         },
         {
            "nome":"ITAPIRATINS",
            "codigo_ibge":"1710904"
         },
         {
            "nome":"ITAPORA DO TOCANTINS",
            "codigo_ibge":"1711100"
         },
         {
            "nome":"JAU DO TOCANTINS",
            "codigo_ibge":"1711506"
         },
         {
            "nome":"JUARINA",
            "codigo_ibge":"1711803"
         },
         {
            "nome":"LAGOA DA CONFUSAO",
            "codigo_ibge":"1711902"
         },
         {
            "nome":"LAGOA DO TOCANTINS",
            "codigo_ibge":"1711951"
         },
         {
            "nome":"LAJEADO",
            "codigo_ibge":"1712009"
         },
         {
            "nome":"LAVANDEIRA",
            "codigo_ibge":"1712157"
         },
         {
            "nome":"LIZARDA",
            "codigo_ibge":"1712405"
         },
         {
            "nome":"LUZINOPOLIS",
            "codigo_ibge":"1712454"
         },
         {
            "nome":"MARIANOPOLIS DO TOCANTINS",
            "codigo_ibge":"1712504"
         },
         {
            "nome":"MATEIROS",
            "codigo_ibge":"1712702"
         },
         {
            "nome":"MAURILANDIA DO TOCANTINS",
            "codigo_ibge":"1712801"
         },
         {
            "nome":"MIRACEMA DO TOCANTINS",
            "codigo_ibge":"1713205"
         },
         {
            "nome":"MIRANORTE",
            "codigo_ibge":"1713304"
         },
         {
            "nome":"MONTE DO CARMO",
            "codigo_ibge":"1713601"
         },
         {
            "nome":"MONTE SANTO DO TOCANTINS",
            "codigo_ibge":"1713700"
         },
         {
            "nome":"PALMEIRAS DO TOCANTINS",
            "codigo_ibge":"1713809"
         },
         {
            "nome":"MURICILANDIA",
            "codigo_ibge":"1713957"
         },
         {
            "nome":"NATIVIDADE",
            "codigo_ibge":"1714203"
         },
         {
            "nome":"NAZARE",
            "codigo_ibge":"1714302"
         },
         {
            "nome":"NOVA OLINDA",
            "codigo_ibge":"1714880"
         },
         {
            "nome":"NOVA ROSALANDIA",
            "codigo_ibge":"1715002"
         },
         {
            "nome":"NOVO ACORDO",
            "codigo_ibge":"1715101"
         },
         {
            "nome":"NOVO ALEGRE",
            "codigo_ibge":"1715150"
         },
         {
            "nome":"NOVO JARDIM",
            "codigo_ibge":"1715259"
         },
         {
            "nome":"OLIVEIRA DE FATIMA",
            "codigo_ibge":"1715507"
         },
         {
            "nome":"PALMEIRANTE",
            "codigo_ibge":"1715705"
         },
         {
            "nome":"PALMEIROPOLIS",
            "codigo_ibge":"1715754"
         },
         {
            "nome":"PARAISO DO TOCANTINS",
            "codigo_ibge":"1716109"
         },
         {
            "nome":"PARANA",
            "codigo_ibge":"1716208"
         },
         {
            "nome":"PAU D ARCO",
            "codigo_ibge":"1716307"
         },
         {
            "nome":"PEDRO AFONSO",
            "codigo_ibge":"1716505"
         },
         {
            "nome":"PEIXE",
            "codigo_ibge":"1716604"
         },
         {
            "nome":"PEQUIZEIRO",
            "codigo_ibge":"1716653"
         },
         {
            "nome":"COLMEIA",
            "codigo_ibge":"1716703"
         },
         {
            "nome":"PINDORAMA DO TOCANTINS",
            "codigo_ibge":"1717008"
         },
         {
            "nome":"PIRAQUE",
            "codigo_ibge":"1717206"
         },
         {
            "nome":"PIUM",
            "codigo_ibge":"1717503"
         },
         {
            "nome":"PONTE ALTA DO BOM JESUS",
            "codigo_ibge":"1717800"
         },
         {
            "nome":"PONTE ALTA DO TOCANTINS",
            "codigo_ibge":"1717909"
         },
         {
            "nome":"PORTO ALEGRE DO TOCANTINS",
            "codigo_ibge":"1718006"
         },
         {
            "nome":"PORTO NACIONAL",
            "codigo_ibge":"1718204"
         },
         {
            "nome":"PRAIA NORTE",
            "codigo_ibge":"1718303"
         },
         {
            "nome":"PRESIDENTE KENNEDY",
            "codigo_ibge":"1718402"
         },
         {
            "nome":"PUGMIL",
            "codigo_ibge":"1718451"
         },
         {
            "nome":"RECURSOLANDIA",
            "codigo_ibge":"1718501"
         },
         {
            "nome":"RIACHINHO",
            "codigo_ibge":"1718550"
         },
         {
            "nome":"RIO DA CONCEICAO",
            "codigo_ibge":"1718659"
         },
         {
            "nome":"RIO DOS BOIS",
            "codigo_ibge":"1718709"
         },
         {
            "nome":"RIO SONO",
            "codigo_ibge":"1718758"
         },
         {
            "nome":"SAMPAIO",
            "codigo_ibge":"1718808"
         },
         {
            "nome":"SANDOLANDIA",
            "codigo_ibge":"1718840"
         },
         {
            "nome":"SANTA FE DO ARAGUAIA",
            "codigo_ibge":"1718865"
         },
         {
            "nome":"SANTA MARIA DO TOCANTINS",
            "codigo_ibge":"1718881"
         },
         {
            "nome":"SANTA RITA DO TOCANTINS",
            "codigo_ibge":"1718899"
         },
         {
            "nome":"SANTA ROSA DO TOCANTINS",
            "codigo_ibge":"1718907"
         },
         {
            "nome":"SANTA TEREZA DO TOCANTINS",
            "codigo_ibge":"1719004"
         },
         {
            "nome":"SANTA TEREZINHA DO TOCANTINS",
            "codigo_ibge":"1720002"
         },
         {
            "nome":"SAO BENTO DO TOCANTINS",
            "codigo_ibge":"1720101"
         },
         {
            "nome":"SAO FELIX DO TOCANTINS",
            "codigo_ibge":"1720150"
         },
         {
            "nome":"SAO MIGUEL DO TOCANTINS",
            "codigo_ibge":"1720200"
         },
         {
            "nome":"SAO SALVADOR DO TOCANTINS",
            "codigo_ibge":"1720259"
         },
         {
            "nome":"SAO SEBASTIAO DO TOCANTINS",
            "codigo_ibge":"1720309"
         },
         {
            "nome":"SAO VALERIO",
            "codigo_ibge":"1720499"
         },
         {
            "nome":"SILVANOPOLIS",
            "codigo_ibge":"1720655"
         },
         {
            "nome":"SITIO NOVO DO TOCANTINS",
            "codigo_ibge":"1720804"
         },
         {
            "nome":"SUCUPIRA",
            "codigo_ibge":"1720853"
         },
         {
            "nome":"TAGUATINGA",
            "codigo_ibge":"1720903"
         },
         {
            "nome":"TAIPAS DO TOCANTINS",
            "codigo_ibge":"1720937"
         },
         {
            "nome":"TALISMA",
            "codigo_ibge":"1720978"
         },
         {
            "nome":"PALMAS",
            "codigo_ibge":"1721000"
         },
         {
            "nome":"TOCANTINIA",
            "codigo_ibge":"1721109"
         },
         {
            "nome":"TOCANTINOPOLIS",
            "codigo_ibge":"1721208"
         },
         {
            "nome":"TUPIRAMA",
            "codigo_ibge":"1721257"
         },
         {
            "nome":"TUPIRATINS",
            "codigo_ibge":"1721307"
         },
         {
            "nome":"WANDERLANDIA",
            "codigo_ibge":"1722081"
         },
         {
            "nome":"XAMBIOA",
            "codigo_ibge":"1722107"
         }
      ]
   },
   {
      "estado" : "SE",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"AMPARO DE SAO FRANCISCO",
            "codigo_ibge":"2800100"
         },
         {
            "nome":"AQUIDABA",
            "codigo_ibge":"2800209"
         },
         {
            "nome":"ARACAJU",
            "codigo_ibge":"2800308"
         },
         {
            "nome":"ARAUA",
            "codigo_ibge":"2800407"
         },
         {
            "nome":"AREIA BRANCA",
            "codigo_ibge":"2800506"
         },
         {
            "nome":"BARRA DOS COQUEIROS",
            "codigo_ibge":"2800605"
         },
         {
            "nome":"BOQUIM",
            "codigo_ibge":"2800670"
         },
         {
            "nome":"BREJO GRANDE",
            "codigo_ibge":"2800704"
         },
         {
            "nome":"CAMPO DO BRITO",
            "codigo_ibge":"2801009"
         },
         {
            "nome":"CANHOBA",
            "codigo_ibge":"2801108"
         },
         {
            "nome":"CANINDE DE SAO FRANCISCO",
            "codigo_ibge":"2801207"
         },
         {
            "nome":"CAPELA",
            "codigo_ibge":"2801306"
         },
         {
            "nome":"CARIRA",
            "codigo_ibge":"2801405"
         },
         {
            "nome":"CARMOPOLIS",
            "codigo_ibge":"2801504"
         },
         {
            "nome":"CEDRO DE SAO JOAO",
            "codigo_ibge":"2801603"
         },
         {
            "nome":"CRISTINAPOLIS",
            "codigo_ibge":"2801702"
         },
         {
            "nome":"CUMBE",
            "codigo_ibge":"2801900"
         },
         {
            "nome":"DIVINA PASTORA",
            "codigo_ibge":"2802007"
         },
         {
            "nome":"ESTANCIA",
            "codigo_ibge":"2802106"
         },
         {
            "nome":"FEIRA NOVA",
            "codigo_ibge":"2802205"
         },
         {
            "nome":"FREI PAULO",
            "codigo_ibge":"2802304"
         },
         {
            "nome":"GARARU",
            "codigo_ibge":"2802403"
         },
         {
            "nome":"GENERAL MAYNARD",
            "codigo_ibge":"2802502"
         },
         {
            "nome":"GRACHO CARDOSO",
            "codigo_ibge":"2802601"
         },
         {
            "nome":"ILHA DAS FLORES",
            "codigo_ibge":"2802700"
         },
         {
            "nome":"INDIAROBA",
            "codigo_ibge":"2802809"
         },
         {
            "nome":"ITABAIANA",
            "codigo_ibge":"2802908"
         },
         {
            "nome":"ITABAIANINHA",
            "codigo_ibge":"2803005"
         },
         {
            "nome":"ITABI",
            "codigo_ibge":"2803104"
         },
         {
            "nome":"ITAPORANGA D AJUDA",
            "codigo_ibge":"2803203"
         },
         {
            "nome":"JAPARATUBA",
            "codigo_ibge":"2803302"
         },
         {
            "nome":"JAPOATA",
            "codigo_ibge":"2803401"
         },
         {
            "nome":"LAGARTO",
            "codigo_ibge":"2803500"
         },
         {
            "nome":"LARANJEIRAS",
            "codigo_ibge":"2803609"
         },
         {
            "nome":"MACAMBIRA",
            "codigo_ibge":"2803708"
         },
         {
            "nome":"MALHADA DOS BOIS",
            "codigo_ibge":"2803807"
         },
         {
            "nome":"MALHADOR",
            "codigo_ibge":"2803906"
         },
         {
            "nome":"MARUIM",
            "codigo_ibge":"2804003"
         },
         {
            "nome":"MOITA BONITA",
            "codigo_ibge":"2804102"
         },
         {
            "nome":"MONTE ALEGRE DE SERGIPE",
            "codigo_ibge":"2804201"
         },
         {
            "nome":"MURIBECA",
            "codigo_ibge":"2804300"
         },
         {
            "nome":"NEOPOLIS",
            "codigo_ibge":"2804409"
         },
         {
            "nome":"NOSSA SENHORA APARECIDA",
            "codigo_ibge":"2804458"
         },
         {
            "nome":"NOSSA SENHORA DA GLORIA",
            "codigo_ibge":"2804508"
         },
         {
            "nome":"NOSSA SENHORA DAS DORES",
            "codigo_ibge":"2804607"
         },
         {
            "nome":"NOSSA SENHORA DE LOURDES",
            "codigo_ibge":"2804706"
         },
         {
            "nome":"NOSSA SENHORA DO SOCORRO",
            "codigo_ibge":"2804805"
         },
         {
            "nome":"PACATUBA",
            "codigo_ibge":"2804904"
         },
         {
            "nome":"PEDRA MOLE",
            "codigo_ibge":"2805000"
         },
         {
            "nome":"PEDRINHAS",
            "codigo_ibge":"2805109"
         },
         {
            "nome":"PINHAO",
            "codigo_ibge":"2805208"
         },
         {
            "nome":"PIRAMBU",
            "codigo_ibge":"2805307"
         },
         {
            "nome":"POCO REDONDO",
            "codigo_ibge":"2805406"
         },
         {
            "nome":"POCO VERDE",
            "codigo_ibge":"2805505"
         },
         {
            "nome":"PORTO DA FOLHA",
            "codigo_ibge":"2805604"
         },
         {
            "nome":"PROPRIA",
            "codigo_ibge":"2805703"
         },
         {
            "nome":"RIACHAO DO DANTAS",
            "codigo_ibge":"2805802"
         },
         {
            "nome":"RIACHUELO",
            "codigo_ibge":"2805901"
         },
         {
            "nome":"RIBEIROPOLIS",
            "codigo_ibge":"2806008"
         },
         {
            "nome":"ROSARIO DO CATETE",
            "codigo_ibge":"2806107"
         },
         {
            "nome":"SALGADO",
            "codigo_ibge":"2806206"
         },
         {
            "nome":"SANTA LUZIA DO ITANHY",
            "codigo_ibge":"2806305"
         },
         {
            "nome":"SANTANA DO SAO FRANCISCO",
            "codigo_ibge":"2806404"
         },
         {
            "nome":"SANTA ROSA DE LIMA",
            "codigo_ibge":"2806503"
         },
         {
            "nome":"SANTO AMARO DAS BROTAS",
            "codigo_ibge":"2806602"
         },
         {
            "nome":"SAO CRISTOVAO",
            "codigo_ibge":"2806701"
         },
         {
            "nome":"SAO DOMINGOS",
            "codigo_ibge":"2806800"
         },
         {
            "nome":"SAO FRANCISCO",
            "codigo_ibge":"2806909"
         },
         {
            "nome":"SAO MIGUEL DO ALEIXO",
            "codigo_ibge":"2807006"
         },
         {
            "nome":"SIMAO DIAS",
            "codigo_ibge":"2807105"
         },
         {
            "nome":"SIRIRI",
            "codigo_ibge":"2807204"
         },
         {
            "nome":"TELHA",
            "codigo_ibge":"2807303"
         },
         {
            "nome":"TOBIAS BARRETO",
            "codigo_ibge":"2807402"
         },
         {
            "nome":"TOMAR DO GERU",
            "codigo_ibge":"2807501"
         },
         {
            "nome":"UMBAUBA",
            "codigo_ibge":"2807600"
         }
      ]
   },
   {
      "estado" : "SC",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ABDON BATISTA",
            "codigo_ibge":"4200051"
         },
         {
            "nome":"ABELARDO LUZ",
            "codigo_ibge":"4200101"
         },
         {
            "nome":"AGROLANDIA",
            "codigo_ibge":"4200200"
         },
         {
            "nome":"AGRONOMICA",
            "codigo_ibge":"4200309"
         },
         {
            "nome":"AGUA DOCE",
            "codigo_ibge":"4200408"
         },
         {
            "nome":"AGUAS DE CHAPECO",
            "codigo_ibge":"4200507"
         },
         {
            "nome":"AGUAS FRIAS",
            "codigo_ibge":"4200556"
         },
         {
            "nome":"AGUAS MORNAS",
            "codigo_ibge":"4200606"
         },
         {
            "nome":"ALFREDO WAGNER",
            "codigo_ibge":"4200705"
         },
         {
            "nome":"ALTO BELA VISTA",
            "codigo_ibge":"4200754"
         },
         {
            "nome":"ANCHIETA",
            "codigo_ibge":"4200804"
         },
         {
            "nome":"ANGELINA",
            "codigo_ibge":"4200903"
         },
         {
            "nome":"ANITA GARIBALDI",
            "codigo_ibge":"4201000"
         },
         {
            "nome":"ANITAPOLIS",
            "codigo_ibge":"4201109"
         },
         {
            "nome":"ANTONIO CARLOS",
            "codigo_ibge":"4201208"
         },
         {
            "nome":"APIUNA",
            "codigo_ibge":"4201257"
         },
         {
            "nome":"ARABUTA",
            "codigo_ibge":"4201273"
         },
         {
            "nome":"ARAQUARI",
            "codigo_ibge":"4201307"
         },
         {
            "nome":"ARARANGUA",
            "codigo_ibge":"4201406"
         },
         {
            "nome":"ARMAZEM",
            "codigo_ibge":"4201505"
         },
         {
            "nome":"ARROIO TRINTA",
            "codigo_ibge":"4201604"
         },
         {
            "nome":"ARVOREDO",
            "codigo_ibge":"4201653"
         },
         {
            "nome":"ASCURRA",
            "codigo_ibge":"4201703"
         },
         {
            "nome":"ATALANTA",
            "codigo_ibge":"4201802"
         },
         {
            "nome":"AURORA",
            "codigo_ibge":"4201901"
         },
         {
            "nome":"BALNEARIO ARROIO DO SILVA",
            "codigo_ibge":"4201950"
         },
         {
            "nome":"BALNEARIO CAMBORIU",
            "codigo_ibge":"4202008"
         },
         {
            "nome":"BALNEARIO BARRA DO SUL",
            "codigo_ibge":"4202057"
         },
         {
            "nome":"BALNEARIO GAIVOTA",
            "codigo_ibge":"4202073"
         },
         {
            "nome":"BANDEIRANTE",
            "codigo_ibge":"4202081"
         },
         {
            "nome":"BARRA BONITA",
            "codigo_ibge":"4202099"
         },
         {
            "nome":"BARRA VELHA",
            "codigo_ibge":"4202107"
         },
         {
            "nome":"BELA VISTA DO TOLDO",
            "codigo_ibge":"4202131"
         },
         {
            "nome":"BELMONTE",
            "codigo_ibge":"4202156"
         },
         {
            "nome":"BENEDITO NOVO",
            "codigo_ibge":"4202206"
         },
         {
            "nome":"BIGUACU",
            "codigo_ibge":"4202305"
         },
         {
            "nome":"BLUMENAU",
            "codigo_ibge":"4202404"
         },
         {
            "nome":"BOCAINA DO SUL",
            "codigo_ibge":"4202438"
         },
         {
            "nome":"BOMBINHAS",
            "codigo_ibge":"4202453"
         },
         {
            "nome":"BOM JARDIM DA SERRA",
            "codigo_ibge":"4202503"
         },
         {
            "nome":"BOM JESUS",
            "codigo_ibge":"4202537"
         },
         {
            "nome":"BOM JESUS DO OESTE",
            "codigo_ibge":"4202578"
         },
         {
            "nome":"BOM RETIRO",
            "codigo_ibge":"4202602"
         },
         {
            "nome":"BOTUVERA",
            "codigo_ibge":"4202701"
         },
         {
            "nome":"BRACO DO NORTE",
            "codigo_ibge":"4202800"
         },
         {
            "nome":"BRACO DO TROMBUDO",
            "codigo_ibge":"4202859"
         },
         {
            "nome":"BRUNOPOLIS",
            "codigo_ibge":"4202875"
         },
         {
            "nome":"BRUSQUE",
            "codigo_ibge":"4202909"
         },
         {
            "nome":"CACADOR",
            "codigo_ibge":"4203006"
         },
         {
            "nome":"CAIBI",
            "codigo_ibge":"4203105"
         },
         {
            "nome":"CALMON",
            "codigo_ibge":"4203154"
         },
         {
            "nome":"CAMBORIU",
            "codigo_ibge":"4203204"
         },
         {
            "nome":"CAPAO ALTO",
            "codigo_ibge":"4203253"
         },
         {
            "nome":"CAMPO ALEGRE",
            "codigo_ibge":"4203303"
         },
         {
            "nome":"CAMPO BELO DO SUL",
            "codigo_ibge":"4203402"
         },
         {
            "nome":"CAMPO ERE",
            "codigo_ibge":"4203501"
         },
         {
            "nome":"CAMPOS NOVOS",
            "codigo_ibge":"4203600"
         },
         {
            "nome":"CANELINHA",
            "codigo_ibge":"4203709"
         },
         {
            "nome":"CANOINHAS",
            "codigo_ibge":"4203808"
         },
         {
            "nome":"CAPINZAL",
            "codigo_ibge":"4203907"
         },
         {
            "nome":"CAPIVARI DE BAIXO",
            "codigo_ibge":"4203956"
         },
         {
            "nome":"CATANDUVAS",
            "codigo_ibge":"4204004"
         },
         {
            "nome":"CAXAMBU DO SUL",
            "codigo_ibge":"4204103"
         },
         {
            "nome":"CELSO RAMOS",
            "codigo_ibge":"4204152"
         },
         {
            "nome":"CERRO NEGRO",
            "codigo_ibge":"4204178"
         },
         {
            "nome":"CHAPADAO DO LAGEADO",
            "codigo_ibge":"4204194"
         },
         {
            "nome":"CHAPECO",
            "codigo_ibge":"4204202"
         },
         {
            "nome":"COCAL DO SUL",
            "codigo_ibge":"4204251"
         },
         {
            "nome":"CONCORDIA",
            "codigo_ibge":"4204301"
         },
         {
            "nome":"CORDILHEIRA ALTA",
            "codigo_ibge":"4204350"
         },
         {
            "nome":"CORONEL FREITAS",
            "codigo_ibge":"4204400"
         },
         {
            "nome":"CORONEL MARTINS",
            "codigo_ibge":"4204459"
         },
         {
            "nome":"CORUPA",
            "codigo_ibge":"4204509"
         },
         {
            "nome":"CORREIA PINTO",
            "codigo_ibge":"4204558"
         },
         {
            "nome":"CRICIUMA",
            "codigo_ibge":"4204608"
         },
         {
            "nome":"CUNHA PORA",
            "codigo_ibge":"4204707"
         },
         {
            "nome":"CUNHATAI",
            "codigo_ibge":"4204756"
         },
         {
            "nome":"CURITIBANOS",
            "codigo_ibge":"4204806"
         },
         {
            "nome":"DESCANSO",
            "codigo_ibge":"4204905"
         },
         {
            "nome":"DIONISIO CERQUEIRA",
            "codigo_ibge":"4205001"
         },
         {
            "nome":"DONA EMMA",
            "codigo_ibge":"4205100"
         },
         {
            "nome":"DOUTOR PEDRINHO",
            "codigo_ibge":"4205159"
         },
         {
            "nome":"ENTRE RIOS",
            "codigo_ibge":"4205175"
         },
         {
            "nome":"ERMO",
            "codigo_ibge":"4205191"
         },
         {
            "nome":"ERVAL VELHO",
            "codigo_ibge":"4205209"
         },
         {
            "nome":"FAXINAL DOS GUEDES",
            "codigo_ibge":"4205308"
         },
         {
            "nome":"FLOR DO SERTAO",
            "codigo_ibge":"4205357"
         },
         {
            "nome":"FLORIANOPOLIS",
            "codigo_ibge":"4205407"
         },
         {
            "nome":"FORMOSA DO SUL",
            "codigo_ibge":"4205431"
         },
         {
            "nome":"FORQUILHINHA",
            "codigo_ibge":"4205456"
         },
         {
            "nome":"FRAIBURGO",
            "codigo_ibge":"4205506"
         },
         {
            "nome":"FREI ROGERIO",
            "codigo_ibge":"4205555"
         },
         {
            "nome":"GALVAO",
            "codigo_ibge":"4205605"
         },
         {
            "nome":"GAROPABA",
            "codigo_ibge":"4205704"
         },
         {
            "nome":"GARUVA",
            "codigo_ibge":"4205803"
         },
         {
            "nome":"GASPAR",
            "codigo_ibge":"4205902"
         },
         {
            "nome":"GOVERNADOR CELSO RAMOS",
            "codigo_ibge":"4206009"
         },
         {
            "nome":"GRAO PARA",
            "codigo_ibge":"4206108"
         },
         {
            "nome":"GRAVATAL",
            "codigo_ibge":"4206207"
         },
         {
            "nome":"GUABIRUBA",
            "codigo_ibge":"4206306"
         },
         {
            "nome":"GUARACIABA",
            "codigo_ibge":"4206405"
         },
         {
            "nome":"GUARAMIRIM",
            "codigo_ibge":"4206504"
         },
         {
            "nome":"GUARUJA DO SUL",
            "codigo_ibge":"4206603"
         },
         {
            "nome":"GUATAMBU",
            "codigo_ibge":"4206652"
         },
         {
            "nome":"HERVAL D OESTE",
            "codigo_ibge":"4206702"
         },
         {
            "nome":"IBIAM",
            "codigo_ibge":"4206751"
         },
         {
            "nome":"IBICARE",
            "codigo_ibge":"4206801"
         },
         {
            "nome":"IBIRAMA",
            "codigo_ibge":"4206900"
         },
         {
            "nome":"ICARA",
            "codigo_ibge":"4207007"
         },
         {
            "nome":"ILHOTA",
            "codigo_ibge":"4207106"
         },
         {
            "nome":"IMARUI",
            "codigo_ibge":"4207205"
         },
         {
            "nome":"IMBITUBA",
            "codigo_ibge":"4207304"
         },
         {
            "nome":"IMBUIA",
            "codigo_ibge":"4207403"
         },
         {
            "nome":"INDAIAL",
            "codigo_ibge":"4207502"
         },
         {
            "nome":"IOMERE",
            "codigo_ibge":"4207577"
         },
         {
            "nome":"IPIRA",
            "codigo_ibge":"4207601"
         },
         {
            "nome":"IPORA DO OESTE",
            "codigo_ibge":"4207650"
         },
         {
            "nome":"IPUACU",
            "codigo_ibge":"4207684"
         },
         {
            "nome":"IPUMIRIM",
            "codigo_ibge":"4207700"
         },
         {
            "nome":"IRACEMINHA",
            "codigo_ibge":"4207759"
         },
         {
            "nome":"IRANI",
            "codigo_ibge":"4207809"
         },
         {
            "nome":"IRATI",
            "codigo_ibge":"4207858"
         },
         {
            "nome":"IRINEOPOLIS",
            "codigo_ibge":"4207908"
         },
         {
            "nome":"ITA",
            "codigo_ibge":"4208005"
         },
         {
            "nome":"ITAIOPOLIS",
            "codigo_ibge":"4208104"
         },
         {
            "nome":"ITAJAI",
            "codigo_ibge":"4208203"
         },
         {
            "nome":"ITAPEMA",
            "codigo_ibge":"4208302"
         },
         {
            "nome":"ITAPIRANGA",
            "codigo_ibge":"4208401"
         },
         {
            "nome":"ITAPOA",
            "codigo_ibge":"4208450"
         },
         {
            "nome":"ITUPORANGA",
            "codigo_ibge":"4208500"
         },
         {
            "nome":"JABORA",
            "codigo_ibge":"4208609"
         },
         {
            "nome":"JACINTO MACHADO",
            "codigo_ibge":"4208708"
         },
         {
            "nome":"JAGUARUNA",
            "codigo_ibge":"4208807"
         },
         {
            "nome":"JARAGUA DO SUL",
            "codigo_ibge":"4208906"
         },
         {
            "nome":"JARDINOPOLIS",
            "codigo_ibge":"4208955"
         },
         {
            "nome":"JOACABA",
            "codigo_ibge":"4209003"
         },
         {
            "nome":"JOINVILLE",
            "codigo_ibge":"4209102"
         },
         {
            "nome":"JOSE BOITEUX",
            "codigo_ibge":"4209151"
         },
         {
            "nome":"JUPIA",
            "codigo_ibge":"4209177"
         },
         {
            "nome":"LACERDOPOLIS",
            "codigo_ibge":"4209201"
         },
         {
            "nome":"LAGES",
            "codigo_ibge":"4209300"
         },
         {
            "nome":"LAGUNA",
            "codigo_ibge":"4209409"
         },
         {
            "nome":"LAJEADO GRANDE",
            "codigo_ibge":"4209458"
         },
         {
            "nome":"LAURENTINO",
            "codigo_ibge":"4209508"
         },
         {
            "nome":"LAURO MULLER",
            "codigo_ibge":"4209607"
         },
         {
            "nome":"LEBON REGIS",
            "codigo_ibge":"4209706"
         },
         {
            "nome":"LEOBERTO LEAL",
            "codigo_ibge":"4209805"
         },
         {
            "nome":"LINDOIA DO SUL",
            "codigo_ibge":"4209854"
         },
         {
            "nome":"LONTRAS",
            "codigo_ibge":"4209904"
         },
         {
            "nome":"LUIZ ALVES",
            "codigo_ibge":"4210001"
         },
         {
            "nome":"LUZERNA",
            "codigo_ibge":"4210035"
         },
         {
            "nome":"MACIEIRA",
            "codigo_ibge":"4210050"
         },
         {
            "nome":"MAFRA",
            "codigo_ibge":"4210100"
         },
         {
            "nome":"MAJOR GERCINO",
            "codigo_ibge":"4210209"
         },
         {
            "nome":"MAJOR VIEIRA",
            "codigo_ibge":"4210308"
         },
         {
            "nome":"MARACAJA",
            "codigo_ibge":"4210407"
         },
         {
            "nome":"MARAVILHA",
            "codigo_ibge":"4210506"
         },
         {
            "nome":"MAREMA",
            "codigo_ibge":"4210555"
         },
         {
            "nome":"MASSARANDUBA",
            "codigo_ibge":"4210605"
         },
         {
            "nome":"MATOS COSTA",
            "codigo_ibge":"4210704"
         },
         {
            "nome":"MELEIRO",
            "codigo_ibge":"4210803"
         },
         {
            "nome":"MIRIM DOCE",
            "codigo_ibge":"4210852"
         },
         {
            "nome":"MODELO",
            "codigo_ibge":"4210902"
         },
         {
            "nome":"MONDAI",
            "codigo_ibge":"4211009"
         },
         {
            "nome":"MONTE CARLO",
            "codigo_ibge":"4211058"
         },
         {
            "nome":"MONTE CASTELO",
            "codigo_ibge":"4211108"
         },
         {
            "nome":"MORRO DA FUMACA",
            "codigo_ibge":"4211207"
         },
         {
            "nome":"MORRO GRANDE",
            "codigo_ibge":"4211256"
         },
         {
            "nome":"NAVEGANTES",
            "codigo_ibge":"4211306"
         },
         {
            "nome":"NOVA ERECHIM",
            "codigo_ibge":"4211405"
         },
         {
            "nome":"NOVA ITABERABA",
            "codigo_ibge":"4211454"
         },
         {
            "nome":"NOVA TRENTO",
            "codigo_ibge":"4211504"
         },
         {
            "nome":"NOVA VENEZA",
            "codigo_ibge":"4211603"
         },
         {
            "nome":"NOVO HORIZONTE",
            "codigo_ibge":"4211652"
         },
         {
            "nome":"ORLEANS",
            "codigo_ibge":"4211702"
         },
         {
            "nome":"OTACILIO COSTA",
            "codigo_ibge":"4211751"
         },
         {
            "nome":"OURO",
            "codigo_ibge":"4211801"
         },
         {
            "nome":"OURO VERDE",
            "codigo_ibge":"4211850"
         },
         {
            "nome":"PAIAL",
            "codigo_ibge":"4211876"
         },
         {
            "nome":"PAINEL",
            "codigo_ibge":"4211892"
         },
         {
            "nome":"PALHOCA",
            "codigo_ibge":"4211900"
         },
         {
            "nome":"PALMA SOLA",
            "codigo_ibge":"4212007"
         },
         {
            "nome":"PALMEIRA",
            "codigo_ibge":"4212056"
         },
         {
            "nome":"PALMITOS",
            "codigo_ibge":"4212106"
         },
         {
            "nome":"PAPANDUVA",
            "codigo_ibge":"4212205"
         },
         {
            "nome":"PARAISO",
            "codigo_ibge":"4212239"
         },
         {
            "nome":"PASSO DE TORRES",
            "codigo_ibge":"4212254"
         },
         {
            "nome":"PASSOS MAIA",
            "codigo_ibge":"4212270"
         },
         {
            "nome":"PAULO LOPES",
            "codigo_ibge":"4212304"
         },
         {
            "nome":"PEDRAS GRANDES",
            "codigo_ibge":"4212403"
         },
         {
            "nome":"PENHA",
            "codigo_ibge":"4212502"
         },
         {
            "nome":"PERITIBA",
            "codigo_ibge":"4212601"
         },
         {
            "nome":"PESCARIA BRAVA",
            "codigo_ibge":"4212650"
         },
         {
            "nome":"PETROLANDIA",
            "codigo_ibge":"4212700"
         },
         {
            "nome":"BALNEARIO PICARRAS",
            "codigo_ibge":"4212809"
         },
         {
            "nome":"PINHALZINHO",
            "codigo_ibge":"4212908"
         },
         {
            "nome":"PINHEIRO PRETO",
            "codigo_ibge":"4213005"
         },
         {
            "nome":"PIRATUBA",
            "codigo_ibge":"4213104"
         },
         {
            "nome":"PLANALTO ALEGRE",
            "codigo_ibge":"4213153"
         },
         {
            "nome":"POMERODE",
            "codigo_ibge":"4213203"
         },
         {
            "nome":"PONTE ALTA",
            "codigo_ibge":"4213302"
         },
         {
            "nome":"PONTE ALTA DO NORTE",
            "codigo_ibge":"4213351"
         },
         {
            "nome":"PONTE SERRADA",
            "codigo_ibge":"4213401"
         },
         {
            "nome":"PORTO BELO",
            "codigo_ibge":"4213500"
         },
         {
            "nome":"PORTO UNIAO",
            "codigo_ibge":"4213609"
         },
         {
            "nome":"POUSO REDONDO",
            "codigo_ibge":"4213708"
         },
         {
            "nome":"PRAIA GRANDE",
            "codigo_ibge":"4213807"
         },
         {
            "nome":"PRESIDENTE CASTELLO BRANCO",
            "codigo_ibge":"4213906"
         },
         {
            "nome":"PRESIDENTE GETULIO",
            "codigo_ibge":"4214003"
         },
         {
            "nome":"PRESIDENTE NEREU",
            "codigo_ibge":"4214102"
         },
         {
            "nome":"PRINCESA",
            "codigo_ibge":"4214151"
         },
         {
            "nome":"QUILOMBO",
            "codigo_ibge":"4214201"
         },
         {
            "nome":"RANCHO QUEIMADO",
            "codigo_ibge":"4214300"
         },
         {
            "nome":"RIO DAS ANTAS",
            "codigo_ibge":"4214409"
         },
         {
            "nome":"RIO DO CAMPO",
            "codigo_ibge":"4214508"
         },
         {
            "nome":"RIO DO OESTE",
            "codigo_ibge":"4214607"
         },
         {
            "nome":"RIO DOS CEDROS",
            "codigo_ibge":"4214706"
         },
         {
            "nome":"RIO DO SUL",
            "codigo_ibge":"4214805"
         },
         {
            "nome":"RIO FORTUNA",
            "codigo_ibge":"4214904"
         },
         {
            "nome":"RIO NEGRINHO",
            "codigo_ibge":"4215000"
         },
         {
            "nome":"RIO RUFINO",
            "codigo_ibge":"4215059"
         },
         {
            "nome":"RIQUEZA",
            "codigo_ibge":"4215075"
         },
         {
            "nome":"RODEIO",
            "codigo_ibge":"4215109"
         },
         {
            "nome":"ROMELANDIA",
            "codigo_ibge":"4215208"
         },
         {
            "nome":"SALETE",
            "codigo_ibge":"4215307"
         },
         {
            "nome":"SALTINHO",
            "codigo_ibge":"4215356"
         },
         {
            "nome":"SALTO VELOSO",
            "codigo_ibge":"4215406"
         },
         {
            "nome":"SANGAO",
            "codigo_ibge":"4215455"
         },
         {
            "nome":"SANTA CECILIA",
            "codigo_ibge":"4215505"
         },
         {
            "nome":"SANTA HELENA",
            "codigo_ibge":"4215554"
         },
         {
            "nome":"SANTA ROSA DE LIMA",
            "codigo_ibge":"4215604"
         },
         {
            "nome":"SANTA ROSA DO SUL",
            "codigo_ibge":"4215653"
         },
         {
            "nome":"SANTA TEREZINHA",
            "codigo_ibge":"4215679"
         },
         {
            "nome":"SANTA TEREZINHA DO PROGRESSO",
            "codigo_ibge":"4215687"
         },
         {
            "nome":"SANTIAGO DO SUL",
            "codigo_ibge":"4215695"
         },
         {
            "nome":"SANTO AMARO DA IMPERATRIZ",
            "codigo_ibge":"4215703"
         },
         {
            "nome":"SAO BERNARDINO",
            "codigo_ibge":"4215752"
         },
         {
            "nome":"SAO BENTO DO SUL",
            "codigo_ibge":"4215802"
         },
         {
            "nome":"SAO BONIFACIO",
            "codigo_ibge":"4215901"
         },
         {
            "nome":"SAO CARLOS",
            "codigo_ibge":"4216008"
         },
         {
            "nome":"SAO CRISTOVAO DO SUL",
            "codigo_ibge":"4216057"
         },
         {
            "nome":"SAO DOMINGOS",
            "codigo_ibge":"4216107"
         },
         {
            "nome":"SAO FRANCISCO DO SUL",
            "codigo_ibge":"4216206"
         },
         {
            "nome":"SAO JOAO DO OESTE",
            "codigo_ibge":"4216255"
         },
         {
            "nome":"SAO JOAO BATISTA",
            "codigo_ibge":"4216305"
         },
         {
            "nome":"SAO JOAO DO ITAPERIU",
            "codigo_ibge":"4216354"
         },
         {
            "nome":"SAO JOAO DO SUL",
            "codigo_ibge":"4216404"
         },
         {
            "nome":"SAO JOAQUIM",
            "codigo_ibge":"4216503"
         },
         {
            "nome":"SAO JOSE",
            "codigo_ibge":"4216602"
         },
         {
            "nome":"SAO JOSE DO CEDRO",
            "codigo_ibge":"4216701"
         },
         {
            "nome":"SAO JOSE DO CERRITO",
            "codigo_ibge":"4216800"
         },
         {
            "nome":"SAO LOURENCO DO OESTE",
            "codigo_ibge":"4216909"
         },
         {
            "nome":"SAO LUDGERO",
            "codigo_ibge":"4217006"
         },
         {
            "nome":"SAO MARTINHO",
            "codigo_ibge":"4217105"
         },
         {
            "nome":"SAO MIGUEL DA BOA VISTA",
            "codigo_ibge":"4217154"
         },
         {
            "nome":"SAO MIGUEL DO OESTE",
            "codigo_ibge":"4217204"
         },
         {
            "nome":"SAO PEDRO DE ALCANTARA",
            "codigo_ibge":"4217253"
         },
         {
            "nome":"SAUDADES",
            "codigo_ibge":"4217303"
         },
         {
            "nome":"SCHROEDER",
            "codigo_ibge":"4217402"
         },
         {
            "nome":"SEARA",
            "codigo_ibge":"4217501"
         },
         {
            "nome":"SERRA ALTA",
            "codigo_ibge":"4217550"
         },
         {
            "nome":"SIDEROPOLIS",
            "codigo_ibge":"4217600"
         },
         {
            "nome":"SOMBRIO",
            "codigo_ibge":"4217709"
         },
         {
            "nome":"SUL BRASIL",
            "codigo_ibge":"4217758"
         },
         {
            "nome":"TAIO",
            "codigo_ibge":"4217808"
         },
         {
            "nome":"TANGARA",
            "codigo_ibge":"4217907"
         },
         {
            "nome":"TIGRINHOS",
            "codigo_ibge":"4217956"
         },
         {
            "nome":"TIJUCAS",
            "codigo_ibge":"4218004"
         },
         {
            "nome":"TIMBE DO SUL",
            "codigo_ibge":"4218103"
         },
         {
            "nome":"TIMBO",
            "codigo_ibge":"4218202"
         },
         {
            "nome":"TIMBO GRANDE",
            "codigo_ibge":"4218251"
         },
         {
            "nome":"TRES BARRAS",
            "codigo_ibge":"4218301"
         },
         {
            "nome":"TREVISO",
            "codigo_ibge":"4218350"
         },
         {
            "nome":"TREZE DE MAIO",
            "codigo_ibge":"4218400"
         },
         {
            "nome":"TREZE TILIAS",
            "codigo_ibge":"4218509"
         },
         {
            "nome":"TROMBUDO CENTRAL",
            "codigo_ibge":"4218608"
         },
         {
            "nome":"TUBARAO",
            "codigo_ibge":"4218707"
         },
         {
            "nome":"TUNAPOLIS",
            "codigo_ibge":"4218756"
         },
         {
            "nome":"TURVO",
            "codigo_ibge":"4218806"
         },
         {
            "nome":"UNIAO DO OESTE",
            "codigo_ibge":"4218855"
         },
         {
            "nome":"URUBICI",
            "codigo_ibge":"4218905"
         },
         {
            "nome":"URUPEMA",
            "codigo_ibge":"4218954"
         },
         {
            "nome":"URUSSANGA",
            "codigo_ibge":"4219002"
         },
         {
            "nome":"VARGEAO",
            "codigo_ibge":"4219101"
         },
         {
            "nome":"VARGEM",
            "codigo_ibge":"4219150"
         },
         {
            "nome":"VARGEM BONITA",
            "codigo_ibge":"4219176"
         },
         {
            "nome":"VIDAL RAMOS",
            "codigo_ibge":"4219200"
         },
         {
            "nome":"VIDEIRA",
            "codigo_ibge":"4219309"
         },
         {
            "nome":"VITOR MEIRELES",
            "codigo_ibge":"4219358"
         },
         {
            "nome":"WITMARSUM",
            "codigo_ibge":"4219408"
         },
         {
            "nome":"XANXERE",
            "codigo_ibge":"4219507"
         },
         {
            "nome":"XAVANTINA",
            "codigo_ibge":"4219606"
         },
         {
            "nome":"XAXIM",
            "codigo_ibge":"4219705"
         },
         {
            "nome":"ZORTEA",
            "codigo_ibge":"4219853"
         },
         {
            "nome":"BALNEARIO RINCAO",
            "codigo_ibge":"4220000"
         }
      ]
   },
   {
      "estado" : "RR",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"AMAJARI",
            "codigo_ibge":"1400027"
         },
         {
            "nome":"ALTO ALEGRE",
            "codigo_ibge":"1400050"
         },
         {
            "nome":"BOA VISTA",
            "codigo_ibge":"1400100"
         },
         {
            "nome":"BONFIM",
            "codigo_ibge":"1400159"
         },
         {
            "nome":"CANTA",
            "codigo_ibge":"1400175"
         },
         {
            "nome":"CARACARAI",
            "codigo_ibge":"1400209"
         },
         {
            "nome":"CAROEBE",
            "codigo_ibge":"1400233"
         },
         {
            "nome":"IRACEMA",
            "codigo_ibge":"1400282"
         },
         {
            "nome":"MUCAJAI",
            "codigo_ibge":"1400308"
         },
         {
            "nome":"NORMANDIA",
            "codigo_ibge":"1400407"
         },
         {
            "nome":"PACARAIMA",
            "codigo_ibge":"1400456"
         },
         {
            "nome":"RORAINOPOLIS",
            "codigo_ibge":"1400472"
         },
         {
            "nome":"SAO JOAO DA BALIZA",
            "codigo_ibge":"1400506"
         },
         {
            "nome":"SAO LUIZ",
            "codigo_ibge":"1400605"
         },
         {
            "nome":"UIRAMUTA",
            "codigo_ibge":"1400704"
         }
      ]
   },
   {
      "estado" : "RO",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ALTA FLORESTA D OESTE",
            "codigo_ibge":"1100015"
         },
         {
            "nome":"ARIQUEMES",
            "codigo_ibge":"1100023"
         },
         {
            "nome":"CABIXI",
            "codigo_ibge":"1100031"
         },
         {
            "nome":"CACOAL",
            "codigo_ibge":"1100049"
         },
         {
            "nome":"CEREJEIRAS",
            "codigo_ibge":"1100056"
         },
         {
            "nome":"COLORADO DO OESTE",
            "codigo_ibge":"1100064"
         },
         {
            "nome":"CORUMBIARA",
            "codigo_ibge":"1100072"
         },
         {
            "nome":"COSTA MARQUES",
            "codigo_ibge":"1100080"
         },
         {
            "nome":"ESPIGAO D OESTE",
            "codigo_ibge":"1100098"
         },
         {
            "nome":"GUAJARA-MIRIM",
            "codigo_ibge":"1100106"
         },
         {
            "nome":"JARU",
            "codigo_ibge":"1100114"
         },
         {
            "nome":"JI-PARANA",
            "codigo_ibge":"1100122"
         },
         {
            "nome":"MACHADINHO D OESTE",
            "codigo_ibge":"1100130"
         },
         {
            "nome":"NOVA BRASILANDIA D OESTE",
            "codigo_ibge":"1100148"
         },
         {
            "nome":"OURO PRETO DO OESTE",
            "codigo_ibge":"1100155"
         },
         {
            "nome":"PIMENTA BUENO",
            "codigo_ibge":"1100189"
         },
         {
            "nome":"PORTO VELHO",
            "codigo_ibge":"1100205"
         },
         {
            "nome":"PRESIDENTE MEDICI",
            "codigo_ibge":"1100254"
         },
         {
            "nome":"RIO CRESPO",
            "codigo_ibge":"1100262"
         },
         {
            "nome":"ROLIM DE MOURA",
            "codigo_ibge":"1100288"
         },
         {
            "nome":"SANTA LUZIA D OESTE",
            "codigo_ibge":"1100296"
         },
         {
            "nome":"VILHENA",
            "codigo_ibge":"1100304"
         },
         {
            "nome":"SAO MIGUEL DO GUAPORE",
            "codigo_ibge":"1100320"
         },
         {
            "nome":"NOVA MAMORE",
            "codigo_ibge":"1100338"
         },
         {
            "nome":"ALVORADA D OESTE",
            "codigo_ibge":"1100346"
         },
         {
            "nome":"ALTO ALEGRE DOS PARECIS",
            "codigo_ibge":"1100379"
         },
         {
            "nome":"ALTO PARAISO",
            "codigo_ibge":"1100403"
         },
         {
            "nome":"BURITIS",
            "codigo_ibge":"1100452"
         },
         {
            "nome":"NOVO HORIZONTE DO OESTE",
            "codigo_ibge":"1100502"
         },
         {
            "nome":"CACAULANDIA",
            "codigo_ibge":"1100601"
         },
         {
            "nome":"CAMPO NOVO DE RONDONIA",
            "codigo_ibge":"1100700"
         },
         {
            "nome":"CANDEIAS DO JAMARI",
            "codigo_ibge":"1100809"
         },
         {
            "nome":"CASTANHEIRAS",
            "codigo_ibge":"1100908"
         },
         {
            "nome":"CHUPINGUAIA",
            "codigo_ibge":"1100924"
         },
         {
            "nome":"CUJUBIM",
            "codigo_ibge":"1100940"
         },
         {
            "nome":"GOVERNADOR JORGE TEIXEIRA",
            "codigo_ibge":"1101005"
         },
         {
            "nome":"ITAPUA DO OESTE",
            "codigo_ibge":"1101104"
         },
         {
            "nome":"MINISTRO ANDREAZZA",
            "codigo_ibge":"1101203"
         },
         {
            "nome":"MIRANTE DA SERRA",
            "codigo_ibge":"1101302"
         },
         {
            "nome":"MONTE NEGRO",
            "codigo_ibge":"1101401"
         },
         {
            "nome":"NOVA UNIAO",
            "codigo_ibge":"1101435"
         },
         {
            "nome":"PARECIS",
            "codigo_ibge":"1101450"
         },
         {
            "nome":"PIMENTEIRAS DO OESTE",
            "codigo_ibge":"1101468"
         },
         {
            "nome":"PRIMAVERA DE RONDONIA",
            "codigo_ibge":"1101476"
         },
         {
            "nome":"SAO FELIPE D OESTE",
            "codigo_ibge":"1101484"
         },
         {
            "nome":"SAO FRANCISCO DO GUAPORE",
            "codigo_ibge":"1101492"
         },
         {
            "nome":"SERINGUEIRAS",
            "codigo_ibge":"1101500"
         },
         {
            "nome":"TEIXEIROPOLIS",
            "codigo_ibge":"1101559"
         },
         {
            "nome":"THEOBROMA",
            "codigo_ibge":"1101609"
         },
         {
            "nome":"URUPA",
            "codigo_ibge":"1101708"
         },
         {
            "nome":"VALE DO ANARI",
            "codigo_ibge":"1101757"
         },
         {
            "nome":"VALE DO PARAISO",
            "codigo_ibge":"1101807"
         }
      ]
   },
   {
      "estado" : "RS",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ACEGUA",
            "codigo_ibge":"4300034"
         },
         {
            "nome":"AGUA SANTA",
            "codigo_ibge":"4300059"
         },
         {
            "nome":"AGUDO",
            "codigo_ibge":"4300109"
         },
         {
            "nome":"AJURICABA",
            "codigo_ibge":"4300208"
         },
         {
            "nome":"ALECRIM",
            "codigo_ibge":"4300307"
         },
         {
            "nome":"ALEGRETE",
            "codigo_ibge":"4300406"
         },
         {
            "nome":"ALEGRIA",
            "codigo_ibge":"4300455"
         },
         {
            "nome":"ALMIRANTE TAMANDARE DO SUL",
            "codigo_ibge":"4300471"
         },
         {
            "nome":"ALPESTRE",
            "codigo_ibge":"4300505"
         },
         {
            "nome":"ALTO ALEGRE",
            "codigo_ibge":"4300554"
         },
         {
            "nome":"ALTO FELIZ",
            "codigo_ibge":"4300570"
         },
         {
            "nome":"ALVORADA",
            "codigo_ibge":"4300604"
         },
         {
            "nome":"AMARAL FERRADOR",
            "codigo_ibge":"4300638"
         },
         {
            "nome":"AMETISTA DO SUL",
            "codigo_ibge":"4300646"
         },
         {
            "nome":"ANDRE DA ROCHA",
            "codigo_ibge":"4300661"
         },
         {
            "nome":"ANTA GORDA",
            "codigo_ibge":"4300703"
         },
         {
            "nome":"ANTONIO PRADO",
            "codigo_ibge":"4300802"
         },
         {
            "nome":"ARAMBARE",
            "codigo_ibge":"4300851"
         },
         {
            "nome":"ARARICA",
            "codigo_ibge":"4300877"
         },
         {
            "nome":"ARATIBA",
            "codigo_ibge":"4300901"
         },
         {
            "nome":"ARROIO DO MEIO",
            "codigo_ibge":"4301008"
         },
         {
            "nome":"ARROIO DO SAL",
            "codigo_ibge":"4301057"
         },
         {
            "nome":"ARROIO DO PADRE",
            "codigo_ibge":"4301073"
         },
         {
            "nome":"ARROIO DOS RATOS",
            "codigo_ibge":"4301107"
         },
         {
            "nome":"ARROIO DO TIGRE",
            "codigo_ibge":"4301206"
         },
         {
            "nome":"ARROIO GRANDE",
            "codigo_ibge":"4301305"
         },
         {
            "nome":"ARVOREZINHA",
            "codigo_ibge":"4301404"
         },
         {
            "nome":"AUGUSTO PESTANA",
            "codigo_ibge":"4301503"
         },
         {
            "nome":"AUREA",
            "codigo_ibge":"4301552"
         },
         {
            "nome":"BAGE",
            "codigo_ibge":"4301602"
         },
         {
            "nome":"BALNEARIO PINHAL",
            "codigo_ibge":"4301636"
         },
         {
            "nome":"BARAO",
            "codigo_ibge":"4301651"
         },
         {
            "nome":"BARAO DE COTEGIPE",
            "codigo_ibge":"4301701"
         },
         {
            "nome":"BARAO DO TRIUNFO",
            "codigo_ibge":"4301750"
         },
         {
            "nome":"BARRACAO",
            "codigo_ibge":"4301800"
         },
         {
            "nome":"BARRA DO GUARITA",
            "codigo_ibge":"4301859"
         },
         {
            "nome":"BARRA DO QUARAI",
            "codigo_ibge":"4301875"
         },
         {
            "nome":"BARRA DO RIBEIRO",
            "codigo_ibge":"4301909"
         },
         {
            "nome":"BARRA DO RIO AZUL",
            "codigo_ibge":"4301925"
         },
         {
            "nome":"BARRA FUNDA",
            "codigo_ibge":"4301958"
         },
         {
            "nome":"BARROS CASSAL",
            "codigo_ibge":"4302006"
         },
         {
            "nome":"BENJAMIN CONSTANT DO SUL",
            "codigo_ibge":"4302055"
         },
         {
            "nome":"BENTO GONCALVES",
            "codigo_ibge":"4302105"
         },
         {
            "nome":"BOA VISTA DAS MISSOES",
            "codigo_ibge":"4302154"
         },
         {
            "nome":"BOA VISTA DO BURICA",
            "codigo_ibge":"4302204"
         },
         {
            "nome":"BOA VISTA DO CADEADO",
            "codigo_ibge":"4302220"
         },
         {
            "nome":"BOA VISTA DO INCRA",
            "codigo_ibge":"4302238"
         },
         {
            "nome":"BOA VISTA DO SUL",
            "codigo_ibge":"4302253"
         },
         {
            "nome":"BOM JESUS",
            "codigo_ibge":"4302303"
         },
         {
            "nome":"BOM PRINCIPIO",
            "codigo_ibge":"4302352"
         },
         {
            "nome":"BOM PROGRESSO",
            "codigo_ibge":"4302378"
         },
         {
            "nome":"BOM RETIRO DO SUL",
            "codigo_ibge":"4302402"
         },
         {
            "nome":"BOQUEIRAO DO LEAO",
            "codigo_ibge":"4302451"
         },
         {
            "nome":"BOSSOROCA",
            "codigo_ibge":"4302501"
         },
         {
            "nome":"BOZANO",
            "codigo_ibge":"4302584"
         },
         {
            "nome":"BRAGA",
            "codigo_ibge":"4302600"
         },
         {
            "nome":"BROCHIER",
            "codigo_ibge":"4302659"
         },
         {
            "nome":"BUTIA",
            "codigo_ibge":"4302709"
         },
         {
            "nome":"CACAPAVA DO SUL",
            "codigo_ibge":"4302808"
         },
         {
            "nome":"CACEQUI",
            "codigo_ibge":"4302907"
         },
         {
            "nome":"CACHOEIRA DO SUL",
            "codigo_ibge":"4303004"
         },
         {
            "nome":"CACHOEIRINHA",
            "codigo_ibge":"4303103"
         },
         {
            "nome":"CACIQUE DOBLE",
            "codigo_ibge":"4303202"
         },
         {
            "nome":"CAIBATE",
            "codigo_ibge":"4303301"
         },
         {
            "nome":"CAICARA",
            "codigo_ibge":"4303400"
         },
         {
            "nome":"CAMAQUA",
            "codigo_ibge":"4303509"
         },
         {
            "nome":"CAMARGO",
            "codigo_ibge":"4303558"
         },
         {
            "nome":"CAMBARA DO SUL",
            "codigo_ibge":"4303608"
         },
         {
            "nome":"CAMPESTRE DA SERRA",
            "codigo_ibge":"4303673"
         },
         {
            "nome":"CAMPINA DAS MISSOES",
            "codigo_ibge":"4303707"
         },
         {
            "nome":"CAMPINAS DO SUL",
            "codigo_ibge":"4303806"
         },
         {
            "nome":"CAMPO BOM",
            "codigo_ibge":"4303905"
         },
         {
            "nome":"CAMPO NOVO",
            "codigo_ibge":"4304002"
         },
         {
            "nome":"CAMPOS BORGES",
            "codigo_ibge":"4304101"
         },
         {
            "nome":"CANDELARIA",
            "codigo_ibge":"4304200"
         },
         {
            "nome":"CANDIDO GODOI",
            "codigo_ibge":"4304309"
         },
         {
            "nome":"CANDIOTA",
            "codigo_ibge":"4304358"
         },
         {
            "nome":"CANELA",
            "codigo_ibge":"4304408"
         },
         {
            "nome":"CANGUCU",
            "codigo_ibge":"4304507"
         },
         {
            "nome":"CANOAS",
            "codigo_ibge":"4304606"
         },
         {
            "nome":"CANUDOS DO VALE",
            "codigo_ibge":"4304614"
         },
         {
            "nome":"CAPAO BONITO DO SUL",
            "codigo_ibge":"4304622"
         },
         {
            "nome":"CAPAO DA CANOA",
            "codigo_ibge":"4304630"
         },
         {
            "nome":"CAPAO DO CIPO",
            "codigo_ibge":"4304655"
         },
         {
            "nome":"CAPAO DO LEAO",
            "codigo_ibge":"4304663"
         },
         {
            "nome":"CAPIVARI DO SUL",
            "codigo_ibge":"4304671"
         },
         {
            "nome":"CAPELA DE SANTANA",
            "codigo_ibge":"4304689"
         },
         {
            "nome":"CAPITAO",
            "codigo_ibge":"4304697"
         },
         {
            "nome":"CARAZINHO",
            "codigo_ibge":"4304705"
         },
         {
            "nome":"CARAA",
            "codigo_ibge":"4304713"
         },
         {
            "nome":"CARLOS BARBOSA",
            "codigo_ibge":"4304804"
         },
         {
            "nome":"CARLOS GOMES",
            "codigo_ibge":"4304853"
         },
         {
            "nome":"CASCA",
            "codigo_ibge":"4304903"
         },
         {
            "nome":"CASEIROS",
            "codigo_ibge":"4304952"
         },
         {
            "nome":"CATUIPE",
            "codigo_ibge":"4305009"
         },
         {
            "nome":"CAXIAS DO SUL",
            "codigo_ibge":"4305108"
         },
         {
            "nome":"CENTENARIO",
            "codigo_ibge":"4305116"
         },
         {
            "nome":"CERRITO",
            "codigo_ibge":"4305124"
         },
         {
            "nome":"CERRO BRANCO",
            "codigo_ibge":"4305132"
         },
         {
            "nome":"CERRO GRANDE",
            "codigo_ibge":"4305157"
         },
         {
            "nome":"CERRO GRANDE DO SUL",
            "codigo_ibge":"4305173"
         },
         {
            "nome":"CERRO LARGO",
            "codigo_ibge":"4305207"
         },
         {
            "nome":"CHAPADA",
            "codigo_ibge":"4305306"
         },
         {
            "nome":"CHARQUEADAS",
            "codigo_ibge":"4305355"
         },
         {
            "nome":"CHARRUA",
            "codigo_ibge":"4305371"
         },
         {
            "nome":"CHIAPETTA",
            "codigo_ibge":"4305405"
         },
         {
            "nome":"CHUI",
            "codigo_ibge":"4305439"
         },
         {
            "nome":"CHUVISCA",
            "codigo_ibge":"4305447"
         },
         {
            "nome":"CIDREIRA",
            "codigo_ibge":"4305454"
         },
         {
            "nome":"CIRIACO",
            "codigo_ibge":"4305504"
         },
         {
            "nome":"COLINAS",
            "codigo_ibge":"4305587"
         },
         {
            "nome":"COLORADO",
            "codigo_ibge":"4305603"
         },
         {
            "nome":"CONDOR",
            "codigo_ibge":"4305702"
         },
         {
            "nome":"CONSTANTINA",
            "codigo_ibge":"4305801"
         },
         {
            "nome":"COQUEIRO BAIXO",
            "codigo_ibge":"4305835"
         },
         {
            "nome":"COQUEIROS DO SUL",
            "codigo_ibge":"4305850"
         },
         {
            "nome":"CORONEL BARROS",
            "codigo_ibge":"4305871"
         },
         {
            "nome":"CORONEL BICACO",
            "codigo_ibge":"4305900"
         },
         {
            "nome":"CORONEL PILAR",
            "codigo_ibge":"4305934"
         },
         {
            "nome":"COTIPORA",
            "codigo_ibge":"4305959"
         },
         {
            "nome":"COXILHA",
            "codigo_ibge":"4305975"
         },
         {
            "nome":"CRISSIUMAL",
            "codigo_ibge":"4306007"
         },
         {
            "nome":"CRISTAL",
            "codigo_ibge":"4306056"
         },
         {
            "nome":"CRISTAL DO SUL",
            "codigo_ibge":"4306072"
         },
         {
            "nome":"CRUZ ALTA",
            "codigo_ibge":"4306106"
         },
         {
            "nome":"CRUZALTENSE",
            "codigo_ibge":"4306130"
         },
         {
            "nome":"CRUZEIRO DO SUL",
            "codigo_ibge":"4306205"
         },
         {
            "nome":"DAVID CANABARRO",
            "codigo_ibge":"4306304"
         },
         {
            "nome":"DERRUBADAS",
            "codigo_ibge":"4306320"
         },
         {
            "nome":"DEZESSEIS DE NOVEMBRO",
            "codigo_ibge":"4306353"
         },
         {
            "nome":"DILERMANDO DE AGUIAR",
            "codigo_ibge":"4306379"
         },
         {
            "nome":"DOIS IRMAOS",
            "codigo_ibge":"4306403"
         },
         {
            "nome":"DOIS IRMAOS DAS MISSOES",
            "codigo_ibge":"4306429"
         },
         {
            "nome":"DOIS LAJEADOS",
            "codigo_ibge":"4306452"
         },
         {
            "nome":"DOM FELICIANO",
            "codigo_ibge":"4306502"
         },
         {
            "nome":"DOM PEDRO DE ALCANTARA",
            "codigo_ibge":"4306551"
         },
         {
            "nome":"DOM PEDRITO",
            "codigo_ibge":"4306601"
         },
         {
            "nome":"DONA FRANCISCA",
            "codigo_ibge":"4306700"
         },
         {
            "nome":"DOUTOR MAURICIO CARDOSO",
            "codigo_ibge":"4306734"
         },
         {
            "nome":"DOUTOR RICARDO",
            "codigo_ibge":"4306759"
         },
         {
            "nome":"ELDORADO DO SUL",
            "codigo_ibge":"4306767"
         },
         {
            "nome":"ENCANTADO",
            "codigo_ibge":"4306809"
         },
         {
            "nome":"ENCRUZILHADA DO SUL",
            "codigo_ibge":"4306908"
         },
         {
            "nome":"ENGENHO VELHO",
            "codigo_ibge":"4306924"
         },
         {
            "nome":"ENTRE-IJUIS",
            "codigo_ibge":"4306932"
         },
         {
            "nome":"ENTRE RIOS DO SUL",
            "codigo_ibge":"4306957"
         },
         {
            "nome":"EREBANGO",
            "codigo_ibge":"4306973"
         },
         {
            "nome":"ERECHIM",
            "codigo_ibge":"4307005"
         },
         {
            "nome":"ERNESTINA",
            "codigo_ibge":"4307054"
         },
         {
            "nome":"HERVAL",
            "codigo_ibge":"4307104"
         },
         {
            "nome":"ERVAL GRANDE",
            "codigo_ibge":"4307203"
         },
         {
            "nome":"ERVAL SECO",
            "codigo_ibge":"4307302"
         },
         {
            "nome":"ESMERALDA",
            "codigo_ibge":"4307401"
         },
         {
            "nome":"ESPERANCA DO SUL",
            "codigo_ibge":"4307450"
         },
         {
            "nome":"ESPUMOSO",
            "codigo_ibge":"4307500"
         },
         {
            "nome":"ESTACAO",
            "codigo_ibge":"4307559"
         },
         {
            "nome":"ESTANCIA VELHA",
            "codigo_ibge":"4307609"
         },
         {
            "nome":"ESTEIO",
            "codigo_ibge":"4307708"
         },
         {
            "nome":"ESTRELA",
            "codigo_ibge":"4307807"
         },
         {
            "nome":"ESTRELA VELHA",
            "codigo_ibge":"4307815"
         },
         {
            "nome":"EUGENIO DE CASTRO",
            "codigo_ibge":"4307831"
         },
         {
            "nome":"FAGUNDES VARELA",
            "codigo_ibge":"4307864"
         },
         {
            "nome":"FARROUPILHA",
            "codigo_ibge":"4307906"
         },
         {
            "nome":"FAXINAL DO SOTURNO",
            "codigo_ibge":"4308003"
         },
         {
            "nome":"FAXINALZINHO",
            "codigo_ibge":"4308052"
         },
         {
            "nome":"FAZENDA VILANOVA",
            "codigo_ibge":"4308078"
         },
         {
            "nome":"FELIZ",
            "codigo_ibge":"4308102"
         },
         {
            "nome":"FLORES DA CUNHA",
            "codigo_ibge":"4308201"
         },
         {
            "nome":"FLORIANO PEIXOTO",
            "codigo_ibge":"4308250"
         },
         {
            "nome":"FONTOURA XAVIER",
            "codigo_ibge":"4308300"
         },
         {
            "nome":"FORMIGUEIRO",
            "codigo_ibge":"4308409"
         },
         {
            "nome":"FORQUETINHA",
            "codigo_ibge":"4308433"
         },
         {
            "nome":"FORTALEZA DOS VALOS",
            "codigo_ibge":"4308458"
         },
         {
            "nome":"FREDERICO WESTPHALEN",
            "codigo_ibge":"4308508"
         },
         {
            "nome":"GARIBALDI",
            "codigo_ibge":"4308607"
         },
         {
            "nome":"GARRUCHOS",
            "codigo_ibge":"4308656"
         },
         {
            "nome":"GAURAMA",
            "codigo_ibge":"4308706"
         },
         {
            "nome":"GENERAL CAMARA",
            "codigo_ibge":"4308805"
         },
         {
            "nome":"GENTIL",
            "codigo_ibge":"4308854"
         },
         {
            "nome":"GETULIO VARGAS",
            "codigo_ibge":"4308904"
         },
         {
            "nome":"GIRUA",
            "codigo_ibge":"4309001"
         },
         {
            "nome":"GLORINHA",
            "codigo_ibge":"4309050"
         },
         {
            "nome":"GRAMADO",
            "codigo_ibge":"4309100"
         },
         {
            "nome":"GRAMADO DOS LOUREIROS",
            "codigo_ibge":"4309126"
         },
         {
            "nome":"GRAMADO XAVIER",
            "codigo_ibge":"4309159"
         },
         {
            "nome":"GRAVATAI",
            "codigo_ibge":"4309209"
         },
         {
            "nome":"GUABIJU",
            "codigo_ibge":"4309258"
         },
         {
            "nome":"GUAIBA",
            "codigo_ibge":"4309308"
         },
         {
            "nome":"GUAPORE",
            "codigo_ibge":"4309407"
         },
         {
            "nome":"GUARANI DAS MISSOES",
            "codigo_ibge":"4309506"
         },
         {
            "nome":"HARMONIA",
            "codigo_ibge":"4309555"
         },
         {
            "nome":"HERVEIRAS",
            "codigo_ibge":"4309571"
         },
         {
            "nome":"HORIZONTINA",
            "codigo_ibge":"4309605"
         },
         {
            "nome":"HULHA NEGRA",
            "codigo_ibge":"4309654"
         },
         {
            "nome":"HUMAITA",
            "codigo_ibge":"4309704"
         },
         {
            "nome":"IBARAMA",
            "codigo_ibge":"4309753"
         },
         {
            "nome":"IBIACA",
            "codigo_ibge":"4309803"
         },
         {
            "nome":"IBIRAIARAS",
            "codigo_ibge":"4309902"
         },
         {
            "nome":"IBIRAPUITA",
            "codigo_ibge":"4309951"
         },
         {
            "nome":"IBIRUBA",
            "codigo_ibge":"4310009"
         },
         {
            "nome":"IGREJINHA",
            "codigo_ibge":"4310108"
         },
         {
            "nome":"IJUI",
            "codigo_ibge":"4310207"
         },
         {
            "nome":"ILOPOLIS",
            "codigo_ibge":"4310306"
         },
         {
            "nome":"IMBE",
            "codigo_ibge":"4310330"
         },
         {
            "nome":"IMIGRANTE",
            "codigo_ibge":"4310363"
         },
         {
            "nome":"INDEPENDENCIA",
            "codigo_ibge":"4310405"
         },
         {
            "nome":"INHACORA",
            "codigo_ibge":"4310413"
         },
         {
            "nome":"IPE",
            "codigo_ibge":"4310439"
         },
         {
            "nome":"IPIRANGA DO SUL",
            "codigo_ibge":"4310462"
         },
         {
            "nome":"IRAI",
            "codigo_ibge":"4310504"
         },
         {
            "nome":"ITAARA",
            "codigo_ibge":"4310538"
         },
         {
            "nome":"ITACURUBI",
            "codigo_ibge":"4310553"
         },
         {
            "nome":"ITAPUCA",
            "codigo_ibge":"4310579"
         },
         {
            "nome":"ITAQUI",
            "codigo_ibge":"4310603"
         },
         {
            "nome":"ITATI",
            "codigo_ibge":"4310652"
         },
         {
            "nome":"ITATIBA DO SUL",
            "codigo_ibge":"4310702"
         },
         {
            "nome":"IVORA",
            "codigo_ibge":"4310751"
         },
         {
            "nome":"IVOTI",
            "codigo_ibge":"4310801"
         },
         {
            "nome":"JABOTICABA",
            "codigo_ibge":"4310850"
         },
         {
            "nome":"JACUIZINHO",
            "codigo_ibge":"4310876"
         },
         {
            "nome":"JACUTINGA",
            "codigo_ibge":"4310900"
         },
         {
            "nome":"JAGUARAO",
            "codigo_ibge":"4311007"
         },
         {
            "nome":"JAGUARI",
            "codigo_ibge":"4311106"
         },
         {
            "nome":"JAQUIRANA",
            "codigo_ibge":"4311122"
         },
         {
            "nome":"JARI",
            "codigo_ibge":"4311130"
         },
         {
            "nome":"JOIA",
            "codigo_ibge":"4311155"
         },
         {
            "nome":"JULIO DE CASTILHOS",
            "codigo_ibge":"4311205"
         },
         {
            "nome":"LAGOA BONITA DO SUL",
            "codigo_ibge":"4311239"
         },
         {
            "nome":"LAGOAO",
            "codigo_ibge":"4311254"
         },
         {
            "nome":"LAGOA DOS TRES CANTOS",
            "codigo_ibge":"4311270"
         },
         {
            "nome":"LAGOA VERMELHA",
            "codigo_ibge":"4311304"
         },
         {
            "nome":"LAJEADO",
            "codigo_ibge":"4311403"
         },
         {
            "nome":"LAJEADO DO BUGRE",
            "codigo_ibge":"4311429"
         },
         {
            "nome":"LAVRAS DO SUL",
            "codigo_ibge":"4311502"
         },
         {
            "nome":"LIBERATO SALZANO",
            "codigo_ibge":"4311601"
         },
         {
            "nome":"LINDOLFO COLLOR",
            "codigo_ibge":"4311627"
         },
         {
            "nome":"LINHA NOVA",
            "codigo_ibge":"4311643"
         },
         {
            "nome":"MACHADINHO",
            "codigo_ibge":"4311700"
         },
         {
            "nome":"MACAMBARA",
            "codigo_ibge":"4311718"
         },
         {
            "nome":"MAMPITUBA",
            "codigo_ibge":"4311734"
         },
         {
            "nome":"MANOEL VIANA",
            "codigo_ibge":"4311759"
         },
         {
            "nome":"MAQUINE",
            "codigo_ibge":"4311775"
         },
         {
            "nome":"MARATA",
            "codigo_ibge":"4311791"
         },
         {
            "nome":"MARAU",
            "codigo_ibge":"4311809"
         },
         {
            "nome":"MARCELINO RAMOS",
            "codigo_ibge":"4311908"
         },
         {
            "nome":"MARIANA PIMENTEL",
            "codigo_ibge":"4311981"
         },
         {
            "nome":"MARIANO MORO",
            "codigo_ibge":"4312005"
         },
         {
            "nome":"MARQUES DE SOUZA",
            "codigo_ibge":"4312054"
         },
         {
            "nome":"MATA",
            "codigo_ibge":"4312104"
         },
         {
            "nome":"MATO CASTELHANO",
            "codigo_ibge":"4312138"
         },
         {
            "nome":"MATO LEITAO",
            "codigo_ibge":"4312153"
         },
         {
            "nome":"MATO QUEIMADO",
            "codigo_ibge":"4312179"
         },
         {
            "nome":"MAXIMILIANO DE ALMEIDA",
            "codigo_ibge":"4312203"
         },
         {
            "nome":"MINAS DO LEAO",
            "codigo_ibge":"4312252"
         },
         {
            "nome":"MIRAGUAI",
            "codigo_ibge":"4312302"
         },
         {
            "nome":"MONTAURI",
            "codigo_ibge":"4312351"
         },
         {
            "nome":"MONTE ALEGRE DOS CAMPOS",
            "codigo_ibge":"4312377"
         },
         {
            "nome":"MONTE BELO DO SUL",
            "codigo_ibge":"4312385"
         },
         {
            "nome":"MONTENEGRO",
            "codigo_ibge":"4312401"
         },
         {
            "nome":"MORMACO",
            "codigo_ibge":"4312427"
         },
         {
            "nome":"MORRINHOS DO SUL",
            "codigo_ibge":"4312443"
         },
         {
            "nome":"MORRO REDONDO",
            "codigo_ibge":"4312450"
         },
         {
            "nome":"MORRO REUTER",
            "codigo_ibge":"4312476"
         },
         {
            "nome":"MOSTARDAS",
            "codigo_ibge":"4312500"
         },
         {
            "nome":"MUCUM",
            "codigo_ibge":"4312609"
         },
         {
            "nome":"MUITOS CAPOES",
            "codigo_ibge":"4312617"
         },
         {
            "nome":"MULITERNO",
            "codigo_ibge":"4312625"
         },
         {
            "nome":"NAO-ME-TOQUE",
            "codigo_ibge":"4312658"
         },
         {
            "nome":"NICOLAU VERGUEIRO",
            "codigo_ibge":"4312674"
         },
         {
            "nome":"NONOAI",
            "codigo_ibge":"4312708"
         },
         {
            "nome":"NOVA ALVORADA",
            "codigo_ibge":"4312757"
         },
         {
            "nome":"NOVA ARACA",
            "codigo_ibge":"4312807"
         },
         {
            "nome":"NOVA BASSANO",
            "codigo_ibge":"4312906"
         },
         {
            "nome":"NOVA BOA VISTA",
            "codigo_ibge":"4312955"
         },
         {
            "nome":"NOVA BRESCIA",
            "codigo_ibge":"4313003"
         },
         {
            "nome":"NOVA CANDELARIA",
            "codigo_ibge":"4313011"
         },
         {
            "nome":"NOVA ESPERANCA DO SUL",
            "codigo_ibge":"4313037"
         },
         {
            "nome":"NOVA HARTZ",
            "codigo_ibge":"4313060"
         },
         {
            "nome":"NOVA PADUA",
            "codigo_ibge":"4313086"
         },
         {
            "nome":"NOVA PALMA",
            "codigo_ibge":"4313102"
         },
         {
            "nome":"NOVA PETROPOLIS",
            "codigo_ibge":"4313201"
         },
         {
            "nome":"NOVA PRATA",
            "codigo_ibge":"4313300"
         },
         {
            "nome":"NOVA RAMADA",
            "codigo_ibge":"4313334"
         },
         {
            "nome":"NOVA ROMA DO SUL",
            "codigo_ibge":"4313359"
         },
         {
            "nome":"NOVA SANTA RITA",
            "codigo_ibge":"4313375"
         },
         {
            "nome":"NOVO CABRAIS",
            "codigo_ibge":"4313391"
         },
         {
            "nome":"NOVO HAMBURGO",
            "codigo_ibge":"4313409"
         },
         {
            "nome":"NOVO MACHADO",
            "codigo_ibge":"4313425"
         },
         {
            "nome":"NOVO TIRADENTES",
            "codigo_ibge":"4313441"
         },
         {
            "nome":"NOVO XINGU",
            "codigo_ibge":"4313466"
         },
         {
            "nome":"NOVO BARREIRO",
            "codigo_ibge":"4313490"
         },
         {
            "nome":"OSORIO",
            "codigo_ibge":"4313508"
         },
         {
            "nome":"PAIM FILHO",
            "codigo_ibge":"4313607"
         },
         {
            "nome":"PALMARES DO SUL",
            "codigo_ibge":"4313656"
         },
         {
            "nome":"PALMEIRA DAS MISSOES",
            "codigo_ibge":"4313706"
         },
         {
            "nome":"PALMITINHO",
            "codigo_ibge":"4313805"
         },
         {
            "nome":"PANAMBI",
            "codigo_ibge":"4313904"
         },
         {
            "nome":"PANTANO GRANDE",
            "codigo_ibge":"4313953"
         },
         {
            "nome":"PARAI",
            "codigo_ibge":"4314001"
         },
         {
            "nome":"PARAISO DO SUL",
            "codigo_ibge":"4314027"
         },
         {
            "nome":"PARECI NOVO",
            "codigo_ibge":"4314035"
         },
         {
            "nome":"PAROBE",
            "codigo_ibge":"4314050"
         },
         {
            "nome":"PASSA SETE",
            "codigo_ibge":"4314068"
         },
         {
            "nome":"PASSO DO SOBRADO",
            "codigo_ibge":"4314076"
         },
         {
            "nome":"PASSO FUNDO",
            "codigo_ibge":"4314100"
         },
         {
            "nome":"PAULO BENTO",
            "codigo_ibge":"4314134"
         },
         {
            "nome":"PAVERAMA",
            "codigo_ibge":"4314159"
         },
         {
            "nome":"PEDRAS ALTAS",
            "codigo_ibge":"4314175"
         },
         {
            "nome":"PEDRO OSORIO",
            "codigo_ibge":"4314209"
         },
         {
            "nome":"PEJUCARA",
            "codigo_ibge":"4314308"
         },
         {
            "nome":"PELOTAS",
            "codigo_ibge":"4314407"
         },
         {
            "nome":"PICADA CAFE",
            "codigo_ibge":"4314423"
         },
         {
            "nome":"PINHAL",
            "codigo_ibge":"4314456"
         },
         {
            "nome":"PINHAL DA SERRA",
            "codigo_ibge":"4314464"
         },
         {
            "nome":"PINHAL GRANDE",
            "codigo_ibge":"4314472"
         },
         {
            "nome":"PINHEIRINHO DO VALE",
            "codigo_ibge":"4314498"
         },
         {
            "nome":"PINHEIRO MACHADO",
            "codigo_ibge":"4314506"
         },
         {
            "nome":"PINTO BANDEIRA",
            "codigo_ibge":"4314548"
         },
         {
            "nome":"PIRAPO",
            "codigo_ibge":"4314555"
         },
         {
            "nome":"PIRATINI",
            "codigo_ibge":"4314605"
         },
         {
            "nome":"PLANALTO",
            "codigo_ibge":"4314704"
         },
         {
            "nome":"POCO DAS ANTAS",
            "codigo_ibge":"4314753"
         },
         {
            "nome":"PONTAO",
            "codigo_ibge":"4314779"
         },
         {
            "nome":"PONTE PRETA",
            "codigo_ibge":"4314787"
         },
         {
            "nome":"PORTAO",
            "codigo_ibge":"4314803"
         },
         {
            "nome":"PORTO ALEGRE",
            "codigo_ibge":"4314902"
         },
         {
            "nome":"PORTO LUCENA",
            "codigo_ibge":"4315008"
         },
         {
            "nome":"PORTO MAUA",
            "codigo_ibge":"4315057"
         },
         {
            "nome":"PORTO VERA CRUZ",
            "codigo_ibge":"4315073"
         },
         {
            "nome":"PORTO XAVIER",
            "codigo_ibge":"4315107"
         },
         {
            "nome":"POUSO NOVO",
            "codigo_ibge":"4315131"
         },
         {
            "nome":"PRESIDENTE LUCENA",
            "codigo_ibge":"4315149"
         },
         {
            "nome":"PROGRESSO",
            "codigo_ibge":"4315156"
         },
         {
            "nome":"PROTASIO ALVES",
            "codigo_ibge":"4315172"
         },
         {
            "nome":"PUTINGA",
            "codigo_ibge":"4315206"
         },
         {
            "nome":"QUARAI",
            "codigo_ibge":"4315305"
         },
         {
            "nome":"QUATRO IRMAOS",
            "codigo_ibge":"4315313"
         },
         {
            "nome":"QUEVEDOS",
            "codigo_ibge":"4315321"
         },
         {
            "nome":"QUINZE DE NOVEMBRO",
            "codigo_ibge":"4315354"
         },
         {
            "nome":"REDENTORA",
            "codigo_ibge":"4315404"
         },
         {
            "nome":"RELVADO",
            "codigo_ibge":"4315453"
         },
         {
            "nome":"RESTINGA SECA",
            "codigo_ibge":"4315503"
         },
         {
            "nome":"RIO DOS INDIOS",
            "codigo_ibge":"4315552"
         },
         {
            "nome":"RIO GRANDE",
            "codigo_ibge":"4315602"
         },
         {
            "nome":"RIO PARDO",
            "codigo_ibge":"4315701"
         },
         {
            "nome":"RIOZINHO",
            "codigo_ibge":"4315750"
         },
         {
            "nome":"ROCA SALES",
            "codigo_ibge":"4315800"
         },
         {
            "nome":"RODEIO BONITO",
            "codigo_ibge":"4315909"
         },
         {
            "nome":"ROLADOR",
            "codigo_ibge":"4315958"
         },
         {
            "nome":"ROLANTE",
            "codigo_ibge":"4316006"
         },
         {
            "nome":"RONDA ALTA",
            "codigo_ibge":"4316105"
         },
         {
            "nome":"RONDINHA",
            "codigo_ibge":"4316204"
         },
         {
            "nome":"ROQUE GONZALES",
            "codigo_ibge":"4316303"
         },
         {
            "nome":"ROSARIO DO SUL",
            "codigo_ibge":"4316402"
         },
         {
            "nome":"SAGRADA FAMILIA",
            "codigo_ibge":"4316428"
         },
         {
            "nome":"SALDANHA MARINHO",
            "codigo_ibge":"4316436"
         },
         {
            "nome":"SALTO DO JACUI",
            "codigo_ibge":"4316451"
         },
         {
            "nome":"SALVADOR DAS MISSOES",
            "codigo_ibge":"4316477"
         },
         {
            "nome":"SALVADOR DO SUL",
            "codigo_ibge":"4316501"
         },
         {
            "nome":"SANANDUVA",
            "codigo_ibge":"4316600"
         },
         {
            "nome":"SANTA BARBARA DO SUL",
            "codigo_ibge":"4316709"
         },
         {
            "nome":"SANTA CECILIA DO SUL",
            "codigo_ibge":"4316733"
         },
         {
            "nome":"SANTA CLARA DO SUL",
            "codigo_ibge":"4316758"
         },
         {
            "nome":"SANTA CRUZ DO SUL",
            "codigo_ibge":"4316808"
         },
         {
            "nome":"SANTA MARIA",
            "codigo_ibge":"4316907"
         },
         {
            "nome":"SANTA MARIA DO HERVAL",
            "codigo_ibge":"4316956"
         },
         {
            "nome":"SANTA MARGARIDA DO SUL",
            "codigo_ibge":"4316972"
         },
         {
            "nome":"SANTANA DA BOA VISTA",
            "codigo_ibge":"4317004"
         },
         {
            "nome":"SANT ANA DO LIVRAMENTO",
            "codigo_ibge":"4317103"
         },
         {
            "nome":"SANTA ROSA",
            "codigo_ibge":"4317202"
         },
         {
            "nome":"SANTA TEREZA",
            "codigo_ibge":"4317251"
         },
         {
            "nome":"SANTA VITORIA DO PALMAR",
            "codigo_ibge":"4317301"
         },
         {
            "nome":"SANTIAGO",
            "codigo_ibge":"4317400"
         },
         {
            "nome":"SANTO ANGELO",
            "codigo_ibge":"4317509"
         },
         {
            "nome":"SANTO ANTONIO DO PALMA",
            "codigo_ibge":"4317558"
         },
         {
            "nome":"SANTO ANTONIO DA PATRULHA",
            "codigo_ibge":"4317608"
         },
         {
            "nome":"SANTO ANTONIO DAS MISSOES",
            "codigo_ibge":"4317707"
         },
         {
            "nome":"SANTO ANTONIO DO PLANALTO",
            "codigo_ibge":"4317756"
         },
         {
            "nome":"SANTO AUGUSTO",
            "codigo_ibge":"4317806"
         },
         {
            "nome":"SANTO CRISTO",
            "codigo_ibge":"4317905"
         },
         {
            "nome":"SANTO EXPEDITO DO SUL",
            "codigo_ibge":"4317954"
         },
         {
            "nome":"SAO BORJA",
            "codigo_ibge":"4318002"
         },
         {
            "nome":"SAO DOMINGOS DO SUL",
            "codigo_ibge":"4318051"
         },
         {
            "nome":"SAO FRANCISCO DE ASSIS",
            "codigo_ibge":"4318101"
         },
         {
            "nome":"SAO FRANCISCO DE PAULA",
            "codigo_ibge":"4318200"
         },
         {
            "nome":"SAO GABRIEL",
            "codigo_ibge":"4318309"
         },
         {
            "nome":"SAO JERONIMO",
            "codigo_ibge":"4318408"
         },
         {
            "nome":"SAO JOAO DA URTIGA",
            "codigo_ibge":"4318424"
         },
         {
            "nome":"SAO JOAO DO POLESINE",
            "codigo_ibge":"4318432"
         },
         {
            "nome":"SAO JORGE",
            "codigo_ibge":"4318440"
         },
         {
            "nome":"SAO JOSE DAS MISSOES",
            "codigo_ibge":"4318457"
         },
         {
            "nome":"SAO JOSE DO HERVAL",
            "codigo_ibge":"4318465"
         },
         {
            "nome":"SAO JOSE DO HORTENCIO",
            "codigo_ibge":"4318481"
         },
         {
            "nome":"SAO JOSE DO INHACORA",
            "codigo_ibge":"4318499"
         },
         {
            "nome":"SAO JOSE DO NORTE",
            "codigo_ibge":"4318507"
         },
         {
            "nome":"SAO JOSE DO OURO",
            "codigo_ibge":"4318606"
         },
         {
            "nome":"SAO JOSE DO SUL",
            "codigo_ibge":"4318614"
         },
         {
            "nome":"SAO JOSE DOS AUSENTES",
            "codigo_ibge":"4318622"
         },
         {
            "nome":"SAO LEOPOLDO",
            "codigo_ibge":"4318705"
         },
         {
            "nome":"SAO LOURENCO DO SUL",
            "codigo_ibge":"4318804"
         },
         {
            "nome":"SAO LUIZ GONZAGA",
            "codigo_ibge":"4318903"
         },
         {
            "nome":"SAO MARCOS",
            "codigo_ibge":"4319000"
         },
         {
            "nome":"SAO MARTINHO",
            "codigo_ibge":"4319109"
         },
         {
            "nome":"SAO MARTINHO DA SERRA",
            "codigo_ibge":"4319125"
         },
         {
            "nome":"SAO MIGUEL DAS MISSOES",
            "codigo_ibge":"4319158"
         },
         {
            "nome":"SAO NICOLAU",
            "codigo_ibge":"4319208"
         },
         {
            "nome":"SAO PAULO DAS MISSOES",
            "codigo_ibge":"4319307"
         },
         {
            "nome":"SAO PEDRO DA SERRA",
            "codigo_ibge":"4319356"
         },
         {
            "nome":"SAO PEDRO DAS MISSOES",
            "codigo_ibge":"4319364"
         },
         {
            "nome":"SAO PEDRO DO BUTIA",
            "codigo_ibge":"4319372"
         },
         {
            "nome":"SAO PEDRO DO SUL",
            "codigo_ibge":"4319406"
         },
         {
            "nome":"SAO SEBASTIAO DO CAI",
            "codigo_ibge":"4319505"
         },
         {
            "nome":"SAO SEPE",
            "codigo_ibge":"4319604"
         },
         {
            "nome":"SAO VALENTIM",
            "codigo_ibge":"4319703"
         },
         {
            "nome":"SAO VALENTIM DO SUL",
            "codigo_ibge":"4319711"
         },
         {
            "nome":"SAO VALERIO DO SUL",
            "codigo_ibge":"4319737"
         },
         {
            "nome":"SAO VENDELINO",
            "codigo_ibge":"4319752"
         },
         {
            "nome":"SAO VICENTE DO SUL",
            "codigo_ibge":"4319802"
         },
         {
            "nome":"SAPIRANGA",
            "codigo_ibge":"4319901"
         },
         {
            "nome":"SAPUCAIA DO SUL",
            "codigo_ibge":"4320008"
         },
         {
            "nome":"SARANDI",
            "codigo_ibge":"4320107"
         },
         {
            "nome":"SEBERI",
            "codigo_ibge":"4320206"
         },
         {
            "nome":"SEDE NOVA",
            "codigo_ibge":"4320230"
         },
         {
            "nome":"SEGREDO",
            "codigo_ibge":"4320263"
         },
         {
            "nome":"SELBACH",
            "codigo_ibge":"4320305"
         },
         {
            "nome":"SENADOR SALGADO FILHO",
            "codigo_ibge":"4320321"
         },
         {
            "nome":"SENTINELA DO SUL",
            "codigo_ibge":"4320354"
         },
         {
            "nome":"SERAFINA CORREA",
            "codigo_ibge":"4320404"
         },
         {
            "nome":"SERIO",
            "codigo_ibge":"4320453"
         },
         {
            "nome":"SERTAO",
            "codigo_ibge":"4320503"
         },
         {
            "nome":"SERTAO SANTANA",
            "codigo_ibge":"4320552"
         },
         {
            "nome":"SETE DE SETEMBRO",
            "codigo_ibge":"4320578"
         },
         {
            "nome":"SEVERIANO DE ALMEIDA",
            "codigo_ibge":"4320602"
         },
         {
            "nome":"SILVEIRA MARTINS",
            "codigo_ibge":"4320651"
         },
         {
            "nome":"SINIMBU",
            "codigo_ibge":"4320677"
         },
         {
            "nome":"SOBRADINHO",
            "codigo_ibge":"4320701"
         },
         {
            "nome":"SOLEDADE",
            "codigo_ibge":"4320800"
         },
         {
            "nome":"TABAI",
            "codigo_ibge":"4320859"
         },
         {
            "nome":"TAPEJARA",
            "codigo_ibge":"4320909"
         },
         {
            "nome":"TAPERA",
            "codigo_ibge":"4321006"
         },
         {
            "nome":"TAPES",
            "codigo_ibge":"4321105"
         },
         {
            "nome":"TAQUARA",
            "codigo_ibge":"4321204"
         },
         {
            "nome":"TAQUARI",
            "codigo_ibge":"4321303"
         },
         {
            "nome":"TAQUARUCU DO SUL",
            "codigo_ibge":"4321329"
         },
         {
            "nome":"TAVARES",
            "codigo_ibge":"4321352"
         },
         {
            "nome":"TENENTE PORTELA",
            "codigo_ibge":"4321402"
         },
         {
            "nome":"TERRA DE AREIA",
            "codigo_ibge":"4321436"
         },
         {
            "nome":"TEUTONIA",
            "codigo_ibge":"4321451"
         },
         {
            "nome":"TIO HUGO",
            "codigo_ibge":"4321469"
         },
         {
            "nome":"TIRADENTES DO SUL",
            "codigo_ibge":"4321477"
         },
         {
            "nome":"TOROPI",
            "codigo_ibge":"4321493"
         },
         {
            "nome":"TORRES",
            "codigo_ibge":"4321501"
         },
         {
            "nome":"TRAMANDAI",
            "codigo_ibge":"4321600"
         },
         {
            "nome":"TRAVESSEIRO",
            "codigo_ibge":"4321626"
         },
         {
            "nome":"TRES ARROIOS",
            "codigo_ibge":"4321634"
         },
         {
            "nome":"TRES CACHOEIRAS",
            "codigo_ibge":"4321667"
         },
         {
            "nome":"TRES COROAS",
            "codigo_ibge":"4321709"
         },
         {
            "nome":"TRES DE MAIO",
            "codigo_ibge":"4321808"
         },
         {
            "nome":"TRES FORQUILHAS",
            "codigo_ibge":"4321832"
         },
         {
            "nome":"TRES PALMEIRAS",
            "codigo_ibge":"4321857"
         },
         {
            "nome":"TRES PASSOS",
            "codigo_ibge":"4321907"
         },
         {
            "nome":"TRINDADE DO SUL",
            "codigo_ibge":"4321956"
         },
         {
            "nome":"TRIUNFO",
            "codigo_ibge":"4322004"
         },
         {
            "nome":"TUCUNDUVA",
            "codigo_ibge":"4322103"
         },
         {
            "nome":"TUNAS",
            "codigo_ibge":"4322152"
         },
         {
            "nome":"TUPANCI DO SUL",
            "codigo_ibge":"4322186"
         },
         {
            "nome":"TUPANCIRETA",
            "codigo_ibge":"4322202"
         },
         {
            "nome":"TUPANDI",
            "codigo_ibge":"4322251"
         },
         {
            "nome":"TUPARENDI",
            "codigo_ibge":"4322301"
         },
         {
            "nome":"TURUCU",
            "codigo_ibge":"4322327"
         },
         {
            "nome":"UBIRETAMA",
            "codigo_ibge":"4322343"
         },
         {
            "nome":"UNIAO DA SERRA",
            "codigo_ibge":"4322350"
         },
         {
            "nome":"UNISTALDA",
            "codigo_ibge":"4322376"
         },
         {
            "nome":"URUGUAIANA",
            "codigo_ibge":"4322400"
         },
         {
            "nome":"VACARIA",
            "codigo_ibge":"4322509"
         },
         {
            "nome":"VALE VERDE",
            "codigo_ibge":"4322525"
         },
         {
            "nome":"VALE DO SOL",
            "codigo_ibge":"4322533"
         },
         {
            "nome":"VALE REAL",
            "codigo_ibge":"4322541"
         },
         {
            "nome":"VANINI",
            "codigo_ibge":"4322558"
         },
         {
            "nome":"VENANCIO AIRES",
            "codigo_ibge":"4322608"
         },
         {
            "nome":"VERA CRUZ",
            "codigo_ibge":"4322707"
         },
         {
            "nome":"VERANOPOLIS",
            "codigo_ibge":"4322806"
         },
         {
            "nome":"VESPASIANO CORREA",
            "codigo_ibge":"4322855"
         },
         {
            "nome":"VIADUTOS",
            "codigo_ibge":"4322905"
         },
         {
            "nome":"VIAMAO",
            "codigo_ibge":"4323002"
         },
         {
            "nome":"VICENTE DUTRA",
            "codigo_ibge":"4323101"
         },
         {
            "nome":"VICTOR GRAEFF",
            "codigo_ibge":"4323200"
         },
         {
            "nome":"VILA FLORES",
            "codigo_ibge":"4323309"
         },
         {
            "nome":"VILA LANGARO",
            "codigo_ibge":"4323358"
         },
         {
            "nome":"VILA MARIA",
            "codigo_ibge":"4323408"
         },
         {
            "nome":"VILA NOVA DO SUL",
            "codigo_ibge":"4323457"
         },
         {
            "nome":"VISTA ALEGRE",
            "codigo_ibge":"4323507"
         },
         {
            "nome":"VISTA ALEGRE DO PRATA",
            "codigo_ibge":"4323606"
         },
         {
            "nome":"VISTA GAUCHA",
            "codigo_ibge":"4323705"
         },
         {
            "nome":"VITORIA DAS MISSOES",
            "codigo_ibge":"4323754"
         },
         {
            "nome":"WESTFALIA",
            "codigo_ibge":"4323770"
         },
         {
            "nome":"XANGRI-LA",
            "codigo_ibge":"4323804"
         }
      ]
   },
   {
      "estado" : "RN",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ACARI",
            "codigo_ibge":"2400109"
         },
         {
            "nome":"ACU",
            "codigo_ibge":"2400208"
         },
         {
            "nome":"AFONSO BEZERRA",
            "codigo_ibge":"2400307"
         },
         {
            "nome":"AGUA NOVA",
            "codigo_ibge":"2400406"
         },
         {
            "nome":"ALEXANDRIA",
            "codigo_ibge":"2400505"
         },
         {
            "nome":"ALMINO AFONSO",
            "codigo_ibge":"2400604"
         },
         {
            "nome":"ALTO DO RODRIGUES",
            "codigo_ibge":"2400703"
         },
         {
            "nome":"ANGICOS",
            "codigo_ibge":"2400802"
         },
         {
            "nome":"ANTONIO MARTINS",
            "codigo_ibge":"2400901"
         },
         {
            "nome":"APODI",
            "codigo_ibge":"2401008"
         },
         {
            "nome":"AREIA BRANCA",
            "codigo_ibge":"2401107"
         },
         {
            "nome":"ARES",
            "codigo_ibge":"2401206"
         },
         {
            "nome":"AUGUSTO SEVERO",
            "codigo_ibge":"2401305"
         },
         {
            "nome":"BAIA FORMOSA",
            "codigo_ibge":"2401404"
         },
         {
            "nome":"BARAUNA",
            "codigo_ibge":"2401453"
         },
         {
            "nome":"BARCELONA",
            "codigo_ibge":"2401503"
         },
         {
            "nome":"BENTO FERNANDES",
            "codigo_ibge":"2401602"
         },
         {
            "nome":"BODO",
            "codigo_ibge":"2401651"
         },
         {
            "nome":"BOM JESUS",
            "codigo_ibge":"2401701"
         },
         {
            "nome":"BREJINHO",
            "codigo_ibge":"2401800"
         },
         {
            "nome":"CAICARA DO NORTE",
            "codigo_ibge":"2401859"
         },
         {
            "nome":"CAICARA DO RIO DO VENTO",
            "codigo_ibge":"2401909"
         },
         {
            "nome":"CAICO",
            "codigo_ibge":"2402006"
         },
         {
            "nome":"CAMPO REDONDO",
            "codigo_ibge":"2402105"
         },
         {
            "nome":"CANGUARETAMA",
            "codigo_ibge":"2402204"
         },
         {
            "nome":"CARAUBAS",
            "codigo_ibge":"2402303"
         },
         {
            "nome":"CARNAUBA DOS DANTAS",
            "codigo_ibge":"2402402"
         },
         {
            "nome":"CARNAUBAIS",
            "codigo_ibge":"2402501"
         },
         {
            "nome":"CEARA-MIRIM",
            "codigo_ibge":"2402600"
         },
         {
            "nome":"CERRO CORA",
            "codigo_ibge":"2402709"
         },
         {
            "nome":"CORONEL EZEQUIEL",
            "codigo_ibge":"2402808"
         },
         {
            "nome":"CORONEL JOAO PESSOA",
            "codigo_ibge":"2402907"
         },
         {
            "nome":"CRUZETA",
            "codigo_ibge":"2403004"
         },
         {
            "nome":"CURRAIS NOVOS",
            "codigo_ibge":"2403103"
         },
         {
            "nome":"DOUTOR SEVERIANO",
            "codigo_ibge":"2403202"
         },
         {
            "nome":"PARNAMIRIM",
            "codigo_ibge":"2403251"
         },
         {
            "nome":"ENCANTO",
            "codigo_ibge":"2403301"
         },
         {
            "nome":"EQUADOR",
            "codigo_ibge":"2403400"
         },
         {
            "nome":"ESPIRITO SANTO",
            "codigo_ibge":"2403509"
         },
         {
            "nome":"EXTREMOZ",
            "codigo_ibge":"2403608"
         },
         {
            "nome":"FELIPE GUERRA",
            "codigo_ibge":"2403707"
         },
         {
            "nome":"FERNANDO PEDROZA",
            "codigo_ibge":"2403756"
         },
         {
            "nome":"FLORANIA",
            "codigo_ibge":"2403806"
         },
         {
            "nome":"FRANCISCO DANTAS",
            "codigo_ibge":"2403905"
         },
         {
            "nome":"FRUTUOSO GOMES",
            "codigo_ibge":"2404002"
         },
         {
            "nome":"GALINHOS",
            "codigo_ibge":"2404101"
         },
         {
            "nome":"GOIANINHA",
            "codigo_ibge":"2404200"
         },
         {
            "nome":"GOVERNADOR DIX-SEPT ROSADO",
            "codigo_ibge":"2404309"
         },
         {
            "nome":"GROSSOS",
            "codigo_ibge":"2404408"
         },
         {
            "nome":"GUAMARE",
            "codigo_ibge":"2404507"
         },
         {
            "nome":"IELMO MARINHO",
            "codigo_ibge":"2404606"
         },
         {
            "nome":"IPANGUACU",
            "codigo_ibge":"2404705"
         },
         {
            "nome":"IPUEIRA",
            "codigo_ibge":"2404804"
         },
         {
            "nome":"ITAJA",
            "codigo_ibge":"2404853"
         },
         {
            "nome":"ITAU",
            "codigo_ibge":"2404903"
         },
         {
            "nome":"JACANA",
            "codigo_ibge":"2405009"
         },
         {
            "nome":"JANDAIRA",
            "codigo_ibge":"2405108"
         },
         {
            "nome":"JANDUIS",
            "codigo_ibge":"2405207"
         },
         {
            "nome":"JANUARIO CICCO",
            "codigo_ibge":"2405306"
         },
         {
            "nome":"JAPI",
            "codigo_ibge":"2405405"
         },
         {
            "nome":"JARDIM DE ANGICOS",
            "codigo_ibge":"2405504"
         },
         {
            "nome":"JARDIM DE PIRANHAS",
            "codigo_ibge":"2405603"
         },
         {
            "nome":"JARDIM DO SERIDO",
            "codigo_ibge":"2405702"
         },
         {
            "nome":"JOAO CAMARA",
            "codigo_ibge":"2405801"
         },
         {
            "nome":"JOAO DIAS",
            "codigo_ibge":"2405900"
         },
         {
            "nome":"JOSE DA PENHA",
            "codigo_ibge":"2406007"
         },
         {
            "nome":"JUCURUTU",
            "codigo_ibge":"2406106"
         },
         {
            "nome":"JUNDIA",
            "codigo_ibge":"2406155"
         },
         {
            "nome":"LAGOA D ANTA",
            "codigo_ibge":"2406205"
         },
         {
            "nome":"LAGOA DE PEDRAS",
            "codigo_ibge":"2406304"
         },
         {
            "nome":"LAGOA DE VELHOS",
            "codigo_ibge":"2406403"
         },
         {
            "nome":"LAGOA NOVA",
            "codigo_ibge":"2406502"
         },
         {
            "nome":"LAGOA SALGADA",
            "codigo_ibge":"2406601"
         },
         {
            "nome":"LAJES",
            "codigo_ibge":"2406700"
         },
         {
            "nome":"LAJES PINTADAS",
            "codigo_ibge":"2406809"
         },
         {
            "nome":"LUCRECIA",
            "codigo_ibge":"2406908"
         },
         {
            "nome":"LUIS GOMES",
            "codigo_ibge":"2407005"
         },
         {
            "nome":"MACAIBA",
            "codigo_ibge":"2407104"
         },
         {
            "nome":"MACAU",
            "codigo_ibge":"2407203"
         },
         {
            "nome":"MAJOR SALES",
            "codigo_ibge":"2407252"
         },
         {
            "nome":"MARCELINO VIEIRA",
            "codigo_ibge":"2407302"
         },
         {
            "nome":"MARTINS",
            "codigo_ibge":"2407401"
         },
         {
            "nome":"MAXARANGUAPE",
            "codigo_ibge":"2407500"
         },
         {
            "nome":"MESSIAS TARGINO",
            "codigo_ibge":"2407609"
         },
         {
            "nome":"MONTANHAS",
            "codigo_ibge":"2407708"
         },
         {
            "nome":"MONTE ALEGRE",
            "codigo_ibge":"2407807"
         },
         {
            "nome":"MONTE DAS GAMELEIRAS",
            "codigo_ibge":"2407906"
         },
         {
            "nome":"MOSSORO",
            "codigo_ibge":"2408003"
         },
         {
            "nome":"NATAL",
            "codigo_ibge":"2408102"
         },
         {
            "nome":"NISIA FLORESTA",
            "codigo_ibge":"2408201"
         },
         {
            "nome":"NOVA CRUZ",
            "codigo_ibge":"2408300"
         },
         {
            "nome":"OLHO-D AGUA DO BORGES",
            "codigo_ibge":"2408409"
         },
         {
            "nome":"OURO BRANCO",
            "codigo_ibge":"2408508"
         },
         {
            "nome":"PARANA",
            "codigo_ibge":"2408607"
         },
         {
            "nome":"PARAU",
            "codigo_ibge":"2408706"
         },
         {
            "nome":"PARAZINHO",
            "codigo_ibge":"2408805"
         },
         {
            "nome":"PARELHAS",
            "codigo_ibge":"2408904"
         },
         {
            "nome":"RIO DO FOGO",
            "codigo_ibge":"2408953"
         },
         {
            "nome":"PASSA E FICA",
            "codigo_ibge":"2409100"
         },
         {
            "nome":"PASSAGEM",
            "codigo_ibge":"2409209"
         },
         {
            "nome":"PATU",
            "codigo_ibge":"2409308"
         },
         {
            "nome":"SANTA MARIA",
            "codigo_ibge":"2409332"
         },
         {
            "nome":"PAU DOS FERROS",
            "codigo_ibge":"2409407"
         },
         {
            "nome":"PEDRA GRANDE",
            "codigo_ibge":"2409506"
         },
         {
            "nome":"PEDRA PRETA",
            "codigo_ibge":"2409605"
         },
         {
            "nome":"PEDRO AVELINO",
            "codigo_ibge":"2409704"
         },
         {
            "nome":"PEDRO VELHO",
            "codigo_ibge":"2409803"
         },
         {
            "nome":"PENDENCIAS",
            "codigo_ibge":"2409902"
         },
         {
            "nome":"PILOES",
            "codigo_ibge":"2410009"
         },
         {
            "nome":"POCO BRANCO",
            "codigo_ibge":"2410108"
         },
         {
            "nome":"PORTALEGRE",
            "codigo_ibge":"2410207"
         },
         {
            "nome":"PORTO DO MANGUE",
            "codigo_ibge":"2410256"
         },
         {
            "nome":"PRESIDENTE JUSCELINO",
            "codigo_ibge":"2410306"
         },
         {
            "nome":"PUREZA",
            "codigo_ibge":"2410405"
         },
         {
            "nome":"RAFAEL FERNANDES",
            "codigo_ibge":"2410504"
         },
         {
            "nome":"RAFAEL GODEIRO",
            "codigo_ibge":"2410603"
         },
         {
            "nome":"RIACHO DA CRUZ",
            "codigo_ibge":"2410702"
         },
         {
            "nome":"RIACHO DE SANTANA",
            "codigo_ibge":"2410801"
         },
         {
            "nome":"RIACHUELO",
            "codigo_ibge":"2410900"
         },
         {
            "nome":"RODOLFO FERNANDES",
            "codigo_ibge":"2411007"
         },
         {
            "nome":"TIBAU",
            "codigo_ibge":"2411056"
         },
         {
            "nome":"RUY BARBOSA",
            "codigo_ibge":"2411106"
         },
         {
            "nome":"SANTA CRUZ",
            "codigo_ibge":"2411205"
         },
         {
            "nome":"SANTANA DO MATOS",
            "codigo_ibge":"2411403"
         },
         {
            "nome":"SANTANA DO SERIDO",
            "codigo_ibge":"2411429"
         },
         {
            "nome":"SANTO ANTONIO",
            "codigo_ibge":"2411502"
         },
         {
            "nome":"SAO BENTO DO NORTE",
            "codigo_ibge":"2411601"
         },
         {
            "nome":"SAO BENTO DO TRAIRI",
            "codigo_ibge":"2411700"
         },
         {
            "nome":"SAO FERNANDO",
            "codigo_ibge":"2411809"
         },
         {
            "nome":"SAO FRANCISCO DO OESTE",
            "codigo_ibge":"2411908"
         },
         {
            "nome":"SAO GONCALO DO AMARANTE",
            "codigo_ibge":"2412005"
         },
         {
            "nome":"SAO JOAO DO SABUGI",
            "codigo_ibge":"2412104"
         },
         {
            "nome":"SAO JOSE DE MIPIBU",
            "codigo_ibge":"2412203"
         },
         {
            "nome":"SAO JOSE DO CAMPESTRE",
            "codigo_ibge":"2412302"
         },
         {
            "nome":"SAO JOSE DO SERIDO",
            "codigo_ibge":"2412401"
         },
         {
            "nome":"SAO MIGUEL",
            "codigo_ibge":"2412500"
         },
         {
            "nome":"SAO MIGUEL DO GOSTOSO",
            "codigo_ibge":"2412559"
         },
         {
            "nome":"SAO PAULO DO POTENGI",
            "codigo_ibge":"2412609"
         },
         {
            "nome":"SAO PEDRO",
            "codigo_ibge":"2412708"
         },
         {
            "nome":"SAO RAFAEL",
            "codigo_ibge":"2412807"
         },
         {
            "nome":"SAO TOME",
            "codigo_ibge":"2412906"
         },
         {
            "nome":"SAO VICENTE",
            "codigo_ibge":"2413003"
         },
         {
            "nome":"SENADOR ELOI DE SOUZA",
            "codigo_ibge":"2413102"
         },
         {
            "nome":"SENADOR GEORGINO AVELINO",
            "codigo_ibge":"2413201"
         },
         {
            "nome":"SERRA DE SAO BENTO",
            "codigo_ibge":"2413300"
         },
         {
            "nome":"SERRA DO MEL",
            "codigo_ibge":"2413359"
         },
         {
            "nome":"SERRA NEGRA DO NORTE",
            "codigo_ibge":"2413409"
         },
         {
            "nome":"SERRINHA",
            "codigo_ibge":"2413508"
         },
         {
            "nome":"SERRINHA DOS PINTOS",
            "codigo_ibge":"2413557"
         },
         {
            "nome":"SEVERIANO MELO",
            "codigo_ibge":"2413607"
         },
         {
            "nome":"SITIO NOVO",
            "codigo_ibge":"2413706"
         },
         {
            "nome":"TABOLEIRO GRANDE",
            "codigo_ibge":"2413805"
         },
         {
            "nome":"TAIPU",
            "codigo_ibge":"2413904"
         },
         {
            "nome":"TANGARA",
            "codigo_ibge":"2414001"
         },
         {
            "nome":"TENENTE ANANIAS",
            "codigo_ibge":"2414100"
         },
         {
            "nome":"TENENTE LAURENTINO CRUZ",
            "codigo_ibge":"2414159"
         },
         {
            "nome":"TIBAU DO SUL",
            "codigo_ibge":"2414209"
         },
         {
            "nome":"TIMBAUBA DOS BATISTAS",
            "codigo_ibge":"2414308"
         },
         {
            "nome":"TOUROS",
            "codigo_ibge":"2414407"
         },
         {
            "nome":"TRIUNFO POTIGUAR",
            "codigo_ibge":"2414456"
         },
         {
            "nome":"UMARIZAL",
            "codigo_ibge":"2414506"
         },
         {
            "nome":"UPANEMA",
            "codigo_ibge":"2414605"
         },
         {
            "nome":"VARZEA",
            "codigo_ibge":"2414704"
         },
         {
            "nome":"VENHA-VER",
            "codigo_ibge":"2414753"
         },
         {
            "nome":"VERA CRUZ",
            "codigo_ibge":"2414803"
         },
         {
            "nome":"VICOSA",
            "codigo_ibge":"2414902"
         },
         {
            "nome":"VILA FLOR",
            "codigo_ibge":"2415008"
         }
      ]
   },
   {
      "estado" : "PI",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ACAUA",
            "codigo_ibge":"2200053"
         },
         {
            "nome":"AGRICOLANDIA",
            "codigo_ibge":"2200103"
         },
         {
            "nome":"AGUA BRANCA",
            "codigo_ibge":"2200202"
         },
         {
            "nome":"ALAGOINHA DO PIAUI",
            "codigo_ibge":"2200251"
         },
         {
            "nome":"ALEGRETE DO PIAUI",
            "codigo_ibge":"2200277"
         },
         {
            "nome":"ALTO LONGA",
            "codigo_ibge":"2200301"
         },
         {
            "nome":"ALTOS",
            "codigo_ibge":"2200400"
         },
         {
            "nome":"ALVORADA DO GURGUEIA",
            "codigo_ibge":"2200459"
         },
         {
            "nome":"AMARANTE",
            "codigo_ibge":"2200509"
         },
         {
            "nome":"ANGICAL DO PIAUI",
            "codigo_ibge":"2200608"
         },
         {
            "nome":"ANISIO DE ABREU",
            "codigo_ibge":"2200707"
         },
         {
            "nome":"ANTONIO ALMEIDA",
            "codigo_ibge":"2200806"
         },
         {
            "nome":"AROAZES",
            "codigo_ibge":"2200905"
         },
         {
            "nome":"AROEIRAS DO ITAIM",
            "codigo_ibge":"2200954"
         },
         {
            "nome":"ARRAIAL",
            "codigo_ibge":"2201002"
         },
         {
            "nome":"ASSUNCAO DO PIAUI",
            "codigo_ibge":"2201051"
         },
         {
            "nome":"AVELINO LOPES",
            "codigo_ibge":"2201101"
         },
         {
            "nome":"BAIXA GRANDE DO RIBEIRO",
            "codigo_ibge":"2201150"
         },
         {
            "nome":"BARRA D ALCANTARA",
            "codigo_ibge":"2201176"
         },
         {
            "nome":"BARRAS",
            "codigo_ibge":"2201200"
         },
         {
            "nome":"BARREIRAS DO PIAUI",
            "codigo_ibge":"2201309"
         },
         {
            "nome":"BARRO DURO",
            "codigo_ibge":"2201408"
         },
         {
            "nome":"BATALHA",
            "codigo_ibge":"2201507"
         },
         {
            "nome":"BELA VISTA DO PIAUI",
            "codigo_ibge":"2201556"
         },
         {
            "nome":"BELEM DO PIAUI",
            "codigo_ibge":"2201572"
         },
         {
            "nome":"BENEDITINOS",
            "codigo_ibge":"2201606"
         },
         {
            "nome":"BERTOLINIA",
            "codigo_ibge":"2201705"
         },
         {
            "nome":"BETANIA DO PIAUI",
            "codigo_ibge":"2201739"
         },
         {
            "nome":"BOA HORA",
            "codigo_ibge":"2201770"
         },
         {
            "nome":"BOCAINA",
            "codigo_ibge":"2201804"
         },
         {
            "nome":"BOM JESUS",
            "codigo_ibge":"2201903"
         },
         {
            "nome":"BOM PRINCIPIO DO PIAUI",
            "codigo_ibge":"2201919"
         },
         {
            "nome":"BONFIM DO PIAUI",
            "codigo_ibge":"2201929"
         },
         {
            "nome":"BOQUEIRAO DO PIAUI",
            "codigo_ibge":"2201945"
         },
         {
            "nome":"BRASILEIRA",
            "codigo_ibge":"2201960"
         },
         {
            "nome":"BREJO DO PIAUI",
            "codigo_ibge":"2201988"
         },
         {
            "nome":"BURITI DOS LOPES",
            "codigo_ibge":"2202000"
         },
         {
            "nome":"BURITI DOS MONTES",
            "codigo_ibge":"2202026"
         },
         {
            "nome":"CABECEIRAS DO PIAUI",
            "codigo_ibge":"2202059"
         },
         {
            "nome":"CAJAZEIRAS DO PIAUI",
            "codigo_ibge":"2202075"
         },
         {
            "nome":"CAJUEIRO DA PRAIA",
            "codigo_ibge":"2202083"
         },
         {
            "nome":"CALDEIRAO GRANDE DO PIAUI",
            "codigo_ibge":"2202091"
         },
         {
            "nome":"CAMPINAS DO PIAUI",
            "codigo_ibge":"2202109"
         },
         {
            "nome":"CAMPO ALEGRE DO FIDALGO",
            "codigo_ibge":"2202117"
         },
         {
            "nome":"CAMPO GRANDE DO PIAUI",
            "codigo_ibge":"2202133"
         },
         {
            "nome":"CAMPO LARGO DO PIAUI",
            "codigo_ibge":"2202174"
         },
         {
            "nome":"CAMPO MAIOR",
            "codigo_ibge":"2202208"
         },
         {
            "nome":"CANAVIEIRA",
            "codigo_ibge":"2202251"
         },
         {
            "nome":"CANTO DO BURITI",
            "codigo_ibge":"2202307"
         },
         {
            "nome":"CAPITAO DE CAMPOS",
            "codigo_ibge":"2202406"
         },
         {
            "nome":"CAPITAO GERVASIO OLIVEIRA",
            "codigo_ibge":"2202455"
         },
         {
            "nome":"CARACOL",
            "codigo_ibge":"2202505"
         },
         {
            "nome":"CARAUBAS DO PIAUI",
            "codigo_ibge":"2202539"
         },
         {
            "nome":"CARIDADE DO PIAUI",
            "codigo_ibge":"2202554"
         },
         {
            "nome":"CASTELO DO PIAUI",
            "codigo_ibge":"2202604"
         },
         {
            "nome":"CAXINGO",
            "codigo_ibge":"2202653"
         },
         {
            "nome":"COCAL",
            "codigo_ibge":"2202703"
         },
         {
            "nome":"COCAL DE TELHA",
            "codigo_ibge":"2202711"
         },
         {
            "nome":"COCAL DOS ALVES",
            "codigo_ibge":"2202729"
         },
         {
            "nome":"COIVARAS",
            "codigo_ibge":"2202737"
         },
         {
            "nome":"COLONIA DO GURGUEIA",
            "codigo_ibge":"2202752"
         },
         {
            "nome":"COLONIA DO PIAUI",
            "codigo_ibge":"2202778"
         },
         {
            "nome":"CONCEICAO DO CANINDE",
            "codigo_ibge":"2202802"
         },
         {
            "nome":"CORONEL JOSE DIAS",
            "codigo_ibge":"2202851"
         },
         {
            "nome":"CORRENTE",
            "codigo_ibge":"2202901"
         },
         {
            "nome":"CRISTALANDIA DO PIAUI",
            "codigo_ibge":"2203008"
         },
         {
            "nome":"CRISTINO CASTRO",
            "codigo_ibge":"2203107"
         },
         {
            "nome":"CURIMATA",
            "codigo_ibge":"2203206"
         },
         {
            "nome":"CURRAIS",
            "codigo_ibge":"2203230"
         },
         {
            "nome":"CURRALINHOS",
            "codigo_ibge":"2203255"
         },
         {
            "nome":"CURRAL NOVO DO PIAUI",
            "codigo_ibge":"2203271"
         },
         {
            "nome":"DEMERVAL LOBAO",
            "codigo_ibge":"2203305"
         },
         {
            "nome":"DIRCEU ARCOVERDE",
            "codigo_ibge":"2203354"
         },
         {
            "nome":"DOM EXPEDITO LOPES",
            "codigo_ibge":"2203404"
         },
         {
            "nome":"DOMINGOS MOURAO",
            "codigo_ibge":"2203420"
         },
         {
            "nome":"DOM INOCENCIO",
            "codigo_ibge":"2203453"
         },
         {
            "nome":"ELESBAO VELOSO",
            "codigo_ibge":"2203503"
         },
         {
            "nome":"ELISEU MARTINS",
            "codigo_ibge":"2203602"
         },
         {
            "nome":"ESPERANTINA",
            "codigo_ibge":"2203701"
         },
         {
            "nome":"FARTURA DO PIAUI",
            "codigo_ibge":"2203750"
         },
         {
            "nome":"FLORES DO PIAUI",
            "codigo_ibge":"2203800"
         },
         {
            "nome":"FLORESTA DO PIAUI",
            "codigo_ibge":"2203859"
         },
         {
            "nome":"FLORIANO",
            "codigo_ibge":"2203909"
         },
         {
            "nome":"FRANCINOPOLIS",
            "codigo_ibge":"2204006"
         },
         {
            "nome":"FRANCISCO AYRES",
            "codigo_ibge":"2204105"
         },
         {
            "nome":"FRANCISCO MACEDO",
            "codigo_ibge":"2204154"
         },
         {
            "nome":"FRANCISCO SANTOS",
            "codigo_ibge":"2204204"
         },
         {
            "nome":"FRONTEIRAS",
            "codigo_ibge":"2204303"
         },
         {
            "nome":"GEMINIANO",
            "codigo_ibge":"2204352"
         },
         {
            "nome":"GILBUES",
            "codigo_ibge":"2204402"
         },
         {
            "nome":"GUADALUPE",
            "codigo_ibge":"2204501"
         },
         {
            "nome":"GUARIBAS",
            "codigo_ibge":"2204550"
         },
         {
            "nome":"HUGO NAPOLEAO",
            "codigo_ibge":"2204600"
         },
         {
            "nome":"ILHA GRANDE",
            "codigo_ibge":"2204659"
         },
         {
            "nome":"INHUMA",
            "codigo_ibge":"2204709"
         },
         {
            "nome":"IPIRANGA DO PIAUI",
            "codigo_ibge":"2204808"
         },
         {
            "nome":"ISAIAS COELHO",
            "codigo_ibge":"2204907"
         },
         {
            "nome":"ITAINOPOLIS",
            "codigo_ibge":"2205003"
         },
         {
            "nome":"ITAUEIRA",
            "codigo_ibge":"2205102"
         },
         {
            "nome":"JACOBINA DO PIAUI",
            "codigo_ibge":"2205151"
         },
         {
            "nome":"JAICOS",
            "codigo_ibge":"2205201"
         },
         {
            "nome":"JARDIM DO MULATO",
            "codigo_ibge":"2205250"
         },
         {
            "nome":"JATOBA DO PIAUI",
            "codigo_ibge":"2205276"
         },
         {
            "nome":"JERUMENHA",
            "codigo_ibge":"2205300"
         },
         {
            "nome":"JOAO COSTA",
            "codigo_ibge":"2205359"
         },
         {
            "nome":"JOAQUIM PIRES",
            "codigo_ibge":"2205409"
         },
         {
            "nome":"JOCA MARQUES",
            "codigo_ibge":"2205458"
         },
         {
            "nome":"JOSE DE FREITAS",
            "codigo_ibge":"2205508"
         },
         {
            "nome":"JUAZEIRO DO PIAUI",
            "codigo_ibge":"2205516"
         },
         {
            "nome":"JULIO BORGES",
            "codigo_ibge":"2205524"
         },
         {
            "nome":"JUREMA",
            "codigo_ibge":"2205532"
         },
         {
            "nome":"LAGOINHA DO PIAUI",
            "codigo_ibge":"2205540"
         },
         {
            "nome":"LAGOA ALEGRE",
            "codigo_ibge":"2205557"
         },
         {
            "nome":"LAGOA DO BARRO DO PIAUI",
            "codigo_ibge":"2205565"
         },
         {
            "nome":"LAGOA DE SAO FRANCISCO",
            "codigo_ibge":"2205573"
         },
         {
            "nome":"LAGOA DO PIAUI",
            "codigo_ibge":"2205581"
         },
         {
            "nome":"LAGOA DO SITIO",
            "codigo_ibge":"2205599"
         },
         {
            "nome":"LANDRI SALES",
            "codigo_ibge":"2205607"
         },
         {
            "nome":"LUIS CORREIA",
            "codigo_ibge":"2205706"
         },
         {
            "nome":"LUZILANDIA",
            "codigo_ibge":"2205805"
         },
         {
            "nome":"MADEIRO",
            "codigo_ibge":"2205854"
         },
         {
            "nome":"MANOEL EMIDIO",
            "codigo_ibge":"2205904"
         },
         {
            "nome":"MARCOLANDIA",
            "codigo_ibge":"2205953"
         },
         {
            "nome":"MARCOS PARENTE",
            "codigo_ibge":"2206001"
         },
         {
            "nome":"MASSAPE DO PIAUI",
            "codigo_ibge":"2206050"
         },
         {
            "nome":"MATIAS OLIMPIO",
            "codigo_ibge":"2206100"
         },
         {
            "nome":"MIGUEL ALVES",
            "codigo_ibge":"2206209"
         },
         {
            "nome":"MIGUEL LEAO",
            "codigo_ibge":"2206308"
         },
         {
            "nome":"MILTON BRANDAO",
            "codigo_ibge":"2206357"
         },
         {
            "nome":"MONSENHOR GIL",
            "codigo_ibge":"2206407"
         },
         {
            "nome":"MONSENHOR HIPOLITO",
            "codigo_ibge":"2206506"
         },
         {
            "nome":"MONTE ALEGRE DO PIAUI",
            "codigo_ibge":"2206605"
         },
         {
            "nome":"MORRO CABECA NO TEMPO",
            "codigo_ibge":"2206654"
         },
         {
            "nome":"MORRO DO CHAPEU DO PIAUI",
            "codigo_ibge":"2206670"
         },
         {
            "nome":"MURICI DOS PORTELAS",
            "codigo_ibge":"2206696"
         },
         {
            "nome":"NAZARE DO PIAUI",
            "codigo_ibge":"2206704"
         },
         {
            "nome":"NAZARIA",
            "codigo_ibge":"2206720"
         },
         {
            "nome":"NOSSA SENHORA DE NAZARE",
            "codigo_ibge":"2206753"
         },
         {
            "nome":"NOSSA SENHORA DOS REMEDIOS",
            "codigo_ibge":"2206803"
         },
         {
            "nome":"NOVO ORIENTE DO PIAUI",
            "codigo_ibge":"2206902"
         },
         {
            "nome":"NOVO SANTO ANTONIO",
            "codigo_ibge":"2206951"
         },
         {
            "nome":"OEIRAS",
            "codigo_ibge":"2207009"
         },
         {
            "nome":"OLHO D AGUA DO PIAUI",
            "codigo_ibge":"2207108"
         },
         {
            "nome":"PADRE MARCOS",
            "codigo_ibge":"2207207"
         },
         {
            "nome":"PAES LANDIM",
            "codigo_ibge":"2207306"
         },
         {
            "nome":"PAJEU DO PIAUI",
            "codigo_ibge":"2207355"
         },
         {
            "nome":"PALMEIRA DO PIAUI",
            "codigo_ibge":"2207405"
         },
         {
            "nome":"PALMEIRAIS",
            "codigo_ibge":"2207504"
         },
         {
            "nome":"PAQUETA",
            "codigo_ibge":"2207553"
         },
         {
            "nome":"PARNAGUA",
            "codigo_ibge":"2207603"
         },
         {
            "nome":"PARNAIBA",
            "codigo_ibge":"2207702"
         },
         {
            "nome":"PASSAGEM FRANCA DO PIAUI",
            "codigo_ibge":"2207751"
         },
         {
            "nome":"PATOS DO PIAUI",
            "codigo_ibge":"2207777"
         },
         {
            "nome":"PAU D ARCO DO PIAUI",
            "codigo_ibge":"2207793"
         },
         {
            "nome":"PAULISTANA",
            "codigo_ibge":"2207801"
         },
         {
            "nome":"PAVUSSU",
            "codigo_ibge":"2207850"
         },
         {
            "nome":"PEDRO II",
            "codigo_ibge":"2207900"
         },
         {
            "nome":"PEDRO LAURENTINO",
            "codigo_ibge":"2207934"
         },
         {
            "nome":"NOVA SANTA RITA",
            "codigo_ibge":"2207959"
         },
         {
            "nome":"PICOS",
            "codigo_ibge":"2208007"
         },
         {
            "nome":"PIMENTEIRAS",
            "codigo_ibge":"2208106"
         },
         {
            "nome":"PIO IX",
            "codigo_ibge":"2208205"
         },
         {
            "nome":"PIRACURUCA",
            "codigo_ibge":"2208304"
         },
         {
            "nome":"PIRIPIRI",
            "codigo_ibge":"2208403"
         },
         {
            "nome":"PORTO",
            "codigo_ibge":"2208502"
         },
         {
            "nome":"PORTO ALEGRE DO PIAUI",
            "codigo_ibge":"2208551"
         },
         {
            "nome":"PRATA DO PIAUI",
            "codigo_ibge":"2208601"
         },
         {
            "nome":"QUEIMADA NOVA",
            "codigo_ibge":"2208650"
         },
         {
            "nome":"REDENCAO DO GURGUEIA",
            "codigo_ibge":"2208700"
         },
         {
            "nome":"REGENERACAO",
            "codigo_ibge":"2208809"
         },
         {
            "nome":"RIACHO FRIO",
            "codigo_ibge":"2208858"
         },
         {
            "nome":"RIBEIRA DO PIAUI",
            "codigo_ibge":"2208874"
         },
         {
            "nome":"RIBEIRO GONCALVES",
            "codigo_ibge":"2208908"
         },
         {
            "nome":"RIO GRANDE DO PIAUI",
            "codigo_ibge":"2209005"
         },
         {
            "nome":"SANTA CRUZ DO PIAUI",
            "codigo_ibge":"2209104"
         },
         {
            "nome":"SANTA CRUZ DOS MILAGRES",
            "codigo_ibge":"2209153"
         },
         {
            "nome":"SANTA FILOMENA",
            "codigo_ibge":"2209203"
         },
         {
            "nome":"SANTA LUZ",
            "codigo_ibge":"2209302"
         },
         {
            "nome":"SANTANA DO PIAUI",
            "codigo_ibge":"2209351"
         },
         {
            "nome":"SANTA ROSA DO PIAUI",
            "codigo_ibge":"2209377"
         },
         {
            "nome":"SANTO ANTONIO DE LISBOA",
            "codigo_ibge":"2209401"
         },
         {
            "nome":"SANTO ANTONIO DOS MILAGRES",
            "codigo_ibge":"2209450"
         },
         {
            "nome":"SANTO INACIO DO PIAUI",
            "codigo_ibge":"2209500"
         },
         {
            "nome":"SAO BRAZ DO PIAUI",
            "codigo_ibge":"2209559"
         },
         {
            "nome":"SAO FELIX DO PIAUI",
            "codigo_ibge":"2209609"
         },
         {
            "nome":"SAO FRANCISCO DE ASSIS DO PIAUI",
            "codigo_ibge":"2209658"
         },
         {
            "nome":"SAO FRANCISCO DO PIAUI",
            "codigo_ibge":"2209708"
         },
         {
            "nome":"SAO GONCALO DO GURGUEIA",
            "codigo_ibge":"2209757"
         },
         {
            "nome":"SAO GONCALO DO PIAUI",
            "codigo_ibge":"2209807"
         },
         {
            "nome":"SAO JOAO DA CANABRAVA",
            "codigo_ibge":"2209856"
         },
         {
            "nome":"SAO JOAO DA FRONTEIRA",
            "codigo_ibge":"2209872"
         },
         {
            "nome":"SAO JOAO DA SERRA",
            "codigo_ibge":"2209906"
         },
         {
            "nome":"SAO JOAO DA VARJOTA",
            "codigo_ibge":"2209955"
         },
         {
            "nome":"SAO JOAO DO ARRAIAL",
            "codigo_ibge":"2209971"
         },
         {
            "nome":"SAO JOAO DO PIAUI",
            "codigo_ibge":"2210003"
         },
         {
            "nome":"SAO JOSE DO DIVINO",
            "codigo_ibge":"2210052"
         },
         {
            "nome":"SAO JOSE DO PEIXE",
            "codigo_ibge":"2210102"
         },
         {
            "nome":"SAO JOSE DO PIAUI",
            "codigo_ibge":"2210201"
         },
         {
            "nome":"SAO JULIAO",
            "codigo_ibge":"2210300"
         },
         {
            "nome":"SAO LOURENCO DO PIAUI",
            "codigo_ibge":"2210359"
         },
         {
            "nome":"SAO LUIS DO PIAUI",
            "codigo_ibge":"2210375"
         },
         {
            "nome":"SAO MIGUEL DA BAIXA GRANDE",
            "codigo_ibge":"2210383"
         },
         {
            "nome":"SAO MIGUEL DO FIDALGO",
            "codigo_ibge":"2210391"
         },
         {
            "nome":"SAO MIGUEL DO TAPUIO",
            "codigo_ibge":"2210409"
         },
         {
            "nome":"SAO PEDRO DO PIAUI",
            "codigo_ibge":"2210508"
         },
         {
            "nome":"SAO RAIMUNDO NONATO",
            "codigo_ibge":"2210607"
         },
         {
            "nome":"SEBASTIAO BARROS",
            "codigo_ibge":"2210623"
         },
         {
            "nome":"SEBASTIAO LEAL",
            "codigo_ibge":"2210631"
         },
         {
            "nome":"SIGEFREDO PACHECO",
            "codigo_ibge":"2210656"
         },
         {
            "nome":"SIMOES",
            "codigo_ibge":"2210706"
         },
         {
            "nome":"SIMPLICIO MENDES",
            "codigo_ibge":"2210805"
         },
         {
            "nome":"SOCORRO DO PIAUI",
            "codigo_ibge":"2210904"
         },
         {
            "nome":"SUSSUAPARA",
            "codigo_ibge":"2210938"
         },
         {
            "nome":"TAMBORIL DO PIAUI",
            "codigo_ibge":"2210953"
         },
         {
            "nome":"TANQUE DO PIAUI",
            "codigo_ibge":"2210979"
         },
         {
            "nome":"TERESINA",
            "codigo_ibge":"2211001"
         },
         {
            "nome":"UNIAO",
            "codigo_ibge":"2211100"
         },
         {
            "nome":"URUCUI",
            "codigo_ibge":"2211209"
         },
         {
            "nome":"VALENCA DO PIAUI",
            "codigo_ibge":"2211308"
         },
         {
            "nome":"VARZEA BRANCA",
            "codigo_ibge":"2211357"
         },
         {
            "nome":"VARZEA GRANDE",
            "codigo_ibge":"2211407"
         },
         {
            "nome":"VERA MENDES",
            "codigo_ibge":"2211506"
         },
         {
            "nome":"VILA NOVA DO PIAUI",
            "codigo_ibge":"2211605"
         },
         {
            "nome":"WALL FERRAZ",
            "codigo_ibge":"2211704"
         }
      ]
   },
   {
      "estado" : "PE",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ABREU E LIMA",
            "codigo_ibge":"2600054"
         },
         {
            "nome":"AFOGADOS DA INGAZEIRA",
            "codigo_ibge":"2600104"
         },
         {
            "nome":"AFRANIO",
            "codigo_ibge":"2600203"
         },
         {
            "nome":"AGRESTINA",
            "codigo_ibge":"2600302"
         },
         {
            "nome":"AGUA PRETA",
            "codigo_ibge":"2600401"
         },
         {
            "nome":"AGUAS BELAS",
            "codigo_ibge":"2600500"
         },
         {
            "nome":"ALAGOINHA",
            "codigo_ibge":"2600609"
         },
         {
            "nome":"ALIANCA",
            "codigo_ibge":"2600708"
         },
         {
            "nome":"ALTINHO",
            "codigo_ibge":"2600807"
         },
         {
            "nome":"AMARAJI",
            "codigo_ibge":"2600906"
         },
         {
            "nome":"ANGELIM",
            "codigo_ibge":"2601003"
         },
         {
            "nome":"ARACOIABA",
            "codigo_ibge":"2601052"
         },
         {
            "nome":"ARARIPINA",
            "codigo_ibge":"2601102"
         },
         {
            "nome":"ARCOVERDE",
            "codigo_ibge":"2601201"
         },
         {
            "nome":"BARRA DE GUABIRABA",
            "codigo_ibge":"2601300"
         },
         {
            "nome":"BARREIROS",
            "codigo_ibge":"2601409"
         },
         {
            "nome":"BELEM DE MARIA",
            "codigo_ibge":"2601508"
         },
         {
            "nome":"BELEM DO SAO FRANCISCO",
            "codigo_ibge":"2601607"
         },
         {
            "nome":"BELO JARDIM",
            "codigo_ibge":"2601706"
         },
         {
            "nome":"BETANIA",
            "codigo_ibge":"2601805"
         },
         {
            "nome":"BEZERROS",
            "codigo_ibge":"2601904"
         },
         {
            "nome":"BODOCO",
            "codigo_ibge":"2602001"
         },
         {
            "nome":"BOM CONSELHO",
            "codigo_ibge":"2602100"
         },
         {
            "nome":"BOM JARDIM",
            "codigo_ibge":"2602209"
         },
         {
            "nome":"BONITO",
            "codigo_ibge":"2602308"
         },
         {
            "nome":"BREJAO",
            "codigo_ibge":"2602407"
         },
         {
            "nome":"BREJINHO",
            "codigo_ibge":"2602506"
         },
         {
            "nome":"BREJO DA MADRE DE DEUS",
            "codigo_ibge":"2602605"
         },
         {
            "nome":"BUENOS AIRES",
            "codigo_ibge":"2602704"
         },
         {
            "nome":"BUIQUE",
            "codigo_ibge":"2602803"
         },
         {
            "nome":"CABO DE SANTO AGOSTINHO",
            "codigo_ibge":"2602902"
         },
         {
            "nome":"CABROBO",
            "codigo_ibge":"2603009"
         },
         {
            "nome":"CACHOEIRINHA",
            "codigo_ibge":"2603108"
         },
         {
            "nome":"CAETES",
            "codigo_ibge":"2603207"
         },
         {
            "nome":"CALCADO",
            "codigo_ibge":"2603306"
         },
         {
            "nome":"CALUMBI",
            "codigo_ibge":"2603405"
         },
         {
            "nome":"CAMARAGIBE",
            "codigo_ibge":"2603454"
         },
         {
            "nome":"CAMOCIM DE SAO FELIX",
            "codigo_ibge":"2603504"
         },
         {
            "nome":"CAMUTANGA",
            "codigo_ibge":"2603603"
         },
         {
            "nome":"CANHOTINHO",
            "codigo_ibge":"2603702"
         },
         {
            "nome":"CAPOEIRAS",
            "codigo_ibge":"2603801"
         },
         {
            "nome":"CARNAIBA",
            "codigo_ibge":"2603900"
         },
         {
            "nome":"CARNAUBEIRA DA PENHA",
            "codigo_ibge":"2603926"
         },
         {
            "nome":"CARPINA",
            "codigo_ibge":"2604007"
         },
         {
            "nome":"CARUARU",
            "codigo_ibge":"2604106"
         },
         {
            "nome":"CASINHAS",
            "codigo_ibge":"2604155"
         },
         {
            "nome":"CATENDE",
            "codigo_ibge":"2604205"
         },
         {
            "nome":"CEDRO",
            "codigo_ibge":"2604304"
         },
         {
            "nome":"CHA DE ALEGRIA",
            "codigo_ibge":"2604403"
         },
         {
            "nome":"CHA GRANDE",
            "codigo_ibge":"2604502"
         },
         {
            "nome":"CONDADO",
            "codigo_ibge":"2604601"
         },
         {
            "nome":"CORRENTES",
            "codigo_ibge":"2604700"
         },
         {
            "nome":"CORTES",
            "codigo_ibge":"2604809"
         },
         {
            "nome":"CUMARU",
            "codigo_ibge":"2604908"
         },
         {
            "nome":"CUPIRA",
            "codigo_ibge":"2605004"
         },
         {
            "nome":"CUSTODIA",
            "codigo_ibge":"2605103"
         },
         {
            "nome":"DORMENTES",
            "codigo_ibge":"2605152"
         },
         {
            "nome":"ESCADA",
            "codigo_ibge":"2605202"
         },
         {
            "nome":"EXU",
            "codigo_ibge":"2605301"
         },
         {
            "nome":"FEIRA NOVA",
            "codigo_ibge":"2605400"
         },
         {
            "nome":"FERNANDO DE NORONHA",
            "codigo_ibge":"2605459"
         },
         {
            "nome":"FERREIROS",
            "codigo_ibge":"2605509"
         },
         {
            "nome":"FLORES",
            "codigo_ibge":"2605608"
         },
         {
            "nome":"FLORESTA",
            "codigo_ibge":"2605707"
         },
         {
            "nome":"FREI MIGUELINHO",
            "codigo_ibge":"2605806"
         },
         {
            "nome":"GAMELEIRA",
            "codigo_ibge":"2605905"
         },
         {
            "nome":"GARANHUNS",
            "codigo_ibge":"2606002"
         },
         {
            "nome":"GLORIA DO GOITA",
            "codigo_ibge":"2606101"
         },
         {
            "nome":"GOIANA",
            "codigo_ibge":"2606200"
         },
         {
            "nome":"GRANITO",
            "codigo_ibge":"2606309"
         },
         {
            "nome":"GRAVATA",
            "codigo_ibge":"2606408"
         },
         {
            "nome":"IATI",
            "codigo_ibge":"2606507"
         },
         {
            "nome":"IBIMIRIM",
            "codigo_ibge":"2606606"
         },
         {
            "nome":"IBIRAJUBA",
            "codigo_ibge":"2606705"
         },
         {
            "nome":"IGARASSU",
            "codigo_ibge":"2606804"
         },
         {
            "nome":"IGUARACI",
            "codigo_ibge":"2606903"
         },
         {
            "nome":"INAJA",
            "codigo_ibge":"2607000"
         },
         {
            "nome":"INGAZEIRA",
            "codigo_ibge":"2607109"
         },
         {
            "nome":"IPOJUCA",
            "codigo_ibge":"2607208"
         },
         {
            "nome":"IPUBI",
            "codigo_ibge":"2607307"
         },
         {
            "nome":"ITACURUBA",
            "codigo_ibge":"2607406"
         },
         {
            "nome":"ITAIBA",
            "codigo_ibge":"2607505"
         },
         {
            "nome":"ILHA DE ITAMARACA",
            "codigo_ibge":"2607604"
         },
         {
            "nome":"ITAMBE",
            "codigo_ibge":"2607653"
         },
         {
            "nome":"ITAPETIM",
            "codigo_ibge":"2607703"
         },
         {
            "nome":"ITAPISSUMA",
            "codigo_ibge":"2607752"
         },
         {
            "nome":"ITAQUITINGA",
            "codigo_ibge":"2607802"
         },
         {
            "nome":"JABOATAO DOS GUARARAPES",
            "codigo_ibge":"2607901"
         },
         {
            "nome":"JAQUEIRA",
            "codigo_ibge":"2607950"
         },
         {
            "nome":"JATAUBA",
            "codigo_ibge":"2608008"
         },
         {
            "nome":"JATOBA",
            "codigo_ibge":"2608057"
         },
         {
            "nome":"JOAO ALFREDO",
            "codigo_ibge":"2608107"
         },
         {
            "nome":"JOAQUIM NABUCO",
            "codigo_ibge":"2608206"
         },
         {
            "nome":"JUCATI",
            "codigo_ibge":"2608255"
         },
         {
            "nome":"JUPI",
            "codigo_ibge":"2608305"
         },
         {
            "nome":"JUREMA",
            "codigo_ibge":"2608404"
         },
         {
            "nome":"LAGOA DO CARRO",
            "codigo_ibge":"2608453"
         },
         {
            "nome":"LAGOA DE ITAENGA",
            "codigo_ibge":"2608503"
         },
         {
            "nome":"LAGOA DO OURO",
            "codigo_ibge":"2608602"
         },
         {
            "nome":"LAGOA DOS GATOS",
            "codigo_ibge":"2608701"
         },
         {
            "nome":"LAGOA GRANDE",
            "codigo_ibge":"2608750"
         },
         {
            "nome":"LAJEDO",
            "codigo_ibge":"2608800"
         },
         {
            "nome":"LIMOEIRO",
            "codigo_ibge":"2608909"
         },
         {
            "nome":"MACAPARANA",
            "codigo_ibge":"2609006"
         },
         {
            "nome":"MACHADOS",
            "codigo_ibge":"2609105"
         },
         {
            "nome":"MANARI",
            "codigo_ibge":"2609154"
         },
         {
            "nome":"MARAIAL",
            "codigo_ibge":"2609204"
         },
         {
            "nome":"MIRANDIBA",
            "codigo_ibge":"2609303"
         },
         {
            "nome":"MORENO",
            "codigo_ibge":"2609402"
         },
         {
            "nome":"NAZARE DA MATA",
            "codigo_ibge":"2609501"
         },
         {
            "nome":"OLINDA",
            "codigo_ibge":"2609600"
         },
         {
            "nome":"OROBO",
            "codigo_ibge":"2609709"
         },
         {
            "nome":"OROCO",
            "codigo_ibge":"2609808"
         },
         {
            "nome":"OURICURI",
            "codigo_ibge":"2609907"
         },
         {
            "nome":"PALMARES",
            "codigo_ibge":"2610004"
         },
         {
            "nome":"PALMEIRINA",
            "codigo_ibge":"2610103"
         },
         {
            "nome":"PANELAS",
            "codigo_ibge":"2610202"
         },
         {
            "nome":"PARANATAMA",
            "codigo_ibge":"2610301"
         },
         {
            "nome":"PARNAMIRIM",
            "codigo_ibge":"2610400"
         },
         {
            "nome":"PASSIRA",
            "codigo_ibge":"2610509"
         },
         {
            "nome":"PAUDALHO",
            "codigo_ibge":"2610608"
         },
         {
            "nome":"PAULISTA",
            "codigo_ibge":"2610707"
         },
         {
            "nome":"PEDRA",
            "codigo_ibge":"2610806"
         },
         {
            "nome":"PESQUEIRA",
            "codigo_ibge":"2610905"
         },
         {
            "nome":"PETROLANDIA",
            "codigo_ibge":"2611002"
         },
         {
            "nome":"PETROLINA",
            "codigo_ibge":"2611101"
         },
         {
            "nome":"POCAO",
            "codigo_ibge":"2611200"
         },
         {
            "nome":"POMBOS",
            "codigo_ibge":"2611309"
         },
         {
            "nome":"PRIMAVERA",
            "codigo_ibge":"2611408"
         },
         {
            "nome":"QUIPAPA",
            "codigo_ibge":"2611507"
         },
         {
            "nome":"QUIXABA",
            "codigo_ibge":"2611533"
         },
         {
            "nome":"RECIFE",
            "codigo_ibge":"2611606"
         },
         {
            "nome":"RIACHO DAS ALMAS",
            "codigo_ibge":"2611705"
         },
         {
            "nome":"RIBEIRAO",
            "codigo_ibge":"2611804"
         },
         {
            "nome":"RIO FORMOSO",
            "codigo_ibge":"2611903"
         },
         {
            "nome":"SAIRE",
            "codigo_ibge":"2612000"
         },
         {
            "nome":"SALGADINHO",
            "codigo_ibge":"2612109"
         },
         {
            "nome":"SALGUEIRO",
            "codigo_ibge":"2612208"
         },
         {
            "nome":"SALOA",
            "codigo_ibge":"2612307"
         },
         {
            "nome":"SANHARO",
            "codigo_ibge":"2612406"
         },
         {
            "nome":"SANTA CRUZ",
            "codigo_ibge":"2612455"
         },
         {
            "nome":"SANTA CRUZ DA BAIXA VERDE",
            "codigo_ibge":"2612471"
         },
         {
            "nome":"SANTA CRUZ DO CAPIBARIBE",
            "codigo_ibge":"2612505"
         },
         {
            "nome":"SANTA FILOMENA",
            "codigo_ibge":"2612554"
         },
         {
            "nome":"SANTA MARIA DA BOA VISTA",
            "codigo_ibge":"2612604"
         },
         {
            "nome":"SANTA MARIA DO CAMBUCA",
            "codigo_ibge":"2612703"
         },
         {
            "nome":"SANTA TEREZINHA",
            "codigo_ibge":"2612802"
         },
         {
            "nome":"SAO BENEDITO DO SUL",
            "codigo_ibge":"2612901"
         },
         {
            "nome":"SAO BENTO DO UNA",
            "codigo_ibge":"2613008"
         },
         {
            "nome":"SAO CAITANO",
            "codigo_ibge":"2613107"
         },
         {
            "nome":"SAO JOAO",
            "codigo_ibge":"2613206"
         },
         {
            "nome":"SAO JOAQUIM DO MONTE",
            "codigo_ibge":"2613305"
         },
         {
            "nome":"SAO JOSE DA COROA GRANDE",
            "codigo_ibge":"2613404"
         },
         {
            "nome":"SAO JOSE DO BELMONTE",
            "codigo_ibge":"2613503"
         },
         {
            "nome":"SAO JOSE DO EGITO",
            "codigo_ibge":"2613602"
         },
         {
            "nome":"SAO LOURENCO DA MATA",
            "codigo_ibge":"2613701"
         },
         {
            "nome":"SAO VICENTE FERRER",
            "codigo_ibge":"2613800"
         },
         {
            "nome":"SERRA TALHADA",
            "codigo_ibge":"2613909"
         },
         {
            "nome":"SERRITA",
            "codigo_ibge":"2614006"
         },
         {
            "nome":"SERTANIA",
            "codigo_ibge":"2614105"
         },
         {
            "nome":"SIRINHAEM",
            "codigo_ibge":"2614204"
         },
         {
            "nome":"MOREILANDIA",
            "codigo_ibge":"2614303"
         },
         {
            "nome":"SOLIDAO",
            "codigo_ibge":"2614402"
         },
         {
            "nome":"SURUBIM",
            "codigo_ibge":"2614501"
         },
         {
            "nome":"TABIRA",
            "codigo_ibge":"2614600"
         },
         {
            "nome":"TACAIMBO",
            "codigo_ibge":"2614709"
         },
         {
            "nome":"TACARATU",
            "codigo_ibge":"2614808"
         },
         {
            "nome":"TAMANDARE",
            "codigo_ibge":"2614857"
         },
         {
            "nome":"TAQUARITINGA DO NORTE",
            "codigo_ibge":"2615003"
         },
         {
            "nome":"TEREZINHA",
            "codigo_ibge":"2615102"
         },
         {
            "nome":"TERRA NOVA",
            "codigo_ibge":"2615201"
         },
         {
            "nome":"TIMBAUBA",
            "codigo_ibge":"2615300"
         },
         {
            "nome":"TORITAMA",
            "codigo_ibge":"2615409"
         },
         {
            "nome":"TRACUNHAEM",
            "codigo_ibge":"2615508"
         },
         {
            "nome":"TRINDADE",
            "codigo_ibge":"2615607"
         },
         {
            "nome":"TRIUNFO",
            "codigo_ibge":"2615706"
         },
         {
            "nome":"TUPANATINGA",
            "codigo_ibge":"2615805"
         },
         {
            "nome":"TUPARETAMA",
            "codigo_ibge":"2615904"
         },
         {
            "nome":"VENTUROSA",
            "codigo_ibge":"2616001"
         },
         {
            "nome":"VERDEJANTE",
            "codigo_ibge":"2616100"
         },
         {
            "nome":"VERTENTE DO LERIO",
            "codigo_ibge":"2616183"
         },
         {
            "nome":"VERTENTES",
            "codigo_ibge":"2616209"
         },
         {
            "nome":"VICENCIA",
            "codigo_ibge":"2616308"
         },
         {
            "nome":"VITORIA DE SANTO ANTAO",
            "codigo_ibge":"2616407"
         },
         {
            "nome":"XEXEU",
            "codigo_ibge":"2616506"
         }
      ]
   },
   {
      "estado" : "PR",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ABATIA",
            "codigo_ibge":"4100103"
         },
         {
            "nome":"ADRIANOPOLIS",
            "codigo_ibge":"4100202"
         },
         {
            "nome":"AGUDOS DO SUL",
            "codigo_ibge":"4100301"
         },
         {
            "nome":"ALMIRANTE TAMANDARE",
            "codigo_ibge":"4100400"
         },
         {
            "nome":"ALTAMIRA DO PARANA",
            "codigo_ibge":"4100459"
         },
         {
            "nome":"ALTONIA",
            "codigo_ibge":"4100509"
         },
         {
            "nome":"ALTO PARANA",
            "codigo_ibge":"4100608"
         },
         {
            "nome":"ALTO PIQUIRI",
            "codigo_ibge":"4100707"
         },
         {
            "nome":"ALVORADA DO SUL",
            "codigo_ibge":"4100806"
         },
         {
            "nome":"AMAPORA",
            "codigo_ibge":"4100905"
         },
         {
            "nome":"AMPERE",
            "codigo_ibge":"4101002"
         },
         {
            "nome":"ANAHY",
            "codigo_ibge":"4101051"
         },
         {
            "nome":"ANDIRA",
            "codigo_ibge":"4101101"
         },
         {
            "nome":"ANGULO",
            "codigo_ibge":"4101150"
         },
         {
            "nome":"ANTONINA",
            "codigo_ibge":"4101200"
         },
         {
            "nome":"ANTONIO OLINTO",
            "codigo_ibge":"4101309"
         },
         {
            "nome":"APUCARANA",
            "codigo_ibge":"4101408"
         },
         {
            "nome":"ARAPONGAS",
            "codigo_ibge":"4101507"
         },
         {
            "nome":"ARAPOTI",
            "codigo_ibge":"4101606"
         },
         {
            "nome":"ARAPUA",
            "codigo_ibge":"4101655"
         },
         {
            "nome":"ARARUNA",
            "codigo_ibge":"4101705"
         },
         {
            "nome":"ARAUCARIA",
            "codigo_ibge":"4101804"
         },
         {
            "nome":"ARIRANHA DO IVAI",
            "codigo_ibge":"4101853"
         },
         {
            "nome":"ASSAI",
            "codigo_ibge":"4101903"
         },
         {
            "nome":"ASSIS CHATEAUBRIAND",
            "codigo_ibge":"4102000"
         },
         {
            "nome":"ASTORGA",
            "codigo_ibge":"4102109"
         },
         {
            "nome":"ATALAIA",
            "codigo_ibge":"4102208"
         },
         {
            "nome":"BALSA NOVA",
            "codigo_ibge":"4102307"
         },
         {
            "nome":"BANDEIRANTES",
            "codigo_ibge":"4102406"
         },
         {
            "nome":"BARBOSA FERRAZ",
            "codigo_ibge":"4102505"
         },
         {
            "nome":"BARRACAO",
            "codigo_ibge":"4102604"
         },
         {
            "nome":"BARRA DO JACARE",
            "codigo_ibge":"4102703"
         },
         {
            "nome":"BELA VISTA DA CAROBA",
            "codigo_ibge":"4102752"
         },
         {
            "nome":"BELA VISTA DO PARAISO",
            "codigo_ibge":"4102802"
         },
         {
            "nome":"BITURUNA",
            "codigo_ibge":"4102901"
         },
         {
            "nome":"BOA ESPERANCA",
            "codigo_ibge":"4103008"
         },
         {
            "nome":"BOA ESPERANCA DO IGUACU",
            "codigo_ibge":"4103024"
         },
         {
            "nome":"BOA VENTURA DE SAO ROQUE",
            "codigo_ibge":"4103040"
         },
         {
            "nome":"BOA VISTA DA APARECIDA",
            "codigo_ibge":"4103057"
         },
         {
            "nome":"BOCAIUVA DO SUL",
            "codigo_ibge":"4103107"
         },
         {
            "nome":"BOM JESUS DO SUL",
            "codigo_ibge":"4103156"
         },
         {
            "nome":"BOM SUCESSO",
            "codigo_ibge":"4103206"
         },
         {
            "nome":"BOM SUCESSO DO SUL",
            "codigo_ibge":"4103222"
         },
         {
            "nome":"BORRAZOPOLIS",
            "codigo_ibge":"4103305"
         },
         {
            "nome":"BRAGANEY",
            "codigo_ibge":"4103354"
         },
         {
            "nome":"BRASILANDIA DO SUL",
            "codigo_ibge":"4103370"
         },
         {
            "nome":"CAFEARA",
            "codigo_ibge":"4103404"
         },
         {
            "nome":"CAFELANDIA",
            "codigo_ibge":"4103453"
         },
         {
            "nome":"CAFEZAL DO SUL",
            "codigo_ibge":"4103479"
         },
         {
            "nome":"CALIFORNIA",
            "codigo_ibge":"4103503"
         },
         {
            "nome":"CAMBARA",
            "codigo_ibge":"4103602"
         },
         {
            "nome":"CAMBE",
            "codigo_ibge":"4103701"
         },
         {
            "nome":"CAMBIRA",
            "codigo_ibge":"4103800"
         },
         {
            "nome":"CAMPINA DA LAGOA",
            "codigo_ibge":"4103909"
         },
         {
            "nome":"CAMPINA DO SIMAO",
            "codigo_ibge":"4103958"
         },
         {
            "nome":"CAMPINA GRANDE DO SUL",
            "codigo_ibge":"4104006"
         },
         {
            "nome":"CAMPO BONITO",
            "codigo_ibge":"4104055"
         },
         {
            "nome":"CAMPO DO TENENTE",
            "codigo_ibge":"4104105"
         },
         {
            "nome":"CAMPO LARGO",
            "codigo_ibge":"4104204"
         },
         {
            "nome":"CAMPO MAGRO",
            "codigo_ibge":"4104253"
         },
         {
            "nome":"CAMPO MOURAO",
            "codigo_ibge":"4104303"
         },
         {
            "nome":"CANDIDO DE ABREU",
            "codigo_ibge":"4104402"
         },
         {
            "nome":"CANDOI",
            "codigo_ibge":"4104428"
         },
         {
            "nome":"CANTAGALO",
            "codigo_ibge":"4104451"
         },
         {
            "nome":"CAPANEMA",
            "codigo_ibge":"4104501"
         },
         {
            "nome":"CAPITAO LEONIDAS MARQUES",
            "codigo_ibge":"4104600"
         },
         {
            "nome":"CARAMBEI",
            "codigo_ibge":"4104659"
         },
         {
            "nome":"CARLOPOLIS",
            "codigo_ibge":"4104709"
         },
         {
            "nome":"CASCAVEL",
            "codigo_ibge":"4104808"
         },
         {
            "nome":"CASTRO",
            "codigo_ibge":"4104907"
         },
         {
            "nome":"CATANDUVAS",
            "codigo_ibge":"4105003"
         },
         {
            "nome":"CENTENARIO DO SUL",
            "codigo_ibge":"4105102"
         },
         {
            "nome":"CERRO AZUL",
            "codigo_ibge":"4105201"
         },
         {
            "nome":"CEU AZUL",
            "codigo_ibge":"4105300"
         },
         {
            "nome":"CHOPINZINHO",
            "codigo_ibge":"4105409"
         },
         {
            "nome":"CIANORTE",
            "codigo_ibge":"4105508"
         },
         {
            "nome":"CIDADE GAUCHA",
            "codigo_ibge":"4105607"
         },
         {
            "nome":"CLEVELANDIA",
            "codigo_ibge":"4105706"
         },
         {
            "nome":"COLOMBO",
            "codigo_ibge":"4105805"
         },
         {
            "nome":"COLORADO",
            "codigo_ibge":"4105904"
         },
         {
            "nome":"CONGONHINHAS",
            "codigo_ibge":"4106001"
         },
         {
            "nome":"CONSELHEIRO MAIRINCK",
            "codigo_ibge":"4106100"
         },
         {
            "nome":"CONTENDA",
            "codigo_ibge":"4106209"
         },
         {
            "nome":"CORBELIA",
            "codigo_ibge":"4106308"
         },
         {
            "nome":"CORNELIO PROCOPIO",
            "codigo_ibge":"4106407"
         },
         {
            "nome":"CORONEL DOMINGOS SOARES",
            "codigo_ibge":"4106456"
         },
         {
            "nome":"CORONEL VIVIDA",
            "codigo_ibge":"4106506"
         },
         {
            "nome":"CORUMBATAI DO SUL",
            "codigo_ibge":"4106555"
         },
         {
            "nome":"CRUZEIRO DO IGUACU",
            "codigo_ibge":"4106571"
         },
         {
            "nome":"CRUZEIRO DO OESTE",
            "codigo_ibge":"4106605"
         },
         {
            "nome":"CRUZEIRO DO SUL",
            "codigo_ibge":"4106704"
         },
         {
            "nome":"CRUZ MACHADO",
            "codigo_ibge":"4106803"
         },
         {
            "nome":"CRUZMALTINA",
            "codigo_ibge":"4106852"
         },
         {
            "nome":"CURITIBA",
            "codigo_ibge":"4106902"
         },
         {
            "nome":"CURIUVA",
            "codigo_ibge":"4107009"
         },
         {
            "nome":"DIAMANTE DO NORTE",
            "codigo_ibge":"4107108"
         },
         {
            "nome":"DIAMANTE DO SUL",
            "codigo_ibge":"4107124"
         },
         {
            "nome":"DIAMANTE D OESTE",
            "codigo_ibge":"4107157"
         },
         {
            "nome":"DOIS VIZINHOS",
            "codigo_ibge":"4107207"
         },
         {
            "nome":"DOURADINA",
            "codigo_ibge":"4107256"
         },
         {
            "nome":"DOUTOR CAMARGO",
            "codigo_ibge":"4107306"
         },
         {
            "nome":"ENEAS MARQUES",
            "codigo_ibge":"4107405"
         },
         {
            "nome":"ENGENHEIRO BELTRAO",
            "codigo_ibge":"4107504"
         },
         {
            "nome":"ESPERANCA NOVA",
            "codigo_ibge":"4107520"
         },
         {
            "nome":"ENTRE RIOS DO OESTE",
            "codigo_ibge":"4107538"
         },
         {
            "nome":"ESPIGAO ALTO DO IGUACU",
            "codigo_ibge":"4107546"
         },
         {
            "nome":"FAROL",
            "codigo_ibge":"4107553"
         },
         {
            "nome":"FAXINAL",
            "codigo_ibge":"4107603"
         },
         {
            "nome":"FAZENDA RIO GRANDE",
            "codigo_ibge":"4107652"
         },
         {
            "nome":"FENIX",
            "codigo_ibge":"4107702"
         },
         {
            "nome":"FERNANDES PINHEIRO",
            "codigo_ibge":"4107736"
         },
         {
            "nome":"FIGUEIRA",
            "codigo_ibge":"4107751"
         },
         {
            "nome":"FLORAI",
            "codigo_ibge":"4107801"
         },
         {
            "nome":"FLOR DA SERRA DO SUL",
            "codigo_ibge":"4107850"
         },
         {
            "nome":"FLORESTA",
            "codigo_ibge":"4107900"
         },
         {
            "nome":"FLORESTOPOLIS",
            "codigo_ibge":"4108007"
         },
         {
            "nome":"FLORIDA",
            "codigo_ibge":"4108106"
         },
         {
            "nome":"FORMOSA DO OESTE",
            "codigo_ibge":"4108205"
         },
         {
            "nome":"FOZ DO IGUACU",
            "codigo_ibge":"4108304"
         },
         {
            "nome":"FRANCISCO ALVES",
            "codigo_ibge":"4108320"
         },
         {
            "nome":"FRANCISCO BELTRAO",
            "codigo_ibge":"4108403"
         },
         {
            "nome":"FOZ DO JORDAO",
            "codigo_ibge":"4108452"
         },
         {
            "nome":"GENERAL CARNEIRO",
            "codigo_ibge":"4108502"
         },
         {
            "nome":"GODOY MOREIRA",
            "codigo_ibge":"4108551"
         },
         {
            "nome":"GOIOERE",
            "codigo_ibge":"4108601"
         },
         {
            "nome":"GOIOXIM",
            "codigo_ibge":"4108650"
         },
         {
            "nome":"GRANDES RIOS",
            "codigo_ibge":"4108700"
         },
         {
            "nome":"GUAIRA",
            "codigo_ibge":"4108809"
         },
         {
            "nome":"GUAIRACA",
            "codigo_ibge":"4108908"
         },
         {
            "nome":"GUAMIRANGA",
            "codigo_ibge":"4108957"
         },
         {
            "nome":"GUAPIRAMA",
            "codigo_ibge":"4109005"
         },
         {
            "nome":"GUAPOREMA",
            "codigo_ibge":"4109104"
         },
         {
            "nome":"GUARACI",
            "codigo_ibge":"4109203"
         },
         {
            "nome":"GUARANIACU",
            "codigo_ibge":"4109302"
         },
         {
            "nome":"GUARAPUAVA",
            "codigo_ibge":"4109401"
         },
         {
            "nome":"GUARAQUECABA",
            "codigo_ibge":"4109500"
         },
         {
            "nome":"GUARATUBA",
            "codigo_ibge":"4109609"
         },
         {
            "nome":"HONORIO SERPA",
            "codigo_ibge":"4109658"
         },
         {
            "nome":"IBAITI",
            "codigo_ibge":"4109708"
         },
         {
            "nome":"IBEMA",
            "codigo_ibge":"4109757"
         },
         {
            "nome":"IBIPORA",
            "codigo_ibge":"4109807"
         },
         {
            "nome":"ICARAIMA",
            "codigo_ibge":"4109906"
         },
         {
            "nome":"IGUARACU",
            "codigo_ibge":"4110003"
         },
         {
            "nome":"IGUATU",
            "codigo_ibge":"4110052"
         },
         {
            "nome":"IMBAU",
            "codigo_ibge":"4110078"
         },
         {
            "nome":"IMBITUVA",
            "codigo_ibge":"4110102"
         },
         {
            "nome":"INACIO MARTINS",
            "codigo_ibge":"4110201"
         },
         {
            "nome":"INAJA",
            "codigo_ibge":"4110300"
         },
         {
            "nome":"INDIANOPOLIS",
            "codigo_ibge":"4110409"
         },
         {
            "nome":"IPIRANGA",
            "codigo_ibge":"4110508"
         },
         {
            "nome":"IPORA",
            "codigo_ibge":"4110607"
         },
         {
            "nome":"IRACEMA DO OESTE",
            "codigo_ibge":"4110656"
         },
         {
            "nome":"IRATI",
            "codigo_ibge":"4110706"
         },
         {
            "nome":"IRETAMA",
            "codigo_ibge":"4110805"
         },
         {
            "nome":"ITAGUAJE",
            "codigo_ibge":"4110904"
         },
         {
            "nome":"ITAIPULANDIA",
            "codigo_ibge":"4110953"
         },
         {
            "nome":"ITAMBARACA",
            "codigo_ibge":"4111001"
         },
         {
            "nome":"ITAMBE",
            "codigo_ibge":"4111100"
         },
         {
            "nome":"ITAPEJARA D OESTE",
            "codigo_ibge":"4111209"
         },
         {
            "nome":"ITAPERUCU",
            "codigo_ibge":"4111258"
         },
         {
            "nome":"ITAUNA DO SUL",
            "codigo_ibge":"4111308"
         },
         {
            "nome":"IVAI",
            "codigo_ibge":"4111407"
         },
         {
            "nome":"IVAIPORA",
            "codigo_ibge":"4111506"
         },
         {
            "nome":"IVATE",
            "codigo_ibge":"4111555"
         },
         {
            "nome":"IVATUBA",
            "codigo_ibge":"4111605"
         },
         {
            "nome":"JABOTI",
            "codigo_ibge":"4111704"
         },
         {
            "nome":"JACAREZINHO",
            "codigo_ibge":"4111803"
         },
         {
            "nome":"JAGUAPITA",
            "codigo_ibge":"4111902"
         },
         {
            "nome":"JAGUARIAIVA",
            "codigo_ibge":"4112009"
         },
         {
            "nome":"JANDAIA DO SUL",
            "codigo_ibge":"4112108"
         },
         {
            "nome":"JANIOPOLIS",
            "codigo_ibge":"4112207"
         },
         {
            "nome":"JAPIRA",
            "codigo_ibge":"4112306"
         },
         {
            "nome":"JAPURA",
            "codigo_ibge":"4112405"
         },
         {
            "nome":"JARDIM ALEGRE",
            "codigo_ibge":"4112504"
         },
         {
            "nome":"JARDIM OLINDA",
            "codigo_ibge":"4112603"
         },
         {
            "nome":"JATAIZINHO",
            "codigo_ibge":"4112702"
         },
         {
            "nome":"JESUITAS",
            "codigo_ibge":"4112751"
         },
         {
            "nome":"JOAQUIM TAVORA",
            "codigo_ibge":"4112801"
         },
         {
            "nome":"JUNDIAI DO SUL",
            "codigo_ibge":"4112900"
         },
         {
            "nome":"JURANDA",
            "codigo_ibge":"4112959"
         },
         {
            "nome":"JUSSARA",
            "codigo_ibge":"4113007"
         },
         {
            "nome":"KALORE",
            "codigo_ibge":"4113106"
         },
         {
            "nome":"LAPA",
            "codigo_ibge":"4113205"
         },
         {
            "nome":"LARANJAL",
            "codigo_ibge":"4113254"
         },
         {
            "nome":"LARANJEIRAS DO SUL",
            "codigo_ibge":"4113304"
         },
         {
            "nome":"LEOPOLIS",
            "codigo_ibge":"4113403"
         },
         {
            "nome":"LIDIANOPOLIS",
            "codigo_ibge":"4113429"
         },
         {
            "nome":"LINDOESTE",
            "codigo_ibge":"4113452"
         },
         {
            "nome":"LOANDA",
            "codigo_ibge":"4113502"
         },
         {
            "nome":"LOBATO",
            "codigo_ibge":"4113601"
         },
         {
            "nome":"LONDRINA",
            "codigo_ibge":"4113700"
         },
         {
            "nome":"LUIZIANA",
            "codigo_ibge":"4113734"
         },
         {
            "nome":"LUNARDELLI",
            "codigo_ibge":"4113759"
         },
         {
            "nome":"LUPIONOPOLIS",
            "codigo_ibge":"4113809"
         },
         {
            "nome":"MALLET",
            "codigo_ibge":"4113908"
         },
         {
            "nome":"MAMBORE",
            "codigo_ibge":"4114005"
         },
         {
            "nome":"MANDAGUACU",
            "codigo_ibge":"4114104"
         },
         {
            "nome":"MANDAGUARI",
            "codigo_ibge":"4114203"
         },
         {
            "nome":"MANDIRITUBA",
            "codigo_ibge":"4114302"
         },
         {
            "nome":"MANFRINOPOLIS",
            "codigo_ibge":"4114351"
         },
         {
            "nome":"MANGUEIRINHA",
            "codigo_ibge":"4114401"
         },
         {
            "nome":"MANOEL RIBAS",
            "codigo_ibge":"4114500"
         },
         {
            "nome":"MARECHAL CANDIDO RONDON",
            "codigo_ibge":"4114609"
         },
         {
            "nome":"MARIA HELENA",
            "codigo_ibge":"4114708"
         },
         {
            "nome":"MARIALVA",
            "codigo_ibge":"4114807"
         },
         {
            "nome":"MARILANDIA DO SUL",
            "codigo_ibge":"4114906"
         },
         {
            "nome":"MARILENA",
            "codigo_ibge":"4115002"
         },
         {
            "nome":"MARILUZ",
            "codigo_ibge":"4115101"
         },
         {
            "nome":"MARINGA",
            "codigo_ibge":"4115200"
         },
         {
            "nome":"MARIOPOLIS",
            "codigo_ibge":"4115309"
         },
         {
            "nome":"MARIPA",
            "codigo_ibge":"4115358"
         },
         {
            "nome":"MARMELEIRO",
            "codigo_ibge":"4115408"
         },
         {
            "nome":"MARQUINHO",
            "codigo_ibge":"4115457"
         },
         {
            "nome":"MARUMBI",
            "codigo_ibge":"4115507"
         },
         {
            "nome":"MATELANDIA",
            "codigo_ibge":"4115606"
         },
         {
            "nome":"MATINHOS",
            "codigo_ibge":"4115705"
         },
         {
            "nome":"MATO RICO",
            "codigo_ibge":"4115739"
         },
         {
            "nome":"MAUA DA SERRA",
            "codigo_ibge":"4115754"
         },
         {
            "nome":"MEDIANEIRA",
            "codigo_ibge":"4115804"
         },
         {
            "nome":"MERCEDES",
            "codigo_ibge":"4115853"
         },
         {
            "nome":"MIRADOR",
            "codigo_ibge":"4115903"
         },
         {
            "nome":"MIRASELVA",
            "codigo_ibge":"4116000"
         },
         {
            "nome":"MISSAL",
            "codigo_ibge":"4116059"
         },
         {
            "nome":"MOREIRA SALES",
            "codigo_ibge":"4116109"
         },
         {
            "nome":"MORRETES",
            "codigo_ibge":"4116208"
         },
         {
            "nome":"MUNHOZ DE MELO",
            "codigo_ibge":"4116307"
         },
         {
            "nome":"NOSSA SENHORA DAS GRACAS",
            "codigo_ibge":"4116406"
         },
         {
            "nome":"NOVA ALIANCA DO IVAI",
            "codigo_ibge":"4116505"
         },
         {
            "nome":"NOVA AMERICA DA COLINA",
            "codigo_ibge":"4116604"
         },
         {
            "nome":"NOVA AURORA",
            "codigo_ibge":"4116703"
         },
         {
            "nome":"NOVA CANTU",
            "codigo_ibge":"4116802"
         },
         {
            "nome":"NOVA ESPERANCA",
            "codigo_ibge":"4116901"
         },
         {
            "nome":"NOVA ESPERANCA DO SUDOESTE",
            "codigo_ibge":"4116950"
         },
         {
            "nome":"NOVA FATIMA",
            "codigo_ibge":"4117008"
         },
         {
            "nome":"NOVA LARANJEIRAS",
            "codigo_ibge":"4117057"
         },
         {
            "nome":"NOVA LONDRINA",
            "codigo_ibge":"4117107"
         },
         {
            "nome":"NOVA OLIMPIA",
            "codigo_ibge":"4117206"
         },
         {
            "nome":"NOVA SANTA BARBARA",
            "codigo_ibge":"4117214"
         },
         {
            "nome":"NOVA SANTA ROSA",
            "codigo_ibge":"4117222"
         },
         {
            "nome":"NOVA PRATA DO IGUACU",
            "codigo_ibge":"4117255"
         },
         {
            "nome":"NOVA TEBAS",
            "codigo_ibge":"4117271"
         },
         {
            "nome":"NOVO ITACOLOMI",
            "codigo_ibge":"4117297"
         },
         {
            "nome":"ORTIGUEIRA",
            "codigo_ibge":"4117305"
         },
         {
            "nome":"OURIZONA",
            "codigo_ibge":"4117404"
         },
         {
            "nome":"OURO VERDE DO OESTE",
            "codigo_ibge":"4117453"
         },
         {
            "nome":"PAICANDU",
            "codigo_ibge":"4117503"
         },
         {
            "nome":"PALMAS",
            "codigo_ibge":"4117602"
         },
         {
            "nome":"PALMEIRA",
            "codigo_ibge":"4117701"
         },
         {
            "nome":"PALMITAL",
            "codigo_ibge":"4117800"
         },
         {
            "nome":"PALOTINA",
            "codigo_ibge":"4117909"
         },
         {
            "nome":"PARAISO DO NORTE",
            "codigo_ibge":"4118006"
         },
         {
            "nome":"PARANACITY",
            "codigo_ibge":"4118105"
         },
         {
            "nome":"PARANAGUA",
            "codigo_ibge":"4118204"
         },
         {
            "nome":"PARANAPOEMA",
            "codigo_ibge":"4118303"
         },
         {
            "nome":"PARANAVAI",
            "codigo_ibge":"4118402"
         },
         {
            "nome":"PATO BRAGADO",
            "codigo_ibge":"4118451"
         },
         {
            "nome":"PATO BRANCO",
            "codigo_ibge":"4118501"
         },
         {
            "nome":"PAULA FREITAS",
            "codigo_ibge":"4118600"
         },
         {
            "nome":"PAULO FRONTIN",
            "codigo_ibge":"4118709"
         },
         {
            "nome":"PEABIRU",
            "codigo_ibge":"4118808"
         },
         {
            "nome":"PEROBAL",
            "codigo_ibge":"4118857"
         },
         {
            "nome":"PEROLA",
            "codigo_ibge":"4118907"
         },
         {
            "nome":"PEROLA D OESTE",
            "codigo_ibge":"4119004"
         },
         {
            "nome":"PIEN",
            "codigo_ibge":"4119103"
         },
         {
            "nome":"PINHAIS",
            "codigo_ibge":"4119152"
         },
         {
            "nome":"PINHALAO",
            "codigo_ibge":"4119202"
         },
         {
            "nome":"PINHAL DE SAO BENTO",
            "codigo_ibge":"4119251"
         },
         {
            "nome":"PINHAO",
            "codigo_ibge":"4119301"
         },
         {
            "nome":"PIRAI DO SUL",
            "codigo_ibge":"4119400"
         },
         {
            "nome":"PIRAQUARA",
            "codigo_ibge":"4119509"
         },
         {
            "nome":"PITANGA",
            "codigo_ibge":"4119608"
         },
         {
            "nome":"PITANGUEIRAS",
            "codigo_ibge":"4119657"
         },
         {
            "nome":"PLANALTINA DO PARANA",
            "codigo_ibge":"4119707"
         },
         {
            "nome":"PLANALTO",
            "codigo_ibge":"4119806"
         },
         {
            "nome":"PONTA GROSSA",
            "codigo_ibge":"4119905"
         },
         {
            "nome":"PONTAL DO PARANA",
            "codigo_ibge":"4119954"
         },
         {
            "nome":"PORECATU",
            "codigo_ibge":"4120002"
         },
         {
            "nome":"PORTO AMAZONAS",
            "codigo_ibge":"4120101"
         },
         {
            "nome":"PORTO BARREIRO",
            "codigo_ibge":"4120150"
         },
         {
            "nome":"PORTO RICO",
            "codigo_ibge":"4120200"
         },
         {
            "nome":"PORTO VITORIA",
            "codigo_ibge":"4120309"
         },
         {
            "nome":"PRADO FERREIRA",
            "codigo_ibge":"4120333"
         },
         {
            "nome":"PRANCHITA",
            "codigo_ibge":"4120358"
         },
         {
            "nome":"PRESIDENTE CASTELO BRANCO",
            "codigo_ibge":"4120408"
         },
         {
            "nome":"PRIMEIRO DE MAIO",
            "codigo_ibge":"4120507"
         },
         {
            "nome":"PRUDENTOPOLIS",
            "codigo_ibge":"4120606"
         },
         {
            "nome":"QUARTO CENTENARIO",
            "codigo_ibge":"4120655"
         },
         {
            "nome":"QUATIGUA",
            "codigo_ibge":"4120705"
         },
         {
            "nome":"QUATRO BARRAS",
            "codigo_ibge":"4120804"
         },
         {
            "nome":"QUATRO PONTES",
            "codigo_ibge":"4120853"
         },
         {
            "nome":"QUEDAS DO IGUACU",
            "codigo_ibge":"4120903"
         },
         {
            "nome":"QUERENCIA DO NORTE",
            "codigo_ibge":"4121000"
         },
         {
            "nome":"QUINTA DO SOL",
            "codigo_ibge":"4121109"
         },
         {
            "nome":"QUITANDINHA",
            "codigo_ibge":"4121208"
         },
         {
            "nome":"RAMILANDIA",
            "codigo_ibge":"4121257"
         },
         {
            "nome":"RANCHO ALEGRE",
            "codigo_ibge":"4121307"
         },
         {
            "nome":"RANCHO ALEGRE D OESTE",
            "codigo_ibge":"4121356"
         },
         {
            "nome":"REALEZA",
            "codigo_ibge":"4121406"
         },
         {
            "nome":"REBOUCAS",
            "codigo_ibge":"4121505"
         },
         {
            "nome":"RENASCENCA",
            "codigo_ibge":"4121604"
         },
         {
            "nome":"RESERVA",
            "codigo_ibge":"4121703"
         },
         {
            "nome":"RESERVA DO IGUACU",
            "codigo_ibge":"4121752"
         },
         {
            "nome":"RIBEIRAO CLARO",
            "codigo_ibge":"4121802"
         },
         {
            "nome":"RIBEIRAO DO PINHAL",
            "codigo_ibge":"4121901"
         },
         {
            "nome":"RIO AZUL",
            "codigo_ibge":"4122008"
         },
         {
            "nome":"RIO BOM",
            "codigo_ibge":"4122107"
         },
         {
            "nome":"RIO BONITO DO IGUACU",
            "codigo_ibge":"4122156"
         },
         {
            "nome":"RIO BRANCO DO IVAI",
            "codigo_ibge":"4122172"
         },
         {
            "nome":"RIO BRANCO DO SUL",
            "codigo_ibge":"4122206"
         },
         {
            "nome":"RIO NEGRO",
            "codigo_ibge":"4122305"
         },
         {
            "nome":"ROLANDIA",
            "codigo_ibge":"4122404"
         },
         {
            "nome":"RONCADOR",
            "codigo_ibge":"4122503"
         },
         {
            "nome":"RONDON",
            "codigo_ibge":"4122602"
         },
         {
            "nome":"ROSARIO DO IVAI",
            "codigo_ibge":"4122651"
         },
         {
            "nome":"SABAUDIA",
            "codigo_ibge":"4122701"
         },
         {
            "nome":"SALGADO FILHO",
            "codigo_ibge":"4122800"
         },
         {
            "nome":"SALTO DO ITARARE",
            "codigo_ibge":"4122909"
         },
         {
            "nome":"SALTO DO LONTRA",
            "codigo_ibge":"4123006"
         },
         {
            "nome":"SANTA AMELIA",
            "codigo_ibge":"4123105"
         },
         {
            "nome":"SANTA CECILIA DO PAVAO",
            "codigo_ibge":"4123204"
         },
         {
            "nome":"SANTA CRUZ DE MONTE CASTELO",
            "codigo_ibge":"4123303"
         },
         {
            "nome":"SANTA FE",
            "codigo_ibge":"4123402"
         },
         {
            "nome":"SANTA HELENA",
            "codigo_ibge":"4123501"
         },
         {
            "nome":"SANTA INES",
            "codigo_ibge":"4123600"
         },
         {
            "nome":"SANTA ISABEL DO IVAI",
            "codigo_ibge":"4123709"
         },
         {
            "nome":"SANTA IZABEL DO OESTE",
            "codigo_ibge":"4123808"
         },
         {
            "nome":"SANTA LUCIA",
            "codigo_ibge":"4123824"
         },
         {
            "nome":"SANTA MARIA DO OESTE",
            "codigo_ibge":"4123857"
         },
         {
            "nome":"SANTA MARIANA",
            "codigo_ibge":"4123907"
         },
         {
            "nome":"SANTA MONICA",
            "codigo_ibge":"4123956"
         },
         {
            "nome":"SANTANA DO ITARARE",
            "codigo_ibge":"4124004"
         },
         {
            "nome":"SANTA TEREZA DO OESTE",
            "codigo_ibge":"4124020"
         },
         {
            "nome":"SANTA TEREZINHA DE ITAIPU",
            "codigo_ibge":"4124053"
         },
         {
            "nome":"SANTO ANTONIO DA PLATINA",
            "codigo_ibge":"4124103"
         },
         {
            "nome":"SANTO ANTONIO DO CAIUA",
            "codigo_ibge":"4124202"
         },
         {
            "nome":"SANTO ANTONIO DO PARAISO",
            "codigo_ibge":"4124301"
         },
         {
            "nome":"SANTO ANTONIO DO SUDOESTE",
            "codigo_ibge":"4124400"
         },
         {
            "nome":"SANTO INACIO",
            "codigo_ibge":"4124509"
         },
         {
            "nome":"SAO CARLOS DO IVAI",
            "codigo_ibge":"4124608"
         },
         {
            "nome":"SAO JERONIMO DA SERRA",
            "codigo_ibge":"4124707"
         },
         {
            "nome":"SAO JOAO",
            "codigo_ibge":"4124806"
         },
         {
            "nome":"SAO JOAO DO CAIUA",
            "codigo_ibge":"4124905"
         },
         {
            "nome":"SAO JOAO DO IVAI",
            "codigo_ibge":"4125001"
         },
         {
            "nome":"SAO JOAO DO TRIUNFO",
            "codigo_ibge":"4125100"
         },
         {
            "nome":"SAO JORGE D OESTE",
            "codigo_ibge":"4125209"
         },
         {
            "nome":"SAO JORGE DO IVAI",
            "codigo_ibge":"4125308"
         },
         {
            "nome":"SAO JORGE DO PATROCINIO",
            "codigo_ibge":"4125357"
         },
         {
            "nome":"SAO JOSE DA BOA VISTA",
            "codigo_ibge":"4125407"
         },
         {
            "nome":"SAO JOSE DAS PALMEIRAS",
            "codigo_ibge":"4125456"
         },
         {
            "nome":"SAO JOSE DOS PINHAIS",
            "codigo_ibge":"4125506"
         },
         {
            "nome":"SAO MANOEL DO PARANA",
            "codigo_ibge":"4125555"
         },
         {
            "nome":"SAO MATEUS DO SUL",
            "codigo_ibge":"4125605"
         },
         {
            "nome":"SAO MIGUEL DO IGUACU",
            "codigo_ibge":"4125704"
         },
         {
            "nome":"SAO PEDRO DO IGUACU",
            "codigo_ibge":"4125753"
         },
         {
            "nome":"SAO PEDRO DO IVAI",
            "codigo_ibge":"4125803"
         },
         {
            "nome":"SAO PEDRO DO PARANA",
            "codigo_ibge":"4125902"
         },
         {
            "nome":"SAO SEBASTIAO DA AMOREIRA",
            "codigo_ibge":"4126009"
         },
         {
            "nome":"SAO TOME",
            "codigo_ibge":"4126108"
         },
         {
            "nome":"SAPOPEMA",
            "codigo_ibge":"4126207"
         },
         {
            "nome":"SARANDI",
            "codigo_ibge":"4126256"
         },
         {
            "nome":"SAUDADE DO IGUACU",
            "codigo_ibge":"4126272"
         },
         {
            "nome":"SENGES",
            "codigo_ibge":"4126306"
         },
         {
            "nome":"SERRANOPOLIS DO IGUACU",
            "codigo_ibge":"4126355"
         },
         {
            "nome":"SERTANEJA",
            "codigo_ibge":"4126405"
         },
         {
            "nome":"SERTANOPOLIS",
            "codigo_ibge":"4126504"
         },
         {
            "nome":"SIQUEIRA CAMPOS",
            "codigo_ibge":"4126603"
         },
         {
            "nome":"SULINA",
            "codigo_ibge":"4126652"
         },
         {
            "nome":"TAMARANA",
            "codigo_ibge":"4126678"
         },
         {
            "nome":"TAMBOARA",
            "codigo_ibge":"4126702"
         },
         {
            "nome":"TAPEJARA",
            "codigo_ibge":"4126801"
         },
         {
            "nome":"TAPIRA",
            "codigo_ibge":"4126900"
         },
         {
            "nome":"TEIXEIRA SOARES",
            "codigo_ibge":"4127007"
         },
         {
            "nome":"TELEMACO BORBA",
            "codigo_ibge":"4127106"
         },
         {
            "nome":"TERRA BOA",
            "codigo_ibge":"4127205"
         },
         {
            "nome":"TERRA RICA",
            "codigo_ibge":"4127304"
         },
         {
            "nome":"TERRA ROXA",
            "codigo_ibge":"4127403"
         },
         {
            "nome":"TIBAGI",
            "codigo_ibge":"4127502"
         },
         {
            "nome":"TIJUCAS DO SUL",
            "codigo_ibge":"4127601"
         },
         {
            "nome":"TOLEDO",
            "codigo_ibge":"4127700"
         },
         {
            "nome":"TOMAZINA",
            "codigo_ibge":"4127809"
         },
         {
            "nome":"TRES BARRAS DO PARANA",
            "codigo_ibge":"4127858"
         },
         {
            "nome":"TUNAS DO PARANA",
            "codigo_ibge":"4127882"
         },
         {
            "nome":"TUNEIRAS DO OESTE",
            "codigo_ibge":"4127908"
         },
         {
            "nome":"TUPASSI",
            "codigo_ibge":"4127957"
         },
         {
            "nome":"TURVO",
            "codigo_ibge":"4127965"
         },
         {
            "nome":"UBIRATA",
            "codigo_ibge":"4128005"
         },
         {
            "nome":"UMUARAMA",
            "codigo_ibge":"4128104"
         },
         {
            "nome":"UNIAO DA VITORIA",
            "codigo_ibge":"4128203"
         },
         {
            "nome":"UNIFLOR",
            "codigo_ibge":"4128302"
         },
         {
            "nome":"URAI",
            "codigo_ibge":"4128401"
         },
         {
            "nome":"WENCESLAU BRAZ",
            "codigo_ibge":"4128500"
         },
         {
            "nome":"VENTANIA",
            "codigo_ibge":"4128534"
         },
         {
            "nome":"VERA CRUZ DO OESTE",
            "codigo_ibge":"4128559"
         },
         {
            "nome":"VERE",
            "codigo_ibge":"4128609"
         },
         {
            "nome":"ALTO PARAISO",
            "codigo_ibge":"4128625"
         },
         {
            "nome":"DOUTOR ULYSSES",
            "codigo_ibge":"4128633"
         },
         {
            "nome":"VIRMOND",
            "codigo_ibge":"4128658"
         },
         {
            "nome":"VITORINO",
            "codigo_ibge":"4128708"
         },
         {
            "nome":"XAMBRE",
            "codigo_ibge":"4128807"
         }
      ]
   },
   {
      "estado" : "PB",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"AGUA BRANCA",
            "codigo_ibge":"2500106"
         },
         {
            "nome":"AGUIAR",
            "codigo_ibge":"2500205"
         },
         {
            "nome":"ALAGOA GRANDE",
            "codigo_ibge":"2500304"
         },
         {
            "nome":"ALAGOA NOVA",
            "codigo_ibge":"2500403"
         },
         {
            "nome":"ALAGOINHA",
            "codigo_ibge":"2500502"
         },
         {
            "nome":"ALCANTIL",
            "codigo_ibge":"2500536"
         },
         {
            "nome":"ALGODAO DE JANDAIRA",
            "codigo_ibge":"2500577"
         },
         {
            "nome":"ALHANDRA",
            "codigo_ibge":"2500601"
         },
         {
            "nome":"SAO JOAO DO RIO DO PEIXE",
            "codigo_ibge":"2500700"
         },
         {
            "nome":"AMPARO",
            "codigo_ibge":"2500734"
         },
         {
            "nome":"APARECIDA",
            "codigo_ibge":"2500775"
         },
         {
            "nome":"ARACAGI",
            "codigo_ibge":"2500809"
         },
         {
            "nome":"ARARA",
            "codigo_ibge":"2500908"
         },
         {
            "nome":"ARARUNA",
            "codigo_ibge":"2501005"
         },
         {
            "nome":"AREIA",
            "codigo_ibge":"2501104"
         },
         {
            "nome":"AREIA DE BARAUNAS",
            "codigo_ibge":"2501153"
         },
         {
            "nome":"AREIAL",
            "codigo_ibge":"2501203"
         },
         {
            "nome":"AROEIRAS",
            "codigo_ibge":"2501302"
         },
         {
            "nome":"ASSUNCAO",
            "codigo_ibge":"2501351"
         },
         {
            "nome":"BAIA DA TRAICAO",
            "codigo_ibge":"2501401"
         },
         {
            "nome":"BANANEIRAS",
            "codigo_ibge":"2501500"
         },
         {
            "nome":"BARAUNA",
            "codigo_ibge":"2501534"
         },
         {
            "nome":"BARRA DE SANTANA",
            "codigo_ibge":"2501575"
         },
         {
            "nome":"BARRA DE SANTA ROSA",
            "codigo_ibge":"2501609"
         },
         {
            "nome":"BARRA DE SAO MIGUEL",
            "codigo_ibge":"2501708"
         },
         {
            "nome":"BAYEUX",
            "codigo_ibge":"2501807"
         },
         {
            "nome":"BELEM",
            "codigo_ibge":"2501906"
         },
         {
            "nome":"BELEM DO BREJO DO CRUZ",
            "codigo_ibge":"2502003"
         },
         {
            "nome":"BERNARDINO BATISTA",
            "codigo_ibge":"2502052"
         },
         {
            "nome":"BOA VENTURA",
            "codigo_ibge":"2502102"
         },
         {
            "nome":"BOA VISTA",
            "codigo_ibge":"2502151"
         },
         {
            "nome":"BOM JESUS",
            "codigo_ibge":"2502201"
         },
         {
            "nome":"BOM SUCESSO",
            "codigo_ibge":"2502300"
         },
         {
            "nome":"BONITO DE SANTA FE",
            "codigo_ibge":"2502409"
         },
         {
            "nome":"BOQUEIRAO",
            "codigo_ibge":"2502508"
         },
         {
            "nome":"IGARACY",
            "codigo_ibge":"2502607"
         },
         {
            "nome":"BORBOREMA",
            "codigo_ibge":"2502706"
         },
         {
            "nome":"BREJO DO CRUZ",
            "codigo_ibge":"2502805"
         },
         {
            "nome":"BREJO DOS SANTOS",
            "codigo_ibge":"2502904"
         },
         {
            "nome":"CAAPORA",
            "codigo_ibge":"2503001"
         },
         {
            "nome":"CABACEIRAS",
            "codigo_ibge":"2503100"
         },
         {
            "nome":"CABEDELO",
            "codigo_ibge":"2503209"
         },
         {
            "nome":"CACHOEIRA DOS INDIOS",
            "codigo_ibge":"2503308"
         },
         {
            "nome":"CACIMBA DE AREIA",
            "codigo_ibge":"2503407"
         },
         {
            "nome":"CACIMBA DE DENTRO",
            "codigo_ibge":"2503506"
         },
         {
            "nome":"CACIMBAS",
            "codigo_ibge":"2503555"
         },
         {
            "nome":"CAICARA",
            "codigo_ibge":"2503605"
         },
         {
            "nome":"CAJAZEIRAS",
            "codigo_ibge":"2503704"
         },
         {
            "nome":"CAJAZEIRINHAS",
            "codigo_ibge":"2503753"
         },
         {
            "nome":"CALDAS BRANDAO",
            "codigo_ibge":"2503803"
         },
         {
            "nome":"CAMALAU",
            "codigo_ibge":"2503902"
         },
         {
            "nome":"CAMPINA GRANDE",
            "codigo_ibge":"2504009"
         },
         {
            "nome":"CAPIM",
            "codigo_ibge":"2504033"
         },
         {
            "nome":"CARAUBAS",
            "codigo_ibge":"2504074"
         },
         {
            "nome":"CARRAPATEIRA",
            "codigo_ibge":"2504108"
         },
         {
            "nome":"CASSERENGUE",
            "codigo_ibge":"2504157"
         },
         {
            "nome":"CATINGUEIRA",
            "codigo_ibge":"2504207"
         },
         {
            "nome":"CATOLE DO ROCHA",
            "codigo_ibge":"2504306"
         },
         {
            "nome":"CATURITE",
            "codigo_ibge":"2504355"
         },
         {
            "nome":"CONCEICAO",
            "codigo_ibge":"2504405"
         },
         {
            "nome":"CONDADO",
            "codigo_ibge":"2504504"
         },
         {
            "nome":"CONDE",
            "codigo_ibge":"2504603"
         },
         {
            "nome":"CONGO",
            "codigo_ibge":"2504702"
         },
         {
            "nome":"COREMAS",
            "codigo_ibge":"2504801"
         },
         {
            "nome":"COXIXOLA",
            "codigo_ibge":"2504850"
         },
         {
            "nome":"CRUZ DO ESPIRITO SANTO",
            "codigo_ibge":"2504900"
         },
         {
            "nome":"CUBATI",
            "codigo_ibge":"2505006"
         },
         {
            "nome":"CUITE",
            "codigo_ibge":"2505105"
         },
         {
            "nome":"CUITEGI",
            "codigo_ibge":"2505204"
         },
         {
            "nome":"CUITE DE MAMANGUAPE",
            "codigo_ibge":"2505238"
         },
         {
            "nome":"CURRAL DE CIMA",
            "codigo_ibge":"2505279"
         },
         {
            "nome":"CURRAL VELHO",
            "codigo_ibge":"2505303"
         },
         {
            "nome":"DAMIAO",
            "codigo_ibge":"2505352"
         },
         {
            "nome":"DESTERRO",
            "codigo_ibge":"2505402"
         },
         {
            "nome":"VISTA SERRANA",
            "codigo_ibge":"2505501"
         },
         {
            "nome":"DIAMANTE",
            "codigo_ibge":"2505600"
         },
         {
            "nome":"DONA INES",
            "codigo_ibge":"2505709"
         },
         {
            "nome":"DUAS ESTRADAS",
            "codigo_ibge":"2505808"
         },
         {
            "nome":"EMAS",
            "codigo_ibge":"2505907"
         },
         {
            "nome":"ESPERANCA",
            "codigo_ibge":"2506004"
         },
         {
            "nome":"FAGUNDES",
            "codigo_ibge":"2506103"
         },
         {
            "nome":"FREI MARTINHO",
            "codigo_ibge":"2506202"
         },
         {
            "nome":"GADO BRAVO",
            "codigo_ibge":"2506251"
         },
         {
            "nome":"GUARABIRA",
            "codigo_ibge":"2506301"
         },
         {
            "nome":"GURINHEM",
            "codigo_ibge":"2506400"
         },
         {
            "nome":"GURJAO",
            "codigo_ibge":"2506509"
         },
         {
            "nome":"IBIARA",
            "codigo_ibge":"2506608"
         },
         {
            "nome":"IMACULADA",
            "codigo_ibge":"2506707"
         },
         {
            "nome":"INGA",
            "codigo_ibge":"2506806"
         },
         {
            "nome":"ITABAIANA",
            "codigo_ibge":"2506905"
         },
         {
            "nome":"ITAPORANGA",
            "codigo_ibge":"2507002"
         },
         {
            "nome":"ITAPOROROCA",
            "codigo_ibge":"2507101"
         },
         {
            "nome":"ITATUBA",
            "codigo_ibge":"2507200"
         },
         {
            "nome":"JACARAU",
            "codigo_ibge":"2507309"
         },
         {
            "nome":"JERICO",
            "codigo_ibge":"2507408"
         },
         {
            "nome":"JOAO PESSOA",
            "codigo_ibge":"2507507"
         },
         {
            "nome":"JUAREZ TAVORA",
            "codigo_ibge":"2507606"
         },
         {
            "nome":"JUAZEIRINHO",
            "codigo_ibge":"2507705"
         },
         {
            "nome":"JUNCO DO SERIDO",
            "codigo_ibge":"2507804"
         },
         {
            "nome":"JURIPIRANGA",
            "codigo_ibge":"2507903"
         },
         {
            "nome":"JURU",
            "codigo_ibge":"2508000"
         },
         {
            "nome":"LAGOA",
            "codigo_ibge":"2508109"
         },
         {
            "nome":"LAGOA DE DENTRO",
            "codigo_ibge":"2508208"
         },
         {
            "nome":"LAGOA SECA",
            "codigo_ibge":"2508307"
         },
         {
            "nome":"LASTRO",
            "codigo_ibge":"2508406"
         },
         {
            "nome":"LIVRAMENTO",
            "codigo_ibge":"2508505"
         },
         {
            "nome":"LOGRADOURO",
            "codigo_ibge":"2508554"
         },
         {
            "nome":"LUCENA",
            "codigo_ibge":"2508604"
         },
         {
            "nome":"MAE D AGUA",
            "codigo_ibge":"2508703"
         },
         {
            "nome":"MALTA",
            "codigo_ibge":"2508802"
         },
         {
            "nome":"MAMANGUAPE",
            "codigo_ibge":"2508901"
         },
         {
            "nome":"MANAIRA",
            "codigo_ibge":"2509008"
         },
         {
            "nome":"MARCACAO",
            "codigo_ibge":"2509057"
         },
         {
            "nome":"MARI",
            "codigo_ibge":"2509107"
         },
         {
            "nome":"MARIZOPOLIS",
            "codigo_ibge":"2509156"
         },
         {
            "nome":"MASSARANDUBA",
            "codigo_ibge":"2509206"
         },
         {
            "nome":"MATARACA",
            "codigo_ibge":"2509305"
         },
         {
            "nome":"MATINHAS",
            "codigo_ibge":"2509339"
         },
         {
            "nome":"MATO GROSSO",
            "codigo_ibge":"2509370"
         },
         {
            "nome":"MATUREIA",
            "codigo_ibge":"2509396"
         },
         {
            "nome":"MOGEIRO",
            "codigo_ibge":"2509404"
         },
         {
            "nome":"MONTADAS",
            "codigo_ibge":"2509503"
         },
         {
            "nome":"MONTE HOREBE",
            "codigo_ibge":"2509602"
         },
         {
            "nome":"MONTEIRO",
            "codigo_ibge":"2509701"
         },
         {
            "nome":"MULUNGU",
            "codigo_ibge":"2509800"
         },
         {
            "nome":"NATUBA",
            "codigo_ibge":"2509909"
         },
         {
            "nome":"NAZAREZINHO",
            "codigo_ibge":"2510006"
         },
         {
            "nome":"NOVA FLORESTA",
            "codigo_ibge":"2510105"
         },
         {
            "nome":"NOVA OLINDA",
            "codigo_ibge":"2510204"
         },
         {
            "nome":"NOVA PALMEIRA",
            "codigo_ibge":"2510303"
         },
         {
            "nome":"OLHO D AGUA",
            "codigo_ibge":"2510402"
         },
         {
            "nome":"OLIVEDOS",
            "codigo_ibge":"2510501"
         },
         {
            "nome":"OURO VELHO",
            "codigo_ibge":"2510600"
         },
         {
            "nome":"PARARI",
            "codigo_ibge":"2510659"
         },
         {
            "nome":"PASSAGEM",
            "codigo_ibge":"2510709"
         },
         {
            "nome":"PATOS",
            "codigo_ibge":"2510808"
         },
         {
            "nome":"PAULISTA",
            "codigo_ibge":"2510907"
         },
         {
            "nome":"PEDRA BRANCA",
            "codigo_ibge":"2511004"
         },
         {
            "nome":"PEDRA LAVRADA",
            "codigo_ibge":"2511103"
         },
         {
            "nome":"PEDRAS DE FOGO",
            "codigo_ibge":"2511202"
         },
         {
            "nome":"PIANCO",
            "codigo_ibge":"2511301"
         },
         {
            "nome":"PICUI",
            "codigo_ibge":"2511400"
         },
         {
            "nome":"PILAR",
            "codigo_ibge":"2511509"
         },
         {
            "nome":"PILOES",
            "codigo_ibge":"2511608"
         },
         {
            "nome":"PILOEZINHOS",
            "codigo_ibge":"2511707"
         },
         {
            "nome":"PIRPIRITUBA",
            "codigo_ibge":"2511806"
         },
         {
            "nome":"PITIMBU",
            "codigo_ibge":"2511905"
         },
         {
            "nome":"POCINHOS",
            "codigo_ibge":"2512002"
         },
         {
            "nome":"POCO DANTAS",
            "codigo_ibge":"2512036"
         },
         {
            "nome":"POCO DE JOSE DE MOURA",
            "codigo_ibge":"2512077"
         },
         {
            "nome":"POMBAL",
            "codigo_ibge":"2512101"
         },
         {
            "nome":"PRATA",
            "codigo_ibge":"2512200"
         },
         {
            "nome":"PRINCESA ISABEL",
            "codigo_ibge":"2512309"
         },
         {
            "nome":"PUXINANA",
            "codigo_ibge":"2512408"
         },
         {
            "nome":"QUEIMADAS",
            "codigo_ibge":"2512507"
         },
         {
            "nome":"QUIXABA",
            "codigo_ibge":"2512606"
         },
         {
            "nome":"REMIGIO",
            "codigo_ibge":"2512705"
         },
         {
            "nome":"PEDRO REGIS",
            "codigo_ibge":"2512721"
         },
         {
            "nome":"RIACHAO",
            "codigo_ibge":"2512747"
         },
         {
            "nome":"RIACHAO DO BACAMARTE",
            "codigo_ibge":"2512754"
         },
         {
            "nome":"RIACHAO DO POCO",
            "codigo_ibge":"2512762"
         },
         {
            "nome":"RIACHO DE SANTO ANTONIO",
            "codigo_ibge":"2512788"
         },
         {
            "nome":"RIACHO DOS CAVALOS",
            "codigo_ibge":"2512804"
         },
         {
            "nome":"RIO TINTO",
            "codigo_ibge":"2512903"
         },
         {
            "nome":"SALGADINHO",
            "codigo_ibge":"2513000"
         },
         {
            "nome":"SALGADO DE SAO FELIX",
            "codigo_ibge":"2513109"
         },
         {
            "nome":"SANTA CECILIA",
            "codigo_ibge":"2513158"
         },
         {
            "nome":"SANTA CRUZ",
            "codigo_ibge":"2513208"
         },
         {
            "nome":"SANTA HELENA",
            "codigo_ibge":"2513307"
         },
         {
            "nome":"SANTA INES",
            "codigo_ibge":"2513356"
         },
         {
            "nome":"SANTA LUZIA",
            "codigo_ibge":"2513406"
         },
         {
            "nome":"SANTANA DE MANGUEIRA",
            "codigo_ibge":"2513505"
         },
         {
            "nome":"SANTANA DOS GARROTES",
            "codigo_ibge":"2513604"
         },
         {
            "nome":"JOCA CLAUDINO",
            "codigo_ibge":"2513653"
         },
         {
            "nome":"SANTA RITA",
            "codigo_ibge":"2513703"
         },
         {
            "nome":"SANTA TERESINHA",
            "codigo_ibge":"2513802"
         },
         {
            "nome":"SANTO ANDRE",
            "codigo_ibge":"2513851"
         },
         {
            "nome":"SAO BENTO",
            "codigo_ibge":"2513901"
         },
         {
            "nome":"SAO BENTINHO",
            "codigo_ibge":"2513927"
         },
         {
            "nome":"SAO DOMINGOS DO CARIRI",
            "codigo_ibge":"2513943"
         },
         {
            "nome":"SAO DOMINGOS",
            "codigo_ibge":"2513968"
         },
         {
            "nome":"SAO FRANCISCO",
            "codigo_ibge":"2513984"
         },
         {
            "nome":"SAO JOAO DO CARIRI",
            "codigo_ibge":"2514008"
         },
         {
            "nome":"SAO JOAO DO TIGRE",
            "codigo_ibge":"2514107"
         },
         {
            "nome":"SAO JOSE DA LAGOA TAPADA",
            "codigo_ibge":"2514206"
         },
         {
            "nome":"SAO JOSE DE CAIANA",
            "codigo_ibge":"2514305"
         },
         {
            "nome":"SAO JOSE DE ESPINHARAS",
            "codigo_ibge":"2514404"
         },
         {
            "nome":"SAO JOSE DOS RAMOS",
            "codigo_ibge":"2514453"
         },
         {
            "nome":"SAO JOSE DE PIRANHAS",
            "codigo_ibge":"2514503"
         },
         {
            "nome":"SAO JOSE DE PRINCESA",
            "codigo_ibge":"2514552"
         },
         {
            "nome":"SAO JOSE DO BONFIM",
            "codigo_ibge":"2514602"
         },
         {
            "nome":"SAO JOSE DO BREJO DO CRUZ",
            "codigo_ibge":"2514651"
         },
         {
            "nome":"SAO JOSE DO SABUGI",
            "codigo_ibge":"2514701"
         },
         {
            "nome":"SAO JOSE DOS CORDEIROS",
            "codigo_ibge":"2514800"
         },
         {
            "nome":"SAO MAMEDE",
            "codigo_ibge":"2514909"
         },
         {
            "nome":"SAO MIGUEL DE TAIPU",
            "codigo_ibge":"2515005"
         },
         {
            "nome":"SAO SEBASTIAO DE LAGOA DE ROCA",
            "codigo_ibge":"2515104"
         },
         {
            "nome":"SAO SEBASTIAO DO UMBUZEIRO",
            "codigo_ibge":"2515203"
         },
         {
            "nome":"SAPE",
            "codigo_ibge":"2515302"
         },
         {
            "nome":"SERIDO",
            "codigo_ibge":"2515401"
         },
         {
            "nome":"SERRA BRANCA",
            "codigo_ibge":"2515500"
         },
         {
            "nome":"SERRA DA RAIZ",
            "codigo_ibge":"2515609"
         },
         {
            "nome":"SERRA GRANDE",
            "codigo_ibge":"2515708"
         },
         {
            "nome":"SERRA REDONDA",
            "codigo_ibge":"2515807"
         },
         {
            "nome":"SERRARIA",
            "codigo_ibge":"2515906"
         },
         {
            "nome":"SERTAOZINHO",
            "codigo_ibge":"2515930"
         },
         {
            "nome":"SOBRADO",
            "codigo_ibge":"2515971"
         },
         {
            "nome":"SOLANEA",
            "codigo_ibge":"2516003"
         },
         {
            "nome":"SOLEDADE",
            "codigo_ibge":"2516102"
         },
         {
            "nome":"SOSSEGO",
            "codigo_ibge":"2516151"
         },
         {
            "nome":"SOUSA",
            "codigo_ibge":"2516201"
         },
         {
            "nome":"SUME",
            "codigo_ibge":"2516300"
         },
         {
            "nome":"TACIMA",
            "codigo_ibge":"2516409"
         },
         {
            "nome":"TAPEROA",
            "codigo_ibge":"2516508"
         },
         {
            "nome":"TAVARES",
            "codigo_ibge":"2516607"
         },
         {
            "nome":"TEIXEIRA",
            "codigo_ibge":"2516706"
         },
         {
            "nome":"TENORIO",
            "codigo_ibge":"2516755"
         },
         {
            "nome":"TRIUNFO",
            "codigo_ibge":"2516805"
         },
         {
            "nome":"UIRAUNA",
            "codigo_ibge":"2516904"
         },
         {
            "nome":"UMBUZEIRO",
            "codigo_ibge":"2517001"
         },
         {
            "nome":"VARZEA",
            "codigo_ibge":"2517100"
         },
         {
            "nome":"VIEIROPOLIS",
            "codigo_ibge":"2517209"
         },
         {
            "nome":"ZABELE",
            "codigo_ibge":"2517407"
         }
      ]
   },
   {
      "estado" : "PA",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ABAETETUBA",
            "codigo_ibge":"1500107"
         },
         {
            "nome":"ABEL FIGUEIREDO",
            "codigo_ibge":"1500131"
         },
         {
            "nome":"ACARA",
            "codigo_ibge":"1500206"
         },
         {
            "nome":"AFUA",
            "codigo_ibge":"1500305"
         },
         {
            "nome":"AGUA AZUL DO NORTE",
            "codigo_ibge":"1500347"
         },
         {
            "nome":"ALENQUER",
            "codigo_ibge":"1500404"
         },
         {
            "nome":"ALMEIRIM",
            "codigo_ibge":"1500503"
         },
         {
            "nome":"ALTAMIRA",
            "codigo_ibge":"1500602"
         },
         {
            "nome":"ANAJAS",
            "codigo_ibge":"1500701"
         },
         {
            "nome":"ANANINDEUA",
            "codigo_ibge":"1500800"
         },
         {
            "nome":"ANAPU",
            "codigo_ibge":"1500859"
         },
         {
            "nome":"AUGUSTO CORREA",
            "codigo_ibge":"1500909"
         },
         {
            "nome":"AURORA DO PARA",
            "codigo_ibge":"1500958"
         },
         {
            "nome":"AVEIRO",
            "codigo_ibge":"1501006"
         },
         {
            "nome":"BAGRE",
            "codigo_ibge":"1501105"
         },
         {
            "nome":"BAIAO",
            "codigo_ibge":"1501204"
         },
         {
            "nome":"BANNACH",
            "codigo_ibge":"1501253"
         },
         {
            "nome":"BARCARENA",
            "codigo_ibge":"1501303"
         },
         {
            "nome":"BELEM",
            "codigo_ibge":"1501402"
         },
         {
            "nome":"BELTERRA",
            "codigo_ibge":"1501451"
         },
         {
            "nome":"BENEVIDES",
            "codigo_ibge":"1501501"
         },
         {
            "nome":"BOM JESUS DO TOCANTINS",
            "codigo_ibge":"1501576"
         },
         {
            "nome":"BONITO",
            "codigo_ibge":"1501600"
         },
         {
            "nome":"BRAGANCA",
            "codigo_ibge":"1501709"
         },
         {
            "nome":"BRASIL NOVO",
            "codigo_ibge":"1501725"
         },
         {
            "nome":"BREJO GRANDE DO ARAGUAIA",
            "codigo_ibge":"1501758"
         },
         {
            "nome":"BREU BRANCO",
            "codigo_ibge":"1501782"
         },
         {
            "nome":"BREVES",
            "codigo_ibge":"1501808"
         },
         {
            "nome":"BUJARU",
            "codigo_ibge":"1501907"
         },
         {
            "nome":"CACHOEIRA DO PIRIA",
            "codigo_ibge":"1501956"
         },
         {
            "nome":"CACHOEIRA DO ARARI",
            "codigo_ibge":"1502004"
         },
         {
            "nome":"CAMETA",
            "codigo_ibge":"1502103"
         },
         {
            "nome":"CANAA DOS CARAJAS",
            "codigo_ibge":"1502152"
         },
         {
            "nome":"CAPANEMA",
            "codigo_ibge":"1502202"
         },
         {
            "nome":"CAPITAO POCO",
            "codigo_ibge":"1502301"
         },
         {
            "nome":"CASTANHAL",
            "codigo_ibge":"1502400"
         },
         {
            "nome":"CHAVES",
            "codigo_ibge":"1502509"
         },
         {
            "nome":"COLARES",
            "codigo_ibge":"1502608"
         },
         {
            "nome":"CONCEICAO DO ARAGUAIA",
            "codigo_ibge":"1502707"
         },
         {
            "nome":"CONCORDIA DO PARA",
            "codigo_ibge":"1502756"
         },
         {
            "nome":"CUMARU DO NORTE",
            "codigo_ibge":"1502764"
         },
         {
            "nome":"CURIONOPOLIS",
            "codigo_ibge":"1502772"
         },
         {
            "nome":"CURRALINHO",
            "codigo_ibge":"1502806"
         },
         {
            "nome":"CURUA",
            "codigo_ibge":"1502855"
         },
         {
            "nome":"CURUCA",
            "codigo_ibge":"1502905"
         },
         {
            "nome":"DOM ELISEU",
            "codigo_ibge":"1502939"
         },
         {
            "nome":"ELDORADO DOS CARAJAS",
            "codigo_ibge":"1502954"
         },
         {
            "nome":"FARO",
            "codigo_ibge":"1503002"
         },
         {
            "nome":"FLORESTA DO ARAGUAIA",
            "codigo_ibge":"1503044"
         },
         {
            "nome":"GARRAFAO DO NORTE",
            "codigo_ibge":"1503077"
         },
         {
            "nome":"GOIANESIA DO PARA",
            "codigo_ibge":"1503093"
         },
         {
            "nome":"GURUPA",
            "codigo_ibge":"1503101"
         },
         {
            "nome":"IGARAPE-ACU",
            "codigo_ibge":"1503200"
         },
         {
            "nome":"IGARAPE-MIRI",
            "codigo_ibge":"1503309"
         },
         {
            "nome":"INHANGAPI",
            "codigo_ibge":"1503408"
         },
         {
            "nome":"IPIXUNA DO PARA",
            "codigo_ibge":"1503457"
         },
         {
            "nome":"IRITUIA",
            "codigo_ibge":"1503507"
         },
         {
            "nome":"ITAITUBA",
            "codigo_ibge":"1503606"
         },
         {
            "nome":"ITUPIRANGA",
            "codigo_ibge":"1503705"
         },
         {
            "nome":"JACAREACANGA",
            "codigo_ibge":"1503754"
         },
         {
            "nome":"JACUNDA",
            "codigo_ibge":"1503804"
         },
         {
            "nome":"JURUTI",
            "codigo_ibge":"1503903"
         },
         {
            "nome":"LIMOEIRO DO AJURU",
            "codigo_ibge":"1504000"
         },
         {
            "nome":"MAE DO RIO",
            "codigo_ibge":"1504059"
         },
         {
            "nome":"MAGALHAES BARATA",
            "codigo_ibge":"1504109"
         },
         {
            "nome":"MARABA",
            "codigo_ibge":"1504208"
         },
         {
            "nome":"MARACANA",
            "codigo_ibge":"1504307"
         },
         {
            "nome":"MARAPANIM",
            "codigo_ibge":"1504406"
         },
         {
            "nome":"MARITUBA",
            "codigo_ibge":"1504422"
         },
         {
            "nome":"MEDICILANDIA",
            "codigo_ibge":"1504455"
         },
         {
            "nome":"MELGACO",
            "codigo_ibge":"1504505"
         },
         {
            "nome":"MOCAJUBA",
            "codigo_ibge":"1504604"
         },
         {
            "nome":"MOJU",
            "codigo_ibge":"1504703"
         },
         {
            "nome":"MOJUI DOS CAMPOS",
            "codigo_ibge":"1504752"
         },
         {
            "nome":"MONTE ALEGRE",
            "codigo_ibge":"1504802"
         },
         {
            "nome":"MUANA",
            "codigo_ibge":"1504901"
         },
         {
            "nome":"NOVA ESPERANCA DO PIRIA",
            "codigo_ibge":"1504950"
         },
         {
            "nome":"NOVA IPIXUNA",
            "codigo_ibge":"1504976"
         },
         {
            "nome":"NOVA TIMBOTEUA",
            "codigo_ibge":"1505007"
         },
         {
            "nome":"NOVO PROGRESSO",
            "codigo_ibge":"1505031"
         },
         {
            "nome":"NOVO REPARTIMENTO",
            "codigo_ibge":"1505064"
         },
         {
            "nome":"OBIDOS",
            "codigo_ibge":"1505106"
         },
         {
            "nome":"OEIRAS DO PARA",
            "codigo_ibge":"1505205"
         },
         {
            "nome":"ORIXIMINA",
            "codigo_ibge":"1505304"
         },
         {
            "nome":"OUREM",
            "codigo_ibge":"1505403"
         },
         {
            "nome":"OURILANDIA DO NORTE",
            "codigo_ibge":"1505437"
         },
         {
            "nome":"PACAJA",
            "codigo_ibge":"1505486"
         },
         {
            "nome":"PALESTINA DO PARA",
            "codigo_ibge":"1505494"
         },
         {
            "nome":"PARAGOMINAS",
            "codigo_ibge":"1505502"
         },
         {
            "nome":"PARAUAPEBAS",
            "codigo_ibge":"1505536"
         },
         {
            "nome":"PAU D ARCO",
            "codigo_ibge":"1505551"
         },
         {
            "nome":"PEIXE-BOI",
            "codigo_ibge":"1505601"
         },
         {
            "nome":"PICARRA",
            "codigo_ibge":"1505635"
         },
         {
            "nome":"PLACAS",
            "codigo_ibge":"1505650"
         },
         {
            "nome":"PONTA DE PEDRAS",
            "codigo_ibge":"1505700"
         },
         {
            "nome":"PORTEL",
            "codigo_ibge":"1505809"
         },
         {
            "nome":"PORTO DE MOZ",
            "codigo_ibge":"1505908"
         },
         {
            "nome":"PRAINHA",
            "codigo_ibge":"1506005"
         },
         {
            "nome":"PRIMAVERA",
            "codigo_ibge":"1506104"
         },
         {
            "nome":"QUATIPURU",
            "codigo_ibge":"1506112"
         },
         {
            "nome":"REDENCAO",
            "codigo_ibge":"1506138"
         },
         {
            "nome":"RIO MARIA",
            "codigo_ibge":"1506161"
         },
         {
            "nome":"RONDON DO PARA",
            "codigo_ibge":"1506187"
         },
         {
            "nome":"RUROPOLIS",
            "codigo_ibge":"1506195"
         },
         {
            "nome":"SALINOPOLIS",
            "codigo_ibge":"1506203"
         },
         {
            "nome":"SALVATERRA",
            "codigo_ibge":"1506302"
         },
         {
            "nome":"SANTA BARBARA DO PARA",
            "codigo_ibge":"1506351"
         },
         {
            "nome":"SANTA CRUZ DO ARARI",
            "codigo_ibge":"1506401"
         },
         {
            "nome":"SANTA ISABEL DO PARA",
            "codigo_ibge":"1506500"
         },
         {
            "nome":"SANTA LUZIA DO PARA",
            "codigo_ibge":"1506559"
         },
         {
            "nome":"SANTA MARIA DAS BARREIRAS",
            "codigo_ibge":"1506583"
         },
         {
            "nome":"SANTA MARIA DO PARA",
            "codigo_ibge":"1506609"
         },
         {
            "nome":"SANTANA DO ARAGUAIA",
            "codigo_ibge":"1506708"
         },
         {
            "nome":"SANTAREM",
            "codigo_ibge":"1506807"
         },
         {
            "nome":"SANTAREM NOVO",
            "codigo_ibge":"1506906"
         },
         {
            "nome":"SANTO ANTONIO DO TAUA",
            "codigo_ibge":"1507003"
         },
         {
            "nome":"SAO CAETANO DE ODIVELAS",
            "codigo_ibge":"1507102"
         },
         {
            "nome":"SAO DOMINGOS DO ARAGUAIA",
            "codigo_ibge":"1507151"
         },
         {
            "nome":"SAO DOMINGOS DO CAPIM",
            "codigo_ibge":"1507201"
         },
         {
            "nome":"SAO FELIX DO XINGU",
            "codigo_ibge":"1507300"
         },
         {
            "nome":"SAO FRANCISCO DO PARA",
            "codigo_ibge":"1507409"
         },
         {
            "nome":"SAO GERALDO DO ARAGUAIA",
            "codigo_ibge":"1507458"
         },
         {
            "nome":"SAO JOAO DA PONTA",
            "codigo_ibge":"1507466"
         },
         {
            "nome":"SAO JOAO DE PIRABAS",
            "codigo_ibge":"1507474"
         },
         {
            "nome":"SAO JOAO DO ARAGUAIA",
            "codigo_ibge":"1507508"
         },
         {
            "nome":"SAO MIGUEL DO GUAMA",
            "codigo_ibge":"1507607"
         },
         {
            "nome":"SAO SEBASTIAO DA BOA VISTA",
            "codigo_ibge":"1507706"
         },
         {
            "nome":"SAPUCAIA",
            "codigo_ibge":"1507755"
         },
         {
            "nome":"SENADOR JOSE PORFIRIO",
            "codigo_ibge":"1507805"
         },
         {
            "nome":"SOURE",
            "codigo_ibge":"1507904"
         },
         {
            "nome":"TAILANDIA",
            "codigo_ibge":"1507953"
         },
         {
            "nome":"TERRA ALTA",
            "codigo_ibge":"1507961"
         },
         {
            "nome":"TERRA SANTA",
            "codigo_ibge":"1507979"
         },
         {
            "nome":"TOME-ACU",
            "codigo_ibge":"1508001"
         },
         {
            "nome":"TRACUATEUA",
            "codigo_ibge":"1508035"
         },
         {
            "nome":"TRAIRAO",
            "codigo_ibge":"1508050"
         },
         {
            "nome":"TUCUMA",
            "codigo_ibge":"1508084"
         },
         {
            "nome":"TUCURUI",
            "codigo_ibge":"1508100"
         },
         {
            "nome":"ULIANOPOLIS",
            "codigo_ibge":"1508126"
         },
         {
            "nome":"URUARA",
            "codigo_ibge":"1508159"
         },
         {
            "nome":"VIGIA",
            "codigo_ibge":"1508209"
         },
         {
            "nome":"VISEU",
            "codigo_ibge":"1508308"
         },
         {
            "nome":"VITORIA DO XINGU",
            "codigo_ibge":"1508357"
         },
         {
            "nome":"XINGUARA",
            "codigo_ibge":"1508407"
         }
      ]
   },
   {
      "estado" : "MG",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ABADIA DOS DOURADOS",
            "codigo_ibge":"3100104"
         },
         {
            "nome":"ABAETE",
            "codigo_ibge":"3100203"
         },
         {
            "nome":"ABRE CAMPO",
            "codigo_ibge":"3100302"
         },
         {
            "nome":"ACAIACA",
            "codigo_ibge":"3100401"
         },
         {
            "nome":"ACUCENA",
            "codigo_ibge":"3100500"
         },
         {
            "nome":"AGUA BOA",
            "codigo_ibge":"3100609"
         },
         {
            "nome":"AGUA COMPRIDA",
            "codigo_ibge":"3100708"
         },
         {
            "nome":"AGUANIL",
            "codigo_ibge":"3100807"
         },
         {
            "nome":"AGUAS FORMOSAS",
            "codigo_ibge":"3100906"
         },
         {
            "nome":"AGUAS VERMELHAS",
            "codigo_ibge":"3101003"
         },
         {
            "nome":"AIMORES",
            "codigo_ibge":"3101102"
         },
         {
            "nome":"AIURUOCA",
            "codigo_ibge":"3101201"
         },
         {
            "nome":"ALAGOA",
            "codigo_ibge":"3101300"
         },
         {
            "nome":"ALBERTINA",
            "codigo_ibge":"3101409"
         },
         {
            "nome":"ALEM PARAIBA",
            "codigo_ibge":"3101508"
         },
         {
            "nome":"ALFENAS",
            "codigo_ibge":"3101607"
         },
         {
            "nome":"ALFREDO VASCONCELOS",
            "codigo_ibge":"3101631"
         },
         {
            "nome":"ALMENARA",
            "codigo_ibge":"3101706"
         },
         {
            "nome":"ALPERCATA",
            "codigo_ibge":"3101805"
         },
         {
            "nome":"ALPINOPOLIS",
            "codigo_ibge":"3101904"
         },
         {
            "nome":"ALTEROSA",
            "codigo_ibge":"3102001"
         },
         {
            "nome":"ALTO CAPARAO",
            "codigo_ibge":"3102050"
         },
         {
            "nome":"ALTO RIO DOCE",
            "codigo_ibge":"3102100"
         },
         {
            "nome":"ALVARENGA",
            "codigo_ibge":"3102209"
         },
         {
            "nome":"ALVINOPOLIS",
            "codigo_ibge":"3102308"
         },
         {
            "nome":"ALVORADA DE MINAS",
            "codigo_ibge":"3102407"
         },
         {
            "nome":"AMPARO DO SERRA",
            "codigo_ibge":"3102506"
         },
         {
            "nome":"ANDRADAS",
            "codigo_ibge":"3102605"
         },
         {
            "nome":"CACHOEIRA DE PAJEU",
            "codigo_ibge":"3102704"
         },
         {
            "nome":"ANDRELANDIA",
            "codigo_ibge":"3102803"
         },
         {
            "nome":"ANGELANDIA",
            "codigo_ibge":"3102852"
         },
         {
            "nome":"ANTONIO CARLOS",
            "codigo_ibge":"3102902"
         },
         {
            "nome":"ANTONIO DIAS",
            "codigo_ibge":"3103009"
         },
         {
            "nome":"ANTONIO PRADO DE MINAS",
            "codigo_ibge":"3103108"
         },
         {
            "nome":"ARACAI",
            "codigo_ibge":"3103207"
         },
         {
            "nome":"ARACITABA",
            "codigo_ibge":"3103306"
         },
         {
            "nome":"ARACUAI",
            "codigo_ibge":"3103405"
         },
         {
            "nome":"ARAGUARI",
            "codigo_ibge":"3103504"
         },
         {
            "nome":"ARANTINA",
            "codigo_ibge":"3103603"
         },
         {
            "nome":"ARAPONGA",
            "codigo_ibge":"3103702"
         },
         {
            "nome":"ARAPORA",
            "codigo_ibge":"3103751"
         },
         {
            "nome":"ARAPUA",
            "codigo_ibge":"3103801"
         },
         {
            "nome":"ARAUJOS",
            "codigo_ibge":"3103900"
         },
         {
            "nome":"ARAXA",
            "codigo_ibge":"3104007"
         },
         {
            "nome":"ARCEBURGO",
            "codigo_ibge":"3104106"
         },
         {
            "nome":"ARCOS",
            "codigo_ibge":"3104205"
         },
         {
            "nome":"AREADO",
            "codigo_ibge":"3104304"
         },
         {
            "nome":"ARGIRITA",
            "codigo_ibge":"3104403"
         },
         {
            "nome":"ARICANDUVA",
            "codigo_ibge":"3104452"
         },
         {
            "nome":"ARINOS",
            "codigo_ibge":"3104502"
         },
         {
            "nome":"ASTOLFO DUTRA",
            "codigo_ibge":"3104601"
         },
         {
            "nome":"ATALEIA",
            "codigo_ibge":"3104700"
         },
         {
            "nome":"AUGUSTO DE LIMA",
            "codigo_ibge":"3104809"
         },
         {
            "nome":"BAEPENDI",
            "codigo_ibge":"3104908"
         },
         {
            "nome":"BALDIM",
            "codigo_ibge":"3105004"
         },
         {
            "nome":"BAMBUI",
            "codigo_ibge":"3105103"
         },
         {
            "nome":"BANDEIRA",
            "codigo_ibge":"3105202"
         },
         {
            "nome":"BANDEIRA DO SUL",
            "codigo_ibge":"3105301"
         },
         {
            "nome":"BARAO DE COCAIS",
            "codigo_ibge":"3105400"
         },
         {
            "nome":"BARAO DE MONTE ALTO",
            "codigo_ibge":"3105509"
         },
         {
            "nome":"BARBACENA",
            "codigo_ibge":"3105608"
         },
         {
            "nome":"BARRA LONGA",
            "codigo_ibge":"3105707"
         },
         {
            "nome":"BARROSO",
            "codigo_ibge":"3105905"
         },
         {
            "nome":"BELA VISTA DE MINAS",
            "codigo_ibge":"3106002"
         },
         {
            "nome":"BELMIRO BRAGA",
            "codigo_ibge":"3106101"
         },
         {
            "nome":"BELO HORIZONTE",
            "codigo_ibge":"3106200"
         },
         {
            "nome":"BELO ORIENTE",
            "codigo_ibge":"3106309"
         },
         {
            "nome":"BELO VALE",
            "codigo_ibge":"3106408"
         },
         {
            "nome":"BERILO",
            "codigo_ibge":"3106507"
         },
         {
            "nome":"BERTOPOLIS",
            "codigo_ibge":"3106606"
         },
         {
            "nome":"BERIZAL",
            "codigo_ibge":"3106655"
         },
         {
            "nome":"BETIM",
            "codigo_ibge":"3106705"
         },
         {
            "nome":"BIAS FORTES",
            "codigo_ibge":"3106804"
         },
         {
            "nome":"BICAS",
            "codigo_ibge":"3106903"
         },
         {
            "nome":"BIQUINHAS",
            "codigo_ibge":"3107000"
         },
         {
            "nome":"BOA ESPERANCA",
            "codigo_ibge":"3107109"
         },
         {
            "nome":"BOCAINA DE MINAS",
            "codigo_ibge":"3107208"
         },
         {
            "nome":"BOCAIUVA",
            "codigo_ibge":"3107307"
         },
         {
            "nome":"BOM DESPACHO",
            "codigo_ibge":"3107406"
         },
         {
            "nome":"BOM JARDIM DE MINAS",
            "codigo_ibge":"3107505"
         },
         {
            "nome":"BOM JESUS DA PENHA",
            "codigo_ibge":"3107604"
         },
         {
            "nome":"BOM JESUS DO AMPARO",
            "codigo_ibge":"3107703"
         },
         {
            "nome":"BOM JESUS DO GALHO",
            "codigo_ibge":"3107802"
         },
         {
            "nome":"BOM REPOUSO",
            "codigo_ibge":"3107901"
         },
         {
            "nome":"BOM SUCESSO",
            "codigo_ibge":"3108008"
         },
         {
            "nome":"BONFIM",
            "codigo_ibge":"3108107"
         },
         {
            "nome":"BONFINOPOLIS DE MINAS",
            "codigo_ibge":"3108206"
         },
         {
            "nome":"BONITO DE MINAS",
            "codigo_ibge":"3108255"
         },
         {
            "nome":"BORDA DA MATA",
            "codigo_ibge":"3108305"
         },
         {
            "nome":"BOTELHOS",
            "codigo_ibge":"3108404"
         },
         {
            "nome":"BOTUMIRIM",
            "codigo_ibge":"3108503"
         },
         {
            "nome":"BRASILANDIA DE MINAS",
            "codigo_ibge":"3108552"
         },
         {
            "nome":"BRASILIA DE MINAS",
            "codigo_ibge":"3108602"
         },
         {
            "nome":"BRAS PIRES",
            "codigo_ibge":"3108701"
         },
         {
            "nome":"BRAUNAS",
            "codigo_ibge":"3108800"
         },
         {
            "nome":"BRASOPOLIS",
            "codigo_ibge":"3108909"
         },
         {
            "nome":"BRUMADINHO",
            "codigo_ibge":"3109006"
         },
         {
            "nome":"BUENO BRANDAO",
            "codigo_ibge":"3109105"
         },
         {
            "nome":"BUENOPOLIS",
            "codigo_ibge":"3109204"
         },
         {
            "nome":"BUGRE",
            "codigo_ibge":"3109253"
         },
         {
            "nome":"BURITIS",
            "codigo_ibge":"3109303"
         },
         {
            "nome":"BURITIZEIRO",
            "codigo_ibge":"3109402"
         },
         {
            "nome":"CABECEIRA GRANDE",
            "codigo_ibge":"3109451"
         },
         {
            "nome":"CABO VERDE",
            "codigo_ibge":"3109501"
         },
         {
            "nome":"CACHOEIRA DA PRATA",
            "codigo_ibge":"3109600"
         },
         {
            "nome":"CACHOEIRA DE MINAS",
            "codigo_ibge":"3109709"
         },
         {
            "nome":"CACHOEIRA DOURADA",
            "codigo_ibge":"3109808"
         },
         {
            "nome":"CAETANOPOLIS",
            "codigo_ibge":"3109907"
         },
         {
            "nome":"CAETE",
            "codigo_ibge":"3110004"
         },
         {
            "nome":"CAIANA",
            "codigo_ibge":"3110103"
         },
         {
            "nome":"CAJURI",
            "codigo_ibge":"3110202"
         },
         {
            "nome":"CALDAS",
            "codigo_ibge":"3110301"
         },
         {
            "nome":"CAMACHO",
            "codigo_ibge":"3110400"
         },
         {
            "nome":"CAMANDUCAIA",
            "codigo_ibge":"3110509"
         },
         {
            "nome":"CAMBUI",
            "codigo_ibge":"3110608"
         },
         {
            "nome":"CAMBUQUIRA",
            "codigo_ibge":"3110707"
         },
         {
            "nome":"CAMPANARIO",
            "codigo_ibge":"3110806"
         },
         {
            "nome":"CAMPANHA",
            "codigo_ibge":"3110905"
         },
         {
            "nome":"CAMPESTRE",
            "codigo_ibge":"3111002"
         },
         {
            "nome":"CAMPINA VERDE",
            "codigo_ibge":"3111101"
         },
         {
            "nome":"CAMPO AZUL",
            "codigo_ibge":"3111150"
         },
         {
            "nome":"CAMPO BELO",
            "codigo_ibge":"3111200"
         },
         {
            "nome":"CAMPO DO MEIO",
            "codigo_ibge":"3111309"
         },
         {
            "nome":"CAMPO FLORIDO",
            "codigo_ibge":"3111408"
         },
         {
            "nome":"CAMPOS ALTOS",
            "codigo_ibge":"3111507"
         },
         {
            "nome":"CAMPOS GERAIS",
            "codigo_ibge":"3111606"
         },
         {
            "nome":"CANAA",
            "codigo_ibge":"3111705"
         },
         {
            "nome":"CANAPOLIS",
            "codigo_ibge":"3111804"
         },
         {
            "nome":"CANA VERDE",
            "codigo_ibge":"3111903"
         },
         {
            "nome":"CANDEIAS",
            "codigo_ibge":"3112000"
         },
         {
            "nome":"CANTAGALO",
            "codigo_ibge":"3112059"
         },
         {
            "nome":"CAPARAO",
            "codigo_ibge":"3112109"
         },
         {
            "nome":"CAPELA NOVA",
            "codigo_ibge":"3112208"
         },
         {
            "nome":"CAPELINHA",
            "codigo_ibge":"3112307"
         },
         {
            "nome":"CAPETINGA",
            "codigo_ibge":"3112406"
         },
         {
            "nome":"CAPIM BRANCO",
            "codigo_ibge":"3112505"
         },
         {
            "nome":"CAPINOPOLIS",
            "codigo_ibge":"3112604"
         },
         {
            "nome":"CAPITAO ANDRADE",
            "codigo_ibge":"3112653"
         },
         {
            "nome":"CAPITAO ENEAS",
            "codigo_ibge":"3112703"
         },
         {
            "nome":"CAPITOLIO",
            "codigo_ibge":"3112802"
         },
         {
            "nome":"CAPUTIRA",
            "codigo_ibge":"3112901"
         },
         {
            "nome":"CARAI",
            "codigo_ibge":"3113008"
         },
         {
            "nome":"CARANAIBA",
            "codigo_ibge":"3113107"
         },
         {
            "nome":"CARANDAI",
            "codigo_ibge":"3113206"
         },
         {
            "nome":"CARANGOLA",
            "codigo_ibge":"3113305"
         },
         {
            "nome":"CARATINGA",
            "codigo_ibge":"3113404"
         },
         {
            "nome":"CARBONITA",
            "codigo_ibge":"3113503"
         },
         {
            "nome":"CAREACU",
            "codigo_ibge":"3113602"
         },
         {
            "nome":"CARLOS CHAGAS",
            "codigo_ibge":"3113701"
         },
         {
            "nome":"CARMESIA",
            "codigo_ibge":"3113800"
         },
         {
            "nome":"CARMO DA CACHOEIRA",
            "codigo_ibge":"3113909"
         },
         {
            "nome":"CARMO DA MATA",
            "codigo_ibge":"3114006"
         },
         {
            "nome":"CARMO DE MINAS",
            "codigo_ibge":"3114105"
         },
         {
            "nome":"CARMO DO CAJURU",
            "codigo_ibge":"3114204"
         },
         {
            "nome":"CARMO DO PARANAIBA",
            "codigo_ibge":"3114303"
         },
         {
            "nome":"CARMO DO RIO CLARO",
            "codigo_ibge":"3114402"
         },
         {
            "nome":"CARMOPOLIS DE MINAS",
            "codigo_ibge":"3114501"
         },
         {
            "nome":"CARNEIRINHO",
            "codigo_ibge":"3114550"
         },
         {
            "nome":"CARRANCAS",
            "codigo_ibge":"3114600"
         },
         {
            "nome":"CARVALHOPOLIS",
            "codigo_ibge":"3114709"
         },
         {
            "nome":"CARVALHOS",
            "codigo_ibge":"3114808"
         },
         {
            "nome":"CASA GRANDE",
            "codigo_ibge":"3114907"
         },
         {
            "nome":"CASCALHO RICO",
            "codigo_ibge":"3115003"
         },
         {
            "nome":"CASSIA",
            "codigo_ibge":"3115102"
         },
         {
            "nome":"CONCEICAO DA BARRA DE MINAS",
            "codigo_ibge":"3115201"
         },
         {
            "nome":"CATAGUASES",
            "codigo_ibge":"3115300"
         },
         {
            "nome":"CATAS ALTAS",
            "codigo_ibge":"3115359"
         },
         {
            "nome":"CATAS ALTAS DA NORUEGA",
            "codigo_ibge":"3115409"
         },
         {
            "nome":"CATUJI",
            "codigo_ibge":"3115458"
         },
         {
            "nome":"CATUTI",
            "codigo_ibge":"3115474"
         },
         {
            "nome":"CAXAMBU",
            "codigo_ibge":"3115508"
         },
         {
            "nome":"CEDRO DO ABAETE",
            "codigo_ibge":"3115607"
         },
         {
            "nome":"CENTRAL DE MINAS",
            "codigo_ibge":"3115706"
         },
         {
            "nome":"CENTRALINA",
            "codigo_ibge":"3115805"
         },
         {
            "nome":"CHACARA",
            "codigo_ibge":"3115904"
         },
         {
            "nome":"CHALE",
            "codigo_ibge":"3116001"
         },
         {
            "nome":"CHAPADA DO NORTE",
            "codigo_ibge":"3116100"
         },
         {
            "nome":"CHAPADA GAUCHA",
            "codigo_ibge":"3116159"
         },
         {
            "nome":"CHIADOR",
            "codigo_ibge":"3116209"
         },
         {
            "nome":"CIPOTANEA",
            "codigo_ibge":"3116308"
         },
         {
            "nome":"CLARAVAL",
            "codigo_ibge":"3116407"
         },
         {
            "nome":"CLARO DOS POCOES",
            "codigo_ibge":"3116506"
         },
         {
            "nome":"CLAUDIO",
            "codigo_ibge":"3116605"
         },
         {
            "nome":"COIMBRA",
            "codigo_ibge":"3116704"
         },
         {
            "nome":"COLUNA",
            "codigo_ibge":"3116803"
         },
         {
            "nome":"COMENDADOR GOMES",
            "codigo_ibge":"3116902"
         },
         {
            "nome":"COMERCINHO",
            "codigo_ibge":"3117009"
         },
         {
            "nome":"CONCEICAO DA APARECIDA",
            "codigo_ibge":"3117108"
         },
         {
            "nome":"CONCEICAO DAS PEDRAS",
            "codigo_ibge":"3117207"
         },
         {
            "nome":"CONCEICAO DAS ALAGOAS",
            "codigo_ibge":"3117306"
         },
         {
            "nome":"CONCEICAO DE IPANEMA",
            "codigo_ibge":"3117405"
         },
         {
            "nome":"CONCEICAO DO MATO DENTRO",
            "codigo_ibge":"3117504"
         },
         {
            "nome":"CONCEICAO DO PARA",
            "codigo_ibge":"3117603"
         },
         {
            "nome":"CONCEICAO DO RIO VERDE",
            "codigo_ibge":"3117702"
         },
         {
            "nome":"CONCEICAO DOS OUROS",
            "codigo_ibge":"3117801"
         },
         {
            "nome":"CONEGO MARINHO",
            "codigo_ibge":"3117836"
         },
         {
            "nome":"CONFINS",
            "codigo_ibge":"3117876"
         },
         {
            "nome":"CONGONHAL",
            "codigo_ibge":"3117900"
         },
         {
            "nome":"CONGONHAS",
            "codigo_ibge":"3118007"
         },
         {
            "nome":"CONGONHAS DO NORTE",
            "codigo_ibge":"3118106"
         },
         {
            "nome":"CONQUISTA",
            "codigo_ibge":"3118205"
         },
         {
            "nome":"CONSELHEIRO LAFAIETE",
            "codigo_ibge":"3118304"
         },
         {
            "nome":"CONSELHEIRO PENA",
            "codigo_ibge":"3118403"
         },
         {
            "nome":"CONSOLACAO",
            "codigo_ibge":"3118502"
         },
         {
            "nome":"CONTAGEM",
            "codigo_ibge":"3118601"
         },
         {
            "nome":"COQUEIRAL",
            "codigo_ibge":"3118700"
         },
         {
            "nome":"CORACAO DE JESUS",
            "codigo_ibge":"3118809"
         },
         {
            "nome":"CORDISBURGO",
            "codigo_ibge":"3118908"
         },
         {
            "nome":"CORDISLANDIA",
            "codigo_ibge":"3119005"
         },
         {
            "nome":"CORINTO",
            "codigo_ibge":"3119104"
         },
         {
            "nome":"COROACI",
            "codigo_ibge":"3119203"
         },
         {
            "nome":"COROMANDEL",
            "codigo_ibge":"3119302"
         },
         {
            "nome":"CORONEL FABRICIANO",
            "codigo_ibge":"3119401"
         },
         {
            "nome":"CORONEL MURTA",
            "codigo_ibge":"3119500"
         },
         {
            "nome":"CORONEL PACHECO",
            "codigo_ibge":"3119609"
         },
         {
            "nome":"CORONEL XAVIER CHAVES",
            "codigo_ibge":"3119708"
         },
         {
            "nome":"CORREGO DANTA",
            "codigo_ibge":"3119807"
         },
         {
            "nome":"CORREGO DO BOM JESUS",
            "codigo_ibge":"3119906"
         },
         {
            "nome":"CORREGO FUNDO",
            "codigo_ibge":"3119955"
         },
         {
            "nome":"CORREGO NOVO",
            "codigo_ibge":"3120003"
         },
         {
            "nome":"COUTO DE MAGALHAES DE MINAS",
            "codigo_ibge":"3120102"
         },
         {
            "nome":"CRISOLITA",
            "codigo_ibge":"3120151"
         },
         {
            "nome":"CRISTAIS",
            "codigo_ibge":"3120201"
         },
         {
            "nome":"CRISTALIA",
            "codigo_ibge":"3120300"
         },
         {
            "nome":"CRISTIANO OTONI",
            "codigo_ibge":"3120409"
         },
         {
            "nome":"CRISTINA",
            "codigo_ibge":"3120508"
         },
         {
            "nome":"CRUCILANDIA",
            "codigo_ibge":"3120607"
         },
         {
            "nome":"CRUZEIRO DA FORTALEZA",
            "codigo_ibge":"3120706"
         },
         {
            "nome":"CRUZILIA",
            "codigo_ibge":"3120805"
         },
         {
            "nome":"CUPARAQUE",
            "codigo_ibge":"3120839"
         },
         {
            "nome":"CURRAL DE DENTRO",
            "codigo_ibge":"3120870"
         },
         {
            "nome":"CURVELO",
            "codigo_ibge":"3120904"
         },
         {
            "nome":"DATAS",
            "codigo_ibge":"3121001"
         },
         {
            "nome":"DELFIM MOREIRA",
            "codigo_ibge":"3121100"
         },
         {
            "nome":"DELFINOPOLIS",
            "codigo_ibge":"3121209"
         },
         {
            "nome":"DELTA",
            "codigo_ibge":"3121258"
         },
         {
            "nome":"DESCOBERTO",
            "codigo_ibge":"3121308"
         },
         {
            "nome":"DESTERRO DE ENTRE RIOS",
            "codigo_ibge":"3121407"
         },
         {
            "nome":"DESTERRO DO MELO",
            "codigo_ibge":"3121506"
         },
         {
            "nome":"DIAMANTINA",
            "codigo_ibge":"3121605"
         },
         {
            "nome":"DIOGO DE VASCONCELOS",
            "codigo_ibge":"3121704"
         },
         {
            "nome":"DIONISIO",
            "codigo_ibge":"3121803"
         },
         {
            "nome":"DIVINESIA",
            "codigo_ibge":"3121902"
         },
         {
            "nome":"DIVINO",
            "codigo_ibge":"3122009"
         },
         {
            "nome":"DIVINO DAS LARANJEIRAS",
            "codigo_ibge":"3122108"
         },
         {
            "nome":"DIVINOLANDIA DE MINAS",
            "codigo_ibge":"3122207"
         },
         {
            "nome":"DIVINOPOLIS",
            "codigo_ibge":"3122306"
         },
         {
            "nome":"DIVISA ALEGRE",
            "codigo_ibge":"3122355"
         },
         {
            "nome":"DIVISA NOVA",
            "codigo_ibge":"3122405"
         },
         {
            "nome":"DIVISOPOLIS",
            "codigo_ibge":"3122454"
         },
         {
            "nome":"DOM BOSCO",
            "codigo_ibge":"3122470"
         },
         {
            "nome":"DOM CAVATI",
            "codigo_ibge":"3122504"
         },
         {
            "nome":"DOM JOAQUIM",
            "codigo_ibge":"3122603"
         },
         {
            "nome":"DOM SILVERIO",
            "codigo_ibge":"3122702"
         },
         {
            "nome":"DOM VICOSO",
            "codigo_ibge":"3122801"
         },
         {
            "nome":"DONA EUSEBIA",
            "codigo_ibge":"3122900"
         },
         {
            "nome":"DORES DE CAMPOS",
            "codigo_ibge":"3123007"
         },
         {
            "nome":"DORES DE GUANHAES",
            "codigo_ibge":"3123106"
         },
         {
            "nome":"DORES DO INDAIA",
            "codigo_ibge":"3123205"
         },
         {
            "nome":"DORES DO TURVO",
            "codigo_ibge":"3123304"
         },
         {
            "nome":"DORESOPOLIS",
            "codigo_ibge":"3123403"
         },
         {
            "nome":"DOURADOQUARA",
            "codigo_ibge":"3123502"
         },
         {
            "nome":"DURANDE",
            "codigo_ibge":"3123528"
         },
         {
            "nome":"ELOI MENDES",
            "codigo_ibge":"3123601"
         },
         {
            "nome":"ENGENHEIRO CALDAS",
            "codigo_ibge":"3123700"
         },
         {
            "nome":"ENGENHEIRO NAVARRO",
            "codigo_ibge":"3123809"
         },
         {
            "nome":"ENTRE FOLHAS",
            "codigo_ibge":"3123858"
         },
         {
            "nome":"ENTRE RIOS DE MINAS",
            "codigo_ibge":"3123908"
         },
         {
            "nome":"ERVALIA",
            "codigo_ibge":"3124005"
         },
         {
            "nome":"ESMERALDAS",
            "codigo_ibge":"3124104"
         },
         {
            "nome":"ESPERA FELIZ",
            "codigo_ibge":"3124203"
         },
         {
            "nome":"ESPINOSA",
            "codigo_ibge":"3124302"
         },
         {
            "nome":"ESPIRITO SANTO DO DOURADO",
            "codigo_ibge":"3124401"
         },
         {
            "nome":"ESTIVA",
            "codigo_ibge":"3124500"
         },
         {
            "nome":"ESTRELA DALVA",
            "codigo_ibge":"3124609"
         },
         {
            "nome":"ESTRELA DO INDAIA",
            "codigo_ibge":"3124708"
         },
         {
            "nome":"ESTRELA DO SUL",
            "codigo_ibge":"3124807"
         },
         {
            "nome":"EUGENOPOLIS",
            "codigo_ibge":"3124906"
         },
         {
            "nome":"EWBANK DA CAMARA",
            "codigo_ibge":"3125002"
         },
         {
            "nome":"EXTREMA",
            "codigo_ibge":"3125101"
         },
         {
            "nome":"FAMA",
            "codigo_ibge":"3125200"
         },
         {
            "nome":"FARIA LEMOS",
            "codigo_ibge":"3125309"
         },
         {
            "nome":"FELICIO DOS SANTOS",
            "codigo_ibge":"3125408"
         },
         {
            "nome":"SAO GONCALO DO RIO PRETO",
            "codigo_ibge":"3125507"
         },
         {
            "nome":"FELISBURGO",
            "codigo_ibge":"3125606"
         },
         {
            "nome":"FELIXLANDIA",
            "codigo_ibge":"3125705"
         },
         {
            "nome":"FERNANDES TOURINHO",
            "codigo_ibge":"3125804"
         },
         {
            "nome":"FERROS",
            "codigo_ibge":"3125903"
         },
         {
            "nome":"FERVEDOURO",
            "codigo_ibge":"3125952"
         },
         {
            "nome":"FLORESTAL",
            "codigo_ibge":"3126000"
         },
         {
            "nome":"FORMIGA",
            "codigo_ibge":"3126109"
         },
         {
            "nome":"FORMOSO",
            "codigo_ibge":"3126208"
         },
         {
            "nome":"FORTALEZA DE MINAS",
            "codigo_ibge":"3126307"
         },
         {
            "nome":"FORTUNA DE MINAS",
            "codigo_ibge":"3126406"
         },
         {
            "nome":"FRANCISCO BADARO",
            "codigo_ibge":"3126505"
         },
         {
            "nome":"FRANCISCO DUMONT",
            "codigo_ibge":"3126604"
         },
         {
            "nome":"FRANCISCO SA",
            "codigo_ibge":"3126703"
         },
         {
            "nome":"FRANCISCOPOLIS",
            "codigo_ibge":"3126752"
         },
         {
            "nome":"FREI GASPAR",
            "codigo_ibge":"3126802"
         },
         {
            "nome":"FREI INOCENCIO",
            "codigo_ibge":"3126901"
         },
         {
            "nome":"FREI LAGONEGRO",
            "codigo_ibge":"3126950"
         },
         {
            "nome":"FRONTEIRA",
            "codigo_ibge":"3127008"
         },
         {
            "nome":"FRONTEIRA DOS VALES",
            "codigo_ibge":"3127057"
         },
         {
            "nome":"FRUTA DE LEITE",
            "codigo_ibge":"3127073"
         },
         {
            "nome":"FRUTAL",
            "codigo_ibge":"3127107"
         },
         {
            "nome":"FUNILANDIA",
            "codigo_ibge":"3127206"
         },
         {
            "nome":"GALILEIA",
            "codigo_ibge":"3127305"
         },
         {
            "nome":"GAMELEIRAS",
            "codigo_ibge":"3127339"
         },
         {
            "nome":"GLAUCILANDIA",
            "codigo_ibge":"3127354"
         },
         {
            "nome":"GOIABEIRA",
            "codigo_ibge":"3127370"
         },
         {
            "nome":"GOIANA",
            "codigo_ibge":"3127388"
         },
         {
            "nome":"GONCALVES",
            "codigo_ibge":"3127404"
         },
         {
            "nome":"GONZAGA",
            "codigo_ibge":"3127503"
         },
         {
            "nome":"GOUVEIA",
            "codigo_ibge":"3127602"
         },
         {
            "nome":"GOVERNADOR VALADARES",
            "codigo_ibge":"3127701"
         },
         {
            "nome":"GRAO MOGOL",
            "codigo_ibge":"3127800"
         },
         {
            "nome":"GRUPIARA",
            "codigo_ibge":"3127909"
         },
         {
            "nome":"GUANHAES",
            "codigo_ibge":"3128006"
         },
         {
            "nome":"GUAPE",
            "codigo_ibge":"3128105"
         },
         {
            "nome":"GUARACIABA",
            "codigo_ibge":"3128204"
         },
         {
            "nome":"GUARACIAMA",
            "codigo_ibge":"3128253"
         },
         {
            "nome":"GUARANESIA",
            "codigo_ibge":"3128303"
         },
         {
            "nome":"GUARANI",
            "codigo_ibge":"3128402"
         },
         {
            "nome":"GUARARA",
            "codigo_ibge":"3128501"
         },
         {
            "nome":"GUARDA-MOR",
            "codigo_ibge":"3128600"
         },
         {
            "nome":"GUAXUPE",
            "codigo_ibge":"3128709"
         },
         {
            "nome":"GUIDOVAL",
            "codigo_ibge":"3128808"
         },
         {
            "nome":"GUIMARANIA",
            "codigo_ibge":"3128907"
         },
         {
            "nome":"GUIRICEMA",
            "codigo_ibge":"3129004"
         },
         {
            "nome":"GURINHATA",
            "codigo_ibge":"3129103"
         },
         {
            "nome":"HELIODORA",
            "codigo_ibge":"3129202"
         },
         {
            "nome":"IAPU",
            "codigo_ibge":"3129301"
         },
         {
            "nome":"IBERTIOGA",
            "codigo_ibge":"3129400"
         },
         {
            "nome":"IBIA",
            "codigo_ibge":"3129509"
         },
         {
            "nome":"IBIAI",
            "codigo_ibge":"3129608"
         },
         {
            "nome":"IBIRACATU",
            "codigo_ibge":"3129657"
         },
         {
            "nome":"IBIRACI",
            "codigo_ibge":"3129707"
         },
         {
            "nome":"IBIRITE",
            "codigo_ibge":"3129806"
         },
         {
            "nome":"IBITIURA DE MINAS",
            "codigo_ibge":"3129905"
         },
         {
            "nome":"IBITURUNA",
            "codigo_ibge":"3130002"
         },
         {
            "nome":"ICARAI DE MINAS",
            "codigo_ibge":"3130051"
         },
         {
            "nome":"IGARAPE",
            "codigo_ibge":"3130101"
         },
         {
            "nome":"IGARATINGA",
            "codigo_ibge":"3130200"
         },
         {
            "nome":"IGUATAMA",
            "codigo_ibge":"3130309"
         },
         {
            "nome":"IJACI",
            "codigo_ibge":"3130408"
         },
         {
            "nome":"ILICINEA",
            "codigo_ibge":"3130507"
         },
         {
            "nome":"IMBE DE MINAS",
            "codigo_ibge":"3130556"
         },
         {
            "nome":"INCONFIDENTES",
            "codigo_ibge":"3130606"
         },
         {
            "nome":"INDAIABIRA",
            "codigo_ibge":"3130655"
         },
         {
            "nome":"INDIANOPOLIS",
            "codigo_ibge":"3130705"
         },
         {
            "nome":"INGAI",
            "codigo_ibge":"3130804"
         },
         {
            "nome":"INHAPIM",
            "codigo_ibge":"3130903"
         },
         {
            "nome":"INHAUMA",
            "codigo_ibge":"3131000"
         },
         {
            "nome":"INIMUTABA",
            "codigo_ibge":"3131109"
         },
         {
            "nome":"IPABA",
            "codigo_ibge":"3131158"
         },
         {
            "nome":"IPANEMA",
            "codigo_ibge":"3131208"
         },
         {
            "nome":"IPATINGA",
            "codigo_ibge":"3131307"
         },
         {
            "nome":"IPIACU",
            "codigo_ibge":"3131406"
         },
         {
            "nome":"IPUIUNA",
            "codigo_ibge":"3131505"
         },
         {
            "nome":"IRAI DE MINAS",
            "codigo_ibge":"3131604"
         },
         {
            "nome":"ITABIRA",
            "codigo_ibge":"3131703"
         },
         {
            "nome":"ITABIRINHA",
            "codigo_ibge":"3131802"
         },
         {
            "nome":"ITABIRITO",
            "codigo_ibge":"3131901"
         },
         {
            "nome":"ITACAMBIRA",
            "codigo_ibge":"3132008"
         },
         {
            "nome":"ITACARAMBI",
            "codigo_ibge":"3132107"
         },
         {
            "nome":"ITAGUARA",
            "codigo_ibge":"3132206"
         },
         {
            "nome":"ITAIPE",
            "codigo_ibge":"3132305"
         },
         {
            "nome":"ITAJUBA",
            "codigo_ibge":"3132404"
         },
         {
            "nome":"ITAMARANDIBA",
            "codigo_ibge":"3132503"
         },
         {
            "nome":"ITAMARATI DE MINAS",
            "codigo_ibge":"3132602"
         },
         {
            "nome":"ITAMBACURI",
            "codigo_ibge":"3132701"
         },
         {
            "nome":"ITAMBE DO MATO DENTRO",
            "codigo_ibge":"3132800"
         },
         {
            "nome":"ITAMOGI",
            "codigo_ibge":"3132909"
         },
         {
            "nome":"ITAMONTE",
            "codigo_ibge":"3133006"
         },
         {
            "nome":"ITANHANDU",
            "codigo_ibge":"3133105"
         },
         {
            "nome":"ITANHOMI",
            "codigo_ibge":"3133204"
         },
         {
            "nome":"ITAOBIM",
            "codigo_ibge":"3133303"
         },
         {
            "nome":"ITAPAGIPE",
            "codigo_ibge":"3133402"
         },
         {
            "nome":"ITAPECERICA",
            "codigo_ibge":"3133501"
         },
         {
            "nome":"ITAPEVA",
            "codigo_ibge":"3133600"
         },
         {
            "nome":"ITATIAIUCU",
            "codigo_ibge":"3133709"
         },
         {
            "nome":"ITAU DE MINAS",
            "codigo_ibge":"3133758"
         },
         {
            "nome":"ITAUNA",
            "codigo_ibge":"3133808"
         },
         {
            "nome":"ITAVERAVA",
            "codigo_ibge":"3133907"
         },
         {
            "nome":"ITINGA",
            "codigo_ibge":"3134004"
         },
         {
            "nome":"ITUETA",
            "codigo_ibge":"3134103"
         },
         {
            "nome":"ITUIUTABA",
            "codigo_ibge":"3134202"
         },
         {
            "nome":"ITUMIRIM",
            "codigo_ibge":"3134301"
         },
         {
            "nome":"ITURAMA",
            "codigo_ibge":"3134400"
         },
         {
            "nome":"ITUTINGA",
            "codigo_ibge":"3134509"
         },
         {
            "nome":"JABOTICATUBAS",
            "codigo_ibge":"3134608"
         },
         {
            "nome":"JACINTO",
            "codigo_ibge":"3134707"
         },
         {
            "nome":"JACUI",
            "codigo_ibge":"3134806"
         },
         {
            "nome":"JACUTINGA",
            "codigo_ibge":"3134905"
         },
         {
            "nome":"JAGUARACU",
            "codigo_ibge":"3135001"
         },
         {
            "nome":"JAIBA",
            "codigo_ibge":"3135050"
         },
         {
            "nome":"JAMPRUCA",
            "codigo_ibge":"3135076"
         },
         {
            "nome":"JANAUBA",
            "codigo_ibge":"3135100"
         },
         {
            "nome":"JANUARIA",
            "codigo_ibge":"3135209"
         },
         {
            "nome":"JAPARAIBA",
            "codigo_ibge":"3135308"
         },
         {
            "nome":"JAPONVAR",
            "codigo_ibge":"3135357"
         },
         {
            "nome":"JECEABA",
            "codigo_ibge":"3135407"
         },
         {
            "nome":"JENIPAPO DE MINAS",
            "codigo_ibge":"3135456"
         },
         {
            "nome":"JEQUERI",
            "codigo_ibge":"3135506"
         },
         {
            "nome":"JEQUITAI",
            "codigo_ibge":"3135605"
         },
         {
            "nome":"JEQUITIBA",
            "codigo_ibge":"3135704"
         },
         {
            "nome":"JEQUITINHONHA",
            "codigo_ibge":"3135803"
         },
         {
            "nome":"JESUANIA",
            "codigo_ibge":"3135902"
         },
         {
            "nome":"JOAIMA",
            "codigo_ibge":"3136009"
         },
         {
            "nome":"JOANESIA",
            "codigo_ibge":"3136108"
         },
         {
            "nome":"JOAO MONLEVADE",
            "codigo_ibge":"3136207"
         },
         {
            "nome":"JOAO PINHEIRO",
            "codigo_ibge":"3136306"
         },
         {
            "nome":"JOAQUIM FELICIO",
            "codigo_ibge":"3136405"
         },
         {
            "nome":"JORDANIA",
            "codigo_ibge":"3136504"
         },
         {
            "nome":"JOSE GONCALVES DE MINAS",
            "codigo_ibge":"3136520"
         },
         {
            "nome":"JOSE RAYDAN",
            "codigo_ibge":"3136553"
         },
         {
            "nome":"JOSENOPOLIS",
            "codigo_ibge":"3136579"
         },
         {
            "nome":"NOVA UNIAO",
            "codigo_ibge":"3136603"
         },
         {
            "nome":"JUATUBA",
            "codigo_ibge":"3136652"
         },
         {
            "nome":"JUIZ DE FORA",
            "codigo_ibge":"3136702"
         },
         {
            "nome":"JURAMENTO",
            "codigo_ibge":"3136801"
         },
         {
            "nome":"JURUAIA",
            "codigo_ibge":"3136900"
         },
         {
            "nome":"JUVENILIA",
            "codigo_ibge":"3136959"
         },
         {
            "nome":"LADAINHA",
            "codigo_ibge":"3137007"
         },
         {
            "nome":"LAGAMAR",
            "codigo_ibge":"3137106"
         },
         {
            "nome":"LAGOA DA PRATA",
            "codigo_ibge":"3137205"
         },
         {
            "nome":"LAGOA DOS PATOS",
            "codigo_ibge":"3137304"
         },
         {
            "nome":"LAGOA DOURADA",
            "codigo_ibge":"3137403"
         },
         {
            "nome":"LAGOA FORMOSA",
            "codigo_ibge":"3137502"
         },
         {
            "nome":"LAGOA GRANDE",
            "codigo_ibge":"3137536"
         },
         {
            "nome":"LAGOA SANTA",
            "codigo_ibge":"3137601"
         },
         {
            "nome":"LAJINHA",
            "codigo_ibge":"3137700"
         },
         {
            "nome":"LAMBARI",
            "codigo_ibge":"3137809"
         },
         {
            "nome":"LAMIM",
            "codigo_ibge":"3137908"
         },
         {
            "nome":"LARANJAL",
            "codigo_ibge":"3138005"
         },
         {
            "nome":"LASSANCE",
            "codigo_ibge":"3138104"
         },
         {
            "nome":"LAVRAS",
            "codigo_ibge":"3138203"
         },
         {
            "nome":"LEANDRO FERREIRA",
            "codigo_ibge":"3138302"
         },
         {
            "nome":"LEME DO PRADO",
            "codigo_ibge":"3138351"
         },
         {
            "nome":"LEOPOLDINA",
            "codigo_ibge":"3138401"
         },
         {
            "nome":"LIBERDADE",
            "codigo_ibge":"3138500"
         },
         {
            "nome":"LIMA DUARTE",
            "codigo_ibge":"3138609"
         },
         {
            "nome":"LIMEIRA DO OESTE",
            "codigo_ibge":"3138625"
         },
         {
            "nome":"LONTRA",
            "codigo_ibge":"3138658"
         },
         {
            "nome":"LUISBURGO",
            "codigo_ibge":"3138674"
         },
         {
            "nome":"LUISLANDIA",
            "codigo_ibge":"3138682"
         },
         {
            "nome":"LUMINARIAS",
            "codigo_ibge":"3138708"
         },
         {
            "nome":"LUZ",
            "codigo_ibge":"3138807"
         },
         {
            "nome":"MACHACALIS",
            "codigo_ibge":"3138906"
         },
         {
            "nome":"MACHADO",
            "codigo_ibge":"3139003"
         },
         {
            "nome":"MADRE DE DEUS DE MINAS",
            "codigo_ibge":"3139102"
         },
         {
            "nome":"MALACACHETA",
            "codigo_ibge":"3139201"
         },
         {
            "nome":"MAMONAS",
            "codigo_ibge":"3139250"
         },
         {
            "nome":"MANGA",
            "codigo_ibge":"3139300"
         },
         {
            "nome":"MANHUACU",
            "codigo_ibge":"3139409"
         },
         {
            "nome":"MANHUMIRIM",
            "codigo_ibge":"3139508"
         },
         {
            "nome":"MANTENA",
            "codigo_ibge":"3139607"
         },
         {
            "nome":"MARAVILHAS",
            "codigo_ibge":"3139706"
         },
         {
            "nome":"MAR DE ESPANHA",
            "codigo_ibge":"3139805"
         },
         {
            "nome":"MARIA DA FE",
            "codigo_ibge":"3139904"
         },
         {
            "nome":"MARIANA",
            "codigo_ibge":"3140001"
         },
         {
            "nome":"MARILAC",
            "codigo_ibge":"3140100"
         },
         {
            "nome":"MARIO CAMPOS",
            "codigo_ibge":"3140159"
         },
         {
            "nome":"MARIPA DE MINAS",
            "codigo_ibge":"3140209"
         },
         {
            "nome":"MARLIERIA",
            "codigo_ibge":"3140308"
         },
         {
            "nome":"MARMELOPOLIS",
            "codigo_ibge":"3140407"
         },
         {
            "nome":"MARTINHO CAMPOS",
            "codigo_ibge":"3140506"
         },
         {
            "nome":"MARTINS SOARES",
            "codigo_ibge":"3140530"
         },
         {
            "nome":"MATA VERDE",
            "codigo_ibge":"3140555"
         },
         {
            "nome":"MATERLANDIA",
            "codigo_ibge":"3140605"
         },
         {
            "nome":"MATEUS LEME",
            "codigo_ibge":"3140704"
         },
         {
            "nome":"MATIAS BARBOSA",
            "codigo_ibge":"3140803"
         },
         {
            "nome":"MATIAS CARDOSO",
            "codigo_ibge":"3140852"
         },
         {
            "nome":"MATIPO",
            "codigo_ibge":"3140902"
         },
         {
            "nome":"MATO VERDE",
            "codigo_ibge":"3141009"
         },
         {
            "nome":"MATOZINHOS",
            "codigo_ibge":"3141108"
         },
         {
            "nome":"MATUTINA",
            "codigo_ibge":"3141207"
         },
         {
            "nome":"MEDEIROS",
            "codigo_ibge":"3141306"
         },
         {
            "nome":"MEDINA",
            "codigo_ibge":"3141405"
         },
         {
            "nome":"MENDES PIMENTEL",
            "codigo_ibge":"3141504"
         },
         {
            "nome":"MERCES",
            "codigo_ibge":"3141603"
         },
         {
            "nome":"MESQUITA",
            "codigo_ibge":"3141702"
         },
         {
            "nome":"MINAS NOVAS",
            "codigo_ibge":"3141801"
         },
         {
            "nome":"MINDURI",
            "codigo_ibge":"3141900"
         },
         {
            "nome":"MIRABELA",
            "codigo_ibge":"3142007"
         },
         {
            "nome":"MIRADOURO",
            "codigo_ibge":"3142106"
         },
         {
            "nome":"MIRAI",
            "codigo_ibge":"3142205"
         },
         {
            "nome":"MIRAVANIA",
            "codigo_ibge":"3142254"
         },
         {
            "nome":"MOEDA",
            "codigo_ibge":"3142304"
         },
         {
            "nome":"MOEMA",
            "codigo_ibge":"3142403"
         },
         {
            "nome":"MONJOLOS",
            "codigo_ibge":"3142502"
         },
         {
            "nome":"MONSENHOR PAULO",
            "codigo_ibge":"3142601"
         },
         {
            "nome":"MONTALVANIA",
            "codigo_ibge":"3142700"
         },
         {
            "nome":"MONTE ALEGRE DE MINAS",
            "codigo_ibge":"3142809"
         },
         {
            "nome":"MONTE AZUL",
            "codigo_ibge":"3142908"
         },
         {
            "nome":"MONTE BELO",
            "codigo_ibge":"3143005"
         },
         {
            "nome":"MONTE CARMELO",
            "codigo_ibge":"3143104"
         },
         {
            "nome":"MONTE FORMOSO",
            "codigo_ibge":"3143153"
         },
         {
            "nome":"MONTE SANTO DE MINAS",
            "codigo_ibge":"3143203"
         },
         {
            "nome":"MONTES CLAROS",
            "codigo_ibge":"3143302"
         },
         {
            "nome":"MONTE SIAO",
            "codigo_ibge":"3143401"
         },
         {
            "nome":"MONTEZUMA",
            "codigo_ibge":"3143450"
         },
         {
            "nome":"MORADA NOVA DE MINAS",
            "codigo_ibge":"3143500"
         },
         {
            "nome":"MORRO DA GARCA",
            "codigo_ibge":"3143609"
         },
         {
            "nome":"MORRO DO PILAR",
            "codigo_ibge":"3143708"
         },
         {
            "nome":"MUNHOZ",
            "codigo_ibge":"3143807"
         },
         {
            "nome":"MURIAE",
            "codigo_ibge":"3143906"
         },
         {
            "nome":"MUTUM",
            "codigo_ibge":"3144003"
         },
         {
            "nome":"MUZAMBINHO",
            "codigo_ibge":"3144102"
         },
         {
            "nome":"NACIP RAYDAN",
            "codigo_ibge":"3144201"
         },
         {
            "nome":"NANUQUE",
            "codigo_ibge":"3144300"
         },
         {
            "nome":"NAQUE",
            "codigo_ibge":"3144359"
         },
         {
            "nome":"NATALANDIA",
            "codigo_ibge":"3144375"
         },
         {
            "nome":"NATERCIA",
            "codigo_ibge":"3144409"
         },
         {
            "nome":"NAZARENO",
            "codigo_ibge":"3144508"
         },
         {
            "nome":"NEPOMUCENO",
            "codigo_ibge":"3144607"
         },
         {
            "nome":"NINHEIRA",
            "codigo_ibge":"3144656"
         },
         {
            "nome":"NOVA BELEM",
            "codigo_ibge":"3144672"
         },
         {
            "nome":"NOVA ERA",
            "codigo_ibge":"3144706"
         },
         {
            "nome":"NOVA LIMA",
            "codigo_ibge":"3144805"
         },
         {
            "nome":"NOVA MODICA",
            "codigo_ibge":"3144904"
         },
         {
            "nome":"NOVA PONTE",
            "codigo_ibge":"3145000"
         },
         {
            "nome":"NOVA PORTEIRINHA",
            "codigo_ibge":"3145059"
         },
         {
            "nome":"NOVA RESENDE",
            "codigo_ibge":"3145109"
         },
         {
            "nome":"NOVA SERRANA",
            "codigo_ibge":"3145208"
         },
         {
            "nome":"NOVO CRUZEIRO",
            "codigo_ibge":"3145307"
         },
         {
            "nome":"NOVO ORIENTE DE MINAS",
            "codigo_ibge":"3145356"
         },
         {
            "nome":"NOVORIZONTE",
            "codigo_ibge":"3145372"
         },
         {
            "nome":"OLARIA",
            "codigo_ibge":"3145406"
         },
         {
            "nome":"OLHOS-D AGUA",
            "codigo_ibge":"3145455"
         },
         {
            "nome":"OLIMPIO NORONHA",
            "codigo_ibge":"3145505"
         },
         {
            "nome":"OLIVEIRA",
            "codigo_ibge":"3145604"
         },
         {
            "nome":"OLIVEIRA FORTES",
            "codigo_ibge":"3145703"
         },
         {
            "nome":"ONCA DE PITANGUI",
            "codigo_ibge":"3145802"
         },
         {
            "nome":"ORATORIOS",
            "codigo_ibge":"3145851"
         },
         {
            "nome":"ORIZANIA",
            "codigo_ibge":"3145877"
         },
         {
            "nome":"OURO BRANCO",
            "codigo_ibge":"3145901"
         },
         {
            "nome":"OURO FINO",
            "codigo_ibge":"3146008"
         },
         {
            "nome":"OURO PRETO",
            "codigo_ibge":"3146107"
         },
         {
            "nome":"OURO VERDE DE MINAS",
            "codigo_ibge":"3146206"
         },
         {
            "nome":"PADRE CARVALHO",
            "codigo_ibge":"3146255"
         },
         {
            "nome":"PADRE PARAISO",
            "codigo_ibge":"3146305"
         },
         {
            "nome":"PAINEIRAS",
            "codigo_ibge":"3146404"
         },
         {
            "nome":"PAINS",
            "codigo_ibge":"3146503"
         },
         {
            "nome":"PAI PEDRO",
            "codigo_ibge":"3146552"
         },
         {
            "nome":"PAIVA",
            "codigo_ibge":"3146602"
         },
         {
            "nome":"PALMA",
            "codigo_ibge":"3146701"
         },
         {
            "nome":"PALMOPOLIS",
            "codigo_ibge":"3146750"
         },
         {
            "nome":"PAPAGAIOS",
            "codigo_ibge":"3146909"
         },
         {
            "nome":"PARACATU",
            "codigo_ibge":"3147006"
         },
         {
            "nome":"PARA DE MINAS",
            "codigo_ibge":"3147105"
         },
         {
            "nome":"PARAGUACU",
            "codigo_ibge":"3147204"
         },
         {
            "nome":"PARAISOPOLIS",
            "codigo_ibge":"3147303"
         },
         {
            "nome":"PARAOPEBA",
            "codigo_ibge":"3147402"
         },
         {
            "nome":"PASSABEM",
            "codigo_ibge":"3147501"
         },
         {
            "nome":"PASSA QUATRO",
            "codigo_ibge":"3147600"
         },
         {
            "nome":"PASSA TEMPO",
            "codigo_ibge":"3147709"
         },
         {
            "nome":"PASSA-VINTE",
            "codigo_ibge":"3147808"
         },
         {
            "nome":"PASSOS",
            "codigo_ibge":"3147907"
         },
         {
            "nome":"PATIS",
            "codigo_ibge":"3147956"
         },
         {
            "nome":"PATOS DE MINAS",
            "codigo_ibge":"3148004"
         },
         {
            "nome":"PATROCINIO",
            "codigo_ibge":"3148103"
         },
         {
            "nome":"PATROCINIO DO MURIAE",
            "codigo_ibge":"3148202"
         },
         {
            "nome":"PAULA CANDIDO",
            "codigo_ibge":"3148301"
         },
         {
            "nome":"PAULISTAS",
            "codigo_ibge":"3148400"
         },
         {
            "nome":"PAVAO",
            "codigo_ibge":"3148509"
         },
         {
            "nome":"PECANHA",
            "codigo_ibge":"3148608"
         },
         {
            "nome":"PEDRA AZUL",
            "codigo_ibge":"3148707"
         },
         {
            "nome":"PEDRA BONITA",
            "codigo_ibge":"3148756"
         },
         {
            "nome":"PEDRA DO ANTA",
            "codigo_ibge":"3148806"
         },
         {
            "nome":"PEDRA DO INDAIA",
            "codigo_ibge":"3148905"
         },
         {
            "nome":"PEDRA DOURADA",
            "codigo_ibge":"3149002"
         },
         {
            "nome":"PEDRALVA",
            "codigo_ibge":"3149101"
         },
         {
            "nome":"PEDRAS DE MARIA DA CRUZ",
            "codigo_ibge":"3149150"
         },
         {
            "nome":"PEDRINOPOLIS",
            "codigo_ibge":"3149200"
         },
         {
            "nome":"PEDRO LEOPOLDO",
            "codigo_ibge":"3149309"
         },
         {
            "nome":"PEDRO TEIXEIRA",
            "codigo_ibge":"3149408"
         },
         {
            "nome":"PEQUERI",
            "codigo_ibge":"3149507"
         },
         {
            "nome":"PEQUI",
            "codigo_ibge":"3149606"
         },
         {
            "nome":"PERDIGAO",
            "codigo_ibge":"3149705"
         },
         {
            "nome":"PERDIZES",
            "codigo_ibge":"3149804"
         },
         {
            "nome":"PERDOES",
            "codigo_ibge":"3149903"
         },
         {
            "nome":"PERIQUITO",
            "codigo_ibge":"3149952"
         },
         {
            "nome":"PESCADOR",
            "codigo_ibge":"3150000"
         },
         {
            "nome":"PIAU",
            "codigo_ibge":"3150109"
         },
         {
            "nome":"PIEDADE DE CARATINGA",
            "codigo_ibge":"3150158"
         },
         {
            "nome":"PIEDADE DE PONTE NOVA",
            "codigo_ibge":"3150208"
         },
         {
            "nome":"PIEDADE DO RIO GRANDE",
            "codigo_ibge":"3150307"
         },
         {
            "nome":"PIEDADE DOS GERAIS",
            "codigo_ibge":"3150406"
         },
         {
            "nome":"PIMENTA",
            "codigo_ibge":"3150505"
         },
         {
            "nome":"PINGO-D AGUA",
            "codigo_ibge":"3150539"
         },
         {
            "nome":"PINTOPOLIS",
            "codigo_ibge":"3150570"
         },
         {
            "nome":"PIRACEMA",
            "codigo_ibge":"3150604"
         },
         {
            "nome":"PIRAJUBA",
            "codigo_ibge":"3150703"
         },
         {
            "nome":"PIRANGA",
            "codigo_ibge":"3150802"
         },
         {
            "nome":"PIRANGUCU",
            "codigo_ibge":"3150901"
         },
         {
            "nome":"PIRANGUINHO",
            "codigo_ibge":"3151008"
         },
         {
            "nome":"PIRAPETINGA",
            "codigo_ibge":"3151107"
         },
         {
            "nome":"PIRAPORA",
            "codigo_ibge":"3151206"
         },
         {
            "nome":"PIRAUBA",
            "codigo_ibge":"3151305"
         },
         {
            "nome":"PITANGUI",
            "codigo_ibge":"3151404"
         },
         {
            "nome":"PIUMHI",
            "codigo_ibge":"3151503"
         },
         {
            "nome":"PLANURA",
            "codigo_ibge":"3151602"
         },
         {
            "nome":"POCO FUNDO",
            "codigo_ibge":"3151701"
         },
         {
            "nome":"POCOS DE CALDAS",
            "codigo_ibge":"3151800"
         },
         {
            "nome":"POCRANE",
            "codigo_ibge":"3151909"
         },
         {
            "nome":"POMPEU",
            "codigo_ibge":"3152006"
         },
         {
            "nome":"PONTE NOVA",
            "codigo_ibge":"3152105"
         },
         {
            "nome":"PONTO CHIQUE",
            "codigo_ibge":"3152131"
         },
         {
            "nome":"PONTO DOS VOLANTES",
            "codigo_ibge":"3152170"
         },
         {
            "nome":"PORTEIRINHA",
            "codigo_ibge":"3152204"
         },
         {
            "nome":"PORTO FIRME",
            "codigo_ibge":"3152303"
         },
         {
            "nome":"POTE",
            "codigo_ibge":"3152402"
         },
         {
            "nome":"POUSO ALEGRE",
            "codigo_ibge":"3152501"
         },
         {
            "nome":"POUSO ALTO",
            "codigo_ibge":"3152600"
         },
         {
            "nome":"PRADOS",
            "codigo_ibge":"3152709"
         },
         {
            "nome":"PRATA",
            "codigo_ibge":"3152808"
         },
         {
            "nome":"PRATAPOLIS",
            "codigo_ibge":"3152907"
         },
         {
            "nome":"PRATINHA",
            "codigo_ibge":"3153004"
         },
         {
            "nome":"PRESIDENTE BERNARDES",
            "codigo_ibge":"3153103"
         },
         {
            "nome":"PRESIDENTE JUSCELINO",
            "codigo_ibge":"3153202"
         },
         {
            "nome":"PRESIDENTE KUBITSCHEK",
            "codigo_ibge":"3153301"
         },
         {
            "nome":"PRESIDENTE OLEGARIO",
            "codigo_ibge":"3153400"
         },
         {
            "nome":"ALTO JEQUITIBA",
            "codigo_ibge":"3153509"
         },
         {
            "nome":"PRUDENTE DE MORAIS",
            "codigo_ibge":"3153608"
         },
         {
            "nome":"QUARTEL GERAL",
            "codigo_ibge":"3153707"
         },
         {
            "nome":"QUELUZITO",
            "codigo_ibge":"3153806"
         },
         {
            "nome":"RAPOSOS",
            "codigo_ibge":"3153905"
         },
         {
            "nome":"RAUL SOARES",
            "codigo_ibge":"3154002"
         },
         {
            "nome":"RECREIO",
            "codigo_ibge":"3154101"
         },
         {
            "nome":"REDUTO",
            "codigo_ibge":"3154150"
         },
         {
            "nome":"RESENDE COSTA",
            "codigo_ibge":"3154200"
         },
         {
            "nome":"RESPLENDOR",
            "codigo_ibge":"3154309"
         },
         {
            "nome":"RESSAQUINHA",
            "codigo_ibge":"3154408"
         },
         {
            "nome":"RIACHINHO",
            "codigo_ibge":"3154457"
         },
         {
            "nome":"RIACHO DOS MACHADOS",
            "codigo_ibge":"3154507"
         },
         {
            "nome":"RIBEIRAO DAS NEVES",
            "codigo_ibge":"3154606"
         },
         {
            "nome":"RIBEIRAO VERMELHO",
            "codigo_ibge":"3154705"
         },
         {
            "nome":"RIO ACIMA",
            "codigo_ibge":"3154804"
         },
         {
            "nome":"RIO CASCA",
            "codigo_ibge":"3154903"
         },
         {
            "nome":"RIO DOCE",
            "codigo_ibge":"3155009"
         },
         {
            "nome":"RIO DO PRADO",
            "codigo_ibge":"3155108"
         },
         {
            "nome":"RIO ESPERA",
            "codigo_ibge":"3155207"
         },
         {
            "nome":"RIO MANSO",
            "codigo_ibge":"3155306"
         },
         {
            "nome":"RIO NOVO",
            "codigo_ibge":"3155405"
         },
         {
            "nome":"RIO PARANAIBA",
            "codigo_ibge":"3155504"
         },
         {
            "nome":"RIO PARDO DE MINAS",
            "codigo_ibge":"3155603"
         },
         {
            "nome":"RIO PIRACICABA",
            "codigo_ibge":"3155702"
         },
         {
            "nome":"RIO POMBA",
            "codigo_ibge":"3155801"
         },
         {
            "nome":"RIO PRETO",
            "codigo_ibge":"3155900"
         },
         {
            "nome":"RIO VERMELHO",
            "codigo_ibge":"3156007"
         },
         {
            "nome":"RITAPOLIS",
            "codigo_ibge":"3156106"
         },
         {
            "nome":"ROCHEDO DE MINAS",
            "codigo_ibge":"3156205"
         },
         {
            "nome":"RODEIRO",
            "codigo_ibge":"3156304"
         },
         {
            "nome":"ROMARIA",
            "codigo_ibge":"3156403"
         },
         {
            "nome":"ROSARIO DA LIMEIRA",
            "codigo_ibge":"3156452"
         },
         {
            "nome":"RUBELITA",
            "codigo_ibge":"3156502"
         },
         {
            "nome":"RUBIM",
            "codigo_ibge":"3156601"
         },
         {
            "nome":"SABARA",
            "codigo_ibge":"3156700"
         },
         {
            "nome":"SABINOPOLIS",
            "codigo_ibge":"3156809"
         },
         {
            "nome":"SACRAMENTO",
            "codigo_ibge":"3156908"
         },
         {
            "nome":"SALINAS",
            "codigo_ibge":"3157005"
         },
         {
            "nome":"SALTO DA DIVISA",
            "codigo_ibge":"3157104"
         },
         {
            "nome":"SANTA BARBARA",
            "codigo_ibge":"3157203"
         },
         {
            "nome":"SANTA BARBARA DO LESTE",
            "codigo_ibge":"3157252"
         },
         {
            "nome":"SANTA BARBARA DO MONTE VERDE",
            "codigo_ibge":"3157278"
         },
         {
            "nome":"SANTA BARBARA DO TUGURIO",
            "codigo_ibge":"3157302"
         },
         {
            "nome":"SANTA CRUZ DE MINAS",
            "codigo_ibge":"3157336"
         },
         {
            "nome":"SANTA CRUZ DE SALINAS",
            "codigo_ibge":"3157377"
         },
         {
            "nome":"SANTA CRUZ DO ESCALVADO",
            "codigo_ibge":"3157401"
         },
         {
            "nome":"SANTA EFIGENIA DE MINAS",
            "codigo_ibge":"3157500"
         },
         {
            "nome":"SANTA FE DE MINAS",
            "codigo_ibge":"3157609"
         },
         {
            "nome":"SANTA HELENA DE MINAS",
            "codigo_ibge":"3157658"
         },
         {
            "nome":"SANTA JULIANA",
            "codigo_ibge":"3157708"
         },
         {
            "nome":"SANTA LUZIA",
            "codigo_ibge":"3157807"
         },
         {
            "nome":"SANTA MARGARIDA",
            "codigo_ibge":"3157906"
         },
         {
            "nome":"SANTA MARIA DE ITABIRA",
            "codigo_ibge":"3158003"
         },
         {
            "nome":"SANTA MARIA DO SALTO",
            "codigo_ibge":"3158102"
         },
         {
            "nome":"SANTA MARIA DO SUACUI",
            "codigo_ibge":"3158201"
         },
         {
            "nome":"SANTANA DA VARGEM",
            "codigo_ibge":"3158300"
         },
         {
            "nome":"SANTANA DE CATAGUASES",
            "codigo_ibge":"3158409"
         },
         {
            "nome":"SANTANA DE PIRAPAMA",
            "codigo_ibge":"3158508"
         },
         {
            "nome":"SANTANA DO DESERTO",
            "codigo_ibge":"3158607"
         },
         {
            "nome":"SANTANA DO GARAMBEU",
            "codigo_ibge":"3158706"
         },
         {
            "nome":"SANTANA DO JACARE",
            "codigo_ibge":"3158805"
         },
         {
            "nome":"SANTANA DO MANHUACU",
            "codigo_ibge":"3158904"
         },
         {
            "nome":"SANTANA DO PARAISO",
            "codigo_ibge":"3158953"
         },
         {
            "nome":"SANTANA DO RIACHO",
            "codigo_ibge":"3159001"
         },
         {
            "nome":"SANTANA DOS MONTES",
            "codigo_ibge":"3159100"
         },
         {
            "nome":"SANTA RITA DE CALDAS",
            "codigo_ibge":"3159209"
         },
         {
            "nome":"SANTA RITA DE JACUTINGA",
            "codigo_ibge":"3159308"
         },
         {
            "nome":"SANTA RITA DE MINAS",
            "codigo_ibge":"3159357"
         },
         {
            "nome":"SANTA RITA DE IBITIPOCA",
            "codigo_ibge":"3159407"
         },
         {
            "nome":"SANTA RITA DO ITUETO",
            "codigo_ibge":"3159506"
         },
         {
            "nome":"SANTA RITA DO SAPUCAI",
            "codigo_ibge":"3159605"
         },
         {
            "nome":"SANTA ROSA DA SERRA",
            "codigo_ibge":"3159704"
         },
         {
            "nome":"SANTA VITORIA",
            "codigo_ibge":"3159803"
         },
         {
            "nome":"SANTO ANTONIO DO AMPARO",
            "codigo_ibge":"3159902"
         },
         {
            "nome":"SANTO ANTONIO DO AVENTUREIRO",
            "codigo_ibge":"3160009"
         },
         {
            "nome":"SANTO ANTONIO DO GRAMA",
            "codigo_ibge":"3160108"
         },
         {
            "nome":"SANTO ANTONIO DO ITAMBE",
            "codigo_ibge":"3160207"
         },
         {
            "nome":"SANTO ANTONIO DO JACINTO",
            "codigo_ibge":"3160306"
         },
         {
            "nome":"SANTO ANTONIO DO MONTE",
            "codigo_ibge":"3160405"
         },
         {
            "nome":"SANTO ANTONIO DO RETIRO",
            "codigo_ibge":"3160454"
         },
         {
            "nome":"SANTO ANTONIO DO RIO ABAIXO",
            "codigo_ibge":"3160504"
         },
         {
            "nome":"SANTO HIPOLITO",
            "codigo_ibge":"3160603"
         },
         {
            "nome":"SANTOS DUMONT",
            "codigo_ibge":"3160702"
         },
         {
            "nome":"SAO BENTO ABADE",
            "codigo_ibge":"3160801"
         },
         {
            "nome":"SAO BRAS DO SUACUI",
            "codigo_ibge":"3160900"
         },
         {
            "nome":"SAO DOMINGOS DAS DORES",
            "codigo_ibge":"3160959"
         },
         {
            "nome":"SAO DOMINGOS DO PRATA",
            "codigo_ibge":"3161007"
         },
         {
            "nome":"SAO FELIX DE MINAS",
            "codigo_ibge":"3161056"
         },
         {
            "nome":"SAO FRANCISCO",
            "codigo_ibge":"3161106"
         },
         {
            "nome":"SAO FRANCISCO DE PAULA",
            "codigo_ibge":"3161205"
         },
         {
            "nome":"SAO FRANCISCO DE SALES",
            "codigo_ibge":"3161304"
         },
         {
            "nome":"SAO FRANCISCO DO GLORIA",
            "codigo_ibge":"3161403"
         },
         {
            "nome":"SAO GERALDO",
            "codigo_ibge":"3161502"
         },
         {
            "nome":"SAO GERALDO DA PIEDADE",
            "codigo_ibge":"3161601"
         },
         {
            "nome":"SAO GERALDO DO BAIXIO",
            "codigo_ibge":"3161650"
         },
         {
            "nome":"SAO GONCALO DO ABAETE",
            "codigo_ibge":"3161700"
         },
         {
            "nome":"SAO GONCALO DO PARA",
            "codigo_ibge":"3161809"
         },
         {
            "nome":"SAO GONCALO DO RIO ABAIXO",
            "codigo_ibge":"3161908"
         },
         {
            "nome":"SAO GONCALO DO SAPUCAI",
            "codigo_ibge":"3162005"
         },
         {
            "nome":"SAO GOTARDO",
            "codigo_ibge":"3162104"
         },
         {
            "nome":"SAO JOAO BATISTA DO GLORIA",
            "codigo_ibge":"3162203"
         },
         {
            "nome":"SAO JOAO DA LAGOA",
            "codigo_ibge":"3162252"
         },
         {
            "nome":"SAO JOAO DA MATA",
            "codigo_ibge":"3162302"
         },
         {
            "nome":"SAO JOAO DA PONTE",
            "codigo_ibge":"3162401"
         },
         {
            "nome":"SAO JOAO DAS MISSOES",
            "codigo_ibge":"3162450"
         },
         {
            "nome":"SAO JOAO DEL REI",
            "codigo_ibge":"3162500"
         },
         {
            "nome":"SAO JOAO DO MANHUACU",
            "codigo_ibge":"3162559"
         },
         {
            "nome":"SAO JOAO DO MANTENINHA",
            "codigo_ibge":"3162575"
         },
         {
            "nome":"SAO JOAO DO ORIENTE",
            "codigo_ibge":"3162609"
         },
         {
            "nome":"SAO JOAO DO PACUI",
            "codigo_ibge":"3162658"
         },
         {
            "nome":"SAO JOAO DO PARAISO",
            "codigo_ibge":"3162708"
         },
         {
            "nome":"SAO JOAO EVANGELISTA",
            "codigo_ibge":"3162807"
         },
         {
            "nome":"SAO JOAO NEPOMUCENO",
            "codigo_ibge":"3162906"
         },
         {
            "nome":"SAO JOAQUIM DE BICAS",
            "codigo_ibge":"3162922"
         },
         {
            "nome":"SAO JOSE DA BARRA",
            "codigo_ibge":"3162948"
         },
         {
            "nome":"SAO JOSE DA LAPA",
            "codigo_ibge":"3162955"
         },
         {
            "nome":"SAO JOSE DA SAFIRA",
            "codigo_ibge":"3163003"
         },
         {
            "nome":"SAO JOSE DA VARGINHA",
            "codigo_ibge":"3163102"
         },
         {
            "nome":"SAO JOSE DO ALEGRE",
            "codigo_ibge":"3163201"
         },
         {
            "nome":"SAO JOSE DO DIVINO",
            "codigo_ibge":"3163300"
         },
         {
            "nome":"SAO JOSE DO GOIABAL",
            "codigo_ibge":"3163409"
         },
         {
            "nome":"SAO JOSE DO JACURI",
            "codigo_ibge":"3163508"
         },
         {
            "nome":"SAO JOSE DO MANTIMENTO",
            "codigo_ibge":"3163607"
         },
         {
            "nome":"SAO LOURENCO",
            "codigo_ibge":"3163706"
         },
         {
            "nome":"SAO MIGUEL DO ANTA",
            "codigo_ibge":"3163805"
         },
         {
            "nome":"SAO PEDRO DA UNIAO",
            "codigo_ibge":"3163904"
         },
         {
            "nome":"SAO PEDRO DOS FERROS",
            "codigo_ibge":"3164001"
         },
         {
            "nome":"SAO PEDRO DO SUACUI",
            "codigo_ibge":"3164100"
         },
         {
            "nome":"SAO ROMAO",
            "codigo_ibge":"3164209"
         },
         {
            "nome":"SAO ROQUE DE MINAS",
            "codigo_ibge":"3164308"
         },
         {
            "nome":"SAO SEBASTIAO DA BELA VISTA",
            "codigo_ibge":"3164407"
         },
         {
            "nome":"SAO SEBASTIAO DA VARGEM ALEGRE",
            "codigo_ibge":"3164431"
         },
         {
            "nome":"SAO SEBASTIAO DO ANTA",
            "codigo_ibge":"3164472"
         },
         {
            "nome":"SAO SEBASTIAO DO MARANHAO",
            "codigo_ibge":"3164506"
         },
         {
            "nome":"SAO SEBASTIAO DO OESTE",
            "codigo_ibge":"3164605"
         },
         {
            "nome":"SAO SEBASTIAO DO PARAISO",
            "codigo_ibge":"3164704"
         },
         {
            "nome":"SAO SEBASTIAO DO RIO PRETO",
            "codigo_ibge":"3164803"
         },
         {
            "nome":"SAO SEBASTIAO DO RIO VERDE",
            "codigo_ibge":"3164902"
         },
         {
            "nome":"SAO TIAGO",
            "codigo_ibge":"3165008"
         },
         {
            "nome":"SAO TOMAS DE AQUINO",
            "codigo_ibge":"3165107"
         },
         {
            "nome":"SAO THOME DAS LETRAS",
            "codigo_ibge":"3165206"
         },
         {
            "nome":"SAO VICENTE DE MINAS",
            "codigo_ibge":"3165305"
         },
         {
            "nome":"SAPUCAI-MIRIM",
            "codigo_ibge":"3165404"
         },
         {
            "nome":"SARDOA",
            "codigo_ibge":"3165503"
         },
         {
            "nome":"SARZEDO",
            "codigo_ibge":"3165537"
         },
         {
            "nome":"SETUBINHA",
            "codigo_ibge":"3165552"
         },
         {
            "nome":"SEM-PEIXE",
            "codigo_ibge":"3165560"
         },
         {
            "nome":"SENADOR AMARAL",
            "codigo_ibge":"3165578"
         },
         {
            "nome":"SENADOR CORTES",
            "codigo_ibge":"3165602"
         },
         {
            "nome":"SENADOR FIRMINO",
            "codigo_ibge":"3165701"
         },
         {
            "nome":"SENADOR JOSE BENTO",
            "codigo_ibge":"3165800"
         },
         {
            "nome":"SENADOR MODESTINO GONCALVES",
            "codigo_ibge":"3165909"
         },
         {
            "nome":"SENHORA DE OLIVEIRA",
            "codigo_ibge":"3166006"
         },
         {
            "nome":"SENHORA DO PORTO",
            "codigo_ibge":"3166105"
         },
         {
            "nome":"SENHORA DOS REMEDIOS",
            "codigo_ibge":"3166204"
         },
         {
            "nome":"SERICITA",
            "codigo_ibge":"3166303"
         },
         {
            "nome":"SERITINGA",
            "codigo_ibge":"3166402"
         },
         {
            "nome":"SERRA AZUL DE MINAS",
            "codigo_ibge":"3166501"
         },
         {
            "nome":"SERRA DA SAUDADE",
            "codigo_ibge":"3166600"
         },
         {
            "nome":"SERRA DOS AIMORES",
            "codigo_ibge":"3166709"
         },
         {
            "nome":"SERRA DO SALITRE",
            "codigo_ibge":"3166808"
         },
         {
            "nome":"SERRANIA",
            "codigo_ibge":"3166907"
         },
         {
            "nome":"SERRANOPOLIS DE MINAS",
            "codigo_ibge":"3166956"
         },
         {
            "nome":"SERRANOS",
            "codigo_ibge":"3167004"
         },
         {
            "nome":"SERRO",
            "codigo_ibge":"3167103"
         },
         {
            "nome":"SETE LAGOAS",
            "codigo_ibge":"3167202"
         },
         {
            "nome":"SILVEIRANIA",
            "codigo_ibge":"3167301"
         },
         {
            "nome":"SILVIANOPOLIS",
            "codigo_ibge":"3167400"
         },
         {
            "nome":"SIMAO PEREIRA",
            "codigo_ibge":"3167509"
         },
         {
            "nome":"SIMONESIA",
            "codigo_ibge":"3167608"
         },
         {
            "nome":"SOBRALIA",
            "codigo_ibge":"3167707"
         },
         {
            "nome":"SOLEDADE DE MINAS",
            "codigo_ibge":"3167806"
         },
         {
            "nome":"TABULEIRO",
            "codigo_ibge":"3167905"
         },
         {
            "nome":"TAIOBEIRAS",
            "codigo_ibge":"3168002"
         },
         {
            "nome":"TAPARUBA",
            "codigo_ibge":"3168051"
         },
         {
            "nome":"TAPIRA",
            "codigo_ibge":"3168101"
         },
         {
            "nome":"TAPIRAI",
            "codigo_ibge":"3168200"
         },
         {
            "nome":"TAQUARACU DE MINAS",
            "codigo_ibge":"3168309"
         },
         {
            "nome":"TARUMIRIM",
            "codigo_ibge":"3168408"
         },
         {
            "nome":"TEIXEIRAS",
            "codigo_ibge":"3168507"
         },
         {
            "nome":"TEOFILO OTONI",
            "codigo_ibge":"3168606"
         },
         {
            "nome":"TIMOTEO",
            "codigo_ibge":"3168705"
         },
         {
            "nome":"TIRADENTES",
            "codigo_ibge":"3168804"
         },
         {
            "nome":"TIROS",
            "codigo_ibge":"3168903"
         },
         {
            "nome":"TOCANTINS",
            "codigo_ibge":"3169000"
         },
         {
            "nome":"TOCOS DO MOJI",
            "codigo_ibge":"3169059"
         },
         {
            "nome":"TOLEDO",
            "codigo_ibge":"3169109"
         },
         {
            "nome":"TOMBOS",
            "codigo_ibge":"3169208"
         },
         {
            "nome":"TRES CORACOES",
            "codigo_ibge":"3169307"
         },
         {
            "nome":"TRES MARIAS",
            "codigo_ibge":"3169356"
         },
         {
            "nome":"TRES PONTAS",
            "codigo_ibge":"3169406"
         },
         {
            "nome":"TUMIRITINGA",
            "codigo_ibge":"3169505"
         },
         {
            "nome":"TUPACIGUARA",
            "codigo_ibge":"3169604"
         },
         {
            "nome":"TURMALINA",
            "codigo_ibge":"3169703"
         },
         {
            "nome":"TURVOLANDIA",
            "codigo_ibge":"3169802"
         },
         {
            "nome":"UBA",
            "codigo_ibge":"3169901"
         },
         {
            "nome":"UBAI",
            "codigo_ibge":"3170008"
         },
         {
            "nome":"UBAPORANGA",
            "codigo_ibge":"3170057"
         },
         {
            "nome":"UBERABA",
            "codigo_ibge":"3170107"
         },
         {
            "nome":"UBERLANDIA",
            "codigo_ibge":"3170206"
         },
         {
            "nome":"UMBURATIBA",
            "codigo_ibge":"3170305"
         },
         {
            "nome":"UNAI",
            "codigo_ibge":"3170404"
         },
         {
            "nome":"UNIAO DE MINAS",
            "codigo_ibge":"3170438"
         },
         {
            "nome":"URUANA DE MINAS",
            "codigo_ibge":"3170479"
         },
         {
            "nome":"URUCANIA",
            "codigo_ibge":"3170503"
         },
         {
            "nome":"URUCUIA",
            "codigo_ibge":"3170529"
         },
         {
            "nome":"VARGEM ALEGRE",
            "codigo_ibge":"3170578"
         },
         {
            "nome":"VARGEM BONITA",
            "codigo_ibge":"3170602"
         },
         {
            "nome":"VARGEM GRANDE DO RIO PARDO",
            "codigo_ibge":"3170651"
         },
         {
            "nome":"VARGINHA",
            "codigo_ibge":"3170701"
         },
         {
            "nome":"VARJAO DE MINAS",
            "codigo_ibge":"3170750"
         },
         {
            "nome":"VARZEA DA PALMA",
            "codigo_ibge":"3170800"
         },
         {
            "nome":"VARZELANDIA",
            "codigo_ibge":"3170909"
         },
         {
            "nome":"VAZANTE",
            "codigo_ibge":"3171006"
         },
         {
            "nome":"VERDELANDIA",
            "codigo_ibge":"3171030"
         },
         {
            "nome":"VEREDINHA",
            "codigo_ibge":"3171071"
         },
         {
            "nome":"VERISSIMO",
            "codigo_ibge":"3171105"
         },
         {
            "nome":"VERMELHO NOVO",
            "codigo_ibge":"3171154"
         },
         {
            "nome":"VESPASIANO",
            "codigo_ibge":"3171204"
         },
         {
            "nome":"VICOSA",
            "codigo_ibge":"3171303"
         },
         {
            "nome":"VIEIRAS",
            "codigo_ibge":"3171402"
         },
         {
            "nome":"MATHIAS LOBATO",
            "codigo_ibge":"3171501"
         },
         {
            "nome":"VIRGEM DA LAPA",
            "codigo_ibge":"3171600"
         },
         {
            "nome":"VIRGINIA",
            "codigo_ibge":"3171709"
         },
         {
            "nome":"VIRGINOPOLIS",
            "codigo_ibge":"3171808"
         },
         {
            "nome":"VIRGOLANDIA",
            "codigo_ibge":"3171907"
         },
         {
            "nome":"VISCONDE DO RIO BRANCO",
            "codigo_ibge":"3172004"
         },
         {
            "nome":"VOLTA GRANDE",
            "codigo_ibge":"3172103"
         },
         {
            "nome":"WENCESLAU BRAZ",
            "codigo_ibge":"3172202"
         }
      ]
   },
   {
      "estado" : "MS",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"AGUA CLARA",
            "codigo_ibge":"5000203"
         },
         {
            "nome":"ALCINOPOLIS",
            "codigo_ibge":"5000252"
         },
         {
            "nome":"AMAMBAI",
            "codigo_ibge":"5000609"
         },
         {
            "nome":"ANASTACIO",
            "codigo_ibge":"5000708"
         },
         {
            "nome":"ANAURILANDIA",
            "codigo_ibge":"5000807"
         },
         {
            "nome":"ANGELICA",
            "codigo_ibge":"5000856"
         },
         {
            "nome":"ANTONIO JOAO",
            "codigo_ibge":"5000906"
         },
         {
            "nome":"APARECIDA DO TABOADO",
            "codigo_ibge":"5001003"
         },
         {
            "nome":"AQUIDAUANA",
            "codigo_ibge":"5001102"
         },
         {
            "nome":"ARAL MOREIRA",
            "codigo_ibge":"5001243"
         },
         {
            "nome":"BANDEIRANTES",
            "codigo_ibge":"5001508"
         },
         {
            "nome":"BATAGUASSU",
            "codigo_ibge":"5001904"
         },
         {
            "nome":"BATAYPORA",
            "codigo_ibge":"5002001"
         },
         {
            "nome":"BELA VISTA",
            "codigo_ibge":"5002100"
         },
         {
            "nome":"BODOQUENA",
            "codigo_ibge":"5002159"
         },
         {
            "nome":"BONITO",
            "codigo_ibge":"5002209"
         },
         {
            "nome":"BRASILANDIA",
            "codigo_ibge":"5002308"
         },
         {
            "nome":"CAARAPO",
            "codigo_ibge":"5002407"
         },
         {
            "nome":"CAMAPUA",
            "codigo_ibge":"5002605"
         },
         {
            "nome":"CAMPO GRANDE",
            "codigo_ibge":"5002704"
         },
         {
            "nome":"CARACOL",
            "codigo_ibge":"5002803"
         },
         {
            "nome":"CASSILANDIA",
            "codigo_ibge":"5002902"
         },
         {
            "nome":"CHAPADAO DO SUL",
            "codigo_ibge":"5002951"
         },
         {
            "nome":"CORGUINHO",
            "codigo_ibge":"5003108"
         },
         {
            "nome":"CORONEL SAPUCAIA",
            "codigo_ibge":"5003157"
         },
         {
            "nome":"CORUMBA",
            "codigo_ibge":"5003207"
         },
         {
            "nome":"COSTA RICA",
            "codigo_ibge":"5003256"
         },
         {
            "nome":"COXIM",
            "codigo_ibge":"5003306"
         },
         {
            "nome":"DEODAPOLIS",
            "codigo_ibge":"5003454"
         },
         {
            "nome":"DOIS IRMAOS DO BURITI",
            "codigo_ibge":"5003488"
         },
         {
            "nome":"DOURADINA",
            "codigo_ibge":"5003504"
         },
         {
            "nome":"DOURADOS",
            "codigo_ibge":"5003702"
         },
         {
            "nome":"ELDORADO",
            "codigo_ibge":"5003751"
         },
         {
            "nome":"FATIMA DO SUL",
            "codigo_ibge":"5003801"
         },
         {
            "nome":"FIGUEIRAO",
            "codigo_ibge":"5003900"
         },
         {
            "nome":"GLORIA DE DOURADOS",
            "codigo_ibge":"5004007"
         },
         {
            "nome":"GUIA LOPES DA LAGUNA",
            "codigo_ibge":"5004106"
         },
         {
            "nome":"IGUATEMI",
            "codigo_ibge":"5004304"
         },
         {
            "nome":"INOCENCIA",
            "codigo_ibge":"5004403"
         },
         {
            "nome":"ITAPORA",
            "codigo_ibge":"5004502"
         },
         {
            "nome":"ITAQUIRAI",
            "codigo_ibge":"5004601"
         },
         {
            "nome":"IVINHEMA",
            "codigo_ibge":"5004700"
         },
         {
            "nome":"JAPORA",
            "codigo_ibge":"5004809"
         },
         {
            "nome":"JARAGUARI",
            "codigo_ibge":"5004908"
         },
         {
            "nome":"JARDIM",
            "codigo_ibge":"5005004"
         },
         {
            "nome":"JATEI",
            "codigo_ibge":"5005103"
         },
         {
            "nome":"JUTI",
            "codigo_ibge":"5005152"
         },
         {
            "nome":"LADARIO",
            "codigo_ibge":"5005202"
         },
         {
            "nome":"LAGUNA CARAPA",
            "codigo_ibge":"5005251"
         },
         {
            "nome":"MARACAJU",
            "codigo_ibge":"5005400"
         },
         {
            "nome":"MIRANDA",
            "codigo_ibge":"5005608"
         },
         {
            "nome":"MUNDO NOVO",
            "codigo_ibge":"5005681"
         },
         {
            "nome":"NAVIRAI",
            "codigo_ibge":"5005707"
         },
         {
            "nome":"NIOAQUE",
            "codigo_ibge":"5005806"
         },
         {
            "nome":"NOVA ALVORADA DO SUL",
            "codigo_ibge":"5006002"
         },
         {
            "nome":"NOVA ANDRADINA",
            "codigo_ibge":"5006200"
         },
         {
            "nome":"NOVO HORIZONTE DO SUL",
            "codigo_ibge":"5006259"
         },
         {
            "nome":"PARAISO DAS AGUAS",
            "codigo_ibge":"5006275"
         },
         {
            "nome":"PARANAIBA",
            "codigo_ibge":"5006309"
         },
         {
            "nome":"PARANHOS",
            "codigo_ibge":"5006358"
         },
         {
            "nome":"PEDRO GOMES",
            "codigo_ibge":"5006408"
         },
         {
            "nome":"PONTA PORA",
            "codigo_ibge":"5006606"
         },
         {
            "nome":"PORTO MURTINHO",
            "codigo_ibge":"5006903"
         },
         {
            "nome":"RIBAS DO RIO PARDO",
            "codigo_ibge":"5007109"
         },
         {
            "nome":"RIO BRILHANTE",
            "codigo_ibge":"5007208"
         },
         {
            "nome":"RIO NEGRO",
            "codigo_ibge":"5007307"
         },
         {
            "nome":"RIO VERDE DE MATO GROSSO",
            "codigo_ibge":"5007406"
         },
         {
            "nome":"ROCHEDO",
            "codigo_ibge":"5007505"
         },
         {
            "nome":"SANTA RITA DO PARDO",
            "codigo_ibge":"5007554"
         },
         {
            "nome":"SAO GABRIEL DO OESTE",
            "codigo_ibge":"5007695"
         },
         {
            "nome":"SETE QUEDAS",
            "codigo_ibge":"5007703"
         },
         {
            "nome":"SELVIRIA",
            "codigo_ibge":"5007802"
         },
         {
            "nome":"SIDROLANDIA",
            "codigo_ibge":"5007901"
         },
         {
            "nome":"SONORA",
            "codigo_ibge":"5007935"
         },
         {
            "nome":"TACURU",
            "codigo_ibge":"5007950"
         },
         {
            "nome":"TAQUARUSSU",
            "codigo_ibge":"5007976"
         },
         {
            "nome":"TERENOS",
            "codigo_ibge":"5008008"
         },
         {
            "nome":"TRES LAGOAS",
            "codigo_ibge":"5008305"
         },
         {
            "nome":"VICENTINA",
            "codigo_ibge":"5008404"
         }
      ]
   },
   {
      "estado" : "MT",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ACORIZAL",
            "codigo_ibge":"5100102"
         },
         {
            "nome":"AGUA BOA",
            "codigo_ibge":"5100201"
         },
         {
            "nome":"ALTA FLORESTA",
            "codigo_ibge":"5100250"
         },
         {
            "nome":"ALTO ARAGUAIA",
            "codigo_ibge":"5100300"
         },
         {
            "nome":"ALTO BOA VISTA",
            "codigo_ibge":"5100359"
         },
         {
            "nome":"ALTO GARCAS",
            "codigo_ibge":"5100409"
         },
         {
            "nome":"ALTO PARAGUAI",
            "codigo_ibge":"5100508"
         },
         {
            "nome":"ALTO TAQUARI",
            "codigo_ibge":"5100607"
         },
         {
            "nome":"APIACAS",
            "codigo_ibge":"5100805"
         },
         {
            "nome":"ARAGUAIANA",
            "codigo_ibge":"5101001"
         },
         {
            "nome":"ARAGUAINHA",
            "codigo_ibge":"5101209"
         },
         {
            "nome":"ARAPUTANGA",
            "codigo_ibge":"5101258"
         },
         {
            "nome":"ARENAPOLIS",
            "codigo_ibge":"5101308"
         },
         {
            "nome":"ARIPUANA",
            "codigo_ibge":"5101407"
         },
         {
            "nome":"BARAO DE MELGACO",
            "codigo_ibge":"5101605"
         },
         {
            "nome":"BARRA DO BUGRES",
            "codigo_ibge":"5101704"
         },
         {
            "nome":"BARRA DO GARCAS",
            "codigo_ibge":"5101803"
         },
         {
            "nome":"BOM JESUS DO ARAGUAIA",
            "codigo_ibge":"5101852"
         },
         {
            "nome":"BRASNORTE",
            "codigo_ibge":"5101902"
         },
         {
            "nome":"CACERES",
            "codigo_ibge":"5102504"
         },
         {
            "nome":"CAMPINAPOLIS",
            "codigo_ibge":"5102603"
         },
         {
            "nome":"CAMPO NOVO DO PARECIS",
            "codigo_ibge":"5102637"
         },
         {
            "nome":"CAMPO VERDE",
            "codigo_ibge":"5102678"
         },
         {
            "nome":"CAMPOS DE JULIO",
            "codigo_ibge":"5102686"
         },
         {
            "nome":"CANABRAVA DO NORTE",
            "codigo_ibge":"5102694"
         },
         {
            "nome":"CANARANA",
            "codigo_ibge":"5102702"
         },
         {
            "nome":"CARLINDA",
            "codigo_ibge":"5102793"
         },
         {
            "nome":"CASTANHEIRA",
            "codigo_ibge":"5102850"
         },
         {
            "nome":"CHAPADA DOS GUIMARAES",
            "codigo_ibge":"5103007"
         },
         {
            "nome":"CLAUDIA",
            "codigo_ibge":"5103056"
         },
         {
            "nome":"COCALINHO",
            "codigo_ibge":"5103106"
         },
         {
            "nome":"COLIDER",
            "codigo_ibge":"5103205"
         },
         {
            "nome":"COLNIZA",
            "codigo_ibge":"5103254"
         },
         {
            "nome":"COMODORO",
            "codigo_ibge":"5103304"
         },
         {
            "nome":"CONFRESA",
            "codigo_ibge":"5103353"
         },
         {
            "nome":"CONQUISTA D OESTE",
            "codigo_ibge":"5103361"
         },
         {
            "nome":"COTRIGUACU",
            "codigo_ibge":"5103379"
         },
         {
            "nome":"CUIABA",
            "codigo_ibge":"5103403"
         },
         {
            "nome":"CURVELANDIA",
            "codigo_ibge":"5103437"
         },
         {
            "nome":"DENISE",
            "codigo_ibge":"5103452"
         },
         {
            "nome":"DIAMANTINO",
            "codigo_ibge":"5103502"
         },
         {
            "nome":"DOM AQUINO",
            "codigo_ibge":"5103601"
         },
         {
            "nome":"FELIZ NATAL",
            "codigo_ibge":"5103700"
         },
         {
            "nome":"FIGUEIROPOLIS D OESTE",
            "codigo_ibge":"5103809"
         },
         {
            "nome":"GAUCHA DO NORTE",
            "codigo_ibge":"5103858"
         },
         {
            "nome":"GENERAL CARNEIRO",
            "codigo_ibge":"5103908"
         },
         {
            "nome":"GLORIA D OESTE",
            "codigo_ibge":"5103957"
         },
         {
            "nome":"GUARANTA DO NORTE",
            "codigo_ibge":"5104104"
         },
         {
            "nome":"GUIRATINGA",
            "codigo_ibge":"5104203"
         },
         {
            "nome":"INDIAVAI",
            "codigo_ibge":"5104500"
         },
         {
            "nome":"IPIRANGA DO NORTE",
            "codigo_ibge":"5104526"
         },
         {
            "nome":"ITANHANGA",
            "codigo_ibge":"5104542"
         },
         {
            "nome":"ITAUBA",
            "codigo_ibge":"5104559"
         },
         {
            "nome":"ITIQUIRA",
            "codigo_ibge":"5104609"
         },
         {
            "nome":"JACIARA",
            "codigo_ibge":"5104807"
         },
         {
            "nome":"JANGADA",
            "codigo_ibge":"5104906"
         },
         {
            "nome":"JAURU",
            "codigo_ibge":"5105002"
         },
         {
            "nome":"JUARA",
            "codigo_ibge":"5105101"
         },
         {
            "nome":"JUINA",
            "codigo_ibge":"5105150"
         },
         {
            "nome":"JURUENA",
            "codigo_ibge":"5105176"
         },
         {
            "nome":"JUSCIMEIRA",
            "codigo_ibge":"5105200"
         },
         {
            "nome":"LAMBARI D OESTE",
            "codigo_ibge":"5105234"
         },
         {
            "nome":"LUCAS DO RIO VERDE",
            "codigo_ibge":"5105259"
         },
         {
            "nome":"LUCIARA",
            "codigo_ibge":"5105309"
         },
         {
            "nome":"VILA BELA DA SANTISSIMA TRINDADE",
            "codigo_ibge":"5105507"
         },
         {
            "nome":"MARCELANDIA",
            "codigo_ibge":"5105580"
         },
         {
            "nome":"MATUPA",
            "codigo_ibge":"5105606"
         },
         {
            "nome":"MIRASSOL D OESTE",
            "codigo_ibge":"5105622"
         },
         {
            "nome":"NOBRES",
            "codigo_ibge":"5105903"
         },
         {
            "nome":"NORTELANDIA",
            "codigo_ibge":"5106000"
         },
         {
            "nome":"NOSSA SENHORA DO LIVRAMENTO",
            "codigo_ibge":"5106109"
         },
         {
            "nome":"NOVA BANDEIRANTES",
            "codigo_ibge":"5106158"
         },
         {
            "nome":"NOVA NAZARE",
            "codigo_ibge":"5106174"
         },
         {
            "nome":"NOVA LACERDA",
            "codigo_ibge":"5106182"
         },
         {
            "nome":"NOVA SANTA HELENA",
            "codigo_ibge":"5106190"
         },
         {
            "nome":"NOVA BRASILANDIA",
            "codigo_ibge":"5106208"
         },
         {
            "nome":"NOVA CANAA DO NORTE",
            "codigo_ibge":"5106216"
         },
         {
            "nome":"NOVA MUTUM",
            "codigo_ibge":"5106224"
         },
         {
            "nome":"NOVA OLIMPIA",
            "codigo_ibge":"5106232"
         },
         {
            "nome":"NOVA UBIRATA",
            "codigo_ibge":"5106240"
         },
         {
            "nome":"NOVA XAVANTINA",
            "codigo_ibge":"5106257"
         },
         {
            "nome":"NOVO MUNDO",
            "codigo_ibge":"5106265"
         },
         {
            "nome":"NOVO HORIZONTE DO NORTE",
            "codigo_ibge":"5106273"
         },
         {
            "nome":"NOVO SAO JOAQUIM",
            "codigo_ibge":"5106281"
         },
         {
            "nome":"PARANAITA",
            "codigo_ibge":"5106299"
         },
         {
            "nome":"PARANATINGA",
            "codigo_ibge":"5106307"
         },
         {
            "nome":"NOVO SANTO ANTONIO",
            "codigo_ibge":"5106315"
         },
         {
            "nome":"PEDRA PRETA",
            "codigo_ibge":"5106372"
         },
         {
            "nome":"PEIXOTO DE AZEVEDO",
            "codigo_ibge":"5106422"
         },
         {
            "nome":"PLANALTO DA SERRA",
            "codigo_ibge":"5106455"
         },
         {
            "nome":"POCONE",
            "codigo_ibge":"5106505"
         },
         {
            "nome":"PONTAL DO ARAGUAIA",
            "codigo_ibge":"5106653"
         },
         {
            "nome":"PONTE BRANCA",
            "codigo_ibge":"5106703"
         },
         {
            "nome":"PONTES E LACERDA",
            "codigo_ibge":"5106752"
         },
         {
            "nome":"PORTO ALEGRE DO NORTE",
            "codigo_ibge":"5106778"
         },
         {
            "nome":"PORTO DOS GAUCHOS",
            "codigo_ibge":"5106802"
         },
         {
            "nome":"PORTO ESPERIDIAO",
            "codigo_ibge":"5106828"
         },
         {
            "nome":"PORTO ESTRELA",
            "codigo_ibge":"5106851"
         },
         {
            "nome":"POXOREO",
            "codigo_ibge":"5107008"
         },
         {
            "nome":"PRIMAVERA DO LESTE",
            "codigo_ibge":"5107040"
         },
         {
            "nome":"QUERENCIA",
            "codigo_ibge":"5107065"
         },
         {
            "nome":"SAO JOSE DOS QUATRO MARCOS",
            "codigo_ibge":"5107107"
         },
         {
            "nome":"RESERVA DO CABACAL",
            "codigo_ibge":"5107156"
         },
         {
            "nome":"RIBEIRAO CASCALHEIRA",
            "codigo_ibge":"5107180"
         },
         {
            "nome":"RIBEIRAOZINHO",
            "codigo_ibge":"5107198"
         },
         {
            "nome":"RIO BRANCO",
            "codigo_ibge":"5107206"
         },
         {
            "nome":"SANTA CARMEM",
            "codigo_ibge":"5107248"
         },
         {
            "nome":"SANTO AFONSO",
            "codigo_ibge":"5107263"
         },
         {
            "nome":"SAO JOSE DO POVO",
            "codigo_ibge":"5107297"
         },
         {
            "nome":"SAO JOSE DO RIO CLARO",
            "codigo_ibge":"5107305"
         },
         {
            "nome":"SAO JOSE DO XINGU",
            "codigo_ibge":"5107354"
         },
         {
            "nome":"SAO PEDRO DA CIPA",
            "codigo_ibge":"5107404"
         },
         {
            "nome":"RONDOLANDIA",
            "codigo_ibge":"5107578"
         },
         {
            "nome":"RONDONOPOLIS",
            "codigo_ibge":"5107602"
         },
         {
            "nome":"ROSARIO OESTE",
            "codigo_ibge":"5107701"
         },
         {
            "nome":"SANTA CRUZ DO XINGU",
            "codigo_ibge":"5107743"
         },
         {
            "nome":"SALTO DO CEU",
            "codigo_ibge":"5107750"
         },
         {
            "nome":"SANTA RITA DO TRIVELATO",
            "codigo_ibge":"5107768"
         },
         {
            "nome":"SANTA TEREZINHA",
            "codigo_ibge":"5107776"
         },
         {
            "nome":"SANTO ANTONIO DO LESTE",
            "codigo_ibge":"5107792"
         },
         {
            "nome":"SANTO ANTONIO DO LEVERGER",
            "codigo_ibge":"5107800"
         },
         {
            "nome":"SAO FELIX DO ARAGUAIA",
            "codigo_ibge":"5107859"
         },
         {
            "nome":"SAPEZAL",
            "codigo_ibge":"5107875"
         },
         {
            "nome":"SERRA NOVA DOURADA",
            "codigo_ibge":"5107883"
         },
         {
            "nome":"SINOP",
            "codigo_ibge":"5107909"
         },
         {
            "nome":"SORRISO",
            "codigo_ibge":"5107925"
         },
         {
            "nome":"TABAPORA",
            "codigo_ibge":"5107941"
         },
         {
            "nome":"TANGARA DA SERRA",
            "codigo_ibge":"5107958"
         },
         {
            "nome":"TAPURAH",
            "codigo_ibge":"5108006"
         },
         {
            "nome":"TERRA NOVA DO NORTE",
            "codigo_ibge":"5108055"
         },
         {
            "nome":"TESOURO",
            "codigo_ibge":"5108105"
         },
         {
            "nome":"TORIXOREU",
            "codigo_ibge":"5108204"
         },
         {
            "nome":"UNIAO DO SUL",
            "codigo_ibge":"5108303"
         },
         {
            "nome":"VALE DE SAO DOMINGOS",
            "codigo_ibge":"5108352"
         },
         {
            "nome":"VARZEA GRANDE",
            "codigo_ibge":"5108402"
         },
         {
            "nome":"VERA",
            "codigo_ibge":"5108501"
         },
         {
            "nome":"VILA RICA",
            "codigo_ibge":"5108600"
         },
         {
            "nome":"NOVA GUARITA",
            "codigo_ibge":"5108808"
         },
         {
            "nome":"NOVA MARILANDIA",
            "codigo_ibge":"5108857"
         },
         {
            "nome":"NOVA MARINGA",
            "codigo_ibge":"5108907"
         },
         {
            "nome":"NOVA MONTE VERDE",
            "codigo_ibge":"5108956"
         }
      ]
   },
   {
      "estado" : "MA",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ACAILANDIA",
            "codigo_ibge":"2100055"
         },
         {
            "nome":"AFONSO CUNHA",
            "codigo_ibge":"2100105"
         },
         {
            "nome":"AGUA DOCE DO MARANHAO",
            "codigo_ibge":"2100154"
         },
         {
            "nome":"ALCANTARA",
            "codigo_ibge":"2100204"
         },
         {
            "nome":"ALDEIAS ALTAS",
            "codigo_ibge":"2100303"
         },
         {
            "nome":"ALTAMIRA DO MARANHAO",
            "codigo_ibge":"2100402"
         },
         {
            "nome":"ALTO ALEGRE DO MARANHAO",
            "codigo_ibge":"2100436"
         },
         {
            "nome":"ALTO ALEGRE DO PINDARE",
            "codigo_ibge":"2100477"
         },
         {
            "nome":"ALTO PARNAIBA",
            "codigo_ibge":"2100501"
         },
         {
            "nome":"AMAPA DO MARANHAO",
            "codigo_ibge":"2100550"
         },
         {
            "nome":"AMARANTE DO MARANHAO",
            "codigo_ibge":"2100600"
         },
         {
            "nome":"ANAJATUBA",
            "codigo_ibge":"2100709"
         },
         {
            "nome":"ANAPURUS",
            "codigo_ibge":"2100808"
         },
         {
            "nome":"APICUM-ACU",
            "codigo_ibge":"2100832"
         },
         {
            "nome":"ARAGUANA",
            "codigo_ibge":"2100873"
         },
         {
            "nome":"ARAIOSES",
            "codigo_ibge":"2100907"
         },
         {
            "nome":"ARAME",
            "codigo_ibge":"2100956"
         },
         {
            "nome":"ARARI",
            "codigo_ibge":"2101004"
         },
         {
            "nome":"AXIXA",
            "codigo_ibge":"2101103"
         },
         {
            "nome":"BACABAL",
            "codigo_ibge":"2101202"
         },
         {
            "nome":"BACABEIRA",
            "codigo_ibge":"2101251"
         },
         {
            "nome":"BACURI",
            "codigo_ibge":"2101301"
         },
         {
            "nome":"BACURITUBA",
            "codigo_ibge":"2101350"
         },
         {
            "nome":"BALSAS",
            "codigo_ibge":"2101400"
         },
         {
            "nome":"BARAO DE GRAJAU",
            "codigo_ibge":"2101509"
         },
         {
            "nome":"BARRA DO CORDA",
            "codigo_ibge":"2101608"
         },
         {
            "nome":"BARREIRINHAS",
            "codigo_ibge":"2101707"
         },
         {
            "nome":"BELAGUA",
            "codigo_ibge":"2101731"
         },
         {
            "nome":"BELA VISTA DO MARANHAO",
            "codigo_ibge":"2101772"
         },
         {
            "nome":"BENEDITO LEITE",
            "codigo_ibge":"2101806"
         },
         {
            "nome":"BEQUIMAO",
            "codigo_ibge":"2101905"
         },
         {
            "nome":"BERNARDO DO MEARIM",
            "codigo_ibge":"2101939"
         },
         {
            "nome":"BOA VISTA DO GURUPI",
            "codigo_ibge":"2101970"
         },
         {
            "nome":"BOM JARDIM",
            "codigo_ibge":"2102002"
         },
         {
            "nome":"BOM JESUS DAS SELVAS",
            "codigo_ibge":"2102036"
         },
         {
            "nome":"BOM LUGAR",
            "codigo_ibge":"2102077"
         },
         {
            "nome":"BREJO",
            "codigo_ibge":"2102101"
         },
         {
            "nome":"BREJO DE AREIA",
            "codigo_ibge":"2102150"
         },
         {
            "nome":"BURITI",
            "codigo_ibge":"2102200"
         },
         {
            "nome":"BURITI BRAVO",
            "codigo_ibge":"2102309"
         },
         {
            "nome":"BURITICUPU",
            "codigo_ibge":"2102325"
         },
         {
            "nome":"BURITIRANA",
            "codigo_ibge":"2102358"
         },
         {
            "nome":"CACHOEIRA GRANDE",
            "codigo_ibge":"2102374"
         },
         {
            "nome":"CAJAPIO",
            "codigo_ibge":"2102408"
         },
         {
            "nome":"CAJARI",
            "codigo_ibge":"2102507"
         },
         {
            "nome":"CAMPESTRE DO MARANHAO",
            "codigo_ibge":"2102556"
         },
         {
            "nome":"CANDIDO MENDES",
            "codigo_ibge":"2102606"
         },
         {
            "nome":"CANTANHEDE",
            "codigo_ibge":"2102705"
         },
         {
            "nome":"CAPINZAL DO NORTE",
            "codigo_ibge":"2102754"
         },
         {
            "nome":"CAROLINA",
            "codigo_ibge":"2102804"
         },
         {
            "nome":"CARUTAPERA",
            "codigo_ibge":"2102903"
         },
         {
            "nome":"CAXIAS",
            "codigo_ibge":"2103000"
         },
         {
            "nome":"CEDRAL",
            "codigo_ibge":"2103109"
         },
         {
            "nome":"CENTRAL DO MARANHAO",
            "codigo_ibge":"2103125"
         },
         {
            "nome":"CENTRO DO GUILHERME",
            "codigo_ibge":"2103158"
         },
         {
            "nome":"CENTRO NOVO DO MARANHAO",
            "codigo_ibge":"2103174"
         },
         {
            "nome":"CHAPADINHA",
            "codigo_ibge":"2103208"
         },
         {
            "nome":"CIDELANDIA",
            "codigo_ibge":"2103257"
         },
         {
            "nome":"CODO",
            "codigo_ibge":"2103307"
         },
         {
            "nome":"COELHO NETO",
            "codigo_ibge":"2103406"
         },
         {
            "nome":"COLINAS",
            "codigo_ibge":"2103505"
         },
         {
            "nome":"CONCEICAO DO LAGO-ACU",
            "codigo_ibge":"2103554"
         },
         {
            "nome":"COROATA",
            "codigo_ibge":"2103604"
         },
         {
            "nome":"CURURUPU",
            "codigo_ibge":"2103703"
         },
         {
            "nome":"DAVINOPOLIS",
            "codigo_ibge":"2103752"
         },
         {
            "nome":"DOM PEDRO",
            "codigo_ibge":"2103802"
         },
         {
            "nome":"DUQUE BACELAR",
            "codigo_ibge":"2103901"
         },
         {
            "nome":"ESPERANTINOPOLIS",
            "codigo_ibge":"2104008"
         },
         {
            "nome":"ESTREITO",
            "codigo_ibge":"2104057"
         },
         {
            "nome":"FEIRA NOVA DO MARANHAO",
            "codigo_ibge":"2104073"
         },
         {
            "nome":"FERNANDO FALCAO",
            "codigo_ibge":"2104081"
         },
         {
            "nome":"FORMOSA DA SERRA NEGRA",
            "codigo_ibge":"2104099"
         },
         {
            "nome":"FORTALEZA DOS NOGUEIRAS",
            "codigo_ibge":"2104107"
         },
         {
            "nome":"FORTUNA",
            "codigo_ibge":"2104206"
         },
         {
            "nome":"GODOFREDO VIANA",
            "codigo_ibge":"2104305"
         },
         {
            "nome":"GONCALVES DIAS",
            "codigo_ibge":"2104404"
         },
         {
            "nome":"GOVERNADOR ARCHER",
            "codigo_ibge":"2104503"
         },
         {
            "nome":"GOVERNADOR EDISON LOBAO",
            "codigo_ibge":"2104552"
         },
         {
            "nome":"GOVERNADOR EUGENIO BARROS",
            "codigo_ibge":"2104602"
         },
         {
            "nome":"GOVERNADOR LUIZ ROCHA",
            "codigo_ibge":"2104628"
         },
         {
            "nome":"GOVERNADOR NEWTON BELLO",
            "codigo_ibge":"2104651"
         },
         {
            "nome":"GOVERNADOR NUNES FREIRE",
            "codigo_ibge":"2104677"
         },
         {
            "nome":"GRACA ARANHA",
            "codigo_ibge":"2104701"
         },
         {
            "nome":"GRAJAU",
            "codigo_ibge":"2104800"
         },
         {
            "nome":"GUIMARAES",
            "codigo_ibge":"2104909"
         },
         {
            "nome":"HUMBERTO DE CAMPOS",
            "codigo_ibge":"2105005"
         },
         {
            "nome":"ICATU",
            "codigo_ibge":"2105104"
         },
         {
            "nome":"IGARAPE DO MEIO",
            "codigo_ibge":"2105153"
         },
         {
            "nome":"IGARAPE GRANDE",
            "codigo_ibge":"2105203"
         },
         {
            "nome":"IMPERATRIZ",
            "codigo_ibge":"2105302"
         },
         {
            "nome":"ITAIPAVA DO GRAJAU",
            "codigo_ibge":"2105351"
         },
         {
            "nome":"ITAPECURU MIRIM",
            "codigo_ibge":"2105401"
         },
         {
            "nome":"ITINGA DO MARANHAO",
            "codigo_ibge":"2105427"
         },
         {
            "nome":"JATOBA",
            "codigo_ibge":"2105450"
         },
         {
            "nome":"JENIPAPO DOS VIEIRAS",
            "codigo_ibge":"2105476"
         },
         {
            "nome":"JOAO LISBOA",
            "codigo_ibge":"2105500"
         },
         {
            "nome":"JOSELANDIA",
            "codigo_ibge":"2105609"
         },
         {
            "nome":"JUNCO DO MARANHAO",
            "codigo_ibge":"2105658"
         },
         {
            "nome":"LAGO DA PEDRA",
            "codigo_ibge":"2105708"
         },
         {
            "nome":"LAGO DO JUNCO",
            "codigo_ibge":"2105807"
         },
         {
            "nome":"LAGO VERDE",
            "codigo_ibge":"2105906"
         },
         {
            "nome":"LAGOA DO MATO",
            "codigo_ibge":"2105922"
         },
         {
            "nome":"LAGO DOS RODRIGUES",
            "codigo_ibge":"2105948"
         },
         {
            "nome":"LAGOA GRANDE DO MARANHAO",
            "codigo_ibge":"2105963"
         },
         {
            "nome":"LAJEADO NOVO",
            "codigo_ibge":"2105989"
         },
         {
            "nome":"LIMA CAMPOS",
            "codigo_ibge":"2106003"
         },
         {
            "nome":"LORETO",
            "codigo_ibge":"2106102"
         },
         {
            "nome":"LUIS DOMINGUES",
            "codigo_ibge":"2106201"
         },
         {
            "nome":"MAGALHAES DE ALMEIDA",
            "codigo_ibge":"2106300"
         },
         {
            "nome":"MARACACUME",
            "codigo_ibge":"2106326"
         },
         {
            "nome":"MARAJA DO SENA",
            "codigo_ibge":"2106359"
         },
         {
            "nome":"MARANHAOZINHO",
            "codigo_ibge":"2106375"
         },
         {
            "nome":"MATA ROMA",
            "codigo_ibge":"2106409"
         },
         {
            "nome":"MATINHA",
            "codigo_ibge":"2106508"
         },
         {
            "nome":"MATOES",
            "codigo_ibge":"2106607"
         },
         {
            "nome":"MATOES DO NORTE",
            "codigo_ibge":"2106631"
         },
         {
            "nome":"MILAGRES DO MARANHAO",
            "codigo_ibge":"2106672"
         },
         {
            "nome":"MIRADOR",
            "codigo_ibge":"2106706"
         },
         {
            "nome":"MIRANDA DO NORTE",
            "codigo_ibge":"2106755"
         },
         {
            "nome":"MIRINZAL",
            "codigo_ibge":"2106805"
         },
         {
            "nome":"MONCAO",
            "codigo_ibge":"2106904"
         },
         {
            "nome":"MONTES ALTOS",
            "codigo_ibge":"2107001"
         },
         {
            "nome":"MORROS",
            "codigo_ibge":"2107100"
         },
         {
            "nome":"NINA RODRIGUES",
            "codigo_ibge":"2107209"
         },
         {
            "nome":"NOVA COLINAS",
            "codigo_ibge":"2107258"
         },
         {
            "nome":"NOVA IORQUE",
            "codigo_ibge":"2107308"
         },
         {
            "nome":"NOVA OLINDA DO MARANHAO",
            "codigo_ibge":"2107357"
         },
         {
            "nome":"OLHO D AGUA DAS CUNHAS",
            "codigo_ibge":"2107407"
         },
         {
            "nome":"OLINDA NOVA DO MARANHAO",
            "codigo_ibge":"2107456"
         },
         {
            "nome":"PACO DO LUMIAR",
            "codigo_ibge":"2107506"
         },
         {
            "nome":"PALMEIRANDIA",
            "codigo_ibge":"2107605"
         },
         {
            "nome":"PARAIBANO",
            "codigo_ibge":"2107704"
         },
         {
            "nome":"PARNARAMA",
            "codigo_ibge":"2107803"
         },
         {
            "nome":"PASSAGEM FRANCA",
            "codigo_ibge":"2107902"
         },
         {
            "nome":"PASTOS BONS",
            "codigo_ibge":"2108009"
         },
         {
            "nome":"PAULINO NEVES",
            "codigo_ibge":"2108058"
         },
         {
            "nome":"PAULO RAMOS",
            "codigo_ibge":"2108108"
         },
         {
            "nome":"PEDREIRAS",
            "codigo_ibge":"2108207"
         },
         {
            "nome":"PEDRO DO ROSARIO",
            "codigo_ibge":"2108256"
         },
         {
            "nome":"PENALVA",
            "codigo_ibge":"2108306"
         },
         {
            "nome":"PERI MIRIM",
            "codigo_ibge":"2108405"
         },
         {
            "nome":"PERITORO",
            "codigo_ibge":"2108454"
         },
         {
            "nome":"PINDARE-MIRIM",
            "codigo_ibge":"2108504"
         },
         {
            "nome":"PINHEIRO",
            "codigo_ibge":"2108603"
         },
         {
            "nome":"PIO XII",
            "codigo_ibge":"2108702"
         },
         {
            "nome":"PIRAPEMAS",
            "codigo_ibge":"2108801"
         },
         {
            "nome":"POCAO DE PEDRAS",
            "codigo_ibge":"2108900"
         },
         {
            "nome":"PORTO FRANCO",
            "codigo_ibge":"2109007"
         },
         {
            "nome":"PORTO RICO DO MARANHAO",
            "codigo_ibge":"2109056"
         },
         {
            "nome":"PRESIDENTE DUTRA",
            "codigo_ibge":"2109106"
         },
         {
            "nome":"PRESIDENTE JUSCELINO",
            "codigo_ibge":"2109205"
         },
         {
            "nome":"PRESIDENTE MEDICI",
            "codigo_ibge":"2109239"
         },
         {
            "nome":"PRESIDENTE SARNEY",
            "codigo_ibge":"2109270"
         },
         {
            "nome":"PRESIDENTE VARGAS",
            "codigo_ibge":"2109304"
         },
         {
            "nome":"PRIMEIRA CRUZ",
            "codigo_ibge":"2109403"
         },
         {
            "nome":"RAPOSA",
            "codigo_ibge":"2109452"
         },
         {
            "nome":"RIACHAO",
            "codigo_ibge":"2109502"
         },
         {
            "nome":"RIBAMAR FIQUENE",
            "codigo_ibge":"2109551"
         },
         {
            "nome":"ROSARIO",
            "codigo_ibge":"2109601"
         },
         {
            "nome":"SAMBAIBA",
            "codigo_ibge":"2109700"
         },
         {
            "nome":"SANTA FILOMENA DO MARANHAO",
            "codigo_ibge":"2109759"
         },
         {
            "nome":"SANTA HELENA",
            "codigo_ibge":"2109809"
         },
         {
            "nome":"SANTA INES",
            "codigo_ibge":"2109908"
         },
         {
            "nome":"SANTA LUZIA",
            "codigo_ibge":"2110005"
         },
         {
            "nome":"SANTA LUZIA DO PARUA",
            "codigo_ibge":"2110039"
         },
         {
            "nome":"SANTA QUITERIA DO MARANHAO",
            "codigo_ibge":"2110104"
         },
         {
            "nome":"SANTA RITA",
            "codigo_ibge":"2110203"
         },
         {
            "nome":"SANTANA DO MARANHAO",
            "codigo_ibge":"2110237"
         },
         {
            "nome":"SANTO AMARO DO MARANHAO",
            "codigo_ibge":"2110278"
         },
         {
            "nome":"SANTO ANTONIO DOS LOPES",
            "codigo_ibge":"2110302"
         },
         {
            "nome":"SAO BENEDITO DO RIO PRETO",
            "codigo_ibge":"2110401"
         },
         {
            "nome":"SAO BENTO",
            "codigo_ibge":"2110500"
         },
         {
            "nome":"SAO BERNARDO",
            "codigo_ibge":"2110609"
         },
         {
            "nome":"SAO DOMINGOS DO AZEITAO",
            "codigo_ibge":"2110658"
         },
         {
            "nome":"SAO DOMINGOS DO MARANHAO",
            "codigo_ibge":"2110708"
         },
         {
            "nome":"SAO FELIX DE BALSAS",
            "codigo_ibge":"2110807"
         },
         {
            "nome":"SAO FRANCISCO DO BREJAO",
            "codigo_ibge":"2110856"
         },
         {
            "nome":"SAO FRANCISCO DO MARANHAO",
            "codigo_ibge":"2110906"
         },
         {
            "nome":"SAO JOAO BATISTA",
            "codigo_ibge":"2111003"
         },
         {
            "nome":"SAO JOAO DO CARU",
            "codigo_ibge":"2111029"
         },
         {
            "nome":"SAO JOAO DO PARAISO",
            "codigo_ibge":"2111052"
         },
         {
            "nome":"SAO JOAO DO SOTER",
            "codigo_ibge":"2111078"
         },
         {
            "nome":"SAO JOAO DOS PATOS",
            "codigo_ibge":"2111102"
         },
         {
            "nome":"SAO JOSE DE RIBAMAR",
            "codigo_ibge":"2111201"
         },
         {
            "nome":"SAO JOSE DOS BASILIOS",
            "codigo_ibge":"2111250"
         },
         {
            "nome":"SAO LUIS",
            "codigo_ibge":"2111300"
         },
         {
            "nome":"SAO LUIS GONZAGA DO MARANHAO",
            "codigo_ibge":"2111409"
         },
         {
            "nome":"SAO MATEUS DO MARANHAO",
            "codigo_ibge":"2111508"
         },
         {
            "nome":"SAO PEDRO DA AGUA BRANCA",
            "codigo_ibge":"2111532"
         },
         {
            "nome":"SAO PEDRO DOS CRENTES",
            "codigo_ibge":"2111573"
         },
         {
            "nome":"SAO RAIMUNDO DAS MANGABEIRAS",
            "codigo_ibge":"2111607"
         },
         {
            "nome":"SAO RAIMUNDO DO DOCA BEZERRA",
            "codigo_ibge":"2111631"
         },
         {
            "nome":"SAO ROBERTO",
            "codigo_ibge":"2111672"
         },
         {
            "nome":"SAO VICENTE FERRER",
            "codigo_ibge":"2111706"
         },
         {
            "nome":"SATUBINHA",
            "codigo_ibge":"2111722"
         },
         {
            "nome":"SENADOR ALEXANDRE COSTA",
            "codigo_ibge":"2111748"
         },
         {
            "nome":"SENADOR LA ROCQUE",
            "codigo_ibge":"2111763"
         },
         {
            "nome":"SERRANO DO MARANHAO",
            "codigo_ibge":"2111789"
         },
         {
            "nome":"SITIO NOVO",
            "codigo_ibge":"2111805"
         },
         {
            "nome":"SUCUPIRA DO NORTE",
            "codigo_ibge":"2111904"
         },
         {
            "nome":"SUCUPIRA DO RIACHAO",
            "codigo_ibge":"2111953"
         },
         {
            "nome":"TASSO FRAGOSO",
            "codigo_ibge":"2112001"
         },
         {
            "nome":"TIMBIRAS",
            "codigo_ibge":"2112100"
         },
         {
            "nome":"TIMON",
            "codigo_ibge":"2112209"
         },
         {
            "nome":"TRIZIDELA DO VALE",
            "codigo_ibge":"2112233"
         },
         {
            "nome":"TUFILANDIA",
            "codigo_ibge":"2112274"
         },
         {
            "nome":"TUNTUM",
            "codigo_ibge":"2112308"
         },
         {
            "nome":"TURIACU",
            "codigo_ibge":"2112407"
         },
         {
            "nome":"TURILANDIA",
            "codigo_ibge":"2112456"
         },
         {
            "nome":"TUTOIA",
            "codigo_ibge":"2112506"
         },
         {
            "nome":"URBANO SANTOS",
            "codigo_ibge":"2112605"
         },
         {
            "nome":"VARGEM GRANDE",
            "codigo_ibge":"2112704"
         },
         {
            "nome":"VIANA",
            "codigo_ibge":"2112803"
         },
         {
            "nome":"VILA NOVA DOS MARTIRIOS",
            "codigo_ibge":"2112852"
         },
         {
            "nome":"VITORIA DO MEARIM",
            "codigo_ibge":"2112902"
         },
         {
            "nome":"VITORINO FREIRE",
            "codigo_ibge":"2113009"
         },
         {
            "nome":"ZE DOCA",
            "codigo_ibge":"2114007"
         }
      ]
   },
   {
      "estado" : "GO",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ABADIA DE GOIAS",
            "codigo_ibge":"5200050"
         },
         {
            "nome":"ABADIANIA",
            "codigo_ibge":"5200100"
         },
         {
            "nome":"ACREUNA",
            "codigo_ibge":"5200134"
         },
         {
            "nome":"ADELANDIA",
            "codigo_ibge":"5200159"
         },
         {
            "nome":"AGUA FRIA DE GOIAS",
            "codigo_ibge":"5200175"
         },
         {
            "nome":"AGUA LIMPA",
            "codigo_ibge":"5200209"
         },
         {
            "nome":"AGUAS LINDAS DE GOIAS",
            "codigo_ibge":"5200258"
         },
         {
            "nome":"ALEXANIA",
            "codigo_ibge":"5200308"
         },
         {
            "nome":"ALOANDIA",
            "codigo_ibge":"5200506"
         },
         {
            "nome":"ALTO HORIZONTE",
            "codigo_ibge":"5200555"
         },
         {
            "nome":"ALTO PARAISO DE GOIAS",
            "codigo_ibge":"5200605"
         },
         {
            "nome":"ALVORADA DO NORTE",
            "codigo_ibge":"5200803"
         },
         {
            "nome":"AMARALINA",
            "codigo_ibge":"5200829"
         },
         {
            "nome":"AMERICANO DO BRASIL",
            "codigo_ibge":"5200852"
         },
         {
            "nome":"AMORINOPOLIS",
            "codigo_ibge":"5200902"
         },
         {
            "nome":"ANAPOLIS",
            "codigo_ibge":"5201108"
         },
         {
            "nome":"ANHANGUERA",
            "codigo_ibge":"5201207"
         },
         {
            "nome":"ANICUNS",
            "codigo_ibge":"5201306"
         },
         {
            "nome":"APARECIDA DE GOIANIA",
            "codigo_ibge":"5201405"
         },
         {
            "nome":"APARECIDA DO RIO DOCE",
            "codigo_ibge":"5201454"
         },
         {
            "nome":"APORE",
            "codigo_ibge":"5201504"
         },
         {
            "nome":"ARACU",
            "codigo_ibge":"5201603"
         },
         {
            "nome":"ARAGARCAS",
            "codigo_ibge":"5201702"
         },
         {
            "nome":"ARAGOIANIA",
            "codigo_ibge":"5201801"
         },
         {
            "nome":"ARAGUAPAZ",
            "codigo_ibge":"5202155"
         },
         {
            "nome":"ARENOPOLIS",
            "codigo_ibge":"5202353"
         },
         {
            "nome":"ARUANA",
            "codigo_ibge":"5202502"
         },
         {
            "nome":"AURILANDIA",
            "codigo_ibge":"5202601"
         },
         {
            "nome":"AVELINOPOLIS",
            "codigo_ibge":"5202809"
         },
         {
            "nome":"BALIZA",
            "codigo_ibge":"5203104"
         },
         {
            "nome":"BARRO ALTO",
            "codigo_ibge":"5203203"
         },
         {
            "nome":"BELA VISTA DE GOIAS",
            "codigo_ibge":"5203302"
         },
         {
            "nome":"BOM JARDIM DE GOIAS",
            "codigo_ibge":"5203401"
         },
         {
            "nome":"BOM JESUS DE GOIAS",
            "codigo_ibge":"5203500"
         },
         {
            "nome":"BONFINOPOLIS",
            "codigo_ibge":"5203559"
         },
         {
            "nome":"BONOPOLIS",
            "codigo_ibge":"5203575"
         },
         {
            "nome":"BRAZABRANTES",
            "codigo_ibge":"5203609"
         },
         {
            "nome":"BRITANIA",
            "codigo_ibge":"5203807"
         },
         {
            "nome":"BURITI ALEGRE",
            "codigo_ibge":"5203906"
         },
         {
            "nome":"BURITI DE GOIAS",
            "codigo_ibge":"5203939"
         },
         {
            "nome":"BURITINOPOLIS",
            "codigo_ibge":"5203962"
         },
         {
            "nome":"CABECEIRAS",
            "codigo_ibge":"5204003"
         },
         {
            "nome":"CACHOEIRA ALTA",
            "codigo_ibge":"5204102"
         },
         {
            "nome":"CACHOEIRA DE GOIAS",
            "codigo_ibge":"5204201"
         },
         {
            "nome":"CACHOEIRA DOURADA",
            "codigo_ibge":"5204250"
         },
         {
            "nome":"CACU",
            "codigo_ibge":"5204300"
         },
         {
            "nome":"CAIAPONIA",
            "codigo_ibge":"5204409"
         },
         {
            "nome":"CALDAS NOVAS",
            "codigo_ibge":"5204508"
         },
         {
            "nome":"CALDAZINHA",
            "codigo_ibge":"5204557"
         },
         {
            "nome":"CAMPESTRE DE GOIAS",
            "codigo_ibge":"5204607"
         },
         {
            "nome":"CAMPINACU",
            "codigo_ibge":"5204656"
         },
         {
            "nome":"CAMPINORTE",
            "codigo_ibge":"5204706"
         },
         {
            "nome":"CAMPO ALEGRE DE GOIAS",
            "codigo_ibge":"5204805"
         },
         {
            "nome":"CAMPO LIMPO DE GOIAS",
            "codigo_ibge":"5204854"
         },
         {
            "nome":"CAMPOS BELOS",
            "codigo_ibge":"5204904"
         },
         {
            "nome":"CAMPOS VERDES",
            "codigo_ibge":"5204953"
         },
         {
            "nome":"CARMO DO RIO VERDE",
            "codigo_ibge":"5205000"
         },
         {
            "nome":"CASTELANDIA",
            "codigo_ibge":"5205059"
         },
         {
            "nome":"CATALAO",
            "codigo_ibge":"5205109"
         },
         {
            "nome":"CATURAI",
            "codigo_ibge":"5205208"
         },
         {
            "nome":"CAVALCANTE",
            "codigo_ibge":"5205307"
         },
         {
            "nome":"CERES",
            "codigo_ibge":"5205406"
         },
         {
            "nome":"CEZARINA",
            "codigo_ibge":"5205455"
         },
         {
            "nome":"CHAPADAO DO CEU",
            "codigo_ibge":"5205471"
         },
         {
            "nome":"CIDADE OCIDENTAL",
            "codigo_ibge":"5205497"
         },
         {
            "nome":"COCALZINHO DE GOIAS",
            "codigo_ibge":"5205513"
         },
         {
            "nome":"COLINAS DO SUL",
            "codigo_ibge":"5205521"
         },
         {
            "nome":"CORREGO DO OURO",
            "codigo_ibge":"5205703"
         },
         {
            "nome":"CORUMBA DE GOIAS",
            "codigo_ibge":"5205802"
         },
         {
            "nome":"CORUMBAIBA",
            "codigo_ibge":"5205901"
         },
         {
            "nome":"CRISTALINA",
            "codigo_ibge":"5206206"
         },
         {
            "nome":"CRISTIANOPOLIS",
            "codigo_ibge":"5206305"
         },
         {
            "nome":"CRIXAS",
            "codigo_ibge":"5206404"
         },
         {
            "nome":"CROMINIA",
            "codigo_ibge":"5206503"
         },
         {
            "nome":"CUMARI",
            "codigo_ibge":"5206602"
         },
         {
            "nome":"DAMIANOPOLIS",
            "codigo_ibge":"5206701"
         },
         {
            "nome":"DAMOLANDIA",
            "codigo_ibge":"5206800"
         },
         {
            "nome":"DAVINOPOLIS",
            "codigo_ibge":"5206909"
         },
         {
            "nome":"DIORAMA",
            "codigo_ibge":"5207105"
         },
         {
            "nome":"DOVERLANDIA",
            "codigo_ibge":"5207253"
         },
         {
            "nome":"EDEALINA",
            "codigo_ibge":"5207352"
         },
         {
            "nome":"EDEIA",
            "codigo_ibge":"5207402"
         },
         {
            "nome":"ESTRELA DO NORTE",
            "codigo_ibge":"5207501"
         },
         {
            "nome":"FAINA",
            "codigo_ibge":"5207535"
         },
         {
            "nome":"FAZENDA NOVA",
            "codigo_ibge":"5207600"
         },
         {
            "nome":"FIRMINOPOLIS",
            "codigo_ibge":"5207808"
         },
         {
            "nome":"FLORES DE GOIAS",
            "codigo_ibge":"5207907"
         },
         {
            "nome":"FORMOSA",
            "codigo_ibge":"5208004"
         },
         {
            "nome":"FORMOSO",
            "codigo_ibge":"5208103"
         },
         {
            "nome":"GAMELEIRA DE GOIAS",
            "codigo_ibge":"5208152"
         },
         {
            "nome":"DIVINOPOLIS DE GOIAS",
            "codigo_ibge":"5208301"
         },
         {
            "nome":"GOIANAPOLIS",
            "codigo_ibge":"5208400"
         },
         {
            "nome":"GOIANDIRA",
            "codigo_ibge":"5208509"
         },
         {
            "nome":"GOIANESIA",
            "codigo_ibge":"5208608"
         },
         {
            "nome":"GOIANIA",
            "codigo_ibge":"5208707"
         },
         {
            "nome":"GOIANIRA",
            "codigo_ibge":"5208806"
         },
         {
            "nome":"GOIAS",
            "codigo_ibge":"5208905"
         },
         {
            "nome":"GOIATUBA",
            "codigo_ibge":"5209101"
         },
         {
            "nome":"GOUVELANDIA",
            "codigo_ibge":"5209150"
         },
         {
            "nome":"GUAPO",
            "codigo_ibge":"5209200"
         },
         {
            "nome":"GUARAITA",
            "codigo_ibge":"5209291"
         },
         {
            "nome":"GUARANI DE GOIAS",
            "codigo_ibge":"5209408"
         },
         {
            "nome":"GUARINOS",
            "codigo_ibge":"5209457"
         },
         {
            "nome":"HEITORAI",
            "codigo_ibge":"5209606"
         },
         {
            "nome":"HIDROLANDIA",
            "codigo_ibge":"5209705"
         },
         {
            "nome":"HIDROLINA",
            "codigo_ibge":"5209804"
         },
         {
            "nome":"IACIARA",
            "codigo_ibge":"5209903"
         },
         {
            "nome":"INACIOLANDIA",
            "codigo_ibge":"5209937"
         },
         {
            "nome":"INDIARA",
            "codigo_ibge":"5209952"
         },
         {
            "nome":"INHUMAS",
            "codigo_ibge":"5210000"
         },
         {
            "nome":"IPAMERI",
            "codigo_ibge":"5210109"
         },
         {
            "nome":"IPIRANGA DE GOIAS",
            "codigo_ibge":"5210158"
         },
         {
            "nome":"IPORA",
            "codigo_ibge":"5210208"
         },
         {
            "nome":"ISRAELANDIA",
            "codigo_ibge":"5210307"
         },
         {
            "nome":"ITABERAI",
            "codigo_ibge":"5210406"
         },
         {
            "nome":"ITAGUARI",
            "codigo_ibge":"5210562"
         },
         {
            "nome":"ITAGUARU",
            "codigo_ibge":"5210604"
         },
         {
            "nome":"ITAJA",
            "codigo_ibge":"5210802"
         },
         {
            "nome":"ITAPACI",
            "codigo_ibge":"5210901"
         },
         {
            "nome":"ITAPIRAPUA",
            "codigo_ibge":"5211008"
         },
         {
            "nome":"ITAPURANGA",
            "codigo_ibge":"5211206"
         },
         {
            "nome":"ITARUMA",
            "codigo_ibge":"5211305"
         },
         {
            "nome":"ITAUCU",
            "codigo_ibge":"5211404"
         },
         {
            "nome":"ITUMBIARA",
            "codigo_ibge":"5211503"
         },
         {
            "nome":"IVOLANDIA",
            "codigo_ibge":"5211602"
         },
         {
            "nome":"JANDAIA",
            "codigo_ibge":"5211701"
         },
         {
            "nome":"JARAGUA",
            "codigo_ibge":"5211800"
         },
         {
            "nome":"JATAI",
            "codigo_ibge":"5211909"
         },
         {
            "nome":"JAUPACI",
            "codigo_ibge":"5212006"
         },
         {
            "nome":"JESUPOLIS",
            "codigo_ibge":"5212055"
         },
         {
            "nome":"JOVIANIA",
            "codigo_ibge":"5212105"
         },
         {
            "nome":"JUSSARA",
            "codigo_ibge":"5212204"
         },
         {
            "nome":"LAGOA SANTA",
            "codigo_ibge":"5212253"
         },
         {
            "nome":"LEOPOLDO DE BULHOES",
            "codigo_ibge":"5212303"
         },
         {
            "nome":"LUZIANIA",
            "codigo_ibge":"5212501"
         },
         {
            "nome":"MAIRIPOTABA",
            "codigo_ibge":"5212600"
         },
         {
            "nome":"MAMBAI",
            "codigo_ibge":"5212709"
         },
         {
            "nome":"MARA ROSA",
            "codigo_ibge":"5212808"
         },
         {
            "nome":"MARZAGAO",
            "codigo_ibge":"5212907"
         },
         {
            "nome":"MATRINCHA",
            "codigo_ibge":"5212956"
         },
         {
            "nome":"MAURILANDIA",
            "codigo_ibge":"5213004"
         },
         {
            "nome":"MIMOSO DE GOIAS",
            "codigo_ibge":"5213053"
         },
         {
            "nome":"MINACU",
            "codigo_ibge":"5213087"
         },
         {
            "nome":"MINEIROS",
            "codigo_ibge":"5213103"
         },
         {
            "nome":"MOIPORA",
            "codigo_ibge":"5213400"
         },
         {
            "nome":"MONTE ALEGRE DE GOIAS",
            "codigo_ibge":"5213509"
         },
         {
            "nome":"MONTES CLAROS DE GOIAS",
            "codigo_ibge":"5213707"
         },
         {
            "nome":"MONTIVIDIU",
            "codigo_ibge":"5213756"
         },
         {
            "nome":"MONTIVIDIU DO NORTE",
            "codigo_ibge":"5213772"
         },
         {
            "nome":"MORRINHOS",
            "codigo_ibge":"5213806"
         },
         {
            "nome":"MORRO AGUDO DE GOIAS",
            "codigo_ibge":"5213855"
         },
         {
            "nome":"MOSSAMEDES",
            "codigo_ibge":"5213905"
         },
         {
            "nome":"MOZARLANDIA",
            "codigo_ibge":"5214002"
         },
         {
            "nome":"MUNDO NOVO",
            "codigo_ibge":"5214051"
         },
         {
            "nome":"MUTUNOPOLIS",
            "codigo_ibge":"5214101"
         },
         {
            "nome":"NAZARIO",
            "codigo_ibge":"5214408"
         },
         {
            "nome":"NEROPOLIS",
            "codigo_ibge":"5214507"
         },
         {
            "nome":"NIQUELANDIA",
            "codigo_ibge":"5214606"
         },
         {
            "nome":"NOVA AMERICA",
            "codigo_ibge":"5214705"
         },
         {
            "nome":"NOVA AURORA",
            "codigo_ibge":"5214804"
         },
         {
            "nome":"NOVA CRIXAS",
            "codigo_ibge":"5214838"
         },
         {
            "nome":"NOVA GLORIA",
            "codigo_ibge":"5214861"
         },
         {
            "nome":"NOVA IGUACU DE GOIAS",
            "codigo_ibge":"5214879"
         },
         {
            "nome":"NOVA ROMA",
            "codigo_ibge":"5214903"
         },
         {
            "nome":"NOVA VENEZA",
            "codigo_ibge":"5215009"
         },
         {
            "nome":"NOVO BRASIL",
            "codigo_ibge":"5215207"
         },
         {
            "nome":"NOVO GAMA",
            "codigo_ibge":"5215231"
         },
         {
            "nome":"NOVO PLANALTO",
            "codigo_ibge":"5215256"
         },
         {
            "nome":"ORIZONA",
            "codigo_ibge":"5215306"
         },
         {
            "nome":"OURO VERDE DE GOIAS",
            "codigo_ibge":"5215405"
         },
         {
            "nome":"OUVIDOR",
            "codigo_ibge":"5215504"
         },
         {
            "nome":"PADRE BERNARDO",
            "codigo_ibge":"5215603"
         },
         {
            "nome":"PALESTINA DE GOIAS",
            "codigo_ibge":"5215652"
         },
         {
            "nome":"PALMEIRAS DE GOIAS",
            "codigo_ibge":"5215702"
         },
         {
            "nome":"PALMELO",
            "codigo_ibge":"5215801"
         },
         {
            "nome":"PALMINOPOLIS",
            "codigo_ibge":"5215900"
         },
         {
            "nome":"PANAMA",
            "codigo_ibge":"5216007"
         },
         {
            "nome":"PARANAIGUARA",
            "codigo_ibge":"5216304"
         },
         {
            "nome":"PARAUNA",
            "codigo_ibge":"5216403"
         },
         {
            "nome":"PEROLANDIA",
            "codigo_ibge":"5216452"
         },
         {
            "nome":"PETROLINA DE GOIAS",
            "codigo_ibge":"5216809"
         },
         {
            "nome":"PILAR DE GOIAS",
            "codigo_ibge":"5216908"
         },
         {
            "nome":"PIRACANJUBA",
            "codigo_ibge":"5217104"
         },
         {
            "nome":"PIRANHAS",
            "codigo_ibge":"5217203"
         },
         {
            "nome":"PIRENOPOLIS",
            "codigo_ibge":"5217302"
         },
         {
            "nome":"PIRES DO RIO",
            "codigo_ibge":"5217401"
         },
         {
            "nome":"PLANALTINA",
            "codigo_ibge":"5217609"
         },
         {
            "nome":"PONTALINA",
            "codigo_ibge":"5217708"
         },
         {
            "nome":"PORANGATU",
            "codigo_ibge":"5218003"
         },
         {
            "nome":"PORTEIRAO",
            "codigo_ibge":"5218052"
         },
         {
            "nome":"PORTELANDIA",
            "codigo_ibge":"5218102"
         },
         {
            "nome":"POSSE",
            "codigo_ibge":"5218300"
         },
         {
            "nome":"PROFESSOR JAMIL",
            "codigo_ibge":"5218391"
         },
         {
            "nome":"QUIRINOPOLIS",
            "codigo_ibge":"5218508"
         },
         {
            "nome":"RIALMA",
            "codigo_ibge":"5218607"
         },
         {
            "nome":"RIANAPOLIS",
            "codigo_ibge":"5218706"
         },
         {
            "nome":"RIO QUENTE",
            "codigo_ibge":"5218789"
         },
         {
            "nome":"RIO VERDE",
            "codigo_ibge":"5218805"
         },
         {
            "nome":"RUBIATABA",
            "codigo_ibge":"5218904"
         },
         {
            "nome":"SANCLERLANDIA",
            "codigo_ibge":"5219001"
         },
         {
            "nome":"SANTA BARBARA DE GOIAS",
            "codigo_ibge":"5219100"
         },
         {
            "nome":"SANTA CRUZ DE GOIAS",
            "codigo_ibge":"5219209"
         },
         {
            "nome":"SANTA FE DE GOIAS",
            "codigo_ibge":"5219258"
         },
         {
            "nome":"SANTA HELENA DE GOIAS",
            "codigo_ibge":"5219308"
         },
         {
            "nome":"SANTA ISABEL",
            "codigo_ibge":"5219357"
         },
         {
            "nome":"SANTA RITA DO ARAGUAIA",
            "codigo_ibge":"5219407"
         },
         {
            "nome":"SANTA RITA DO NOVO DESTINO",
            "codigo_ibge":"5219456"
         },
         {
            "nome":"SANTA ROSA DE GOIAS",
            "codigo_ibge":"5219506"
         },
         {
            "nome":"SANTA TEREZA DE GOIAS",
            "codigo_ibge":"5219605"
         },
         {
            "nome":"SANTA TEREZINHA DE GOIAS",
            "codigo_ibge":"5219704"
         },
         {
            "nome":"SANTO ANTONIO DA BARRA",
            "codigo_ibge":"5219712"
         },
         {
            "nome":"SANTO ANTONIO DE GOIAS",
            "codigo_ibge":"5219738"
         },
         {
            "nome":"SANTO ANTONIO DO DESCOBERTO",
            "codigo_ibge":"5219753"
         },
         {
            "nome":"SAO DOMINGOS",
            "codigo_ibge":"5219803"
         },
         {
            "nome":"SAO FRANCISCO DE GOIAS",
            "codigo_ibge":"5219902"
         },
         {
            "nome":"SAO JOAO D ALIANCA",
            "codigo_ibge":"5220009"
         },
         {
            "nome":"SAO JOAO DA PARAUNA",
            "codigo_ibge":"5220058"
         },
         {
            "nome":"SAO LUIS DE MONTES BELOS",
            "codigo_ibge":"5220108"
         },
         {
            "nome":"SAO LUIZ DO NORTE",
            "codigo_ibge":"5220157"
         },
         {
            "nome":"SAO MIGUEL DO ARAGUAIA",
            "codigo_ibge":"5220207"
         },
         {
            "nome":"SAO MIGUEL DO PASSA QUATRO",
            "codigo_ibge":"5220264"
         },
         {
            "nome":"SAO PATRICIO",
            "codigo_ibge":"5220280"
         },
         {
            "nome":"SAO SIMAO",
            "codigo_ibge":"5220405"
         },
         {
            "nome":"SENADOR CANEDO",
            "codigo_ibge":"5220454"
         },
         {
            "nome":"SERRANOPOLIS",
            "codigo_ibge":"5220504"
         },
         {
            "nome":"SILVANIA",
            "codigo_ibge":"5220603"
         },
         {
            "nome":"SIMOLANDIA",
            "codigo_ibge":"5220686"
         },
         {
            "nome":"SITIO D ABADIA",
            "codigo_ibge":"5220702"
         },
         {
            "nome":"TAQUARAL DE GOIAS",
            "codigo_ibge":"5221007"
         },
         {
            "nome":"TERESINA DE GOIAS",
            "codigo_ibge":"5221080"
         },
         {
            "nome":"TEREZOPOLIS DE GOIAS",
            "codigo_ibge":"5221197"
         },
         {
            "nome":"TRES RANCHOS",
            "codigo_ibge":"5221304"
         },
         {
            "nome":"TRINDADE",
            "codigo_ibge":"5221403"
         },
         {
            "nome":"TROMBAS",
            "codigo_ibge":"5221452"
         },
         {
            "nome":"TURVANIA",
            "codigo_ibge":"5221502"
         },
         {
            "nome":"TURVELANDIA",
            "codigo_ibge":"5221551"
         },
         {
            "nome":"UIRAPURU",
            "codigo_ibge":"5221577"
         },
         {
            "nome":"URUACU",
            "codigo_ibge":"5221601"
         },
         {
            "nome":"URUANA",
            "codigo_ibge":"5221700"
         },
         {
            "nome":"URUTAI",
            "codigo_ibge":"5221809"
         },
         {
            "nome":"VALPARAISO DE GOIAS",
            "codigo_ibge":"5221858"
         },
         {
            "nome":"VARJAO",
            "codigo_ibge":"5221908"
         },
         {
            "nome":"VIANOPOLIS",
            "codigo_ibge":"5222005"
         },
         {
            "nome":"VICENTINOPOLIS",
            "codigo_ibge":"5222054"
         },
         {
            "nome":"VILA BOA",
            "codigo_ibge":"5222203"
         },
         {
            "nome":"VILA PROPICIO",
            "codigo_ibge":"5222302"
         }
      ]
   },
   {
      "estado" : "ES",
      "cidades" :[
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"AFONSO CLÁUDIO",
            "codigo_ibge":"3200102"
         },
         {
            "nome":"ÁGUA DOCE DO NORTE",
            "codigo_ibge":"3200136"
         },
         {
            "nome":"ÁGUIA BRANCA",
            "codigo_ibge":"3200169"
         },
         {
            "nome":"ALEGRE (ESPÍRITO SANTO)",
            "codigo_ibge":"3200201"
         },
         {
            "nome":"ALFREDO CHAVES (ESPÍRITO SANTO)",
            "codigo_ibge":"3200300"
         },
         {
            "nome":"ALTO RIO NOVO",
            "codigo_ibge":"3200359"
         },
         {
            "nome":"ANCHIETA (ESPÍRITO SANTO)",
            "codigo_ibge":"3200409"
         },
         {
            "nome":"APIACÁ",
            "codigo_ibge":"3200508"
         },
         {
            "nome":"ARACRUZ",
            "codigo_ibge":"3200607"
         },
         {
            "nome":"ATÍLIO VIVÁCQUA (ESPÍRITO SANTO)",
            "codigo_ibge":"3200706"
         },
         {
            "nome":"BAIXO GUANDU",
            "codigo_ibge":"3200805"
         },
         {
            "nome":"BARRA DE SÃO FRANCISCO",
            "codigo_ibge":"3200904"
         },
         {
            "nome":"BOA ESPERANÇA (ESPÍRITO SANTO)",
            "codigo_ibge":"3201001"
         },
         {
            "nome":"BOM JESUS DO NORTE",
            "codigo_ibge":"3201100"
         },
         {
            "nome":"BREJETUBA",
            "codigo_ibge":"3201159"
         },
         {
            "nome":"CACHOEIRO DE ITAPEMIRIM",
            "codigo_ibge":"3201209"
         },
         {
            "nome":"CARIACICA",
            "codigo_ibge":"3201308"
         },
         {
            "nome":"CASTELO (ESPÍRITO SANTO)",
            "codigo_ibge":"3201407"
         },
         {
            "nome":"COLATINA",
            "codigo_ibge":"3201506"
         },
         {
            "nome":"CONCEIÇÃO DA BARRA",
            "codigo_ibge":"3201605"
         },
         {
            "nome":"CONCEIÇÃO DO CASTELO",
            "codigo_ibge":"3201704"
         },
         {
            "nome":"DIVINO DE SÃO LOURENÇO",
            "codigo_ibge":"3201803"
         },
         {
            "nome":"DOMINGOS MARTINS",
            "codigo_ibge":"3201902"
         },
         {
            "nome":"DORES DO RIO PRETO",
            "codigo_ibge":"3202009"
         },
         {
            "nome":"ECOPORANGA",
            "codigo_ibge":"3202108"
         },
         {
            "nome":"FUNDÃO (ESPÍRITO SANTO)",
            "codigo_ibge":"3202207"
         },
         {
            "nome":"GOVERNADOR LINDENBERG",
            "codigo_ibge":"3202256"
         },
         {
            "nome":"GUAÇUÍ",
            "codigo_ibge":"3202306"
         },
         {
            "nome":"GUARAPARI",
            "codigo_ibge":"3202405"
         },
         {
            "nome":"IBATIBA",
            "codigo_ibge":"3202454"
         },
         {
            "nome":"IBIRAÇU",
            "codigo_ibge":"3202504"
         },
         {
            "nome":"IBITIRAMA",
            "codigo_ibge":"3202553"
         },
         {
            "nome":"ICONHA",
            "codigo_ibge":"3202603"
         },
         {
            "nome":"IRUPI",
            "codigo_ibge":"3202652"
         },
         {
            "nome":"ITAGUAÇU",
            "codigo_ibge":"3202702"
         },
         {
            "nome":"ITAPEMIRIM",
            "codigo_ibge":"3202801"
         },
         {
            "nome":"ITARANA",
            "codigo_ibge":"3202900"
         },
         {
            "nome":"IÚNA",
            "codigo_ibge":"3203007"
         },
         {
            "nome":"JAGUARÉ",
            "codigo_ibge":"3203056"
         },
         {
            "nome":"JERÔNIMO MONTEIRO",
            "codigo_ibge":"3203106"
         },
         {
            "nome":"JOÃO NEIVA",
            "codigo_ibge":"3203130"
         },
         {
            "nome":"LARANJA DA TERRA",
            "codigo_ibge":"3203163"
         },
         {
            "nome":"LINHARES (ESPÍRITO SANTO)",
            "codigo_ibge":"3203205"
         },
         {
            "nome":"MANTENÓPOLIS",
            "codigo_ibge":"3203304"
         },
         {
            "nome":"MARATAÍZES",
            "codigo_ibge":"3203320"
         },
         {
            "nome":"MARECHAL FLORIANO",
            "codigo_ibge":"3203346"
         },
         {
            "nome":"MARILÂNDIA",
            "codigo_ibge":"3203353"
         },
         {
            "nome":"MIMOSO DO SUL",
            "codigo_ibge":"3203403"
         },
         {
            "nome":"MONTANHA (ESPÍRITO SANTO)",
            "codigo_ibge":"3203502"
         },
         {
            "nome":"MUCURICI",
            "codigo_ibge":"3203601"
         },
         {
            "nome":"MUNIZ FREIRE",
            "codigo_ibge":"3203700"
         },
         {
            "nome":"MUQUI",
            "codigo_ibge":"3203809"
         },
         {
            "nome":"NOVA VENÉCIA",
            "codigo_ibge":"3203908"
         },
         {
            "nome":"PANCAS",
            "codigo_ibge":"3204005"
         },
         {
            "nome":"PEDRO CANÁRIO",
            "codigo_ibge":"3204054"
         },
         {
            "nome":"PINHEIROS (ESPÍRITO SANTO)",
            "codigo_ibge":"3204104"
         },
         {
            "nome":"PIÚMA",
            "codigo_ibge":"3204203"
         },
         {
            "nome":"PONTO BELO",
            "codigo_ibge":"3204252"
         },
         {
            "nome":"PRESIDENTE KENNEDY (ESPÍRITO SANTO)",
            "codigo_ibge":"3204302"
         },
         {
            "nome":"RIO BANANAL (MUNICÍPIO)",
            "codigo_ibge":"3204351"
         },
         {
            "nome":"RIO NOVO DO SUL",
            "codigo_ibge":"3204401"
         },
         {
            "nome":"SANTA LEOPOLDINA",
            "codigo_ibge":"3204500"
         },
         {
            "nome":"SANTA MARIA DE JETIBÁ",
            "codigo_ibge":"3204559"
         },
         {
            "nome":"SANTA TERESA (ESPÍRITO SANTO)",
            "codigo_ibge":"3204609"
         },
         {
            "nome":"SÃO DOMINGOS DO NORTE",
            "codigo_ibge":"3204658"
         },
         {
            "nome":"SÃO GABRIEL DA PALHA",
            "codigo_ibge":"3204708"
         },
         {
            "nome":"SÃO JOSÉ DO CALÇADO",
            "codigo_ibge":"3204807"
         },
         {
            "nome":"SÃO MATEUS (ESPÍRITO SANTO)",
            "codigo_ibge":"3204906"
         },
         {
            "nome":"SÃO ROQUE DO CANAÃ",
            "codigo_ibge":"3204955"
         },
         {
            "nome":"SERRA (ESPÍRITO SANTO)",
            "codigo_ibge":"3205002"
         },
         {
            "nome":"SOORETAMA",
            "codigo_ibge":"3205010"
         },
         {
            "nome":"VARGEM ALTA",
            "codigo_ibge":"3205036"
         },
         {
            "nome":"VENDA NOVA DO IMIGRANTE",
            "codigo_ibge":"3205069"
         },
         {
            "nome":"VIANA (ESPÍRITO SANTO)",
            "codigo_ibge":"3205101"
         },
         {
            "nome":"VILA PAVÃO",
            "codigo_ibge":"3205150"
         },
         {
            "nome":"VILA VALÉRIO",
            "codigo_ibge":"3205176"
         },
         {
            "nome":"VILA VELHA",
            "codigo_ibge":"3205200"
         },
         {
            "nome":"VITÓRIA (ESPÍRITO SANTO)",
            "codigo_ibge":"3205309"
         }
      ]
   },
   {
      "estado" : "DF",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"BRASILIA",
            "codigo_ibge":"5300108"
         }
      ]
   },
   {
      "estado" : "CE",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ABAIARA",
            "codigo_ibge":"2300101"
         },
         {
            "nome":"ACARAPE",
            "codigo_ibge":"2300150"
         },
         {
            "nome":"ACARAU",
            "codigo_ibge":"2300200"
         },
         {
            "nome":"ACOPIARA",
            "codigo_ibge":"2300309"
         },
         {
            "nome":"AIUABA",
            "codigo_ibge":"2300408"
         },
         {
            "nome":"ALCANTARAS",
            "codigo_ibge":"2300507"
         },
         {
            "nome":"ALTANEIRA",
            "codigo_ibge":"2300606"
         },
         {
            "nome":"ALTO SANTO",
            "codigo_ibge":"2300705"
         },
         {
            "nome":"AMONTADA",
            "codigo_ibge":"2300754"
         },
         {
            "nome":"ANTONINA DO NORTE",
            "codigo_ibge":"2300804"
         },
         {
            "nome":"APUIARES",
            "codigo_ibge":"2300903"
         },
         {
            "nome":"AQUIRAZ",
            "codigo_ibge":"2301000"
         },
         {
            "nome":"ARACATI",
            "codigo_ibge":"2301109"
         },
         {
            "nome":"ARACOIABA",
            "codigo_ibge":"2301208"
         },
         {
            "nome":"ARARENDA",
            "codigo_ibge":"2301257"
         },
         {
            "nome":"ARARIPE",
            "codigo_ibge":"2301307"
         },
         {
            "nome":"ARATUBA",
            "codigo_ibge":"2301406"
         },
         {
            "nome":"ARNEIROZ",
            "codigo_ibge":"2301505"
         },
         {
            "nome":"ASSARE",
            "codigo_ibge":"2301604"
         },
         {
            "nome":"AURORA",
            "codigo_ibge":"2301703"
         },
         {
            "nome":"BAIXIO",
            "codigo_ibge":"2301802"
         },
         {
            "nome":"BANABUIU",
            "codigo_ibge":"2301851"
         },
         {
            "nome":"BARBALHA",
            "codigo_ibge":"2301901"
         },
         {
            "nome":"BARREIRA",
            "codigo_ibge":"2301950"
         },
         {
            "nome":"BARRO",
            "codigo_ibge":"2302008"
         },
         {
            "nome":"BARROQUINHA",
            "codigo_ibge":"2302057"
         },
         {
            "nome":"BATURITE",
            "codigo_ibge":"2302107"
         },
         {
            "nome":"BEBERIBE",
            "codigo_ibge":"2302206"
         },
         {
            "nome":"BELA CRUZ",
            "codigo_ibge":"2302305"
         },
         {
            "nome":"BOA VIAGEM",
            "codigo_ibge":"2302404"
         },
         {
            "nome":"BREJO SANTO",
            "codigo_ibge":"2302503"
         },
         {
            "nome":"CAMOCIM",
            "codigo_ibge":"2302602"
         },
         {
            "nome":"CAMPOS SALES",
            "codigo_ibge":"2302701"
         },
         {
            "nome":"CANINDE",
            "codigo_ibge":"2302800"
         },
         {
            "nome":"CAPISTRANO",
            "codigo_ibge":"2302909"
         },
         {
            "nome":"CARIDADE",
            "codigo_ibge":"2303006"
         },
         {
            "nome":"CARIRE",
            "codigo_ibge":"2303105"
         },
         {
            "nome":"CARIRIACU",
            "codigo_ibge":"2303204"
         },
         {
            "nome":"CARIUS",
            "codigo_ibge":"2303303"
         },
         {
            "nome":"CARNAUBAL",
            "codigo_ibge":"2303402"
         },
         {
            "nome":"CASCAVEL",
            "codigo_ibge":"2303501"
         },
         {
            "nome":"CATARINA",
            "codigo_ibge":"2303600"
         },
         {
            "nome":"CATUNDA",
            "codigo_ibge":"2303659"
         },
         {
            "nome":"CAUCAIA",
            "codigo_ibge":"2303709"
         },
         {
            "nome":"CEDRO",
            "codigo_ibge":"2303808"
         },
         {
            "nome":"CHAVAL",
            "codigo_ibge":"2303907"
         },
         {
            "nome":"CHORO",
            "codigo_ibge":"2303931"
         },
         {
            "nome":"CHOROZINHO",
            "codigo_ibge":"2303956"
         },
         {
            "nome":"COREAU",
            "codigo_ibge":"2304004"
         },
         {
            "nome":"CRATEUS",
            "codigo_ibge":"2304103"
         },
         {
            "nome":"CRATO",
            "codigo_ibge":"2304202"
         },
         {
            "nome":"CROATA",
            "codigo_ibge":"2304236"
         },
         {
            "nome":"CRUZ",
            "codigo_ibge":"2304251"
         },
         {
            "nome":"DEPUTADO IRAPUAN PINHEIRO",
            "codigo_ibge":"2304269"
         },
         {
            "nome":"ERERE",
            "codigo_ibge":"2304277"
         },
         {
            "nome":"EUSEBIO",
            "codigo_ibge":"2304285"
         },
         {
            "nome":"FARIAS BRITO",
            "codigo_ibge":"2304301"
         },
         {
            "nome":"FORQUILHA",
            "codigo_ibge":"2304350"
         },
         {
            "nome":"FORTALEZA",
            "codigo_ibge":"2304400"
         },
         {
            "nome":"FORTIM",
            "codigo_ibge":"2304459"
         },
         {
            "nome":"FRECHEIRINHA",
            "codigo_ibge":"2304509"
         },
         {
            "nome":"GENERAL SAMPAIO",
            "codigo_ibge":"2304608"
         },
         {
            "nome":"GRACA",
            "codigo_ibge":"2304657"
         },
         {
            "nome":"GRANJA",
            "codigo_ibge":"2304707"
         },
         {
            "nome":"GRANJEIRO",
            "codigo_ibge":"2304806"
         },
         {
            "nome":"GROAIRAS",
            "codigo_ibge":"2304905"
         },
         {
            "nome":"GUAIUBA",
            "codigo_ibge":"2304954"
         },
         {
            "nome":"GUARACIABA DO NORTE",
            "codigo_ibge":"2305001"
         },
         {
            "nome":"GUARAMIRANGA",
            "codigo_ibge":"2305100"
         },
         {
            "nome":"HIDROLANDIA",
            "codigo_ibge":"2305209"
         },
         {
            "nome":"HORIZONTE",
            "codigo_ibge":"2305233"
         },
         {
            "nome":"IBARETAMA",
            "codigo_ibge":"2305266"
         },
         {
            "nome":"IBIAPINA",
            "codigo_ibge":"2305308"
         },
         {
            "nome":"IBICUITINGA",
            "codigo_ibge":"2305332"
         },
         {
            "nome":"ICAPUI",
            "codigo_ibge":"2305357"
         },
         {
            "nome":"ICO",
            "codigo_ibge":"2305407"
         },
         {
            "nome":"IGUATU",
            "codigo_ibge":"2305506"
         },
         {
            "nome":"INDEPENDENCIA",
            "codigo_ibge":"2305605"
         },
         {
            "nome":"IPAPORANGA",
            "codigo_ibge":"2305654"
         },
         {
            "nome":"IPAUMIRIM",
            "codigo_ibge":"2305704"
         },
         {
            "nome":"IPU",
            "codigo_ibge":"2305803"
         },
         {
            "nome":"IPUEIRAS",
            "codigo_ibge":"2305902"
         },
         {
            "nome":"IRACEMA",
            "codigo_ibge":"2306009"
         },
         {
            "nome":"IRAUCUBA",
            "codigo_ibge":"2306108"
         },
         {
            "nome":"ITAICABA",
            "codigo_ibge":"2306207"
         },
         {
            "nome":"ITAITINGA",
            "codigo_ibge":"2306256"
         },
         {
            "nome":"ITAPAGE",
            "codigo_ibge":"2306306"
         },
         {
            "nome":"ITAPIPOCA",
            "codigo_ibge":"2306405"
         },
         {
            "nome":"ITAPIUNA",
            "codigo_ibge":"2306504"
         },
         {
            "nome":"ITAREMA",
            "codigo_ibge":"2306553"
         },
         {
            "nome":"ITATIRA",
            "codigo_ibge":"2306603"
         },
         {
            "nome":"JAGUARETAMA",
            "codigo_ibge":"2306702"
         },
         {
            "nome":"JAGUARIBARA",
            "codigo_ibge":"2306801"
         },
         {
            "nome":"JAGUARIBE",
            "codigo_ibge":"2306900"
         },
         {
            "nome":"JAGUARUANA",
            "codigo_ibge":"2307007"
         },
         {
            "nome":"JARDIM",
            "codigo_ibge":"2307106"
         },
         {
            "nome":"JATI",
            "codigo_ibge":"2307205"
         },
         {
            "nome":"JIJOCA DE JERICOACOARA",
            "codigo_ibge":"2307254"
         },
         {
            "nome":"JUAZEIRO DO NORTE",
            "codigo_ibge":"2307304"
         },
         {
            "nome":"JUCAS",
            "codigo_ibge":"2307403"
         },
         {
            "nome":"LAVRAS DA MANGABEIRA",
            "codigo_ibge":"2307502"
         },
         {
            "nome":"LIMOEIRO DO NORTE",
            "codigo_ibge":"2307601"
         },
         {
            "nome":"MADALENA",
            "codigo_ibge":"2307635"
         },
         {
            "nome":"MARACANAU",
            "codigo_ibge":"2307650"
         },
         {
            "nome":"MARANGUAPE",
            "codigo_ibge":"2307700"
         },
         {
            "nome":"MARCO",
            "codigo_ibge":"2307809"
         },
         {
            "nome":"MARTINOPOLE",
            "codigo_ibge":"2307908"
         },
         {
            "nome":"MASSAPE",
            "codigo_ibge":"2308005"
         },
         {
            "nome":"MAURITI",
            "codigo_ibge":"2308104"
         },
         {
            "nome":"MERUOCA",
            "codigo_ibge":"2308203"
         },
         {
            "nome":"MILAGRES",
            "codigo_ibge":"2308302"
         },
         {
            "nome":"MILHA",
            "codigo_ibge":"2308351"
         },
         {
            "nome":"MIRAIMA",
            "codigo_ibge":"2308377"
         },
         {
            "nome":"MISSAO VELHA",
            "codigo_ibge":"2308401"
         },
         {
            "nome":"MOMBACA",
            "codigo_ibge":"2308500"
         },
         {
            "nome":"MONSENHOR TABOSA",
            "codigo_ibge":"2308609"
         },
         {
            "nome":"MORADA NOVA",
            "codigo_ibge":"2308708"
         },
         {
            "nome":"MORAUJO",
            "codigo_ibge":"2308807"
         },
         {
            "nome":"MORRINHOS",
            "codigo_ibge":"2308906"
         },
         {
            "nome":"MUCAMBO",
            "codigo_ibge":"2309003"
         },
         {
            "nome":"MULUNGU",
            "codigo_ibge":"2309102"
         },
         {
            "nome":"NOVA OLINDA",
            "codigo_ibge":"2309201"
         },
         {
            "nome":"NOVA RUSSAS",
            "codigo_ibge":"2309300"
         },
         {
            "nome":"NOVO ORIENTE",
            "codigo_ibge":"2309409"
         },
         {
            "nome":"OCARA",
            "codigo_ibge":"2309458"
         },
         {
            "nome":"OROS",
            "codigo_ibge":"2309508"
         },
         {
            "nome":"PACAJUS",
            "codigo_ibge":"2309607"
         },
         {
            "nome":"PACATUBA",
            "codigo_ibge":"2309706"
         },
         {
            "nome":"PACOTI",
            "codigo_ibge":"2309805"
         },
         {
            "nome":"PACUJA",
            "codigo_ibge":"2309904"
         },
         {
            "nome":"PALHANO",
            "codigo_ibge":"2310001"
         },
         {
            "nome":"PALMACIA",
            "codigo_ibge":"2310100"
         },
         {
            "nome":"PARACURU",
            "codigo_ibge":"2310209"
         },
         {
            "nome":"PARAIPABA",
            "codigo_ibge":"2310258"
         },
         {
            "nome":"PARAMBU",
            "codigo_ibge":"2310308"
         },
         {
            "nome":"PARAMOTI",
            "codigo_ibge":"2310407"
         },
         {
            "nome":"PEDRA BRANCA",
            "codigo_ibge":"2310506"
         },
         {
            "nome":"PENAFORTE",
            "codigo_ibge":"2310605"
         },
         {
            "nome":"PENTECOSTE",
            "codigo_ibge":"2310704"
         },
         {
            "nome":"PEREIRO",
            "codigo_ibge":"2310803"
         },
         {
            "nome":"PINDORETAMA",
            "codigo_ibge":"2310852"
         },
         {
            "nome":"PIQUET CARNEIRO",
            "codigo_ibge":"2310902"
         },
         {
            "nome":"PIRES FERREIRA",
            "codigo_ibge":"2310951"
         },
         {
            "nome":"PORANGA",
            "codigo_ibge":"2311009"
         },
         {
            "nome":"PORTEIRAS",
            "codigo_ibge":"2311108"
         },
         {
            "nome":"POTENGI",
            "codigo_ibge":"2311207"
         },
         {
            "nome":"POTIRETAMA",
            "codigo_ibge":"2311231"
         },
         {
            "nome":"QUITERIANOPOLIS",
            "codigo_ibge":"2311264"
         },
         {
            "nome":"QUIXADA",
            "codigo_ibge":"2311306"
         },
         {
            "nome":"QUIXELO",
            "codigo_ibge":"2311355"
         },
         {
            "nome":"QUIXERAMOBIM",
            "codigo_ibge":"2311405"
         },
         {
            "nome":"QUIXERE",
            "codigo_ibge":"2311504"
         },
         {
            "nome":"REDENCAO",
            "codigo_ibge":"2311603"
         },
         {
            "nome":"RERIUTABA",
            "codigo_ibge":"2311702"
         },
         {
            "nome":"RUSSAS",
            "codigo_ibge":"2311801"
         },
         {
            "nome":"SABOEIRO",
            "codigo_ibge":"2311900"
         },
         {
            "nome":"SALITRE",
            "codigo_ibge":"2311959"
         },
         {
            "nome":"SANTANA DO ACARAU",
            "codigo_ibge":"2312007"
         },
         {
            "nome":"SANTANA DO CARIRI",
            "codigo_ibge":"2312106"
         },
         {
            "nome":"SANTA QUITERIA",
            "codigo_ibge":"2312205"
         },
         {
            "nome":"SAO BENEDITO",
            "codigo_ibge":"2312304"
         },
         {
            "nome":"SAO GONCALO DO AMARANTE",
            "codigo_ibge":"2312403"
         },
         {
            "nome":"SAO JOAO DO JAGUARIBE",
            "codigo_ibge":"2312502"
         },
         {
            "nome":"SAO LUIS DO CURU",
            "codigo_ibge":"2312601"
         },
         {
            "nome":"SENADOR POMPEU",
            "codigo_ibge":"2312700"
         },
         {
            "nome":"SENADOR SA",
            "codigo_ibge":"2312809"
         },
         {
            "nome":"SOBRAL",
            "codigo_ibge":"2312908"
         },
         {
            "nome":"SOLONOPOLE",
            "codigo_ibge":"2313005"
         },
         {
            "nome":"TABULEIRO DO NORTE",
            "codigo_ibge":"2313104"
         },
         {
            "nome":"TAMBORIL",
            "codigo_ibge":"2313203"
         },
         {
            "nome":"TARRAFAS",
            "codigo_ibge":"2313252"
         },
         {
            "nome":"TAUA",
            "codigo_ibge":"2313302"
         },
         {
            "nome":"TEJUCUOCA",
            "codigo_ibge":"2313351"
         },
         {
            "nome":"TIANGUA",
            "codigo_ibge":"2313401"
         },
         {
            "nome":"TRAIRI",
            "codigo_ibge":"2313500"
         },
         {
            "nome":"TURURU",
            "codigo_ibge":"2313559"
         },
         {
            "nome":"UBAJARA",
            "codigo_ibge":"2313609"
         },
         {
            "nome":"UMARI",
            "codigo_ibge":"2313708"
         },
         {
            "nome":"UMIRIM",
            "codigo_ibge":"2313757"
         },
         {
            "nome":"URUBURETAMA",
            "codigo_ibge":"2313807"
         },
         {
            "nome":"URUOCA",
            "codigo_ibge":"2313906"
         },
         {
            "nome":"VARJOTA",
            "codigo_ibge":"2313955"
         },
         {
            "nome":"VARZEA ALEGRE",
            "codigo_ibge":"2314003"
         },
         {
            "nome":"VICOSA DO CEARA",
            "codigo_ibge":"2314102"
         }
      ]
   },
   {
      "estado" : "BA",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ABAIRA",
            "codigo_ibge":"2900108"
         },
         {
            "nome":"ABARE",
            "codigo_ibge":"2900207"
         },
         {
            "nome":"ACAJUTIBA",
            "codigo_ibge":"2900306"
         },
         {
            "nome":"ADUSTINA",
            "codigo_ibge":"2900355"
         },
         {
            "nome":"AGUA FRIA",
            "codigo_ibge":"2900405"
         },
         {
            "nome":"ERICO CARDOSO",
            "codigo_ibge":"2900504"
         },
         {
            "nome":"AIQUARA",
            "codigo_ibge":"2900603"
         },
         {
            "nome":"ALAGOINHAS",
            "codigo_ibge":"2900702"
         },
         {
            "nome":"ALCOBACA",
            "codigo_ibge":"2900801"
         },
         {
            "nome":"ALMADINA",
            "codigo_ibge":"2900900"
         },
         {
            "nome":"AMARGOSA",
            "codigo_ibge":"2901007"
         },
         {
            "nome":"AMELIA RODRIGUES",
            "codigo_ibge":"2901106"
         },
         {
            "nome":"AMERICA DOURADA",
            "codigo_ibge":"2901155"
         },
         {
            "nome":"ANAGE",
            "codigo_ibge":"2901205"
         },
         {
            "nome":"ANDARAI",
            "codigo_ibge":"2901304"
         },
         {
            "nome":"ANDORINHA",
            "codigo_ibge":"2901353"
         },
         {
            "nome":"ANGICAL",
            "codigo_ibge":"2901403"
         },
         {
            "nome":"ANGUERA",
            "codigo_ibge":"2901502"
         },
         {
            "nome":"ANTAS",
            "codigo_ibge":"2901601"
         },
         {
            "nome":"ANTONIO CARDOSO",
            "codigo_ibge":"2901700"
         },
         {
            "nome":"ANTONIO GONCALVES",
            "codigo_ibge":"2901809"
         },
         {
            "nome":"APORA",
            "codigo_ibge":"2901908"
         },
         {
            "nome":"APUAREMA",
            "codigo_ibge":"2901957"
         },
         {
            "nome":"ARACATU",
            "codigo_ibge":"2902005"
         },
         {
            "nome":"ARACAS",
            "codigo_ibge":"2902054"
         },
         {
            "nome":"ARACI",
            "codigo_ibge":"2902104"
         },
         {
            "nome":"ARAMARI",
            "codigo_ibge":"2902203"
         },
         {
            "nome":"ARATACA",
            "codigo_ibge":"2902252"
         },
         {
            "nome":"ARATUIPE",
            "codigo_ibge":"2902302"
         },
         {
            "nome":"AURELINO LEAL",
            "codigo_ibge":"2902401"
         },
         {
            "nome":"BAIANOPOLIS",
            "codigo_ibge":"2902500"
         },
         {
            "nome":"BAIXA GRANDE",
            "codigo_ibge":"2902609"
         },
         {
            "nome":"BANZAE",
            "codigo_ibge":"2902658"
         },
         {
            "nome":"BARRA",
            "codigo_ibge":"2902708"
         },
         {
            "nome":"BARRA DA ESTIVA",
            "codigo_ibge":"2902807"
         },
         {
            "nome":"BARRA DO CHOCA",
            "codigo_ibge":"2902906"
         },
         {
            "nome":"BARRA DO MENDES",
            "codigo_ibge":"2903003"
         },
         {
            "nome":"BARRA DO ROCHA",
            "codigo_ibge":"2903102"
         },
         {
            "nome":"BARREIRAS",
            "codigo_ibge":"2903201"
         },
         {
            "nome":"BARRO ALTO",
            "codigo_ibge":"2903235"
         },
         {
            "nome":"BARROCAS",
            "codigo_ibge":"2903276"
         },
         {
            "nome":"BARRO PRETO",
            "codigo_ibge":"2903300"
         },
         {
            "nome":"BELMONTE",
            "codigo_ibge":"2903409"
         },
         {
            "nome":"BELO CAMPO",
            "codigo_ibge":"2903508"
         },
         {
            "nome":"BIRITINGA",
            "codigo_ibge":"2903607"
         },
         {
            "nome":"BOA NOVA",
            "codigo_ibge":"2903706"
         },
         {
            "nome":"BOA VISTA DO TUPIM",
            "codigo_ibge":"2903805"
         },
         {
            "nome":"BOM JESUS DA LAPA",
            "codigo_ibge":"2903904"
         },
         {
            "nome":"BOM JESUS DA SERRA",
            "codigo_ibge":"2903953"
         },
         {
            "nome":"BONINAL",
            "codigo_ibge":"2904001"
         },
         {
            "nome":"BONITO",
            "codigo_ibge":"2904050"
         },
         {
            "nome":"BOQUIRA",
            "codigo_ibge":"2904100"
         },
         {
            "nome":"BOTUPORA",
            "codigo_ibge":"2904209"
         },
         {
            "nome":"BREJOES",
            "codigo_ibge":"2904308"
         },
         {
            "nome":"BREJOLANDIA",
            "codigo_ibge":"2904407"
         },
         {
            "nome":"BROTAS DE MACAUBAS",
            "codigo_ibge":"2904506"
         },
         {
            "nome":"BRUMADO",
            "codigo_ibge":"2904605"
         },
         {
            "nome":"BUERAREMA",
            "codigo_ibge":"2904704"
         },
         {
            "nome":"BURITIRAMA",
            "codigo_ibge":"2904753"
         },
         {
            "nome":"CAATIBA",
            "codigo_ibge":"2904803"
         },
         {
            "nome":"CABACEIRAS DO PARAGUACU",
            "codigo_ibge":"2904852"
         },
         {
            "nome":"CACHOEIRA",
            "codigo_ibge":"2904902"
         },
         {
            "nome":"CACULE",
            "codigo_ibge":"2905008"
         },
         {
            "nome":"CAEM",
            "codigo_ibge":"2905107"
         },
         {
            "nome":"CAETANOS",
            "codigo_ibge":"2905156"
         },
         {
            "nome":"CAETITE",
            "codigo_ibge":"2905206"
         },
         {
            "nome":"CAFARNAUM",
            "codigo_ibge":"2905305"
         },
         {
            "nome":"CAIRU",
            "codigo_ibge":"2905404"
         },
         {
            "nome":"CALDEIRAO GRANDE",
            "codigo_ibge":"2905503"
         },
         {
            "nome":"CAMACAN",
            "codigo_ibge":"2905602"
         },
         {
            "nome":"CAMACARI",
            "codigo_ibge":"2905701"
         },
         {
            "nome":"CAMAMU",
            "codigo_ibge":"2905800"
         },
         {
            "nome":"CAMPO ALEGRE DE LOURDES",
            "codigo_ibge":"2905909"
         },
         {
            "nome":"CAMPO FORMOSO",
            "codigo_ibge":"2906006"
         },
         {
            "nome":"CANAPOLIS",
            "codigo_ibge":"2906105"
         },
         {
            "nome":"CANARANA",
            "codigo_ibge":"2906204"
         },
         {
            "nome":"CANAVIEIRAS",
            "codigo_ibge":"2906303"
         },
         {
            "nome":"CANDEAL",
            "codigo_ibge":"2906402"
         },
         {
            "nome":"CANDEIAS",
            "codigo_ibge":"2906501"
         },
         {
            "nome":"CANDIBA",
            "codigo_ibge":"2906600"
         },
         {
            "nome":"CANDIDO SALES",
            "codigo_ibge":"2906709"
         },
         {
            "nome":"CANSANCAO",
            "codigo_ibge":"2906808"
         },
         {
            "nome":"CANUDOS",
            "codigo_ibge":"2906824"
         },
         {
            "nome":"CAPELA DO ALTO ALEGRE",
            "codigo_ibge":"2906857"
         },
         {
            "nome":"CAPIM GROSSO",
            "codigo_ibge":"2906873"
         },
         {
            "nome":"CARAIBAS",
            "codigo_ibge":"2906899"
         },
         {
            "nome":"CARAVELAS",
            "codigo_ibge":"2906907"
         },
         {
            "nome":"CARDEAL DA SILVA",
            "codigo_ibge":"2907004"
         },
         {
            "nome":"CARINHANHA",
            "codigo_ibge":"2907103"
         },
         {
            "nome":"CASA NOVA",
            "codigo_ibge":"2907202"
         },
         {
            "nome":"CASTRO ALVES",
            "codigo_ibge":"2907301"
         },
         {
            "nome":"CATOLANDIA",
            "codigo_ibge":"2907400"
         },
         {
            "nome":"CATU",
            "codigo_ibge":"2907509"
         },
         {
            "nome":"CATURAMA",
            "codigo_ibge":"2907558"
         },
         {
            "nome":"CENTRAL",
            "codigo_ibge":"2907608"
         },
         {
            "nome":"CHORROCHO",
            "codigo_ibge":"2907707"
         },
         {
            "nome":"CICERO DANTAS",
            "codigo_ibge":"2907806"
         },
         {
            "nome":"CIPO",
            "codigo_ibge":"2907905"
         },
         {
            "nome":"COARACI",
            "codigo_ibge":"2908002"
         },
         {
            "nome":"COCOS",
            "codigo_ibge":"2908101"
         },
         {
            "nome":"CONCEICAO DA FEIRA",
            "codigo_ibge":"2908200"
         },
         {
            "nome":"CONCEICAO DO ALMEIDA",
            "codigo_ibge":"2908309"
         },
         {
            "nome":"CONCEICAO DO COITE",
            "codigo_ibge":"2908408"
         },
         {
            "nome":"CONCEICAO DO JACUIPE",
            "codigo_ibge":"2908507"
         },
         {
            "nome":"CONDE",
            "codigo_ibge":"2908606"
         },
         {
            "nome":"CONDEUBA",
            "codigo_ibge":"2908705"
         },
         {
            "nome":"CONTENDAS DO SINCORA",
            "codigo_ibge":"2908804"
         },
         {
            "nome":"CORACAO DE MARIA",
            "codigo_ibge":"2908903"
         },
         {
            "nome":"CORDEIROS",
            "codigo_ibge":"2909000"
         },
         {
            "nome":"CORIBE",
            "codigo_ibge":"2909109"
         },
         {
            "nome":"CORONEL JOAO SA",
            "codigo_ibge":"2909208"
         },
         {
            "nome":"CORRENTINA",
            "codigo_ibge":"2909307"
         },
         {
            "nome":"COTEGIPE",
            "codigo_ibge":"2909406"
         },
         {
            "nome":"CRAVOLANDIA",
            "codigo_ibge":"2909505"
         },
         {
            "nome":"CRISOPOLIS",
            "codigo_ibge":"2909604"
         },
         {
            "nome":"CRISTOPOLIS",
            "codigo_ibge":"2909703"
         },
         {
            "nome":"CRUZ DAS ALMAS",
            "codigo_ibge":"2909802"
         },
         {
            "nome":"CURACA",
            "codigo_ibge":"2909901"
         },
         {
            "nome":"DARIO MEIRA",
            "codigo_ibge":"2910008"
         },
         {
            "nome":"DIAS D AVILA",
            "codigo_ibge":"2910057"
         },
         {
            "nome":"DOM BASILIO",
            "codigo_ibge":"2910107"
         },
         {
            "nome":"DOM MACEDO COSTA",
            "codigo_ibge":"2910206"
         },
         {
            "nome":"ELISIO MEDRADO",
            "codigo_ibge":"2910305"
         },
         {
            "nome":"ENCRUZILHADA",
            "codigo_ibge":"2910404"
         },
         {
            "nome":"ENTRE RIOS",
            "codigo_ibge":"2910503"
         },
         {
            "nome":"ESPLANADA",
            "codigo_ibge":"2910602"
         },
         {
            "nome":"EUCLIDES DA CUNHA",
            "codigo_ibge":"2910701"
         },
         {
            "nome":"EUNAPOLIS",
            "codigo_ibge":"2910727"
         },
         {
            "nome":"FATIMA",
            "codigo_ibge":"2910750"
         },
         {
            "nome":"FEIRA DA MATA",
            "codigo_ibge":"2910776"
         },
         {
            "nome":"FEIRA DE SANTANA",
            "codigo_ibge":"2910800"
         },
         {
            "nome":"FILADELFIA",
            "codigo_ibge":"2910859"
         },
         {
            "nome":"FIRMINO ALVES",
            "codigo_ibge":"2910909"
         },
         {
            "nome":"FLORESTA AZUL",
            "codigo_ibge":"2911006"
         },
         {
            "nome":"FORMOSA DO RIO PRETO",
            "codigo_ibge":"2911105"
         },
         {
            "nome":"GANDU",
            "codigo_ibge":"2911204"
         },
         {
            "nome":"GAVIAO",
            "codigo_ibge":"2911253"
         },
         {
            "nome":"GENTIO DO OURO",
            "codigo_ibge":"2911303"
         },
         {
            "nome":"GLORIA",
            "codigo_ibge":"2911402"
         },
         {
            "nome":"GONGOGI",
            "codigo_ibge":"2911501"
         },
         {
            "nome":"GOVERNADOR MANGABEIRA",
            "codigo_ibge":"2911600"
         },
         {
            "nome":"GUAJERU",
            "codigo_ibge":"2911659"
         },
         {
            "nome":"GUANAMBI",
            "codigo_ibge":"2911709"
         },
         {
            "nome":"GUARATINGA",
            "codigo_ibge":"2911808"
         },
         {
            "nome":"HELIOPOLIS",
            "codigo_ibge":"2911857"
         },
         {
            "nome":"IACU",
            "codigo_ibge":"2911907"
         },
         {
            "nome":"IBIASSUCE",
            "codigo_ibge":"2912004"
         },
         {
            "nome":"IBICARAI",
            "codigo_ibge":"2912103"
         },
         {
            "nome":"IBICOARA",
            "codigo_ibge":"2912202"
         },
         {
            "nome":"IBICUI",
            "codigo_ibge":"2912301"
         },
         {
            "nome":"IBIPEBA",
            "codigo_ibge":"2912400"
         },
         {
            "nome":"IBIPITANGA",
            "codigo_ibge":"2912509"
         },
         {
            "nome":"IBIQUERA",
            "codigo_ibge":"2912608"
         },
         {
            "nome":"IBIRAPITANGA",
            "codigo_ibge":"2912707"
         },
         {
            "nome":"IBIRAPUA",
            "codigo_ibge":"2912806"
         },
         {
            "nome":"IBIRATAIA",
            "codigo_ibge":"2912905"
         },
         {
            "nome":"IBITIARA",
            "codigo_ibge":"2913002"
         },
         {
            "nome":"IBITITA",
            "codigo_ibge":"2913101"
         },
         {
            "nome":"IBOTIRAMA",
            "codigo_ibge":"2913200"
         },
         {
            "nome":"ICHU",
            "codigo_ibge":"2913309"
         },
         {
            "nome":"IGAPORA",
            "codigo_ibge":"2913408"
         },
         {
            "nome":"IGRAPIUNA",
            "codigo_ibge":"2913457"
         },
         {
            "nome":"IGUAI",
            "codigo_ibge":"2913507"
         },
         {
            "nome":"ILHEUS",
            "codigo_ibge":"2913606"
         },
         {
            "nome":"INHAMBUPE",
            "codigo_ibge":"2913705"
         },
         {
            "nome":"IPECAETA",
            "codigo_ibge":"2913804"
         },
         {
            "nome":"IPIAU",
            "codigo_ibge":"2913903"
         },
         {
            "nome":"IPIRA",
            "codigo_ibge":"2914000"
         },
         {
            "nome":"IPUPIARA",
            "codigo_ibge":"2914109"
         },
         {
            "nome":"IRAJUBA",
            "codigo_ibge":"2914208"
         },
         {
            "nome":"IRAMAIA",
            "codigo_ibge":"2914307"
         },
         {
            "nome":"IRAQUARA",
            "codigo_ibge":"2914406"
         },
         {
            "nome":"IRARA",
            "codigo_ibge":"2914505"
         },
         {
            "nome":"IRECE",
            "codigo_ibge":"2914604"
         },
         {
            "nome":"ITABELA",
            "codigo_ibge":"2914653"
         },
         {
            "nome":"ITABERABA",
            "codigo_ibge":"2914703"
         },
         {
            "nome":"ITABUNA",
            "codigo_ibge":"2914802"
         },
         {
            "nome":"ITACARE",
            "codigo_ibge":"2914901"
         },
         {
            "nome":"ITAETE",
            "codigo_ibge":"2915007"
         },
         {
            "nome":"ITAGI",
            "codigo_ibge":"2915106"
         },
         {
            "nome":"ITAGIBA",
            "codigo_ibge":"2915205"
         },
         {
            "nome":"ITAGIMIRIM",
            "codigo_ibge":"2915304"
         },
         {
            "nome":"ITAGUACU DA BAHIA",
            "codigo_ibge":"2915353"
         },
         {
            "nome":"ITAJU DO COLONIA",
            "codigo_ibge":"2915403"
         },
         {
            "nome":"ITAJUIPE",
            "codigo_ibge":"2915502"
         },
         {
            "nome":"ITAMARAJU",
            "codigo_ibge":"2915601"
         },
         {
            "nome":"ITAMARI",
            "codigo_ibge":"2915700"
         },
         {
            "nome":"ITAMBE",
            "codigo_ibge":"2915809"
         },
         {
            "nome":"ITANAGRA",
            "codigo_ibge":"2915908"
         },
         {
            "nome":"ITANHEM",
            "codigo_ibge":"2916005"
         },
         {
            "nome":"ITAPARICA",
            "codigo_ibge":"2916104"
         },
         {
            "nome":"ITAPE",
            "codigo_ibge":"2916203"
         },
         {
            "nome":"ITAPEBI",
            "codigo_ibge":"2916302"
         },
         {
            "nome":"ITAPETINGA",
            "codigo_ibge":"2916401"
         },
         {
            "nome":"ITAPICURU",
            "codigo_ibge":"2916500"
         },
         {
            "nome":"ITAPITANGA",
            "codigo_ibge":"2916609"
         },
         {
            "nome":"ITAQUARA",
            "codigo_ibge":"2916708"
         },
         {
            "nome":"ITARANTIM",
            "codigo_ibge":"2916807"
         },
         {
            "nome":"ITATIM",
            "codigo_ibge":"2916856"
         },
         {
            "nome":"ITIRUCU",
            "codigo_ibge":"2916906"
         },
         {
            "nome":"ITIUBA",
            "codigo_ibge":"2917003"
         },
         {
            "nome":"ITORORO",
            "codigo_ibge":"2917102"
         },
         {
            "nome":"ITUACU",
            "codigo_ibge":"2917201"
         },
         {
            "nome":"ITUBERA",
            "codigo_ibge":"2917300"
         },
         {
            "nome":"IUIU",
            "codigo_ibge":"2917334"
         },
         {
            "nome":"JABORANDI",
            "codigo_ibge":"2917359"
         },
         {
            "nome":"JACARACI",
            "codigo_ibge":"2917409"
         },
         {
            "nome":"JACOBINA",
            "codigo_ibge":"2917508"
         },
         {
            "nome":"JAGUAQUARA",
            "codigo_ibge":"2917607"
         },
         {
            "nome":"JAGUARARI",
            "codigo_ibge":"2917706"
         },
         {
            "nome":"JAGUARIPE",
            "codigo_ibge":"2917805"
         },
         {
            "nome":"JANDAIRA",
            "codigo_ibge":"2917904"
         },
         {
            "nome":"JEQUIE",
            "codigo_ibge":"2918001"
         },
         {
            "nome":"JEREMOABO",
            "codigo_ibge":"2918100"
         },
         {
            "nome":"JIQUIRICA",
            "codigo_ibge":"2918209"
         },
         {
            "nome":"JITAUNA",
            "codigo_ibge":"2918308"
         },
         {
            "nome":"JOAO DOURADO",
            "codigo_ibge":"2918357"
         },
         {
            "nome":"JUAZEIRO",
            "codigo_ibge":"2918407"
         },
         {
            "nome":"JUCURUCU",
            "codigo_ibge":"2918456"
         },
         {
            "nome":"JUSSARA",
            "codigo_ibge":"2918506"
         },
         {
            "nome":"JUSSARI",
            "codigo_ibge":"2918555"
         },
         {
            "nome":"JUSSIAPE",
            "codigo_ibge":"2918605"
         },
         {
            "nome":"LAFAIETE COUTINHO",
            "codigo_ibge":"2918704"
         },
         {
            "nome":"LAGOA REAL",
            "codigo_ibge":"2918753"
         },
         {
            "nome":"LAJE",
            "codigo_ibge":"2918803"
         },
         {
            "nome":"LAJEDAO",
            "codigo_ibge":"2918902"
         },
         {
            "nome":"LAJEDINHO",
            "codigo_ibge":"2919009"
         },
         {
            "nome":"LAJEDO DO TABOCAL",
            "codigo_ibge":"2919058"
         },
         {
            "nome":"LAMARAO",
            "codigo_ibge":"2919108"
         },
         {
            "nome":"LAPAO",
            "codigo_ibge":"2919157"
         },
         {
            "nome":"LAURO DE FREITAS",
            "codigo_ibge":"2919207"
         },
         {
            "nome":"LENCOIS",
            "codigo_ibge":"2919306"
         },
         {
            "nome":"LICINIO DE ALMEIDA",
            "codigo_ibge":"2919405"
         },
         {
            "nome":"LIVRAMENTO DE NOSSA SENHORA",
            "codigo_ibge":"2919504"
         },
         {
            "nome":"LUIS EDUARDO MAGALHAES",
            "codigo_ibge":"2919553"
         },
         {
            "nome":"MACAJUBA",
            "codigo_ibge":"2919603"
         },
         {
            "nome":"MACARANI",
            "codigo_ibge":"2919702"
         },
         {
            "nome":"MACAUBAS",
            "codigo_ibge":"2919801"
         },
         {
            "nome":"MACURURE",
            "codigo_ibge":"2919900"
         },
         {
            "nome":"MADRE DE DEUS",
            "codigo_ibge":"2919926"
         },
         {
            "nome":"MAETINGA",
            "codigo_ibge":"2919959"
         },
         {
            "nome":"MAIQUINIQUE",
            "codigo_ibge":"2920007"
         },
         {
            "nome":"MAIRI",
            "codigo_ibge":"2920106"
         },
         {
            "nome":"MALHADA",
            "codigo_ibge":"2920205"
         },
         {
            "nome":"MALHADA DE PEDRAS",
            "codigo_ibge":"2920304"
         },
         {
            "nome":"MANOEL VITORINO",
            "codigo_ibge":"2920403"
         },
         {
            "nome":"MANSIDAO",
            "codigo_ibge":"2920452"
         },
         {
            "nome":"MARACAS",
            "codigo_ibge":"2920502"
         },
         {
            "nome":"MARAGOGIPE",
            "codigo_ibge":"2920601"
         },
         {
            "nome":"MARAU",
            "codigo_ibge":"2920700"
         },
         {
            "nome":"MARCIONILIO SOUZA",
            "codigo_ibge":"2920809"
         },
         {
            "nome":"MASCOTE",
            "codigo_ibge":"2920908"
         },
         {
            "nome":"MATA DE SAO JOAO",
            "codigo_ibge":"2921005"
         },
         {
            "nome":"MATINA",
            "codigo_ibge":"2921054"
         },
         {
            "nome":"MEDEIROS NETO",
            "codigo_ibge":"2921104"
         },
         {
            "nome":"MIGUEL CALMON",
            "codigo_ibge":"2921203"
         },
         {
            "nome":"MILAGRES",
            "codigo_ibge":"2921302"
         },
         {
            "nome":"MIRANGABA",
            "codigo_ibge":"2921401"
         },
         {
            "nome":"MIRANTE",
            "codigo_ibge":"2921450"
         },
         {
            "nome":"MONTE SANTO",
            "codigo_ibge":"2921500"
         },
         {
            "nome":"MORPARA",
            "codigo_ibge":"2921609"
         },
         {
            "nome":"MORRO DO CHAPEU",
            "codigo_ibge":"2921708"
         },
         {
            "nome":"MORTUGABA",
            "codigo_ibge":"2921807"
         },
         {
            "nome":"MUCUGE",
            "codigo_ibge":"2921906"
         },
         {
            "nome":"MUCURI",
            "codigo_ibge":"2922003"
         },
         {
            "nome":"MULUNGU DO MORRO",
            "codigo_ibge":"2922052"
         },
         {
            "nome":"MUNDO NOVO",
            "codigo_ibge":"2922102"
         },
         {
            "nome":"MUNIZ FERREIRA",
            "codigo_ibge":"2922201"
         },
         {
            "nome":"MUQUEM DE SAO FRANCISCO",
            "codigo_ibge":"2922250"
         },
         {
            "nome":"MURITIBA",
            "codigo_ibge":"2922300"
         },
         {
            "nome":"MUTUIPE",
            "codigo_ibge":"2922409"
         },
         {
            "nome":"NAZARE",
            "codigo_ibge":"2922508"
         },
         {
            "nome":"NILO PECANHA",
            "codigo_ibge":"2922607"
         },
         {
            "nome":"NORDESTINA",
            "codigo_ibge":"2922656"
         },
         {
            "nome":"NOVA CANAA",
            "codigo_ibge":"2922706"
         },
         {
            "nome":"NOVA FATIMA",
            "codigo_ibge":"2922730"
         },
         {
            "nome":"NOVA IBIA",
            "codigo_ibge":"2922755"
         },
         {
            "nome":"NOVA ITARANA",
            "codigo_ibge":"2922805"
         },
         {
            "nome":"NOVA REDENCAO",
            "codigo_ibge":"2922854"
         },
         {
            "nome":"NOVA SOURE",
            "codigo_ibge":"2922904"
         },
         {
            "nome":"NOVA VICOSA",
            "codigo_ibge":"2923001"
         },
         {
            "nome":"NOVO HORIZONTE",
            "codigo_ibge":"2923035"
         },
         {
            "nome":"NOVO TRIUNFO",
            "codigo_ibge":"2923050"
         },
         {
            "nome":"OLINDINA",
            "codigo_ibge":"2923100"
         },
         {
            "nome":"OLIVEIRA DOS BREJINHOS",
            "codigo_ibge":"2923209"
         },
         {
            "nome":"OURICANGAS",
            "codigo_ibge":"2923308"
         },
         {
            "nome":"OUROLANDIA",
            "codigo_ibge":"2923357"
         },
         {
            "nome":"PALMAS DE MONTE ALTO",
            "codigo_ibge":"2923407"
         },
         {
            "nome":"PALMEIRAS",
            "codigo_ibge":"2923506"
         },
         {
            "nome":"PARAMIRIM",
            "codigo_ibge":"2923605"
         },
         {
            "nome":"PARATINGA",
            "codigo_ibge":"2923704"
         },
         {
            "nome":"PARIPIRANGA",
            "codigo_ibge":"2923803"
         },
         {
            "nome":"PAU BRASIL",
            "codigo_ibge":"2923902"
         },
         {
            "nome":"PAULO AFONSO",
            "codigo_ibge":"2924009"
         },
         {
            "nome":"PE DE SERRA",
            "codigo_ibge":"2924058"
         },
         {
            "nome":"PEDRAO",
            "codigo_ibge":"2924108"
         },
         {
            "nome":"PEDRO ALEXANDRE",
            "codigo_ibge":"2924207"
         },
         {
            "nome":"PIATA",
            "codigo_ibge":"2924306"
         },
         {
            "nome":"PILAO ARCADO",
            "codigo_ibge":"2924405"
         },
         {
            "nome":"PINDAI",
            "codigo_ibge":"2924504"
         },
         {
            "nome":"PINDOBACU",
            "codigo_ibge":"2924603"
         },
         {
            "nome":"PINTADAS",
            "codigo_ibge":"2924652"
         },
         {
            "nome":"PIRAI DO NORTE",
            "codigo_ibge":"2924678"
         },
         {
            "nome":"PIRIPA",
            "codigo_ibge":"2924702"
         },
         {
            "nome":"PIRITIBA",
            "codigo_ibge":"2924801"
         },
         {
            "nome":"PLANALTINO",
            "codigo_ibge":"2924900"
         },
         {
            "nome":"PLANALTO",
            "codigo_ibge":"2925006"
         },
         {
            "nome":"POCOES",
            "codigo_ibge":"2925105"
         },
         {
            "nome":"POJUCA",
            "codigo_ibge":"2925204"
         },
         {
            "nome":"PONTO NOVO",
            "codigo_ibge":"2925253"
         },
         {
            "nome":"PORTO SEGURO",
            "codigo_ibge":"2925303"
         },
         {
            "nome":"POTIRAGUA",
            "codigo_ibge":"2925402"
         },
         {
            "nome":"PRADO",
            "codigo_ibge":"2925501"
         },
         {
            "nome":"PRESIDENTE DUTRA",
            "codigo_ibge":"2925600"
         },
         {
            "nome":"PRESIDENTE JANIO QUADROS",
            "codigo_ibge":"2925709"
         },
         {
            "nome":"PRESIDENTE TANCREDO NEVES",
            "codigo_ibge":"2925758"
         },
         {
            "nome":"QUEIMADAS",
            "codigo_ibge":"2925808"
         },
         {
            "nome":"QUIJINGUE",
            "codigo_ibge":"2925907"
         },
         {
            "nome":"QUIXABEIRA",
            "codigo_ibge":"2925931"
         },
         {
            "nome":"RAFAEL JAMBEIRO",
            "codigo_ibge":"2925956"
         },
         {
            "nome":"REMANSO",
            "codigo_ibge":"2926004"
         },
         {
            "nome":"RETIROLANDIA",
            "codigo_ibge":"2926103"
         },
         {
            "nome":"RIACHAO DAS NEVES",
            "codigo_ibge":"2926202"
         },
         {
            "nome":"RIACHAO DO JACUIPE",
            "codigo_ibge":"2926301"
         },
         {
            "nome":"RIACHO DE SANTANA",
            "codigo_ibge":"2926400"
         },
         {
            "nome":"RIBEIRA DO AMPARO",
            "codigo_ibge":"2926509"
         },
         {
            "nome":"RIBEIRA DO POMBAL",
            "codigo_ibge":"2926608"
         },
         {
            "nome":"RIBEIRAO DO LARGO",
            "codigo_ibge":"2926657"
         },
         {
            "nome":"RIO DE CONTAS",
            "codigo_ibge":"2926707"
         },
         {
            "nome":"RIO DO ANTONIO",
            "codigo_ibge":"2926806"
         },
         {
            "nome":"RIO DO PIRES",
            "codigo_ibge":"2926905"
         },
         {
            "nome":"RIO REAL",
            "codigo_ibge":"2927002"
         },
         {
            "nome":"RODELAS",
            "codigo_ibge":"2927101"
         },
         {
            "nome":"RUY BARBOSA",
            "codigo_ibge":"2927200"
         },
         {
            "nome":"SALINAS DA MARGARIDA",
            "codigo_ibge":"2927309"
         },
         {
            "nome":"SALVADOR",
            "codigo_ibge":"2927408"
         },
         {
            "nome":"SANTA BARBARA",
            "codigo_ibge":"2927507"
         },
         {
            "nome":"SANTA BRIGIDA",
            "codigo_ibge":"2927606"
         },
         {
            "nome":"SANTA CRUZ CABRALIA",
            "codigo_ibge":"2927705"
         },
         {
            "nome":"SANTA CRUZ DA VITORIA",
            "codigo_ibge":"2927804"
         },
         {
            "nome":"SANTA INES",
            "codigo_ibge":"2927903"
         },
         {
            "nome":"SANTALUZ",
            "codigo_ibge":"2928000"
         },
         {
            "nome":"SANTA LUZIA",
            "codigo_ibge":"2928059"
         },
         {
            "nome":"SANTA MARIA DA VITORIA",
            "codigo_ibge":"2928109"
         },
         {
            "nome":"SANTANA",
            "codigo_ibge":"2928208"
         },
         {
            "nome":"SANTANOPOLIS",
            "codigo_ibge":"2928307"
         },
         {
            "nome":"SANTA RITA DE CASSIA",
            "codigo_ibge":"2928406"
         },
         {
            "nome":"SANTA TERESINHA",
            "codigo_ibge":"2928505"
         },
         {
            "nome":"SANTO AMARO",
            "codigo_ibge":"2928604"
         },
         {
            "nome":"SANTO ANTONIO DE JESUS",
            "codigo_ibge":"2928703"
         },
         {
            "nome":"SANTO ESTEVAO",
            "codigo_ibge":"2928802"
         },
         {
            "nome":"SAO DESIDERIO",
            "codigo_ibge":"2928901"
         },
         {
            "nome":"SAO DOMINGOS",
            "codigo_ibge":"2928950"
         },
         {
            "nome":"SAO FELIX",
            "codigo_ibge":"2929008"
         },
         {
            "nome":"SAO FELIX DO CORIBE",
            "codigo_ibge":"2929057"
         },
         {
            "nome":"SAO FELIPE",
            "codigo_ibge":"2929107"
         },
         {
            "nome":"SAO FRANCISCO DO CONDE",
            "codigo_ibge":"2929206"
         },
         {
            "nome":"SAO GABRIEL",
            "codigo_ibge":"2929255"
         },
         {
            "nome":"SAO GONCALO DOS CAMPOS",
            "codigo_ibge":"2929305"
         },
         {
            "nome":"SAO JOSE DA VITORIA",
            "codigo_ibge":"2929354"
         },
         {
            "nome":"SAO JOSE DO JACUIPE",
            "codigo_ibge":"2929370"
         },
         {
            "nome":"SAO MIGUEL DAS MATAS",
            "codigo_ibge":"2929404"
         },
         {
            "nome":"SAO SEBASTIAO DO PASSE",
            "codigo_ibge":"2929503"
         },
         {
            "nome":"SAPEACU",
            "codigo_ibge":"2929602"
         },
         {
            "nome":"SATIRO DIAS",
            "codigo_ibge":"2929701"
         },
         {
            "nome":"SAUBARA",
            "codigo_ibge":"2929750"
         },
         {
            "nome":"SAUDE",
            "codigo_ibge":"2929800"
         },
         {
            "nome":"SEABRA",
            "codigo_ibge":"2929909"
         },
         {
            "nome":"SEBASTIAO LARANJEIRAS",
            "codigo_ibge":"2930006"
         },
         {
            "nome":"SENHOR DO BONFIM",
            "codigo_ibge":"2930105"
         },
         {
            "nome":"SERRA DO RAMALHO",
            "codigo_ibge":"2930154"
         },
         {
            "nome":"SENTO SE",
            "codigo_ibge":"2930204"
         },
         {
            "nome":"SERRA DOURADA",
            "codigo_ibge":"2930303"
         },
         {
            "nome":"SERRA PRETA",
            "codigo_ibge":"2930402"
         },
         {
            "nome":"SERRINHA",
            "codigo_ibge":"2930501"
         },
         {
            "nome":"SERROLANDIA",
            "codigo_ibge":"2930600"
         },
         {
            "nome":"SIMOES FILHO",
            "codigo_ibge":"2930709"
         },
         {
            "nome":"SITIO DO MATO",
            "codigo_ibge":"2930758"
         },
         {
            "nome":"SITIO DO QUINTO",
            "codigo_ibge":"2930766"
         },
         {
            "nome":"SOBRADINHO",
            "codigo_ibge":"2930774"
         },
         {
            "nome":"SOUTO SOARES",
            "codigo_ibge":"2930808"
         },
         {
            "nome":"TABOCAS DO BREJO VELHO",
            "codigo_ibge":"2930907"
         },
         {
            "nome":"TANHACU",
            "codigo_ibge":"2931004"
         },
         {
            "nome":"TANQUE NOVO",
            "codigo_ibge":"2931053"
         },
         {
            "nome":"TANQUINHO",
            "codigo_ibge":"2931103"
         },
         {
            "nome":"TAPEROA",
            "codigo_ibge":"2931202"
         },
         {
            "nome":"TAPIRAMUTA",
            "codigo_ibge":"2931301"
         },
         {
            "nome":"TEIXEIRA DE FREITAS",
            "codigo_ibge":"2931350"
         },
         {
            "nome":"TEODORO SAMPAIO",
            "codigo_ibge":"2931400"
         },
         {
            "nome":"TEOFILANDIA",
            "codigo_ibge":"2931509"
         },
         {
            "nome":"TEOLANDIA",
            "codigo_ibge":"2931608"
         },
         {
            "nome":"TERRA NOVA",
            "codigo_ibge":"2931707"
         },
         {
            "nome":"TREMEDAL",
            "codigo_ibge":"2931806"
         },
         {
            "nome":"TUCANO",
            "codigo_ibge":"2931905"
         },
         {
            "nome":"UAUA",
            "codigo_ibge":"2932002"
         },
         {
            "nome":"UBAIRA",
            "codigo_ibge":"2932101"
         },
         {
            "nome":"UBAITABA",
            "codigo_ibge":"2932200"
         },
         {
            "nome":"UBATA",
            "codigo_ibge":"2932309"
         },
         {
            "nome":"UIBAI",
            "codigo_ibge":"2932408"
         },
         {
            "nome":"UMBURANAS",
            "codigo_ibge":"2932457"
         },
         {
            "nome":"UNA",
            "codigo_ibge":"2932507"
         },
         {
            "nome":"URANDI",
            "codigo_ibge":"2932606"
         },
         {
            "nome":"URUCUCA",
            "codigo_ibge":"2932705"
         },
         {
            "nome":"UTINGA",
            "codigo_ibge":"2932804"
         },
         {
            "nome":"VALENCA",
            "codigo_ibge":"2932903"
         },
         {
            "nome":"VALENTE",
            "codigo_ibge":"2933000"
         },
         {
            "nome":"VARZEA DA ROCA",
            "codigo_ibge":"2933059"
         },
         {
            "nome":"VARZEA DO POCO",
            "codigo_ibge":"2933109"
         },
         {
            "nome":"VARZEA NOVA",
            "codigo_ibge":"2933158"
         },
         {
            "nome":"VARZEDO",
            "codigo_ibge":"2933174"
         },
         {
            "nome":"VERA CRUZ",
            "codigo_ibge":"2933208"
         },
         {
            "nome":"VEREDA",
            "codigo_ibge":"2933257"
         },
         {
            "nome":"VITORIA DA CONQUISTA",
            "codigo_ibge":"2933307"
         },
         {
            "nome":"WAGNER",
            "codigo_ibge":"2933406"
         },
         {
            "nome":"WANDERLEY",
            "codigo_ibge":"2933455"
         },
         {
            "nome":"WENCESLAU GUIMARAES",
            "codigo_ibge":"2933505"
         },
         {
            "nome":"XIQUE-XIQUE",
            "codigo_ibge":"2933604"
         }
      ]
   },
   {
      "estado" : "AM",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ALVARAES",
            "codigo_ibge":"1300029"
         },
         {
            "nome":"AMATURA",
            "codigo_ibge":"1300060"
         },
         {
            "nome":"ANAMA",
            "codigo_ibge":"1300086"
         },
         {
            "nome":"ANORI",
            "codigo_ibge":"1300102"
         },
         {
            "nome":"APUI",
            "codigo_ibge":"1300144"
         },
         {
            "nome":"ATALAIA DO NORTE",
            "codigo_ibge":"1300201"
         },
         {
            "nome":"AUTAZES",
            "codigo_ibge":"1300300"
         },
         {
            "nome":"BARCELOS",
            "codigo_ibge":"1300409"
         },
         {
            "nome":"BARREIRINHA",
            "codigo_ibge":"1300508"
         },
         {
            "nome":"BENJAMIN CONSTANT",
            "codigo_ibge":"1300607"
         },
         {
            "nome":"BERURI",
            "codigo_ibge":"1300631"
         },
         {
            "nome":"BOA VISTA DO RAMOS",
            "codigo_ibge":"1300680"
         },
         {
            "nome":"BOCA DO ACRE",
            "codigo_ibge":"1300706"
         },
         {
            "nome":"BORBA",
            "codigo_ibge":"1300805"
         },
         {
            "nome":"CAAPIRANGA",
            "codigo_ibge":"1300839"
         },
         {
            "nome":"CANUTAMA",
            "codigo_ibge":"1300904"
         },
         {
            "nome":"CARAUARI",
            "codigo_ibge":"1301001"
         },
         {
            "nome":"CAREIRO",
            "codigo_ibge":"1301100"
         },
         {
            "nome":"CAREIRO DA VARZEA",
            "codigo_ibge":"1301159"
         },
         {
            "nome":"COARI",
            "codigo_ibge":"1301209"
         },
         {
            "nome":"CODAJAS",
            "codigo_ibge":"1301308"
         },
         {
            "nome":"EIRUNEPE",
            "codigo_ibge":"1301407"
         },
         {
            "nome":"ENVIRA",
            "codigo_ibge":"1301506"
         },
         {
            "nome":"FONTE BOA",
            "codigo_ibge":"1301605"
         },
         {
            "nome":"GUAJARA",
            "codigo_ibge":"1301654"
         },
         {
            "nome":"HUMAITA",
            "codigo_ibge":"1301704"
         },
         {
            "nome":"IPIXUNA",
            "codigo_ibge":"1301803"
         },
         {
            "nome":"IRANDUBA",
            "codigo_ibge":"1301852"
         },
         {
            "nome":"ITACOATIARA",
            "codigo_ibge":"1301902"
         },
         {
            "nome":"ITAMARATI",
            "codigo_ibge":"1301951"
         },
         {
            "nome":"ITAPIRANGA",
            "codigo_ibge":"1302009"
         },
         {
            "nome":"JAPURA",
            "codigo_ibge":"1302108"
         },
         {
            "nome":"JURUA",
            "codigo_ibge":"1302207"
         },
         {
            "nome":"JUTAI",
            "codigo_ibge":"1302306"
         },
         {
            "nome":"LABREA",
            "codigo_ibge":"1302405"
         },
         {
            "nome":"MANACAPURU",
            "codigo_ibge":"1302504"
         },
         {
            "nome":"MANAQUIRI",
            "codigo_ibge":"1302553"
         },
         {
            "nome":"MANAUS",
            "codigo_ibge":"1302603"
         },
         {
            "nome":"MANICORE",
            "codigo_ibge":"1302702"
         },
         {
            "nome":"MARAA",
            "codigo_ibge":"1302801"
         },
         {
            "nome":"MAUES",
            "codigo_ibge":"1302900"
         },
         {
            "nome":"NHAMUNDA",
            "codigo_ibge":"1303007"
         },
         {
            "nome":"NOVA OLINDA DO NORTE",
            "codigo_ibge":"1303106"
         },
         {
            "nome":"NOVO AIRAO",
            "codigo_ibge":"1303205"
         },
         {
            "nome":"NOVO ARIPUANA",
            "codigo_ibge":"1303304"
         },
         {
            "nome":"PARINTINS",
            "codigo_ibge":"1303403"
         },
         {
            "nome":"PAUINI",
            "codigo_ibge":"1303502"
         },
         {
            "nome":"PRESIDENTE FIGUEIREDO",
            "codigo_ibge":"1303536"
         },
         {
            "nome":"RIO PRETO DA EVA",
            "codigo_ibge":"1303569"
         },
         {
            "nome":"SANTA ISABEL DO RIO NEGRO",
            "codigo_ibge":"1303601"
         },
         {
            "nome":"SANTO ANTONIO DO ICA",
            "codigo_ibge":"1303700"
         },
         {
            "nome":"SAO GABRIEL DA CACHOEIRA",
            "codigo_ibge":"1303809"
         },
         {
            "nome":"SAO PAULO DE OLIVENCA",
            "codigo_ibge":"1303908"
         },
         {
            "nome":"SAO SEBASTIAO DO UATUMA",
            "codigo_ibge":"1303957"
         },
         {
            "nome":"SILVES",
            "codigo_ibge":"1304005"
         },
         {
            "nome":"TABATINGA",
            "codigo_ibge":"1304062"
         },
         {
            "nome":"TAPAUA",
            "codigo_ibge":"1304104"
         },
         {
            "nome":"TEFE",
            "codigo_ibge":"1304203"
         },
         {
            "nome":"TONANTINS",
            "codigo_ibge":"1304237"
         },
         {
            "nome":"UARINI",
            "codigo_ibge":"1304260"
         },
         {
            "nome":"URUCARA",
            "codigo_ibge":"1304302"
         },
         {
            "nome":"URUCURITUBA",
            "codigo_ibge":"1304401"
         }
      ]
   },
   {
      "estado" : "AP",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"SERRA DO NAVIO",
            "codigo_ibge":"1600055"
         },
         {
            "nome":"AMAPA",
            "codigo_ibge":"1600105"
         },
         {
            "nome":"PEDRA BRANCA DO AMAPARI",
            "codigo_ibge":"1600154"
         },
         {
            "nome":"CALCOENE",
            "codigo_ibge":"1600204"
         },
         {
            "nome":"CUTIAS",
            "codigo_ibge":"1600212"
         },
         {
            "nome":"FERREIRA GOMES",
            "codigo_ibge":"1600238"
         },
         {
            "nome":"ITAUBAL",
            "codigo_ibge":"1600253"
         },
         {
            "nome":"LARANJAL DO JARI",
            "codigo_ibge":"1600279"
         },
         {
            "nome":"MACAPA",
            "codigo_ibge":"1600303"
         },
         {
            "nome":"MAZAGAO",
            "codigo_ibge":"1600402"
         },
         {
            "nome":"OIAPOQUE",
            "codigo_ibge":"1600501"
         },
         {
            "nome":"PORTO GRANDE",
            "codigo_ibge":"1600535"
         },
         {
            "nome":"PRACUUBA",
            "codigo_ibge":"1600550"
         },
         {
            "nome":"SANTANA",
            "codigo_ibge":"1600600"
         },
         {
            "nome":"TARTARUGALZINHO",
            "codigo_ibge":"1600709"
         },
         {
            "nome":"VITORIA DO JARI",
            "codigo_ibge":"1600808"
         }
      ]
   },
   {
      "estado" : "AL",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"AGUA BRANCA",
            "codigo_ibge":"2700102"
         },
         {
            "nome":"ANADIA",
            "codigo_ibge":"2700201"
         },
         {
            "nome":"ARAPIRACA",
            "codigo_ibge":"2700300"
         },
         {
            "nome":"ATALAIA",
            "codigo_ibge":"2700409"
         },
         {
            "nome":"BARRA DE SANTO ANTONIO",
            "codigo_ibge":"2700508"
         },
         {
            "nome":"BARRA DE SAO MIGUEL",
            "codigo_ibge":"2700607"
         },
         {
            "nome":"BATALHA",
            "codigo_ibge":"2700706"
         },
         {
            "nome":"BELEM",
            "codigo_ibge":"2700805"
         },
         {
            "nome":"BELO MONTE",
            "codigo_ibge":"2700904"
         },
         {
            "nome":"BOCA DA MATA",
            "codigo_ibge":"2701001"
         },
         {
            "nome":"BRANQUINHA",
            "codigo_ibge":"2701100"
         },
         {
            "nome":"CACIMBINHAS",
            "codigo_ibge":"2701209"
         },
         {
            "nome":"CAJUEIRO",
            "codigo_ibge":"2701308"
         },
         {
            "nome":"CAMPESTRE",
            "codigo_ibge":"2701357"
         },
         {
            "nome":"CAMPO ALEGRE",
            "codigo_ibge":"2701407"
         },
         {
            "nome":"CAMPO GRANDE",
            "codigo_ibge":"2701506"
         },
         {
            "nome":"CANAPI",
            "codigo_ibge":"2701605"
         },
         {
            "nome":"CAPELA",
            "codigo_ibge":"2701704"
         },
         {
            "nome":"CARNEIROS",
            "codigo_ibge":"2701803"
         },
         {
            "nome":"CHA PRETA",
            "codigo_ibge":"2701902"
         },
         {
            "nome":"COITE DO NOIA",
            "codigo_ibge":"2702009"
         },
         {
            "nome":"COLONIA LEOPOLDINA",
            "codigo_ibge":"2702108"
         },
         {
            "nome":"COQUEIRO SECO",
            "codigo_ibge":"2702207"
         },
         {
            "nome":"CORURIPE",
            "codigo_ibge":"2702306"
         },
         {
            "nome":"CRAIBAS",
            "codigo_ibge":"2702355"
         },
         {
            "nome":"DELMIRO GOUVEIA",
            "codigo_ibge":"2702405"
         },
         {
            "nome":"DOIS RIACHOS",
            "codigo_ibge":"2702504"
         },
         {
            "nome":"ESTRELA DE ALAGOAS",
            "codigo_ibge":"2702553"
         },
         {
            "nome":"FEIRA GRANDE",
            "codigo_ibge":"2702603"
         },
         {
            "nome":"FELIZ DESERTO",
            "codigo_ibge":"2702702"
         },
         {
            "nome":"FLEXEIRAS",
            "codigo_ibge":"2702801"
         },
         {
            "nome":"GIRAU DO PONCIANO",
            "codigo_ibge":"2702900"
         },
         {
            "nome":"IBATEGUARA",
            "codigo_ibge":"2703007"
         },
         {
            "nome":"IGACI",
            "codigo_ibge":"2703106"
         },
         {
            "nome":"IGREJA NOVA",
            "codigo_ibge":"2703205"
         },
         {
            "nome":"INHAPI",
            "codigo_ibge":"2703304"
         },
         {
            "nome":"JACARE DOS HOMENS",
            "codigo_ibge":"2703403"
         },
         {
            "nome":"JACUIPE",
            "codigo_ibge":"2703502"
         },
         {
            "nome":"JAPARATINGA",
            "codigo_ibge":"2703601"
         },
         {
            "nome":"JARAMATAIA",
            "codigo_ibge":"2703700"
         },
         {
            "nome":"JEQUIA DA PRAIA",
            "codigo_ibge":"2703759"
         },
         {
            "nome":"JOAQUIM GOMES",
            "codigo_ibge":"2703809"
         },
         {
            "nome":"JUNDIA",
            "codigo_ibge":"2703908"
         },
         {
            "nome":"JUNQUEIRO",
            "codigo_ibge":"2704005"
         },
         {
            "nome":"LAGOA DA CANOA",
            "codigo_ibge":"2704104"
         },
         {
            "nome":"LIMOEIRO DE ANADIA",
            "codigo_ibge":"2704203"
         },
         {
            "nome":"MACEIO",
            "codigo_ibge":"2704302"
         },
         {
            "nome":"MAJOR ISIDORO",
            "codigo_ibge":"2704401"
         },
         {
            "nome":"MARAGOGI",
            "codigo_ibge":"2704500"
         },
         {
            "nome":"MARAVILHA",
            "codigo_ibge":"2704609"
         },
         {
            "nome":"MARECHAL DEODORO",
            "codigo_ibge":"2704708"
         },
         {
            "nome":"MARIBONDO",
            "codigo_ibge":"2704807"
         },
         {
            "nome":"MAR VERMELHO",
            "codigo_ibge":"2704906"
         },
         {
            "nome":"MATA GRANDE",
            "codigo_ibge":"2705002"
         },
         {
            "nome":"MATRIZ DE CAMARAGIBE",
            "codigo_ibge":"2705101"
         },
         {
            "nome":"MESSIAS",
            "codigo_ibge":"2705200"
         },
         {
            "nome":"MINADOR DO NEGRAO",
            "codigo_ibge":"2705309"
         },
         {
            "nome":"MONTEIROPOLIS",
            "codigo_ibge":"2705408"
         },
         {
            "nome":"MURICI",
            "codigo_ibge":"2705507"
         },
         {
            "nome":"NOVO LINO",
            "codigo_ibge":"2705606"
         },
         {
            "nome":"OLHO D AGUA DAS FLORES",
            "codigo_ibge":"2705705"
         },
         {
            "nome":"OLHO D AGUA DO CASADO",
            "codigo_ibge":"2705804"
         },
         {
            "nome":"OLHO D AGUA GRANDE",
            "codigo_ibge":"2705903"
         },
         {
            "nome":"OLIVENCA",
            "codigo_ibge":"2706000"
         },
         {
            "nome":"OURO BRANCO",
            "codigo_ibge":"2706109"
         },
         {
            "nome":"PALESTINA",
            "codigo_ibge":"2706208"
         },
         {
            "nome":"PALMEIRA DOS INDIOS",
            "codigo_ibge":"2706307"
         },
         {
            "nome":"PAO DE ACUCAR",
            "codigo_ibge":"2706406"
         },
         {
            "nome":"PARICONHA",
            "codigo_ibge":"2706422"
         },
         {
            "nome":"PARIPUEIRA",
            "codigo_ibge":"2706448"
         },
         {
            "nome":"PASSO DE CAMARAGIBE",
            "codigo_ibge":"2706505"
         },
         {
            "nome":"PAULO JACINTO",
            "codigo_ibge":"2706604"
         },
         {
            "nome":"PENEDO",
            "codigo_ibge":"2706703"
         },
         {
            "nome":"PIACABUCU",
            "codigo_ibge":"2706802"
         },
         {
            "nome":"PILAR",
            "codigo_ibge":"2706901"
         },
         {
            "nome":"PINDOBA",
            "codigo_ibge":"2707008"
         },
         {
            "nome":"PIRANHAS",
            "codigo_ibge":"2707107"
         },
         {
            "nome":"POCO DAS TRINCHEIRAS",
            "codigo_ibge":"2707206"
         },
         {
            "nome":"PORTO CALVO",
            "codigo_ibge":"2707305"
         },
         {
            "nome":"PORTO DE PEDRAS",
            "codigo_ibge":"2707404"
         },
         {
            "nome":"PORTO REAL DO COLEGIO",
            "codigo_ibge":"2707503"
         },
         {
            "nome":"QUEBRANGULO",
            "codigo_ibge":"2707602"
         },
         {
            "nome":"RIO LARGO",
            "codigo_ibge":"2707701"
         },
         {
            "nome":"ROTEIRO",
            "codigo_ibge":"2707800"
         },
         {
            "nome":"SANTA LUZIA DO NORTE",
            "codigo_ibge":"2707909"
         },
         {
            "nome":"SANTANA DO IPANEMA",
            "codigo_ibge":"2708006"
         },
         {
            "nome":"SANTANA DO MUNDAU",
            "codigo_ibge":"2708105"
         },
         {
            "nome":"SAO BRAS",
            "codigo_ibge":"2708204"
         },
         {
            "nome":"SAO JOSE DA LAJE",
            "codigo_ibge":"2708303"
         },
         {
            "nome":"SAO JOSE DA TAPERA",
            "codigo_ibge":"2708402"
         },
         {
            "nome":"SAO LUIS DO QUITUNDE",
            "codigo_ibge":"2708501"
         },
         {
            "nome":"SAO MIGUEL DOS CAMPOS",
            "codigo_ibge":"2708600"
         },
         {
            "nome":"SAO MIGUEL DOS MILAGRES",
            "codigo_ibge":"2708709"
         },
         {
            "nome":"SAO SEBASTIAO",
            "codigo_ibge":"2708808"
         },
         {
            "nome":"SATUBA",
            "codigo_ibge":"2708907"
         },
         {
            "nome":"SENADOR RUI PALMEIRA",
            "codigo_ibge":"2708956"
         },
         {
            "nome":"TANQUE D ARCA",
            "codigo_ibge":"2709004"
         },
         {
            "nome":"TAQUARANA",
            "codigo_ibge":"2709103"
         },
         {
            "nome":"TEOTONIO VILELA",
            "codigo_ibge":"2709152"
         },
         {
            "nome":"TRAIPU",
            "codigo_ibge":"2709202"
         },
         {
            "nome":"UNIAO DOS PALMARES",
            "codigo_ibge":"2709301"
         },
         {
            "nome":"VICOSA",
            "codigo_ibge":"2709400"
         }
      ]
   },
   {
      "estado" : "AC",
      "cidades" : [
         {
            "nome":"Selecione a Cidade",
            "codigo_ibge":"Selecione_a_Cidade"
         },
         {
            "nome":"ACRELANDIA",
            "codigo_ibge":"1200013"
         },
         {
            "nome":"ASSIS BRASIL",
            "codigo_ibge":"1200054"
         },
         {
            "nome":"BRASILEIA",
            "codigo_ibge":"1200104"
         },
         {
            "nome":"BUJARI",
            "codigo_ibge":"1200138"
         },
         {
            "nome":"CAPIXABA",
            "codigo_ibge":"1200179"
         },
         {
            "nome":"CRUZEIRO DO SUL",
            "codigo_ibge":"1200203"
         },
         {
            "nome":"EPITACIOLANDIA",
            "codigo_ibge":"1200252"
         },
         {
            "nome":"FEIJO",
            "codigo_ibge":"1200302"
         },
         {
            "nome":"JORDAO",
            "codigo_ibge":"1200328"
         },
         {
            "nome":"MANCIO LIMA",
            "codigo_ibge":"1200336"
         },
         {
            "nome":"MANOEL URBANO",
            "codigo_ibge":"1200344"
         },
         {
            "nome":"MARECHAL THAUMATURGO",
            "codigo_ibge":"1200351"
         },
         {
            "nome":"PLACIDO DE CASTRO",
            "codigo_ibge":"1200385"
         },
         {
            "nome":"PORTO WALTER",
            "codigo_ibge":"1200393"
         },
         {
            "nome":"RIO BRANCO",
            "codigo_ibge":"1200401"
         },
         {
            "nome":"RODRIGUES ALVES",
            "codigo_ibge":"1200427"
         },
         {
            "nome":"SANTA ROSA DO PURUS",
            "codigo_ibge":"1200435"
         },
         {
            "nome":"SENADOR GUIOMARD",
            "codigo_ibge":"1200450"
         },
         {
            "nome":"SENA MADUREIRA",
            "codigo_ibge":"1200500"
         },
         {
            "nome":"TARAUACA",
            "codigo_ibge":"1200609"
         },
         {
            "nome":"XAPURI",
            "codigo_ibge":"1200708"
         },
         {
            "nome":"PORTO ACRE",
            "codigo_ibge":"1200807"
         }
      ]
   },

{
    "estado" : "SP",
    "cidades" : [
      {
         "nome":"Selecione a Cidade",
         "codigo_ibge":"Selecione_a_Cidade"
      },
        
            {
               "nome":"ADAMANTINA",
               "codigo_ibge":"3500105"
            },
            {
               "nome":"ADOLFO",
               "codigo_ibge":"3500204"
            },
            {
               "nome":"AGUAI",
               "codigo_ibge":"3500303"
            },
            {
               "nome":"AGUAS DA PRATA",
               "codigo_ibge":"3500402"
            },
            {
               "nome":"AGUAS DE LINDOIA",
               "codigo_ibge":"3500501"
            },
            {
               "nome":"AGUAS DE SANTA BARBARA",
               "codigo_ibge":"3500550"
            },
            {
               "nome":"AGUAS DE SAO PEDRO",
               "codigo_ibge":"3500600"
            },
            {
               "nome":"AGUDOS",
               "codigo_ibge":"3500709"
            },
            {
               "nome":"ALAMBARI",
               "codigo_ibge":"3500758"
            },
            {
               "nome":"ALFREDO MARCONDES",
               "codigo_ibge":"3500808"
            },
            {
               "nome":"ALTAIR",
               "codigo_ibge":"3500907"
            },
            {
               "nome":"ALTINOPOLIS",
               "codigo_ibge":"3501004"
            },
            {
               "nome":"ALTO ALEGRE",
               "codigo_ibge":"3501103"
            },
            {
               "nome":"ALUMINIO",
               "codigo_ibge":"3501152"
            },
            {
               "nome":"ALVARES FLORENCE",
               "codigo_ibge":"3501202"
            },
            {
               "nome":"ALVARES MACHADO",
               "codigo_ibge":"3501301"
            },
            {
               "nome":"ALVARO DE CARVALHO",
               "codigo_ibge":"3501400"
            },
            {
               "nome":"ALVINLANDIA",
               "codigo_ibge":"3501509"
            },
            {
               "nome":"AMERICANA",
               "codigo_ibge":"3501608"
            },
            {
               "nome":"AMERICO BRASILIENSE",
               "codigo_ibge":"3501707"
            },
            {
               "nome":"AMERICO DE CAMPOS",
               "codigo_ibge":"3501806"
            },
            {
               "nome":"AMPARO",
               "codigo_ibge":"3501905"
            },
            {
               "nome":"ANALANDIA",
               "codigo_ibge":"3502002"
            },
            {
               "nome":"ANDRADINA",
               "codigo_ibge":"3502101"
            },
            {
               "nome":"ANGATUBA",
               "codigo_ibge":"3502200"
            },
            {
               "nome":"ANHEMBI",
               "codigo_ibge":"3502309"
            },
            {
               "nome":"ANHUMAS",
               "codigo_ibge":"3502408"
            },
            {
               "nome":"APARECIDA",
               "codigo_ibge":"3502507"
            },
            {
               "nome":"APARECIDA D OESTE",
               "codigo_ibge":"3502606"
            },
            {
               "nome":"APIAI",
               "codigo_ibge":"3502705"
            },
            {
               "nome":"ARACARIGUAMA",
               "codigo_ibge":"3502754"
            },
            {
               "nome":"ARACATUBA",
               "codigo_ibge":"3502804"
            },
            {
               "nome":"ARACOIABA DA SERRA",
               "codigo_ibge":"3502903"
            },
            {
               "nome":"ARAMINA",
               "codigo_ibge":"3503000"
            },
            {
               "nome":"ARANDU",
               "codigo_ibge":"3503109"
            },
            {
               "nome":"ARAPEI",
               "codigo_ibge":"3503158"
            },
            {
               "nome":"ARARAQUARA",
               "codigo_ibge":"3503208"
            },
            {
               "nome":"ARARAS",
               "codigo_ibge":"3503307"
            },
            {
               "nome":"ARCO-IRIS",
               "codigo_ibge":"3503356"
            },
            {
               "nome":"AREALVA",
               "codigo_ibge":"3503406"
            },
            {
               "nome":"AREIAS",
               "codigo_ibge":"3503505"
            },
            {
               "nome":"AREIOPOLIS",
               "codigo_ibge":"3503604"
            },
            {
               "nome":"ARIRANHA",
               "codigo_ibge":"3503703"
            },
            {
               "nome":"ARTUR NOGUEIRA",
               "codigo_ibge":"3503802"
            },
            {
               "nome":"ARUJA",
               "codigo_ibge":"3503901"
            },
            {
               "nome":"ASPASIA",
               "codigo_ibge":"3503950"
            },
            {
               "nome":"ASSIS",
               "codigo_ibge":"3504008"
            },
            {
               "nome":"ATIBAIA",
               "codigo_ibge":"3504107"
            },
            {
               "nome":"AURIFLAMA",
               "codigo_ibge":"3504206"
            },
            {
               "nome":"AVAI",
               "codigo_ibge":"3504305"
            },
            {
               "nome":"AVANHANDAVA",
               "codigo_ibge":"3504404"
            },
            {
               "nome":"AVARE",
               "codigo_ibge":"3504503"
            },
            {
               "nome":"BADY BASSITT",
               "codigo_ibge":"3504602"
            },
            {
               "nome":"BALBINOS",
               "codigo_ibge":"3504701"
            },
            {
               "nome":"BALSAMO",
               "codigo_ibge":"3504800"
            },
            {
               "nome":"BANANAL",
               "codigo_ibge":"3504909"
            },
            {
               "nome":"BARAO DE ANTONINA",
               "codigo_ibge":"3505005"
            },
            {
               "nome":"BARBOSA",
               "codigo_ibge":"3505104"
            },
            {
               "nome":"BARIRI",
               "codigo_ibge":"3505203"
            },
            {
               "nome":"BARRA BONITA",
               "codigo_ibge":"3505302"
            },
            {
               "nome":"BARRA DO CHAPEU",
               "codigo_ibge":"3505351"
            },
            {
               "nome":"BARRA DO TURVO",
               "codigo_ibge":"3505401"
            },
            {
               "nome":"BARRETOS",
               "codigo_ibge":"3505500"
            },
            {
               "nome":"BARRINHA",
               "codigo_ibge":"3505609"
            },
            {
               "nome":"BARUERI",
               "codigo_ibge":"3505708"
            },
            {
               "nome":"BASTOS",
               "codigo_ibge":"3505807"
            },
            {
               "nome":"BATATAIS",
               "codigo_ibge":"3505906"
            },
            {
               "nome":"BAURU",
               "codigo_ibge":"3506003"
            },
            {
               "nome":"BEBEDOURO",
               "codigo_ibge":"3506102"
            },
            {
               "nome":"BENTO DE ABREU",
               "codigo_ibge":"3506201"
            },
            {
               "nome":"BERNARDINO DE CAMPOS",
               "codigo_ibge":"3506300"
            },
            {
               "nome":"BERTIOGA",
               "codigo_ibge":"3506359"
            },
            {
               "nome":"BILAC",
               "codigo_ibge":"3506409"
            },
            {
               "nome":"BIRIGUI",
               "codigo_ibge":"3506508"
            },
            {
               "nome":"BIRITIBA-MIRIM",
               "codigo_ibge":"3506607"
            },
            {
               "nome":"BOA ESPERANCA DO SUL",
               "codigo_ibge":"3506706"
            },
            {
               "nome":"BOCAINA",
               "codigo_ibge":"3506805"
            },
            {
               "nome":"BOFETE",
               "codigo_ibge":"3506904"
            },
            {
               "nome":"BOITUVA",
               "codigo_ibge":"3507001"
            },
            {
               "nome":"BOM JESUS DOS PERDOES",
               "codigo_ibge":"3507100"
            },
            {
               "nome":"BOM SUCESSO DE ITARARE",
               "codigo_ibge":"3507159"
            },
            {
               "nome":"BORA",
               "codigo_ibge":"3507209"
            },
            {
               "nome":"BORACEIA",
               "codigo_ibge":"3507308"
            },
            {
               "nome":"BORBOREMA",
               "codigo_ibge":"3507407"
            },
            {
               "nome":"BOREBI",
               "codigo_ibge":"3507456"
            },
            {
               "nome":"BOTUCATU",
               "codigo_ibge":"3507506"
            },
            {
               "nome":"BRAGANCA PAULISTA",
               "codigo_ibge":"3507605"
            },
            {
               "nome":"BRAUNA",
               "codigo_ibge":"3507704"
            },
            {
               "nome":"BREJO ALEGRE",
               "codigo_ibge":"3507753"
            },
            {
               "nome":"BRODOWSKI",
               "codigo_ibge":"3507803"
            },
            {
               "nome":"BROTAS",
               "codigo_ibge":"3507902"
            },
            {
               "nome":"BURI",
               "codigo_ibge":"3508009"
            },
            {
               "nome":"BURITAMA",
               "codigo_ibge":"3508108"
            },
            {
               "nome":"BURITIZAL",
               "codigo_ibge":"3508207"
            },
            {
               "nome":"CABRALIA PAULISTA",
               "codigo_ibge":"3508306"
            },
            {
               "nome":"CABREUVA",
               "codigo_ibge":"3508405"
            },
            {
               "nome":"CACAPAVA",
               "codigo_ibge":"3508504"
            },
            {
               "nome":"CACHOEIRA PAULISTA",
               "codigo_ibge":"3508603"
            },
            {
               "nome":"CACONDE",
               "codigo_ibge":"3508702"
            },
            {
               "nome":"CAFELANDIA",
               "codigo_ibge":"3508801"
            },
            {
               "nome":"CAIABU",
               "codigo_ibge":"3508900"
            },
            {
               "nome":"CAIEIRAS",
               "codigo_ibge":"3509007"
            },
            {
               "nome":"CAIUA",
               "codigo_ibge":"3509106"
            },
            {
               "nome":"CAJAMAR",
               "codigo_ibge":"3509205"
            },
            {
               "nome":"CAJATI",
               "codigo_ibge":"3509254"
            },
            {
               "nome":"CAJOBI",
               "codigo_ibge":"3509304"
            },
            {
               "nome":"CAJURU",
               "codigo_ibge":"3509403"
            },
            {
               "nome":"CAMPINA DO MONTE ALEGRE",
               "codigo_ibge":"3509452"
            },
            {
               "nome":"CAMPINAS",
               "codigo_ibge":"3509502"
            },
            {
               "nome":"CAMPO LIMPO PAULISTA",
               "codigo_ibge":"3509601"
            },
            {
               "nome":"CAMPOS DO JORDAO",
               "codigo_ibge":"3509700"
            },
            {
               "nome":"CAMPOS NOVOS PAULISTA",
               "codigo_ibge":"3509809"
            },
            {
               "nome":"CANANEIA",
               "codigo_ibge":"3509908"
            },
            {
               "nome":"CANAS",
               "codigo_ibge":"3509957"
            },
            {
               "nome":"CANDIDO MOTA",
               "codigo_ibge":"3510005"
            },
            {
               "nome":"CANDIDO RODRIGUES",
               "codigo_ibge":"3510104"
            },
            {
               "nome":"CANITAR",
               "codigo_ibge":"3510153"
            },
            {
               "nome":"CAPAO BONITO",
               "codigo_ibge":"3510203"
            },
            {
               "nome":"CAPELA DO ALTO",
               "codigo_ibge":"3510302"
            },
            {
               "nome":"CAPIVARI",
               "codigo_ibge":"3510401"
            },
            {
               "nome":"CARAGUATATUBA",
               "codigo_ibge":"3510500"
            },
            {
               "nome":"CARAPICUIBA",
               "codigo_ibge":"3510609"
            },
            {
               "nome":"CARDOSO",
               "codigo_ibge":"3510708"
            },
            {
               "nome":"CASA BRANCA",
               "codigo_ibge":"3510807"
            },
            {
               "nome":"CASSIA DOS COQUEIROS",
               "codigo_ibge":"3510906"
            },
            {
               "nome":"CASTILHO",
               "codigo_ibge":"3511003"
            },
            {
               "nome":"CATANDUVA",
               "codigo_ibge":"3511102"
            },
            {
               "nome":"CATIGUA",
               "codigo_ibge":"3511201"
            },
            {
               "nome":"CEDRAL",
               "codigo_ibge":"3511300"
            },
            {
               "nome":"CERQUEIRA CESAR",
               "codigo_ibge":"3511409"
            },
            {
               "nome":"CERQUILHO",
               "codigo_ibge":"3511508"
            },
            {
               "nome":"CESARIO LANGE",
               "codigo_ibge":"3511607"
            },
            {
               "nome":"CHARQUEADA",
               "codigo_ibge":"3511706"
            },
            {
               "nome":"CLEMENTINA",
               "codigo_ibge":"3511904"
            },
            {
               "nome":"COLINA",
               "codigo_ibge":"3512001"
            },
            {
               "nome":"COLOMBIA",
               "codigo_ibge":"3512100"
            },
            {
               "nome":"CONCHAL",
               "codigo_ibge":"3512209"
            },
            {
               "nome":"CONCHAS",
               "codigo_ibge":"3512308"
            },
            {
               "nome":"CORDEIROPOLIS",
               "codigo_ibge":"3512407"
            },
            {
               "nome":"COROADOS",
               "codigo_ibge":"3512506"
            },
            {
               "nome":"CORONEL MACEDO",
               "codigo_ibge":"3512605"
            },
            {
               "nome":"CORUMBATAI",
               "codigo_ibge":"3512704"
            },
            {
               "nome":"COSMOPOLIS",
               "codigo_ibge":"3512803"
            },
            {
               "nome":"COSMORAMA",
               "codigo_ibge":"3512902"
            },
            {
               "nome":"COTIA",
               "codigo_ibge":"3513009"
            },
            {
               "nome":"CRAVINHOS",
               "codigo_ibge":"3513108"
            },
            {
               "nome":"CRISTAIS PAULISTA",
               "codigo_ibge":"3513207"
            },
            {
               "nome":"CRUZALIA",
               "codigo_ibge":"3513306"
            },
            {
               "nome":"CRUZEIRO",
               "codigo_ibge":"3513405"
            },
            {
               "nome":"CUBATAO",
               "codigo_ibge":"3513504"
            },
            {
               "nome":"CUNHA",
               "codigo_ibge":"3513603"
            },
            {
               "nome":"DESCALVADO",
               "codigo_ibge":"3513702"
            },
            {
               "nome":"DIADEMA",
               "codigo_ibge":"3513801"
            },
            {
               "nome":"DIRCE REIS",
               "codigo_ibge":"3513850"
            },
            {
               "nome":"DIVINOLANDIA",
               "codigo_ibge":"3513900"
            },
            {
               "nome":"DOBRADA",
               "codigo_ibge":"3514007"
            },
            {
               "nome":"DOIS CORREGOS",
               "codigo_ibge":"3514106"
            },
            {
               "nome":"DOLCINOPOLIS",
               "codigo_ibge":"3514205"
            },
            {
               "nome":"DOURADO",
               "codigo_ibge":"3514304"
            },
            {
               "nome":"DRACENA",
               "codigo_ibge":"3514403"
            },
            {
               "nome":"DUARTINA",
               "codigo_ibge":"3514502"
            },
            {
               "nome":"DUMONT",
               "codigo_ibge":"3514601"
            },
            {
               "nome":"ECHAPORA",
               "codigo_ibge":"3514700"
            },
            {
               "nome":"ELDORADO",
               "codigo_ibge":"3514809"
            },
            {
               "nome":"ELIAS FAUSTO",
               "codigo_ibge":"3514908"
            },
            {
               "nome":"ELISIARIO",
               "codigo_ibge":"3514924"
            },
            {
               "nome":"EMBAUBA",
               "codigo_ibge":"3514957"
            },
            {
               "nome":"EMBU DAS ARTES",
               "codigo_ibge":"3515004"
            },
            {
               "nome":"EMBU-GUACU",
               "codigo_ibge":"3515103"
            },
            {
               "nome":"EMILIANOPOLIS",
               "codigo_ibge":"3515129"
            },
            {
               "nome":"ENGENHEIRO COELHO",
               "codigo_ibge":"3515152"
            },
            {
               "nome":"ESPIRITO SANTO DO PINHAL",
               "codigo_ibge":"3515186"
            },
            {
               "nome":"ESPIRITO SANTO DO TURVO",
               "codigo_ibge":"3515194"
            },
            {
               "nome":"ESTRELA D OESTE",
               "codigo_ibge":"3515202"
            },
            {
               "nome":"ESTRELA DO NORTE",
               "codigo_ibge":"3515301"
            },
            {
               "nome":"EUCLIDES DA CUNHA PAULISTA",
               "codigo_ibge":"3515350"
            },
            {
               "nome":"FARTURA",
               "codigo_ibge":"3515400"
            },
            {
               "nome":"FERNANDOPOLIS",
               "codigo_ibge":"3515509"
            },
            {
               "nome":"FERNANDO PRESTES",
               "codigo_ibge":"3515608"
            },
            {
               "nome":"FERNAO",
               "codigo_ibge":"3515657"
            },
            {
               "nome":"FERRAZ DE VASCONCELOS",
               "codigo_ibge":"3515707"
            },
            {
               "nome":"FLORA RICA",
               "codigo_ibge":"3515806"
            },
            {
               "nome":"FLOREAL",
               "codigo_ibge":"3515905"
            },
            {
               "nome":"FLORIDA PAULISTA",
               "codigo_ibge":"3516002"
            },
            {
               "nome":"FLORINIA",
               "codigo_ibge":"3516101"
            },
            {
               "nome":"FRANCA",
               "codigo_ibge":"3516200"
            },
            {
               "nome":"FRANCISCO MORATO",
               "codigo_ibge":"3516309"
            },
            {
               "nome":"FRANCO DA ROCHA",
               "codigo_ibge":"3516408"
            },
            {
               "nome":"GABRIEL MONTEIRO",
               "codigo_ibge":"3516507"
            },
            {
               "nome":"GALIA",
               "codigo_ibge":"3516606"
            },
            {
               "nome":"GARCA",
               "codigo_ibge":"3516705"
            },
            {
               "nome":"GASTAO VIDIGAL",
               "codigo_ibge":"3516804"
            },
            {
               "nome":"GAVIAO PEIXOTO",
               "codigo_ibge":"3516853"
            },
            {
               "nome":"GENERAL SALGADO",
               "codigo_ibge":"3516903"
            },
            {
               "nome":"GETULINA",
               "codigo_ibge":"3517000"
            },
            {
               "nome":"GLICERIO",
               "codigo_ibge":"3517109"
            },
            {
               "nome":"GUAICARA",
               "codigo_ibge":"3517208"
            },
            {
               "nome":"GUAIMBE",
               "codigo_ibge":"3517307"
            },
            {
               "nome":"GUAIRA",
               "codigo_ibge":"3517406"
            },
            {
               "nome":"GUAPIACU",
               "codigo_ibge":"3517505"
            },
            {
               "nome":"GUAPIARA",
               "codigo_ibge":"3517604"
            },
            {
               "nome":"GUARA",
               "codigo_ibge":"3517703"
            },
            {
               "nome":"GUARACAI",
               "codigo_ibge":"3517802"
            },
            {
               "nome":"GUARACI",
               "codigo_ibge":"3517901"
            },
            {
               "nome":"GUARANI D OESTE",
               "codigo_ibge":"3518008"
            },
            {
               "nome":"GUARANTA",
               "codigo_ibge":"3518107"
            },
            {
               "nome":"GUARARAPES",
               "codigo_ibge":"3518206"
            },
            {
               "nome":"GUARAREMA",
               "codigo_ibge":"3518305"
            },
            {
               "nome":"GUARATINGUETA",
               "codigo_ibge":"3518404"
            },
            {
               "nome":"GUAREI",
               "codigo_ibge":"3518503"
            },
            {
               "nome":"GUARIBA",
               "codigo_ibge":"3518602"
            },
            {
               "nome":"GUARUJA",
               "codigo_ibge":"3518701"
            },
            {
               "nome":"GUARULHOS",
               "codigo_ibge":"3518800"
            },
            {
               "nome":"GUATAPARA",
               "codigo_ibge":"3518859"
            },
            {
               "nome":"GUZOLANDIA",
               "codigo_ibge":"3518909"
            },
            {
               "nome":"HERCULANDIA",
               "codigo_ibge":"3519006"
            },
            {
               "nome":"HOLAMBRA",
               "codigo_ibge":"3519055"
            },
            {
               "nome":"HORTOLANDIA",
               "codigo_ibge":"3519071"
            },
            {
               "nome":"IACANGA",
               "codigo_ibge":"3519105"
            },
            {
               "nome":"IACRI",
               "codigo_ibge":"3519204"
            },
            {
               "nome":"IARAS",
               "codigo_ibge":"3519253"
            },
            {
               "nome":"IBATE",
               "codigo_ibge":"3519303"
            },
            {
               "nome":"IBIRA",
               "codigo_ibge":"3519402"
            },
            {
               "nome":"IBIRAREMA",
               "codigo_ibge":"3519501"
            },
            {
               "nome":"IBITINGA",
               "codigo_ibge":"3519600"
            },
            {
               "nome":"IBIUNA",
               "codigo_ibge":"3519709"
            },
            {
               "nome":"ICEM",
               "codigo_ibge":"3519808"
            },
            {
               "nome":"IEPE",
               "codigo_ibge":"3519907"
            },
            {
               "nome":"IGARACU DO TIETE",
               "codigo_ibge":"3520004"
            },
            {
               "nome":"IGARAPAVA",
               "codigo_ibge":"3520103"
            },
            {
               "nome":"IGARATA",
               "codigo_ibge":"3520202"
            },
            {
               "nome":"IGUAPE",
               "codigo_ibge":"3520301"
            },
            {
               "nome":"ILHABELA",
               "codigo_ibge":"3520400"
            },
            {
               "nome":"ILHA COMPRIDA",
               "codigo_ibge":"3520426"
            },
            {
               "nome":"ILHA SOLTEIRA",
               "codigo_ibge":"3520442"
            },
            {
               "nome":"INDAIATUBA",
               "codigo_ibge":"3520509"
            },
            {
               "nome":"INDIANA",
               "codigo_ibge":"3520608"
            },
            {
               "nome":"INDIAPORA",
               "codigo_ibge":"3520707"
            },
            {
               "nome":"INUBIA PAULISTA",
               "codigo_ibge":"3520806"
            },
            {
               "nome":"IPAUSSU",
               "codigo_ibge":"3520905"
            },
            {
               "nome":"IPERO",
               "codigo_ibge":"3521002"
            },
            {
               "nome":"IPEUNA",
               "codigo_ibge":"3521101"
            },
            {
               "nome":"IPIGUA",
               "codigo_ibge":"3521150"
            },
            {
               "nome":"IPORANGA",
               "codigo_ibge":"3521200"
            },
            {
               "nome":"IPUA",
               "codigo_ibge":"3521309"
            },
            {
               "nome":"IRACEMAPOLIS",
               "codigo_ibge":"3521408"
            },
            {
               "nome":"IRAPUA",
               "codigo_ibge":"3521507"
            },
            {
               "nome":"IRAPURU",
               "codigo_ibge":"3521606"
            },
            {
               "nome":"ITABERA",
               "codigo_ibge":"3521705"
            },
            {
               "nome":"ITAI",
               "codigo_ibge":"3521804"
            },
            {
               "nome":"ITAJOBI",
               "codigo_ibge":"3521903"
            },
            {
               "nome":"ITAJU",
               "codigo_ibge":"3522000"
            },
            {
               "nome":"ITANHAEM",
               "codigo_ibge":"3522109"
            },
            {
               "nome":"ITAOCA",
               "codigo_ibge":"3522158"
            },
            {
               "nome":"ITAPECERICA DA SERRA",
               "codigo_ibge":"3522208"
            },
            {
               "nome":"ITAPETININGA",
               "codigo_ibge":"3522307"
            },
            {
               "nome":"ITAPEVA",
               "codigo_ibge":"3522406"
            },
            {
               "nome":"ITAPEVI",
               "codigo_ibge":"3522505"
            },
            {
               "nome":"ITAPIRA",
               "codigo_ibge":"3522604"
            },
            {
               "nome":"ITAPIRAPUA PAULISTA",
               "codigo_ibge":"3522653"
            },
            {
               "nome":"ITAPOLIS",
               "codigo_ibge":"3522703"
            },
            {
               "nome":"ITAPORANGA",
               "codigo_ibge":"3522802"
            },
            {
               "nome":"ITAPUI",
               "codigo_ibge":"3522901"
            },
            {
               "nome":"ITAPURA",
               "codigo_ibge":"3523008"
            },
            {
               "nome":"ITAQUAQUECETUBA",
               "codigo_ibge":"3523107"
            },
            {
               "nome":"ITARARE",
               "codigo_ibge":"3523206"
            },
            {
               "nome":"ITARIRI",
               "codigo_ibge":"3523305"
            },
            {
               "nome":"ITATIBA",
               "codigo_ibge":"3523404"
            },
            {
               "nome":"ITATINGA",
               "codigo_ibge":"3523503"
            },
            {
               "nome":"ITIRAPINA",
               "codigo_ibge":"3523602"
            },
            {
               "nome":"ITIRAPUA",
               "codigo_ibge":"3523701"
            },
            {
               "nome":"ITOBI",
               "codigo_ibge":"3523800"
            },
            {
               "nome":"ITU",
               "codigo_ibge":"3523909"
            },
            {
               "nome":"ITUPEVA",
               "codigo_ibge":"3524006"
            },
            {
               "nome":"ITUVERAVA",
               "codigo_ibge":"3524105"
            },
            {
               "nome":"JABORANDI",
               "codigo_ibge":"3524204"
            },
            {
               "nome":"JABOTICABAL",
               "codigo_ibge":"3524303"
            },
            {
               "nome":"JACAREI",
               "codigo_ibge":"3524402"
            },
            {
               "nome":"JACI",
               "codigo_ibge":"3524501"
            },
            {
               "nome":"JACUPIRANGA",
               "codigo_ibge":"3524600"
            },
            {
               "nome":"JAGUARIUNA",
               "codigo_ibge":"3524709"
            },
            {
               "nome":"JALES",
               "codigo_ibge":"3524808"
            },
            {
               "nome":"JAMBEIRO",
               "codigo_ibge":"3524907"
            },
            {
               "nome":"JANDIRA",
               "codigo_ibge":"3525003"
            },
            {
               "nome":"JARDINOPOLIS",
               "codigo_ibge":"3525102"
            },
            {
               "nome":"JARINU",
               "codigo_ibge":"3525201"
            },
            {
               "nome":"JAU",
               "codigo_ibge":"3525300"
            },
            {
               "nome":"JERIQUARA",
               "codigo_ibge":"3525409"
            },
            {
               "nome":"JOANOPOLIS",
               "codigo_ibge":"3525508"
            },
            {
               "nome":"JOAO RAMALHO",
               "codigo_ibge":"3525607"
            },
            {
               "nome":"JOSE BONIFACIO",
               "codigo_ibge":"3525706"
            },
            {
               "nome":"JULIO MESQUITA",
               "codigo_ibge":"3525805"
            },
            {
               "nome":"JUMIRIM",
               "codigo_ibge":"3525854"
            },
            {
               "nome":"JUNDIAI",
               "codigo_ibge":"3525904"
            },
            {
               "nome":"JUNQUEIROPOLIS",
               "codigo_ibge":"3526001"
            },
            {
               "nome":"JUQUIA",
               "codigo_ibge":"3526100"
            },
            {
               "nome":"JUQUITIBA",
               "codigo_ibge":"3526209"
            },
            {
               "nome":"LAGOINHA",
               "codigo_ibge":"3526308"
            },
            {
               "nome":"LARANJAL PAULISTA",
               "codigo_ibge":"3526407"
            },
            {
               "nome":"LAVINIA",
               "codigo_ibge":"3526506"
            },
            {
               "nome":"LAVRINHAS",
               "codigo_ibge":"3526605"
            },
            {
               "nome":"LEME",
               "codigo_ibge":"3526704"
            },
            {
               "nome":"LENCOIS PAULISTA",
               "codigo_ibge":"3526803"
            },
            {
               "nome":"LIMEIRA",
               "codigo_ibge":"3526902"
            },
            {
               "nome":"LINDOIA",
               "codigo_ibge":"3527009"
            },
            {
               "nome":"LINS",
               "codigo_ibge":"3527108"
            },
            {
               "nome":"LORENA",
               "codigo_ibge":"3527207"
            },
            {
               "nome":"LOURDES",
               "codigo_ibge":"3527256"
            },
            {
               "nome":"LOUVEIRA",
               "codigo_ibge":"3527306"
            },
            {
               "nome":"LUCELIA",
               "codigo_ibge":"3527405"
            },
            {
               "nome":"LUCIANOPOLIS",
               "codigo_ibge":"3527504"
            },
            {
               "nome":"LUIS ANTONIO",
               "codigo_ibge":"3527603"
            },
            {
               "nome":"LUIZIANIA",
               "codigo_ibge":"3527702"
            },
            {
               "nome":"LUPERCIO",
               "codigo_ibge":"3527801"
            },
            {
               "nome":"LUTECIA",
               "codigo_ibge":"3527900"
            },
            {
               "nome":"MACATUBA",
               "codigo_ibge":"3528007"
            },
            {
               "nome":"MACAUBAL",
               "codigo_ibge":"3528106"
            },
            {
               "nome":"MACEDONIA",
               "codigo_ibge":"3528205"
            },
            {
               "nome":"MAGDA",
               "codigo_ibge":"3528304"
            },
            {
               "nome":"MAIRINQUE",
               "codigo_ibge":"3528403"
            },
            {
               "nome":"MAIRIPORA",
               "codigo_ibge":"3528502"
            },
            {
               "nome":"MANDURI",
               "codigo_ibge":"3528601"
            },
            {
               "nome":"MARABA PAULISTA",
               "codigo_ibge":"3528700"
            },
            {
               "nome":"MARACAI",
               "codigo_ibge":"3528809"
            },
            {
               "nome":"MARAPOAMA",
               "codigo_ibge":"3528858"
            },
            {
               "nome":"MARIAPOLIS",
               "codigo_ibge":"3528908"
            },
            {
               "nome":"MARILIA",
               "codigo_ibge":"3529005"
            },
            {
               "nome":"MARINOPOLIS",
               "codigo_ibge":"3529104"
            },
            {
               "nome":"MARTINOPOLIS",
               "codigo_ibge":"3529203"
            },
            {
               "nome":"MATAO",
               "codigo_ibge":"3529302"
            },
            {
               "nome":"MAUA",
               "codigo_ibge":"3529401"
            },
            {
               "nome":"MENDONCA",
               "codigo_ibge":"3529500"
            },
            {
               "nome":"MERIDIANO",
               "codigo_ibge":"3529609"
            },
            {
               "nome":"MESOPOLIS",
               "codigo_ibge":"3529658"
            },
            {
               "nome":"MIGUELOPOLIS",
               "codigo_ibge":"3529708"
            },
            {
               "nome":"MINEIROS DO TIETE",
               "codigo_ibge":"3529807"
            },
            {
               "nome":"MIRACATU",
               "codigo_ibge":"3529906"
            },
            {
               "nome":"MIRA ESTRELA",
               "codigo_ibge":"3530003"
            },
            {
               "nome":"MIRANDOPOLIS",
               "codigo_ibge":"3530102"
            },
            {
               "nome":"MIRANTE DO PARANAPANEMA",
               "codigo_ibge":"3530201"
            },
            {
               "nome":"MIRASSOL",
               "codigo_ibge":"3530300"
            },
            {
               "nome":"MIRASSOLANDIA",
               "codigo_ibge":"3530409"
            },
            {
               "nome":"MOCOCA",
               "codigo_ibge":"3530508"
            },
            {
               "nome":"MOGI DAS CRUZES",
               "codigo_ibge":"3530607"
            },
            {
               "nome":"MOGI GUACU",
               "codigo_ibge":"3530706"
            },
            {
               "nome":"MOJI MIRIM",
               "codigo_ibge":"3530805"
            },
            {
               "nome":"MOMBUCA",
               "codigo_ibge":"3530904"
            },
            {
               "nome":"MONCOES",
               "codigo_ibge":"3531001"
            },
            {
               "nome":"MONGAGUA",
               "codigo_ibge":"3531100"
            },
            {
               "nome":"MONTE ALEGRE DO SUL",
               "codigo_ibge":"3531209"
            },
            {
               "nome":"MONTE ALTO",
               "codigo_ibge":"3531308"
            },
            {
               "nome":"MONTE APRAZIVEL",
               "codigo_ibge":"3531407"
            },
            {
               "nome":"MONTE AZUL PAULISTA",
               "codigo_ibge":"3531506"
            },
            {
               "nome":"MONTE CASTELO",
               "codigo_ibge":"3531605"
            },
            {
               "nome":"MONTEIRO LOBATO",
               "codigo_ibge":"3531704"
            },
            {
               "nome":"MONTE MOR",
               "codigo_ibge":"3531803"
            },
            {
               "nome":"MORRO AGUDO",
               "codigo_ibge":"3531902"
            },
            {
               "nome":"MORUNGABA",
               "codigo_ibge":"3532009"
            },
            {
               "nome":"MOTUCA",
               "codigo_ibge":"3532058"
            },
            {
               "nome":"MURUTINGA DO SUL",
               "codigo_ibge":"3532108"
            },
            {
               "nome":"NANTES",
               "codigo_ibge":"3532157"
            },
            {
               "nome":"NARANDIBA",
               "codigo_ibge":"3532207"
            },
            {
               "nome":"NATIVIDADE DA SERRA",
               "codigo_ibge":"3532306"
            },
            {
               "nome":"NAZARE PAULISTA",
               "codigo_ibge":"3532405"
            },
            {
               "nome":"NEVES PAULISTA",
               "codigo_ibge":"3532504"
            },
            {
               "nome":"NHANDEARA",
               "codigo_ibge":"3532603"
            },
            {
               "nome":"NIPOA",
               "codigo_ibge":"3532702"
            },
            {
               "nome":"NOVA ALIANCA",
               "codigo_ibge":"3532801"
            },
            {
               "nome":"NOVA CAMPINA",
               "codigo_ibge":"3532827"
            },
            {
               "nome":"NOVA CANAA PAULISTA",
               "codigo_ibge":"3532843"
            },
            {
               "nome":"NOVA CASTILHO",
               "codigo_ibge":"3532868"
            },
            {
               "nome":"NOVA EUROPA",
               "codigo_ibge":"3532900"
            },
            {
               "nome":"NOVA GRANADA",
               "codigo_ibge":"3533007"
            },
            {
               "nome":"NOVA GUATAPORANGA",
               "codigo_ibge":"3533106"
            },
            {
               "nome":"NOVA INDEPENDENCIA",
               "codigo_ibge":"3533205"
            },
            {
               "nome":"NOVAIS",
               "codigo_ibge":"3533254"
            },
            {
               "nome":"NOVA LUZITANIA",
               "codigo_ibge":"3533304"
            },
            {
               "nome":"NOVA ODESSA",
               "codigo_ibge":"3533403"
            },
            {
               "nome":"NOVO HORIZONTE",
               "codigo_ibge":"3533502"
            },
            {
               "nome":"NUPORANGA",
               "codigo_ibge":"3533601"
            },
            {
               "nome":"OCAUCU",
               "codigo_ibge":"3533700"
            },
            {
               "nome":"OLEO",
               "codigo_ibge":"3533809"
            },
            {
               "nome":"OLIMPIA",
               "codigo_ibge":"3533908"
            },
            {
               "nome":"ONDA VERDE",
               "codigo_ibge":"3534005"
            },
            {
               "nome":"ORIENTE",
               "codigo_ibge":"3534104"
            },
            {
               "nome":"ORINDIUVA",
               "codigo_ibge":"3534203"
            },
            {
               "nome":"ORLANDIA",
               "codigo_ibge":"3534302"
            },
            {
               "nome":"OSASCO",
               "codigo_ibge":"3534401"
            },
            {
               "nome":"OSCAR BRESSANE",
               "codigo_ibge":"3534500"
            },
            {
               "nome":"OSVALDO CRUZ",
               "codigo_ibge":"3534609"
            },
            {
               "nome":"OURINHOS",
               "codigo_ibge":"3534708"
            },
            {
               "nome":"OUROESTE",
               "codigo_ibge":"3534757"
            },
            {
               "nome":"OURO VERDE",
               "codigo_ibge":"3534807"
            },
            {
               "nome":"PACAEMBU",
               "codigo_ibge":"3534906"
            },
            {
               "nome":"PALESTINA",
               "codigo_ibge":"3535002"
            },
            {
               "nome":"PALMARES PAULISTA",
               "codigo_ibge":"3535101"
            },
            {
               "nome":"PALMEIRA D OESTE",
               "codigo_ibge":"3535200"
            },
            {
               "nome":"PALMITAL",
               "codigo_ibge":"3535309"
            },
            {
               "nome":"PANORAMA",
               "codigo_ibge":"3535408"
            },
            {
               "nome":"PARAGUACU PAULISTA",
               "codigo_ibge":"3535507"
            },
            {
               "nome":"PARAIBUNA",
               "codigo_ibge":"3535606"
            },
            {
               "nome":"PARAISO",
               "codigo_ibge":"3535705"
            },
            {
               "nome":"PARANAPANEMA",
               "codigo_ibge":"3535804"
            },
            {
               "nome":"PARANAPUA",
               "codigo_ibge":"3535903"
            },
            {
               "nome":"PARAPUA",
               "codigo_ibge":"3536000"
            },
            {
               "nome":"PARDINHO",
               "codigo_ibge":"3536109"
            },
            {
               "nome":"PARIQUERA-ACU",
               "codigo_ibge":"3536208"
            },
            {
               "nome":"PARISI",
               "codigo_ibge":"3536257"
            },
            {
               "nome":"PATROCINIO PAULISTA",
               "codigo_ibge":"3536307"
            },
            {
               "nome":"PAULICEIA",
               "codigo_ibge":"3536406"
            },
            {
               "nome":"PAULINIA",
               "codigo_ibge":"3536505"
            },
            {
               "nome":"PAULISTANIA",
               "codigo_ibge":"3536570"
            },
            {
               "nome":"PAULO DE FARIA",
               "codigo_ibge":"3536604"
            },
            {
               "nome":"PEDERNEIRAS",
               "codigo_ibge":"3536703"
            },
            {
               "nome":"PEDRA BELA",
               "codigo_ibge":"3536802"
            },
            {
               "nome":"PEDRANOPOLIS",
               "codigo_ibge":"3536901"
            },
            {
               "nome":"PEDREGULHO",
               "codigo_ibge":"3537008"
            },
            {
               "nome":"PEDREIRA",
               "codigo_ibge":"3537107"
            },
            {
               "nome":"PEDRINHAS PAULISTA",
               "codigo_ibge":"3537156"
            },
            {
               "nome":"PEDRO DE TOLEDO",
               "codigo_ibge":"3537206"
            },
            {
               "nome":"PENAPOLIS",
               "codigo_ibge":"3537305"
            },
            {
               "nome":"PEREIRA BARRETO",
               "codigo_ibge":"3537404"
            },
            {
               "nome":"PEREIRAS",
               "codigo_ibge":"3537503"
            },
            {
               "nome":"PERUIBE",
               "codigo_ibge":"3537602"
            },
            {
               "nome":"PIACATU",
               "codigo_ibge":"3537701"
            },
            {
               "nome":"PIEDADE",
               "codigo_ibge":"3537800"
            },
            {
               "nome":"PILAR DO SUL",
               "codigo_ibge":"3537909"
            },
            {
               "nome":"PINDAMONHANGABA",
               "codigo_ibge":"3538006"
            },
            {
               "nome":"PINDORAMA",
               "codigo_ibge":"3538105"
            },
            {
               "nome":"PINHALZINHO",
               "codigo_ibge":"3538204"
            },
            {
               "nome":"PIQUEROBI",
               "codigo_ibge":"3538303"
            },
            {
               "nome":"PIQUETE",
               "codigo_ibge":"3538501"
            },
            {
               "nome":"PIRACAIA",
               "codigo_ibge":"3538600"
            },
            {
               "nome":"PIRACICABA",
               "codigo_ibge":"3538709"
            },
            {
               "nome":"PIRAJU",
               "codigo_ibge":"3538808"
            },
            {
               "nome":"PIRAJUI",
               "codigo_ibge":"3538907"
            },
            {
               "nome":"PIRANGI",
               "codigo_ibge":"3539004"
            },
            {
               "nome":"PIRAPORA DO BOM JESUS",
               "codigo_ibge":"3539103"
            },
            {
               "nome":"PIRAPOZINHO",
               "codigo_ibge":"3539202"
            },
            {
               "nome":"PIRASSUNUNGA",
               "codigo_ibge":"3539301"
            },
            {
               "nome":"PIRATININGA",
               "codigo_ibge":"3539400"
            },
            {
               "nome":"PITANGUEIRAS",
               "codigo_ibge":"3539509"
            },
            {
               "nome":"PLANALTO",
               "codigo_ibge":"3539608"
            },
            {
               "nome":"PLATINA",
               "codigo_ibge":"3539707"
            },
            {
               "nome":"POA",
               "codigo_ibge":"3539806"
            },
            {
               "nome":"POLONI",
               "codigo_ibge":"3539905"
            },
            {
               "nome":"POMPEIA",
               "codigo_ibge":"3540002"
            },
            {
               "nome":"PONGAI",
               "codigo_ibge":"3540101"
            },
            {
               "nome":"PONTAL",
               "codigo_ibge":"3540200"
            },
            {
               "nome":"PONTALINDA",
               "codigo_ibge":"3540259"
            },
            {
               "nome":"PONTES GESTAL",
               "codigo_ibge":"3540309"
            },
            {
               "nome":"POPULINA",
               "codigo_ibge":"3540408"
            },
            {
               "nome":"PORANGABA",
               "codigo_ibge":"3540507"
            },
            {
               "nome":"PORTO FELIZ",
               "codigo_ibge":"3540606"
            },
            {
               "nome":"PORTO FERREIRA",
               "codigo_ibge":"3540705"
            },
            {
               "nome":"POTIM",
               "codigo_ibge":"3540754"
            },
            {
               "nome":"POTIRENDABA",
               "codigo_ibge":"3540804"
            },
            {
               "nome":"PRACINHA",
               "codigo_ibge":"3540853"
            },
            {
               "nome":"PRADOPOLIS",
               "codigo_ibge":"3540903"
            },
            {
               "nome":"PRAIA GRANDE",
               "codigo_ibge":"3541000"
            },
            {
               "nome":"PRATANIA",
               "codigo_ibge":"3541059"
            },
            {
               "nome":"PRESIDENTE ALVES",
               "codigo_ibge":"3541109"
            },
            {
               "nome":"PRESIDENTE BERNARDES",
               "codigo_ibge":"3541208"
            },
            {
               "nome":"PRESIDENTE EPITACIO",
               "codigo_ibge":"3541307"
            },
            {
               "nome":"PRESIDENTE PRUDENTE",
               "codigo_ibge":"3541406"
            },
            {
               "nome":"PRESIDENTE VENCESLAU",
               "codigo_ibge":"3541505"
            },
            {
               "nome":"PROMISSAO",
               "codigo_ibge":"3541604"
            },
            {
               "nome":"QUADRA",
               "codigo_ibge":"3541653"
            },
            {
               "nome":"QUATA",
               "codigo_ibge":"3541703"
            },
            {
               "nome":"QUEIROZ",
               "codigo_ibge":"3541802"
            },
            {
               "nome":"QUELUZ",
               "codigo_ibge":"3541901"
            },
            {
               "nome":"QUINTANA",
               "codigo_ibge":"3542008"
            },
            {
               "nome":"RAFARD",
               "codigo_ibge":"3542107"
            },
            {
               "nome":"RANCHARIA",
               "codigo_ibge":"3542206"
            },
            {
               "nome":"REDENCAO DA SERRA",
               "codigo_ibge":"3542305"
            },
            {
               "nome":"REGENTE FEIJO",
               "codigo_ibge":"3542404"
            },
            {
               "nome":"REGINOPOLIS",
               "codigo_ibge":"3542503"
            },
            {
               "nome":"REGISTRO",
               "codigo_ibge":"3542602"
            },
            {
               "nome":"RESTINGA",
               "codigo_ibge":"3542701"
            },
            {
               "nome":"RIBEIRA",
               "codigo_ibge":"3542800"
            },
            {
               "nome":"RIBEIRAO BONITO",
               "codigo_ibge":"3542909"
            },
            {
               "nome":"RIBEIRAO BRANCO",
               "codigo_ibge":"3543006"
            },
            {
               "nome":"RIBEIRAO CORRENTE",
               "codigo_ibge":"3543105"
            },
            {
               "nome":"RIBEIRAO DO SUL",
               "codigo_ibge":"3543204"
            },
            {
               "nome":"RIBEIRAO DOS INDIOS",
               "codigo_ibge":"3543238"
            },
            {
               "nome":"RIBEIRAO GRANDE",
               "codigo_ibge":"3543253"
            },
            {
               "nome":"RIBEIRAO PIRES",
               "codigo_ibge":"3543303"
            },
            {
               "nome":"RIBEIRAO PRETO",
               "codigo_ibge":"3543402"
            },
            {
               "nome":"RIVERSUL",
               "codigo_ibge":"3543501"
            },
            {
               "nome":"RIFAINA",
               "codigo_ibge":"3543600"
            },
            {
               "nome":"RINCAO",
               "codigo_ibge":"3543709"
            },
            {
               "nome":"RINOPOLIS",
               "codigo_ibge":"3543808"
            },
            {
               "nome":"RIO CLARO",
               "codigo_ibge":"3543907"
            },
            {
               "nome":"RIO DAS PEDRAS",
               "codigo_ibge":"3544004"
            },
            {
               "nome":"RIO GRANDE DA SERRA",
               "codigo_ibge":"3544103"
            },
            {
               "nome":"RIOLANDIA",
               "codigo_ibge":"3544202"
            },
            {
               "nome":"ROSANA",
               "codigo_ibge":"3544251"
            },
            {
               "nome":"ROSEIRA",
               "codigo_ibge":"3544301"
            },
            {
               "nome":"RUBIACEA",
               "codigo_ibge":"3544400"
            },
            {
               "nome":"RUBINEIA",
               "codigo_ibge":"3544509"
            },
            {
               "nome":"SABINO",
               "codigo_ibge":"3544608"
            },
            {
               "nome":"SAGRES",
               "codigo_ibge":"3544707"
            },
            {
               "nome":"SALES",
               "codigo_ibge":"3544806"
            },
            {
               "nome":"SALES OLIVEIRA",
               "codigo_ibge":"3544905"
            },
            {
               "nome":"SALESOPOLIS",
               "codigo_ibge":"3545001"
            },
            {
               "nome":"SALMOURAO",
               "codigo_ibge":"3545100"
            },
            {
               "nome":"SALTINHO",
               "codigo_ibge":"3545159"
            },
            {
               "nome":"SALTO",
               "codigo_ibge":"3545209"
            },
            {
               "nome":"SALTO DE PIRAPORA",
               "codigo_ibge":"3545308"
            },
            {
               "nome":"SALTO GRANDE",
               "codigo_ibge":"3545407"
            },
            {
               "nome":"SANDOVALINA",
               "codigo_ibge":"3545506"
            },
            {
               "nome":"SANTA ADELIA",
               "codigo_ibge":"3545605"
            },
            {
               "nome":"SANTA ALBERTINA",
               "codigo_ibge":"3545704"
            },
            {
               "nome":"SANTA BARBARA D OESTE",
               "codigo_ibge":"3545803"
            },
            {
               "nome":"SANTA BRANCA",
               "codigo_ibge":"3546009"
            },
            {
               "nome":"SANTA CLARA D OESTE",
               "codigo_ibge":"3546108"
            },
            {
               "nome":"SANTA CRUZ DA CONCEICAO",
               "codigo_ibge":"3546207"
            },
            {
               "nome":"SANTA CRUZ DA ESPERANCA",
               "codigo_ibge":"3546256"
            },
            {
               "nome":"SANTA CRUZ DAS PALMEIRAS",
               "codigo_ibge":"3546306"
            },
            {
               "nome":"SANTA CRUZ DO RIO PARDO",
               "codigo_ibge":"3546405"
            },
            {
               "nome":"SANTA ERNESTINA",
               "codigo_ibge":"3546504"
            },
            {
               "nome":"SANTA FE DO SUL",
               "codigo_ibge":"3546603"
            },
            {
               "nome":"SANTA GERTRUDES",
               "codigo_ibge":"3546702"
            },
            {
               "nome":"SANTA ISABEL",
               "codigo_ibge":"3546801"
            },
            {
               "nome":"SANTA LUCIA",
               "codigo_ibge":"3546900"
            },
            {
               "nome":"SANTA MARIA DA SERRA",
               "codigo_ibge":"3547007"
            },
            {
               "nome":"SANTA MERCEDES",
               "codigo_ibge":"3547106"
            },
            {
               "nome":"SANTANA DA PONTE PENSA",
               "codigo_ibge":"3547205"
            },
            {
               "nome":"SANTANA DE PARNAIBA",
               "codigo_ibge":"3547304"
            },
            {
               "nome":"SANTA RITA D OESTE",
               "codigo_ibge":"3547403"
            },
            {
               "nome":"SANTA RITA DO PASSA QUATRO",
               "codigo_ibge":"3547502"
            },
            {
               "nome":"SANTA ROSA DE VITERBO",
               "codigo_ibge":"3547601"
            },
            {
               "nome":"SANTA SALETE",
               "codigo_ibge":"3547650"
            },
            {
               "nome":"SANTO ANASTACIO",
               "codigo_ibge":"3547700"
            },
            {
               "nome":"SANTO ANDRE",
               "codigo_ibge":"3547809"
            },
            {
               "nome":"SANTO ANTONIO DA ALEGRIA",
               "codigo_ibge":"3547908"
            },
            {
               "nome":"SANTO ANTONIO DE POSSE",
               "codigo_ibge":"3548005"
            },
            {
               "nome":"SANTO ANTONIO DO ARACANGUA",
               "codigo_ibge":"3548054"
            },
            {
               "nome":"SANTO ANTONIO DO JARDIM",
               "codigo_ibge":"3548104"
            },
            {
               "nome":"SANTO ANTONIO DO PINHAL",
               "codigo_ibge":"3548203"
            },
            {
               "nome":"SANTO EXPEDITO",
               "codigo_ibge":"3548302"
            },
            {
               "nome":"SANTOPOLIS DO AGUAPEI",
               "codigo_ibge":"3548401"
            },
            {
               "nome":"SANTOS",
               "codigo_ibge":"3548500"
            },
            {
               "nome":"SAO BENTO DO SAPUCAI",
               "codigo_ibge":"3548609"
            },
            {
               "nome":"SAO BERNARDO DO CAMPO",
               "codigo_ibge":"3548708"
            },
            {
               "nome":"SAO CAETANO DO SUL",
               "codigo_ibge":"3548807"
            },
            {
               "nome":"SAO CARLOS",
               "codigo_ibge":"3548906"
            },
            {
               "nome":"SAO FRANCISCO",
               "codigo_ibge":"3549003"
            },
            {
               "nome":"SAO JOAO DA BOA VISTA",
               "codigo_ibge":"3549102"
            },
            {
               "nome":"SAO JOAO DAS DUAS PONTES",
               "codigo_ibge":"3549201"
            },
            {
               "nome":"SAO JOAO DE IRACEMA",
               "codigo_ibge":"3549250"
            },
            {
               "nome":"SAO JOAO DO PAU D ALHO",
               "codigo_ibge":"3549300"
            },
            {
               "nome":"SAO JOAQUIM DA BARRA",
               "codigo_ibge":"3549409"
            },
            {
               "nome":"SAO JOSE DA BELA VISTA",
               "codigo_ibge":"3549508"
            },
            {
               "nome":"SAO JOSE DO BARREIRO",
               "codigo_ibge":"3549607"
            },
            {
               "nome":"SAO JOSE DO RIO PARDO",
               "codigo_ibge":"3549706"
            },
            {
               "nome":"SAO JOSE DO RIO PRETO",
               "codigo_ibge":"3549805"
            },
            {
               "nome":"SAO JOSE DOS CAMPOS",
               "codigo_ibge":"3549904"
            },
            {
               "nome":"SAO LOURENCO DA SERRA",
               "codigo_ibge":"3549953"
            },
            {
               "nome":"SAO LUIS DO PARAITINGA",
               "codigo_ibge":"3550001"
            },
            {
               "nome":"SAO MANUEL",
               "codigo_ibge":"3550100"
            },
            {
               "nome":"SAO MIGUEL ARCANJO",
               "codigo_ibge":"3550209"
            },
            {
               "nome":"SAO PAULO",
               "codigo_ibge":"3550308"
            },
            {
               "nome":"SAO PEDRO",
               "codigo_ibge":"3550407"
            },
            {
               "nome":"SAO PEDRO DO TURVO",
               "codigo_ibge":"3550506"
            },
            {
               "nome":"SAO ROQUE",
               "codigo_ibge":"3550605"
            },
            {
               "nome":"SAO SEBASTIAO",
               "codigo_ibge":"3550704"
            },
            {
               "nome":"SAO SEBASTIAO DA GRAMA",
               "codigo_ibge":"3550803"
            },
            {
               "nome":"SAO SIMAO",
               "codigo_ibge":"3550902"
            },
            {
               "nome":"SAO VICENTE",
               "codigo_ibge":"3551009"
            },
            {
               "nome":"SARAPUI",
               "codigo_ibge":"3551108"
            },
            {
               "nome":"SARUTAIA",
               "codigo_ibge":"3551207"
            },
            {
               "nome":"SEBASTIANOPOLIS DO SUL",
               "codigo_ibge":"3551306"
            },
            {
               "nome":"SERRA AZUL",
               "codigo_ibge":"3551405"
            },
            {
               "nome":"SERRANA",
               "codigo_ibge":"3551504"
            },
            {
               "nome":"SERRA NEGRA",
               "codigo_ibge":"3551603"
            },
            {
               "nome":"SERTAOZINHO",
               "codigo_ibge":"3551702"
            },
            {
               "nome":"SETE BARRAS",
               "codigo_ibge":"3551801"
            },
            {
               "nome":"SEVERINIA",
               "codigo_ibge":"3551900"
            },
            {
               "nome":"SILVEIRAS",
               "codigo_ibge":"3552007"
            },
            {
               "nome":"SOCORRO",
               "codigo_ibge":"3552106"
            },
            {
               "nome":"SOROCABA",
               "codigo_ibge":"3552205"
            },
            {
               "nome":"SUD MENNUCCI",
               "codigo_ibge":"3552304"
            },
            {
               "nome":"SUMARE",
               "codigo_ibge":"3552403"
            },
            {
               "nome":"SUZANO",
               "codigo_ibge":"3552502"
            },
            {
               "nome":"SUZANAPOLIS",
               "codigo_ibge":"3552551"
            },
            {
               "nome":"TABAPUA",
               "codigo_ibge":"3552601"
            },
            {
               "nome":"TABATINGA",
               "codigo_ibge":"3552700"
            },
            {
               "nome":"TABOAO DA SERRA",
               "codigo_ibge":"3552809"
            },
            {
               "nome":"TACIBA",
               "codigo_ibge":"3552908"
            },
            {
               "nome":"TAGUAI",
               "codigo_ibge":"3553005"
            },
            {
               "nome":"TAIACU",
               "codigo_ibge":"3553104"
            },
            {
               "nome":"TAIUVA",
               "codigo_ibge":"3553203"
            },
            {
               "nome":"TAMBAU",
               "codigo_ibge":"3553302"
            },
            {
               "nome":"TANABI",
               "codigo_ibge":"3553401"
            },
            {
               "nome":"TAPIRAI",
               "codigo_ibge":"3553500"
            },
            {
               "nome":"TAPIRATIBA",
               "codigo_ibge":"3553609"
            },
            {
               "nome":"TAQUARAL",
               "codigo_ibge":"3553658"
            },
            {
               "nome":"TAQUARITINGA",
               "codigo_ibge":"3553708"
            },
            {
               "nome":"TAQUARITUBA",
               "codigo_ibge":"3553807"
            },
            {
               "nome":"TAQUARIVAI",
               "codigo_ibge":"3553856"
            },
            {
               "nome":"TARABAI",
               "codigo_ibge":"3553906"
            },
            {
               "nome":"TARUMA",
               "codigo_ibge":"3553955"
            },
            {
               "nome":"TATUI",
               "codigo_ibge":"3554003"
            },
            {
               "nome":"TAUBATE",
               "codigo_ibge":"3554102"
            },
            {
               "nome":"TEJUPA",
               "codigo_ibge":"3554201"
            },
            {
               "nome":"TEODORO SAMPAIO",
               "codigo_ibge":"3554300"
            },
            {
               "nome":"TERRA ROXA",
               "codigo_ibge":"3554409"
            },
            {
               "nome":"TIETE",
               "codigo_ibge":"3554508"
            },
            {
               "nome":"TIMBURI",
               "codigo_ibge":"3554607"
            },
            {
               "nome":"TORRE DE PEDRA",
               "codigo_ibge":"3554656"
            },
            {
               "nome":"TORRINHA",
               "codigo_ibge":"3554706"
            },
            {
               "nome":"TRABIJU",
               "codigo_ibge":"3554755"
            },
            {
               "nome":"TREMEMBE",
               "codigo_ibge":"3554805"
            },
            {
               "nome":"TRES FRONTEIRAS",
               "codigo_ibge":"3554904"
            },
            {
               "nome":"TUIUTI",
               "codigo_ibge":"3554953"
            },
            {
               "nome":"TUPA",
               "codigo_ibge":"3555000"
            },
            {
               "nome":"TUPI PAULISTA",
               "codigo_ibge":"3555109"
            },
            {
               "nome":"TURIUBA",
               "codigo_ibge":"3555208"
            },
            {
               "nome":"TURMALINA",
               "codigo_ibge":"3555307"
            },
            {
               "nome":"UBARANA",
               "codigo_ibge":"3555356"
            },
            {
               "nome":"UBATUBA",
               "codigo_ibge":"3555406"
            },
            {
               "nome":"UBIRAJARA",
               "codigo_ibge":"3555505"
            },
            {
               "nome":"UCHOA",
               "codigo_ibge":"3555604"
            },
            {
               "nome":"UNIAO PAULISTA",
               "codigo_ibge":"3555703"
            },
            {
               "nome":"URANIA",
               "codigo_ibge":"3555802"
            },
            {
               "nome":"URU",
               "codigo_ibge":"3555901"
            },
            {
               "nome":"URUPES",
               "codigo_ibge":"3556008"
            },
            {
               "nome":"VALENTIM GENTIL",
               "codigo_ibge":"3556107"
            },
            {
               "nome":"VALINHOS",
               "codigo_ibge":"3556206"
            },
            {
               "nome":"VALPARAISO",
               "codigo_ibge":"3556305"
            },
            {
               "nome":"VARGEM",
               "codigo_ibge":"3556354"
            },
            {
               "nome":"VARGEM GRANDE DO SUL",
               "codigo_ibge":"3556404"
            },
            {
               "nome":"VARGEM GRANDE PAULISTA",
               "codigo_ibge":"3556453"
            },
            {
               "nome":"VARZEA PAULISTA",
               "codigo_ibge":"3556503"
            },
            {
               "nome":"VERA CRUZ",
               "codigo_ibge":"3556602"
            },
            {
               "nome":"VINHEDO",
               "codigo_ibge":"3556701"
            },
            {
               "nome":"VIRADOURO",
               "codigo_ibge":"3556800"
            },
            {
               "nome":"VISTA ALEGRE DO ALTO",
               "codigo_ibge":"3556909"
            },
            {
               "nome":"VITORIA BRASIL",
               "codigo_ibge":"3556958"
            },
            {
               "nome":"VOTORANTIM",
               "codigo_ibge":"3557006"
            },
            {
               "nome":"VOTUPORANGA",
               "codigo_ibge":"3557105"
            },
            {
               "nome":"ZACARIAS",
               "codigo_ibge":"3557154"
            },
            {
               "nome":"CHAVANTES",
               "codigo_ibge":"3557204"
            },
            {
               "nome":"ESTIVA GERBI",
               "codigo_ibge":"3557303"
            }
         ]
},

{
    "estado" : "RJ",
    "cidades" : [
      {
         "nome":"Selecione a Cidade",
         "codigo_ibge":"Selecione_a_Cidade"
      },
        {
           "nome":"ANGRA DOS REIS",
           "codigo_ibge":"3300100"
        },
        {
           "nome":"APERIBE",
           "codigo_ibge":"3300159"
        },
        {
           "nome":"ARARUAMA",
           "codigo_ibge":"3300209"
        },
        {
           "nome":"AREAL",
           "codigo_ibge":"3300225"
        },
        {
           "nome":"ARMACAO DOS BUZIOS",
           "codigo_ibge":"3300233"
        },
        {
           "nome":"ARRAIAL DO CABO",
           "codigo_ibge":"3300258"
        },
        {
           "nome":"BARRA DO PIRAI",
           "codigo_ibge":"3300308"
        },
        {
           "nome":"BARRA MANSA",
           "codigo_ibge":"3300407"
        },
        {
           "nome":"BELFORD ROXO",
           "codigo_ibge":"3300456"
        },
        {
           "nome":"BOM JARDIM",
           "codigo_ibge":"3300506"
        },
        {
           "nome":"BOM JESUS DO ITABAPOANA",
           "codigo_ibge":"3300605"
        },
        {
           "nome":"CABO FRIO",
           "codigo_ibge":"3300704"
        },
        {
           "nome":"CACHOEIRAS DE MACACU",
           "codigo_ibge":"3300803"
        },
        {
           "nome":"CAMBUCI",
           "codigo_ibge":"3300902"
        },
        {
           "nome":"CARAPEBUS",
           "codigo_ibge":"3300936"
        },
        {
           "nome":"COMENDADOR LEVY GASPARIAN",
           "codigo_ibge":"3300951"
        },
        {
           "nome":"CAMPOS DOS GOYTACAZES",
           "codigo_ibge":"3301009"
        },
        {
           "nome":"CANTAGALO",
           "codigo_ibge":"3301108"
        },
        {
           "nome":"CARDOSO MOREIRA",
           "codigo_ibge":"3301157"
        },
        {
           "nome":"CARMO",
           "codigo_ibge":"3301207"
        },
        {
           "nome":"CASIMIRO DE ABREU",
           "codigo_ibge":"3301306"
        },
        {
           "nome":"CONCEICAO DE MACABU",
           "codigo_ibge":"3301405"
        },
        {
           "nome":"CORDEIRO",
           "codigo_ibge":"3301504"
        },
        {
           "nome":"DUAS BARRAS",
           "codigo_ibge":"3301603"
        },
        {
           "nome":"DUQUE DE CAXIAS",
           "codigo_ibge":"3301702"
        },
        {
           "nome":"ENGENHEIRO PAULO DE FRONTIN",
           "codigo_ibge":"3301801"
        },
        {
           "nome":"GUAPIMIRIM",
           "codigo_ibge":"3301850"
        },
        {
           "nome":"IGUABA GRANDE",
           "codigo_ibge":"3301876"
        },
        {
           "nome":"ITABORAI",
           "codigo_ibge":"3301900"
        },
        {
           "nome":"ITAGUAI",
           "codigo_ibge":"3302007"
        },
        {
           "nome":"ITALVA",
           "codigo_ibge":"3302056"
        },
        {
           "nome":"ITAOCARA",
           "codigo_ibge":"3302106"
        },
        {
           "nome":"ITAPERUNA",
           "codigo_ibge":"3302205"
        },
        {
           "nome":"ITATIAIA",
           "codigo_ibge":"3302254"
        },
        {
           "nome":"JAPERI",
           "codigo_ibge":"3302270"
        },
        {
           "nome":"LAJE DO MURIAE",
           "codigo_ibge":"3302304"
        },
        {
           "nome":"MACAE",
           "codigo_ibge":"3302403"
        },
        {
           "nome":"MACUCO",
           "codigo_ibge":"3302452"
        },
        {
           "nome":"MAGE",
           "codigo_ibge":"3302502"
        },
        {
           "nome":"MANGARATIBA",
           "codigo_ibge":"3302601"
        },
        {
           "nome":"MARICA",
           "codigo_ibge":"3302700"
        },
        {
           "nome":"MENDES",
           "codigo_ibge":"3302809"
        },
        {
           "nome":"MESQUITA",
           "codigo_ibge":"3302858"
        },
        {
           "nome":"MIGUEL PEREIRA",
           "codigo_ibge":"3302908"
        },
        {
           "nome":"MIRACEMA",
           "codigo_ibge":"3303005"
        },
        {
           "nome":"NATIVIDADE",
           "codigo_ibge":"3303104"
        },
        {
           "nome":"NILOPOLIS",
           "codigo_ibge":"3303203"
        },
        {
           "nome":"NITEROI",
           "codigo_ibge":"3303302"
        },
        {
           "nome":"NOVA FRIBURGO",
           "codigo_ibge":"3303401"
        },
        {
           "nome":"NOVA IGUACU",
           "codigo_ibge":"3303500"
        },
        {
           "nome":"PARACAMBI",
           "codigo_ibge":"3303609"
        },
        {
           "nome":"PARAIBA DO SUL",
           "codigo_ibge":"3303708"
        },
        {
           "nome":"PARATY",
           "codigo_ibge":"3303807"
        },
        {
           "nome":"PATY DO ALFERES",
           "codigo_ibge":"3303856"
        },
        {
           "nome":"PETROPOLIS",
           "codigo_ibge":"3303906"
        },
        {
           "nome":"PINHEIRAL",
           "codigo_ibge":"3303955"
        },
        {
           "nome":"PIRAI",
           "codigo_ibge":"3304003"
        },
        {
           "nome":"PORCIUNCULA",
           "codigo_ibge":"3304102"
        },
        {
           "nome":"PORTO REAL",
           "codigo_ibge":"3304110"
        },
        {
           "nome":"QUATIS",
           "codigo_ibge":"3304128"
        },
        {
           "nome":"QUEIMADOS",
           "codigo_ibge":"3304144"
        },
        {
           "nome":"QUISSAMA",
           "codigo_ibge":"3304151"
        },
        {
           "nome":"RESENDE",
           "codigo_ibge":"3304201"
        },
        {
           "nome":"RIO BONITO",
           "codigo_ibge":"3304300"
        },
        {
           "nome":"RIO CLARO",
           "codigo_ibge":"3304409"
        },
        {
           "nome":"RIO DAS FLORES",
           "codigo_ibge":"3304508"
        },
        {
           "nome":"RIO DAS OSTRAS",
           "codigo_ibge":"3304524"
        },
        {
           "nome":"RIO DE JANEIRO",
           "codigo_ibge":"3304557"
        },
        {
           "nome":"SANTA MARIA MADALENA",
           "codigo_ibge":"3304607"
        },
        {
           "nome":"SANTO ANTONIO DE PADUA",
           "codigo_ibge":"3304706"
        },
        {
           "nome":"SAO FRANCISCO DE ITABAPOANA",
           "codigo_ibge":"3304755"
        },
        {
           "nome":"SAO FIDELIS",
           "codigo_ibge":"3304805"
        },
        {
           "nome":"SAO GONCALO",
           "codigo_ibge":"3304904"
        },
        {
           "nome":"SAO JOAO DA BARRA",
           "codigo_ibge":"3305000"
        },
        {
           "nome":"SAO JOAO DE MERITI",
           "codigo_ibge":"3305109"
        },
        {
           "nome":"SAO JOSE DE UBA",
           "codigo_ibge":"3305133"
        },
        {
           "nome":"SAO JOSE DO VALE DO RIO PRETO",
           "codigo_ibge":"3305158"
        },
        {
           "nome":"SAO PEDRO DA ALDEIA",
           "codigo_ibge":"3305208"
        },
        {
           "nome":"SAO SEBASTIAO DO ALTO",
           "codigo_ibge":"3305307"
        },
        {
           "nome":"SAPUCAIA",
           "codigo_ibge":"3305406"
        },
        {
           "nome":"SAQUAREMA",
           "codigo_ibge":"3305505"
        },
        {
           "nome":"SEROPEDICA",
           "codigo_ibge":"3305554"
        },
        {
           "nome":"SILVA JARDIM",
           "codigo_ibge":"3305604"
        },
        {
           "nome":"SUMIDOURO",
           "codigo_ibge":"3305703"
        },
        {
           "nome":"TANGUA",
           "codigo_ibge":"3305752"
        },
        {
           "nome":"TERESOPOLIS",
           "codigo_ibge":"3305802"
        },
        {
           "nome":"TRAJANO DE MORAES",
           "codigo_ibge":"3305901"
        },
        {
           "nome":"TRES RIOS",
           "codigo_ibge":"3306008"
        },
        {
           "nome":"VALENCA",
           "codigo_ibge":"3306107"
        },
        {
           "nome":"VARRE-SAI",
           "codigo_ibge":"3306156"
        },
        {
           "nome":"VASSOURAS",
           "codigo_ibge":"3306206"
        },
        {
           "nome":"VOLTA REDONDA",
           "codigo_ibge":"3306305"
        }
     ]

}



    
]