
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, Table, InputGroup } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { FaClipboard } from 'react-icons/fa';
import apiAxios from '../../services/api';
import { getTokenParceiroLogado, logoffParceiro } from '../../services/auth';
import MyVerticallyCenteredModal from '../modals/verticallyCenteredModal';
import { getTiposEstabelecimentosBebidas } from '../../util/Categorias';
import { cidades } from '../../hooks/cidades';
import { formatDateDDMMAAAA } from '../../util/util';

// Schema de validação
const schema = yup.object().shape({
    nome_estabelecimento: yup.string().required('Nome do estabelecimento é obrigatório'),
    tipo_estabelecimento: yup.string().required('Tipo de estabelecimento é obrigatório'),
    rua: yup.string().required('Rua é obrigatória'),
    numero: yup.string().required('Número é obrigatório'),
    estado: yup.string().required('Estado é obrigatório'),
    cidade: yup.string().required('Cidade é obrigatória'),
    bairro: yup.string().required('Bairro é obrigatório'),
    cep: yup.string().required('CEP é obrigatório'),
    cnpj: yup.string().required('CNPJ é obrigatório'),
    telefone: yup.string().required('Telefone é obrigatório'),
    email: yup.string().email('Email inválido').required('Email é obrigatório'),
    password: yup.string().required('Senha é obrigatória'),
  });


const SectionDashParceiro = (props) => {

  const [selectedEstado,setSelectedEstado] = useState('');
    const [establishments, setEstablishments] = useState([]);
    const [parceiro, setParceiro] = useState([]);
    const [planoAtivo, setPlanoAtivo] = useState(false);
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [clipboardText, setClipboardText] = useState('');
    const [modalShow, setModalShow] = React.useState(false);
 const [textBodyModal, setTextBodyModal] = React.useState('');
 const [titleTextBodyModal, setTitleTextBodyModal] = React.useState('');
 const [titleModal, setTitleModal] = React.useState('');
    const { control, handleSubmit, reset:resetFormNovoEstabelecimento } = useForm({
      resolver: yupResolver(schema),
      defaultValues: {
        nome_estabelecimento: '',
        tipo_estabelecimento: '',
        rua: '',
        numero: '',
        estado: '',
        cidade: '',
        bairro: '',
        cep: '',
        cnpj: '',
        telefone: '',
        email: '',
        password: '',
      },
    });
  
    // Função para copiar o texto para a área de transferência
    const copyToClipboard = () => {
      navigator.clipboard.writeText(clipboardText);
     
    };
  
    const handleEstadoUpdate = (event) => {
      setSelectedEstado(event.target.value);
    }

    const cidaesDoEstadoEscolhido = cidades.filter(x => x.estado === selectedEstado);

    // Função para enviar o formulário
    const onSubmit = async (data:any) => {
     

        const urlNovoEstabelecimentoParceiro = '/parceiro/novoEstabelecimento';
       
        apiAxios.post(urlNovoEstabelecimentoParceiro,data)
        .then((response) => {
        
          if(!response.data.success){
          //SEM SUCESSO AO PESQUISAR OS DADOS DO PARCEIRO
              
          setTextBodyModal(response.data.message);
          setTitleTextBodyModal('Ops, Atenção !');
          setTitleModal('Verifique :');
          setModalShow(true);
  
  
          }
          else{
            //SUCESSO AO PESQUISAR OS DADOS DO PARCEIRO
            resetFormNovoEstabelecimento();
            setEstablishments(response.data.estabelecimentos ? response.data.estabelecimentos : []);
            setPlanoAtivo(response.data.planoAtivo);
            setDataInicio(response.data.parceiro.dataInicioPlano ? response.data.parceiro.dataInicioPlano : 'Contrate Um Plano' );
            setDataFim(response.data.parceiro.dataFimPlano ? response.data.parceiro.dataFimPlano : 'Contrate Um Plano');
            

            setTextBodyModal(response.data.message);
            setTitleTextBodyModal('Novo Estabelecimento Adicionado, Parabéns !');
            setTitleModal('Sucesso');
            setModalShow(true);

            
          }
          
        });
    };
  
    // Função para buscar estabelecimentos
    const fetchEstablishments = async () => {
     
      const urlConsultaInformacoesParceiro = "/parceiro/consultaInformacoes";

    apiAxios.post(urlConsultaInformacoesParceiro, {
   
        'tokenParceiroLogado':getTokenParceiroLogado()
        
       
      })
      .then((response) => {
      
        if(!response.data.success){
        //SEM SUCESSO AO PESQUISAR OS DADOS DO PARCEIRO
            
        setTextBodyModal(response.data.message);
        setTitleTextBodyModal('Ops, Atenção !');
        setTitleModal('Verifique :');
        setModalShow(true);
        
        if(response.data.message === 'Token Expirado'){
          logoffParceiro();
          props.callbackParceiroIsLoged(false);
        }

        }
        else{
          //SUCESSO AO PESQUISAR OS DADOS DO PARCEIRO
          setEstablishments(response.data.estabelecimentos ? response.data.estabelecimentos : []);
          setPlanoAtivo(response.data.planoAtivo);
          setDataInicio(
          (
            response.data.parceiro.dataInicioPlano && response.data.parceiro.dataInicioPlano != '' ?
            response.data.parceiro.dataInicioPlano
            :
            ''
          )
           );
           
          setDataFim((
            response.data.parceiro.dataFimPlano && response.data.parceiro.dataFimPlano != '' ?
            response.data.parceiro.dataFimPlano
            :
            ''
          ));
          setParceiro(response.data.parceiro);
        }
        
      })
      .catch((erro) => {
        //alert('ERRO: '+erro);
        
      });

    };
  
    // Função para remover um estabelecimento
    const removeEstablishment = async (id:any) => {
      
      const urlRemoverEstabelecimentoParceiro = '/parceiro/removerEstabelecimento';

      apiAxios.post(urlRemoverEstabelecimentoParceiro, {
   
        'idEstabelecimento':id
        
       
      })
      .then((response) => {
      
        if(!response.data.success){
        //SEM SUCESSO AO PESQUISAR OS DADOS DO PARCEIRO
            
        setTextBodyModal(response.data.message);
        setTitleTextBodyModal('Ops, Atenção !');
        setTitleModal('Verifique :');
        setModalShow(true);


        }
        else{
          //SUCESSO AO PESQUISAR OS DADOS DO PARCEIRO
          setEstablishments(response.data.estabelecimentos ? response.data.estabelecimentos : []);
          setPlanoAtivo(response.data.planoAtivo);
          setDataInicio(response.data.parceiro.dataInicioPlano ? response.data.parceiro.dataInicioPlano : 'Contrate Um Plano' );
          setDataFim(response.data.parceiro.dataFimPlano ? response.data.parceiro.dataFimPlano : 'Contrate Um Plano');
        }
        
      })
      .catch((erro) => {
        //alert('ERRO: '+erro);
        
      });
    };
  
    useEffect(() => {
      fetchEstablishments();
    }, []);
  
    const TIPO_COMERCIO = getTiposEstabelecimentosBebidas();

    const sair = () => {
      logoffParceiro();
      props.callbackParceiroIsLoged(false);
    };

    return (
      <Container>
        {/* Seção de Copiar Texto */}
        <section className="my-5">
        <MyVerticallyCenteredModal
     show={modalShow}
     onHide={() => setModalShow(false)}
     textBodyModal={textBodyModal}
     titleTextBodyModal={titleTextBodyModal}
     titleModal={titleModal}
   /> 
   <Row className="mb-4">
   <Col>
   <Button variant="danger" onClick={sair}>Sair</Button>
   </Col>
   </Row>
          {/*<Row className="mb-4">
            <Col>
            <Form.Label>Aqui esta seu Token para utilizar nossa API</Form.Label>
              <InputGroup>
              
                <Form.Control
                  type="hidden"
                  value={clipboardText}
                  onChange={(e) => setClipboardText(e.target.value)}
                  placeholder="Texto para copiar"
                />
                <Button variant="outline-secondary" onClick={copyToClipboard}>
                  <FaClipboard /> Copiar
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <p className="mt-2">
          <strong>Como utilizar o token para requisições na API GeoGet:</strong> Adicione no Authorization como Bearer da requisição.
        </p>*/}
        <p>{parceiro.nomeSistema}</p>
        <p>
          <strong>Plano:</strong> 
          <span style={{ color: planoAtivo ? 'green' : 'red' }}>
          {(planoAtivo ? 'Ativo' : 'Inativo')}
          </span>, 
           <strong>Data Início:</strong> {(dataInicio !== '' ? formatDateDDMMAAAA(dataInicio) : '--' )}, 
           <strong>Data Fim:</strong> {(dataFim !== '' ? formatDateDDMMAAAA(dataFim) : '--' )},
           <strong>Qtde Máx. Estabelecimentos:</strong> {parceiro.qtdeEstabelecimentosPlano}
        </p>
        </section>
  
        {/* Seção de Adicionar Estabelecimento */}
        <section className="my-5">
          <header className="text-center mb-4">
            <h1>Adicionar Estabelecimento</h1>
          </header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6}>
                <Form.Group controlId="nome_estabelecimento">
                  <Form.Label>Nome do Estabelecimento</Form.Label>
                  <Controller
                    name="nome_estabelecimento"
                    control={control}
                    render={({ field }) => <Form.Control {...field} />}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="tipo_estabelecimento">
                  <Form.Label>Tipo de Estabelecimento</Form.Label>
                  <Controller
                    name="tipo_estabelecimento"
                    control={control}
                    render={({ field }) => (
                      <Form.Control as="select" {...field}>
                       
                        {TIPO_COMERCIO.map(c => <option key={c}>{c}</option>)}
                      </Form.Control>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="rua">
                  <Form.Label>Rua</Form.Label>
                  <Controller
                    name="rua"
                    control={control}
                    render={({ field }) => <Form.Control {...field} />}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="numero">
                  <Form.Label>Número</Form.Label>
                  <Controller
                    name="numero"
                    control={control}
                    render={({ field }) => <Form.Control {...field} />}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="estado">
                  <Form.Label>Estado</Form.Label>
                  <Controller
                    name="estado"
                    control={control}
                    render={({ field }) => (
                      <Form.Control as="select" {...field}
                      onChange={(e) => { 
                        field.onChange(e); // react-hook-form's onChange
                        handleEstadoUpdate(e); // Your custom onChange
                      }}>
                       <option value=''>Informe o Estado</option>
                   <option value="AC">Acre</option>
                 <option value="AL">Alagoas</option>
                 <option value="AP">Amapá</option>
                 <option value="AM">Amazonas</option>
                 <option value="BA">Bahia</option>
                 <option value="CE">Ceará</option>
                 <option value="DF">Distrito Federal</option>
                 <option value="ES">Espírito Santo</option>
                 <option value="GO">Goiás</option>
                 <option value="MA">Maranhão</option>
                 <option value="MT">Mato Grosso</option>
                 <option value="MS">Mato Grosso do Sul</option>
                 <option value="MG">Minas Gerais</option>
                 <option value="PA">Pará</option>
                 <option value="PB">Paraíba</option>
                 <option value="PR">Paraná</option>
                 <option value="PE">Pernambuco</option>
                 <option value="PI">Piauí</option>
                 <option value="RJ">Rio de Janeiro</option>
                 <option value="RN">Rio Grande do Norte</option>
                 <option value="RS">Rio Grande do Sul</option>
                 <option value="RO">Rondônia</option>
                 <option value="RR">Roraima</option>
                 <option value="SC">Santa Catarina</option>
                 <option value="SP">São Paulo</option>
                 <option value="SE">Sergipe</option>
                 <option value="TO">Tocantins</option>
                        {/* Adicione outros estados aqui */}
                      </Form.Control>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="cidade">
                  <Form.Label>Cidade</Form.Label>
                  <Controller
                    name="cidade"
                    control={control}
                    render={({ field }) => (
                      <Form.Control as="select" {...field}>
                        <option value="">Selecione</option>
                        {/* Popule as cidades com base no estado selecionado */}
                        {cidaesDoEstadoEscolhido[0].cidades.map((cidade) => (
                     <option key={cidade.codigo_ibge} value={  cidade.codigo_ibge === 'Selecione_a_Cidade' ? "" : cidade.nome}>{cidade.nome}</option>
                   ))}
                        {/* Adicione outras cidades aqui */}
                      </Form.Control>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="bairro">
                  <Form.Label>Bairro</Form.Label>
                  <Controller
                    name="bairro"
                    control={control}
                    render={({ field }) => <Form.Control {...field} />}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="cep">
                  <Form.Label>CEP</Form.Label>
                  <Controller
                    name="cep"
                    control={control}
                    render={({ field }) => (
                      <InputMask mask="99999-999" {...field}>
                        {(inputProps) => <Form.Control {...inputProps} />}
                      </InputMask>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="cnpj">
                  <Form.Label>CNPJ</Form.Label>
                  <Controller
                    name="cnpj"
                    control={control}
                    render={({ field }) => (
                      <InputMask mask="99.999.999/9999-99" {...field}>
                        {(inputProps) => <Form.Control {...inputProps} />}
                      </InputMask>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="telefone">
                  <Form.Label>Telefone</Form.Label>
                  <Controller
                    name="telefone"
                    control={control}
                    render={({ field }) => (
                      <InputMask mask="(99) 99999-9999" {...field}>
                        {(inputProps) => <Form.Control {...inputProps} />}
                      </InputMask>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => <Form.Control type="email" {...field} />}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="password">
                  <Form.Label>Senha</Form.Label>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => <Form.Control type="password" {...field} />}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="justify-content-md-center mt-2">
            <Col xs="auto">
            <Button variant="primary" type="submit" disabled={planoAtivo ? false : true}>Adicionar</Button>
            </Col>
            </Row>
          </Form>
        </section>
  
        {/* Seção de Tabela de Estabelecimentos */}
        <section className="my-5">
          <header className="text-center mb-4">
            <h1>Estabelecimentos</h1>
          </header>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>CNPJ</th>
                <th>Nome</th>
                <th>Qtde Prods Publicados</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {establishments.map((establishment) => (
                <tr key={establishment._id}>
                  <td>{establishment.cnpj}</td>
                  <td>{establishment.nome_estabelecimento}</td>
                  <td>{establishment.produtos_venda ? establishment.produtos_venda.length : '0' }</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => removeEstablishment(establishment._id)}
                    >
                      Remover
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </section>
      </Container>
    );


}


export default SectionDashParceiro;