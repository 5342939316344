import React from "react";
import { useEffect, useRef } from "react";
import { socketPing } from "../context/socket";
import { useState } from "react";
import { mountCards, mountTimeLine } from "../hooks/useMountScreen";
import SectionLibereSuaLocalizacao from "./sectionLibereSuaLocalizacao";
import MapLeafletForUsers from "./mapLeafletForUsers";
import { getTokenIsAuthenticatedClient, isAuthenticatedClient } from "../services/auth";
import { v4 as uuidv4 } from 'uuid';
import { verifyNotification } from "../hooks/useNotifications";
import getCoordinates from "../hooks/getCoordinates";
import SectionRotas from "./SectionRotas";


 import {
    Container
} from "react-bootstrap";
import AlertGeoGet from "./modals/AlertGeoGet";

//import addNotification from 'react-push-notification';



const SectionTimeLine = (props) => { 

  console.log('RENDERIZOU TIMELINE');
//  const socket = useContext(SocketContext);
  const [cardsForAreaCards , setCardsForAreaCards] = useState(['Ao se locomover, iremos atualizando os lugares mais proximos, com promoções']);


const [posicaoAtual, setPosicaoAtual] = useState({'latitude':null, 'longitude':null});
//const posicaoAtual = useRef({'latitude':null, 'longitude':null});
const [estabelecimentos, setEstabelecimentos] = useState([]);
const [qtdeVinhos, setQtdeVinhos] = useState(0);
const [qtdeCervejas, setQtdeCervejas] = useState(0);
const [qtdeCarros, setQtdeCarross] = useState(0);

/*
const location = useRef({
  status: null,
  permissionState: null,
  latitude: null,
  longitude: null,
  message: '',
  browser: null
});*/

// Armazena o valor anterior para comparação
//const previousLocation = useRef(location.current);

const mensagemVerificandoCoordenadas = useRef('Verificando suas coordenadas...');

const [location, setLocation] = useState({
  status: null,
  permissionState: null,
  latitude: null,
  longitude: null,
  message: '',
  browser: null
});



const [userRespondedNotification, setUserRespondedNotification] = useState(false);

let interval ;

const iniciaVerificacaoCoordenadas = ()=>{

  interval = setInterval(async ()=>{
    // Função para atualizar a localização
    getCoordinates().then((locationData:any)=>{
       
      if (locationData.permissionState === 'denied' || locationData.permissionState === 'prompt' || locationData.permissionState === 'unknown') {
        // Se o acesso à localização foi negado, seta isLocationNegada para true e interrompe o setInterval
        
        setLocation(locationData);

        if (socketPing.connected) {
          socketPing.disconnect(true);
        }

        clearInterval(interval); // Interrompe o intervalo
         {/*<AlertGeoGet show={true} mensagem={"Para fornecer uma experiência melhorada, habilite a geolocalização nas configurações do seu navegador. Isso nos permitirá mostrar os estabelecimentos mais próximos de você!"} />*/}
        alert('O acesso a sua Localização, não esta permitido. Permita acesso a geolocalização nas configurações do seu navegador,e tente novamente.');
        
        return;
      }
      else{

        if (!socketPing.connected) {
          socketPing.connect();
        }
           // Verifica se houve alteração no estado da localização
  //const hasChanged = JSON.stringify(previousLocation) !== JSON.stringify(locationData);
  const hasChanged = 
  JSON.stringify({'latitude':posicaoAtual.latitude,'longitude':posicaoAtual.longitude }) !== 
  JSON.stringify({'latitude':locationData.latitude, 'longitude':locationData.longitude});

  if (hasChanged) {
     // Atualiza o estado da localização e o estado anterior
     setLocation(locationData);

    // Chama a função quando há alteração
   // handleCurrentLocation();

   setPosicaoAtual({'latitude':locationData.latitude, 'longitude':locationData.longitude}) ;
   
  
     socketPing.emit("/ping", {

       'latitude' : locationData.latitude, 
       'longitude' : locationData.longitude,
       'latitudeAtual' : null  ,
       'longitudeAtual' : null ,
       'distanciaMetros' : '3000',
       'produtos' : ['Vinhos','Cervejas'],
       'categorias' : ['Cervejas','Vinhos'],
       'dadosCliente' : ( isAuthenticatedClient() ?  getTokenIsAuthenticatedClient() : null),
       'clienteLogado' : false

   });

  }


      }

     

  

    })
   }, 7000);

}

  useEffect(() => {

   // socketPing.connect();
    
    iniciaVerificacaoCoordenadas();
    
   //funcao para detectar o fechamento da aba ou janela
   const handleUnload = async () =>{ 
    
    
    socketPing.disconnect(true);
    localStorage.removeItem('socketIdPing');
    clearInterval(interval);
    
   }

   window.addEventListener('beforeunload',handleUnload);

    return () =>{ // return é a função de cleanup 
     
      console.log('DESMONTANDOCLEAN NA TIMELINEEEE');
      
      socketPing.disconnect(true);
      localStorage.removeItem('socketIdPing');
      window.removeEventListener('beforeunload',handleUnload);
      clearInterval(interval);
      

    };
    
   
  },[]);

  /*
  useEffect(() => {

    if(location.permissionState === 'granted' && location.status === 'success'){ 
     
      handleCurrentLocation();
    }

  },[location]);*/




  

  const handleCurrentLocation = async () => { 
    
        var produtosPreferencia = ['Vinhos','Cervejas'];
        var categoriasPreferencia = ['Cervejas','Vinhos'];
        setPosicaoAtual({'latitude':location.latitude, 'longitude':location.longitude}) ;
        const data = {

            'latitude' : location.latitude, 
            'longitude' : location.longitude,
            'latitudeAtual' : null  ,
            'longitudeAtual' : null ,
            'distanciaMetros' : '3000',
            'produtos' : produtosPreferencia,
            'categorias' : categoriasPreferencia,
            'dadosCliente' : ( isAuthenticatedClient() ?  getTokenIsAuthenticatedClient() : null),
            'clienteLogado' : false

        };
       
          socketPing.emit("/ping", data);

  };

  async function enableNotifications(){
    await verifyNotification().then(() =>{
      setUserRespondedNotification(true);
    });
    

  }

  function notEnableNotifications(){
    setUserRespondedNotification(true);
  }


  socketPing.on('ping', data =>{ 
    
    if(data.publicacaoProdutos){ 

      console.log('OPAAAA RECEBEMOS PUBLICACAO DE PRODUTOS => '+ JSON.stringify(data.produtosPublicados));

    }else{

     // console.log('PING RECEBIDO => '+ JSON.stringify(data));
      var arrayProdutos = [];
      var arrayEstabelecimentosEclientes = [];
var verificaTemEstabelecimentos = (
          
  !data.erro && data.estabelecimentosPorCategoriaProdutosPing.length > 0   ?
  true
  :
  false
  );

  var verificaTemClientes = (
          
    !data.erro && data.usuariosPorCategoriaProdutosPing.length > 0   ?
    true
    :
    false
    );

  if(verificaTemEstabelecimentos){ 

    data.estabelecimentosPorCategoriaProdutosPing.forEach(async (estab) => { 

      if(estab.produtos_venda.length > 0 ){
        estab.produtos_venda.forEach(async (produto) => {
          arrayProdutos.push(produto);
        });
      }

      arrayEstabelecimentosEclientes.push(estab);
    });
    
  }

  if(verificaTemClientes){ 

    data.usuariosPorCategoriaProdutosPing.forEach(async (cliente) => { 
      
      if(cliente.produtos_venda.length > 0 ){
        cliente.produtos_venda.forEach(async (produto) => {
          arrayProdutos.push(produto);
        });
      }
      arrayEstabelecimentosEclientes.push(cliente);
    });

  }


    

  if(arrayEstabelecimentosEclientes.length > 0 ){
   // console.log('AQUI ESTA OS ESTABELECIMENTOS => '+JSON.stringify(arrayEstabelecimentosEclientes));
    setEstabelecimentos(arrayEstabelecimentosEclientes);
    
    //PARA CONTAR QTDE DE CERVEJAS E DE VINHOS
    let qtdeCervejas = 0 ;
    let qtdeVinhos = 0 ;
    let qtdeCarros = 0 ;
   
     if(arrayProdutos.length > 0){
     
      // Supondo que arrayProdutos é um array de produtos
for (const produto of arrayProdutos) {
  // Se a categoria for Vinhos
  if (produto.categoria === 'Vinhos') {
    qtdeVinhos++;
  }
  // Se a categoria for Cervejas
  else if (produto.categoria === 'Cervejas') {
    qtdeCervejas++;
  }
  // Se a categoria for Carros
  else if (produto.categoria === 'Carros') {
    qtdeCarros++;
  }
}
     
     }
  
    setQtdeCervejas(qtdeCervejas);
    setQtdeVinhos(qtdeVinhos);
    setQtdeCarross(qtdeCarros);

    
    const cardsMontados = mountCards(data);
        
    //Aqui atualizar as areas da tela
    setCardsForAreaCards(cardsMontados);
  
    //const timeLineMontada = mountTimeLine(data,timelineForAreaTimeLine);
    
    
/*
    arrayEstabelecimentosEclientes.forEach(element => { 
    
      //Itera nos produtos a venda do Estabelecimento
      element.produtos_venda.forEach(prodVenda => {
      
       let urlGooGleMaps = new URL('https://www.google.com/maps/dir/');

           urlGooGleMaps.searchParams.append('q', element.rua+','+element.numero+' - '+element.bairro+','+' '+element.cidade+' - '+element.estado+', '+element.cep);
           //Av. Santo Albano, 848 - Vila Vera, São Paulo - SP, 04296-000
      
         const tituloNotificacao = prodVenda.categoria+' '+prodVenda.nome_produto+' '+prodVenda.ml+'ml - R$'+prodVenda.preco;
         const subTituloNotificacao = 'Distância: '+element.distanciaEmKilometros.toFixed(1)+' KM';
         const mensagemNotificacao = subTituloNotificacao+', '+tituloNotificacao+', CLIQUE AQUI E VÁ BUSCAR';
       

      });

   });*/


  }


    }

        });

  
  return (


 
      (location.permissionState === 'granted' && location.status === 'success') 
      &&
      (posicaoAtual.latitude != null && posicaoAtual.longitude != null) 
      
      ?
     
       ( <>  
     
      
     <section key={uuidv4()} style={{ textAlign: 'center', padding: '1rem 0 3rem 0', backgroundColor: '#f9f9f9' }}>
    <Container key={uuidv4()} style={{ maxWidth: '1140px', margin: '0 auto' }}>
      <h3 style={{ marginBottom: '1.5rem', fontSize: '1.75rem', fontWeight: 'bold', color: '#333' }}>
        As melhores Ofertas de cervejas e vinhos ao seu redor!
      </h3>
      {estabelecimentos !== null && estabelecimentos.length > 0 && (
        <h4 style={{ marginBottom: '1rem', fontSize: '1.25rem', color: '#007bff' }}>
          {estabelecimentos.length} Estabelecimentos ao seu redor!
        </h4>
      )}
      <h4 style={{ marginBottom: '2rem', fontSize: '1.25rem', color: '#007bff' }}>
        {qtdeCervejas} Cervejas, {qtdeVinhos} Vinhos
      </h4>
  
      
        {cardsForAreaCards}
     
    </Container>
  </section>
  
  
  <section key='123445' className="text-center" style={{"padding-top": "2rem", "padding-bottom": "3rem"}}> 
  <Container>
  
  <MapLeafletForUsers    
              arrayMarkers={estabelecimentos}
              centroMapaPosicaoUser={[posicaoAtual.latitude,posicaoAtual.longitude]}
              //posicaoUser={[posicaoAtual.latitude,posicaoAtual.longitude]}
              dataUserCenter={{nome:'Você'}}
              mapParaEstabelecimento={false}
              raioCirculo = {500}
              />
  
  
  
            </Container>
            </section>
            
  
  {
     (estabelecimentos != null && estabelecimentos.length > 0 ? <SectionRotas estabelecimentos={estabelecimentos} origin={{ lat: posicaoAtual.latitude, lng: posicaoAtual.longitude }} /> : '')
     }
     
  </>
  
   )
  
  
  
   :
    
    
   (
      
      <SectionLibereSuaLocalizacao 
      iniciaVerificacaoCoordenadas={iniciaVerificacaoCoordenadas} 
      location={location} 
      />
   
  
  
   )
 
    


  )


  

    
}

export default SectionTimeLine;


