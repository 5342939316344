export const marcasCarros = 
[
    {
        "codigo":"",
        "nome":"Informe a Marca"
    },
    {
       "codigo":"1",
       "nome":"Acura"
    },
    {
       "codigo":"2",
       "nome":"Agrale"
    },
    {
       "codigo":"3",
       "nome":"Alfa Romeo"
    },
    {
       "codigo":"4",
       "nome":"AM Gen"
    },
    {
       "codigo":"5",
       "nome":"Asia Motors"
    },
    {
       "codigo":"189",
       "nome":"ASTON MARTIN"
    },
    {
       "codigo":"6",
       "nome":"Audi"
    },
    {
       "codigo":"207",
       "nome":"Baby"
    },
    {
       "codigo":"7",
       "nome":"BMW"
    },
    {
       "codigo":"8",
       "nome":"BRM"
    },
    {
       "codigo":"123",
       "nome":"Bugre"
    },
    {
       "codigo":"238",
       "nome":"BYD"
    },
    {
       "codigo":"236",
       "nome":"CAB Motors"
    },
    {
       "codigo":"10",
       "nome":"Cadillac"
    },
    {
       "codigo":"161",
       "nome":"Caoa Chery"
    },
    {
       "codigo":"11",
       "nome":"CBT Jipe"
    },
    {
       "codigo":"136",
       "nome":"CHANA"
    },
    {
       "codigo":"182",
       "nome":"CHANGAN"
    },
    {
       "codigo":"12",
       "nome":"Chrysler"
    },
    {
       "codigo":"13",
       "nome":"Citroën"
    },
    {
       "codigo":"14",
       "nome":"Cross Lander"
    },
    {
       "codigo":"241",
       "nome":"D2D "
    },
    {
       "codigo":"15",
       "nome":"Daewoo"
    },
    {
       "codigo":"16",
       "nome":"Daihatsu"
    },
    {
       "codigo":"17",
       "nome":"Dodge"
    },
    {
       "codigo":"147",
       "nome":"EFFA"
    },
    {
       "codigo":"18",
       "nome":"Engesa"
    },
    {
       "codigo":"19",
       "nome":"Envemo"
    },
    {
       "codigo":"20",
       "nome":"Ferrari"
    },
    {
       "codigo":"21",
       "nome":"Fiat"
    },
    {
       "codigo":"149",
       "nome":"Fibravan"
    },
    {
       "codigo":"22",
       "nome":"Ford"
    },
    {
       "codigo":"190",
       "nome":"FOTON"
    },
    {
       "codigo":"170",
       "nome":"Fyber"
    },
    {
       "codigo":"199",
       "nome":"GEELY"
    },
    {
       "codigo":"23",
       "nome":"GM - Chevrolet"
    },
    {
       "codigo":"153",
       "nome":"GREAT WALL"
    },
    {
       "codigo":"24",
       "nome":"Gurgel"
    },
    {
       "codigo":"240",
       "nome":"GWM"
    },
    {
       "codigo":"152",
       "nome":"HAFEI"
    },
    {
       "codigo":"214",
       "nome":"HITECH ELECTRIC"
    },
    {
       "codigo":"25",
       "nome":"Honda"
    },
    {
       "codigo":"26",
       "nome":"Hyundai"
    },
    {
       "codigo":"27",
       "nome":"Isuzu"
    },
    {
       "codigo":"208",
       "nome":"IVECO"
    },
    {
       "codigo":"177",
       "nome":"JAC"
    },
    {
       "codigo":"28",
       "nome":"Jaguar"
    },
    {
       "codigo":"29",
       "nome":"Jeep"
    },
    {
       "codigo":"154",
       "nome":"JINBEI"
    },
    {
       "codigo":"30",
       "nome":"JPX"
    },
    {
       "codigo":"31",
       "nome":"Kia Motors"
    },
    {
       "codigo":"32",
       "nome":"Lada"
    },
    {
       "codigo":"171",
       "nome":"LAMBORGHINI"
    },
    {
       "codigo":"33",
       "nome":"Land Rover"
    },
    {
       "codigo":"34",
       "nome":"Lexus"
    },
    {
       "codigo":"168",
       "nome":"LIFAN"
    },
    {
       "codigo":"127",
       "nome":"LOBINI"
    },
    {
       "codigo":"35",
       "nome":"Lotus"
    },
    {
       "codigo":"140",
       "nome":"Mahindra"
    },
    {
       "codigo":"36",
       "nome":"Maserati"
    },
    {
       "codigo":"37",
       "nome":"Matra"
    },
    {
       "codigo":"38",
       "nome":"Mazda"
    },
    {
       "codigo":"211",
       "nome":"Mclaren"
    },
    {
       "codigo":"39",
       "nome":"Mercedes-Benz"
    },
    {
       "codigo":"40",
       "nome":"Mercury"
    },
    {
       "codigo":"167",
       "nome":"MG"
    },
    {
       "codigo":"156",
       "nome":"MINI"
    },
    {
       "codigo":"41",
       "nome":"Mitsubishi"
    },
    {
       "codigo":"42",
       "nome":"Miura"
    },
    {
       "codigo":"43",
       "nome":"Nissan"
    },
    {
       "codigo":"44",
       "nome":"Peugeot"
    },
    {
       "codigo":"45",
       "nome":"Plymouth"
    },
    {
       "codigo":"46",
       "nome":"Pontiac"
    },
    {
       "codigo":"47",
       "nome":"Porsche"
    },
    {
       "codigo":"185",
       "nome":"RAM"
    },
    {
       "codigo":"186",
       "nome":"RELY"
    },
    {
       "codigo":"48",
       "nome":"Renault"
    },
    {
       "codigo":"195",
       "nome":"Rolls-Royce"
    },
    {
       "codigo":"49",
       "nome":"Rover"
    },
    {
       "codigo":"50",
       "nome":"Saab"
    },
    {
       "codigo":"51",
       "nome":"Saturn"
    },
    {
       "codigo":"52",
       "nome":"Seat"
    },
    {
       "codigo":"183",
       "nome":"SHINERAY"
    },
    {
       "codigo":"157",
       "nome":"smart"
    },
    {
       "codigo":"125",
       "nome":"SSANGYONG"
    },
    {
       "codigo":"54",
       "nome":"Subaru"
    },
    {
       "codigo":"55",
       "nome":"Suzuki"
    },
    {
       "codigo":"165",
       "nome":"TAC"
    },
    {
       "codigo":"56",
       "nome":"Toyota"
    },
    {
       "codigo":"57",
       "nome":"Troller"
    },
    {
       "codigo":"58",
       "nome":"Volvo"
    },
    {
       "codigo":"59",
       "nome":"VW - VolksWagen"
    },
    {
       "codigo":"163",
       "nome":"Wake"
    },
    {
       "codigo":"120",
       "nome":"Walk"
    }
 ]