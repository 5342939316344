export const categorias = [
    {
       "categoria" : "",
       "subcategorias" : [
          {
             "nome":"Selecione a Categoria",
             "codigo":"Selecione_a_Categoria"
          }
       ]
    },
    {
       "categoria" : "Cervejas", // Codigo 1
       "subcategorias" : [
          {
             "nome":"Selecione a Categoria",
             "codigo":"Selecione_a_Categoria"
          },
          {
            "nome":"Artesanal",
            "codigo":"11"
          },
          {
             "nome":"Pilsen",
             "codigo":"12"
          },
          {
             "nome":"Lager",
             "codigo":"13"
          },
          {
             "nome":"Pale Ale",
             "codigo":"14"
          },
          {
             "nome":"Bock",
             "codigo":"15"
          },
          {
             "nome":"Weissbier",
             "codigo":"16"
          },
          {
             "nome":"Witbier",
             "codigo":"17"
          }
       ]
    },
    {
        "categoria" : "Vinhos", // Codigo 2
        "subcategorias" : [
           {
              "nome":"Selecione a Categoria",
              "codigo":"Selecione_a_Categoria"
           },
           {
              "nome":"Vinho Tinto",
              "codigo":"22"
           },
           {
              "nome":"Vinho Branco",
              "codigo":"23"
           },
           {
              "nome":"Vinho Rosé",
              "codigo":"24"
           },
           {
              "nome":"Espumante",
              "codigo":"25"
           },
           {
              "nome":"Vinho Licoroso",
              "codigo":"26"
           }
        ]
     }
    
 
 
     
 ]