
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState} from 'react';
import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { object, string } from "yup";
import { cidades } from '../hooks/cidades';
//import axios from 'axios';  
import apiAxios from '../services/api';
import {  isAuthenticatedClient, login, logoutClient } from '../services/auth';
import { Modal } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getTiposEstabelecimentosBebidas } from '../util/Categorias';
import { v4 as uuidv4 } from 'uuid';




function MyVerticallyCenteredModal(props) {

 return (
     <Modal
       {...props}
       size="lg"
       aria-labelledby="contained-modal-title-vcenter"
       centered
     >
       <Modal.Header closeButton>
         <Modal.Title id="contained-modal-title-vcenter">
         {props.titleModal}
         </Modal.Title>
       </Modal.Header>
       <Modal.Body>
         <h4>{props.titleTextBodyModal}</h4>
         <p>
         {props.textBodyModal}
         </p>
       </Modal.Body>
       <Modal.Footer>
         <Button onClick={props.onHide}>Close</Button>
       </Modal.Footer>
     </Modal>
   );


}

const schemaLogin = object({
 emailLogin:string().email('Email inválido').required("Email Obrigatório").min(5,"Minimo de 5 caracteres"),
 senhaLogin:string().required("Senha Obrigatório").min(6,'Minimo de 6 caracteres').max(6,'Maximo de 6 caracteres')
});

const schemaCadastro = object({
 nome_estabelecimento:string().required("Nome Obrigatório").min(3,"Minimo de 3 caracteres").max(25,'Máximo 25 caracteres'),
 rua:string().required("Rua Obrigatório").min(3,"Minimo de 3 caracteres").max(25,'Maximo de 25 caracteres'),
 numero:string().required("Obrigatório"),
 bairro:string().required("Bairro Obrigatório").min(3,"Minimo de 3 caracteres").max(25,'Maximo de 25 caracteres'),
 cnpj:string().required("CNPJ Obrigatório").transform(value => value.replace(/[^\d]/g, '')).min(14,"Minimo de 14 caracteres").max(14,'Maximo de 14 caracteres'),
 cep:string().required("Cep Obrigatório").max(9,"Maximo de 9 caracteres").min(9,"Minimo 9 caracteres") ,
 telefone:string().required("Celular Obrigatório").transform(value => value.replace(/[^\d]/g, '')).min(11,"Minimo de 11 caracteres"),
 email:string().email('Email inválido').required("Email Obrigatório").min(5,"Minimo de 5 caracteres"),
 senha:string().required("Senha Obrigatório").min(6,'Minimo de 6 caracteres').max(6,'Maximo de 6 caracteres'),
 estado:string().required("Informe o Estado"),
 cidade:string().required("Informe a Cidade")
});

 

const SectionFormsCadastroLoginEstabelecimento = (props) => {  

  const { executeRecaptcha } = useGoogleReCaptcha();
 const [modalShow, setModalShow] = React.useState(false);
 const [textBodyModal, setTextBodyModal] = React.useState('');
 const [titleTextBodyModal, setTitleTextBodyModal] = React.useState('');
 const [titleModal, setTitleModal] = React.useState('');
 
 const [selectedEstado,setSelectedEstado] = useState('');

 const [isLoggedLogin,setIsLoggedLogin] = useState(false);

 const handleEstadoUpdate = (event) => {
   setSelectedEstado(event.target.value);
 }
  
  const cidaesDoEstadoEscolhido = cidades.filter(x => x.estado === selectedEstado);
  //console.log(cidaesDoEstadoEscolhido);
   const { 
     register, 
     setError : setErrorLogin,
     handleSubmit : onSubmitLogin, 
     watch, 
     formState: { errors } ,
     setFocus : setFocusFormLogin
   } = useForm({resolver:yupResolver(schemaLogin)});

   const {
     register: registerCadastro, 
     handleSubmit: onSubmitCadastro, 
     watch : watchCadastro, 
     formState: {errors: errorsCadastro},
     reset : resetFormCadastro,
     setFocus : setFocusFormCadastro
   } = useForm({
     mode: "onChange",
     resolver: yupResolver(schemaCadastro)
   });

   const handleSubmitLogin = (data:any) => { 

     const urlLoginEstabelecimento = "/estabelecimento/login";
   
     console.log('LOGIN : '+data);//Aqui fazer o submit para o backend


          apiAxios.post(urlLoginEstabelecimento, {
          'emailLogin' : data.emailLogin,
          'senhaLogin' : data.senhaLogin
         })
         .then((response) => {
          
           
           if(response.status === 200 && response.data.auth){
            const dadosUsuario = response.data.dataUser ;
            if(!response.data.coordenadasAtualizadas){
              //Mostrar mensagem informando que coordenadas estao sendo processadas
              setIsLoggedLogin(false);
             props.callbackParentIsLoged(isLoggedLogin);

             setTextBodyModal('No momento, as coordenadas do endereço informando, estão sendo processadas, para mostrar as melhores promoções ao seu redor. Por favor, realize o Login mais tarde.');
             setTitleTextBodyModal('Porcessando coordenadas');
             setTitleModal('Atenção:');
             setModalShow(true);
            }
            else{
              //Seguir com o Login

              //se usuario estiver logado, realiza o Logoff do Usuario
              if(isAuthenticatedClient()){logoutClient()}
              // Extrair o token do cabeçalho Authorization
              //const token = response.headers['authorization'].split(' ')[1];

              login(response.data.token,response.data.dataUser);
             setIsLoggedLogin(true);
             props.callbackParentIsLoged(isLoggedLogin);
             
            }

              
           }
           else{
             setIsLoggedLogin(false);
             props.callbackParentIsLoged(isLoggedLogin);

             handleError(response);
           }
   
           
         })
         .catch((erro) => {
           
          handleError(erro.response);
          
         });
   };

   const handleError = (response) => {
    const message = response?.data?.message || 'Olá, estamos realizando algumas Manutenções, por favor, tente novamente mais tarde.';

    if (response.status === 401) {
        setTextBodyModal(message);
        setTitleTextBodyModal('Erro de autenticação');
        setTitleModal('Atenção:');
    } else if (response.status === 403) {
        setTextBodyModal(message);
        setTitleTextBodyModal('Acesso negado');
        setTitleModal('Atenção:');
    } else if (response.status === 400) {
        setTextBodyModal(message);
        setTitleTextBodyModal('Erro de validação');
        setTitleModal('Atenção:');
    } else {
        setTextBodyModal(message);
        setTitleTextBodyModal('Estamos em Manutenção...');
        setTitleModal('Opsss...');
    }
    setModalShow(true);
};

   

   props.callbackParentIsLoged(isLoggedLogin);

   const handleSubmitCadastro = async (data:any) => { 

    

     if(data.cidade == '' || data.cidade == 'Selecione o Estado'){
       alert('Informe a Cidade');
       
     }else if(!validarCNPJ(data.cnpj)){
       alert('CNPJ Invalido');
     }
     else{ 

      if (!executeRecaptcha) {
        console.log('Execute recaptcha não está disponível ainda');
        return;
      }
  
      const token = await executeRecaptcha('signup');

       const urlNovoEstabelecimento = "/estabelecimento/novo";

       apiAxios.post(urlNovoEstabelecimento, {
   
         'email':data.email,
         'rua': data.rua,
         'numero':data.numero.replace('___','').replace('__','').replace('_',''),
         'nome_estabelecimento': data.nome_estabelecimento,
         'tipo_estabelecimento':data.tipo_estabelecimento,
         'estado':data.estado,
         'cidade':data.cidade,
         'bairro':data.bairro,
         'cep':data.cep,
         'cnpj':data.cnpj,
         'telefone':data.telefone,
         'senha':data.senha,
         'tokenRecaptcha': token,
         'isParceiro':false
       })
       .then((response) => {
         //aqui, tratar a resposta
         //alert('RESPOSTA DO CADASTRO : '+response.data);
         
         if(response.data.succes){
           //resetFormCadastro(data);
           resetFormCadastro();

           setTextBodyModal(response.data.message);
           setTitleTextBodyModal('Sucesso !');
           setTitleModal('Cadastro :');
           setModalShow(true);

           setFocusFormLogin('emailLogin');

         }
         else{
           //Nao sucesso
          console.log('ERRO AO TENTAR CADASTRAR => '+ JSON.stringify(response));
           setTextBodyModal(response.data.message);
           setTitleTextBodyModal('Ops, Atenção !');
           setTitleModal('Cadastro :');
           setModalShow(true);

         }
         
       })
       .catch((erro) => {
         //alert('ERRO: '+erro);
         console.log('ERRO AO TENTAR CADASTRAR ESTABELECIMENTO CATCH => '+ JSON.stringify(erro));
         setTitleModal('Seu Cadastro :');
         setTitleTextBodyModal('Oooops...');
         setTextBodyModal('Algo ocorreu, contate o Administrador.');
         setModalShow(true);
       });
       
       
     }

     
};

function verificaCoordenadas(idVerificar,tipoUsuario,objectEndereco){

  let urlVerificaCoordenadas = "/estabelecimento/verificaCoordenadas";

  if(tipoUsuario === 'cliente'){
   urlVerificaCoordenadas = "/estabelecimento/verificaCoordenadas";
  }

  
  console.log('ENVIANDO A VERIFICACAO DE COORDENADAS : => '+JSON.stringify(objectEndereco));
     apiAxios.post(urlVerificaCoordenadas, {
 
      id:idVerificar,
      endereco: objectEndereco
     })
     .then((response) => {
       //aqui, tratar a resposta
      
       
       if(response.data.succes){
         //resetFormCadastro(data);
        

        
       }
       else{
         //Nao sucesso

        
       }
       
     })
     .catch((erro) => {
       //alert('ERRO: '+erro);
      
     });

}

   const validarCNPJ = (cnpj) => {

     cnpj = cnpj.replace(/[^\d]+/g,'');
    
       if(cnpj == ''){ return false;}
        
       if (cnpj.length != 14){
           return false;
       }
    
       // Elimina CNPJs invalidos conhecidos
       if (cnpj == "00000000000000" || 
           cnpj == "11111111111111" || 
           cnpj == "22222222222222" || 
           cnpj == "33333333333333" || 
           cnpj == "44444444444444" || 
           cnpj == "55555555555555" || 
           cnpj == "66666666666666" || 
           cnpj == "77777777777777" || 
           cnpj == "88888888888888" || 
           cnpj == "99999999999999"){
           return false;
           }
            
       // Valida DVs
       var b = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]
       var c = String(cnpj).replace(/[^\d]/g, '')
       
       if(c.length !== 14){
         return false;
       }
           
   
       if(/0{14}/.test(c)){
         return false;
       }
           
   
       for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
       if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n)){
           return false;
         }
   
       for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
       if(c[13] != (((n %= 11) < 2) ? 0 : 11 - n)){
         return false;
       }
           
   
       return true;
   }


   //const TIPO_COMERCIO = ['Tipo de Comércio','Adega', 'Mercado', 'Sacolão', 'Vinícola', 'Bar/Boteco', 'Distr. Bebidas', 'Concessionaria de Carros','Multimarcas de Carros'];
   const TIPO_COMERCIO = getTiposEstabelecimentosBebidas();
   

   return (
   
 
 
<section className="w-100 px-4 py-5 text-center bg-light">

     <MyVerticallyCenteredModal
     show={modalShow}
     onHide={() => setModalShow(false)}
     textBodyModal={textBodyModal}
     titleTextBodyModal={titleTextBodyModal}
     titleModal={titleModal}
   />   
    
<Container>
<h3 className="mb-5">
Aumente o movimento em seu bar ou restaurante com promoções inteligentes para clientes próximos! Cadastre-se na GeoGet e faça suas mesas encherem nos horários ociosos. É Grátis
  </h3>
   
     <Row>
       <Col md={4} className="text-center" >
       <h5 className="mb-5">Faça Login e mostre seus produtos</h5>
        <Form onSubmit={onSubmitLogin(handleSubmitLogin)}> 
         <Row className="mb-3 centered">
             <Col sm={8} >
              
                 <Form.Control
                 required
           type="email"
           placeholder="Email"
           id="emailLogin" 
           defaultValue=""
           {...register("emailLogin")}
         />
         <span style={{'color':'red'}}>{errors?.emailLogin?.message}</span>
         
             </Col>
            
         </Row>
         <Row className="mb-3 centered">
             <Col sm={8}>
                 
                 <Form.Control
           required
           type="password"
           placeholder="Senha"
           id="senhaLogin" 
           defaultValue=""
           {...register("senhaLogin")}
         />
         <span style={{'color':'red'}}>{errors?.senhaLogin?.message}</span>
             </Col>
             
         </Row>
         
         <Row>
         <Button variant="primary" type="submit" className="px-4 float-right mb-3">Entrar</Button>
         </Row>
         
         
           <Row>
          <label style={{ fontSize: '12px' }}><a href="/esqueciSenha" target='_blank'>Esqueci a senha</a></label>
          </Row>
     </Form>
       </Col>
        
        <Col md={1} className="mb-3">
     <div className="divider">
     <div className="sep">
        
     </div>
   </div>
 </Col>
       
       
       <Col md={6} className="text-center" id="formCadastroEstabelecimentos">
       <h5 className="mb-5">Cadastre seu estabelecimento e atraia amantes de cervejas e vinhos ao seu redor com Geolocalização!</h5>
          <Form onSubmit={onSubmitCadastro(handleSubmitCadastro)} >
         <Row>
             <Col sm={6} className="mb-3">
                  
                 <Form.Control 
                  
                 type="text" 
                 id="nome_estabelecimento" 
                 {...registerCadastro("nome_estabelecimento")}
                 placeholder="Nome do Comércio" 
                 defaultValue=""
                 />
                 <span style={{'color':'red'}}>{errorsCadastro?.nome_estabelecimento?.message}</span>
             </Col>
             <Col sm={6} className="mb-3">
                 
                  
                 <Form.Select aria-label="Informe o tipo do seu comércio" id="tipo_estabelecimento" {...registerCadastro("tipo_estabelecimento")} >
                   {TIPO_COMERCIO.map(c => <option key={c}>{c}</option>)}
                 </Form.Select>
             </Col>
         </Row>
         <Row>
             <Col sm={6} className="mb-3">
                  
                 <Form.Control
           required
           type="text"
           placeholder="Rua"
           id={uuidv4()}
           {...registerCadastro("rua")}
           defaultValue=""
         />
          <span style={{'color':'red'}}>{errorsCadastro?.rua?.message}</span>
             </Col>
             <Col sm={3} className="mb-3">
                  
                 <Form.Control
           required
           type="text"
           placeholder="Numero"
           id={uuidv4()} 
           {...registerCadastro("numero")}
           defaultValue=""
           as={InputMask}
           mask="999999"
         />
          <span style={{'color':'red'}}>{errorsCadastro?.numero?.message}</span>
             </Col>
            
             
         </Row>
         <Row>
             <Col sm={6} className="mb-3">
                  <Form.Select value={selectedEstado} aria-label="Selecione o Estado em que seu comércio está localizado" id="estado"
                   {...registerCadastro("estado")}
                   onChange={handleEstadoUpdate}
                  >
                   <option value=''>Informe o Estado</option>
                   <option value="AC">Acre</option>
                 <option value="AL">Alagoas</option>
                 <option value="AP">Amapá</option>
                 <option value="AM">Amazonas</option>
                 <option value="BA">Bahia</option>
                 <option value="CE">Ceará</option>
                 <option value="DF">Distrito Federal</option>
                 <option value="ES">Espírito Santo</option>
                 <option value="GO">Goiás</option>
                 <option value="MA">Maranhão</option>
                 <option value="MT">Mato Grosso</option>
                 <option value="MS">Mato Grosso do Sul</option>
                 <option value="MG">Minas Gerais</option>
                 <option value="PA">Pará</option>
                 <option value="PB">Paraíba</option>
                 <option value="PR">Paraná</option>
                 <option value="PE">Pernambuco</option>
                 <option value="PI">Piauí</option>
                 <option value="RJ">Rio de Janeiro</option>
                 <option value="RN">Rio Grande do Norte</option>
                 <option value="RS">Rio Grande do Sul</option>
                 <option value="RO">Rondônia</option>
                 <option value="RR">Roraima</option>
                 <option value="SC">Santa Catarina</option>
                 <option value="SP">São Paulo</option>
                 <option value="SE">Sergipe</option>
                 <option value="TO">Tocantins</option>
                 </Form.Select>
                 <span style={{'color':'red'}}>{errorsCadastro?.estado?.message}</span>
             </Col>
             <Col sm={6} className="mb-3">
                  
                 <Form.Select aria-label="Selecione a Cidade em que seu comércio está localizado" id="cidade"  {...registerCadastro("cidade",{required:true})}>
                 
                   {cidaesDoEstadoEscolhido[0].cidades.map((cidade) => (
                     <option key={cidade.codigo_ibge} value={  cidade.codigo_ibge === 'Selecione_a_Cidade' ? "" : cidade.nome}>{cidade.nome}</option>
                   ))}
                  
                 </Form.Select>
                 <span style={{'color':'red'}}>{errorsCadastro?.cidade?.message}</span>
             </Col>
         </Row>
         <Row>
         <Col sm={6} className="mb-3">
                 
                 <Form.Control
           required
           type="text"
           placeholder="Bairro"
           id={uuidv4()} 
           {...registerCadastro("bairro")}
           defaultValue=""
         />
          <span style={{'color':'red'}}>{errorsCadastro?.bairro?.message}</span>
             </Col>
         <Col sm={6} className="mb-3">
                 
                 <Form.Control
           required
           type="text"
           placeholder="Cep"
           as={InputMask}
           mask="99999-999"
           id={uuidv4()} 
           {...registerCadastro("cep")}
           
         />
          <span style={{'color':'red'}}>{errorsCadastro?.cep?.message}</span>
             </Col>
         </Row>
         <Row>
             <Col sm={6} className="mb-3">
                 
                  <Form.Control
       required
       type="text"
       id={uuidv4()} 
       {...registerCadastro("cnpj")}
       as={InputMask}
       mask="99.999.999/9999-99"
       placeholder="CNPJ"
       aria-describedby="Informe CNPJ"
     />
      <span style={{'color':'red'}}>{errorsCadastro?.cnpj?.message}</span>
             </Col>
             <Col sm={6} className="mb-3">
                  
                 <Form.Control
       required
       type="text"
       id="telefone" 
       {...registerCadastro("telefone")}
       placeholder="WhatsApp do Vendedor"
       as={InputMask}
       mask="(99)99999-9999"
       aria-describedby="Informe Celular"
       
     />
      <span style={{'color':'red'}}>{errorsCadastro?.telefone?.message}</span>
             </Col>
         </Row>
         <Row>
             <Col sm={6} className="mb-3">
                 
                  <Form.Control
       required
       type="email"
       id="email" 
       {...registerCadastro("email",{required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i})}
       placeholder="Email"
       aria-describedby="Informe Email"
       defaultValue=''
     />
      <span style={{'color':'red'}}>{errorsCadastro?.email?.message}</span>
             </Col>
             <Col sm={6} className="mb-3">
                  
                 <Form.Control
       required
       type="password"
       id="senha" 
       {...registerCadastro("senha")}
       placeholder="Senha"
       aria-describedby="Informe Senha"
       defaultValue=''
     />
      <span style={{'color':'red'}}>{errorsCadastro?.senha?.message}</span>
             </Col>
         </Row>
         
          <Row>
          <Button variant="primary" type="submit" className="px-4 float-right">Cadastrar</Button>
          </Row>

          <Row>
          <label style={{ fontSize: '12px' }}>Ao clicar em Cadastrar você concorda com <a href="/politicaPrivacidade" target='_blank'>Política de Privacidade</a> e <a href="/termosUso" target='_blank'>Termos de Uso</a></label>
          </Row>

         
         
     </Form>
       </Col>
     </Row>
   
</Container>
</section>
   );
}

export default SectionFormsCadastroLoginEstabelecimento;
















