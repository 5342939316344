import React, { useState, useEffect, useRef } from 'react';
import { Carousel } from 'react-bootstrap';
import TypewriterIA from './TypewriterIA'; // Importe o componente TypewriterIA

const SectionIaGeoGet = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const typewriterRef = useRef(null);

  const questionsAndAnswers = [
    { question: 'Como posso aumentar as vendas de vinhos em meu restaurante?', answer: 'A IA sugere oferecer opções de harmonização com pratos, promoções em vinhos de diferentes regiões e criar eventos de degustação.' },
    { question: 'Quais as tendências atuais de cervejas artesanais?', answer: 'As cervejas artesanais estão em alta, especialmente as IPAs e as cervejas sour. Investir em marcas locais pode atrair um público fiel.' },
    { question: 'Como devo organizar o layout das prateleiras para destacar os produtos?', answer: 'Organize os produtos de acordo com categorias e faixas de preço, utilizando sinalização clara e iluminação para destacar os itens em promoção.' },
    
    // Novas perguntas e respostas
    { question: 'Qual a melhor estratégia para divulgar promoções de cervejas artesanais?', answer: 'Utilize as redes sociais para alcançar seu público-alvo, promovendo postagens patrocinadas e colaborando com influenciadores locais que apreciam cervejas artesanais.' },
    { question: 'Como escolher os melhores fornecedores de vinhos?', answer: 'Busque fornecedores que ofereçam vinhos premiados e que possam fornecer suporte com treinamentos e materiais promocionais. Negocie condições que garantam um bom custo-benefício.' },
    { question: 'Qual a importância de oferecer opções de vinhos orgânicos?', answer: 'Os vinhos orgânicos estão cada vez mais populares entre consumidores conscientes. Ter essas opções no cardápio pode atrair um público que busca produtos mais saudáveis e sustentáveis.' },
    { question: 'Como fidelizar clientes que compram cervejas premium?', answer: 'Crie um clube de assinaturas para cervejas premium, oferecendo benefícios exclusivos como descontos, brindes e acesso antecipado a lançamentos.' },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuestionIndex((prevIndex) => (prevIndex + 1) % questionsAndAnswers.length);
    }, 8000); // Tempo entre cada troca de slide (8 segundos)

    return () => clearInterval(interval);
  }, [questionsAndAnswers.length]);

  useEffect(() => {
    if (typewriterRef.current) {
      typewriterRef.current.reset();
    }
  }, [currentQuestionIndex]);

  return (
    <section style={{ padding: '50px 0', backgroundColor: '#f8f9fa' }}>
      <div className="container">
        <h2 style={{ textAlign: 'center', marginBottom: '30px', fontSize: '2em', fontWeight: 'bold', color: '#343a40' }}>
          Conheça a IA Geoget: Sua Assistente Especializada no Segmento de Bares, Restaurantes, Botecos, Cervejarias, e outros.
        </h2>
        <p style={{ textAlign: 'center', marginBottom: '50px', fontSize: '1.2em', color: '#6c757d' }}>
          A IA Geoget foi treinada especificamente para o segmento de cervejas, vinhos, Bares, restaurantes, Botecos, Cervejarias... 
          Ela pode ajudar a esclarecer dúvidas sobre esses produtos, além de fornecer insights valiosos para o seu negócio.
        </p>
        <Carousel activeIndex={currentQuestionIndex} controls={false} indicators={false} interval={null}>
          {questionsAndAnswers.map((qa, index) => (
            <Carousel.Item key={index}>
              <div style={{ minHeight: '200px', textAlign: 'center' }}>
                <h4 style={{ marginBottom: '20px', fontStyle: 'italic', color: '#007bff' }}>
                  "{qa.question}"
                </h4>
                <TypewriterIA 
                  ref={typewriterRef} 
                  text={qa.answer} 
                  speed={50} 
                />
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </section>
  );
};

export default SectionIaGeoGet;
