
import React,{ useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
 import {
    Container,
    Row,
    Figure,
	Col,
	Card 
} from "react-bootstrap";
import ImagemRadarBuscandoBebidas from '../assets/images/radarBuscandoBebidas.webp';



const SectionLibereSuaLocalizacao = ({iniciaVerificacaoCoordenadas,location}) => {
	

	const [mensagem, setMensagem] = useState('Verificando suas Coordenadas...');

	/*useEffect(() => {

		if(props.permissionState === 'prompt'){
			setMensagem('O aplicativo precisa de permissão para acessar sua localização. Você verá um aviso solicitando permissão.');
		}
		else if(props.permissionState === 'denied'){
			setMensagem('O acesso à sua localização foi negado. Por favor, permita o acesso nas configurações do navegador para usar todos os recursos.');
		}else if(props.permissionState === 'granted' && props.statusGetLocaton === 'error'){
		setMensagem('Ocorreu um erro inesperado ao tentar acessar sua localização. Tente novamente .')
		
	}else if(props.permissionState === 'granted' && props.statusGetLocaton === 'timeout'){
		setMensagem('O pedido de localização expirou. Verifique sua conexão de rede ou GPS e tente novamente.')
		
	}else if(props.permissionState === 'granted' && props.statusGetLocaton === 'unavailable'){
		setMensagem('As informações de localização não estão disponíveis. Verifique se o GPS está ativado e tente novamente.')
		}

			 
	},[props.permissionState]);*/

	/*useEffect(() => {

		if(props.permissionState === 'prompt'){
			setMensagem('O aplicativo precisa de permissão para acessar sua localização. Você verá um aviso solicitando permissão.');
		}
		else if(props.permissionState === 'denied'){
			setMensagem('O acesso à sua localização foi negado. Por favor, permita o acesso nas configurações do navegador para usar todos os recursos.');
		}else if(props.permissionState === 'granted' && props.statusGetLocaton === 'error'){
		setMensagem('Ocorreu um erro inesperado ao tentar acessar sua localização. Tente novamente .')
		
	}else if(props.permissionState === 'granted' && props.statusGetLocaton === 'timeout'){
		setMensagem('O pedido de localização expirou. Verifique sua conexão de rede ou GPS e tente novamente.')
		
	}else if(props.permissionState === 'granted' && props.statusGetLocaton === 'unavailable'){
		setMensagem('As informações de localização não estão disponíveis. Verifique se o GPS está ativado e tente novamente.')
		}

			 
	});*/

	// Definição de estilos inline
	const styles = {
		section: {
		  backgroundColor: '#f9f9f9', // Fundo suave
		  padding: '3rem 0', // Padding vertical para espaçamento
		},
		title: {
		  color: '#333', // Cor do texto
		  fontWeight: 'bold', // Texto em negrito
		  textAlign: 'center', // Alinhamento centralizado
		  marginBottom: '2rem', // Margem inferior
		},
		spinnerRow: {
		  justifyContent: 'center', // Centraliza o spinner
		  marginBottom: '2rem', // Margem inferior para o espaçamento
		},
		card: {
		  border: 'none', // Remove a borda padrão do Card
		  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Sombra suave
		  borderRadius: '0.75rem', // Bordas arredondadas
		  overflow: 'hidden', // Evita que a imagem "vaze" do card
		},
		image: {
		  width: '100%', // Responsividade
		  objectFit: 'cover', // Mantém a proporção da imagem
		  maxHeight: '300px', // Altura máxima para a imagem
		},
		caption: {
		  textAlign: 'center', // Centraliza o texto da legenda
		  padding: '1rem', // Padding interno para espaçamento
		  fontSize: '1rem', // Tamanho da fonte
		  color: '#555', // Cor suave para o texto
		},
	  };

    return (
       /*
        <section style={styles.section}>
      <Container>
        <Row>
          <Col xs={12}>
            <h3 style={styles.title}>{mensagem}</h3>
          </Col>
        </Row>
        <Row style={styles.spinnerRow}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Carregando...</span>
          </Spinner>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <Card style={styles.card}>
              <Figure className="m-0">
                <Figure.Image
                  src={ImagemRadarBuscandoBebidas}  // Substitua pelo caminho correto da imagem
                  alt="GeoGet buscando promoções de vinhos e cervejas"
                  style={styles.image}
                />
                <Figure.Caption style={styles.caption}>
                  GeoGet está buscando as melhores promoções de vinhos e cervejas ao seu redor.
                </Figure.Caption>
              </Figure>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
	*/

	<>
      <section style={{ backgroundColor: '#f9f9f9', padding: '3rem 0' }}>
    <Container>
      
      {
        
      location.permissionState === 'denied' || location.permissionState === 'prompt' 
       ?
       (
        <>
        <Row>
  <Col xs={12}>
    {/* Alerta de erro de localização */}
    <p style={{ 
      color: '#333', // Cor neutra e legível
      textAlign: 'center', 
      marginBottom: '1.5rem', 
      fontSize: '1rem', // Tamanho da fonte mais legível
      lineHeight: '1.5', // Aumenta o espaçamento entre linhas para melhor leitura
      maxWidth: '600px', // Limita a largura para não ficar muito extenso
      margin: '0 auto' // Centraliza o texto
    }}>
    Note que o <strong>Safari</strong> e o <strong>Opera</strong> podem bloquear o acesso à localização em algumas situações.
    </p>

	<div style={{
      backgroundColor: '#ffef96',
      color: '#333',
      padding: '1rem',
      borderRadius: '0.5rem',
      textAlign: 'center',
      marginBottom: '1rem',
      boxShadow: '0px 4px 6px rgba(0,0,0,0.1)'
    }}>
      <strong>Atenção:</strong> Não foi possível acessar sua localização.
      <p style={{ margin: '0.5rem 0 0' }}>
        <p>1- Habilite a geolocalização nas configurações do navegador;</p>
		<p>2- Tente novamente abaixo.</p>
      </p>
    </div>

    {/* Botão de ação */}
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1.5rem' }}>
      <button
        onClick={iniciaVerificacaoCoordenadas}
        style={{
          backgroundColor: '#28a745', // Cor mais amigável (verde) que indica ação positiva
          color: '#fff', // Contraste adequado para acessibilidade
          padding: '0.75rem 1.5rem', 
          borderRadius: '0.5rem',
          border: 'none', 
          fontSize: '1.1rem', // Tamanho de fonte confortável para leitura
          cursor: 'pointer', 
          transition: 'background-color 0.3s ease',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Sombra sutil para destaque
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#218838')}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#28a745')}
      >
        Habilite e tente novamente
      </button>
    </div>
  </Col>
</Row>

              </>
       )
       :
       (


        (location.permissionState === 'unknown' || location.permissionState === 'error') ?
        //Erro  -Pode ser problema de Browser ou pode tentar novamente

        (
          <Row>
  <Col xs={12}>
    {/* Alerta de erro de localização */}
    <p style={{ 
      color: '#333', // Cor neutra e legível
      textAlign: 'center', 
      marginBottom: '1.5rem', 
      fontSize: '1rem', // Tamanho da fonte mais legível
      lineHeight: '1.5', // Aumenta o espaçamento entre linhas para melhor leitura
      maxWidth: '600px', // Limita a largura para não ficar muito extenso
      margin: '0 auto' // Centraliza o texto
    }}>
    <p>Alguns navegadores, como <strong>Safari</strong> e o <strong>Opera</strong> podem bloquear o acesso à localização.</p>
   
    </p>

	<div style={{
      backgroundColor: '#ffef96',
      color: '#333',
      padding: '1rem',
      borderRadius: '0.5rem',
      textAlign: 'center',
      marginBottom: '1rem',
      boxShadow: '0px 4px 6px rgba(0,0,0,0.1)'
    }}>
      <strong>Atenção:</strong> Não foi possível acessar sua localização.
      <p style={{ margin: '0.5rem 0 0' }}>
      <p>1- Tente Liberar o acesso a Localização, e,após, clique no Botão abaixo</p>
      <p>2- Ou, acesse a GeoGet com outro Navegador</p>
      </p>
    </div>

    {/* Botão de ação */}
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1.5rem' }}>
      <button
        onClick={iniciaVerificacaoCoordenadas}
        style={{
          backgroundColor: '#28a745', // Cor mais amigável (verde) que indica ação positiva
          color: '#fff', // Contraste adequado para acessibilidade
          padding: '0.75rem 1.5rem', 
          borderRadius: '0.5rem',
          border: 'none', 
          fontSize: '1.1rem', // Tamanho de fonte confortável para leitura
          cursor: 'pointer', 
          transition: 'background-color 0.3s ease',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Sombra sutil para destaque
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#218838')}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#28a745')}
      >
        Habilite e tente novamente
      </button>
    </div>
  </Col>
</Row>
        )



        :
        //Buscando 
       ( <>
         <Row>
        <Col xs={12}>
          
            <h3 style={{ color: '#333', fontWeight: 'bold', textAlign: 'center', marginBottom: '2rem' }}>
              {mensagem}
            </h3>
          
        </Col>
      </Row>
  
      <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Carregando...</span>
        </Spinner>
      </Row>
      
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={6}>
          <Card style={{ border: 'none', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '0.75rem', overflow: 'hidden' }}>
            <Figure className="m-0">
              <Figure.Image
                src={ImagemRadarBuscandoBebidas}
                alt="GeoGet buscando promoções de vinhos e cervejas"
                style={{ width: '100%', objectFit: 'cover', maxHeight: '300px' }}
              />
              <Figure.Caption style={{ textAlign: 'center', padding: '1rem', fontSize: '1rem', color: '#555' }}>
                GeoGet está buscando as melhores promoções de vinhos e cervejas ao seu redor.
              </Figure.Caption>
            </Figure>
          </Card>
        </Col>
      </Row>
        </>)
       )  
      }
      
      
     
    </Container>
  </section>
  </>




    );
}

export default SectionLibereSuaLocalizacao;





