import {Container, Row, Col, Card, ListGroup, Button, Nav} from 'react-bootstrap';
import { Link } from "react-router-dom";





const SectionSobreGeoGet = () => {
    return (
        <Container>
     <Row>
     <Col>
        <div style={{ margin: '20px', padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: 1.6 }}>
      <h1>Sobre a GeoGet</h1>

      <h2>Transformando a Geolocalização em Oportunidades</h2>
      <p>
        Na GeoGet, estamos revolucionando a forma como você descobre promoções e ofertas em sua região. Utilizando tecnologia de ponta em geolocalização, conectamos você com os melhores estabelecimentos ao seu redor, proporcionando uma experiência única e personalizada.
      </p>

      <h2>Nossa Missão</h2>
      <p>
        Nossa missão é simples: facilitar a sua busca por ofertas incríveis e trazer à sua porta as melhores oportunidades de compra. Queremos que cada usuário tenha acesso rápido e fácil a promoções exclusivas de bebidas, para que você possa aproveitar ao máximo os melhores preços e variedades sem complicação.
      </p>

      <h2>Como Funciona</h2>
      <p>
        Imagine um mundo onde você pode localizar as melhores promoções de cervejas e vinhos em questão de segundos. A GeoGet utiliza um sistema avançado de rastreamento em tempo real para informar você sobre as melhores ofertas próximas, atualizando constantemente as informações conforme você se desloca. Com a nossa plataforma, encontrar a bebida perfeita pelo preço ideal nunca foi tão fácil.
      </p>

      <h2>Por que Escolher a GeoGet?</h2>
      <ul>
        <li><strong>Tecnologia de Ponta:</strong> Nosso sistema de geolocalização é preciso e confiável, garantindo que você sempre encontre as melhores ofertas perto de você.</li>
        <li><strong>Atualizações em Tempo Real:</strong> Receba notificações instantâneas sobre promoções e ofertas enquanto você se move, garantindo que você nunca perca uma oportunidade.</li>
        <li><strong>Parcerias Exclusivas:</strong> Trabalhamos com os melhores estabelecimentos para trazer ofertas exclusivas que você não encontrará em nenhum outro lugar.</li>
        <li><strong>Experiência Personalizada:</strong> Personalize suas preferências e receba recomendações que atendam exatamente ao que você procura.</li>
      </ul>

      <h2>Junte-se a Nós</h2>
      <p>
        Na GeoGet, acreditamos que a inovação começa com a simplicidade. Nossa plataforma foi criada para ser intuitiva e fácil de usar, garantindo que todos possam aproveitar ao máximo as vantagens da geolocalização. Junte-se à nossa comunidade e descubra um mundo de ofertas e promoções que estão apenas esperando para serem descobertas.
      </p>
    </div>
    </Col>
      </Row>

      <Row className="my-5">
            <Col>
             
              <h1 className="text-center mt-4">O que a GeoGet Faz</h1>
              <p className="text-center mt-3">
                Descubra promoções exclusivas de cervejas e vinhos nos melhores estabelecimentos perto de você. Aproveite agora!
              </p>
              <p className="text-center mt-2">
              <strong>GeoGet é um sistema avançado baseado em geolocalização projetado para conectar clientes a estabelecimentos próximos que oferecem promoções de cerveja e vinho. </strong>
              Tem como objetivo atrair mais clientes para diversos estabelecimentos, como bares, restaurantes, lojas de bebidas, mercados, distribuidoras de bebidas, adegas, lojas de bebidas 
              GeoGet oferece vantagens significativas para estabelecimentos, sistemas ERP e usuários/leads que apreciam cervejas e vinhos, melhorando sua experiência geral, tornando-se uma ferramenta essencial para o mercado de bebidas.
              </p>
            </Col>
          </Row>
    
          {/* Vantagens para Usuários */}
          <Row className="my-5">
            <Col>
              <h2>Vantagens para Usuários</h2>
              <p>Explore as vantagens que a GeoGet oferece aos consumidores, ajudando você a encontrar as melhores promoções e bebidas próximas de forma rápida e fácil.</p>
              <Card className="mb-4 shadow-sm">
                <Card.Body>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Descubra bebidas próximas facilmente.</ListGroup.Item>
                    <ListGroup.Item>Pesquise cervejas e vinhos por proximidade e preferência.</ListGroup.Item>
                    <ListGroup.Item>Veja as bebidas mais populares na sua região.</ListGroup.Item>
                    <ListGroup.Item>Acesse comparações de preços das bebidas.</ListGroup.Item>
                    <ListGroup.Item>Participe da Rota da Cerveja e da Rota do Vinho.</ListGroup.Item>
                    <ListGroup.Item>Fale diretamente com os vendedores dos estabelecimentos.</ListGroup.Item>
                    <ListGroup.Item>Motoristas de aplicativos podem ganhar porcentagens sobre as indicações.</ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
    
              {/* Botão de Cadastro para Usuários */}
              <div className="text-center">
            <Nav.Link href="/cadastroUsuarios"> <Button variant="primary" size="lg" className="mt-3">
                <i className="fa fa-user"></i> Cadastre-se como Usuário
              </Button></Nav.Link>
              </div>
            </Col>
          </Row>
    
          {/* Vantagens para Estabelecimentos */}
          <Row className="my-5">
            <Col>
              <h2>Vantagens para Estabelecimentos</h2>
              <p>A GeoGet é a plataforma ideal para você promover suas bebidas e atrair mais clientes para o seu estabelecimento. Confira as principais vantagens:</p>
              <Card className="mb-4 shadow-sm">
                <Card.Body>
                  <ListGroup variant="flush">
                    <ListGroup.Item>Promova suas bebidas para clientes próximos.</ListGroup.Item>
                    <ListGroup.Item>Acesse insights de concentração de clientes.</ListGroup.Item>
                    <ListGroup.Item>Identifique os melhores horários para promoções.</ListGroup.Item>
                    <ListGroup.Item>Analise os preços da região.</ListGroup.Item>
                    <ListGroup.Item>Veja quais produtos atraem mais clientes.</ListGroup.Item>
                    <ListGroup.Item>Faça parte da rede de motoristas de aplicativo.</ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
    
              {/* Botão de Cadastro para Estabelecimentos */}
              <div className="text-center">
             

            <Nav.Link href="/cadastroEstabelecimentos"> <Button variant="success" size="lg" className="mt-3">
                <i className="fa fa-store"></i> Cadastre seu Estabelecimento
              </Button></Nav.Link>
              </div>
            </Col>
          </Row>
      </Container>
    );
}

export default SectionSobreGeoGet;