import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom"; // Usando React Router para navegação

 


const SectionCardsCervejaVinho = () => {


 

    return (
      <section
      className="py-5"
      style={{
        backgroundColor: "#f1f1f1",
        borderRadius: "15px", // Cantos arredondados
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Sombra suave
        padding: "20px",
      }}
    >
      <Container fluid>
        <Row className="align-items-center text-center">
          {/* Conteúdo principal */}
          <Col md={12}>
          <h2
  style={{
    fontSize: window.innerWidth < 576 ? "1.2rem" : window.innerWidth < 768 ? "1.6rem" : "2rem", // Ajuste de tamanho para telas menores
    fontWeight: "bold",
    color: "#333",
    marginBottom: "20px",
    lineHeight: "1.3", // Melhora a leitura e espaçamento entre linhas
    textAlign: "center", // Centraliza o texto para melhorar a apresentação
  }}
>
  <Link
    to="/sobre" // Defina a rota para onde deseja redirecionar
    style={{
      textDecoration: "none",
      color: "#007bff",
      transition: "color 0.3s ease", // Efeito de transição suave
    }}
    onMouseEnter={(e) => (e.currentTarget.style.color = "#0056b3")}
    onMouseLeave={(e) => (e.currentTarget.style.color = "#007bff")}
  >
    Compare preços e descubra as bebidas mais desejadas em tempo real. Cadastre-se na GeoGet e garanta economia em cada saída.
  </Link>
</h2>

          </Col>
        </Row>
      </Container>
    </section>
    
    );
}

export default SectionCardsCervejaVinho;



