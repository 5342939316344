import React from 'react';
import { useForm } from 'react-hook-form';
import apiAxios from '../services/api';
import MyVerticallyCenteredModal from './modals/verticallyCenteredModal';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const SectionEsqueciSenha = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [modalShow, setModalShow] = React.useState(false);
  const [textBodyModal, setTextBodyModal] = React.useState('');
  const [titleTextBodyModal, setTitleTextBodyModal] = React.useState('');
  const [titleModal, setTitleModal] = React.useState('');

  const onSubmit = async (data) => {

        const { email, userType } = data;
        let urlRecuperarSenha = '';
        if(userType === 'estabelecimento'){
          urlRecuperarSenha =  '/estabelecimento/recuperacaoSenha';
        }else if(userType === 'usuario'){
          urlRecuperarSenha = '/usuario/recuperacaoSenha';
        }else{
          urlRecuperarSenha = '/parceiro/recuperacaoSenha';
        }

        apiAxios.post(urlRecuperarSenha, {
            'email' : email
           })
           .then((response) => {
             
            setTextBodyModal(response.data.msg);
            setTitleTextBodyModal('Sobre sua senha...');
            setTitleModal('Atenção:');
            setModalShow(true);

           })
           .catch((erro) => {
             console.log(JSON.stringify(erro));
             alert('Ocorreu um erro ao solicitar a redefinição de senha. Por favor, tente novamente.');
            
           });

   
  };

  return ( 
    <div>
    <MyVerticallyCenteredModal
    show={modalShow}
    onHide={() => setModalShow(false)}
    textBodyModal={textBodyModal}
    titleTextBodyModal={titleTextBodyModal}
    titleModal={titleModal}
  />   


<section className="w-100 px-4 py-5 text-center">
   
<Container>
      <h2>Redefinição de Senha</h2>
       
     
       
      <Row className="justify-content-center">
  <Col md={6}>
    <Form onSubmit={handleSubmit(onSubmit)} className="p-4 bg-light rounded shadow-sm">
      <h4 className="mb-4 text-center">Redefinir Senha</h4>

      <Form.Group className="mb-3">
        <Form.Label>Email:</Form.Label>
        <Form.Control
          type="email"
          {...register('email', {
            required: 'Email é obrigatório',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Email inválido'
            }
          })}
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Label>Tipo de Usuário:</Form.Label>
        <Form.Select aria-label="Informe o tipo de usuário" {...register('userType', { required: 'Tipo de usuário é obrigatório' })} isInvalid={!!errors.userType}>
          <option value="">Você é:</option>
          <option value="estabelecimento">Estabelecimento</option>
          <option value="usuario">Usuário</option>
          <option value="sistemaParceiro">Sistema Parceiro</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">{errors.userType?.message}</Form.Control.Feedback>
      </Form.Group>

      <Button variant="primary" type="submit" className="w-100">Solicitar Redefinição de Senha</Button>
    </Form>
  </Col>
</Row>

    
    </Container>
     </section>

    </div>
  );
};

export default SectionEsqueciSenha;