import Container  from "react-bootstrap/Container";
import Row  from "react-bootstrap/Row";
import Form  from "react-bootstrap/Form";
import Col  from "react-bootstrap/Col";
import Card  from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Label from "react-bootstrap/FormLabel";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { object, string } from "yup";
import React, { useState, useRef } from "react";
import { useEffect,useContext } from "react";
import { getDataClientLocalStorage, getDataEstabelecimentoLocalStorage, getTokenIsAuthenticatedEstabelecimento, getTokenJwtFormLocalStorage, isAuthenticated, logout } from "../services/auth";
import apiAxios from "../services/api";
import { Modal } from 'react-bootstrap';
import { montaAreaProdutos } from "../hooks/montaProdutos";
import FileDownload from "js-file-download";
import { categorias } from "../hooks/useCategorias";
import { marcasCarros } from "../hooks/useMarcasCarros";
import { modelosCarros } from "../hooks/useModelosCarros";
//import { socketEstabelecimento } from "../context/socket";
import { socketEstabelecimento } from "../context/socket";
import {format,parseISO } from "date-fns";
import MapLeaflet from "./mapLeaflet";
import kilometrosInput from "./inputs/kilometros";
import { v4 as uuidv4 } from 'uuid';
import MyVerticallyCenteredModal from "./modals/verticallyCenteredModal";



const SectionFormCarrosEdicaoCarros = (props) => {

  const [modalShow, setModalShow] = React.useState(false);
  const [textBodyModal, setTextBodyModal] = React.useState('');
  const [titleTextBodyModal, setTitleTextBodyModal] = React.useState('');
  const [titleModal, setTitleModal] = React.useState('');

  const [alteracaoProduto , setAlteracaoProduto] = useState(false);
  const tokenJwtFormRef = useRef();
  const [selectedMarca,setSelectedMarca] = useState('');
  const [modelos , setModelos] = useState([{'nome':'Informe a Marca','codigo':''}]);

  const [selectedModelo,setSelectedModelo] = useState('');
  const [valKmRodados,setValKmRodados] = useState("");
  const [labelCheckBlindado , setLabelCheckBlindado] = useState('Não Blindado');
  const [blindadoCheck , setBlindadoCheck] = useState(false);
  const [desabilitarBotaoPublicar , setDesabilitarBotaoPublicar] = useState(false);
  const[nomeUsuario , setNomeUsuario] = useState('');
  const [produtos, setProdutos] = useState([]);
  const [areaProdutos , setAreaProdutos] = useState([]);
  const [tokenJwtForm , setTokenJwtForm] = useState('');
  const [marcaProduto , setMarcaProduto] = useState('');
  const [kilometrosRodados , setKilometrosRodados] = useState('');
  const [modeloProduto , setModeloProduto] = useState();
  const [anoModelo , setAnoModelo] = useState('');
  const [anoFabricacao , setAnoFabricacao] = useState('');
  const [precoPorProduto , setPrecoPorProduto] = useState('');
  const [versao , setVersao] = useState('');
  const [descricaoProduto , setDescricaoProduto] = useState('');
  const [idProduto , setIdProduto] = useState('');
  const [cor , setCor] = useState('');
  const [tipoUsuario , setTipoUsuario] = useState('');
  const [qrCode , setQrcode] = useState('');

  const scrollAtualizacao = useRef();
const executeScrollAtualizacao = () => scrollAtualizacao.current.scrollIntoView()   ;

function montaPordutosTela ()  {

  const arr = [];

  var arrayComProdutoMontados =  produtos.map((produto) =>
   
              <Col sm={6} className="mt-2">
              {getCardsCarros(produto)}
              </Col>
           
  );

  var rr =( <div><Row className="mt-4" >
         
  {arrayComProdutoMontados}
 
          </Row></div>);

  arr.push(rr);
  

  return arr;

}

const resetMarcasModelos = ()=>{
  setSelectedMarca('Informe a Marca');
  setModelos([{'nome':'Informe a Marca','codigo':''}]);
}

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //sempre que algum campo é alterado é chama esta funcao
    }

    const currencyMask = (e:React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value;
      value = value.replace(/\D/g,"");
      value = value.replace(/(\d)(\d{2})$/, "$1,$2");
      value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
      e.target.value = value;
      return e;
    }
    
    const currencyMaskTransform = (value:string) =>{
      
      value = value.replace(/\D/g,"");
      value = value.replace(/(\d)(\d{2})$/, "$1, $2");
      value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
       
      return value;
    }

  const handleChangeBlindado = (e)=>{
  
  
    if(e.target.checked === true || e.target.checked === 'true'){
      alterarEstadoCheckBlindado(true);
    }
    else{
      alterarEstadoCheckBlindado(false);
    }
  }

  const alterarEstadoCheckBlindado = (estadoBooleano)=>{

    if(estadoBooleano){
      setLabelCheckBlindado('Blindado');
      setBlindadoCheck(true);
    }
    else{
      setLabelCheckBlindado('Não Blindado');
      setBlindadoCheck(false);
    }
  
  }

  const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const removeNonNumeric = num => num.toString().replace(/[^0-9]/g, "");
const handleChangeKm = (event) => {
   
  const valorCampo = addCommas(removeNonNumeric(event.target.value));

  if(valorCampo === '' || (valorCampo.length <= 7 && parseFloat(valorCampo) <= 999.999) ){ 
    setValKmRodados(valorCampo);
   }
  
}

  const handleModeloUpdate = (event) => {
    setSelectedModelo(event.target.value);
  }

  const handleMarcaUpdate = (event) => {
    setSelectedMarca(event.target.value);
     
    let nomeDaMarca = event.target.value;
    atualizaListaModelos(nomeDaMarca);
  }

  const atualizaListaModelos = (marca)=>{

    if(marca == ''){
      setModelos([{'nome':'Informe a Marca','codigo':''}]);
    }else{

      const modelosCarrosEncontrados = modelosCarros.filter(x => x.nome === marca);
    
    if(null !== modelosCarrosEncontrados){
      setModelos(modelosCarrosEncontrados[0].modelos);
    }

    }

  }

  useEffect(() => { 

    var urlConsultaProdutos = "/produto/consultar";

    if(props.tipoUsuario === 'estabelecimento'){
      setTipoUsuario('estabelecimento');
      const dadosUsuario = getDataEstabelecimentoLocalStorage();
      setNomeUsuario(dadosUsuario.nome);
      setTokenJwtForm(getTokenJwtFormLocalStorage());
      urlConsultaProdutos = "/produto/consultar";
    }else{
      setTipoUsuario('usuario');
      setTokenJwtForm(getDataClientLocalStorage().tokenUseInForms);
      setNomeUsuario(getDataClientLocalStorage().nome);
      urlConsultaProdutos = "/produto/consultarCliente" ;
    }

    //AQUI FAZER A CONSULTA
    setAlteracaoProduto(false);


    

    apiAxios.post(urlConsultaProdutos, {
     'consultaProdutos' : true
    })
    .then((response) => {
     
     //console.log(response);

     if(response.data.success && response.data.auth ){

       
       //imgQrcode.src = response.data.qrcode;
       if( Array.isArray(response.data.produtosEstabelecimento) ){

          //aqui fazer a logica de setar os produtos na tela
       var produtos = response.data.produtosEstabelecimento;
       setProdutos(produtos);

       }
      
       if(response.data.qtdeProdutosCadastrados < 5 ){
         setDesabilitarBotaoPublicar(false);
       }
       else if(response.data.qtdeProdutosCadastrados >= 5){
         setDesabilitarBotaoPublicar(true);
       }

     }
     
     
    })
    .catch((erro) => {
      
     if(!erro.response.data.success && !erro.response.data.auth && erro.response.data.message === 'Token Expirado'){
       logoff();
     }

     //console.log(erro);

    });



  },[]);

  useEffect(() => { // esse é responsável em pegar as alterações
    
    // var areaMontada = montaAreaProdutos(produtos);
   
   const arProds = montaPordutosTela ();

   setAreaProdutos(arProds) ;
   
   //console.log('AQUI SETAR O JWT PARA FORM DE EDICAO DE PRODUTOS => '+ getTokenJwtFormLocalStorage());
 }, [produtos]);

  const schemaFormNovoEdicao = object().shape({
    marca:string().required("Marca Obrigatório"),
    modelo:string().required("Modelo Obrigatório"),
    descricao:string().required('Descrição Obrigatório').min(10,'Mínimo 10 caracteres').max(30,'Máximo 30 caracteres'),
    ano_modelo:string().required('Ano Modelo, Obrigatorio'),
    ano_fabricacao:string().required('Ano Fabricacao, Obrigatorio'),
    preco:string().required('Preço Atual Obrigatório'),
    versao:string().required('Versão Obrigatório').min(2,'Mínimo 2 caracteres').max(30,'Máximo 30 caracteres'),
    cor:string().required('Cor Obrigatório').min(2,'Mínimo 2 caracteres').max(30,'Máximo 30 caracteres'),
    kilometros_rodados:string().required('KM Obrigatorio')
  });

  const { 
    register, 
    handleSubmit : onSubmitNovoEdicao, 
    setValue,
    watch, 
    formState: { errors },
    reset : resetFormNovoProduto, 
  } = useForm({resolver:yupResolver(schemaFormNovoEdicao)});

  const handleSubmitNovoEdicao = (data:any) => {
    
    console.log('ESTA TENTANO CADASTRAR => '+ JSON.stringify(data));
    const date = new Date();
    if(
      data.marca == '' ||
      data.modelo == '' ||
      isNaN(data.ano_modelo)  || parseInt(data.ano_modelo) < 1900 || parseInt(data.ano_modelo) > date.getFullYear() ||
      isNaN(data.ano_fabricacao)  || parseInt(data.ano_fabricacao) < 1900 || parseInt(data.ano_fabricacao) > date.getFullYear() ||
      !data.preco ||
      !data.kilometros_rodados || isNaN(data.kilometros_rodados) ||
      !data.descricao ||
      !data.cor 
      ){
        alert('Por favor, verifique o preenchimento dos campos Obrigatorios');
        return;
      }

    if(!alteracaoProduto){
      //novo
      console.log('E UMA INCLUSAO DE CARRO...');
    //  console.log('Novo produto : '+data);
    var urlNovoCarro = "";
    
      if(tipoUsuario === 'estabelecimento'){
        urlNovoCarro = "/produto/carroNovo";
       
      }
      else{
        urlNovoCarro = "/produto/carroNovoUsuario";
       
      }
    
      console.log(' => '+urlNovoCarro);
    apiAxios.post(urlNovoCarro, {
      
      
      
      'marca' : data.marca,
      'modelo' : data.modelo,
      'ano_modelo' : data.ano_modelo,
      'ano_fabricacao' : data.ano_fabricacao,
      'versao' : data.versao,
      'cor' : data.cor,
      'kilometros_rodados' : data.kilometros_rodados,
      'preco' : data.preco,
      'descricao' : data.descricao,
      'blindado' : blindadoCheck,
      'tokenJwtForm' : tokenJwtFormRef.current.value
     

     })
     .then((response) => {
      

        if(response.data.success){

          setTitleModal('Produto Cadastrado !');
          if(response.data.qtdeUsuariosInformados > 0){
            setTextBodyModal('Seu carro foi Cadastrado e já foi enviado um alerta para '+response.data.qtdeUsuariosInformados+' clientes próximos ao seu estabelecimento');
          }
          else{
            setTextBodyModal('Seu carro foi Cadastrado com Sucesso, agora os clientes próximos serão avisados');
          }
          setTitleTextBodyModal('Cadastro realizado, os clientes próximos , estão sendo avisados');
          
          setModalShow(true);

          if(response.data.qtdeProdutosCadastrados < 5 ){
            setDesabilitarBotaoPublicar(false);
        }
        else if(response.data.qtdeProdutosCadastrados >= 5){
          setDesabilitarBotaoPublicar(true);
        }


        
        if(tipoUsuario === 'usuario'){
          if(Array.isArray(response.data.produtosCliente) ){

            //aqui fazer a logica de setar os produtos na tela
            var produtos = response.data.produtosCliente;
            setProdutos(produtos);
      
          }

        }
        else{
          //estabelecimento
          if(Array.isArray(response.data.produtosEstabelecimento) ){

            //aqui fazer a logica de setar os produtos na tela
            var produtos = response.data.produtosEstabelecimento;
            setProdutos(produtos);
      
          }
        }
        
       

       resetMarcasModelos();
       setValKmRodados('');
       setBlindadoCheck(false);
        resetFormNovoProduto({
          marca : 'Informe a Marca',
          modelo : '',
          descricao : '',
          ano_modelo: '',
          preco : '',
          ano_fabricacao : '',
          versao : '',
          cor : '',
          kilometros_rodados : '',
          blindado : ''

        });

        }
        else{
          alert(response.data.message);
        }
        
      
       
      console.log(response);

     })
     .catch((erro) => {
       console.log('ERRO : '+JSON.stringify(erro));
      if(!erro.response.data.success && !erro.response.data.auth && erro.response.data.message === 'Token Expirado'){
       
        if(tipoUsuario === 'estabelecimento'){
          logoff();
        }
        else{
         alert('TOKEN EXPIRADO');
        }
       
      }

       console.log(erro);
      
     });



    }
    else{
      //Atualizar

      var urlAtualizarProduto = "";
      if(tipoUsuario === 'estabelecimento'){
        urlAtualizarProduto = "/produto/atualizarCarro";
      }
      else{
        urlAtualizarProduto = "/produto/atualizarCarroUsuario";
      }



    apiAxios.post(urlAtualizarProduto, {
      
      'idProduto' : idProduto,
      'marca' : data.marca,
      'modelo' : data.modelo,
      'ano_modelo' : data.ano_modelo,
      'ano_fabricacao' : data.ano_fabricacao,
      'versao' : data.versao,
      'cor' : data.cor,
      'kilometros_rodados' : data.kilometros_rodados,
      'preco' : data.preco,
      'descricao' : data.descricao,
      'blindado' : blindadoCheck,
      'tokenJwtForm' : tokenJwtFormRef.current.value


     })
     .then((response) => {
      

        if(response.data.success){

          resetMarcasModelos();
       setValKmRodados('');
       setBlindadoCheck(false);
          resetFormNovoProduto({
            marca : '',
            modelo : '',
            descricao : '',
            ano_modelo: '',
            preco : '',
            ano_fabricacao : '',
            versao : '',
            cor : '',
            kilometros_rodados : '',
            blindado : ''
            
          });

         
          

          if(Array.isArray(response.data.produtosEstabelecimento) ){

            //aqui fazer a logica de setar os produtos na tela
            var produtos = response.data.produtosEstabelecimento;
            setProdutos(produtos);
      
          }

          setTitleModal('Produto Atualizado !');
          if(response.data.qtdeUsuariosInformados > 0){
            setTextBodyModal('Seu carro foi Atualizado, clientes próximos ao seu estabelecimento foram avisados');
          }
          else{
            setTextBodyModal('Seu carro foi Atualizado com Sucesso, agora os clientes próximos ao seu estabelecimento serão avisados');
          }
          setTitleTextBodyModal('Atualização realizada, os clientes próximos ao seu estabelecimento, estão sendo avisados');
          
          setModalShow(true);


          if(response.data.qtdeProdutosCadastrados < 5 ){
            setDesabilitarBotaoPublicar(false);
          }
          else if(response.data.qtdeProdutosCadastrados >= 5){
            setDesabilitarBotaoPublicar(true);
          }
          
        }

       
      setAlteracaoProduto(false);
       
      console.log(response);

     })
     .catch((erro) => {
       
      if(!erro.response.data.success && !erro.response.data.auth && erro.response.data.message === 'Token Expirado'){
        logoff();
      }

       console.log(erro);
      
     });


    }
    
    


};

const mecanismoAlteracaoProduto = ()=>{
  setAlteracaoProduto(true);
  setDesabilitarBotaoPublicar(false);
}

const getCardsCarros = (produto) => {

  return ( 
      <Card>
        <Card.Body>
          <h5 className="card-title">{produto.nome_produto}</h5>
          <p className="card-text">{produto.descricao} </p>
          <p className="card-text">Ano-Modelo: {produto.ano_modelo}</p>
          <p className="card-text">Ano-Fabr: {produto.ano_fabricacao}</p>
          <p className="card-text">{produto.versao}</p>
          <p className="card-text">Cor: {produto.cor}</p>
      <p className="card-text">Por: R$ {produto.preco}</p>
          <p className="card-text">KM Rodados: {produto.kilometros_rodados}</p>
          <p className="card-text">Blindado: { (produto.blindado ?  'Sim' : 'Não'  )}</p>
         
          <Button variant="primary" type="button" onClick={alterarProduto} id={produto._id} 
          data-nomeproduto={produto.nome_produto}
          data-descricao={produto.descricao} 
          data-marca={produto.marca}
          data-modelo={produto.modelo}
          data-anomodelo={produto.ano_modelo}
          data-anofabricacao={produto.ano_fabricacao}
          data-versao={produto.versao}
          data-cor={produto.cor}
          data-kilometrosrodados={produto.kilometros_rodados}
          data-preco={produto.preco}
          data-blindado={produto.blindado}
          >Alterar</Button>
          
      <Button variant="danger" type="button" onClick={apagarProduto} id={produto._id} 
          data-nomeproduto={produto.nome_produto}
          data-descricao={produto.descricao} 
          data-marca={produto.marca}
          data-modelo={produto.modelo}
          data-anomodelo={produto.ano_modelo}
          data-anofabricacao={produto.ano_fabricacao}
          data-versao={produto.versao}
          data-preco={produto.preco}
          >Apagar</Button>
        </Card.Body>
      </Card>
    
          );

}

const alterarProduto = event => { 
 
  mecanismoAlteracaoProduto();

  setMarcaProduto(event.target.getAttribute("data-marca"));
  setValue('marca',event.target.getAttribute("data-marca"),{ shouldValidate: true });
  setSelectedMarca(event.target.getAttribute("data-marca"));

  atualizaListaModelos(event.target.getAttribute("data-marca"));
  setModeloProduto(event.target.getAttribute("data-modelo"));
  setValue('modelo',event.target.getAttribute("data-modelo"),{ shouldValidate: true });
  setSelectedModelo(event.target.getAttribute("data-modelo"));
  //document.getElementById('modelo').options.selectedIndex = event.target.getAttribute("data-modelo");

  setAnoModelo(event.target.getAttribute("data-anomodelo"));
  setValue('ano_modelo',event.target.getAttribute("data-anomodelo"),{ shouldValidate: true });

  setAnoFabricacao(event.target.getAttribute("data-anofabricacao"));
  setValue('ano_fabricacao',event.target.getAttribute("data-anofabricacao"),{ shouldValidate: true });

  setVersao(event.target.getAttribute("data-versao"));
  setValue('versao',event.target.getAttribute("data-versao"),{ shouldValidate: true });

  setCor(event.target.getAttribute("data-cor"));
  setValue('cor',event.target.getAttribute("data-cor"),{ shouldValidate: true });

  
 
  setValue('blindado',event.target.getAttribute("data-blindado"),{ shouldValidate: true });
  if(event.target.getAttribute("data-blindado") === true || event.target.getAttribute("data-blindado") === 'true'){
    alterarEstadoCheckBlindado(true);
  }else{
    alterarEstadoCheckBlindado(false);
  }

  setKilometrosRodados(event.target.getAttribute("data-kilometrosrodados"));
  setValKmRodados(event.target.getAttribute("data-kilometrosrodados"));
  setValue('kilometros_rodados',event.target.getAttribute("data-kilometrosrodados"),{ shouldValidate: true });

  setDescricaoProduto(event.target.getAttribute("data-descricao"));
  setValue('descricao',event.target.getAttribute("data-descricao"),{ shouldValidate: true });
 

  setPrecoPorProduto(event.target.getAttribute("data-preco"));
  setValue('preco',currencyMaskTransform(event.target.getAttribute("data-preco")),{ shouldValidate: true });
 
   setIdProduto(event.currentTarget.id);

   executeScrollAtualizacao();
  //alert(event.currentTarget.id);
};

const apagarProduto = event => {
  //alert('APAGAR :'+event.currentTarget.id);
  setAlteracaoProduto(false);

  resetFormNovoProduto({
            marca : '',
            modelo : '',
            descricao : '',
            ano_modelo: '',
            preco : '',
            ano_fabricacao : '',
            versao : '',
            cor : '',
            kilometros_rodados : '',
            blindado : ''
    
  });
  
  executeScrollAtualizacao();



  var urlRemoverProduto = "/produto/removerCarro";


  if(props.tipoUsuario === 'estabelecimento'){
    urlRemoverProduto = "/produto/removerCarro";
  }else{
    urlRemoverProduto = "/produto/removerCarroUsuario";
  }

  apiAxios.post(urlRemoverProduto, {
    'idProduto' : event.currentTarget.id
   })
   .then((response) => {
    
    if(response.data.success && response.data.auth   ){

      if(Array.isArray(response.data.produtosEstabelecimento)){

         //aqui fazer a logica de setar os produtos na tela
      var produtos = response.data.produtosEstabelecimento;
      setProdutos(produtos);

      }

      if(response.data.qtdeProdutosCadastrados < 5 ){
        setDesabilitarBotaoPublicar(false);
      }
      else if(response.data.qtdeProdutosCadastrados >= 5){
        setDesabilitarBotaoPublicar(true);
      }
     

    }
    

   })
   .catch((erro) => {
     
    console.log(erro);

   });

}

    return (

        <section className="w-100 px-4 py-5 text-center bg-light">


          <MyVerticallyCenteredModal
      show={modalShow}
      onHide={() => setModalShow(false)}
      textBodyModal={textBodyModal}
      titleTextBodyModal={titleTextBodyModal}
      titleModal={titleModal}
    />
            <Row>
	  <h4 className="mb-5" ref={scrollAtualizacao}>{nomeUsuario}, Publique seus Carros:</h4>
   
	   <Form onSubmit={onSubmitNovoEdicao(handleSubmitNovoEdicao)}>

     <input type="hidden" ref={tokenJwtFormRef} name="tokenJwtForm" id="tokenJwtForm" value={tokenJwtForm} />
               
     <Row>
     <Col sm={3} className="mb-3">
                         
                    
                         <Form.Select value={selectedMarca} aria-label="Selecione a Marca" id="marca"
                         required
                         {...register("marca")}
                         onChange={handleMarcaUpdate}
                        >
                        {
     
                          marcasCarros.map((marca) => (
     
                         <option value={  (marca.nome == 'Informe a Marca' ?  ''    : marca.nome  )  } key={uuidv4()}>
                           {marca.nome}
                         </option>
                 
                       ))
     
                        }
                       </Form.Select>
                       
                <span style={{'color':'red'}}>{errors?.marca?.message}</span>
                         </Col>
                         <Col sm={3} className="mb-3">
                             
                             <Form.Select  
                             value={selectedModelo}
                            
                              required
                              {...register("modelo")}
                              placeholder="modelo" 
                              aria-label="Selecione o Modelo" 
                              id="modelo" 
                              onChange={handleModeloUpdate}
                              >
                           
                           {modelos.map((modelo) => (
                      <option key={uuidv4()} value={   (
                        modelo.nome == 'Informe a Marca' ||
                        modelo.nome == 'Selecione o Modelo' ? 
                        ''
                        :
                        modelo.nome
                        )  }>{modelo.nome}</option>
                           ))}
                          
                         </Form.Select>
                      
                    <span style={{'color':'red'}}>{errors?.modelo?.message}</span>
                                
                             </Col>

                             <Col sm={3} className="mb-3">
                  <Form.Control
            required
            type="text"
           value={valKmRodados}
            id="kilometros_rodados" 
           placeholder="KM Rodados"
           onInput={handleChangeKm}
            {...register("kilometros_rodados")}
          />
          <span style={{'color':'red'}}>{errors?.kilometros_rodados?.message}</span>
                  </Col>

                  <Col sm={3} className="mb-3">
                 
                 <Form.Check 
                  type="switch"
                  id="blindado"
                  label={labelCheckBlindado}
                  defaultChecked={false}
                  onChange={handleChangeBlindado}
                  checked={blindadoCheck}
                 />
                 
                        
                    </Col>

                 
      </Row>
                <Row>
                
                        
                    <Col sm={3} className="mb-3">
                       <Form.Control
            required
            type="text"
            id="ano_modelo" 
            {...register("ano_modelo")}
            placeholder="Ano Modelo"
            defaultValue=""
            as={InputMask}
            mask="9999"
            
          />
           <span style={{'color':'red'}}>{errors?.ano_modelo?.message}</span>
                    </Col>

                    <Col sm={3} className="mb-3">
                        
                         <Form.Control
            required
            type="text"
            id="ano_fabricacao" 
            {...register("ano_fabricacao")}
            placeholder="Ano Fabricação"
            defaultValue=""
            as={InputMask}
            mask="9999"
            
          />
           <span style={{'color':'red'}}>{errors?.ano_fabricacao?.message}</span>
                        
                    </Col>

                    <Col sm={3} className="mb-3">
                         
                    <Form.Control
            required
            type="text"
            id="versao" 
            {...register("versao")}
            //as={InputMask}
            //mask="9.999,99"
            placeholder="Versao"
            defaultValue=""
            maxLength="20"
          />
          <span style={{'color':'red'}}>{errors?.versao?.message}</span>
                     </Col>
                     <Col sm={3} className="mb-3">
                     <Form.Control
            required
            type="text"
            id="cor" 
            {...register("cor")}
            placeholder="Cor"
            defaultValue=""
            maxLength="20"
           
          />
          <span style={{'color':'red'}}>{errors?.cor?.message}</span>
                     </Col>
                </Row>
                <Row>
                   
					 <Col sm={3} className="mb-3">
                         
                       
          <Form.Control
             required
             type="text"
             id="preco" 
             {...register("preco")}
             placeholder="Preço Por"
             defaultValue=""
             onChange={(e) => handleChange(currencyMask(e))} 
             maxLength="12"
           />
            <span style={{'color':'red'}}>{errors?.preco?.message}</span>
                    </Col>
                    <Col sm={3} className="mb-3">
           
                        
                        
                    </Col>
                   
					
                </Row>
                <Row>
               
                    
					
                    
                </Row>
                <Row>
                  
                  <Col>
                  <Form.Control
            required
            type="text"
            id="descricao" 
            {...register("descricao")}
            placeholder="Breve Descrição"
            defaultValue=""
            maxLength="100"
            
          />
          <span style={{'color':'red'}}>{errors?.descricao?.message}</span>
                  </Col>
                </Row>
                <Row>
                <Col></Col>
                <Col></Col>
                <Col sm={3} className="mb-3">
                <Button variant="primary" type="submit" disabled={desabilitarBotaoPublicar} className="px-4">{alteracaoProduto ?  'Atualizar'  : 'Publicar'}</Button>
                </Col>
                <Col></Col>
                <Col></Col>
                </Row>
            </Form>
	  </Row>
      <hr/>
      <Row className="mt-4">
      <h4 className="mb-5">{nomeUsuario}, {areaProdutos.length > 0 && null != areaProdutos 
      ?
    'Atualize ou Remova seus produtos:'
      :
      'Mostre seus produtos para clientes ao seu redor'
    
    }</h4>
      </Row>
      <Row className="mt-4 text-center">
      {areaProdutos.length > 0 && null != areaProdutos 
      ? 
      //Logica para atualizar cards de produtos
         
          areaProdutos

       
      
      : 
      //Não tem produtos Cadastrados
      <p>'Publique seus Produtos'</p>
      }
	</Row>
  <hr/>
        </section>

    );

}

export default SectionFormCarrosEdicaoCarros;