import React, { useState, useRef, useEffect } from 'react';
import apiAxios from "../services/api";
import { Container, Row, Col, Form, Button, Card, Spinner, Alert } from 'react-bootstrap';
import { getDataEstabelecimentoLocalStorage } from '../services/auth';

const SectionQuestionarioComChatIAEstabelecimentos = () => {
    const questions = [
        "Quais são os principais objetivos que você deseja alcançar com a utilização da IA para o seu negócio?",
        "Quais são os maiores desafios que você enfrenta atualmente em relação à venda de cervejas e vinhos?",
        "Como você descreve o perfil dos seus clientes mais frequentes?"
    ];

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [chatEnabled, setChatEnabled] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const [currentMessage, setCurrentMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const chatEndRef = useRef(null);

    const handleAnswerChange = (e) => {
        setAnswers({
            ...answers,
            [questions[currentQuestionIndex]]: e.target.value
        });
    };

    const handleNextQuestion = () => {
        if (answers[questions[currentQuestionIndex]] && answers[questions[currentQuestionIndex]].trim() !== '') {
            if (currentQuestionIndex < questions.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            } else {
                setChatEnabled(true);
            }
        } else {
            alert('Por favor, responda à pergunta antes de prosseguir.');
        }
    };

    const handleSendMessage = async () => {
        if (currentMessage.trim() !== '') {
            const userMessage = currentMessage;
            setChatMessages([...chatMessages, { from: 'user', text: userMessage }]);
            setCurrentMessage('');
            setIsLoading(true);
            setError(null);

            const payload = {
                questionsAndAnswers: answers,
                userMessage: userMessage,
                'threadAssistantOpenAi':(getDataEstabelecimentoLocalStorage().threadAssistantOpenAi ? getDataEstabelecimentoLocalStorage().threadAssistantOpenAi : null)
            };

            try {
                const urlConsultaProdutos = "/estabelecimento/getTextAiEstabelecimentoLogadoChat";

                apiAxios.post(urlConsultaProdutos, payload)
                    .then((response) => {
                        if (response.data.success) {
                            const aiResponse = response.data.texto;

                            setChatMessages((prevMessages) => [
                                ...prevMessages,
                                { from: 'ai', text: aiResponse }
                            ]);
                        }
                        else{
                            alert(response.data.message);
                        }
                    });

            } catch (err) {
                console.error('Erro ao enviar mensagem:', err);
                setError('Ocorreu um erro ao obter a resposta da IA. Por favor, tente novamente.');
            } finally {
                setIsLoading(false);
            }
        }
    };

    // Scroll para o final sempre que uma nova mensagem for adicionada ou o contêiner crescer
    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatMessages]);

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={8}>
                    <h2 className="text-center mb-4 text-primary">
                        Responda às perguntas para começar e depois converse com a IA da GeoGet!
                    </h2>
                </Col>
            </Row>
            {!chatEnabled ? (
                <Row className="justify-content-center">
                    <Col md={6}>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <Card.Title className="text-center">
                                    Pergunta {currentQuestionIndex + 1} de {questions.length}
                                </Card.Title>
                                <Form.Group>
                                    <Form.Label>{questions[currentQuestionIndex]}</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={answers[questions[currentQuestionIndex]] || ''}
                                        onChange={handleAnswerChange}
                                        placeholder="Digite sua resposta aqui..."
                                    />
                                </Form.Group>
                                <div className="text-center mt-3">
                                    <Button variant="primary" onClick={handleNextQuestion}>
                                        {currentQuestionIndex === questions.length - 1 ? 'Iniciar Chat' : 'Próximo'}
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            ) : (
                <Row className="justify-content-center">
                    <Col md={8}>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <Card.Title className="text-center">
                                    Chat com GeoGet IA
                                </Card.Title>
                                <div
                                    className="chat-box overflow-auto border p-3 mb-3"
                                    style={{ maxHeight: '500px', overflowY: 'auto', backgroundColor: '#f8f9fa' }}
                                >
                                    {chatMessages.map((message, index) => (
                                        <div
                                            key={index}
                                            className={`d-flex mb-2 ${
                                                message.from === 'user' ? 'justify-content-end' : 'justify-content-start'
                                            }`}
                                        >
                                            <div
                                                className={`p-2 rounded ${
                                                    message.from === 'user' ? 'bg-primary text-white' : 'bg-secondary text-white'
                                                }`}
                                                style={{ maxWidth: '70%', whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                                            >
                                                <strong>{message.from === 'user' ? 'Você' : 'GeoGet IA'}:</strong> {message.text}
                                            </div>
                                        </div>
                                    ))}
                                    {isLoading && (
                                        <div className="d-flex justify-content-center">
                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                    )}
                                    <div ref={chatEndRef} />
                                </div>
                                {error && (
                                    <Alert variant="danger">
                                        {error}
                                    </Alert>
                                )}
                                <Form.Group className="d-flex">
                                    <Form.Control
                                        type="text"
                                        placeholder="Digite sua mensagem..."
                                        value={currentMessage}
                                        onChange={(e) => setCurrentMessage(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleSendMessage();
                                            }
                                        }}
                                        disabled={isLoading}
                                    />
                                    <Button
                                        variant="success"
                                        onClick={handleSendMessage}
                                        disabled={isLoading}
                                        className="ms-2"
                                    >
                                        Enviar
                                    </Button>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default SectionQuestionarioComChatIAEstabelecimentos;
