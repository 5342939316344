
import React from 'react';
import { Container, Row, Col, Card, ListGroup, Figure, Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa'; // Importa ícone de marcação
import ImagemVantagem1 from '../../assets/images/clientesfelizesnosbares.webp';
import qrCodeImage from '../../assets/images/qrcodepixparceiros.png';





const SectionVantagensParceiroGeoGet = (props) => {

    return (
        <main className="my-5">
      <Container>
       
        <section className="mb-5">
          <header className="text-center mb-4">
            <h1>Vantagens de Utilizar a API da GeoGet</h1>
            <p>Estabelecimentos cadastrados no seu sistema ERP irão aproveitar funcionalidades exclusivas da GeoGet para impulsionar suas ofertas</p>
          </header>
          <Row>
            <Col md={6}>
              <Figure>
                <Figure.Image
                  width="100%"
                  src={ImagemVantagem1} // Substitua esta URL com a URL da imagem desejada
                  alt="Estabelecimentos conectados a uma rede de clientes apaixonados por cervejas e vinhos"
                  className="img-fluid"
                />
                <Figure.Caption className="text-center">
                  Os estabelecimentos cadastrados no seu Sistema serão conectados a uma rede de clientes apaixonados por cervejas e vinhos
                </Figure.Caption>
              </Figure>
            </Col>
            <Col md={6}>
              <section>
                <Card>
                  <Card.Body>
                    <Card.Title className="text-center">Por que ser um parceiro GeoGet?</Card.Title>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <strong>Inteligência Artificial</strong>: Os estabelecimentos irão interagir com Inteligência Artificial voltada para o segmento de cervejas e vinhos.
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Publicação de Promoções</strong>: Os estabelecimentos podem publicar até 15 promoções de cervejas e vinhos para clientes/leads ao seu redor.
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Indicação de Motoristas de Aplicativos</strong>: Alcance um público maior, quando um Motorista de aplicativo passar pela região do estabelecimento, irá indicar ao seu passageiro.
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Relatórios de Desempenho</strong>: Receba relatórios detalhados sobre o desempenho das promoções e a interação dos clientes.
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Segmentação de Clientes</strong>: Segmentação precisa de clientes interessados em cervejas e vinhos, baseada em geolocalização.
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Integração Fácil</strong>: API fácil de integrar com sistemas existentes, facilitando a adoção e uso.
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Suporte Especializado</strong>: Suporte especializado para ajudar a maximizar os benefícios da parceria.
                      </ListGroup.Item>
                    </ListGroup>
                  </Card.Body>
                </Card>
              </section>
            </Col>
          </Row>
        </section>

       
        <section>
          <header className="text-center mb-4">
            <h1>Plano de Assinatura</h1>
            <p>Conheça as vantagens GeoGet que vão agregar ao seu sistema, e os Donos dos Estabelecimentos vão adorar:</p>
          </header>
          <Row className="justify-content-center">
            <Col md={6} lg={4}>
            <Card className="text-center">
  <Card.Header>
    <h2>Plano Mensal</h2>
  </Card.Header>
  <Card.Body>
    <Card.Title className="display-4">R$ 2.225,00</Card.Title>
    <Card.Text>
      <strong>Válido a cada 30 dias</strong>
    </Card.Text>
    <Card.Text>
      <ul className="list-unstyled">
        <li className="d-flex align-items-center">
          <FaCheckCircle className="text-success me-2" style={{ fontSize: '1.25rem' }} />
          <strong>Cadastro de até 5 Estabelecimentos</strong>
        </li>
        <li className="d-flex align-items-center">
          <FaCheckCircle className="text-success me-2" style={{ fontSize: '1.25rem' }} />
          <strong>Bate Papo com Inteligência Artificial</strong>: Para o segmento de cervejas e vinhos
        </li>
        <li className="d-flex align-items-center">
          <FaCheckCircle className="text-success me-2" style={{ fontSize: '1.25rem' }} />
          <strong>Publique até 15 Geopromoções</strong>: Para clientes/leads ao seu redor
        </li>
        <li className="d-flex align-items-center">
          <FaCheckCircle className="text-success me-2" style={{ fontSize: '1.25rem' }} />
          <strong>Motoristas de aplicativos indicando para os Estabelecimentos, passageiros da região</strong>
        </li>
        <li className="d-flex align-items-center">
          <FaCheckCircle className="text-success me-2" style={{ fontSize: '1.25rem' }} />
          <strong>Integração da API GeoGet com seu sistema, podendo adequar ao seu Layout</strong>
        </li>
      </ul>
    </Card.Text>

    {/* QR Code */}
    <div className="my-4">
      <img src={qrCodeImage} alt="QR Code para Pagamento" className="img-fluid" style={{ width: '150px', height: '150px' }} />
      
    </div>

    {/* Mensagem após o QR code */}
    <Card.Text className="text-muted">
      Após realizar o pagamento, envie um email informando, para: <strong>geoget.geopromocoes@gmail.com</strong>
    </Card.Text>

  </Card.Body>
  <Card.Footer className="text-muted">
    Ou, Entre em contato para mais informações.
  </Card.Footer>
</Card>

            </Col>
          </Row>
        </section>
      </Container>
    </main>
      );

}

export default SectionVantagensParceiroGeoGet;