import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import ImagemVantagem1 from '../assets/images/telacelularcomcervejaepoints.webp';
import ImagemVantagem2 from '../assets/images/mappointcomcerveja.webp';
import ImagemVantagem3 from '../assets/images/celulartelacomcerveja.webp';


// Lista de imagens importadas.
const imageImports = [ImagemVantagem1, ImagemVantagem2, ImagemVantagem3];
// Lista de frases para serem exibidas.
const phrases = [
    'A Geoget te conecta a promoções relevantes e insights de mercado em tempo real, através da Geolocalização',
    'Descubra o melhor da sua região com a Geoget! Saiba quais são as cervejas e vinhos favoritos perto de você, compare a média de preços e receba promoções irresistíveis exatamente onde você está. A experiência de compra mais inteligente começa aqui!',
    'Aqui a sua Localização é usada de maneira eficaz, para te trazer Promoções de Cervejas e Vinhos ao seu redor.'
    
  ];

const SectionVantagemUsuarios = (props) => {

    const [phrase, setPhrase] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        // Seleciona uma frase aleatória da lista a cada renderização.
        const randomIndex = Math.floor(Math.random() * phrases.length);
        setPhrase(phrases[randomIndex]);

         // Seleciona uma imagem aleatória da lista a cada renderização.
    const randomImageIndex = Math.floor(Math.random() * imageImports.length);
    setImageUrl(imageImports[randomImageIndex]);
      }, []); // O array vazio garante que a frase seja definida apenas uma vez por renderização.

    return (
       <section key='5584'  style={{
        paddingTop: '8rem',
        paddingBottom: '3rem',
        '@media (max-width: 768px)': {
          paddingTop: '12rem',  // Aumenta o espaçamento no mobile
          paddingBottom: '5rem',
        }

        }}
       >
       <Container key='23359' className="my-4">
          <Row className="align-items-center">
            <Col md={6}>
              <Image src={imageUrl} alt="Geoget Geopromocoes de cervejas e vinhos" fluid />
            </Col>
            <Col md={6}>
              <h3>
              {phrase}
              </h3>
            </Col>
          </Row>
        </Container>
        </section>
      );

}

export default SectionVantagemUsuarios;