

import React from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { FaBullhorn,FaRobot,FaCar } from 'react-icons/fa'; // Importando ícones do react-icons
import qrCodeImage from '../assets/images/pix495geoget.png';

const SectionPrecoPlanoEstabelecimentos = () => {
    
   
    

    return (
        <Container className="mt-5">
        <Row className="justify-content-center">
            <Col xs={12} md={8} lg={6}>
                <Card className="shadow-sm border-0" style={{ maxWidth: '600px', margin: 'auto' }}>
                    <Card.Header className="bg-primary text-white text-center py-3" style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                        Aumente Suas Vendas - Plano Mensal GeoGet
                    </Card.Header>
                    <Card.Body className="p-4">
                        <Card.Title className="text-center mb-4" style={{ fontSize: '1.75rem', color: '#333' }}>
                            <strong>R$ 495,00</strong>
                        </Card.Title>
                        <div className="text-center mb-4">
                            <p><strong>Transforme suas vendas com a IA GeoGet!</strong></p>
                            <p>Publique até 15 ofertas exclusivas e atraia mais clientes.</p>
                        </div>
                        <div className="mb-4">
                            <div className="d-flex flex-column">
                                <div className="benefits-card mb-3">
                                    <h5><FaBullhorn /> Publique até 15 promoções</h5>
                                    <p>Atraia clientes da sua região e amplie suas vendas.</p>
                                </div>
                                <div className="benefits-card mb-3">
                                    <h5><FaRobot /> Assistente de Inteligência Artificial especializado</h5>
                                    <p>Converse com a Inteligência Artificial especializada no seu Segmento</p>
                                </div>
                            </div>
                            <div className="benefits-card" style={{ backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '5px' }}>
                                <h5><FaCar /> Indicações de Motoristas de Aplicativos</h5>
                                <p>Motoristas que passam pela sua região indicam seu estabelecimento, ajudando você a atrair novos clientes.</p>
                            </div>
                        </div>
                        <div className="text-center mb-4">
                            <p><strong>Garanta já seu plano!</strong></p>
                            <p>Pague facilmente via PIX QR Code:</p>
                            <img src={qrCodeImage} alt="QR Code" className="img-fluid" />
                        </div>
                        <div className="text-center mb-4">
                            <p><strong>Prefere pagar por transferência?</strong></p>
                            <table className="table table-borderless">
                                <tbody>
                                    <tr><td><strong>Banco:</strong></td><td>077</td></tr>
                                    <tr><td><strong>Agência:</strong></td><td>0001</td></tr>
                                    <tr><td><strong>Conta:</strong></td><td>39242883-0</td></tr>
                                    <tr><strong>GEOGET SERVIÇOS WEB LTDA</strong></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="text-center mt-4">
                            <p style={{ color: 'red', fontSize: '0.9em' }}>
                                <strong>Após realizar o pagamento, siga os passos abaixo:</strong><br />
                                <ul>
                                    <li>Envie um e-mail para <a href="mailto:geoget.geopromocoes@gmail.com">geoget.geopromocoes@gmail.com</a></li>
                                    <li>Inclua no e-mail: Comprovante de pagamento e dados do estabelecimento.</li>
                                </ul>
                            </p>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
    
    


    
    
  );
};

export default SectionPrecoPlanoEstabelecimentos;
